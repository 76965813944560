import ErrorBoundary from "../layouts/ErrorBoundary";
import { checkAuth } from "../utils/auth";

const Homepage = (props) => {
  const auth = checkAuth();
  let url;
  if (auth) {
    url = "/tour";
  } else {
    url = "/signin";
  }
  props.history.push(url);
  return null;
};
export default Homepage;
