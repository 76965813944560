import {
  getInputValue,
  sourcesOptions,
  getOptionFromSource,
} from "../../utils/commonutils";

const crudCode = {};

crudCode.onChangeInput = async (params) => {
  //console.log('onChangeInput params:' , params);
  const {
    nameForm,
    tableCrud,
    inputFullName,
    line,
    action,
    parentField,
    event,
    newValue,
    previousValue,
    props,
    formProps,
  } = params;

  if (inputFullName === "statusbase") {
    let newColor = "";
    if (newValue) {
      const objOption = getOptionFromSource("confirmationBaseStatus", newValue);
      if (objOption && objOption.color) newColor = objOption.color;
    }
    formProps.change("color", newColor);
  }
};

export default crudCode;
