// www.mobilsem.com  - Copyright .src/components/settings/Settings.js
import React, { Component } from "react";
import { connect } from "react-redux";

import { withRouter } from "react-router";
import { graphql, compose } from "react-apollo";
import { bindActionCreators } from "redux";

import PageHeader from "../PageHeader";
import defQls from "../../defQls";
import { processError } from "../../utils/helper";
import Form from "./BankStatementForm";
import { gqlActions } from "../../gqls/gqlactions";
import { appSettingsLoad } from "../../actions/appActions";

const upload = (file) => {
  fetch("http://www.example.net", {
    // Your POST endpoint
    method: "POST",
    headers: {
      "Content-Type": "You will perhaps need to define a content-type here",
    },
    body: file, // This is your file object
  })
    .then(
      (response) => response.json() // if the response is a JSON object
    )
    .then(
      (success) => console.log(success) // Handle the success response object
    )
    .catch(
      (error) => console.log(error) // Handle the error response object
    );
};
class Settings extends Component {
  constructor(props) {
    super(props);
  }

  handleSubmit = async (values) => {};

  render() {
    const { t } = this.props;

    let aElements = [];
    aElements.push(
      <PageHeader
        key="pageheader"
        icon="settings"
        title="form.bankstatement"
        t={t}
      />
    );

    aElements.push(
      <Form
        key="form"
        onSubmit={this.handleSubmit}
        t={t}
        containerPropsForm={this.props}
      />
    );

    return <div>{aElements}</div>;
  }
}

const withRouterList = withRouter(Settings);
const withGraphqlandRouter = compose(
  graphql(defQls.setOrgSettings, {
    name: "setOrgSettings",
    options: (props) => {
      const optionsValues = { variables: { id: props.match.params.id } };
      optionsValues.fetchPolicy = "network-only";
      return optionsValues;
    },
  }),
  graphql(defQls.getOrgSettings, {
    name: "getOrgSettings",
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = "network-only";
      let filters = {};

      optionsValues.variables = { ...filters };
      return optionsValues;
    },
  })
)(withRouterList);

const mapStateToProps = (state, ownProps) => {
  return {
    myState: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ appSettingsLoad, dispatch }, dispatch);
};

const ComponentFull = connect(
  mapStateToProps,
  mapDispatchToProps
)(withGraphqlandRouter);

export default ComponentFull;
