import React, { Component } from "react";
import { Link } from "react-router-dom";

import PageHeader from "../PageHeader";
import { getSession } from "../../utils/auth";
import ShowIcon from "../icons/ShowIcon";
import bowser from "bowser";
/*
      <Link to="/tourcategory">
        <div style = {{ marginTop: '7px', display: 'flex', alignItems: 'center' }}>
          <div style={{ width: '30px'}}><ShowIcon icon="tourcategory" /></div>
          <div>{t('table.tourcategory')}</div>
        </div>
      </Link>

 */

const handleClickDateFormat = ({ target }) => {
  if (target.checked) {
    target.removeAttribute("checked");
    localStorage.setItem("setDateFormat", "D/M/Y");
  } else {
    target.setAttribute("checked", true);
    localStorage.removeItem("setDateFormat");
  }
};
const Others = (props) => {
  let dateFormat;
  if (bowser.name === "Safari") {
    dateFormat = localStorage.getItem("setDateFormat");
  }
  let session = getSession();
  const { t } = props;
  return (
    <div>
      <PageHeader title="form.more" icon="iclistblack" key="pageheader" t={t} />
      {(session.ai ||
        !session.gu ||
        (session.pe.gendertype && session.pe.gendertype.view)) && (
        <Link to="/gendertype">
          <div
            style={{ marginTop: "7px", display: "flex", alignItems: "center" }}
          >
            <div style={{ width: "30px" }}>
              <ShowIcon icon="heart" />
            </div>
            <div>{t("table.gendertype")}</div>
          </div>
        </Link>
      )}
      {(session.ai ||
        (session.pe && session.pe.user && session.pe.user.view)) && (
        <Link to="/user">
          <div
            style={{ marginTop: "7px", display: "flex", alignItems: "center" }}
          >
            <div style={{ width: "30px" }}>
              <ShowIcon icon="person" />
            </div>
            <div>{t("table.user")}</div>
          </div>
        </Link>
      )}

      {(session.ai ||
        (session.pe && session.pe.usergroup && session.pe.usergroup.view)) && (
        <Link to="/usergroup" className="navbar">
          <div
            style={{ marginTop: "7px", display: "flex", alignItems: "center" }}
          >
            <div style={{ width: "30px" }}>
              <ShowIcon icon="people" />
            </div>
            <div>{t("table.usergroup_long")}</div>
          </div>
        </Link>
      )}

      {session.ai && (
        <Link to="/settings" className="navbar">
          <div
            style={{ marginTop: "7px", display: "flex", alignItems: "center" }}
          >
            <div style={{ width: "30px" }}>
              <ShowIcon icon="settings" />
            </div>
            <div>{t("menu.settings")}</div>
          </div>
        </Link>
      )}
      <div
        style={{ color: "#999999", paddingTop: "20px", paddingBottom: "10px" }}
      >
        {t("form.myAccount")}
      </div>
      <Link to="/changePass" className="navbar">
        <div
          style={{
            marginTop: "7px",
            height: "25px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ width: "30px", textAlign: "center" }}>►</div>
          <div>{t("form.changePass")}</div>
        </div>
      </Link>
      {bowser.name === "Safari" && (
        <div
          style={{
            marginTop: "7px",
            display: "flex",
            alignItems: "center",
            margingLeft: "10px",
          }}
        >
          &nbsp;
          <input
            type="checkbox"
            value="D/M/Y"
            onClick={handleClickDateFormat}
            defaultChecked={dateFormat === "D/M/Y"}
          />
          &nbsp;&nbsp;{t("form.date")} D/M/Y
        </div>
      )}
    </div>
  );
};
export default Others;
