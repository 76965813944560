import React from "react";
import { resolvePathObj } from "../../utils/commonutils";

const Comp = (props) => {
  return (
    <div style={{ paddingTop: "10px", alignItems: "top" }}>
      <div
        className="formLabel"
        style={{ paddingTop: "7px", fontSize: "22px" }}
      >
        {props.t("form.onlineregtype")}
      </div>
      <div className="formSubLabel" style={{ paddingTop: "0px" }}>
        {props.t("info.onlineregCatTourFields")}
      </div>
      <div style={{ paddingTop: "7px", fontSize: "18px", color: "#aaaaaa" }}>
        {props.t("form.personaldata")}
      </div>
    </div>
  );
};

export default Comp;
