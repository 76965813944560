const iconlibray1 = {
  check: {
    path: ["M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"],
    viewBox: "2 2 21 17",
  },
  /*badge:
    {
      path: [
        'M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm0 4c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12H6v-1.4c0-2 4-3.1 6-3.1s6 1.1 6 3.1V19z',
      ],
    },*/

  gps: {
    path: [
      "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z",
    ],
  },

  home: {
    path: ["M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"],
  },
  create: {
    path: [
      "M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z",
    ],
  },
  tablet: {
    path: [
      "M16 1H8C6.34 1 5 2.34 5 4v16c0 1.66 1.34 3 3 3h8c1.66 0 3-1.34 3-3V4c0-1.66-1.34-3-3-3zm-2 20h-4v-1h4v1zm3.25-3H6.75V4h10.5v14z",
    ],
  },
  labels: {
    path: [
      "M20 2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM8 20H4v-4h4v4zm0-6H4v-4h4v4zm0-6H4V4h4v4zm6 12h-4v-4h4v4zm0-6h-4v-4h4v4zm0-6h-4V4h4v4zm6 12h-4v-4h4v4zm0-6h-4v-4h4v4zm0-6h-4V4h4v4z",
    ],
  },
  accounting: {
    path: [
      "M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-5 14H4v-4h11v4zm0-5H4V9h11v4zm5 5h-4V9h4v9z",
    ],
  },
  link: {
    path: [
      "M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z",
    ],
  },
  linkoff: {
    path: [
      "M17 7h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1 0 1.43-.98 2.63-2.31 2.98l1.46 1.46C20.88 15.61 22 13.95 22 12c0-2.76-2.24-5-5-5zm-1 4h-2.19l2 2H16zM2 4.27l3.11 3.11C3.29 8.12 2 9.91 2 12c0 2.76 2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1 0-1.59 1.21-2.9 2.76-3.07L8.73 11H8v2h2.73L13 15.27V17h1.73l4.01 4L20 19.74 3.27 3 2 4.27z",
    ],
  },
  mailing: {
    path: [
      "M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z",
    ],
  },
  checkbank: {
    path: [
      "M 2.1439206,9.4947517 V 40.734492 H 36.879181 L 44.98431,29.836673 V 9.4947517 Z M 4.6887377,12.917758 H 42.439493 v 14.49875 h -7.360476 v 9.894977 H 4.6887377 Z m 2.8876063,4.651016 0.1191067,2.587239 c 10.5790593,-0.10724 21.1582513,1.480261 31.7373303,0 L 39.194568,17.33056 C 29.11779,19.719343 18.605376,17.785715 7.576344,17.568774 Z m -0.1191067,5.289549 0.2382134,2.827474 c 10.5791103,-1.504582 21.1582203,0 31.7373303,0 V 22.26279 c -10.351005,1.098356 -20.178292,0.0058 -31.9755437,0.595533 z m 0.2382134,4.934251 -0.1191067,2.706344 c 4.173751,0.692231 7.016997,1.009696 11.507312,0.119107 l 0.476427,-2.825451 c -4.874738,1.386493 -7.904422,2.281529 -11.8646323,0 z m 19.3363943,-0.03033 c -0.998767,2.041681 -3.18708,1.114816 -3.826826,3.1379 -1.06771,2.207836 1.81654,1.60706 3.054837,0.720525 1.789317,-2.188961 2.641673,1.227127 4.284922,-0.655382 0.67304,-1.17047 3.348594,2.42931 3.695549,0.429982 0.251565,-1.582603 -1.585112,-4.016309 -2.87032,-2.854658 -1.003972,1.276771 -3.633889,0.243981 -4.338162,-0.778367 z m 10.593746,3.075247 h 3.013737 c -0.971289,0.655561 -2.118842,4.163839 -3.013737,3.046374 z",
    ],
    viewBox: "0 0 48 48",
  },
  arroba: {
    path: [
      "m27.508,30.846c0.001,0 0.002,0 0.003-0.001 0,0 0.001,0 0.001-0.001l-.004,.002zm.586-.158c-0.209,0-0.423,0.048-0.583,0.157-2.661,1.973-5.945,3.155-9.511,3.155-8.836,0-16-7.163-16-16s7.164-16 16-16c8.824,0 15.977,7.144 15.998,15.963l2-.014c-0.028-9.917-8.074-17.949-17.998-17.949-9.941,0-18,8.059-18,18s8.059,18 18,18c3.908,0 7.506-1.269 10.455-3.386 0.373-0.146 0.639-0.503 0.639-0.927 3.55271e-15-0.552-0.448-0.999-1-0.999zm5.906-12.688c0,2.762-2.238,5-5,5s-5-2.238-5-5v-5c0-0.553-0.447-1-1-1-0.553,0-1,0.447-1,1v0.558c-1.064-0.958-2.457-1.558-4-1.558-3.314,0-6,2.687-6,6s2.686,6 6,6c2.043,0 3.842-1.025 4.925-2.584 1.2,2.129 3.456,3.584 6.075,3.584 3.866,0 7-3.134 7-7h-2zm-16,4c-2.209,0-4-1.791-4-4s1.791-4 4-4 4,1.791 4,4-1.791,4-4,4z",
    ],
    viewBox: "-6 -6 47 47",
  },
  flight: {
    path: [
      "M21 16v-2l-8-5V3.5c0-.83-.67-1.5-1.5-1.5S10 2.67 10 3.5V9l-8 5v2l8-2.5V19l-2 1.5V22l3.5-1 3.5 1v-1.5L13 19v-5.5l8 2.5z",
    ],
  },
  flightadd: {
    path: [
      "M 19.243176,16.518114 V 14.690819 L 11.982891,10.122581 V 5.0975186 c 0,-0.7583275 -0.608048,-1.3704715 -1.361303,-1.3704715 -0.7532544,0 -1.3613033,0.612144 -1.3613033,1.3704715 V 10.122581 L 2,14.690819 v 1.827295 l 7.2602847,-2.284119 v 5.025062 L 7.4452135,20.629529 V 22 L 10.621588,21.086352 13.797963,22 v -1.370471 l -1.815072,-1.370472 v -5.025062 z",
      "m 19.85608,0.18114145 h -2 V 4.1811415 h -4 v 2 h 4 v 3.9999995 h 2 V 6.1811415 h 4 v -2 h -4 z",
    ],
  },

  person: {
    path: [
      "M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z",
    ],
  },
  service: {
    path: [
      "M20 6h-2.18c.11-.31.18-.65.18-1 0-1.66-1.34-3-3-3-1.05 0-1.96.54-2.5 1.35l-.5.67-.5-.68C10.96 2.54 10.05 2 9 2 7.34 2 6 3.34 6 5c0 .35.07.69.18 1H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm-5-2c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zM9 4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm11 15H4v-2h16v2zm0-5H4V8h5.08L7 10.83 8.62 12 11 8.76l1-1.36 1 1.36L15.38 12 17 10.83 14.92 8H20v6z",
    ],
  },
  doublebed: {
    path: [
      "m 82.287,38.779548 c -8.404,0.611 -13.714,13.715 -13.714,13.715 h 34.286 c 0,-0.001 -2.86,-15.001 -20.572,-13.715 z m -27.429,0 c -17.712,-1.285 -20.571,13.715 -20.571,13.715 h 34.286 c 0,-0.001 -5.31,-13.105 -13.715,-13.715 z M 27.431,82.287 h 6.857 V 96.001 H 48.002 V 82.287 h 41.143 v 13.714 h 13.714 V 82.287 h 6.857 V 61.716 H 27.431 Z",
    ],
    viewBox: "15 15 105 105",
  },
  singlebed: {
    path: [
      "m 87.296641,52.494548 c -28.208007,34.486122 -14.104003,17.243061 0,0 z m -13.715,-13.715 c -17.712,-1.285 -20.571,13.715 -20.571,13.715 h 34.286 c 0,-0.001 -5.31,-13.105 -13.715,-13.715 z M 27.431,82.287 h 6.857 V 96.001 H 48.002 V 82.287 h 41.143 v 13.714 h 13.714 V 82.287 h 6.857 V 61.716 H 27.431 Z",
    ],
    viewBox: "15 15 105 105",
  },

  people: {
    path: [
      "M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z",
    ],
  },
  info: {
    path: [
      "M11 17h2v-6h-2v6zm1-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM11 9h2V7h-2v2z",
    ],
  },
  refresh: {
    path: [
      "M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z",
    ],
  },
  checkdepositlist: {
    path: [
      "M20 6h-3V4c0-1.11-.89-2-2-2H9c-1.11 0-2 .89-2 2v2H4c-1.11 0-2 .89-2 2v11c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zM9 4h6v2H9V4zm11 15H4v-2h16v2zm0-5H4V8h3v2h2V8h6v2h2V8h3v6z",
    ],
  },
  cleanfilters: {
    path: [
      "M7 14c-1.66 0-3 1.34-3 3 0 1.31-1.16 2-2 2 .92 1.22 2.49 2 4 2 2.21 0 4-1.79 4-4 0-1.66-1.34-3-3-3zm13.71-9.37l-1.34-1.34c-.39-.39-1.02-.39-1.41 0L9 12.25 11.75 15l8.96-8.96c.39-.39.39-1.02 0-1.41z",
    ],
  },
  settings: {
    path: [
      "M19.43 12.98c.04-.32.07-.64.07-.98s-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.12-.22-.39-.3-.61-.22l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.23-.09-.49 0-.61.22l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98s.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.12.22.39.3.61.22l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.23.09.49 0 .61-.22l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zM12 15.5c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z",
    ],
  },
  payment: {
    path: [
      "M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z",
    ],
  },

  assignment: {
    path: [
      "M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z",
    ],
  },
  transportation: {
    path: [
      "M17 5H3c-1.1 0-2 .89-2 2v9h2c0 1.65 1.34 3 3 3s3-1.35 3-3h5.5c0 1.65 1.34 3 3 3s3-1.35 3-3H23v-5l-6-6zM3 11V7h4v4H3zm3 6.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm7-6.5H9V7h4v4zm4.5 6.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zM15 11V7h1l4 4h-5z",
    ],
  },
  stopspoint: {
    path: [
      "M20 10h-3V8.86c1.72-.45 3-2 3-3.86h-3V4c0-.55-.45-1-1-1H8c-.55 0-1 .45-1 1v1H4c0 1.86 1.28 3.41 3 3.86V10H4c0 1.86 1.28 3.41 3 3.86V15H4c0 1.86 1.28 3.41 3 3.86V20c0 .55.45 1 1 1h8c.55 0 1-.45 1-1v-1.14c1.72-.45 3-2 3-3.86h-3v-1.14c1.72-.45 3-2 3-3.86zm-8 9c-1.11 0-2-.9-2-2s.89-2 2-2c1.1 0 2 .9 2 2s-.89 2-2 2zm0-5c-1.11 0-2-.9-2-2s.89-2 2-2c1.1 0 2 .9 2 2s-.89 2-2 2zm0-5c-1.11 0-2-.9-2-2 0-1.11.89-2 2-2 1.1 0 2 .89 2 2 0 1.1-.89 2-2 2z",
    ],
  },
  roombooking: {
    path: [
      "M 2.2334199,2.4303337 2.2174499,22.75655 c 0.06837,-0.0056 0.1251932,-0.03711 0.1863115,-0.05903 v 0.08714 h 1.767296 V 20.862217 H 20.062527 v 1.922445 h 1.767296 c -0.01164,-2.009792 0.02411,-4.019437 -0.01774,-6.028724 -0.01087,-2.615732 0.04562,-5.231764 0.0036,-7.8471784 C 21.273162,7.1319227 22.061452,7.3569841 20.953273,7.2167754 18.386036,7.0414048 13.529734,7.9511237 11.046834,6.85983 l 0.04791,3.648151 -6.9361048,-0.07589 -0.015969,-7.976466 z M 6.7740932,4.2740833 A 1.7950327,2.4371021 0 0 0 4.9784069,6.7108684 1.7950327,2.4371021 0 0 0 6.7740932,9.1504641 1.7950327,2.4371021 0 0 0 8.5697796,6.7108684 1.7950327,2.4371021 0 0 0 6.7740932,4.2740833 Z M 4.0787894,12.025702 c 5.3137749,0.03385 10.6273776,0.06733 15.9411516,0.101184 -0.03057,0.901798 0.02249,3.040069 0,4.06131 -0.385396,0.16959 -0.782391,0.335321 -1.171099,0.303544 -2.484459,-0.114746 -5.207749,0.426443 -7.647636,-0.56493 l 0.03194,3.012956 H 4.1710578 v -6.680782 c -0.033571,0.0015 -0.063035,0.02518 -0.095817,0.03093 z M 6.7740932,13.06 A 1.7950327,2.4371021 0 0 0 4.9784069,15.496786 1.7950327,2.4371021 0 0 0 6.7740932,17.933571 1.7950327,2.4371021 0 0 0 8.5697796,15.496786 1.7950327,2.4371021 0 0 0 6.7740932,13.06 Z",
    ],
  },
  empty: {
    path: ["M7.41"],
  },
  up: {
    path: ["M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"],
  },
  down: {
    path: ["M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z"],
  },

  navleft: {
    path: ["M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"],
  },
  navright: {
    path: ["M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"],
  },
  navfirst: {
    path: ["M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"],
  },
  navlast: {
    path: ["M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"],
  },
  assignmentturnedin: {
    path: [
      "M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-2 14l-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z",
    ],
  },
  timeline: {
    path: [
      "M23 8c0 1.1-.9 2-2 2-.18 0-.35-.02-.51-.07l-3.56 3.55c.05.16.07.34.07.52 0 1.1-.9 2-2 2s-2-.9-2-2c0-.18.02-.36.07-.52l-2.55-2.55c-.16.05-.34.07-.52.07s-.36-.02-.52-.07l-4.55 4.56c.05.16.07.33.07.51 0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2c.18 0 .35.02.51.07l4.56-4.55C8.02 9.36 8 9.18 8 9c0-1.1.9-2 2-2s2 .9 2 2c0 .18-.02.36-.07.52l2.55 2.55c.16-.05.34-.07.52-.07s.36.02.52.07l3.55-3.56C19.02 8.35 19 8.18 19 8c0-1.1.9-2 2-2s2 .9 2 2z",
    ],
  },
  pollblack: {
    path: [
      "M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM9 17H7v-7h2v7zm4 0h-2V7h2v10zm4 0h-2v-4h2v4z",
    ],
  },

  systemupdate: {
    path: [
      "M12 16.5l4-4h-3v-9h-2v9H8l4 4zm9-13h-6v1.99h6v14.03H3V5.49h6V3.5H3c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2z",
    ],
  },

  close: {
    path: [
      "M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z",
    ],
    viewBox: "2 2 21 17",
  },

  account: {
    path: [
      "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z",
    ],
  },
  vpnkey: {
    path: [
      "M12.65 10C11.83 7.67 9.61 6 7 6c-3.31 0-6 2.69-6 6s2.69 6 6 6c2.61 0 4.83-1.67 5.65-4H17v4h4v-4h2v-4H12.65zM7 14c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z",
    ],
  },
  exitapp: {
    path: [
      "M10.09 15.59L11.5 17l5-5-5-5-1.41 1.41L12.67 11H3v2h9.67l-2.58 2.59zM19 3H5c-1.11 0-2 .9-2 2v4h2V5h14v14H5v-4H3v4c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z",
    ],
  },
  editblack: {
    path: [
      "M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z",
    ],
  },
  write: {
    path: [
      "M17.75 7L14 3.25l-10 10V17h3.75l10-10zm2.96-2.96c.39-.39.39-1.02 0-1.41L18.37.29c-.39-.39-1.02-.39-1.41 0L15 2.25 18.75 6l1.96-1.96z",
      "M0 20h24v4H0z",
    ],
  },
  registration: {
    path: [
      "M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm2 14H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z",
    ],
  },
  registrationadd: {
    path: [
      "M 14.718856,7.6966064 H 11.657305 C 11.349685,6.8392766 10.544013,6.2184515 9.591856,6.2184515 c -0.952157,0 -1.7578283,0.6208251 -2.0654482,1.4781549 H 4.4648569 C 3.6591856,7.6966064 3,8.3617761 3,9.1747613 V 19.521845 C 3,20.33483 3.6591856,21 4.4648569,21 H 14.718856 c 0.805671,0 1.464856,-0.66517 1.464856,-1.478155 V 9.1747613 c 0,-0.8129852 -0.659185,-1.4781549 -1.464856,-1.4781549 z m -5.127,0 c 0.4028356,0 0.732429,0.3325848 0.732429,0.7390774 0,0.4064926 -0.3295934,0.7390775 -0.732429,0.7390775 -0.4028356,0 -0.7324284,-0.3325849 -0.7324284,-0.7390775 0,-0.4064926 0.3295928,-0.7390774 0.7324284,-0.7390774 z M 11.056713,18.043691 H 5.9297138 V 16.565535 H 11.056713 Z M 13.253998,15.08738 H 5.9297138 v -1.478154 h 7.3242842 z m 0,-2.956309 H 5.9297138 v -1.478155 h 7.3242842 z",
      "M 19.85608,0.18114145 h -2 V 4.1811415 h -4 v 2 h 4 v 3.9999995 h 2 V 6.1811415 h 4 v -2 h -4 z",
    ],
  },
  recordvoice: {
    viewBox: "-4 -4 27 27", // need margin right
    path: [
      "M9 15c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4zm7.76-9.64l-1.68 1.69c.84 1.18.84 2.71 0 3.89l1.68 1.69c2.02-2.02 2.02-5.07 0-7.27zM20.07 2l-1.63 1.63c2.77 3.02 2.77 7.56 0 10.74L20.07 16c3.9-3.89 3.91-9.95 0-14z",
    ],
    circle: ['cx="9" cy="9" r="4"'],
  },
  tagfaces: {
    path: [
      "M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z",
    ],
  },
  permcontactcalendar: {
    path: [
      "M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12H6v-1c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1z",
    ],
  },
  localatm: {
    path: [
      "M11 17h2v-1h1c.55 0 1-.45 1-1v-3c0-.55-.45-1-1-1h-3v-1h4V8h-2V7h-2v1h-1c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1h3v1H9v2h2v1zm9-13H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4V6h16v12z",
    ],
  },
  monetization: {
    path: [
      "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1.41 16.09V20h-2.67v-1.93c-1.71-.36-3.16-1.46-3.27-3.4h1.96c.1 1.05.82 1.87 2.65 1.87 1.96 0 2.4-.98 2.4-1.59 0-.83-.44-1.61-2.67-2.14-2.48-.6-4.18-1.62-4.18-3.67 0-1.72 1.39-2.84 3.11-3.21V4h2.67v1.95c1.86.45 2.79 1.86 2.85 3.39H14.3c-.05-1.11-.64-1.87-2.22-1.87-1.5 0-2.4.68-2.4 1.64 0 .84.65 1.39 2.67 1.91s4.18 1.39 4.18 3.91c-.01 1.83-1.38 2.83-3.12 3.16z",
    ],
  },
  paymentadd: {
    path: [
      "M 9.5323943,6.3291979 C 5.3745127,6.3291979 2,9.8394581 2,14.164599 2,18.48974 5.3745127,22 9.5323943,22 c 4.1578827,0 7.5323947,-3.51026 7.5323947,-7.835401 0,-4.3251409 -3.374512,-7.8354011 -7.5323947,-7.8354011 z M 10.594462,18.936358 V 20.43292 H 8.5833129 V 18.920688 C 7.2952734,18.638613 6.2030762,17.776719 6.1202199,16.256651 h 1.4763493 c 0.075324,0.822717 0.6176562,1.46522 1.9960846,1.46522 1.4763492,0 1.8077742,-0.767869 1.8077742,-1.245829 0,-0.650338 -0.331425,-1.2615 -2.0111491,-1.676776 -1.8680337,-0.470124 -3.1485408,-1.269334 -3.1485408,-2.875592 0,-1.347689 1.0470028,-2.2252543 2.3425748,-2.5151637 V 7.8962784 h 2.0111491 v 1.5279031 c 1.401026,0.352593 2.101538,1.4573845 2.146733,2.6562005 h -1.47635 c -0.03766,-0.869729 -0.482073,-1.465219 -1.6721912,-1.465219 -1.1298591,0 -1.8077747,0.532806 -1.8077747,1.285006 0,0.658172 0.4896058,1.08912 2.0111494,1.49656 1.5215435,0.407442 3.1485405,1.089122 3.1485405,3.063643 -0.0075,1.433878 -1.03947,2.217418 -2.350107,2.475986 z",
      "M 19.85608,0.18114145 h -2 V 4.1811415 h -4 v 2 h 4 v 3.9999995 h 2 V 6.1811415 h 4 v -2 h -4 z",
    ],
  },
  city: {
    path: [
      "M15 11V5l-3-3-3 3v2H3v14h18V11h-6zm-8 8H5v-2h2v2zm0-4H5v-2h2v2zm0-4H5V9h2v2zm6 8h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2V9h2v2zm0-4h-2V5h2v2zm6 12h-2v-2h2v2zm0-4h-2v-2h2v2z",
    ],
  },
  bank: {
    viewBox: "0 0 38 38",
    fillrule: "evenodd",
    path: [
      "M10.5480623 13.1080574L30.6650623 13.1080574 20.9790623 7.06641028 10.5480623 13.1080574zM6.99306226 15C6.65106226 15 6.32306226 14.8255323 6.14306226 14.5422568 5.86006226 14.0985836 6.01006226 13.5179627 6.47706226 13.2468812L20.4670623 5.14445174C20.6240623 5.04971391 20.8350623 5.01031798 21.0080623 5 21.2150623 5.00468999 21.4060623 5.06190789 21.5670623 5.1669637L34.5500623 13.2637651C34.8210623 13.4335428 34.9890623 13.7177563 34.9990623 14.0244818L34.9990623 14.0535597C34.9850623 14.2786793 34.9310623 14.432511 34.8400623 14.5666448 34.6520623 14.8396023 34.3400623 15 33.9990623 15L33.9830623 15 7.00906226 15 6.99306226 15zM11 30.0152271L16 30.0152271 16 18.9847729 11 18.9847729 11 30.0152271zM18 30.0152271L23 30.0152271 23 18.9847729 18 18.9847729 18 30.0152271zM25 30.0152271L30 30.0152271 30 18.9847729 25 18.9847729 25 30.0152271zM33.004 18.9847729C33.553 18.9847729 34 18.5397742 34 17.9923865 34 17.4449987 33.553 17 33.004 17L7.996 17C7.447 17 7 17.4449987 7 17.9923865 7 18.5397742 7.447 18.9847729 7.996 18.9847729L9 18.9847729 9 30.0152271 7.996 30.0152271C7.447 30.0152271 7 30.4602258 7 31.0085981 7 31.5550013 7.447 32 7.996 32L33.004 32C33.553 32 34 31.5550013 34 31.0085981 34 30.4602258 33.553 30.0152271 33.004 30.0152271L32 30.0152271 32 18.9847729 33.004 18.9847729zM35.004 34L5.996 34C5.447 34 5 34.4481904 5 34.9995042 5 35.550818 5.447 36 5.996 36L35.004 36C35.553 36 36 35.550818 36 34.9995042 36 34.4481904 35.553 34 35.004 34",
    ],
  },

  powersettings: {
    path: [
      "M13 3h-2v10h2V3zm4.83 2.17l-1.42 1.42C17.99 7.86 19 9.81 19 12c0 3.87-3.13 7-7 7s-7-3.13-7-7c0-2.19 1.01-4.14 2.58-5.42L6.17 5.17C4.23 6.82 3 9.26 3 12c0 4.97 4.03 9 9 9s9-4.03 9-9c0-2.74-1.23-5.18-3.17-6.83z",
    ],
  },
  balance: {
    viewBox: "-10 -10 512 512",
    path: [
      "M31.334 72.438h203.858v-53.053h42.629v53.064h203.879v60.836h-203.879v290.161c40.038 6.257 69.683 30.7 69.683 59.3 0 3.123-0.522 6.759-1.065 9.882h-179.897c-0.542-3.123-1.054-6.759-1.054-9.882 0-28.601 29.645-53.043 69.703-59.3v-290.161h-203.858v-60.846zM157.184 245.084h-126.884c0 2.058 0 3.635 0 5.724 0 35.358 28.57 63.98 63.426 63.98 35.358 0 63.447-28.621 63.447-63.98 0.010-2.089 0.010-3.666 0.010-5.724v0zM93.737 158.761l-20.296 34.837-20.265 35.379h81.122l-20.275-35.379-20.285-34.837zM93.737 133.796l-30.689 54.077-32.225 57.201h125.85l-32.266-57.201-30.669-54.077zM480.645 245.084h-126.382c-0.522 2.058-0.522 3.635-0.522 5.724 0 35.358 28.621 63.98 63.437 63.98 35.358 0 63.98-28.621 63.98-63.98 0-2.089 0-3.666-0.512-5.724v0zM417.178 158.761l-20.275 34.837-19.784 35.379h80.62l-20.296-35.379-20.265-34.837zM417.178 133.796l-30.659 54.077-32.256 57.201h126.382l-32.236-57.201-31.232-54.077z",
    ],
  },
  addcircleoutline: {
    path: [
      "M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z",
    ],
  },
  plusbox: {
    path: [
      "M17,13H13V17H11V13H7V11H11V7H13V11H17M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3Z",
    ],
  },
  markercheck: {
    path: [
      "M10,16L5,11L6.41,9.58L10,13.17L17.59,5.58L19,7M19,1H5C3.89,1 3,1.89 3,3V15.93C3,16.62 3.35,17.23 3.88,17.59L12,23L20.11,17.59C20.64,17.23 21,16.62 21,15.93V3C21,1.89 20.1,1 19,1Z",
    ],
  },
  closebox: {
    path: [
      "M19,3H16.3H7.7H5A2,2 0 0,0 3,5V7.7V16.4V19A2,2 0 0,0 5,21H7.7H16.4H19A2,2 0 0,0 21,19V16.3V7.7V5A2,2 0 0,0 19,3M15.6,17L12,13.4L8.4,17L7,15.6L10.6,12L7,8.4L8.4,7L12,10.6L15.6,7L17,8.4L13.4,12L17,15.6L15.6,17Z",
    ],
  },
  deleteforever: {
    path: [
      "M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8.46,11.88L9.87,10.47L12,12.59L14.12,10.47L15.53,11.88L13.41,14L15.53,16.12L14.12,17.53L12,15.41L9.88,17.53L8.47,16.12L10.59,14L8.46,11.88M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z",
    ],
  },

  arrowdowndropcircleoutline: {
    path: [
      "M12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M17,14L12,9L7,14H17Z",
    ],
  },

  plus: {
    path: ["M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"],
  },
  iclistblack: {
    path: [
      "M3 13h2v-2H3v2zm0 4h2v-2H3v2zm0-8h2V7H3v2zm4 4h14v-2H7v2zm0 4h14v-2H7v2zM7 7v2h14V7H7z",
    ],
  },
  deleteforever: {
    path: [
      "M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2.46-7.12l1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14l-2.13-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4z",
    ],
  },
  saveblack: {
    path: ["M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"],
  },
  phoneblack: {
    path: [
      "M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z",
    ],
  },
  removecircleblack: {
    path: [
      "M7 11v2h10v-2H7zm5-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z",
    ],
  },
  highlightoff: {
    path: [
      "M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z",
    ],
  },
  eye: {
    path: [
      "M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z",
    ],
  },
  eyeclosed: {
    path: [
      "M 12,4.5 C 7,4.5 2.73,7.61 1,12 c 1.73,4.39 6,7.5 11,7.5 5,0 9.27,-3.11 11,-7.5 C 21.27,7.61 17,4.5 12,4.5 Z M 12,15 C 9.24,15 7,14.76 7,12 7,9.24 9.24,9 12,9 c 2.76,0 5,0.24 5,3 0,2.76 -2.24,3 -5,3 z m 0,-4 c -1.66,0 -2.9922389,0.184753 -3,1 -0.00793,0.832646 1.34,1 3,1 1.66,0 2.990127,-0.168051 3,-1 0.0097,-0.815638 -1.34,-1 -3,-1 z",
    ],
  },
  eyecheck: {
    path: [
      "M23.5,17L18.5,22L15,18.5L16.5,17L18.5,19L22,15.5L23.5,17M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M12,17C12.5,17 12.97,16.93 13.42,16.79C13.15,17.5 13,18.22 13,19V19.45L12,19.5C7,19.5 2.73,16.39 1,12C2.73,7.61 7,4.5 12,4.5C17,4.5 21.27,7.61 23,12C22.75,12.64 22.44,13.26 22.08,13.85C21.18,13.31 20.12,13 19,13C18.22,13 17.5,13.15 16.79,13.42C16.93,12.97 17,12.5 17,12A5,5 0 0,0 12,7A5,5 0 0,0 7,12A5,5 0 0,0 12,17Z",
    ],
  },
  collage: {
    path: [
      "M5,3C3.89,3 3,3.89 3,5V19C3,20.11 3.89,21 5,21H11V3M13,3V11H21V5C21,3.89 20.11,3 19,3M13,13V21H19C20.11,21 21,20.11 21,19V13",
    ],
  },
  hotel: {
    path: [
      "M12 7V3H2v18h20V7H12zM6 19H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4V9h2v2zm0-4H4V5h2v2zm4 12H8v-2h2v2zm0-4H8v-2h2v2zm0-4H8V9h2v2zm0-4H8V5h2v2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2V9h8v10zm-2-8h-2v2h2v-2zm0 4h-2v2h2v-2z",
    ],
  },
  room: {
    path: [
      "m 6,2.6101695 c -1.1,0 -2,0.8267797 -2,1.8372881 V 19.145763 c 0,1.010509 0.9,1.837288 2,1.837288 h 12 c 1.1,0 2,-0.826779 2,-1.837288 V 4.4474576 C 20,3.4369492 19.1,2.6101695 18,2.6101695 Z M 18.322266,4.2608581 18.423828,19.362864 H 9.7636719 L 9.6601562,4.3541579 Z m -1.650391,6.0788389 -2.435547,1.550212 2.644531,1.361819 a 3.2542374,2.3355358 0 0 0 -0.208984,-2.912031 z",
    ],
  },
  bed: {
    path: [
      "M7 13c1.66 0 3-1.34 3-3S8.66 7 7 7s-3 1.34-3 3 1.34 3 3 3zm12-6h-8v7H3V5H1v15h2v-3h18v3h2v-9c0-2.21-1.79-4-4-4z",
    ],
  },
  paymentdocstatus: {
    path: [
      "M2.53 19.65l1.34.56v-9.03l-2.43 5.86c-.41 1.02.08 2.19 1.09 2.61zm19.5-3.7L17.07 3.98c-.31-.75-1.04-1.21-1.81-1.23-.26 0-.53.04-.79.15L7.1 5.95c-.75.31-1.21 1.03-1.23 1.8-.01.27.04.54.15.8l4.96 11.97c.31.76 1.05 1.22 1.83 1.23.26 0 .52-.05.77-.15l7.36-3.05c1.02-.42 1.51-1.59 1.09-2.6zM7.88 8.75c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-2 11c0 1.1.9 2 2 2h1.45l-3.45-8.34v6.34z",
    ],
  },
  done: {
    path: ["M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"],
  },
  clear: {
    path: [
      "M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z",
    ],
  },
  warning: {
    path: [
      "M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z",
    ],
    viewBox: "2 2 21 18",
  },
  heart: {
    path: [
      "M16.5 3c-1.74 0-3.41.81-4.5 2.09C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54L12 21.35l1.45-1.32C18.6 15.36 22 12.28 22 8.5 22 5.42 19.58 3 16.5 3zm-4.4 15.55l-.1.1-.1-.1C7.14 14.24 4 11.39 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04.99 3.57 2.36h1.87C13.46 5.99 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z",
    ],
  },
  log: {
    path: [
      "M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.28 2.54.72-1.21-3.5-2.08V8H12z",
    ],
  },
  duplicate: {
    path: [
      "M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1 9h-4v4h-2v-4H9V9h4V5h2v4h4v2z",
    ],
  },
  help: {
    path: [
      "M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z",
    ],
  },
  seat: {
    size: "22",
    path: [
      "M5.35 5.64c-.9-.64-1.12-1.88-.49-2.79.63-.9 1.88-1.12 2.79-.49.9.64 1.12 1.88.49 2.79-.64.9-1.88 1.12-2.79.49zM16 19H8.93c-1.48 0-2.74-1.08-2.96-2.54L4 7H2l1.99 9.76C4.37 19.2 6.47 21 8.94 21H16v-2zm.23-4h-4.88l-1.03-4.1c1.58.89 3.28 1.54 5.15 1.22V9.99c-1.63.31-3.44-.27-4.69-1.25L9.14 7.47c-.23-.18-.49-.3-.76-.38-.32-.09-.66-.12-.99-.06h-.02c-1.23.22-2.05 1.39-1.84 2.61l1.35 5.92C7.16 16.98 8.39 18 9.83 18h6.85l3.82 3 1.5-1.5-5.77-4.5z",
    ],
  },
  export: {
    path: [
      "M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM17 13l-5 5-5-5h3V9h4v4h3z",
    ],
  },
  download: {
    path: ["M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"],
  },
  pricelist: {
    viewBox: "0 2 110 110",
    path: [
      "M 68.460938,16.503906 C 48.732818,30.192989 28.996185,43.869581 9.2734375,57.566406 19.53339,72.358337 29.784707,87.156433 40.054688,101.94141 60.000944,88.095228 79.971806,74.284594 99.898438,60.410156 100.87289,52.65825 103.4953,45.123068 103.49219,37.253906 99.083641,31.627261 96.002885,24.971449 90.804688,19.972656 83.321825,19.062325 76.053029,16.449075 68.460938,16.503906 Z m 21.805351,15.001446 c 7.611427,6.305542 -1.409092,15.754867 -7.464922,9.831054 -6.778412,-6.543505 1.956151,-14.83689 7.464922,-9.831054 z m -35.365898,3.832539 c 0.236638,0.02617 0.503016,0.0815 0.800781,0.171875 2.745344,1.205373 -0.878201,5.618504 3.107422,5.34375 2.443122,0.847479 6.607257,-1.298293 5.746094,3.472656 1.184094,3.513289 -3.285538,6.821702 -4.632813,2.041016 -2.5118,-3.379616 -8.42524,-3.33688 -11.425781,-0.580079 -3.116143,3.842357 1.40934,7.449045 5.082031,8.033203 4.592428,1.571709 10.521996,2.812037 11.953125,8.181641 1.680959,5.326755 -2.787709,10.526448 -8.025391,11.089844 -0.77039,0.144778 -1.551705,0.23462 -2.333984,0.28125 -0.348344,2.891627 0.158329,5.998164 -0.9375,8.708984 -3.115718,2.28949 -4.041014,-1.962698 -3.439453,-4.291015 0.08132,-1.531575 0.160865,-3.062175 0.242187,-4.59375 -2.740881,-0.434422 -4.368473,-2.720466 -6.65625,-0.644532 -3.180493,-0.122962 -2.447166,-3.773849 -2.521484,-5.957031 -0.557111,-4.83114 5.311326,-3.592222 5.630859,0.359375 3.353716,2.577416 9.052902,3.584508 12.326172,0.359375 3.389663,-4.657602 -2.726978,-7.711929 -6.546875,-8.546875 -4.039543,-1.270215 -8.854701,-2.65022 -10.207031,-7.232422 -1.94601,-5.081625 1.986949,-10.691144 7.265625,-11.232422 3.908404,1.002118 1.022686,-5.357402 4.572266,-4.964843 z",
    ],
  },
  pele: {
    size: "24",
    viewBox: "-50 -0 270 240",
    path: [
      "M99.170,55.413 C105.357,55.413 110.645,53.218 115.035,48.828 C119.426,44.438 121.620,39.150 121.620,32.964 C121.620,26.778 119.425,21.490 115.035,17.100 C110.645,12.709 105.357,10.515 99.170,10.515 C92.984,10.515 87.596,12.710 83.006,17.100 C78.416,21.489 76.121,26.778 76.121,32.964 C76.121,39.150 78.416,44.438 83.006,48.828 C87.596,53.218 92.984,55.413 99.170,55.413 ZM21.043,231.871 L21.043,234.865 C21.043,239.255 22.539,243.046 25.533,246.239 C28.526,249.432 32.218,251.028 36.608,251.028 C44.591,251.028 49.779,247.237 52.174,239.654 L79.713,170.952 L106.055,239.055 C106.853,242.248 108.649,245.041 111.443,247.436 C114.237,249.831 117.630,251.028 121.620,251.028 C126.011,251.028 129.702,249.432 132.696,246.239 C135.689,243.047 137.186,239.255 137.186,234.865 L137.186,231.871 L102.009,136.591 L104.407,122.279 L106.653,124.855 C107.052,126.851 107.951,128.447 109.347,129.645 C110.744,130.841 112.041,131.440 113.239,131.440 L115.035,132.039 L152.153,141.019 L153.949,141.019 C157.142,141.019 159.836,140.021 162.031,138.025 C164.226,136.030 165.324,133.635 165.324,130.841 C165.324,125.254 162.530,121.662 156.943,120.066 L124.614,112.283 L117.110,88.073 C111.041,84.546 116.082,85.697 109.751,85.011 C104.822,84.476 101.383,89.894 98.190,89.495 L88.549,90.046 C83.361,90.046 72.689,86.063 68.698,89.057 C64.706,92.050 62.277,101.910 61.080,105.103 L56.819,121.205 L21.043,231.871 L21.043,231.871 Z",
      "M26.924,85.365 L51.915,85.365 L38.159,139.000 L13.159,139.000 L26.924,85.365 Z",
      "M169.974,94.972 L179.003,94.972 L174.033,251.000 L165.001,251.000 L169.974,94.972 Z",
    ],
  },

  loadingcircle: {
    size: "27",
    viewBox: "0 0 40 40",
    path: [
      "M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z",
      "M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z",
    ],
  },
  tourcategory: {
    path: [
      "M 21.700786,7.0522286 C 21.304325,6.2850436 20.381203,5.9861441 19.655329,6.3937833 L 14.827679,9.0584655 6.5678529,4.0872402 4.8117007,5.0495216 10.5627,11.407737 6.0443853,13.902741 3.75716,12.784901 l -1.3177473,0.729927 2.5301307,2.803282 1.0687778,1.178948 1.4541484,-0.805087 4.8276514,-2.664682 3.955641,-2.179592 4.827652,-2.6646827 c 0.733207,-0.4201233 0.993831,-1.363601 0.597372,-2.1307857 z",
      "M 17.450635,14.868051 5.7752236,21.918958 21.413223,21.570248 Z",
    ],
  },
};

export default iconlibray1;
