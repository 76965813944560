import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import { Link } from "react-router-dom";

import { dateToISO, ISOtoDate } from "../../utils/commonutils";
import { graphql, compose } from "react-apollo";
import {
  sourcesOptions,
  getOptionFromSource,
  getObjFromListById,
} from "../../utils/commonutils";
import IconLoading from "../icons/IconLoading";
//import { Row, Col, Card } from 'antd';
import D3SimplePieChart from "../charts/D3SimplePieChart";
import D3SimpleRingChart from "../charts/D3SimpleRingChart";

import { Button } from "../../layouts/cssstyled";
import defQls from "../../defQls";
import { Tables } from "../../defTables";
import { PageHeader } from "../../layouts/menu";
import { ListDataRercord, ListDataHeader } from "../ListData";

const tableCrud = "registration";
const action = "list";

const yourSplit = (N, string) => {
  let app = string.split(" "),
    arrayApp = [],
    stringApp = "";
  app.forEach(function (sentence, index) {
    stringApp += sentence + " ";

    if ((index + 1) % N === 0) {
      arrayApp.push(stringApp);
      stringApp = "";
    } else if (app.length === index + 1 && stringApp !== "") {
      arrayApp.push(stringApp);
      stringApp = "";
    }
  });
  return arrayApp;
};

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idYear: 200, // current year by default
      listMode: "rows",
      tourRegistration: null,
    };
  }
  onChangeYear = async (e) => {
    const idYear = e.target.value;
    this.setState({ idYear });
    this.props.tour.refetch({
      inactive: idYear ? null : false, // only when idyear is not empty , show indifferent if it's active
      _multidateField: "datestart",
      _multidate: idYear,
    });
  };

  render() {
    if (
      !this.props.registration ||
      this.props.registration.loading ||
      !this.props.registration.registrations ||
      !this.props.tour ||
      this.props.tour.loading ||
      !this.props.tour.tours
      //if ( !this.props.tour || this.props.tour.loading || !this.props.tour.tours
    ) {
      return <IconLoading />;
    }
    const { t, ...otherProps } = this.props;
    const idYear = this.state.idYear;
    let tourSumarize = {};
    let tours = {};
    let NewDatas = {};
    let paymentSource = {};
    let toursGraph = {};
    let payment = {}; // equivalent to toursGraph , but for payments, on the future, merge them

    // load payment first (separatedly from registrations that load after), is already present in tour record
    this.props.tour.tours.map((tour) => {
      let key = tour.name;

      /*

      default values for proces later

       */
      toursGraph[tour.name] = { stats: [], colors: [], dataPrint: [] };
      toursGraph[tour.name].titles = ["Inscriptions"];
      tourSumarize[key] = {};
      tours[key] = {};
      tours[key].totalstatus = 0;
      tours[key].total = tour.total;
      tours[key].paid = tour.paid;
      tours[key].balance = tour.balance;
      tours[key].seatsmax = tour.seatsmax;
      // default values by state
      sourcesOptions["registrationBaseStatus"].map((status) => {
        tourSumarize[key][status.id] = 0;
      });

      paymentSource[key] = {
        total: tour.total,
        paid: tour.paid,
        balance: tour.balance,
      };
      /* very important send 'age' must not be empty */
      let dataPrint2 = [];
      if (paymentSource[key].paid !== null)
        dataPrint2.push(
          <div key="paid">
            <div
              style={{
                display: "inline",
                paddingRight: "20px",
                backgroundColor: "#6ec3f1",
                width: "40px",
              }}
            >
              {" "}
            </div>
            <span style={{ color: "#999999" }}>
              &nbsp;Payé: {paymentSource[key].paid}{" "}
            </span>
          </div>
        );

      if (paymentSource[key].balance !== null)
        dataPrint2.push(
          <div key="balance">
            <div
              style={{
                display: "inline",
                paddingRight: "20px",
                backgroundColor: "#ffa401",
                width: "40px",
              }}
            >
              {" "}
            </div>
            <span style={{ color: "#999999" }}>
              &nbsp;Solde: {paymentSource[key].balance}{" "}
            </span>
          </div>
        );

      dataPrint2.push(
        <div key="total">
          <div
            style={{
              display: "inline",
              paddingRight: "20px",
              backgroundColor: "#AAAAAA",
              width: "40px",
            }}
          >
            {" "}
          </div>
          <span style={{ color: "#999999" }}>
            &nbsp;Total:{" "}
            {paymentSource[key].total ? paymentSource[key].total : 0}{" "}
          </span>
        </div>
      );

      payment[key] = {
        titles: ["Paiements"],
        dataPrint: [dataPrint2[0], dataPrint2[1], dataPrint2[2]],
      };

      if (paymentSource[key].paid === 0 && paymentSource[key].balance === 0) {
        payment[key].colors = ["#AAAAAA", "nocolor"];
        payment[key].stats = [
          { age: "total", population: 100 },
          { age: "nada", population: 0 },
        ];
      } else {
        payment[key].colors = ["#6ec3f1", "#ffa401"];
        payment[key].stats = [
          { age: "paid", population: paymentSource[key].paid },
          { age: "balance", population: paymentSource[key].balance },
        ];
      }

      //console.log( 'payment:'+key,  payment[key]);
    });

    if (
      this.props.registration &&
      !this.props.registration.loading &&
      this.props.registration.registrations
    ) {
      this.props.registration.registrations.map((record) => {
        let tourname = record.registrationtour_id.name;
        if (record.registrationstatus_id && tourSumarize[tourname]) {
          ////console.log(record);
          tourSumarize[tourname][
            record.registrationregistrationstatus_id.statusbase
          ] += 1;

          if (record.registrationregistrationstatus_id.statusbase !== 14)
            tours[tourname].totalstatus += 1;
        }
      });
      for (const [key, tourSum] of Object.entries(tourSumarize)) {
        NewDatas[key] = [];
        let totalWithStatus = 0;

        let seatsfree = tours[key].seatsmax
          ? tours[key].seatsmax - tours[key].totalstatus
          : 0;
        // just 'age' is compatible with actual react component

        if (seatsfree > 0) {
          //NewDatas[key].push ( { age: 'Libre '+ seatsfree , population:  seatsfree });
          NewDatas[key].push({
            age: "Libre",
            qty: seatsfree,
            population: seatsfree,
            color: "#f4ddb5",
          });
        }
        //
        //forOwn(tourSum, function (total, status) {
        for (const [status, total] of Object.entries(tourSum)) {
          //let statusdet = sourcesOptions["registrationBaseStatus"].find(item => {
          let statusObj = getOptionFromSource(
            "registrationBaseStatus",
            parseInt(status)
          );
          //console.log('statusObje for ' + status ,   statusObj);
          let statusdet = t(
            "registrationBaseStatus." + statusObj.name + "_short"
          );
          //console.log('statusdet',statusdet);
          if (statusdet && (status === "4" || total > 0)) {
            // force show inscriptios same his total is zero
            //NewDatas[key].push ( { age: t('registrationBaseStatus.'+statusdet)+ ': '+total , population: total });
            NewDatas[key].push({
              age: statusdet,
              qty: total,
              population: total,
              color: statusObj.color,
            });
            totalWithStatus += total;
          }
        }
      }

      // after load registrationtion, overwrite emptye values loaded after load tours
      for (const [key, tour] of Object.entries(NewDatas)) {
        let colors = [];
        let stats = [];
        let dataPrint = [];
        NewDatas[key].map((subdata) => {
          //console.log('subdata', subdata);
          colors.push(subdata.color);
          stats.push({ age: subdata.age, population: subdata.population });
          dataPrint.push(
            <div key={subdata.age}>
              <div
                style={{
                  display: "inline",
                  paddingRight: "20px",
                  backgroundColor: subdata.color,
                  width: "40px",
                }}
              >
                {" "}
              </div>
              <span style={{ color: "#999999" }}>
                &nbsp;{subdata.age} {subdata.qty}{" "}
              </span>
            </div>
          );
        });

        // update keys conserving previous values
        toursGraph[key].stats = stats;
        toursGraph[key].colors = colors;
        toursGraph[key].dataPrint = dataPrint;
      }
      /*setTimeout(() => {
        this.setState( (prevState, props) => {
          return { tourRegistration: toursGraph};
        });
      }, 1000);*/
    }

    const graphs = [];

    // list accord tour order, just the tours have stats (toursGraph[key])
    this.props.tour.tours.map((record) => {
      let key = record.name;
      //if (toursGraph[key]) {
      //console.log('this.state.tourRegistration '+key, this.state.tourRegistration);
      let tourData = toursGraph[key];
      //let graphRegistrations = this.state.tourRegistration && this.state.tourRegistration.titles ? <div><div key="chart"><D3SimplePieChart data={this.state.tourRegistration[key]} /></div><div key="data">{this.state.tourRegistration[key].dataPrint}</div></div> : <IconLoading />;
      //let graphRegistrations = this.tourData && tourData.titles ? <div><div key="chart"><D3SimplePieChart data={tourData} /></div><div key="data">{tourData.dataPrint}</div></div> : <IconLoading />;
      let graphRegistrations = (
        <div>
          <div key="chart">
            <D3SimplePieChart data={tourData} />
          </div>
          <div key="data">{tourData.dataPrint}</div>
        </div>
      );
      let graphPayments = (
        <div>
          <div key="char">
            <D3SimplePieChart data={payment[key]} />
            <div key="data">{payment[key].dataPrint}</div>
          </div>
        </div>
      );

      graphs.push(
        <div
          key={key}
          style={{
            display: "flex",
            flexBasis: "320px",
            flexDirection: "column",
            minHeight: "200px",
            borderBottom: "dashed 1px #aaaaaa",
            borderRight: "dashed 1px #aaaaaa",
            padding: "10px",
          }}
        >
          <div key="title" style={{ color: "#777777", fontSize: "19px" }}>
            {key}{" "}
          </div>
          <div key="graphic" style={{ display: "flex", flexDirection: "rol" }}>
            <div
              key="registration"
              style={{ borderBottom: "solid 20px #ffffff" }}
            >
              {graphRegistrations}
            </div>
            <div key="payment" style={{ borderBottom: "solid 20px #ffffff" }}>
              {graphPayments}
            </div>
          </div>
        </div>
      );
      //}
    });

    const yearToday = new Date().getFullYear();
    const defYears = [
      { id: 205, name: yearToday - 1 },
      { id: 200, name: yearToday },
      { id: 210, name: yearToday + 1 },
    ];
    const years = [];

    years.push(<option value=""> ...</option>);
    for (let y = 0; y < defYears.length; y++) {
      years.push(
        <option key={defYears[y].id} value={defYears[y].id}>
          {defYears[y].name}
        </option>
      );
    }

    return (
      <div>
        <div>
          <span className="formLabel">{t("form.year")}&nbsp;</span>
          <select onChange={this.onChangeYear} value={idYear ? idYear : ""}>
            {years}
          </select>
        </div>
        <div
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          {graphs}
        </div>
      </div>
    );
  }
}

const withList = withRouter(List);

const withRouterList = withRouter(List);
const withGraphqlandRouter = compose(
  graphql(defQls.tour.List, {
    name: "tour",
    options: (props) => {
      const optionsValues = {
        variables: {
          inactive: false,
          _multidateField: "datestart",
          _multidate: "200",
        },
      };
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : "network-only";
      return optionsValues;
    },
  }),

  graphql(defQls.registration.List, {
    name: "registration",
    options: (props) => {
      const optionsValues = {}; // variables: {id: props.match.params.id } };
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : "network-only";
      optionsValues.variables = {
        inactive: false,
      };
      return optionsValues;
    },
  })
)(withRouterList);

const mapStateToProps = (state) => {
  return {
    myState: state,
  };
};

const ComponentFull = connect(mapStateToProps, {})(withGraphqlandRouter);

export default ComponentFull;
