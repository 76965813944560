import React from "react";
import { resolvePathObj, getInputValue } from "../../utils/commonutils";

const Comp = (props) => {
  return (
    <div style={{ paddingTop: "12px", alignItems: "top" }}>
      service input options
    </div>
  );
};

export default Comp;
