import React from "react";
import ShowIcon from "./ShowIcon";

const IconLoading = (props) => {
  const size = props.size || "40";
  return (
    <ShowIcon
      animatePath="1"
      icon="loadingcircle"
      color="?"
      viewBox="0 0 40 40"
      size={size}
      pathfill="#FF6700::#FF6700"
      opacity="0.3::1"
      animateFrom="0 20 20"
      animateTo="360 20 20"
      animateDur="1s"
      animateRepeatCount="indefinite"
    />
  );
};

export default IconLoading;
