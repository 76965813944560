
import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import { Field, FieldArray, reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';
import FormField  from '../FormField';
import FormArrayField from '../FormArrayField';

import { fieldsValidator, getOptions, processNewValues } from '../../utils/helper';
import {resolvePathObj, processValues, realTypeOf, tryParseJSON} from '../../utils/commonutils';

import { Tables } from '../../defTables';
import { normalizes } from '../../utils/normalizes';


const tableCrud = 'person';
let nameForm = `${tableCrud}Form`;
const validate = (values, props) => fieldsValidator('person', values, props, nameForm);

class Form extends Component {

  constructor(props) {
    super(props);

    


  }

  componentDidMount() {
   
   
   
    this.timerHandle = setTimeout(() => {
     
      this.props.dispatch ( change (nameForm, '_fakefield',  Math.random() ));
      this.props.executeCode ( 'onChangeInput', { nameForm, action: 'initForm' , props: this.props, formProps: this.props, formState: this.props.formState  });
    }, 1000);
    window.addEventListener('message', this.readMessage, true);
  }

  componentWillUnmount() {
    if (this.timerHandle) {
      clearTimeout(this.timerHandle);
      this.timerHandle = 0;
    }
    window.removeEventListener('message', this.readMessage, true);
  }

  readMessage = (event) => {
    if (!event.origin.includes(window.location.hostname)) return;
    if (event && event.data) {
      const params = tryParseJSON(event.data, {});
      if (params.popup) {
        params.inputFullName = params.popup;
        if (!params.formProps) params.formProps = this.props;
        this.props.executeCode('onChangeInput', params);
      }
  
    }
  };

  hocdebounced = debounce(( methodCode, params)=> {
  
    this.props.executeCode( methodCode, params);
  }, 1500);

  

  render() {
   
    const { error, handleSubmit, invalid, pristine, change, submitting, submitFailed, t, ...otherProps } = this.props;
    return (

      <form onSubmit={handleSubmit}>
        <div className="appBodyTitleSeparator" />
        
        <div className="formSection">
          <Field
            inputName="gendertype_id"
            name="gendertype_id"
            formProps={this.props}
            pathInTables="person.fields.gendertype_id"
            formState={this.props.formState}
            nameForm="personForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="selectAutocomplete"
            tableCrud={tableCrud}
            typeInput="selectAutocomplete"
          />
          <Field
            inputName="lastname"
            name="lastname"
            formProps={this.props}
            pathInTables="person.fields.lastname"
            formState={this.props.formState}
            nameForm="personForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            normalize={normalizes.UpperCase}
            typeInput="text"
            onChange={(event, newValue, previousValue) => {
              this.hocdebounced( 'onChangeInput', { nameForm, inputFullName: 'lastname',formProps: this.props, formState: this.props.formState, event, newValue, previousValue });
            }}
          />
          <Field
            inputName="firstname"
            name="firstname"
            formProps={this.props}
            pathInTables="person.fields.firstname"
            formState={this.props.formState}
            nameForm="personForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            normalize={normalizes.StartCase}
            typeInput="text"
            onChange={(event, newValue, previousValue) => {
              this.hocdebounced( 'onChangeInput', { nameForm, inputFullName: 'firstname',formProps: this.props, formState: this.props.formState, event, newValue, previousValue });
            }}
          />
          <Field
            inputName="email"
            name="email"
            formProps={this.props}
            pathInTables="person.fields.email"
            formState={this.props.formState}
            nameForm="personForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            normalize={normalizes.LowerCase}
            typeInput="text"
          />
          <Field
            inputName="nick"
            name="nick"
            formProps={this.props}
            pathInTables="person.fields.nick"
            formState={this.props.formState}
            nameForm="personForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            normalize={normalizes.LowerCaseTrim}
            typeInput="text"
            disabled={this.props.id ? true : false}
          />
          <Field
            inputName="password"
            name="password"
            formProps={this.props}
            pathInTables="person.fields.password"
            formState={this.props.formState}
            nameForm="personForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="password"
            autocomplete="new-password"
            typeInput="password"
          />
          <Field
            inputName="socialsn"
            name="socialsn"
            formProps={this.props}
            pathInTables="person.fields.socialsn"
            formState={this.props.formState}
            nameForm="personForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            typeInput="text"
          />
          <Field
            inputName="occupation"
            name="occupation"
            formProps={this.props}
            pathInTables="person.fields.occupation"
            formState={this.props.formState}
            nameForm="personForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            typeInput="text"
          />
          <Field
            inputName="imageright"
            name="imageright"
            formProps={this.props}
            pathInTables="person.fields.imageright"
            formState={this.props.formState}
            nameForm="personForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="selectBox"
            typeInput="selectBox"
            listSource="yesNoEmpty"
          />
          <Field
            inputName="inactive"
            name="inactive"
            formProps={this.props}
            pathInTables="person.fields.inactive"
            formState={this.props.formState}
            nameForm="personForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="checkbox"
            typeInput="checkbox"
          />
        {(resolvePathObj(this.props, 'myState.app.appSettings.settingsforms.customer_deceased', true)) && 
          <Field
            inputName="deceased"
            name="deceased"
            formProps={this.props}
            pathInTables="person.fields.deceased"
            formState={this.props.formState}
            nameForm="personForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="checkbox"
            typeInput="checkbox"
          />
 }
        </div>
        <div className="formSection">
          <Field
            inputName="birthdate"
            name="birthdate"
            formProps={this.props}
            pathInTables="person.fields.birthdate"
            formState={this.props.formState}
            nameForm="personForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            typeInput="text"
          />
        </div>
        <div className="formSection">
          <Field
            inputName="grouped_customer_id"
            name="grouped_customer_id"
            formProps={this.props}
            pathInTables="person.fields.grouped_customer_id"
            formState={this.props.formState}
            nameForm="personForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="selectAutocomplete"
            tableCrud={tableCrud}
            typeInput="selectAutocomplete"
          />
          <Field
            inputName="grouped_filiation"
            name="grouped_filiation"
            formProps={this.props}
            pathInTables="person.fields.grouped_filiation"
            formState={this.props.formState}
            nameForm="personForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="selectBox"
            typeInput="selectBox"
            listSource="filiation"
          />
          <Field
            inputName="birthplace"
            name="birthplace"
            formProps={this.props}
            pathInTables="person.fields.birthplace"
            formState={this.props.formState}
            nameForm="personForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            typeInput="text"
          />
          <Field
            inputName="birthcountry"
            name="birthcountry"
            formProps={this.props}
            pathInTables="person.fields.birthcountry"
            formState={this.props.formState}
            nameForm="personForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="selectAutocomplete"
            typeInput="selectAutocomplete"
            listSource="countries"
          />
          <Field
            inputName="nationality"
            name="nationality"
            formProps={this.props}
            pathInTables="person.fields.nationality"
            formState={this.props.formState}
            nameForm="personForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="selectAutocomplete"
            typeInput="selectAutocomplete"
            listSource="countries"
          />
          <Field
            inputName="residence"
            name="residence"
            formProps={this.props}
            pathInTables="person.fields.residence"
            formState={this.props.formState}
            nameForm="personForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="selectAutocomplete"
            typeInput="selectAutocomplete"
            listSource="countries"
          />
          <Field
            inputName="fullnameparent1"
            name="fullnameparent1"
            formProps={this.props}
            pathInTables="person.fields.fullnameparent1"
            formState={this.props.formState}
            nameForm="personForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            typeInput="text"
          />
          <Field
            inputName="fullnameparent2"
            name="fullnameparent2"
            formProps={this.props}
            pathInTables="person.fields.fullnameparent2"
            formState={this.props.formState}
            nameForm="personForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            typeInput="text"
          />
          <Field
            inputName="nameunmarried"
            name="nameunmarried"
            formProps={this.props}
            pathInTables="person.fields.nameunmarried"
            formState={this.props.formState}
            nameForm="personForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            typeInput="text"
          />
        </div>
        <div className="formSection">
          <FieldArray
            inputName="contacts"
            name="contacts"
            formProps={this.props}
            pathInTables="person.fields.contacts"
            formState={this.props.formState}
            nameForm="personForm"
            fieldParentKey="contacts"
            component={FormArrayField}
            tableCrud={tableCrud}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            t={t}
            mode={{} }
          />
        </div>
        <div className="formSection">
          <FieldArray
            inputName="phones"
            name="phones"
            formProps={this.props}
            pathInTables="person.fields.phones"
            formState={this.props.formState}
            nameForm="personForm"
            fieldParentKey="phones"
            component={FormArrayField}
            tableCrud={tableCrud}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            t={t}
            mode={{} }
          />
        </div>
        <div className="formSection">
          <Field
            inputName="groupedaddress_customer_id"
            name="groupedaddress_customer_id"
            formProps={this.props}
            pathInTables="person.fields.groupedaddress_customer_id"
            formState={this.props.formState}
            nameForm="personForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="selectAutocomplete"
            tableCrud={tableCrud}
            typeInput="selectAutocomplete"
          />
          <Field
            inputName="customeraddress_customers"
            name="customeraddress_customers"
            formProps={this.props}
            pathInTables="person.fields.customeraddress_customers"
            formState={this.props.formState}
            nameForm="personForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="hidden"
            typeInput="hidden"
          />
        </div>
        <div className="formSection">
          <FieldArray
            inputName="addresses"
            name="addresses"
            formProps={this.props}
            pathInTables="person.fields.addresses"
            formState={this.props.formState}
            nameForm="personForm"
            fieldParentKey="addresses"
            component={FormArrayField}
            tableCrud={tableCrud}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            t={t}
            mode={{} }
            labelChildsShow={true}
          />
        </div>
        <div className="formSection">
          <FieldArray
            inputName="identitydocs"
            name="identitydocs"
            formProps={this.props}
            pathInTables="person.fields.identitydocs"
            formState={this.props.formState}
            nameForm="personForm"
            fieldParentKey="identitydocs"
            component={FormArrayField}
            tableCrud={tableCrud}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            t={t}
            mode={{} }
            labelChildsShow={true}
          />
        </div>
          <Field
            inputName="notes"
            name="notes"
            formProps={this.props}
            pathInTables="person.fields.notes"
            formState={this.props.formState}
            nameForm="personForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="textarea"
            typeInput="textarea"
          />
          <Field
            inputName="organizer_id"
            name="organizer_id"
            formProps={this.props}
            pathInTables="person.fields.organizer_id"
            formState={this.props.formState}
            nameForm="personForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="selectAutocomplete"
            tableCrud={tableCrud}
            typeInput="selectAutocomplete"
            disabled={this.props.id ? true : false}
          />
        <Field
          inputName="_formstate"
          name="_formstate"
          typeInput="hidden"
          component={FormField}
          label=""
        />

        <div>
          {error && <strong>{error}</strong>}

          <div className="formError">
            {invalid && t('form.haserrors')}
          </div>

        </div>

      </form>
    );
  }
}


const ComponentWithData = reduxForm({
  form: nameForm,
  touchOnChange: true,
  
  enableReinitialize: true,
  validate,
})(Form);

function mapStateToProps(state, ownProps) {
 
 
  const log = false;
  const statesReturn = { myState: state };
  let initialValues;
  
  
  if (ownProps.formState.actionsave_origen === 'update' ||  ownProps.formState.actionsave_origen === 'duplicate' ) {
    
    if (ownProps.data) {

      initialValues = processValues(ownProps, tableCrud, ownProps.data, 'toClient', 'view');
      
      if (ownProps.formState.defaultValues && ownProps.formState.defaultValues.id) {
        initialValues.id = ownProps.formState.defaultValues.id;
      }
    }
  } else {
    initialValues = processNewValues(ownProps, tableCrud, ownProps.data, 'toClient','new' );
    if (ownProps.containerPropsForm.history && Object.keys(Tables[tableCrud].listFilters)) {
     
      const search = ownProps.containerPropsForm.history.location.search;
      const params = new URLSearchParams(search);
     
      Object.keys(Tables[tableCrud].listFilters.fields).map((fieldKey) => {
       
        if (log) console.log('fieldKey',fieldKey);
        let fieldFilter = Tables[tableCrud].listFilters.fields[fieldKey] ;
        let defaultValue, defaultValueName;
        if (ownProps.formState.defaultValues && ownProps.formState.defaultValues[fieldKey] ) {
         
          defaultValue = ownProps.formState.defaultValues[fieldKey];
        } else {
          defaultValue = params.get(fieldKey);
          defaultValueName = params.get(fieldKey.replace('_id','_name'));
        }
        if (defaultValue) {
          let initialVal;
          const isFieldArray = fieldFilter.fieldContainer && Tables[tableCrud].fields[fieldFilter.fieldContainer].subfields;
          if (fieldFilter.fieldSource
            && fieldFilter.fieldSource.typeInput === 'selectAutocomplete'
            && (fieldFilter.fieldSource.saveonly )
            && !defaultValue.id
            ) {
           

            initialVal = { id: defaultValue, name: defaultValueName };
          } else {
            initialVal = defaultValue;
          }
          if (isFieldArray) {
            fieldKey = fieldFilter.fieldContainer;
            if (!initialValues[fieldKey]) initialValues[fieldKey] = [ {id: ''} ];
            initialValues[fieldKey][0][fieldFilter.subfield] = initialVal;

          } else {
            initialValues[fieldKey] = initialVal;
          }
          if (log) console.log('initialValues[' + fieldKey+'] ', initialValues[fieldKey]);
        }
      });
    }
  }
  statesReturn.initialValues = initialValues ;
  
  return statesReturn;
}

const ComponentWithDataAndState = connect(
  mapStateToProps,
  null,
)(ComponentWithData);

export default ComponentWithDataAndState;
