import React from "react";
import {
  getInputValue,
  getRoomInfo,
  resolvePathObj,
  tt,
} from "../../utils/commonutils";
import { change } from "redux-form";
const crudCode = {};

crudCode.onChangeInput = async (params) => {
  // const log = true;
  const log = false;
  if (log) console.log("onChangeInput params:", params);
  const {
    nameForm,
    tableCrud,
    inputFullName,
    line,
    action,
    parentField,
    event,
    newValue,
    previousValue,
    props,
    formProps,
    formState,
    fns,
  } = params;

  /*
    for grandchild fields, to know the grandparent line; to see: props.lineParent
  */
  const result = {};

  // important define inside warningFields, these reset field on initForm (executed after save too)
  // if not warning fields will persist, because logic warnings enter only if is not initForm
  // different to payment crud when

  if (inputFullName === "seatingrangerange" || action === "initForm") {
    let newStates = {
      disabledFields: {},
      hiddenFields: {},
      warningFields: {},
      errors: {},
    };

    // realname grandparent
    const seatingconfiguration = getInputValue(
      formProps,
      "seatingconfiguration",
      { nameForm }
    );

    if (seatingconfiguration) {
      for (let a = 0; a < seatingconfiguration.length; a++) {
        if (seatingconfiguration[a].seatingrange) {
          let seatlistAllWagon = "";
          for (
            let b = 0;
            b < seatingconfiguration[a].seatingrange.length;
            b++
          ) {
            // the line for grand child is at the end always, it was already this logic
            const inputFullNameCtrl = `seatingconfiguration${a}seatingrangerange${b}`;

            let range;
            //  props.lineParent , in save action props is undefined
            if (
              inputFullName === "seatingrangerange" &&
              props &&
              a === props.lineParent &&
              b === line
            ) {
              range = newValue;
            } else {
              range = seatingconfiguration[a].seatingrange[b].range;
            }
            if (!range) {
              range = "";
            }
            // let currentSeatingrange = seatingconfiguration[props.lineParent].seatingrange[line].range;

            // produce seat list:
            let seatlist = ", ";
            let seatCount = 0;
            const rangeseparator = range.split(",");
            for (let r = 0; r < rangeseparator.length; r++) {
              const rangeblock = rangeseparator[r].split("-");

              if (
                rangeblock.length === 2 &&
                parseInt(rangeblock[0]) > 0 &&
                parseInt(rangeblock[1]) > 0 &&
                parseInt(rangeblock[1]) > parseInt(rangeblock[0])
              ) {
                for (
                  let b = parseInt(rangeblock[0]);
                  b <= parseInt(rangeblock[1]);
                  b++
                ) {
                  if (!seatlist.includes(`, ${b},`)) {
                    seatlist += b + ", ";
                    if (seatlistAllWagon.includes(`, ${b},`)) {
                      newStates.errors[
                        inputFullNameCtrl
                      ] = `mobilsemError.seatingduplicated::${b}`;
                    }
                    seatCount++;
                  }
                }
              } else {
                if (!seatlist.includes(`, ${rangeseparator[r]},`)) {
                  seatlist += rangeseparator[r] + ", ";
                  seatCount++;
                }
              }
            }
            seatlistAllWagon += seatlist;
            if (inputFullName === "seatingrangerange") {
              formProps.dispatch(
                change(
                  formProps.form,
                  `seatingconfiguration[${a}].seatingrange[${b}].seats`,
                  seatlist
                )
              );
            }

            newStates.warningFields[inputFullNameCtrl] = [];
            newStates.warningFields[inputFullNameCtrl].push(
              <div key="l1">
                {tt(formProps.t, "form.seatingtotal") + ": " + seatCount}
              </div>
            );
            // TWIN DLENGTH-3
            newStates.warningFields[inputFullNameCtrl].push(
              <div key="l2">
                {seatlist === ", "
                  ? ""
                  : seatlist.substr(1, seatlist.length - 3)}
              </div>
            );
            result.newStates = newStates;
          }
        }
      }
    }
  }

  return result;
};

export default crudCode;
