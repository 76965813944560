import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { appSetOrg } from '../actions/appActions';
import FormField from '../components/FormField';

class Form extends Component {

  executeCode = ( methodCode ='', params = {} ) => {
    //console.log('executeCode() methodCode:'+ methodCode + ', params:', params);
    let newOrg;
    if (params.newValue && params.newValue.id) {
      newOrg = params.newValue.id;
    } else {
      newOrg = null;
    }
    this.props.appSetOrg(newOrg); // distpacher hier
    this.props.myAppSettingsLoad(newOrg);
  };

  render() {
    let titleStyle = { marginTop: '3px', fontSize:'23px', color: '#666666', marginBottom: '10px'};

    let subtitleStyle = { marginTop: '15px', fontSize:'19px', color: '#474747', marginBottom: '10px'};

    const { error, pristine, invalid, submitFailed, submitting } = this.props;
    const { handleSubmit } = this.props;
    //console.log('props childe', this.props);
    return (
      <form onSubmit={handleSubmit}>
        <div key="registration" style={{ display: 'flex', alignItems: 'center',
    justifyContent: 'center', backgroundColor: '#f18f7f' }}>
           <Field
            inputName="organizer_id"
            name="organizer_id"
            formProps={this.props}
            formState={this.state}
            pathInTables="customer.fields.organizer_id"
            nameForm="formOrganizer"
            component={FormField}
            type="selectAutocomplete"
            typeInput="selectAutocomplete"
            executeCode={this.executeCode}
            onChange={(event, newValue, previousValue) => {
              this.executeCode( 'onChangeInput', { inputFullName: 'organizer_id',formProps: this.props, formState: this.state, event, newValue, previousValue })
            }}
          />
        </div>
      </form>
    );
  }
}


const ReduxedForm = reduxForm({
  form: 'formOrganizer',
  enableReinitialize: true, // the state is changed a lot of times so , need this for the last change on state when finally ql is loaded
})(Form);


function mapStateToProps(state, ownProps) {
  // to view data , need to pass the values to redux-form trough initialValues
  // from graphl data , not important to 'add' action, there no values
  const statesReturn = { myState: state };
  return statesReturn;
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ appSetOrg, dispatch }, dispatch);
};


const ComponentWithDataAndState = connect(
  mapStateToProps,
 mapDispatchToProps,
)(ReduxedForm);


export default ComponentWithDataAndState;
