import React from "react";
import { Link } from "react-router-dom";
import ShowIcon from "../icons/ShowIcon";

import { Tables } from "../../defTables";

const TourHeader = (props) => {
  //console.log(props);
  if (!props.view_tour || !props.view_tour.tour) {
    // if table have no realated table or users related table is not defined, then have no info about users action
    return null;
  }
  let record = props.view_tour.tour;

  //<div style={{ position: 'relative' }}>
  //formRowExpand
  //<label className="formLabel"></label>
  //<div className="formRow"> crea salto de lineas para el input ..???
  // <div className="formRowExpand"> expande en toda el ancho, no usar formRow como child
  return (
    <div>
      <div className="formSectionData">
        <div className="formData" style={{ fontSize: "20px" }}>
          {record.name}
        </div>

        <Link className="aToolBarExtend" to={`/tour-view/${record.id}`}>
          <ShowIcon size="30" color="gray" icon="eye" />
        </Link>

        <div className="formData" style={{ textAlign: "right" }}>
          {record.price && <span>{record.price}</span>}
        </div>
      </div>
      <div className="appBodyHeaderSeparator" />
    </div>
  );
};

export default TourHeader;
