import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { appSubmitStart, appSubmitStop } from "../actions/appActions";
import { showConfirm, hideConfirm } from "../actions/confirmActions";
import ShowIcon from "../components/icons/ShowIcon";
import IconLoading from "../components/icons/IconLoading";
import { Tables } from "../defTables";
import { submit } from "redux-form";
import FormField from "./FormField";
import {
  getOptions,
  resolveLink,
  getFieldsChanged,
  uuid,
  preSubmitToolBar,
} from "../utils/helper";
import { getSession } from "../utils/auth";
import { getPermission } from "../utils/commonutils";
//import { withCrudTool } from './hocCrudToolBar';
import { Button } from "../layouts/cssstyled";
import { getInputValue, tt, resolvePathObj } from "../utils/commonutils";

const iconSave = process.env.REACT_APP_NAME.includes("PeleAdmin")
  ? "saveblack"
  : "done";

export class CrudToolBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: false,
    };
  }

  changePageCtrl = (props, url, isFunction = false) => {
    if (isFunction) {
      this.props.toolbarFunctions(url, { t: props.t });
    } else {
      this.props.containerPropsForm.goUrl(url);
    }
  };

  handleClick = async (e) => {
    if (this.props.myState.app.submitting) {
      //ctrl submiting global , really neded, new block ctrl can reemplace this ?
      e.preventDefault();
      ////console.error('submit twice');
      return;
    }
    if (!this.state.clicked) {
      // TWIN CTR231 ctrl is not saving this
      this.setState({ clicked: true }, async () => {
        // just to disable double ctrl clic
        this.setState({ clicked: false });
      });
      // important preSubmit, because we need to show alerts popups with the errors
      //  otherwise redux form, show only the errors on the fields, and not popups
      //  only problem is that validation do twice .... %%% see if can be done only one time
      const resOk = await preSubmitToolBar(
        this.props.tableCrud,
        this.props.containerState.actionsave,
        this.props,
        null
      );
      if (resOk) {
        this.props.appSubmitStart();
        const result = await this.props.dispatch(
          submit(this.props.nameCrudForm)
        ); // modern way to submit
        // commented use because , logic dont detect 'this.props.myState.app.submitting'
        //   and then loading (pending saving ) is not showed, to do stop, on the validations
        // this.props.appSubmitStop();
      }
    }
  };

  closeWindow = () => {
    window.close();
  };

  render() {
    let log = false;
    const props = this.props;

    const t = this.props.t;
    let tableName = props.tableCrud;
    let table = Tables[tableName];

    const urlParams = new URLSearchParams(props.location.search);
    let popup = urlParams.get("popup"); // it will convert to inputFullName

    let session = getSession();
    // TWIN TAI231
    let permission = getPermission(
      session,
      tableName,
      props.containerState.actionsave
    );

    const required = (value) => (value ? undefined : "Required");
    const minLength = (min) => (value) =>
      value && value.length < min
        ? `Must be ${min} characters or more`
        : undefined;
    const minLength3 = minLength(3);

    let toolbars = [];

    let alertPermission = [];
    if (!permission.add)
      alertPermission.push(<span key="vadd">{t("info.vAdd")}</span>);
    if (!permission.update)
      alertPermission.push(<span key="vup"> {t("info.vUpdate")}</span>);
    // dont' needed for delete, it's evident when delete button is not showed
    //    and it's confus just to show only that alert, when the unique alert is for delete
    // if (!permission.delete) alertPermission.push (<span> {t('info.vDelete')}</span>);
    let groupedTool = [];
    if (!props.containerState.actionsave.includes("list")) {
      if (permission.save) {
        if (
          (!props.myState || !props.myState.app.submitting) &&
          // only show button save if there is not dissable for update or add state
          !(
            table.disabledUpdate && props.containerState.actionsave === "update"
          ) &&
          !(table.disabledAdd && props.containerState.actionsave === "add")
        ) {
          if (popup && isNaN(popup)) {
            // when is popup to add record (popup is not number)

            groupedTool.push(
              <div
                key="save2"
                style={{ position: "relative", marginRight: "20px" }}
              >
                <div className="iconInput">
                  {" "}
                  <ShowIcon icon={iconSave} color="#5995c3" />
                </div>
                <Button
                  contrast
                  style={{ paddingLeft: "35px" }}
                  onClick={(e) => this.handleClick(e)}
                >
                  {t("info.saveandexit")}
                </Button>
              </div>
            );
          } else {
            if (
              !(
                props.containerState.actionsave === "update" &&
                props.invisibleSaveBtnOnUpdate
              )
            ) {
              toolbars.push(
                <a
                  key="save1"
                  title={t("form.save")}
                  onClick={(e) => this.handleClick(e)}
                >
                  <ShowIcon size="35" color="#5995c3" icon={iconSave} />
                </a>
              );
            }
          }
        }
      }
      if (props.myState && props.myState.app.submitting) {
        // need to adapt loading icon for both modes, child windows, or normal
        /* a wrapper to get same size button save */
        if (popup && isNaN(popup)) {
          groupedTool.push(
            <a key="loading">
              <IconLoading size="35" />
            </a>
          );
        } else {
          toolbars.push(
            <a key="loading">
              <IconLoading size="35" />
            </a>
          );
        }
      }
    }
    if (popup && isNaN(popup)) {
      groupedTool.push(
        <div style={{ position: "relative" }}>
          <div className="iconInput">
            {" "}
            <ShowIcon icon="close" color="red" />
          </div>
          <Button
            contrast
            style={{ paddingLeft: "35px" }}
            onClick={() => this.closeWindow()}
          >
            {t("info.popupCloseNoSave")}
          </Button>
        </div>
      );
    }

    if (groupedTool.length > 0) {
      toolbars.push(
        <div
          key="grouped"
          style={{ display: "flex", justifyContent: "flex-start" }}
        >
          {groupedTool}
        </div>
      );
    }
    const propsWithParentState = { ...props, ...this.props.containerState };
    if (!props.containerState.actionsave.includes("list")) {
      /*toolbars.push(
        <Link key="list2" title={t('form.list')} to={resolveLink(table, props, 'list')}>
          <ShowIcon size="38" color="gray" icon="iclistblack" />
        </Link>);*/
      toolbars.push(
        <a
          onClick={() =>
            this.changePageCtrl(
              props,
              resolveLink(table, propsWithParentState, "list")
            )
          }
          key="list2"
        >
          <ShowIcon size="38" color="gray" icon="iclistblack" />
        </a>
      );
    }

    if (permission.add && !table.disabledAdd) {
      toolbars.push(
        <a
          onClick={() =>
            this.changePageCtrl(
              props,
              resolveLink(table, propsWithParentState, "add")
            )
          }
          key="add1"
          title={t("info.add") + " " + t("table." + tableName)}
        >
          <ShowIcon
            size="35"
            color="rgb(73, 132, 171)"
            icon="addcircleoutline"
          />
        </a>
      );
    }

    // action 'list' see 'ListData.js'
    //if (props.action === 'view') { // block twin L827
    if (table.toolbar) {
      //let toolbars = [];
      Object.keys(table.toolbar.menus).map((keyName, keyIndex) => {
        let menu = table.toolbar.menus[keyName];
        let add = false;
        if (
          (menu.inView && props.containerState.actionsave === "update") ||
          (menu.inListHeader && props.containerState.actionsave === "list")
        ) {
          add = true;
        }
        if (
          menu.module &&
          !resolvePathObj(
            this.props,
            "myState.app.appSettings.modules." + menu.module,
            true
          )
        ) {
          add = false;
        }
        if (log) console.log("menu", menu);
        let download = false;
        if (add) {
          let url = "";
          //console.log('menu',menu);
          if (keyName === "duplicate") {
            menu.icon = "duplicate";
            //url = `${window.location.pathname}?duplicate=1`;
          }
          if (menu.urlType === "filter") {
            //twin U3902
            if (log) console.log("have no url");
          }
          if (menu.urlType === "link") {
            //twin U3902
            url = menu.url;
            if (url.includes("{DOWN_URI}") || url.includes("http"))
              download = true;
            url = url.replace("{DOWN_URI}", process.env.REACT_APP_DOWN_URI);
            if (props.containerState.id)
              url = url.replace("{id}", props.containerState.id);
            if (props.containerState.parentid)
              url = url.replace("{parentid}", props.containerState.parentid);
            //props.id
            if (log) console.log("type link, url:", url);
          }

          if (menu.urlType === "child") {
            url = `/${props.tableCrud}-child/${props.containerState.id}/${keyName}`;
            if (log) console.log("type child, url:", url);
          }
          // have url, if not have url or toolbarFunctions will fail with a button without link
          let title = menu.label ? props.t(menu.label) : "";
          if (download) {
            toolbars.push(
              <a
                onClick={() => this.changePageCtrl(props, url)}
                key={keyName}
                title={title}
              >
                <ShowIcon size="35" color="gray" icon={menu.icon} />
              </a>
            );
          } else if (url) {
            if (log) console.log("menu url", url);
            toolbars.push(
              <a
                onClick={() => this.changePageCtrl(props, url)}
                className="aToolBarExtend"
                key={keyIndex}
                title={title}
              >
                <ShowIcon size="35" color="gray" icon={menu.icon} />
              </a>
            );
          } else if (props.toolbarFunctions) {
            if (log)
              console.log("is not url, but function:", props.toolbarFunctions);
            //console.log('tool '+keyName);

            toolbars.push(
              <a
                key={keyIndex}
                onClick={(e) => this.changePageCtrl(props, keyName, true)}
                title={title}
              >
                <ShowIcon size="35" color="gray" icon={menu.icon} />
              </a>
            );
          }
        }
      });
      //aColData.push( <div key="toolbar" className="cell appCrudBar"  >{toolbars}</div>)
    }
    //}

    if (
      permission.delete &&
      props.containerState.id &&
      !table.disabledDelete &&
      !props.invisibleDeleteBtn
    ) {
      let ableDelete = true;
      if (table.disabledDeleteWhen) {
        // twin OP920
        for (const [fieldKey, fieldCond] of Object.entries(
          table.disabledDeleteWhen
        )) {
          // import than this.props (have .myState),
          let fieldValue = getInputValue(this.props, fieldKey, {
            initial: true,
            nameForm: `${tableName}Form`,
          });
          for (const [key, value] of Object.entries(fieldCond)) {
            if (key === "$equal") {
              if (fieldValue === value) ableDelete = false;
            }
          }
        }
      }
      if (ableDelete) {
        toolbars.push(
          <a
            key="delete1"
            title={t("info.vDelete")}
            onClick={() => {
              //if(window.confirm( props.t('form.deleteconfirm'))) {this.props.handleDeleteRecord()};
              props.showConfirm({
                id: uuid(),
                type: "confirmation",
                text: props.t("form.deleteconfirm"),
                buttons: [
                  {
                    label: props.t("info.vDelete"),
                    onClick: () => this.props.handleDeleteRecord(),
                  },
                  {
                    label: props.t("form.no"),
                    onClick: () => null,
                  },
                ],
              });
            }}
          >
            <ShowIcon size="35" color="darkred" icon="deleteforever" />
          </a>
        );
      }
    }

    return (
      <React.Fragment>
        {popup && !isNaN(popup) && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ position: "relative" }}>
              <div className="iconInput">
                {" "}
                <ShowIcon icon="close" color="red" />
              </div>
              <Button
                contrast
                style={{ paddingLeft: "35px" }}
                onClick={() => this.closeWindow()}
              >
                {t("info.vClose")}
              </Button>
            </div>
          </div>
        )}

        <div className="pageHeader">
          <div className="appCrudBar">{toolbars}</div>
          {alertPermission.length > 0 && (
            <div style={{ color: "#7d4d59", fontSize: "15px" }}>
              <ShowIcon size="17" color="orange" icon="warning" />{" "}
              {t("info.alertPermission")} {alertPermission}.{" "}
              {t("info.permisask")}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}
//const withComponentReduxedForm  = withCrudTool(ComponentReduxedForm,formName );
const withComponentReduxedForm = withRouter(CrudToolBar);

const mapStateToProps = (state, ownProps) => {
  let initialValues = {};
  let stateToProps = {
    myState: state,
  };
  return stateToProps;
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { dispatch, appSubmitStart, appSubmitStop, showConfirm, hideConfirm },
    dispatch
  );
};

const ComponentWithDataAndState = connect(
  mapStateToProps,
  mapDispatchToProps
)(withComponentReduxedForm);

export default ComponentWithDataAndState;
