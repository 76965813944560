import React from "react";
import {
  resolvePathObj,
  getInputValue,
  sourcesOptions,
  getObjFromListById,
} from "../../utils/commonutils";

const Comp = (props) => {
  const { t } = props;
  const fieldValue = resolvePathObj(
    props,
    "formProps.containerPropsForm.crud_view_customer.customer.customerperson_id.identitydocs",
    { notFound: null }
  );
  if (!fieldValue) {
    return null;
  }
  const resultField = [];
  let oLines;
  try {
    oLines = JSON.parse(fieldValue);
    if (oLines.length === 0) {
      resultField.push(<div>{props.t("login.errorEmptyField")}</div>);
    }
  } catch (err) {
    return null;
  }

  if (!fieldValue) {
    return null;
  }

  oLines.map((line, key) => {
    resultField.push(
      <div key={"add1" + key}>
        {t(
          "identitydocsType." +
            getObjFromListById(
              sourcesOptions.identitydocsType,
              line.type.id,
              null
            ).name
        )}
        : &nbsp;
        {line.number}&nbsp;&nbsp;
        {t("form.date")}: {line.ididate}&nbsp;&nbsp;
        {t("form.idexpiration")}: {line.idexpiration}&nbsp;&nbsp;
        {getObjFromListById(sourcesOptions.countries, line.country).name}
        {line.idaddressisother && (
          <div>
            {t("form.address")}: {line.addressline1}&nbsp;{line.addressline2}
            &nbsp;{line.addressline3}&nbsp;{line.city}
          </div>
        )}
        {line.idnameisother && (
          <div>
            {t("form.lastname")}: {line.lastname}&nbsp;{t("form.firstname")}:{" "}
            {line.firstname}
          </div>
        )}
      </div>
    );
  });

  return (
    <div className="linkedContainer" style={{ marginLeft: "2px" }}>
      <div>{resultField}</div>
    </div>
  );
};

export default Comp;
