import React from "react";
import { Field, reduxForm } from "redux-form";
import { Button } from "../../layouts/cssstyled";
import FormField from "../FormField";
import { fieldsValidator } from "../../utils/helper";
import ShowIcon from "../icons/ShowIcon";
import { normalizes } from "../../utils/normalizes";
import bowser from "bowser";
const fields = {
  nick: {
    validators: ["required", "alphaUSNumeric", "minLength:3"],
  },
  password: {
    validators: ["required", "alphaUSNumeric", "minLength:3"],
  },
};
const nameForm = "signin";
const validate = (values, props) =>
  fieldsValidator(fields, values, props, nameForm);
/*
icon is not ok position left: style={{  position: 'relative', paddingLeft: '35px' }}
 */
const Form = (props) => {
  // //console.log('sign in form props');
  // //console.log(props);

  const {
    error,
    handleSubmit,
    pristine,
    invalid,
    reset,
    submitting,
    t,
    changeLanguage,
    i18n,
  } = props;

  let browserCompatible = true;
  let browserVersionCompatible = true;
  let browserMsgVersionOk = <ShowIcon size="18" color="green" icon="done" />;
  let browserMsgOk = <ShowIcon size="18" color="green" icon="done" />;
  let browserVersion = (
    <span style={{ color: "#666666" }}>
      {t("info.version")}: {bowser.version}
    </span>
  );
  let browserMsgWarning = <span></span>;
  if (
    bowser.name === "Chrome" ||
    bowser.name === "Firefox" ||
    bowser.name === "Safari"
  ) {
    browserCompatible = true;
  } else if (bowser.name === "Internet Explorer") {
    browserCompatible = false;
  } else {
    browserCompatible = false;
  }

  if (!browserCompatible) {
    browserMsgOk = (
      <span>
        <ShowIcon size="18" color="darkred" icon="clear" />
      </span>
    );
    browserMsgWarning = <span> {t("info.browserError")}</span>;
  }

  if (bowser.name === "Firefox") {
    if (bowser.version < 57) {
      browserVersionCompatible = false;
      browserMsgVersionOk = (
        <span>
          <ShowIcon size="18" color="darkred" icon="clear" />
          <br />
          {t("info.browserVersionError")} 57
        </span>
      );
    }
  } else if (bowser.name === "Chrome") {
    if (bowser.version < 62) {
      browserMsgVersionOk = (
        <span>
          <ShowIcon size="18" color="darkred" icon="clear" />
          <br />
          {t("info.browserVersionError")} 62
        </span>
      );
      browserVersionCompatible = false;
    }
  } else if (bowser.name === "Safari") {
    if (bowser.version < 11) {
      browserMsgVersionOk = (
        <span>
          <ShowIcon size="18" color="darkred" icon="clear" />
          <br />
          {t("info.browserVersionError")} 11
        </span>
      );
      browserVersionCompatible = false;
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div style={{ width: "300px", margin: "auto" }}>
        <Field
          name="nick"
          icon="account"
          iconcolor="orange"
          animatePath="*"
          type="text"
          component={FormField}
          label="login.usercode::test"
          formRowExpand="1"
          autocomplete="on"
          normalize={normalizes.LowerCase}
        />
        <Field
          name="password"
          icon="vpnkey"
          iconcolor="orange"
          animatePath="*"
          typeInput="password"
          component={FormField}
          label="login.password"
          autocomplete="on"
          formRowExpand="1"
        />

        {browserCompatible && browserVersionCompatible && (
          <div style={{ position: "relative", margin: "auto" }}>
            <Button type="submit" disabled={submitting}>
              {t("login.signin")}
            </Button>
          </div>
        )}

        <div key="sep2" style={{ height: "20px" }}></div>

        <div key="adv">
          <a
            style={{ color: "#1464b0" }}
            rel="nofollow"
            target="_blank"
            href="https://www.pelemobil.com/cgupeleadmin.html"
          >
            {t("info.cgu")}
          </a>
        </div>
      </div>

      <div key="cond" style={{ marginBottom: "10px", marginTop: "10px" }}>
        <a
          target="_blank"
          href="https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit?usp=sharing"
        >
          <ShowIcon
            verticalAlign="middle"
            icon="help"
            size="30"
            color="#C59E2F"
          />
          <span style={{ verticalAlign: "middle" }}>
            {props.t("info.help")}
          </span>
        </a>
      </div>

      <div style={{ height: "20px" }}>&nbsp;</div>
      <div>
        <div>
          {t("info.browser")}:{" "}
          <span style={{ color: "#666666" }}>{bowser.name}</span>
          {browserMsgOk}
        </div>
        {browserCompatible && (
          <div>
            {t("info.version")}:{" "}
            <span style={{ color: "#666666" }}>{bowser.version}</span>
            {browserMsgVersionOk}
          </div>
        )}
        <div>{browserMsgWarning}</div>
        <div>{!browserCompatible && t("info.browsersMsgCompatible")}</div>
      </div>
    </form>
  );
};

export default reduxForm({
  form: nameForm,
  validate,
})(Form);
