import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { graphql, compose } from "react-apollo";
import { loader } from "graphql.macro";
import debounce from "lodash/debounce";
import { gqlActions } from "../../gqls/gqlactions";
import { submit, change, touch, initialize } from "redux-form";

import { Tables } from "../../defTables";
import { fetchQl } from "../../apolloClient";
import defQls from "../../defQls";
import {
  processError,
  errorTranslate,
  checkLoadCrud,
  cleanFilters,
  getFilters,
  preSubmitValidation,
  submitValues,
  deleteRecord,
  uuid,
  getRecordFromOptionsIndirectOnFilter,
  resolveLink,
  getListviewFields,
} from "../../utils/helper";

import Form from "./TourSettingsForm";
import {
  appSubmitStart,
  appSubmitStop,
  appSettingsLoad,
} from "../../actions/appActions";
import { showConfirm, hideConfirm } from "../../actions/confirmActions";

import PageHeader from "../PageHeader";

import IconLoading from "../icons/IconLoading";
import { MsgError } from "../ListData";
import { getInputValue, tt, getObjFromListById } from "../../utils/commonutils";

import RecordInfo from "../RecordInfo";
import RecordHeadInfo from "../RecordHeadInfo";

const crudCode = {};

const templates = [];

const tableCrud = "tourprice";
const action = "view";
const nameForm = tableCrud + "Form";
const formFilterName = "listFiltertour";

class FormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actionsave: "update",
      actionsave_origen: "update",
      crudAction: "View",
      crudTable: tableCrud,
      mainForm: nameForm,
      id: this.props.match.params.id || "",
      dialogOpened: {},
      parentid:
        this.props.match.params && this.props.match.params.parentid
          ? this.props.match.params.parentid
          : "",
      disabledFields: [],
      hiddenFields: [],
      warningFields: {},
    };
  }

  handleSubmit = async (values) => {
    try {
      if (
        this.state.warningFields &&
        this.state.warningFields._submit &&
        this.state.warningFields._submit.length
      ) {
        const validation = await preSubmitValidation(
          tableCrud,
          this.state.actionsave,
          this.props,
          this.syncCode,
          values,
          crudCode
        );
        if (!validation) return false;
        this.props.showConfirm({
          id: uuid(),
          type: "confirmation",
          text: [
            ...this.state.warningFields._submit,
            <div key="usure">{this.props.t("form.uSureSave")}</div>,
          ],
          buttons: [
            {
              label: this.props.t("form.yes"),
              onClick: async () => await this.handleSubmitOk(values),
            },
            {
              label: this.props.t("form.no"),
              onClick: () => null,
            },
          ],
        });
      } else {
        return await this.handleSubmitOk(values);
      }
    } catch (err) {
      throw err;
    }
  };

  handleSubmitOk = async (values) => {
    try {
      let newValues = values;
      newValues.tour_id = this.props.match.params.id;
      gqlActions("", "setTourCustomerStatusAndServices", this.props, {
        settingsTourCustomerStatus: JSON.stringify(newValues),
      })
        .then((response) => {})
        .catch((err) => {
          processError(err, this.props, true);
        });
    } catch (err) {}
  };

  toolbarFunctions = (toolbar, params = {}) => {
    if (toolbar === "duplicate") {
      const listFields = this.props.goUrl(null, { justCheck: true });

      let text = this.props.t("form.uSureDuplicate");
      if (listFields) {
        text += "\n\n" + tt(this.props.t, "form.goWithoutSave::" + listFields);
      }
      this.props.showConfirm({
        id: uuid(),
        type: "confirmation",
        text,
        buttons: [
          {
            label: this.props.t("form.yes"),
            onClick: () =>
              this.setState(
                {
                  actionsave: "add",
                  actionsave_origen: "duplicate",
                  defaultValues: { id: uuid() },
                },

                () => {
                  for (const [inputName, value] of Object.entries(
                    Tables[tableCrud].fields
                  )) {
                    if (value.onDebounce) {
                      const newValue = getInputValue(this.props, inputName, {
                        nameForm,
                      });
                      this.executeCode("onChangeInput", {
                        nameForm,
                        inputFullName: inputName,
                        formProps: this.props,
                        formState: this.state,
                        event: null,
                        newValue,
                      });
                    }
                  }
                }
              ),
          },
          {
            label: this.props.t("form.no"),
            onClick: () => null,
          },
        ],
      });
    }
  };

  hocdebounced = debounce((methodCode, params) => {
    this.executeCode(methodCode, params);
  }, 1500);

  executeCode = async (methodCode = "", params = {}) => {
    params.tableCrud = tableCrud;
    params.formState = this.state;

    if (!crudCode[methodCode]) {
      return;
    }

    const result = await crudCode[methodCode](params);

    if (result && result.changeFieldsLater) {
      Object.keys(result.changeFieldsLater).map((keyName, keyIndex) => {
        let valueField = result.changeFieldsLater[keyName];
        params.formProps.change(keyName, valueField);
      });
    }

    if (result && result.newStates) {
      let formStateChanged = false;
      let currentState = this.state;
      Object.keys(result.newStates).map((keyName, keyIndex) => {
        if (
          typeof this.state[keyName] === "undefined" ||
          JSON.stringify(this.state[keyName]) !==
            JSON.stringify(result.newStates[keyName])
        ) {
          let keyNewState = result.newStates[keyName];

          if (keyName === "warningFields") {
            keyNewState = {
              ...this.state[keyName],
              ...result.newStates[keyName],
            };
          }
          this.setState({ [keyName]: keyNewState });
          currentState[keyName] = result.newStates[keyName];
          formStateChanged = true;
        }
      });
      if (formStateChanged) {
        this.props.dispatch(
          change(nameForm, "_formstate", JSON.stringify(currentState))
        );
      }
    }

    if (result && typeof result.valueToReturn !== "undefined") {
      return result.valueToReturn;
    }
  };

  handleDeleteRecord = () => {
    deleteRecord(tableCrud, this.props);
  };

  syncCode = (method, params = {}) => {
    let valueToReturn;

    if (typeof valueToReturn !== "undefined") return valueToReturn;
  };

  render() {
    const { t, ...otherProps } = this.props;
    let aElements = [];
    let aQlFiltered = {
      crud_view_tour: { table: "tour" },
      getTourServices: true,
      getTourCustomerStatusAndServices: true,
      list_tourroom: { table: "tourroom" },
    };

    const resultCheck = checkLoadCrud(aQlFiltered, this.props);
    if (resultCheck.messageError) {
      return <MsgError msg={resultCheck.messageError} t={this.props.t} />;
    }
    aElements.push(
      <PageHeader
        key="pageheader"
        action={action}
        t={t}
        tableCrud={tableCrud}
        tables={Tables}
        handleSubmit={this.handleSubmit}
        handleDeleteRecord={this.handleDeleteRecord}
        id={this.props.match.params.id}
      />
    );
    if (!resultCheck.globalLoading && !resultCheck.messageError) {
      if (
        this.props["crud_view_" + tableCrud] &&
        this.props["crud_view_" + tableCrud][tableCrud]
      ) {
        if (this.props["crud_view_" + tableCrud][tableCrud].deleted) {
          aElements.push(
            <RecordHeadInfo
              key="recordhead"
              tableCrud={tableCrud}
              {...this.props}
              data={this.props["crud_view_" + tableCrud][tableCrud]}
            />
          );
        }
      }
    }

    if (resultCheck.globalLoading && !resultCheck.messageError) {
      aElements.push(
        <div key="titleandloading">
          <div className="appBodyTitleSeparator" />
          <div
            className="animated-background"
            style={{ width: "100%", height: "300px" }}
          >
            <IconLoading key="icon" />
          </div>
        </div>
      );
    }
    if (this.state.actionsave === "add") {
      aElements.push(
        <div key="addlegend" style={{ color: "darkred" }}>
          {t("info." + this.state.actionsave_origen)}
        </div>
      );
    }
    if (!resultCheck.globalLoading && !resultCheck.messageError) {
      aElements.push(
        <Form
          executeCode={this.executeCode}
          syncCode={this.syncCode}
          toolbarFunctions={this.toolbarFunctions}
          crudAction="View"
          key="mainform"
          id={this.props.match.params.id}
          data={{}}
          onSubmit={this.handleSubmit}
          containerPropsForm={this.props}
          formState={this.state}
          t={this.props.t}
        />
      );
    }

    return <div>{aElements}</div>;
  }
}

const withGraphqlandRouter = compose(
  graphql(defQls.tour.View, {
    name: "crud_view_tour",
    options: (props) => {
      const optionsValues = {
        variables: { id: props.match.params.id, _qlType: "View" },
      };
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : "network-only";
      return optionsValues;
    },
  }),
  graphql(defQls.tourroom.ListMini, {
    name: "list_tourroom",
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : "network-only";
      optionsValues.variables = {
        tour_id: props.match.params.id,
        _qlType: "ListMini-price-", // TWIN PR1413
        _orders: "hotel_id",
      };
      return optionsValues;
    },
  }),

  graphql(defQls.getTourCustomerStatusAndServices, {
    name: "getTourCustomerStatusAndServices",
    options: (props) => {
      const optionsValues = {
        variables: {
          tour_id: props.match.params.id,
          _qlType: "ListMini-price-", // TWIN PR1413
        },
      };
      optionsValues.fetchPolicy = "network-only";
      return optionsValues;
    },
  }),
  graphql(defQls.getTourServices, {
    name: "getTourServices",
    options: (props) => {
      const optionsValues = {
        variables: { tour_id: props.match.params.id },
      };
      optionsValues.fetchPolicy = "network-only";
      return optionsValues;
    },
  }),
  graphql(defQls.tourcategory.ListMini, {
    name: "list_tourcategory",
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : "network-only";
      optionsValues.variables = { _qlType: "ListMini" };
      return optionsValues;
    },
  }),
  graphql(defQls.aplan.ListMini, {
    name: "list_aplan",
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : "network-only";
      optionsValues.variables = { _qlType: "ListMini" };
      return optionsValues;
    },
  }),
  graphql(defQls.acostplan.ListMini, {
    name: "list_acostplan",
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : "network-only";
      optionsValues.variables = { _qlType: "ListMini" };
      return optionsValues;
    },
  }),
  graphql(defQls.setTourCustomerStatusAndServices, {
    name: "setTourCustomerStatusAndServices",
    options: (props) => {
      const optionsValues = {
        variables: {
          tour_id: props.match.params.id,
        },
      };
      optionsValues.fetchPolicy = "network-only";
      return optionsValues;
    },
  })
)(withRouter(FormContainer));

const mapStateToProps = (state) => {
  return {
    myState: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      appSubmitStart,
      appSubmitStop,
      showConfirm,
      initialize,
      change,
      touch,
      dispatch,
    },
    dispatch
  );
};

const withState = connect(
  mapStateToProps,
  mapDispatchToProps
)(withGraphqlandRouter);

const ComponentFull = withState;

export default ComponentFull;
