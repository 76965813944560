import React from "react";
import {
  resolvePathObj,
  getInputValue,
  sourcesOptions,
  getObjFromListById,
} from "../../utils/commonutils";

const Comp = (props) => {
  const { t } = props;
  const fieldValue = resolvePathObj(
    props,
    "formProps.containerPropsForm.crud_view_customer.customer.customerperson_id.name",
    { notFound: null }
  );
  if (!fieldValue) {
    return null;
  }

  const recPerson =
    props.formProps.containerPropsForm.crud_view_customer.customer
      .customerperson_id;

  const resultField = [];
  resultField.push(
    <div key={"add1"} style={{ paddingBottom: "7px" }}>
      {recPerson.name}&nbsp;&nbsp; {recPerson.birthdate}&nbsp;&nbsp;
      {recPerson.email}&nbsp;&nbsp;
      {recPerson.mobile}
    </div>
  );

  resultField.push(
    <div key={"add2"}>
      {t("form.residence")}:{" "}
      {getObjFromListById(sourcesOptions.countries, recPerson.residence).name}
      &nbsp;&nbsp;{t("form.nationality")}:
      {getObjFromListById(sourcesOptions.countries, recPerson.nationality).name}
    </div>
  );

  return (
    <div className="linkedContainer" style={{ marginLeft: "2px" }}>
      <div>{resultField}</div>
    </div>
  );
};

export default Comp;
