import React from "react";
import { Field, reduxForm, change } from "redux-form";
import { bindActionCreators } from "redux";

import { connect } from "react-redux";
import { resolvePathObj, getInputValue, getSMS } from "../../utils/commonutils";
import { appSubmitStart, appSubmitStop } from "../../actions/appActions";
import { showConfirm } from "../../actions/confirmActions";
import ShowIcon from "../icons/ShowIcon";

const nameForm = "MemberAddress";
class SubComponentInForm extends React.Component {
  constructor(props) {
    super(props);
  }

  executeCode = async (methodCode = "", params = {}) => {};

  shouldComponentUpdate(nextProps, nextState) {
    return (
      JSON.stringify(this.props) !== JSON.stringify(nextProps) ||
      JSON.stringify(this.state) !== JSON.stringify(nextState)
    );
  }

  render() {
    let getOrgSettings = resolvePathObj(
      this.props,
      "containerPropsForm.getOrgSettings.getOrgSettings.record"
    );
    let customersrecipients = getInputValue(this.props, "customersrecipients", {
      nameForm: "ejobmailForm",
    });
    if (getOrgSettings) {
      getOrgSettings = JSON.parse(getOrgSettings);
    }

    if (!customersrecipients || customersrecipients.length === 0) {
      // dont show if settings is not loaded
      return null;
    }

    const channelEmail = resolvePathObj(
      this.props,
      "myState.app.appSettings.modules.EMAIL",
      { notFound: null }
    );
    const channelSMS = resolvePathObj(
      this.props,
      "myState.app.appSettings.modules.SMS",
      { notFound: null }
    );

    const channelsfilters = getInputValue(this.props, "channelsfilters", {
      nameForm: "ejobmailForm",
    }).toString();
    let contentsms = getInputValue(this.props, "sms", {
      nameForm: "ejobmailForm",
    });

    let emailToSend = 0;
    let smsToSend = 0;
    let smsPerMessage = 1;
    let docToSend = 0;

    try {
      if (contentsms) {
        smsPerMessage = getSMS(contentsms).qtysms;
      }
      customersrecipients.map((customer) => {
        if (channelsfilters === "5") {
          if (customer.email) {
            emailToSend++;
          } else if (customer.mobile) {
            smsToSend++;
          } else {
            docToSend++;
          }
        }
        if (channelsfilters === "7") {
          if (customer.email) {
            emailToSend++;
          } else {
            docToSend++;
          }
        }
        if (channelsfilters === "10") {
          if (customer.email) {
            emailToSend++;
          }
          if (customer.mobile) {
            smsToSend++;
          }
        }
        if (channelsfilters === "20") {
          if (customer.mobile) {
            smsToSend++;
          }
        }
        if (channelsfilters === "30") {
          if (customer.email) emailToSend++;
        }
        if (channelsfilters === "40") {
          docToSend++;
        }
      });
    } catch (err) {
      //console.log(err);
    }

    // TWIN MSG0391
    let email =
      resolvePathObj(this.props, "myState.app.appSettings.email", {
        notFound: null,
      }) || "";
    if (!this.props.myState.app.appSettings) return null; //settings  not loaded yet
    /*if (email && channelEmail && channelSMS && !this.props.always ) {
        return null; // all is ok
    }*/
    // {email}
    let messagingsActive = [];
    let limitemailState = "";
    let limitsmsState = "";
    let total = {
      email: getOrgSettings.mailing.qty,
      sms: getOrgSettings.mailing.qtysms,
      emailToSend,
      docToSend,
      smsToSend: smsToSend * smsPerMessage,
      smsToSendString: smsToSend > 0 ? `${smsToSend}  * ${smsPerMessage}` : 0,
    };

    let newtotal = {
      email: getOrgSettings.mailing.qty + emailToSend,
      sms: getOrgSettings.mailing.qtysms + total.smsToSend,
      prixemail: 0,
      prixsms: (total.smsToSend * getOrgSettings.prixsms).toFixed(2),
    };
    if (newtotal.email > getOrgSettings.limitemail)
      limitemailState = "* Limit depassé";
    if (newtotal.sms > getOrgSettings.limitsms)
      limitsmsState = "* Limit depassé";
    const limitexceeded = limitemailState || limitsmsState ? "" : "OK";

    // field for validation total messages
    let totalMessaging = emailToSend + docToSend + total.smsToSend;

    setTimeout(() => {
      // need max 1 second, too much short not good for render
      this.props.dispatch(
        change("ejobmailForm", "limitexceeded", limitexceeded)
      );
      this.props.dispatch(
        change("ejobmailForm", "totalMessaging", totalMessaging)
      );
    }, 1000);

    messagingsActive.push(
      <div
        key="resume"
        style={{
          width: "200px",
          display: "flex",
          color: "#555555",
          justifyContent: "space-between",
        }}
      >
        <div>
          <span style={{ fontWeight: "bold" }}>
            {this.props.t("form.email")}:{" "}
          </span>
        </div>
        <div>{total.emailToSend}</div>
        <div>
          <span style={{ fontWeight: "bold" }}>
            {this.props.t("form.pdf")}:{" "}
          </span>
        </div>
        <div>{total.docToSend}</div>
      </div>
    );

    //        <div style={{fontSize: '15px', marginTop: '5px', color: '#555555' }}>{this.props.t('info.enabledMessaging')} </div>

    return (
      <div style={{ marginTop: "0px", marginBottom: "7px" }}>
        <div style={{ marginTop: "2px" }}>{messagingsActive}</div>
      </div>
    );
  }
}
//

function mapStateToProps(state, ownProps) {
  const initialValues = {};
  const statesReturn = { myState: state, initialValues };
  return statesReturn;
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { appSubmitStart, appSubmitStop, showConfirm, dispatch, change },
    dispatch
  );
};

const ComponentWithDataAndState = connect(
  mapStateToProps,
  mapDispatchToProps
)(SubComponentInForm);

export default ComponentWithDataAndState;
