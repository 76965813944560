import React from "react";
import { Link } from "react-router-dom";
import ShowIcon from "../icons/ShowIcon";

import { Tables } from "../../defTables";

const RegistrationHistoryHeader = (props) => {
  if (!props.view_registration || !props.view_registration.registration) {
    // if table have no realated table or users related table is not defined, then have no info about users action
    return null;
  }
  let record = props.view_registration.registration;

  return (
    <div>
      <div>
        <div style={{ fontSize: "20px" }}>
          {props.t("table.customer")}: {record.registrationcustomer_id.name}
        </div>
        <div style={{ fontSize: "20px" }}>
          {props.t("table.tour")}: {record.registrationtour_id.name}
        </div>
        <div>
          {props.t("table.registrationstatus")}:{" "}
          {record.registrationregistrationstatus_id && (
            <span>{record.registrationregistrationstatus_id.name}</span>
          )}
        </div>
        <Link className="aToolBarExtend" to={`/registration-view/${record.id}`}>
          <ShowIcon size="40" color="gray" icon="eye" />
        </Link>
      </div>
    </div>
  );
};

export default RegistrationHistoryHeader;
