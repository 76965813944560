
import React, { Component } from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom';

import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { reducer as formReducer } from 'redux-form';
import createSagaMiddleware from 'redux-saga';
import { getSession } from './utils/auth';

import { Provider } from 'react-redux';
import { ApolloProvider } from 'react-apollo';

import { resolvePathObj, processValues } from './utils/commonutils';
import ErrorBoundary from './layouts/ErrorBoundary';
import createClient from './apolloClient';
import notifReducer from './reducers/notifReducers';
import confirmReducer from './reducers/confirmReducer';
import rootSaga from './reducers/notifSagas';
import authReducer from './reducers/authReducer';
import appReducer from './reducers/appReducer';
import SettingsLoad from './SettingsLoad';
import './App.css';
import LayoutContainer from './layouts/LayoutContainer';


import OrganizerAdd from './components/organizer/OrganizerAdd';
import OrganizerList from './components/organizer/OrganizerList';
import OrganizerView from './components/organizer/OrganizerView';
import PersonAdd from './components/person/PersonAdd';
import PersonList from './components/person/PersonList';
import PersonView from './components/person/PersonView';
import CustomerAdd from './components/customer/CustomerAdd';
import CustomerList from './components/customer/CustomerList';
import CustomerView from './components/customer/CustomerView';
import TourAdd from './components/tour/TourAdd';
import TourList from './components/tour/TourList';
import TourView from './components/tour/TourView';
import TourcategoryAdd from './components/tourcategory/TourcategoryAdd';
import TourcategoryList from './components/tourcategory/TourcategoryList';
import TourcategoryView from './components/tourcategory/TourcategoryView';
import TransportationAdd from './components/transportation/TransportationAdd';
import TransportationList from './components/transportation/TransportationList';
import TransportationView from './components/transportation/TransportationView';
import TourpriceAdd from './components/tourprice/TourpriceAdd';
import TourpriceList from './components/tourprice/TourpriceList';
import TourpriceView from './components/tourprice/TourpriceView';
import TourroomAdd from './components/tourroom/TourroomAdd';
import TourroomList from './components/tourroom/TourroomList';
import TourroomView from './components/tourroom/TourroomView';
import RegonlineList from './components/regonline/RegonlineList';
import RegonlineView from './components/regonline/RegonlineView';
import RegistrationAdd from './components/registration/RegistrationAdd';
import RegistrationList from './components/registration/RegistrationList';
import RegistrationView from './components/registration/RegistrationView';
import CustomerhistoryList from './components/customerhistory/CustomerhistoryList';
import PaymenthistoryList from './components/paymenthistory/PaymenthistoryList';
import RegistrationhistoryList from './components/registrationhistory/RegistrationhistoryList';
import PaymentAdd from './components/payment/PaymentAdd';
import PaymentList from './components/payment/PaymentList';
import PaymentView from './components/payment/PaymentView';
import CheckdepositheadAdd from './components/checkdeposithead/CheckdepositheadAdd';
import CheckdepositheadList from './components/checkdeposithead/CheckdepositheadList';
import CheckdepositheadView from './components/checkdeposithead/CheckdepositheadView';
import CheckdepositdetList from './components/checkdepositdet/CheckdepositdetList';
import ExportAdd from './components/export/ExportAdd';
import ExportList from './components/export/ExportList';
import ExportView from './components/export/ExportView';
import HotelAdd from './components/hotel/HotelAdd';
import HotelList from './components/hotel/HotelList';
import HotelView from './components/hotel/HotelView';
import StopspointAdd from './components/stopspoint/StopspointAdd';
import StopspointList from './components/stopspoint/StopspointList';
import StopspointView from './components/stopspoint/StopspointView';
import ServiceAdd from './components/service/ServiceAdd';
import ServiceList from './components/service/ServiceList';
import ServiceView from './components/service/ServiceView';
import CityAdd from './components/city/CityAdd';
import CityList from './components/city/CityList';
import CityView from './components/city/CityView';
import RegistrationstatusAdd from './components/registrationstatus/RegistrationstatusAdd';
import RegistrationstatusList from './components/registrationstatus/RegistrationstatusList';
import RegistrationstatusView from './components/registrationstatus/RegistrationstatusView';
import ConfirmationstatusAdd from './components/confirmationstatus/ConfirmationstatusAdd';
import ConfirmationstatusList from './components/confirmationstatus/ConfirmationstatusList';
import ConfirmationstatusView from './components/confirmationstatus/ConfirmationstatusView';
import BankAdd from './components/bank/BankAdd';
import BankList from './components/bank/BankList';
import BankView from './components/bank/BankView';
import PaymentmethodAdd from './components/paymentmethod/PaymentmethodAdd';
import PaymentmethodList from './components/paymentmethod/PaymentmethodList';
import PaymentmethodView from './components/paymentmethod/PaymentmethodView';
import PaymentdocstatusAdd from './components/paymentdocstatus/PaymentdocstatusAdd';
import PaymentdocstatusList from './components/paymentdocstatus/PaymentdocstatusList';
import PaymentdocstatusView from './components/paymentdocstatus/PaymentdocstatusView';
import CustomerstatusAdd from './components/customerstatus/CustomerstatusAdd';
import CustomerstatusList from './components/customerstatus/CustomerstatusList';
import CustomerstatusView from './components/customerstatus/CustomerstatusView';
import GendertypeAdd from './components/gendertype/GendertypeAdd';
import GendertypeList from './components/gendertype/GendertypeList';
import GendertypeView from './components/gendertype/GendertypeView';
import UserlogList from './components/userlog/UserlogList';
import UserlogView from './components/userlog/UserlogView';
import UserAdd from './components/user/UserAdd';
import UserList from './components/user/UserList';
import UserView from './components/user/UserView';
import UsergroupAdd from './components/usergroup/UsergroupAdd';
import UsergroupList from './components/usergroup/UsergroupList';
import UsergroupView from './components/usergroup/UsergroupView';
import EjobmailAdd from './components/ejobmail/EjobmailAdd';
import EjobmailList from './components/ejobmail/EjobmailList';
import EjobmailView from './components/ejobmail/EjobmailView';
import EtemplateAdd from './components/etemplate/EtemplateAdd';
import EtemplateList from './components/etemplate/EtemplateList';
import EtemplateView from './components/etemplate/EtemplateView';
import EjobdayList from './components/ejobday/EjobdayList';
import EjobmonthList from './components/ejobmonth/EjobmonthList';
import AplanAdd from './components/aplan/AplanAdd';
import AplanList from './components/aplan/AplanList';
import AplanView from './components/aplan/AplanView';
import AcostplanAdd from './components/acostplan/AcostplanAdd';
import AcostplanList from './components/acostplan/AcostplanList';
import AcostplanView from './components/acostplan/AcostplanView';
import AjournalcodeAdd from './components/ajournalcode/AjournalcodeAdd';
import AjournalcodeList from './components/ajournalcode/AjournalcodeList';
import AjournalcodeView from './components/ajournalcode/AjournalcodeView';


import Homepage from './components/Homepage';
import ChangePass from './components/myaccount/ChangePass';
import Dashboard from './components/tour/TourChart';
import Settings from './components/settings/Settings';
import Accounting from './components/accounting/Accounting';
import Mailing from './components/mailing/Mailing';
import Esettings from './components/esettings/Esettings';
import More from './components/more/More';
import SignOut from './components/session/SignOut';
import AjournalList from './components/ajournal/AjournalList';
import BankStatement from './components/ajournal/BankStatement';
import TourroomBooking from './components/tourroom/TourroomBooking';
import TransportationBooking from './components/transportation/TransportationBooking';
import TourSettingsRegistration from './components/tour/TourSettingsRegistration';
import TourSettings from './components/tour/TourSettings';
import SignInPage from './components/session/SignInPage';
import SignedOutPage from './components/session/SignedOutPage';
import ExportCustomerAdd from './components/export/ExportCustomerAdd';


import NotFound from './components/NotFound';
import TestColors from './components/test/TestColors';
import Constants from "./utils/environment";

window.onerror = (msg, url, lineNo, columnNo, error) => {
  console.log('hay error');
  const info = {
    href: window.location.href || '',
    url: url || '',
    lineNo: lineNo || '',
    columnNo: columnNo || '',
    error: error || '',
  };
 
  const session = getSession();
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    version: process.env.REACT_APP_VERSION,
    is:  session.is,
  };
  let qlVars = {error: msg.toString(), info: JSON.stringify(info) };
  console.log('vars', qlVars);
  headers['Authorization'] = 'Bearer ' + session.token;
  let query = `mutation setError ( $error: String, $info: String )   {   
    setError ( error: $error, info: $info ){
       answer
       errors {
          key,
          value
       }        
    }
  }
  `;
  const qlBody= JSON.stringify( {query, variables: qlVars });
  try {
    fetch(Constants.URI, {
      headers,
      method: 'POST',
      body: qlBody,
    });
  } catch (err) {
    console.error(err) ;
  }


};

const sagaMiddleware = createSagaMiddleware();

const client = createClient();

const store = createStore(
  combineReducers({
   
    form: formReducer,
    auth: authReducer,
    app: appReducer,
    notifications: notifReducer,
    confirms: confirmReducer,
  }),
  {},
  compose(
   
    applyMiddleware(sagaMiddleware),
   
    window.devToolsExtension ? window.devToolsExtension() : f => f,
  ),
);

sagaMiddleware.run(rootSaga);

class App extends Component {

  componentDidMount() {
   
    localStorage.removeItem('Error');
  }
  render() {
    const { t, i18n } = this.props;

    return (
      <ErrorBoundary>
        <ApolloProvider client={client}>
          <Provider store={store} >
            <SettingsLoad>
              <BrowserRouter>
                <Switch>
                  {}
                  

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table=""
                    action=""
                    path="/changepass"
                    component={ChangePass}
                    title="form.changePass"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table=""
                    action=""
                    path="/dashboard"
                    component={Dashboard}
                    title="menu.dashboard"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={true}
                    table=""
                    action=""
                    path="/settings"
                    component={Settings}
                    title="menu.settings"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table=""
                    action=""
                    path="/accounting"
                    component={Accounting}
                    title="menu.accounting"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table=""
                    action=""
                    path="/mailing"
                    component={Mailing}
                    title="menu.messaging"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={true}
                    table=""
                    action=""
                    path="/esettings"
                    component={Esettings}
                    title="menu.settings"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table=""
                    action=""
                    path="/more"
                    component={More}
                    title="form.more"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table=""
                    action=""
                    path="/ajournal"
                    component={AjournalList}
                    title="form.ajournal"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={true}
                    table=""
                    action=""
                    path="/bankstatement"
                    component={BankStatement}
                    title="form.bankstatement"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table=""
                    action=""
                    path="/tour-child/:parentid/tourroom-booking/:id"
                    component={TourroomBooking}
                    title="table.tourroom"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table=""
                    action=""
                    path="/tour-child/:parentid/transportation-booking/:id"
                    component={TransportationBooking}
                    title="table.transportation"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table=""
                    action=""
                    path="/tour-settings/:parentid/customer-status/:id"
                    component={TourSettingsRegistration}
                    title="table.tourroom"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table=""
                    action=""
                    path="/tour-settings-registration/:id"
                    component={TourSettings}
                    title="table.tourroom"
                  />

                  <LayoutContainer
                    t={t}
                      public="yes"
                    i18n={i18n}
                    ai={false}
                    table=""
                    action=""
                    path="/signin"
                    component={SignInPage}
                    title="login.signin"
                  />

                  <LayoutContainer
                    t={t}
                      public="yes"
                    i18n={i18n}
                    ai={false}
                    table=""
                    action=""
                    path="/signedout"
                    component={SignedOutPage}
                    title="login.signedout"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table=""
                    action=""
                    path="/export-customeradd"
                    component={ExportCustomerAdd}
                    title="table.export"
                  />
                  

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="organizer"
                    action="add"
                    path="/organizer-add"
                    component={OrganizerAdd}
                    title="table.organizer"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="organizer"
                    action="list"
                    path="/organizer"
                    component={OrganizerList}
                    title="table.organizer"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="organizer"
                    action="view"
                    path="/organizer-view/:id"
                    component={OrganizerView}
                    title="table.organizer"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="person"
                    action="add"
                    path="/person-add"
                    component={PersonAdd}
                    title="table.person"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="person"
                    action="list"
                    path="/person"
                    component={PersonList}
                    title="table.person"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="person"
                    action="view"
                    path="/person-view/:id"
                    component={PersonView}
                    title="table.person"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="customer"
                    action="add"
                    path="/customer-add"
                    component={CustomerAdd}
                    title="table.customer"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="customer"
                    action="list"
                    path="/customer"
                    component={CustomerList}
                    title="table.customer"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="customer"
                    action="view"
                    path="/customer-view/:id"
                    component={CustomerView}
                    title="table.customer"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="tour"
                    action="add"
                    path="/tour-add"
                    component={TourAdd}
                    title="table.tour"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="tour"
                    action="list"
                    path="/tour"
                    component={TourList}
                    title="table.tour"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="tour"
                    action="view"
                    path="/tour-view/:id"
                    component={TourView}
                    title="table.tour"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="tourcategory"
                    action="add"
                    path="/tourcategory-add"
                    component={TourcategoryAdd}
                    title="table.tourcategory"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="tourcategory"
                    action="list"
                    path="/tourcategory"
                    component={TourcategoryList}
                    title="table.tourcategory"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="tourcategory"
                    action="view"
                    path="/tourcategory-view/:id"
                    component={TourcategoryView}
                    title="table.tourcategory"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="transportation"
                    action="add"
                    path="/tour-child/:parentid/transportation-add"
                    component={TransportationAdd}
                    title="table.transportation"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="transportation"
                    action="list"
                    path="/tour-child/:parentid/transportation"
                    component={TransportationList}
                    title="table.transportation"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="transportation"
                    action="view"
                    path="/tour-child/:parentid/transportation-view/:id"
                    component={TransportationView}
                    title="table.transportation"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="tourprice"
                    action="add"
                    path="/tour-child/:parentid/tourprice-add"
                    component={TourpriceAdd}
                    title="table.tourprice"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="tourprice"
                    action="list"
                    path="/tour-child/:parentid/tourprice"
                    component={TourpriceList}
                    title="table.tourprice"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="tourprice"
                    action="view"
                    path="/tour-child/:parentid/tourprice-view/:id"
                    component={TourpriceView}
                    title="table.tourprice"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="tourroom"
                    action="add"
                    path="/tour-child/:parentid/tourroom-add"
                    component={TourroomAdd}
                    title="table.tourroom"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="tourroom"
                    action="list"
                    path="/tour-child/:parentid/tourroom"
                    component={TourroomList}
                    title="table.tourroom"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="tourroom"
                    action="view"
                    path="/tour-child/:parentid/tourroom-view/:id"
                    component={TourroomView}
                    title="table.tourroom"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="regonline"
                    action="list"
                    path="/regonline"
                    component={RegonlineList}
                    title="table.regonline"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="regonline"
                    action="view"
                    path="/regonline-view/:id"
                    component={RegonlineView}
                    title="table.regonline"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="registration"
                    action="add"
                    path="/registration-add"
                    component={RegistrationAdd}
                    title="table.registration"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="registration"
                    action="list"
                    path="/registration"
                    component={RegistrationList}
                    title="table.registration"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="registration"
                    action="view"
                    path="/registration-view/:id"
                    component={RegistrationView}
                    title="table.registration"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    permissionTable="customer"
                    table="customerhistory"
                    action="list"
                    path="/customer-child/:parentid/customerhistory"
                    component={CustomerhistoryList}
                    title="table.customerhistory"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    permissionTable="payment"
                    table="paymenthistory"
                    action="list"
                    path="/payment-child/:parentid/paymenthistory"
                    component={PaymenthistoryList}
                    title="table.paymenthistory"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    permissionTable="registration"
                    table="registrationhistory"
                    action="list"
                    path="/registration-child/:parentid/registrationhistory"
                    component={RegistrationhistoryList}
                    title="table.registrationhistory"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="payment"
                    action="add"
                    path="/payment-add"
                    component={PaymentAdd}
                    title="table.payment"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="payment"
                    action="list"
                    path="/payment"
                    component={PaymentList}
                    title="table.payment"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="payment"
                    action="view"
                    path="/payment-view/:id"
                    component={PaymentView}
                    title="table.payment"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="checkdeposithead"
                    action="add"
                    path="/checkdeposithead-add"
                    component={CheckdepositheadAdd}
                    title="table.checkdeposithead"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="checkdeposithead"
                    action="list"
                    path="/checkdeposithead"
                    component={CheckdepositheadList}
                    title="table.checkdeposithead"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="checkdeposithead"
                    action="view"
                    path="/checkdeposithead-view/:id"
                    component={CheckdepositheadView}
                    title="table.checkdeposithead"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    permissionTable="checkdeposithead"
                    table="checkdepositdet"
                    action="list"
                    path="/checkdeposithead-child/:parentid/checkdepositdet"
                    component={CheckdepositdetList}
                    title="table.checkdepositdet"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="export"
                    action="add"
                    path="/export-add"
                    component={ExportAdd}
                    title="table.export"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="export"
                    action="list"
                    path="/export"
                    component={ExportList}
                    title="table.export"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="export"
                    action="view"
                    path="/export-view/:id"
                    component={ExportView}
                    title="table.export"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="hotel"
                    action="add"
                    path="/hotel-add"
                    component={HotelAdd}
                    title="table.hotel"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="hotel"
                    action="list"
                    path="/hotel"
                    component={HotelList}
                    title="table.hotel"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="hotel"
                    action="view"
                    path="/hotel-view/:id"
                    component={HotelView}
                    title="table.hotel"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="stopspoint"
                    action="add"
                    path="/stopspoint-add"
                    component={StopspointAdd}
                    title="table.stopspoint"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="stopspoint"
                    action="list"
                    path="/stopspoint"
                    component={StopspointList}
                    title="table.stopspoint"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="stopspoint"
                    action="view"
                    path="/stopspoint-view/:id"
                    component={StopspointView}
                    title="table.stopspoint"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="service"
                    action="add"
                    path="/service-add"
                    component={ServiceAdd}
                    title="table.service"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="service"
                    action="list"
                    path="/service"
                    component={ServiceList}
                    title="table.service"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="service"
                    action="view"
                    path="/service-view/:id"
                    component={ServiceView}
                    title="table.service"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="city"
                    action="add"
                    path="/city-add"
                    component={CityAdd}
                    title="table.city"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="city"
                    action="list"
                    path="/city"
                    component={CityList}
                    title="table.city"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="city"
                    action="view"
                    path="/city-view/:id"
                    component={CityView}
                    title="table.city"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="registrationstatus"
                    action="add"
                    path="/registrationstatus-add"
                    component={RegistrationstatusAdd}
                    title="table.registrationstatus"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="registrationstatus"
                    action="list"
                    path="/registrationstatus"
                    component={RegistrationstatusList}
                    title="table.registrationstatus"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="registrationstatus"
                    action="view"
                    path="/registrationstatus-view/:id"
                    component={RegistrationstatusView}
                    title="table.registrationstatus"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="confirmationstatus"
                    action="add"
                    path="/confirmationstatus-add"
                    component={ConfirmationstatusAdd}
                    title="table.confirmationstatus"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="confirmationstatus"
                    action="list"
                    path="/confirmationstatus"
                    component={ConfirmationstatusList}
                    title="table.confirmationstatus"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="confirmationstatus"
                    action="view"
                    path="/confirmationstatus-view/:id"
                    component={ConfirmationstatusView}
                    title="table.confirmationstatus"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="bank"
                    action="add"
                    path="/bank-add"
                    component={BankAdd}
                    title="table.bank"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="bank"
                    action="list"
                    path="/bank"
                    component={BankList}
                    title="table.bank"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="bank"
                    action="view"
                    path="/bank-view/:id"
                    component={BankView}
                    title="table.bank"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="paymentmethod"
                    action="add"
                    path="/paymentmethod-add"
                    component={PaymentmethodAdd}
                    title="table.paymentmethod"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="paymentmethod"
                    action="list"
                    path="/paymentmethod"
                    component={PaymentmethodList}
                    title="table.paymentmethod"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="paymentmethod"
                    action="view"
                    path="/paymentmethod-view/:id"
                    component={PaymentmethodView}
                    title="table.paymentmethod"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="paymentdocstatus"
                    action="add"
                    path="/paymentdocstatus-add"
                    component={PaymentdocstatusAdd}
                    title="table.paymentdocstatus"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="paymentdocstatus"
                    action="list"
                    path="/paymentdocstatus"
                    component={PaymentdocstatusList}
                    title="table.paymentdocstatus"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="paymentdocstatus"
                    action="view"
                    path="/paymentdocstatus-view/:id"
                    component={PaymentdocstatusView}
                    title="table.paymentdocstatus"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="customerstatus"
                    action="add"
                    path="/customerstatus-add"
                    component={CustomerstatusAdd}
                    title="table.customerstatus"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="customerstatus"
                    action="list"
                    path="/customerstatus"
                    component={CustomerstatusList}
                    title="table.customerstatus"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="customerstatus"
                    action="view"
                    path="/customerstatus-view/:id"
                    component={CustomerstatusView}
                    title="table.customerstatus"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="gendertype"
                    action="add"
                    path="/gendertype-add"
                    component={GendertypeAdd}
                    title="table.gendertype"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="gendertype"
                    action="list"
                    path="/gendertype"
                    component={GendertypeList}
                    title="table.gendertype"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="gendertype"
                    action="view"
                    path="/gendertype-view/:id"
                    component={GendertypeView}
                    title="table.gendertype"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="userlog"
                    action="list"
                    path="/userlog"
                    component={UserlogList}
                    title="table.userlog"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="userlog"
                    action="view"
                    path="/userlog-view/:id"
                    component={UserlogView}
                    title="table.userlog"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={true}
                    table="user"
                    action="add"
                    path="/user-add"
                    component={UserAdd}
                    title="table.user"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={true}
                    table="user"
                    action="list"
                    path="/user"
                    component={UserList}
                    title="table.user"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={true}
                    table="user"
                    action="view"
                    path="/user-view/:id"
                    component={UserView}
                    title="table.user"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={true}
                    table="usergroup"
                    action="add"
                    path="/usergroup-add"
                    component={UsergroupAdd}
                    title="table.usergroup"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={true}
                    table="usergroup"
                    action="list"
                    path="/usergroup"
                    component={UsergroupList}
                    title="table.usergroup"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={true}
                    table="usergroup"
                    action="view"
                    path="/usergroup-view/:id"
                    component={UsergroupView}
                    title="table.usergroup"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="ejobmail"
                    action="add"
                    path="/ejobmail-add"
                    component={EjobmailAdd}
                    title="table.ejobmail"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="ejobmail"
                    action="list"
                    path="/ejobmail"
                    component={EjobmailList}
                    title="table.ejobmail"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="ejobmail"
                    action="view"
                    path="/ejobmail-view/:id"
                    component={EjobmailView}
                    title="table.ejobmail"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="etemplate"
                    action="add"
                    path="/etemplate-add"
                    component={EtemplateAdd}
                    title="table.etemplate"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="etemplate"
                    action="list"
                    path="/etemplate"
                    component={EtemplateList}
                    title="table.etemplate"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="etemplate"
                    action="view"
                    path="/etemplate-view/:id"
                    component={EtemplateView}
                    title="table.etemplate"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="ejobday"
                    action="list"
                    path="/ejobday"
                    component={EjobdayList}
                    title="table.ejobday"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="ejobmonth"
                    action="list"
                    path="/ejobmonth"
                    component={EjobmonthList}
                    title="table.ejobmonth"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="aplan"
                    action="add"
                    path="/aplan-add"
                    component={AplanAdd}
                    title="table.aplan"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="aplan"
                    action="list"
                    path="/aplan"
                    component={AplanList}
                    title="table.aplan"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="aplan"
                    action="view"
                    path="/aplan-view/:id"
                    component={AplanView}
                    title="table.aplan"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="acostplan"
                    action="add"
                    path="/acostplan-add"
                    component={AcostplanAdd}
                    title="table.acostplan"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="acostplan"
                    action="list"
                    path="/acostplan"
                    component={AcostplanList}
                    title="table.acostplan"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="acostplan"
                    action="view"
                    path="/acostplan-view/:id"
                    component={AcostplanView}
                    title="table.acostplan"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="ajournalcode"
                    action="add"
                    path="/ajournalcode-add"
                    component={AjournalcodeAdd}
                    title="table.ajournalcode"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="ajournalcode"
                    action="list"
                    path="/ajournalcode"
                    component={AjournalcodeList}
                    title="table.ajournalcode"
                  />

                  <LayoutContainer
                    t={t}
                    i18n={i18n}
                    ai={false}
                    table="ajournalcode"
                    action="view"
                    path="/ajournalcode-view/:id"
                    component={AjournalcodeView}
                    title="table.ajournalcode"
                  />
                  
                <Route exact path="/" component={Homepage} />
                <Route exact path="/signout" component={SignOut} />
                  <Route component={NotFound} />
                </Switch>
              </BrowserRouter>
            </SettingsLoad>
          </Provider>
        </ApolloProvider>
      </ErrorBoundary>
    );
  }
}


export default App;
