import React from "react";
import { resolvePathObj, getInputValue } from "../../utils/commonutils";

const Comp = (props) => {
  return (
    <React.Fragment>
      <div className="formRow">test</div>
    </React.Fragment>
  );
};

export default Comp;
