import Constants from "../utils/environment";
const initialState = {
  themeName: Constants.THEME_NAME,
  submitting: false,
  confirmopen: null,
  settings: {},
  _pagecurrent: 1,
  _pagestotal: 1,
  _pagerecords: 100,
  _pagetotalrecord: 0,
  info: {},
  org: null,
};

const appstate = (state = initialState, action) => {
  switch (action.type) {
    case "SUBMIT_START":
      return Object.assign({}, state, action.payload);
    case "SUBMIT_STOP":
      return Object.assign({}, state, action.payload);
    case "SETTINGS_LOAD":
      return Object.assign({}, state, action.payload);
    case "SETORG":
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

export default appstate;
