import React, { Component } from "react";
import { signOut } from "../../actions/authActions";

class SignOut extends Component {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
    signOut();
    this.props.history.push("/signedout");
  }
  render() {
    return null;
  }
}

export default SignOut;
