// www.mobilsem.com  - Copyright .src/components/tourroom/TourroomView.js
import React, { Component } from "react";
import { withRouter } from "react-router";
import { graphql, compose } from "react-apollo";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { submit, change, touch, initialize } from "redux-form";
//import { Values } from 'redux-form-website-template';

import Form from "./TourroomBookingForm";

import defQls from "../../defQls";

import { Tables } from "../../defTables";

import {
  submitValues,
  deleteRecord,
  processError,
  errorTranslate,
  checkLoadCrud,
} from "../../utils/helper";

import { appSubmitStart, appSubmitStop } from "../../actions/appActions";
import PageHeader from "../PageHeader";
import CrudToolBar from "../CrudToolBar";
import IconLoading from "../icons/IconLoading";
import { MsgError } from "../ListData";
import RecordInfo from "../RecordInfo";

import RoomHeader from "./RoomHeader";
import { tt } from "../../utils/commonutils";
import { showConfirm } from "../../actions/confirmActions";

const crudCode = {};

const tableCrud = "tourroom";
const action = "view";

const formFilterName = "listFiltertourroom";

class FormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actionsave: "update",
      actionsave_origen: "update",
    };
  }

  handleSubmit = async (values) => {
    return await submitValues(
      tableCrud,
      this.state.actionsave,
      this.props,
      false
    );
  };

  toolbarFunctions = (toolbar) => {
    if (toolbar === "duplicate") {
      this.setState({ actionsave: "add", actionsave_origen: "duplicate" });
    }
  };

  changePage = (link) => {
    this.props.goUrl(link);
  };

  handleDeleteRecord = () => {
    deleteRecord(tableCrud, this.props);
  };

  render() {
    console.log("tourroombooking");
    const { t, ...otherProps } = this.props;

    let aElements = [];
    let aQlFiltered = {
      getTourRoomCustomers: true,
      crud_view_tourroom: { table: "tourroom" },
      crud_update_tourroom: { table: "tourroom" },
      crud_add_tourroom: { table: "tourroom" },
      crud_delete_tourroom: { table: "tourroom" },
      view_tour: { table: "tour" },
      list_hotel: { table: "hotel" },
    };

    const resultCheck = checkLoadCrud(aQlFiltered, this.props);
    if (resultCheck.messageError) {
      return <MsgError msg={resultCheck.messageError} t={this.props.t} />;
    }
    aElements.push(
      <PageHeader
        key="pageheader"
        icon="bed"
        title="table.roombooking"
        action={action}
        t={t}
        tableCrud={tableCrud}
        tables={Tables}
        handleSubmit={this.handleSubmit}
        handleDeleteRecord={this.handleDeleteRecord}
        id={this.props.match.params.id}
      />
    );

    if (resultCheck.globalLoading && !resultCheck.messageError) {
      aElements.push(
        <div key="titleandloading">
          <div className="appBodyTitleSeparator" />
          <div
            className="animated-background"
            style={{ width: "100%", height: "300px" }}
          >
            <IconLoading key="icon" />
          </div>
        </div>
      );
    }
    if (this.state.actionsave === "add") {
      aElements.push(
        <div key="addlegend" style={{ color: "darkred" }}>
          {t("info." + this.state.actionsave_origen)}
        </div>
      );
    }

    if (!resultCheck.globalLoading && !resultCheck.messageError) {
      aElements.push(<RoomHeader key="RoomHeader" {...this.props} t={t} />);

      aElements.push(
        <Form
          changePage={this.changePage}
          crudAction="View"
          key="mainform"
          id={this.props.match.params.id}
          data={this.props["crud_view_" + tableCrud][tableCrud]}
          onSubmit={this.handleSubmit}
          containerPropsForm={this.props}
          t={this.props.t}
        />
      );
    }

    if (!resultCheck.globalLoading && !resultCheck.messageError) {
      aElements.push(
        <RecordInfo
          key="recordinfo"
          tableCrud={tableCrud}
          {...this.props}
          data={this.props["crud_view_" + tableCrud][tableCrud]}
        />
      );
    }
    return <div>{aElements}</div>;
  }
}

const withGraphqlandRouter = compose(
  graphql(defQls.tourroom.View, {
    name: "crud_view_tourroom",
    options: (props) => {
      const optionsValues = {
        variables: { id: props.match.params.id, _qlType: "View" },
      };
      optionsValues.fetchPolicy = "network-only";
      return optionsValues;
    },
  }),
  graphql(defQls.getTourRoomCustomers, {
    name: "getTourRoomCustomers",
    options: (props) => {
      const optionsValues = {
        variables: { tourroom_id: props.match.params.id, _qlType: "View" },
      };
      optionsValues.fetchPolicy = "network-only";
      return optionsValues;
    },
  }),
  graphql(defQls.tourroom.Update, {
    name: "crud_update_tourroom",
    options: (props) => {
      const optionsValues = {
        variables: { id: props.match.params.id, _qlType: "Update" },
      };
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : "network-only";
      return optionsValues;
    },
  }),
  graphql(defQls.tourroom.Add, {
    name: "crud_add_tourroom",
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : "network-only";
      optionsValues.variables = { _qlType: "Add" };
      return optionsValues;
    },
  }),
  graphql(defQls.tourroom.Delete, {
    name: "crud_delete_tourroom",
    options: (props) => {
      const optionsValues = {
        variables: { id: props.match.params.id, _qlType: "Delete" },
      };
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : "network-only";
      return optionsValues;
    },
  }),
  graphql(defQls.tour.View, {
    name: "view_tour",
    options: (props) => {
      return {
        variables: { id: props.match.params.parentid, _qlType: "View" },
        fetchPolicy: "network-only",
      };
    },
  }),
  graphql(defQls.hotel.ListMini, {
    name: "list_hotel",
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : "network-only";
      optionsValues.variables = { _qlType: "ListMini" };
      return optionsValues;
    },
  })
)(withRouter(FormContainer));

const mapStateToProps = (state) => {
  return {
    myState: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      appSubmitStart,
      appSubmitStop,
      showConfirm,
      initialize,
      change,
      touch,
      dispatch,
    },
    dispatch
  );
};

const withState = connect(
  mapStateToProps,
  mapDispatchToProps
)(withGraphqlandRouter);

const ComponentFull = withState; //withCrud (withState);  // hoc be at the end of another wrappers

export default ComponentFull;
