import React, { Component } from "react";
import ShowIcon from "./icons/ShowIcon";
import { removeToast } from "../actions/notifActions";
import { realTypeOf } from "../utils/commonutils";

class Notif extends Component {
  shouldComponentUpdate() {
    return true;
  }
  handleClick = (e, id) => {
    e.preventDefault();

    //this.props.removeToast(id)
    //console.log('clic ');

    //const id= this.props.key;
    ////console.log(id);
    this.props.hideNotification(id);
  };
  render() {
    ////console.log('**************props notif');
    ////console.log(this.props);
    const bgcolor = !this.props.notification.isError
      ? "green"
      : this.props.notification.isError === 3
      ? "#D2691E"
      : "darkred";

    //console.log(this.props.notification.text,realTypeOf(this.props.notification.text));
    let aNewTexts = [];
    if (
      realTypeOf(this.props.notification.text) === "[object Object]" ||
      realTypeOf(this.props.notification.text) === "[object Array]"
    ) {
      const aTexts = this.props.notification.text;
      aNewTexts = aTexts;
      /* imposible to examine objects type react, they are not common html elements
      let index=0;
      for (const [key, value] of Object.entries(aTexts)) {
        console.log('key, value', key, value);
        let aStyle = {
          marginBottom: '5px',
        };
        if (index === 0) { // for alert; line 1 is like the title, rest it's text, it will have bg white
          aStyle.backgroundColor = bgcolor;
          aStyle.color = '#FFFFFF';
          aStyle.marginTop = '-18px';
        } else {
          aStyle.backgroundColor = '#DDDDDD';
          aStyle.color = '#333333';
          aStyle.padding = '5px';
        }

        aNewTexts.push(<div style={aStyle}>{value}</div>);
        index ++;
      }*/
    } else {
      const aTexts = this.props.notification.text.split("<br/>");
      aTexts.map((text, index) => {
        let aStyle = {
          marginBottom: "5px",
        };
        if (text.indexOf("<a") === -1) {
          aStyle.cursor = "pointer";
        }
        if (index === 0) {
          // for alert; line 1 is like the title, rest it's text, it will have bg white
          aStyle.backgroundColor = bgcolor;
          aStyle.color = "#FFFFFF";
          aStyle.marginTop = "-18px";
        } else {
          aStyle.backgroundColor = "#DDDDDD";
          aStyle.color = "#333333";
          aStyle.padding = "5px";
        }

        if (text.indexOf("<a") === -1) {
          aNewTexts.push(
            <div
              key={`m${index}`}
              style={aStyle}
              onClick={(e) => this.handleClick(e, this.props.notification.id)}
            >
              {text}
            </div>
          );
        } else {
          aNewTexts.push(
            <div key={`m${index}`} style={aStyle}>
              {text}
            </div>
          );
        }
      });
    }

    //    display: 'inline',

    return (
      <div
        style={{
          fontSize: "16px",
          backgroundColor: bgcolor,
          color: "white",
          border: "solid 1px #000000",
          padding: "12px",
          width: "100%",
          maxWidth: "730px",
        }}
      >
        <div style={{ textAlign: "right" }}>
          <a
            className="aNotHover"
            onClick={(e) => this.handleClick(e, this.props.notification.id)}
          >
            <ShowIcon icon="close" size="19" color="white" />
          </a>
        </div>
        {aNewTexts}
      </div>
    );
  }
}

export default Notif;
