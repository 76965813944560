/*
WARNING:
  formProps is artificial when is launched from containerForm, in the case initForm or manual change
    from container.
    this send his props himself and not containerForm
  so don't use formProps to use special props, form myState and tt is ok, container and Form have these same props
  getInputValue use myState, myState is present in container so is ok to use it,
    but send always the name of form { nameForm }, because if formProps is artificil will fail
  use tt , for translate, have dependency with props
 */

import React from "react";
import ShowIcon from "../icons/ShowIcon";
import { getInputValue, processValues, tt } from "../../utils/commonutils";
import { valPhone } from "../../utils/validators";

import {
  submit,
  change,
  arrayRemove,
  arrayInsert,
  arrayRemoveAll,
} from "redux-form";
import strFragments from "../../defStrFragmentsQls.js";
import { fetchQl } from "../../apolloClient";
const crudCode = {};

crudCode.onChangeInput = async (params) => {
  //console.log('params client onChangeInput', params);
  //console.log('onChangeInput params:' , params);
  const {
    nameForm,
    popup,
    tableCrud,
    inputFullName,
    inputName,
    line,
    action,
    parentField,
    event,
    newValue,
    previousValue,
    props,
    formProps,
    formState,
  } = params;

  /*
     popup denote that value is sent through window, and is here not because there is change, but
     must execute change manually and follow the same logic that onChange
     */
  const result = {};

  // dont add hiddenFields, the logic ist just update when is initForm or on change affected fields
  let newStates = { disabledFields: {}, warningFields: {} };
  if (inputFullName === "type") {
    // autocomplete  get only pure id, before compare changes with old (not really old, but previous value)
    if (!newStates.hiddenFields) {
      newStates.hiddenFields = {}; // important to dont reset eveery time, just read the changes after initForm for all situations where add hiddenFields
    }

    if (newValue === "1") {
      // clean pelerins
      formProps.dispatch(change(formProps.form, `files`, []));
    }
    newStates.hiddenFields["files"] = newValue ? true : false;
  }
  result.newStates = newStates;
  return result;
};

export default crudCode;
