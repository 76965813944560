

import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  graphql,
  compose,
} from 'react-apollo';
import { loader } from "graphql.macro";
import debounce from 'lodash/debounce';



import { submit, change, touch, initialize } from 'redux-form';


import { Tables } from '../../defTables';
import { fetchQl } from '../../apolloClient';
import defQls from '../../defQls';
import { processError , errorTranslate, checkLoadCrud, cleanFilters, getFilters, preSubmitValidation, submitValues, deleteRecord, uuid, getRecordFromOptionsIndirectOnFilter, resolveLink, getListviewFields } from '../../utils/helper';

import Form from './BankForm';
import { appSubmitStart, appSubmitStop, appSettingsLoad } from '../../actions/appActions';
import { showConfirm, hideConfirm } from '../../actions/confirmActions';

import PageHeader from '../PageHeader';
import CrudToolBar from '../CrudToolBar';
import IconLoading from '../icons/IconLoading';
import { MsgError } from '../ListData';
import { getInputValue, tt, getObjFromListById  } from "../../utils/commonutils";


import RecordInfo from '../RecordInfo';
import RecordHeadInfo from '../RecordHeadInfo';


const crudCode = {};

const templates = [];

const tableCrud = 'bank';
const action ='view';
const nameForm = tableCrud + 'Form';
const formFilterName="listFilterbank";

class FormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actionsave: 'update',
      actionsave_origen: 'update',
      crudAction: 'View',
      crudTable: tableCrud,
      mainForm: nameForm,
      id: this.props.match.params.id || '',
      dialogOpened: {}, 
      parentid: this.props.match.params && this.props.match.params.parentid ? this.props.match.params.parentid : '',
      disabledFields: [],
      hiddenFields: [],
      warningFields: {},
    };
  }

  handleSubmit = async (values) => {
    try {
      if (this.state.warningFields && this.state.warningFields._submit && this.state.warningFields._submit.length) {
        const validation = await preSubmitValidation (tableCrud, this.state.actionsave, this.props, this.syncCode, values, crudCode);
        if (!validation) return false;
        this.props.showConfirm({
          id: uuid(),
          type: 'confirmation',
          text: [ ...this.state.warningFields._submit, <div key="usure">{this.props.t('form.uSureSave')}</div>],
          buttons: [
            {
              label: this.props.t('form.yes'),
              onClick: async () => await this.handleSubmitOk(values),
            },
            {
              label: this.props.t('form.no'),
              onClick: () => null,
            },
          ],
        });
      } else {
        return await this.handleSubmitOk(values);
      }
    } catch (err) {
      throw(err);
    }
  };

  handleSubmitOk = async (values) => {
    try {
      const res = await submitValues (tableCrud, this.state.actionsave, this.props, true, this.syncCode, values, crudCode);
      if (res) {
        if (res !== 'dontreloaddata') { 
          const newState = {
            actionsave: 'update',
            actionsave_origen: 'update',
          };
          if (res && res.id) newState.id = res.id;
          this.setState(newState, async () => {
            await this.executeCode( 'onChangeInput', {
              action: 'initForm',
              nameForm,
             
             
             
              formProps: { ...this.props, containerPropsForm: this.props},
              formState: this.state,
              event: null,
              newValue: null,
            });
          });
        }    
      } else {
       
       
        this.props.dispatch ( change (nameForm, '_fakefield',  Math.random() ));
      }
      return res;
    } catch (err) {
     
    }
  };



  toolbarFunctions = ( toolbar, params ={} ) => {
    
  };


  hocdebounced = debounce(( methodCode, params)=> {
    this.executeCode( methodCode, params);
  }, 1500);

  executeCode = async ( methodCode ='', params = {} ) => {

   
    params.tableCrud = tableCrud ;
    params.formState = this.state;


    if (!crudCode[methodCode]) {
      return;
    }

    const result = await crudCode[methodCode](params);

    
    if (result && result.changeFieldsLater) {
      
      Object.keys(result.changeFieldsLater).map( (keyName, keyIndex) => {
        let valueField = result.changeFieldsLater[keyName];
        params.formProps.change(keyName, valueField);
      });
      
    }
    

    if (result && result.newStates) {
     
     
      let formStateChanged = false;
      let currentState = this.state;
      Object.keys(result.newStates).map( (keyName, keyIndex) => {
        if (typeof this.state[keyName] === 'undefined'
          || JSON.stringify(this.state[keyName]) !== JSON.stringify(result.newStates[keyName])) {
         
         
 
          let keyNewState = result.newStates[keyName];
         
         
          if (keyName === 'warningFields') { 
            keyNewState = { ...this.state[keyName], ...result.newStates[keyName]};
          }
          this.setState({ [keyName]: keyNewState});
          currentState[keyName] = result.newStates[keyName];
          formStateChanged = true;
        }
  		});
  		if (formStateChanged) {
  		 
       
        this.props.dispatch(change(nameForm, '_formstate', JSON.stringify(currentState)));
      }
    }
   
    if (result && typeof result.valueToReturn !=='undefined' ) {
     
      return result.valueToReturn;
    }
  };
  




  handleDeleteRecord = () => {
    deleteRecord (tableCrud, this.props);
  };

  syncCode = (method, params ={}) => {
    let valueToReturn;
    
    if (typeof(valueToReturn)!=='undefined') return valueToReturn;
  };

  render() {

    
    const { t, ...otherProps} = this.props;
    let aElements = [];
    let aQlFiltered = {"crud_view_bank":{"table":"bank"},"crud_update_bank":{"table":"bank"},"crud_add_bank":{"table":"bank"},"crud_delete_bank":{"table":"bank"}} 
    const resultCheck = checkLoadCrud (aQlFiltered,this.props);
    if (resultCheck.messageError) {
      return <MsgError msg={resultCheck.messageError} t={this.props.t} />;
    }
    aElements.push(<PageHeader key="pageheader" action={action} t={t} tableCrud={tableCrud} tables={Tables}  handleSubmit={this.handleSubmit} handleDeleteRecord={this.handleDeleteRecord} id={this.props.match.params.id}  />);
    if (!resultCheck.globalLoading && !resultCheck.messageError) {
      if (this.props['crud_view_'+tableCrud] && this.props['crud_view_'+tableCrud][tableCrud]) {
        if (this.props['crud_view_'+tableCrud][tableCrud].deleted) {
    aElements.push(<RecordHeadInfo  
      key="recordhead"
      tableCrud={tableCrud} 
      {...this.props} 
      data={this.props['crud_view_'+tableCrud][tableCrud]} 
      />);
        }
      }
    }
    aElements.push(<CrudToolBar
      containerPropsForm={this.props}
      toolbarFunctions={this.toolbarFunctions}
      handleSubmit={this.handleSubmit}
      handleDeleteRecord={this.handleDeleteRecord}
      t={this.props.t}
      tableCrud={tableCrud}
      containerState={this.state}
      nameCrudForm={nameForm}
      nameFilterForm={formFilterName}
      tables={Tables}
      key="crudToolBar1"
      crudCode={crudCode}
      executeCode={this.executeCode}
    />);
    if (resultCheck.globalLoading && !resultCheck.messageError) {
      aElements.push(
        <div key="titleandloading">
          <div className="appBodyTitleSeparator" />
          <div className="animated-background" style={ {width: '100%', height: '93px' } }>
            <IconLoading key="icon" />
          </div>
        </div>);
    }
    if (this.state.actionsave === 'add') {
      aElements.push(<div key="addlegend" style = { {color:'darkred'}}>{t('info.'+this.state.actionsave_origen)}</div>);
    }
    if (!resultCheck.globalLoading && !resultCheck.messageError) {
      aElements.push(<Form
        executeCode={this.executeCode}
        syncCode={this.syncCode}
        toolbarFunctions={this.toolbarFunctions}
        crudAction="View"
        key="mainform"
        id={ this.props.match.params.id }
      data={this.props['crud_view_'+tableCrud][tableCrud]}
        onSubmit={this.handleSubmit}
        containerPropsForm={this.props}
        formState={this.state}
        t={this.props.t}
      />);
    }

    
    if (!resultCheck.globalLoading && !resultCheck.messageError) {
      aElements.push(<RecordInfo  
      key="recordinfo"
      tableCrud={tableCrud} 
      {...this.props} 
      data={this.props['crud_view_'+tableCrud][tableCrud]} 
    />);
    }
    return (
      <div>
        {aElements}
      </div>
    );

  }
}



const withGraphqlandRouter = compose(

  graphql(defQls.bank.View, {
    name: 'crud_view_bank',
    options: props => {
      const optionsValues = { variables: {id: props.match.params.id, _qlType: 'View' }};
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy ? Tables[tableCrud].fetchPolicy :'network-only';
      return optionsValues;
    },
  }),
  graphql(defQls.bank.Update, {
    name: 'crud_update_bank',
    options: props => {
      const optionsValues = { variables: {id: props.match.params.id, _qlType: 'Update' } };
      optionsValues.fetchPolicy = 'no-cache';
      return optionsValues;
    },
  }),
  graphql(defQls.bank.Add, {
    name: 'crud_add_bank',
    options: props => {
      const optionsValues = {};
      optionsValues.fetchPolicy = 'no-cache';
      optionsValues.variables= { _qlType: 'Add' };
      return optionsValues;
    },
  }),
  graphql(defQls.bank.Delete, {
    name: 'crud_delete_bank',
    options: props => {
      const optionsValues = { variables: {id: props.match.params.id, _qlType: 'Delete'  } };
      optionsValues.fetchPolicy = 'no-cache';
      return optionsValues;
    },
  }),
  graphql(defQls.organizer.ListMini, {
    name: 'list_organizer',
    options: props => {
      const optionsValues = {} ;
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy ? Tables[tableCrud].fetchPolicy :'network-only';
      optionsValues.variables= { _qlType: 'ListMini', organizer_id: props.myState.app.org };
      return optionsValues;
    },
  }),
)(withRouter(FormContainer));


const mapStateToProps = (state) => {
  return {
    myState: state,
  };
};


const mapDispatchToProps = (dispatch) => {
  return bindActionCreators ({ appSubmitStart,  appSubmitStop, showConfirm, initialize, change, touch, dispatch }, dispatch ); 
};

const withState = connect(
  mapStateToProps ,
  mapDispatchToProps,
)(withGraphqlandRouter);



const ComponentFull = withState ;

export default ComponentFull;
