import React, { Component } from "react";
import { Button } from "../../layouts/cssstyled";

import { connect } from "react-redux";

class TestColors extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.state = {
      width: "300px",
      borderTopColor: "orange",
    };
  }
  handleInputChange(event) {
    const newvalue = event.target.value;
    this.setState((prevState, props) => ({
      width: newvalue,
    }));
  }
  componentDidUpdate() {
    //console.log('didupdate');
    //console.log(this.setState);
    // this.forceUpdate();
  }
  render() {
    //console.log('estado');
    //console.log(this.props.theme);
    const style = {
      borderColor: "yellow",
      width: this.state.width,
      display: "flex",
      height: "400px",
      flexDirection: "column",
    };
    // <SectionContent style={style}>
    return (
      <div style={{ width: "250px" }}>
        Theme: {this.props.theme}
        <input onChange={this.handleInputChange} /> {this.state.width}
        <Button plain>Plain Primary</Button>
        <Button plain secondary>
          Plain Secondary
        </Button>
        <Button>Raised Primary</Button>
        <Button small>Raised Primary Small</Button>
        <Button large>Raised Primary Large</Button>
        <Button radio="0,5'">Raised Primary 1/2</Button>
        <Button secondary>Raised Secondary</Button>
      </div>
    );
  }
}

export default connect((state) => ({ theme: state.app.theme }))(TestColors);
