import React from "react";
import { Link } from "react-router-dom";
import ShowIcon from "../icons/ShowIcon";
import {
  sourcesOptions,
  getObjFromListById,
  dateToISO,
  getTheme,
} from "../../utils/commonutils";
import themes from "../../layouts/themes";
import { fetchQl } from "../../apolloClient";

const downFile = (id, props) => {
  props.goUrl("", {
    callback: async () => {
      const resCheck = await fetchQl(
        `
        mutation setExportChecks ($id: ID!) {
          setExportChecks  (id: $id) {
            pagesettings
          }
        }`,
        [{ name: "id", type: "ID!", value: id }],
        { props }
      );
      // TWIN  CHDN829
      const nameFile = resCheck.data.setExportChecks.answer;
      let url = `${process.env.REACT_APP_DOWN_URI}${nameFile}`;
      // console.log('nameFile 3', nameFile, url);
      window.location.replace(url);
    },
  });
};

const Header = (props) => {
  if (
    !props.view_checkdeposithead ||
    !props.view_checkdeposithead.checkdeposithead
  ) {
    // if table have no realated then have no info
    return null;
  }
  let record = props.view_checkdeposithead.checkdeposithead;
  let state = props.t(
    "processTransaction." +
      getObjFromListById(sourcesOptions.processTransaction, record.statusbase)
        .name
  );

  const theme = getTheme();

  return (
    <div>
      <div>
        <div
          style={{
            fontSize: "20px",
            color: "#7F7F7F",
            display: "flex",
            alignItems: "center",
          }}
        >
          {props.t("table.checkdeposithead")} # {record.name}
          <Link
            className="aToolBarExtend"
            to={`/checkdeposithead-view/${record.id}`}
          >
            <ShowIcon size="40" color="gray" icon="eye" />
          </Link>
        </div>
        <div style={{ fontSize: "18px", color: "#9F9F9F" }}>{state}</div>
        <div style={{ fontSize: "18px", color: "#9F9F9F" }}>
          {props.t("form.date")}: {dateToISO(record.date)}
        </div>
        <div style={{ fontSize: "18px", color: "#9F9F9F" }}>
          {props.t("form.dateaccounting")}: {dateToISO(record.dateaccounting)}
        </div>
        <a
          key="exp"
          onClick={() => {
            downFile(record.id, props);
          }}
        >
          <ShowIcon
            size="35"
            color={themes[theme].PrimaryDark}
            icon="download"
          />
        </a>
        <Link
          key="list2"
          title={props.t("table.payment")}
          to="/checkdeposithead"
        >
          <ShowIcon size="38" color="gray" icon="iclistblack" />
        </Link>
        <Link key="pay" title={props.t("table.payment")} to="/payment">
          <ShowIcon size="38" color="gray" icon="monetization" />
        </Link>
      </div>
    </div>
  );
};

export default Header;
