import gql from 'graphql-tag';
import strQls from './defStrQls.js';
import strFragments from './defStrFragmentsQls.js'
const defQls = {}; 
  
defQls['changePass'] = gql`
    mutation changePass ($password: String!,  $newpass: String!) {
      changePass (password: $password, newpass: $newpass) {
         answer
      }
    }
`;

// www.mobilsem.com  - Copyright .src/components/settings/Settings.qls
defQls['setOrgSettings'] = gql`
    mutation setOrgSettings ($settingsforms: String, $accounting: String, $othersettings: String, $esettings: String, $textgcu: String) {
      setOrgSettings (settingsforms: $settingsforms, accounting: $accounting, othersettings: $othersettings, esettings: $esettings, textgcu: $textgcu) {
         answer
         errors {
            key,
            value
         }
      }
    }
`;

defQls['getOrgSettings'] = gql`
    query getOrgSettings  {
        getOrgSettings   {
          record
          errors {
            key,
            value
          }
        }
    }
`;

defQls['deletePagesetting'] = gql`
    mutation deletePagesetting ($id: ID!) {
      deletePagesetting (id: $id) {
         answer
         errors {
            key,
            value
         }
      }
    }
`;
defQls['organizer'] = {};

defQls['organizer']['ListMini'] = gql`
        query OrganizerList ($name: String, $province: String, $city: String, $email: String, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          organizers  (name: $name, province: $province, city: $city, email: $email, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
               id
  name

          }    
        }
        `;
defQls['organizer']['List'] = gql`
        query OrganizerList ($name: String, $province: String, $city: String, $email: String, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          organizers  (name: $name, province: $province, city: $city, email: $email, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
             ${strFragments.organizer}
          }    
        }
        `;
defQls['organizer']['ListPage'] = gql`${strQls.organizer.ListPage}`;

defQls['organizer']['View'] = gql`
        query OrganizerView($id: ID!, $organizer_id: String) {
          organizer (id: $id, organizer_id: $organizer_id)  {
              id
  name
  province
  contact
  email
  limitemail
  limitsms
  info
  status
  contacts
  modules
  numberingformat
  notes
  settingsforms
  textgcu
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizerupdated_user_id  {
    name
 }
  organizercreated_user_id  {
    name
 }

          }    
        }
        `;
defQls['organizer']['Update'] = gql`
        mutation organizerUpdate(  $id: ID!,  $name: String,  $province: String,  $contact: String,  $email: String,  $limitemail: Int,  $limitsms: Int,  $info: String,  $status: Int,  $contacts: String,  $modules: String,  $numberingformat: String,  $notes: String,  $settingsforms: String,  $textgcu: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String) {
          organizerUpdate (  id: $id,  name: $name,  province: $province,  contact: $contact,  email: $email,  limitemail: $limitemail,  limitsms: $limitsms,  info: $info,  status: $status,  contacts: $contacts,  modules: $modules,  numberingformat: $numberingformat,  notes: $notes,  settingsforms: $settingsforms,  textgcu: $textgcu,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id) {
              id
  name
  province
  contact
  email
  limitemail
  limitsms
  info
  status
  contacts
  modules
  numberingformat
  notes
  settingsforms
  textgcu
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id

          }    
        }
        `;
defQls['organizer']['Delete'] = gql`
        mutation organizerDelete($id: ID!) {
          organizerDelete (id: $id) {
              id
  name
  province
  contact
  email
  limitemail
  limitsms
  info
  status
  contacts
  modules
  numberingformat
  notes
  settingsforms
  textgcu
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id

          }    
        }
        `;
defQls['organizer']['Add'] = gql`
        mutation organizerAdd(  $id: ID!,  $name: String,  $province: String,  $contact: String,  $email: String,  $limitemail: Int,  $limitsms: Int,  $info: String,  $status: Int,  $contacts: String,  $modules: String,  $numberingformat: String,  $notes: String,  $settingsforms: String,  $textgcu: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String) {
          organizerAdd (  id: $id,  name: $name,  province: $province,  contact: $contact,  email: $email,  limitemail: $limitemail,  limitsms: $limitsms,  info: $info,  status: $status,  contacts: $contacts,  modules: $modules,  numberingformat: $numberingformat,  notes: $notes,  settingsforms: $settingsforms,  textgcu: $textgcu,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id) {
              id
  name
  province
  contact
  email
  limitemail
  limitsms
  info
  status
  contacts
  modules
  numberingformat
  notes
  settingsforms
  textgcu
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id

          }    
        }
        `;
defQls['customeraddress'] = {};

defQls['customeraddress']['ListMini'] = gql`
        query CustomeraddressList ($organizer_id: String, $id: ID, $_qlType: String, $_filter: String) {
          customeraddresss  (organizer_id: $organizer_id, id: $id, _qlType: $_qlType, _filter: $_filter) {
               id
  customers
  addresses

          }    
        }
        `;
defQls['customeraddress']['List'] = gql`
        query CustomeraddressList ($organizer_id: String, $id: ID, $_qlType: String, $_filter: String) {
          customeraddresss  (organizer_id: $organizer_id, id: $id, _qlType: $_qlType, _filter: $_filter) {
             ${strFragments.customeraddress}
          }    
        }
        `;
defQls['customeraddress']['ListPage'] = gql`${strQls.customeraddress.ListPage}`;

defQls['customeraddress']['View'] = gql`
        query CustomeraddressView($id: ID!, $organizer_id: String) {
          customeraddress (id: $id, organizer_id: $organizer_id)  {
              id
  customers
  addresses
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id
  customeraddressorganizer_id  {
    name
 }
  customeraddressupdated_user_id  {
    name
 }
  customeraddresscreated_user_id  {
    name
 }

          }    
        }
        `;
defQls['customeraddress']['Update'] = gql`
        mutation customeraddressUpdate(  $id: ID!,  $customers: String,  $addresses: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          customeraddressUpdate (  id: $id,  customers: $customers,  addresses: $addresses,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  customers
  addresses
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id

          }    
        }
        `;
defQls['customeraddress']['Delete'] = gql`
        mutation customeraddressDelete($id: ID!) {
          customeraddressDelete (id: $id) {
              id
  customers
  addresses
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['customeraddress']['Add'] = gql`
        mutation customeraddressAdd(  $id: ID!,  $customers: String,  $addresses: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          customeraddressAdd (  id: $id,  customers: $customers,  addresses: $addresses,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  customers
  addresses
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['person'] = {};

defQls['person']['ListMini'] = gql`
        query PersonList ($organizer_id: String, $lastname: String, $firstname: String, $addresses_city_id: ID, $inactive: Boolean, $id: ID, $name: String, $_qlType: String, $_filter: String, $_orders: String) {
          persons  (organizer_id: $organizer_id, lastname: $lastname, firstname: $firstname, addresses_city_id: $addresses_city_id, inactive: $inactive, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
               id
  name
  email
  groupedaddress_customer_id
persongendertype_id  {
    name
 }
personpending_tourid  {
    name
 }

          }    
        }
        `;
defQls['person']['List'] = gql`
        query PersonList ($organizer_id: String, $lastname: String, $firstname: String, $addresses_city_id: ID, $inactive: Boolean, $id: ID, $name: String, $_qlType: String, $_filter: String, $_orders: String) {
          persons  (organizer_id: $organizer_id, lastname: $lastname, firstname: $firstname, addresses_city_id: $addresses_city_id, inactive: $inactive, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
             ${strFragments.person}
          }    
        }
        `;
defQls['person']['ListPage'] = gql`${strQls.person.ListPage}`;

defQls['person']['View'] = gql`
        query PersonView($id: ID!, $organizer_id: String) {
          person (id: $id, organizer_id: $organizer_id)  {
              id
  gendertype_id
  name
  lastname
  firstname
  email
  nick
  password
  socialsn
  imageright
  inactive
  deceased
  birthdate
  grouped_customer_id
  grouped_filiation
  info
  nationality
  residence
  contacts
  phones
  groupedaddress_customer_id
  customeraddress_customers
  addresses
  identitydocs
  counterhistory
  person_groupid
  pending_tourid
  member
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id
  persongrouped_customer_id  {
    name
 }
  persongroupedaddress_customer_id  {
    name
 }
  persongendertype_id  {
    name
 }
  personpending_tourid  {
    name
 }
  personorganizer_id  {
    name
 }
  personupdated_user_id  {
    name
 }
  personcreated_user_id  {
    name
 }

          }    
        }
        `;
defQls['person']['Update'] = gql`
        mutation personUpdate(  $id: ID!,  $gendertype_id: String,  $name: String,  $lastname: String,  $firstname: String,  $email: String,  $nick: String,  $password: String,  $socialsn: String,  $imageright: Int,  $inactive: Boolean,  $deceased: Boolean,  $birthdate: String,  $grouped_customer_id: String,  $grouped_filiation: Int,  $info: String,  $nationality: String,  $residence: String,  $contacts: String,  $phones: String,  $groupedaddress_customer_id: String,  $customeraddress_customers: String,  $addresses: String,  $identitydocs: String,  $counterhistory: Int,  $person_groupid: ID,  $pending_tourid: String,  $member: Int,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          personUpdate (  id: $id,  gendertype_id: $gendertype_id,  name: $name,  lastname: $lastname,  firstname: $firstname,  email: $email,  nick: $nick,  password: $password,  socialsn: $socialsn,  imageright: $imageright,  inactive: $inactive,  deceased: $deceased,  birthdate: $birthdate,  grouped_customer_id: $grouped_customer_id,  grouped_filiation: $grouped_filiation,  info: $info,  nationality: $nationality,  residence: $residence,  contacts: $contacts,  phones: $phones,  groupedaddress_customer_id: $groupedaddress_customer_id,  customeraddress_customers: $customeraddress_customers,  addresses: $addresses,  identitydocs: $identitydocs,  counterhistory: $counterhistory,  person_groupid: $person_groupid,  pending_tourid: $pending_tourid,  member: $member,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  gendertype_id
  name
  lastname
  firstname
  email
  nick
  password
  socialsn
  imageright
  inactive
  deceased
  birthdate
  grouped_customer_id
  grouped_filiation
  info
  nationality
  residence
  contacts
  phones
  groupedaddress_customer_id
  customeraddress_customers
  addresses
  identitydocs
  counterhistory
  person_groupid
  pending_tourid
  member
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id

          }    
        }
        `;
defQls['person']['Delete'] = gql`
        mutation personDelete($id: ID!) {
          personDelete (id: $id) {
              id
  gendertype_id
  name
  lastname
  firstname
  email
  nick
  password
  socialsn
  imageright
  inactive
  deceased
  birthdate
  grouped_customer_id
  grouped_filiation
  info
  nationality
  residence
  contacts
  phones
  groupedaddress_customer_id
  customeraddress_customers
  addresses
  identitydocs
  counterhistory
  person_groupid
  pending_tourid
  member
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['person']['Add'] = gql`
        mutation personAdd(  $id: ID!,  $gendertype_id: String,  $name: String,  $lastname: String,  $firstname: String,  $email: String,  $nick: String,  $password: String,  $socialsn: String,  $imageright: Int,  $inactive: Boolean,  $deceased: Boolean,  $birthdate: String,  $grouped_customer_id: String,  $grouped_filiation: Int,  $info: String,  $nationality: String,  $residence: String,  $contacts: String,  $phones: String,  $groupedaddress_customer_id: String,  $customeraddress_customers: String,  $addresses: String,  $identitydocs: String,  $counterhistory: Int,  $person_groupid: ID,  $pending_tourid: String,  $member: Int,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          personAdd (  id: $id,  gendertype_id: $gendertype_id,  name: $name,  lastname: $lastname,  firstname: $firstname,  email: $email,  nick: $nick,  password: $password,  socialsn: $socialsn,  imageright: $imageright,  inactive: $inactive,  deceased: $deceased,  birthdate: $birthdate,  grouped_customer_id: $grouped_customer_id,  grouped_filiation: $grouped_filiation,  info: $info,  nationality: $nationality,  residence: $residence,  contacts: $contacts,  phones: $phones,  groupedaddress_customer_id: $groupedaddress_customer_id,  customeraddress_customers: $customeraddress_customers,  addresses: $addresses,  identitydocs: $identitydocs,  counterhistory: $counterhistory,  person_groupid: $person_groupid,  pending_tourid: $pending_tourid,  member: $member,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  gendertype_id
  name
  lastname
  firstname
  email
  nick
  password
  socialsn
  imageright
  inactive
  deceased
  birthdate
  grouped_customer_id
  grouped_filiation
  info
  nationality
  residence
  contacts
  phones
  groupedaddress_customer_id
  customeraddress_customers
  addresses
  identitydocs
  counterhistory
  person_groupid
  pending_tourid
  member
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['customer'] = {};

defQls['customer']['ListMini'] = gql`
        query CustomerList ($organizer_id: String, $numbering: String, $lastname: String, $firstname: String, $customerstatus_id: String, $addresses_city_id: ID, $inactive: Boolean, $nosharedata: Boolean, $nonewsletter: Boolean, $id: ID, $name: String, $_qlType: String, $_filter: String, $_orders: String) {
          customers  (organizer_id: $organizer_id, numbering: $numbering, lastname: $lastname, firstname: $firstname, customerstatus_id: $customerstatus_id, addresses_city_id: $addresses_city_id, inactive: $inactive, nosharedata: $nosharedata, nonewsletter: $nonewsletter, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
               id
  name
  email
  customerstatus_id
  customersubstatus_id
  mobile
customergendertype_id  {
    name
 }
customerperson_id  {
    name
    email
 }

          }    
        }
        `;
defQls['customer']['List'] = gql`
        query CustomerList ($organizer_id: String, $numbering: String, $lastname: String, $firstname: String, $customerstatus_id: String, $addresses_city_id: ID, $inactive: Boolean, $nosharedata: Boolean, $nonewsletter: Boolean, $id: ID, $name: String, $_qlType: String, $_filter: String, $_orders: String) {
          customers  (organizer_id: $organizer_id, numbering: $numbering, lastname: $lastname, firstname: $firstname, customerstatus_id: $customerstatus_id, addresses_city_id: $addresses_city_id, inactive: $inactive, nosharedata: $nosharedata, nonewsletter: $nonewsletter, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
             ${strFragments.customer}
          }    
        }
        `;
defQls['customer']['ListPage'] = gql`${strQls.customer.ListPage}`;

defQls['customer']['View'] = gql`
        query CustomerView($id: ID!, $organizer_id: String) {
          customer (id: $id, organizer_id: $organizer_id)  {
              id
  gendertype_id
  name
  lastname
  firstname
  numbering
  email
  socialsn
  imageright
  nosharedata
  nonewsletter
  inactive
  deceased
  customerstatus_id
  customersubstatus_id
  dietnotes
  handicapnotes
  grouped_customer_id
  grouped_filiation
  birthdate
  info
  nationality
  residence
  contacts
  mobile
  phones
  customeraddress_id
  customeraddress_customers
  addresses
  identitydocs
  counterhistory
  person_id
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id
  customercustomeraddress_id  {
    customers
    addresses
 }
  customergrouped_customer_id  {
    name
 }
  customergendertype_id  {
    name
 }
  customercustomerstatus_id  {
    name
 }
  customerperson_id  {
    name
    lastname
    firstname
    email
    nick
    password
    socialsn
    info
    imageright
    inactive
    deceased
    birthdate
    grouped_filiation
    info
    nationality
    residence
    contacts
    phones
    groupedaddress_customer_id
    addresses
    identitydocs
    counterhistory
    person_groupid
    pending_tourid
    member
    deleted
 }
  customerorganizer_id  {
    name
 }
  customerupdated_user_id  {
    name
 }
  customercreated_user_id  {
    name
 }

          }    
        }
        `;
defQls['customer']['Update'] = gql`
        mutation customerUpdate(  $id: ID!,  $gendertype_id: String,  $name: String,  $lastname: String,  $firstname: String,  $numbering: String,  $email: String,  $socialsn: String,  $imageright: Int,  $nosharedata: Boolean,  $nonewsletter: Boolean,  $inactive: Boolean,  $deceased: Boolean,  $customerstatus_id: String,  $customersubstatus_id: String,  $dietnotes: String,  $handicapnotes: String,  $grouped_customer_id: String,  $grouped_filiation: Int,  $birthdate: String,  $info: String,  $nationality: String,  $residence: String,  $contacts: String,  $mobile: String,  $phones: String,  $customeraddress_id: String,  $customeraddress_customers: String,  $addresses: String,  $identitydocs: String,  $counterhistory: Int,  $person_id: ID,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          customerUpdate (  id: $id,  gendertype_id: $gendertype_id,  name: $name,  lastname: $lastname,  firstname: $firstname,  numbering: $numbering,  email: $email,  socialsn: $socialsn,  imageright: $imageright,  nosharedata: $nosharedata,  nonewsletter: $nonewsletter,  inactive: $inactive,  deceased: $deceased,  customerstatus_id: $customerstatus_id,  customersubstatus_id: $customersubstatus_id,  dietnotes: $dietnotes,  handicapnotes: $handicapnotes,  grouped_customer_id: $grouped_customer_id,  grouped_filiation: $grouped_filiation,  birthdate: $birthdate,  info: $info,  nationality: $nationality,  residence: $residence,  contacts: $contacts,  mobile: $mobile,  phones: $phones,  customeraddress_id: $customeraddress_id,  customeraddress_customers: $customeraddress_customers,  addresses: $addresses,  identitydocs: $identitydocs,  counterhistory: $counterhistory,  person_id: $person_id,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  gendertype_id
  name
  lastname
  firstname
  numbering
  email
  socialsn
  imageright
  nosharedata
  nonewsletter
  inactive
  deceased
  customerstatus_id
  customersubstatus_id
  dietnotes
  handicapnotes
  grouped_customer_id
  grouped_filiation
  birthdate
  info
  nationality
  residence
  contacts
  mobile
  phones
  customeraddress_id
  customeraddress_customers
  addresses
  identitydocs
  counterhistory
  person_id
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id

          }    
        }
        `;
defQls['customer']['Delete'] = gql`
        mutation customerDelete($id: ID!) {
          customerDelete (id: $id) {
              id
  gendertype_id
  name
  lastname
  firstname
  numbering
  email
  socialsn
  imageright
  nosharedata
  nonewsletter
  inactive
  deceased
  customerstatus_id
  customersubstatus_id
  dietnotes
  handicapnotes
  grouped_customer_id
  grouped_filiation
  birthdate
  info
  nationality
  residence
  contacts
  mobile
  phones
  customeraddress_id
  customeraddress_customers
  addresses
  identitydocs
  counterhistory
  person_id
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['customer']['Add'] = gql`
        mutation customerAdd(  $id: ID!,  $gendertype_id: String,  $name: String,  $lastname: String,  $firstname: String,  $numbering: String,  $email: String,  $socialsn: String,  $imageright: Int,  $nosharedata: Boolean,  $nonewsletter: Boolean,  $inactive: Boolean,  $deceased: Boolean,  $customerstatus_id: String,  $customersubstatus_id: String,  $dietnotes: String,  $handicapnotes: String,  $grouped_customer_id: String,  $grouped_filiation: Int,  $birthdate: String,  $info: String,  $nationality: String,  $residence: String,  $contacts: String,  $mobile: String,  $phones: String,  $customeraddress_id: String,  $customeraddress_customers: String,  $addresses: String,  $identitydocs: String,  $counterhistory: Int,  $person_id: ID,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          customerAdd (  id: $id,  gendertype_id: $gendertype_id,  name: $name,  lastname: $lastname,  firstname: $firstname,  numbering: $numbering,  email: $email,  socialsn: $socialsn,  imageright: $imageright,  nosharedata: $nosharedata,  nonewsletter: $nonewsletter,  inactive: $inactive,  deceased: $deceased,  customerstatus_id: $customerstatus_id,  customersubstatus_id: $customersubstatus_id,  dietnotes: $dietnotes,  handicapnotes: $handicapnotes,  grouped_customer_id: $grouped_customer_id,  grouped_filiation: $grouped_filiation,  birthdate: $birthdate,  info: $info,  nationality: $nationality,  residence: $residence,  contacts: $contacts,  mobile: $mobile,  phones: $phones,  customeraddress_id: $customeraddress_id,  customeraddress_customers: $customeraddress_customers,  addresses: $addresses,  identitydocs: $identitydocs,  counterhistory: $counterhistory,  person_id: $person_id,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  gendertype_id
  name
  lastname
  firstname
  numbering
  email
  socialsn
  imageright
  nosharedata
  nonewsletter
  inactive
  deceased
  customerstatus_id
  customersubstatus_id
  dietnotes
  handicapnotes
  grouped_customer_id
  grouped_filiation
  birthdate
  info
  nationality
  residence
  contacts
  mobile
  phones
  customeraddress_id
  customeraddress_customers
  addresses
  identitydocs
  counterhistory
  person_id
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
/*
don't use 'gql', because it will be used directly by fetch(), that use direct string, and no graphql
defQls['getCitiesByCp'] = gql` query getCitiesByCp( $cp: String )  {
*/
/*defQls['getCitiesByCp'] = ` query getCitiesByCp( $cp: String )  {
            getCitiesByCp ( cp: $cp )   {
                id
                name
                city_id
            }
          }
    `;
*/
// With autocomplete, fetch works only with .name variable to look data, does not support another names
defQls['getCitiesByCp'] = ` query getCitiesByCp( $name: String )  {
            getCitiesByCp ( name: $name )   {
                id
                name
                city_name
                city_id
            }    
          }
    `;

defQls['tour'] = {};

defQls['tour']['ListMini'] = gql`
        query TourList ($organizer_id: String, $name: String, $_multidate: String, $_multidate1: String, $_multidate2: String, $_multidateField: String, $inactive: Boolean, $id: ID, $_qlType: String, $_filter: String) {
          tours  (organizer_id: $organizer_id, name: $name, _multidate: $_multidate, _multidate1: $_multidate1, _multidate2: $_multidate2, _multidateField: $_multidateField, inactive: $inactive, id: $id, _qlType: $_qlType, _filter: $_filter) {
               id
  name
  longname
  inactive
  tourcategory_id
  price
  cancelledprice
  acode_id
  acostcode_id
touracode_id  {
    name
 }
touracostcode_id  {
    name
 }

          }    
        }
        `;
defQls['tour']['List'] = gql`
        query TourList ($organizer_id: String, $name: String, $_multidate: String, $_multidate1: String, $_multidate2: String, $_multidateField: String, $inactive: Boolean, $id: ID, $_qlType: String, $_filter: String) {
          tours  (organizer_id: $organizer_id, name: $name, _multidate: $_multidate, _multidate1: $_multidate1, _multidate2: $_multidate2, _multidateField: $_multidateField, inactive: $inactive, id: $id, _qlType: $_qlType, _filter: $_filter) {
             ${strFragments.tour}
          }    
        }
        `;
defQls['tour']['ListPage'] = gql`${strQls.tour.ListPage}`;

defQls['tour']['View'] = gql`
        query TourView($id: ID!, $organizer_id: String) {
          tour (id: $id, organizer_id: $organizer_id)  {
              id
  name
  longname
  country
  description
  seatsmax
  seatsmin
  datestart
  dateend
  inactive
  registrationstart
  registrationend
  tourcategory_id
  onlineregtype
  gcu
  urlgcu
  textgcu
  itinerary
  total
  paid
  balance
  price
  cancelledprice
  acode_id
  acostcode_id
  servicesbystatus
  additionalservices
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id
  tourtourcategory_id  {
    name
 }
  touracode_id  {
    name
 }
  touracostcode_id  {
    name
 }
  tourorganizer_id  {
    name
 }
  tourupdated_user_id  {
    name
 }
  tourcreated_user_id  {
    name
 }

          }    
        }
        `;
defQls['tour']['Update'] = gql`
        mutation tourUpdate(  $id: ID!,  $name: String,  $longname: String,  $country: String,  $description: String,  $seatsmax: Int,  $seatsmin: Int,  $datestart: String,  $dateend: String,  $inactive: Boolean,  $registrationstart: String,  $registrationend: String,  $tourcategory_id: String,  $onlineregtype: Int,  $gcu: Int,  $urlgcu: String,  $textgcu: String,  $itinerary: String,  $total: Float,  $paid: Float,  $balance: Float,  $price: Float,  $cancelledprice: Float,  $acode_id: String,  $acostcode_id: String,  $servicesbystatus: String,  $additionalservices: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          tourUpdate (  id: $id,  name: $name,  longname: $longname,  country: $country,  description: $description,  seatsmax: $seatsmax,  seatsmin: $seatsmin,  datestart: $datestart,  dateend: $dateend,  inactive: $inactive,  registrationstart: $registrationstart,  registrationend: $registrationend,  tourcategory_id: $tourcategory_id,  onlineregtype: $onlineregtype,  gcu: $gcu,  urlgcu: $urlgcu,  textgcu: $textgcu,  itinerary: $itinerary,  total: $total,  paid: $paid,  balance: $balance,  price: $price,  cancelledprice: $cancelledprice,  acode_id: $acode_id,  acostcode_id: $acostcode_id,  servicesbystatus: $servicesbystatus,  additionalservices: $additionalservices,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  longname
  country
  description
  seatsmax
  seatsmin
  datestart
  dateend
  inactive
  registrationstart
  registrationend
  tourcategory_id
  onlineregtype
  gcu
  urlgcu
  textgcu
  itinerary
  total
  paid
  balance
  price
  cancelledprice
  acode_id
  acostcode_id
  servicesbystatus
  additionalservices
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id

          }    
        }
        `;
defQls['tour']['Delete'] = gql`
        mutation tourDelete($id: ID!) {
          tourDelete (id: $id) {
              id
  name
  longname
  country
  description
  seatsmax
  seatsmin
  datestart
  dateend
  inactive
  registrationstart
  registrationend
  tourcategory_id
  onlineregtype
  gcu
  urlgcu
  textgcu
  itinerary
  total
  paid
  balance
  price
  cancelledprice
  acode_id
  acostcode_id
  servicesbystatus
  additionalservices
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['tour']['Add'] = gql`
        mutation tourAdd(  $id: ID!,  $name: String,  $longname: String,  $country: String,  $description: String,  $seatsmax: Int,  $seatsmin: Int,  $datestart: String,  $dateend: String,  $inactive: Boolean,  $registrationstart: String,  $registrationend: String,  $tourcategory_id: String,  $onlineregtype: Int,  $gcu: Int,  $urlgcu: String,  $textgcu: String,  $itinerary: String,  $total: Float,  $paid: Float,  $balance: Float,  $price: Float,  $cancelledprice: Float,  $acode_id: String,  $acostcode_id: String,  $servicesbystatus: String,  $additionalservices: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          tourAdd (  id: $id,  name: $name,  longname: $longname,  country: $country,  description: $description,  seatsmax: $seatsmax,  seatsmin: $seatsmin,  datestart: $datestart,  dateend: $dateend,  inactive: $inactive,  registrationstart: $registrationstart,  registrationend: $registrationend,  tourcategory_id: $tourcategory_id,  onlineregtype: $onlineregtype,  gcu: $gcu,  urlgcu: $urlgcu,  textgcu: $textgcu,  itinerary: $itinerary,  total: $total,  paid: $paid,  balance: $balance,  price: $price,  cancelledprice: $cancelledprice,  acode_id: $acode_id,  acostcode_id: $acostcode_id,  servicesbystatus: $servicesbystatus,  additionalservices: $additionalservices,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  longname
  country
  description
  seatsmax
  seatsmin
  datestart
  dateend
  inactive
  registrationstart
  registrationend
  tourcategory_id
  onlineregtype
  gcu
  urlgcu
  textgcu
  itinerary
  total
  paid
  balance
  price
  cancelledprice
  acode_id
  acostcode_id
  servicesbystatus
  additionalservices
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['getTourCustomerstatuss'] = gql`
  query getTourCustomerstatuss($tourcategory_id: ID) {
    getTourCustomerstatuss(tourcategory_id: $tourcategory_id) {
      id
      name
    }
  }
`;
defQls['getTourCustomerStatusAndServices'] = gql`
  query getTourCustomerStatusAndServices(
    $tour_id: ID
    $customerstatus_id: ID
    $_qlType: String
  ) {
    getTourCustomerStatusAndServices(
      tour_id: $tour_id
      customerstatus_id: $customerstatus_id
      _qlType: $_qlType
    ) {
      id
      name
      nameOnly
      tourprice_id
      substatuss {
        id
        name
        nameOnly
        price
        inputprice
        filteredhotels
        filteredservices
      }
      tourprice_price
      filterbyservice
      filteredservices
      availableservices {
        id
        name
        nameOnly
        amount
        typeEntry
      }
      filterbyhotel
      filteredhotels
      availablehotels {
        id
        name
        priceroom
      }
    }
  }
`;
defQls['setTourCustomerStatusAndServices'] = gql`
  mutation setTourCustomerStatusAndServices(
    $settingsTourCustomerStatus: String
  ) {
    setTourCustomerStatusAndServices(
      settingsTourCustomerStatus: $settingsTourCustomerStatus
    ) {
      answer
      errors {
        key
        value
      }
    }
  }
`;

defQls['tourcategory'] = {};

defQls['tourcategory']['ListMini'] = gql`
        query TourcategoryList ($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          tourcategorys  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
               id
  name

          }    
        }
        `;
defQls['tourcategory']['List'] = gql`
        query TourcategoryList ($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          tourcategorys  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
             ${strFragments.tourcategory}
          }    
        }
        `;
defQls['tourcategory']['ListPage'] = gql`${strQls.tourcategory.ListPage}`;

defQls['tourcategory']['View'] = gql`
        query TourcategoryView($id: ID!, $organizer_id: String) {
          tourcategory (id: $id, organizer_id: $organizer_id)  {
              id
  name
  settings
  filterbycustomerstatus
  filteredcustomerstatuss
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id
  tourcategoryorganizer_id  {
    name
 }
  tourcategoryupdated_user_id  {
    name
 }
  tourcategorycreated_user_id  {
    name
 }

          }    
        }
        `;
defQls['tourcategory']['Update'] = gql`
        mutation tourcategoryUpdate(  $id: ID!,  $name: String!,  $settings: String,  $filterbycustomerstatus: Int,  $filteredcustomerstatuss: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          tourcategoryUpdate (  id: $id,  name: $name,  settings: $settings,  filterbycustomerstatus: $filterbycustomerstatus,  filteredcustomerstatuss: $filteredcustomerstatuss,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  settings
  filterbycustomerstatus
  filteredcustomerstatuss
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id

          }    
        }
        `;
defQls['tourcategory']['Delete'] = gql`
        mutation tourcategoryDelete($id: ID!) {
          tourcategoryDelete (id: $id) {
              id
  name
  settings
  filterbycustomerstatus
  filteredcustomerstatuss
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['tourcategory']['Add'] = gql`
        mutation tourcategoryAdd(  $id: ID!,  $name: String!,  $settings: String,  $filterbycustomerstatus: Int,  $filteredcustomerstatuss: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          tourcategoryAdd (  id: $id,  name: $name,  settings: $settings,  filterbycustomerstatus: $filterbycustomerstatus,  filteredcustomerstatuss: $filteredcustomerstatuss,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  settings
  filterbycustomerstatus
  filteredcustomerstatuss
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['transportation'] = {};

defQls['transportation']['ListMini'] = gql`
        query TransportationList ($organizer_id: String, $tour_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          transportations  (organizer_id: $organizer_id, tour_id: $tour_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
               id
  tour_id
  name
  price
  routeplanner
  seatingconfiguration

          }    
        }
        `;
defQls['transportation']['List'] = gql`
        query TransportationList ($organizer_id: String, $tour_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          transportations  (organizer_id: $organizer_id, tour_id: $tour_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
             ${strFragments.transportation}
          }    
        }
        `;
defQls['transportation']['ListPage'] = gql`${strQls.transportation.ListPage}`;

defQls['transportation']['View'] = gql`
        query TransportationView($id: ID!, $organizer_id: String) {
          transportation (id: $id, organizer_id: $organizer_id)  {
              id
  tour_id
  name
  seats
  price
  driverinfo
  responsibleinfo
  routeplanner
  seatingconfiguration
  bookings
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id
  transportationtour_id  {
    name
    seatsmax
 }
  transportationorganizer_id  {
    name
 }
  transportationupdated_user_id  {
    name
 }
  transportationcreated_user_id  {
    name
 }

          }    
        }
        `;
defQls['transportation']['Update'] = gql`
        mutation transportationUpdate(  $id: ID!,  $tour_id: String,  $name: String,  $seats: Int,  $price: Float,  $driverinfo: String,  $responsibleinfo: String,  $routeplanner: String,  $seatingconfiguration: String,  $bookings: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          transportationUpdate (  id: $id,  tour_id: $tour_id,  name: $name,  seats: $seats,  price: $price,  driverinfo: $driverinfo,  responsibleinfo: $responsibleinfo,  routeplanner: $routeplanner,  seatingconfiguration: $seatingconfiguration,  bookings: $bookings,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  tour_id
  name
  seats
  price
  driverinfo
  responsibleinfo
  routeplanner
  seatingconfiguration
  bookings
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id

          }    
        }
        `;
defQls['transportation']['Delete'] = gql`
        mutation transportationDelete($id: ID!) {
          transportationDelete (id: $id) {
              id
  tour_id
  name
  seats
  price
  driverinfo
  responsibleinfo
  routeplanner
  seatingconfiguration
  bookings
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['transportation']['Add'] = gql`
        mutation transportationAdd(  $id: ID!,  $tour_id: String,  $name: String,  $seats: Int,  $price: Float,  $driverinfo: String,  $responsibleinfo: String,  $routeplanner: String,  $seatingconfiguration: String,  $bookings: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          transportationAdd (  id: $id,  tour_id: $tour_id,  name: $name,  seats: $seats,  price: $price,  driverinfo: $driverinfo,  responsibleinfo: $responsibleinfo,  routeplanner: $routeplanner,  seatingconfiguration: $seatingconfiguration,  bookings: $bookings,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  tour_id
  name
  seats
  price
  driverinfo
  responsibleinfo
  routeplanner
  seatingconfiguration
  bookings
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['getTransportationCustomers'] = gql`
  query getTransportationCustomers(
    $transportation_id: String
    $tour_id: String
  ) {
    getTransportationCustomers(
      transportation_id: $transportation_id
      tour_id: $tour_id
    ) {
      transportation_id
      name
      customer_id
      registration_id
      transportation
      errorT
      realStopId
      services
      __typename
    }
  }
`;

defQls['tourprice'] = {};

defQls['tourprice']['ListMini'] = gql`
        query TourpriceList ($organizer_id: String, $tour_id: String, $id: ID, $_qlType: String, $_filter: String) {
          tourprices  (organizer_id: $organizer_id, tour_id: $tour_id, id: $id, _qlType: $_qlType, _filter: $_filter) {
               id
  customerstatus_id
  tour_id
  price

          }    
        }
        `;
defQls['tourprice']['List'] = gql`
        query TourpriceList ($organizer_id: String, $tour_id: String, $id: ID, $_qlType: String, $_filter: String) {
          tourprices  (organizer_id: $organizer_id, tour_id: $tour_id, id: $id, _qlType: $_qlType, _filter: $_filter) {
             ${strFragments.tourprice}
          }    
        }
        `;
defQls['tourprice']['ListPage'] = gql`${strQls.tourprice.ListPage}`;

defQls['tourprice']['View'] = gql`
        query TourpriceView($id: ID!, $organizer_id: String) {
          tourprice (id: $id, organizer_id: $organizer_id)  {
              id
  customerstatus_id
  tour_id
  price
  substatuss
  notes
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id
  tourpricecustomerstatus_id  {
    name
 }
  tourpricetour_id  {
    name
    seatsmax
 }
  tourpriceorganizer_id  {
    name
 }
  tourpriceupdated_user_id  {
    name
 }
  tourpricecreated_user_id  {
    name
 }

          }    
        }
        `;
defQls['tourprice']['Update'] = gql`
        mutation tourpriceUpdate(  $id: ID!,  $customerstatus_id: String,  $tour_id: String,  $price: Float,  $substatuss: String,  $notes: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          tourpriceUpdate (  id: $id,  customerstatus_id: $customerstatus_id,  tour_id: $tour_id,  price: $price,  substatuss: $substatuss,  notes: $notes,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  customerstatus_id
  tour_id
  price
  substatuss
  notes
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id

          }    
        }
        `;
defQls['tourprice']['Delete'] = gql`
        mutation tourpriceDelete($id: ID!) {
          tourpriceDelete (id: $id) {
              id
  customerstatus_id
  tour_id
  price
  substatuss
  notes
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['tourprice']['Add'] = gql`
        mutation tourpriceAdd(  $id: ID!,  $customerstatus_id: String,  $tour_id: String,  $price: Float,  $substatuss: String,  $notes: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          tourpriceAdd (  id: $id,  customerstatus_id: $customerstatus_id,  tour_id: $tour_id,  price: $price,  substatuss: $substatuss,  notes: $notes,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  customerstatus_id
  tour_id
  price
  substatuss
  notes
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['tourroom'] = {};

defQls['tourroom']['ListMini'] = gql`
        query TourroomList ($organizer_id: String, $tour_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String, $_orders: String) {
          tourrooms  (organizer_id: $organizer_id, tour_id: $tour_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
               id
  hotel_id
  room_type
  name
  tour_id
  isfullname
  people_available
  priceroom
tourroomtour_id  {
    name
 }
tourroomhotel_id  {
    name
 }

          }    
        }
        `;
defQls['tourroom']['List'] = gql`
        query TourroomList ($organizer_id: String, $tour_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String, $_orders: String) {
          tourrooms  (organizer_id: $organizer_id, tour_id: $tour_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
             ${strFragments.tourroom}
          }    
        }
        `;
defQls['tourroom']['ListPage'] = gql`${strQls.tourroom.ListPage}`;

defQls['tourroom']['View'] = gql`
        query TourroomView($id: ID!, $organizer_id: String) {
          tourroom (id: $id, organizer_id: $organizer_id)  {
              id
  hotel_id
  room_type
  name
  tour_id
  _name
  isfullname
  room_quantity
  people_capacity
  people_registered
  people_pre_registered
  people_available
  people_allocated
  people_to_allocate
  priceroom
  bookings
  customers
  room_numbers
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id
  tourroomtour_id  {
    name
 }
  tourroomhotel_id  {
    name
 }
  tourroomorganizer_id  {
    name
 }
  tourroomupdated_user_id  {
    name
 }
  tourroomcreated_user_id  {
    name
 }

          }    
        }
        `;
defQls['tourroom']['Update'] = gql`
        mutation tourroomUpdate(  $id: ID!,  $hotel_id: String,  $room_type: Int,  $name: String,  $tour_id: String,  $_name: String,  $isfullname: Boolean,  $room_quantity: Int,  $people_capacity: Int,  $people_registered: Int,  $people_pre_registered: Int,  $people_available: Int,  $people_allocated: Int,  $people_to_allocate: Int,  $priceroom: Float,  $bookings: String,  $customers: String,  $room_numbers: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          tourroomUpdate (  id: $id,  hotel_id: $hotel_id,  room_type: $room_type,  name: $name,  tour_id: $tour_id,  _name: $_name,  isfullname: $isfullname,  room_quantity: $room_quantity,  people_capacity: $people_capacity,  people_registered: $people_registered,  people_pre_registered: $people_pre_registered,  people_available: $people_available,  people_allocated: $people_allocated,  people_to_allocate: $people_to_allocate,  priceroom: $priceroom,  bookings: $bookings,  customers: $customers,  room_numbers: $room_numbers,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  hotel_id
  room_type
  name
  tour_id
  _name
  isfullname
  room_quantity
  people_capacity
  people_registered
  people_pre_registered
  people_available
  people_allocated
  people_to_allocate
  priceroom
  bookings
  customers
  room_numbers
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id

          }    
        }
        `;
defQls['tourroom']['Delete'] = gql`
        mutation tourroomDelete($id: ID!) {
          tourroomDelete (id: $id) {
              id
  hotel_id
  room_type
  name
  tour_id
  _name
  isfullname
  room_quantity
  people_capacity
  people_registered
  people_pre_registered
  people_available
  people_allocated
  people_to_allocate
  priceroom
  bookings
  customers
  room_numbers
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['tourroom']['Add'] = gql`
        mutation tourroomAdd(  $id: ID!,  $hotel_id: String,  $room_type: Int,  $name: String,  $tour_id: String,  $_name: String,  $isfullname: Boolean,  $room_quantity: Int,  $people_capacity: Int,  $people_registered: Int,  $people_pre_registered: Int,  $people_available: Int,  $people_allocated: Int,  $people_to_allocate: Int,  $priceroom: Float,  $bookings: String,  $customers: String,  $room_numbers: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          tourroomAdd (  id: $id,  hotel_id: $hotel_id,  room_type: $room_type,  name: $name,  tour_id: $tour_id,  _name: $_name,  isfullname: $isfullname,  room_quantity: $room_quantity,  people_capacity: $people_capacity,  people_registered: $people_registered,  people_pre_registered: $people_pre_registered,  people_available: $people_available,  people_allocated: $people_allocated,  people_to_allocate: $people_to_allocate,  priceroom: $priceroom,  bookings: $bookings,  customers: $customers,  room_numbers: $room_numbers,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  hotel_id
  room_type
  name
  tour_id
  _name
  isfullname
  room_quantity
  people_capacity
  people_registered
  people_pre_registered
  people_available
  people_allocated
  people_to_allocate
  priceroom
  bookings
  customers
  room_numbers
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['getTourRoomCustomers'] = gql`
  query getTourRoomCustomers($tourroom_id: String!) {
    getTourRoomCustomers(tourroom_id: $tourroom_id) {
      tourroom_id
      name
      customer_id
      registration_id
      services
      room_shared
      regonline_room_shared
      __typename
    }
  }
`;

defQls['regonline'] = {};

defQls['regonline']['ListMini'] = gql`
        query RegonlineList ($organizer_id: String, $tour_id: ID, $state: Int, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          regonlines  (organizer_id: $organizer_id, tour_id: $tour_id, state: $state, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
               id
  room_type
  state
regonlineperson_id  {
    name
    email
    id
 }
regonlineregistration_id  {
    registrationstatus_id
    id
 }
regonlinecustomer_id  {
    name
    email
    id
 }
regonlinetour_id  {
    name
    seatsmax
    id
 }
tourroomhotel_id  {
    name
 }

          }    
        }
        `;
defQls['regonline']['List'] = gql`
        query RegonlineList ($organizer_id: String, $tour_id: ID, $state: Int, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          regonlines  (organizer_id: $organizer_id, tour_id: $tour_id, state: $state, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
             ${strFragments.regonline}
          }    
        }
        `;
defQls['regonline']['ListPage'] = gql`${strQls.regonline.ListPage}`;

defQls['regonline']['View'] = gql`
        query RegonlineView($id: ID!, $organizer_id: String) {
          regonline (id: $id, organizer_id: $organizer_id)  {
              id
  customer_id
  person_id
  tour_id
  registration_id
  customerstatus_id
  customersubstatus_id
  dateregistration
  tourroom_id
  room_type
  room_shared
  stopspoint_id
  items
  objects
  price
  partialpayment
  registrationstatus_id
  signature
  paid
  payinput
  payoutput
  paymethod
  state
  notes
  person_groupid
  modetest
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id
  regonlineperson_id  {
    name
    email
    id
 }
  regonlineregistration_id  {
    registrationstatus_id
    id
 }
  regonlinecustomer_id  {
    name
    email
    id
 }
  regonlinetour_id  {
    name
    seatsmax
    id
 }
  regonlinetourroom_id  {
    room_type
    hotel_id
  tourroomhotel_id  {
    name
 }
    id
 }
  regonlineregistrationstatus_id  {
    name
    color
    statusbase
    id
 }
  regonlinestopspoint_id  {
    name
    id
 }
  regonlinecustomerstatus_id  {
    name
    id
 }
  regonlineorganizer_id  {
    name
    id
 }
  regonlineupdated_user_id  {
    name
    id
 }
  regonlinecreated_user_id  {
    name
    id
 }

          }    
        }
        `;
defQls['regonline']['Update'] = gql`
        mutation regonlineUpdate(  $id: ID!,  $customer_id: ID,  $person_id: ID,  $tour_id: ID,  $registration_id: ID,  $customerstatus_id: String,  $customersubstatus_id: String,  $dateregistration: String,  $tourroom_id: String,  $room_type: Int,  $room_shared: String,  $stopspoint_id: String,  $items: String,  $objects: String,  $price: Float,  $partialpayment: Float,  $registrationstatus_id: String,  $signature: String,  $paid: Float,  $payinput: String,  $payoutput: String,  $paymethod: Int,  $state: Int,  $notes: String,  $person_groupid: String,  $modetest: Boolean,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          regonlineUpdate (  id: $id,  customer_id: $customer_id,  person_id: $person_id,  tour_id: $tour_id,  registration_id: $registration_id,  customerstatus_id: $customerstatus_id,  customersubstatus_id: $customersubstatus_id,  dateregistration: $dateregistration,  tourroom_id: $tourroom_id,  room_type: $room_type,  room_shared: $room_shared,  stopspoint_id: $stopspoint_id,  items: $items,  objects: $objects,  price: $price,  partialpayment: $partialpayment,  registrationstatus_id: $registrationstatus_id,  signature: $signature,  paid: $paid,  payinput: $payinput,  payoutput: $payoutput,  paymethod: $paymethod,  state: $state,  notes: $notes,  person_groupid: $person_groupid,  modetest: $modetest,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  customer_id
  person_id
  tour_id
  registration_id
  customerstatus_id
  customersubstatus_id
  dateregistration
  tourroom_id
  room_type
  room_shared
  stopspoint_id
  items
  objects
  price
  partialpayment
  registrationstatus_id
  signature
  paid
  payinput
  payoutput
  paymethod
  state
  notes
  person_groupid
  modetest
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id

          }    
        }
        `;
defQls['regonline']['Delete'] = gql`
        mutation regonlineDelete($id: ID!) {
          regonlineDelete (id: $id) {
              id
  customer_id
  person_id
  tour_id
  registration_id
  customerstatus_id
  customersubstatus_id
  dateregistration
  tourroom_id
  room_type
  room_shared
  stopspoint_id
  items
  objects
  price
  partialpayment
  registrationstatus_id
  signature
  paid
  payinput
  payoutput
  paymethod
  state
  notes
  person_groupid
  modetest
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['regonline']['Add'] = gql`
        mutation regonlineAdd(  $id: ID!,  $customer_id: ID,  $person_id: ID,  $tour_id: ID,  $registration_id: ID,  $customerstatus_id: String,  $customersubstatus_id: String,  $dateregistration: String,  $tourroom_id: String,  $room_type: Int,  $room_shared: String,  $stopspoint_id: String,  $items: String,  $objects: String,  $price: Float,  $partialpayment: Float,  $registrationstatus_id: String,  $signature: String,  $paid: Float,  $payinput: String,  $payoutput: String,  $paymethod: Int,  $state: Int,  $notes: String,  $person_groupid: String,  $modetest: Boolean,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          regonlineAdd (  id: $id,  customer_id: $customer_id,  person_id: $person_id,  tour_id: $tour_id,  registration_id: $registration_id,  customerstatus_id: $customerstatus_id,  customersubstatus_id: $customersubstatus_id,  dateregistration: $dateregistration,  tourroom_id: $tourroom_id,  room_type: $room_type,  room_shared: $room_shared,  stopspoint_id: $stopspoint_id,  items: $items,  objects: $objects,  price: $price,  partialpayment: $partialpayment,  registrationstatus_id: $registrationstatus_id,  signature: $signature,  paid: $paid,  payinput: $payinput,  payoutput: $payoutput,  paymethod: $paymethod,  state: $state,  notes: $notes,  person_groupid: $person_groupid,  modetest: $modetest,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  customer_id
  person_id
  tour_id
  registration_id
  customerstatus_id
  customersubstatus_id
  dateregistration
  tourroom_id
  room_type
  room_shared
  stopspoint_id
  items
  objects
  price
  partialpayment
  registrationstatus_id
  signature
  paid
  payinput
  payoutput
  paymethod
  state
  notes
  person_groupid
  modetest
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
// needed for regonline view
defQls['getTourServices'] = gql`
  query getTourServices($tour_id: ID!) {
    getTourServices(tour_id: $tour_id) {
      id
      idOnly
      name
      nameOnly
      tour_id
      operator
      amount
      typeEntry
      listOptions {
        id
        name
        nameOnly
        amount
      }
      cancellednotzero
      service_id
    }
  }
`;

defQls['registration'] = {};

defQls['registration']['ListMini'] = gql`
        query RegistrationList ($organizer_id: String, $numbering: String, $customer_id: ID, $tour_id: ID, $transportation_transportation_id: String, $tourroom_id: String, $registrationstatus_id_statusbase: Int, $customerstatus_id: String, $customersubstatus_id: String, $confirmationstatus_id_statusbase: Int, $_balance: String, $assignedroom: Boolean, $_channel: String, $_withmobile: String, $inactive: Boolean, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          registrations  (organizer_id: $organizer_id, numbering: $numbering, customer_id: $customer_id, tour_id: $tour_id, transportation_transportation_id: $transportation_transportation_id, tourroom_id: $tourroom_id, registrationstatus_id_statusbase: $registrationstatus_id_statusbase, customerstatus_id: $customerstatus_id, customersubstatus_id: $customersubstatus_id, confirmationstatus_id_statusbase: $confirmationstatus_id_statusbase, _balance: $_balance, assignedroom: $assignedroom, _channel: $_channel, _withmobile: $_withmobile, inactive: $inactive, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
               id
registrationcustomer_id  {
    name
    email
 }
registrationtour_id  {
    name
    seatsmax
 }
registrationregonline_id  {
    state
 }
tourroomhotel_id  {
    name
 }

          }    
        }
        `;
defQls['registration']['List'] = gql`
        query RegistrationList ($organizer_id: String, $numbering: String, $customer_id: ID, $tour_id: ID, $transportation_transportation_id: String, $tourroom_id: String, $registrationstatus_id_statusbase: Int, $customerstatus_id: String, $customersubstatus_id: String, $confirmationstatus_id_statusbase: Int, $_balance: String, $assignedroom: Boolean, $_channel: String, $_withmobile: String, $inactive: Boolean, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          registrations  (organizer_id: $organizer_id, numbering: $numbering, customer_id: $customer_id, tour_id: $tour_id, transportation_transportation_id: $transportation_transportation_id, tourroom_id: $tourroom_id, registrationstatus_id_statusbase: $registrationstatus_id_statusbase, customerstatus_id: $customerstatus_id, customersubstatus_id: $customersubstatus_id, confirmationstatus_id_statusbase: $confirmationstatus_id_statusbase, _balance: $_balance, assignedroom: $assignedroom, _channel: $_channel, _withmobile: $_withmobile, inactive: $inactive, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
             ${strFragments.registration}
          }    
        }
        `;
defQls['registration']['ListPage'] = gql`${strQls.registration.ListPage}`;

defQls['registration']['View'] = gql`
        query RegistrationView($id: ID!, $organizer_id: String) {
          registration (id: $id, organizer_id: $organizer_id)  {
              id
  customer_id
  registrationgroupping_customers
  regonline_id
  person_id
  tour_id
  _services
  numbering
  registrationstatus_id
  dateregistration
  confirmationstatus_id
  inactive
  customerstatus_id
  customersubstatus_id
  tourroom_id
  room_shared
  assignedroom
  _roommates
  tollfree
  price
  paid
  balance
  _priceformula
  transportation
  objects
  notes
  counterhistory
  person_groupid
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id
  registrationcustomer_id  {
    name
    email
 }
  registrationtour_id  {
    name
    seatsmax
 }
  registrationregonline_id  {
    person_id
    registration_id
    customersubstatus_id
    dateregistration
    room_type
    room_shared
    stopspoint_id
    items
    objects
    price
    partialpayment
    signature
    paid
    payinput
    payoutput
    paymethod
    state
    notes
    person_groupid
    modetest
    deleted
 }
  registrationtourroom_id  {
    room_type
    hotel_id
  tourroomhotel_id  {
    name
 }
 }
  registrationregistrationstatus_id  {
    name
    color
    statusbase
 }
  registrationconfirmationstatus_id  {
    name
    color
 }
  registrationcustomerstatus_id  {
    name
 }
  registrationorganizer_id  {
    name
 }
  registrationupdated_user_id  {
    name
 }
  registrationcreated_user_id  {
    name
 }

          }    
        }
        `;
defQls['registration']['Update'] = gql`
        mutation registrationUpdate(  $id: ID!,  $customer_id: ID,  $registrationgroupping_customers: String,  $regonline_id: ID,  $person_id: ID,  $tour_id: ID,  $_services: String,  $numbering: String,  $registrationstatus_id: String,  $dateregistration: String,  $confirmationstatus_id: String,  $inactive: Boolean,  $customerstatus_id: String,  $customersubstatus_id: String,  $tourroom_id: String,  $room_shared: String,  $assignedroom: Boolean,  $_roommates: String,  $tollfree: Boolean,  $price: Float,  $paid: Float,  $balance: Float,  $_priceformula: String,  $transportation: String,  $objects: String,  $notes: String,  $counterhistory: Int,  $person_groupid: ID,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          registrationUpdate (  id: $id,  customer_id: $customer_id,  registrationgroupping_customers: $registrationgroupping_customers,  regonline_id: $regonline_id,  person_id: $person_id,  tour_id: $tour_id,  _services: $_services,  numbering: $numbering,  registrationstatus_id: $registrationstatus_id,  dateregistration: $dateregistration,  confirmationstatus_id: $confirmationstatus_id,  inactive: $inactive,  customerstatus_id: $customerstatus_id,  customersubstatus_id: $customersubstatus_id,  tourroom_id: $tourroom_id,  room_shared: $room_shared,  assignedroom: $assignedroom,  _roommates: $_roommates,  tollfree: $tollfree,  price: $price,  paid: $paid,  balance: $balance,  _priceformula: $_priceformula,  transportation: $transportation,  objects: $objects,  notes: $notes,  counterhistory: $counterhistory,  person_groupid: $person_groupid,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  customer_id
  registrationgroupping_customers
  regonline_id
  person_id
  tour_id
  _services
  numbering
  registrationstatus_id
  dateregistration
  confirmationstatus_id
  inactive
  customerstatus_id
  customersubstatus_id
  tourroom_id
  room_shared
  assignedroom
  _roommates
  tollfree
  price
  paid
  balance
  _priceformula
  transportation
  objects
  notes
  counterhistory
  person_groupid
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id

          }    
        }
        `;
defQls['registration']['Delete'] = gql`
        mutation registrationDelete($id: ID!) {
          registrationDelete (id: $id) {
              id
  customer_id
  registrationgroupping_customers
  regonline_id
  person_id
  tour_id
  _services
  numbering
  registrationstatus_id
  dateregistration
  confirmationstatus_id
  inactive
  customerstatus_id
  customersubstatus_id
  tourroom_id
  room_shared
  assignedroom
  _roommates
  tollfree
  price
  paid
  balance
  _priceformula
  transportation
  objects
  notes
  counterhistory
  person_groupid
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['registration']['Add'] = gql`
        mutation registrationAdd(  $id: ID!,  $customer_id: ID,  $registrationgroupping_customers: String,  $regonline_id: ID,  $person_id: ID,  $tour_id: ID,  $_services: String,  $numbering: String,  $registrationstatus_id: String,  $dateregistration: String,  $confirmationstatus_id: String,  $inactive: Boolean,  $customerstatus_id: String,  $customersubstatus_id: String,  $tourroom_id: String,  $room_shared: String,  $assignedroom: Boolean,  $_roommates: String,  $tollfree: Boolean,  $price: Float,  $paid: Float,  $balance: Float,  $_priceformula: String,  $transportation: String,  $objects: String,  $notes: String,  $counterhistory: Int,  $person_groupid: ID,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          registrationAdd (  id: $id,  customer_id: $customer_id,  registrationgroupping_customers: $registrationgroupping_customers,  regonline_id: $regonline_id,  person_id: $person_id,  tour_id: $tour_id,  _services: $_services,  numbering: $numbering,  registrationstatus_id: $registrationstatus_id,  dateregistration: $dateregistration,  confirmationstatus_id: $confirmationstatus_id,  inactive: $inactive,  customerstatus_id: $customerstatus_id,  customersubstatus_id: $customersubstatus_id,  tourroom_id: $tourroom_id,  room_shared: $room_shared,  assignedroom: $assignedroom,  _roommates: $_roommates,  tollfree: $tollfree,  price: $price,  paid: $paid,  balance: $balance,  _priceformula: $_priceformula,  transportation: $transportation,  objects: $objects,  notes: $notes,  counterhistory: $counterhistory,  person_groupid: $person_groupid,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  customer_id
  registrationgroupping_customers
  regonline_id
  person_id
  tour_id
  _services
  numbering
  registrationstatus_id
  dateregistration
  confirmationstatus_id
  inactive
  customerstatus_id
  customersubstatus_id
  tourroom_id
  room_shared
  assignedroom
  _roommates
  tollfree
  price
  paid
  balance
  _priceformula
  transportation
  objects
  notes
  counterhistory
  person_groupid
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
// these def go to global defQls , and disponible for all cruds forms or components

defQls['getTourStopsPoints'] = gql`
  query getTourStopsPoints {
    getTourStopsPoints {
      id
      realstopid
      name
      tour_id
      transportation_id
      price
    }
  }
`;

defQls['getTourWagons'] = gql`
  query getTourWagons {
    getTourWagons {
      id
      name
      tour_id
      transportation_id
      otherinfo
      seatingrange
    }
  }
`;

defQls['customerhistory'] = {};

defQls['customerhistory']['ListMini'] = gql`
        query CustomerhistoryList ($organizer_id: String, $customer_id: String, $id: ID, $_qlType: String, $_filter: String) {
          customerhistorys  (organizer_id: $organizer_id, customer_id: $customer_id, id: $id, _qlType: $_qlType, _filter: $_filter) {
               id

          }    
        }
        `;
defQls['customerhistory']['List'] = gql`
        query CustomerhistoryList ($organizer_id: String, $customer_id: String, $id: ID, $_qlType: String, $_filter: String) {
          customerhistorys  (organizer_id: $organizer_id, customer_id: $customer_id, id: $id, _qlType: $_qlType, _filter: $_filter) {
             ${strFragments.customerhistory}
          }    
        }
        `;
defQls['customerhistory']['ListPage'] = gql`${strQls.customerhistory.ListPage}`;

defQls['customerhistory']['View'] = gql`
        query CustomerhistoryView($id: ID!, $organizer_id: String) {
          customerhistory (id: $id, organizer_id: $organizer_id)  {
              id
  actiondate
  user_id
  customer_id
  changes
  oldchanges
  organizer_id
  customerhistoryuser_id  {
    name
 }
  customerhistorycustomer_id  {
    name
 }
  customerhistoryorganizer_id  {
    name
 }

          }    
        }
        `;
defQls['customerhistory']['Update'] = gql`
        mutation customerhistoryUpdate(  $id: ID!,  $actiondate: String,  $user_id: String,  $customer_id: String,  $changes: String,  $oldchanges: String,  $organizer_id: String) {
          customerhistoryUpdate (  id: $id,  actiondate: $actiondate,  user_id: $user_id,  customer_id: $customer_id,  changes: $changes,  oldchanges: $oldchanges,  organizer_id: $organizer_id) {
              id
  actiondate
  user_id
  customer_id
  changes
  oldchanges

          }    
        }
        `;
defQls['customerhistory']['Add'] = gql`
        mutation customerhistoryAdd(  $id: ID!,  $actiondate: String,  $user_id: String,  $customer_id: String,  $changes: String,  $oldchanges: String,  $organizer_id: String) {
          customerhistoryAdd (  id: $id,  actiondate: $actiondate,  user_id: $user_id,  customer_id: $customer_id,  changes: $changes,  oldchanges: $oldchanges,  organizer_id: $organizer_id) {
              id
  actiondate
  user_id
  customer_id
  changes
  oldchanges
  organizer_id

          }    
        }
        `;
defQls['paymenthistory'] = {};

defQls['paymenthistory']['ListMini'] = gql`
        query PaymenthistoryList ($organizer_id: String, $payment_id: String, $id: ID, $_qlType: String, $_filter: String) {
          paymenthistorys  (organizer_id: $organizer_id, payment_id: $payment_id, id: $id, _qlType: $_qlType, _filter: $_filter) {
               id

          }    
        }
        `;
defQls['paymenthistory']['List'] = gql`
        query PaymenthistoryList ($organizer_id: String, $payment_id: String, $id: ID, $_qlType: String, $_filter: String) {
          paymenthistorys  (organizer_id: $organizer_id, payment_id: $payment_id, id: $id, _qlType: $_qlType, _filter: $_filter) {
             ${strFragments.paymenthistory}
          }    
        }
        `;
defQls['paymenthistory']['ListPage'] = gql`${strQls.paymenthistory.ListPage}`;

defQls['paymenthistory']['View'] = gql`
        query PaymenthistoryView($id: ID!, $organizer_id: String) {
          paymenthistory (id: $id, organizer_id: $organizer_id)  {
              id
  actiondate
  user_id
  payment_id
  changes
  oldchanges
  organizer_id
  paymenthistoryuser_id  {
    name
 }
  paymenthistorypayment_id  {
    name
 }
  paymenthistoryorganizer_id  {
    name
 }

          }    
        }
        `;
defQls['paymenthistory']['Update'] = gql`
        mutation paymenthistoryUpdate(  $id: ID!,  $actiondate: String,  $user_id: String,  $payment_id: String,  $changes: String,  $oldchanges: String,  $organizer_id: String) {
          paymenthistoryUpdate (  id: $id,  actiondate: $actiondate,  user_id: $user_id,  payment_id: $payment_id,  changes: $changes,  oldchanges: $oldchanges,  organizer_id: $organizer_id) {
              id
  actiondate
  user_id
  payment_id
  changes
  oldchanges

          }    
        }
        `;
defQls['paymenthistory']['Add'] = gql`
        mutation paymenthistoryAdd(  $id: ID!,  $actiondate: String,  $user_id: String,  $payment_id: String,  $changes: String,  $oldchanges: String,  $organizer_id: String) {
          paymenthistoryAdd (  id: $id,  actiondate: $actiondate,  user_id: $user_id,  payment_id: $payment_id,  changes: $changes,  oldchanges: $oldchanges,  organizer_id: $organizer_id) {
              id
  actiondate
  user_id
  payment_id
  changes
  oldchanges
  organizer_id

          }    
        }
        `;
defQls['registrationhistory'] = {};

defQls['registrationhistory']['ListMini'] = gql`
        query RegistrationhistoryList ($organizer_id: String, $registration_id: String, $id: ID, $_qlType: String, $_filter: String) {
          registrationhistorys  (organizer_id: $organizer_id, registration_id: $registration_id, id: $id, _qlType: $_qlType, _filter: $_filter) {
               id

          }    
        }
        `;
defQls['registrationhistory']['List'] = gql`
        query RegistrationhistoryList ($organizer_id: String, $registration_id: String, $id: ID, $_qlType: String, $_filter: String) {
          registrationhistorys  (organizer_id: $organizer_id, registration_id: $registration_id, id: $id, _qlType: $_qlType, _filter: $_filter) {
             ${strFragments.registrationhistory}
          }    
        }
        `;
defQls['registrationhistory']['ListPage'] = gql`${strQls.registrationhistory.ListPage}`;

defQls['registrationhistory']['View'] = gql`
        query RegistrationhistoryView($id: ID!, $organizer_id: String) {
          registrationhistory (id: $id, organizer_id: $organizer_id)  {
              id
  actiondate
  user_id
  customer_id
  tour_id
  tourroom_id
  dateregistration
  registrationstatus_id
  confirmationstatus_id
  notes
  registration_id
  changes
  oldchanges
  organizer_id
  registrationhistoryuser_id  {
    name
 }
  registrationhistorycustomer_id  {
    name
 }
  registrationhistorytour_id  {
    name
    seatsmax
 }
  registrationhistorytourroom_id  {
    name
 }
  registrationhistoryregistrationstatus_id  {
    name
    color
    statusbase
 }
  registrationhistoryconfirmationstatus_id  {
    name
    color
 }
  registrationhistoryregistration_id  {
    registrationstatus_id
 }
  registrationhistoryorganizer_id  {
    name
 }

          }    
        }
        `;
defQls['registrationhistory']['Update'] = gql`
        mutation registrationhistoryUpdate(  $id: ID!,  $actiondate: String,  $user_id: String,  $customer_id: String,  $tour_id: String,  $tourroom_id: String,  $dateregistration: String,  $registrationstatus_id: String,  $confirmationstatus_id: String,  $notes: String,  $registration_id: String,  $changes: String,  $oldchanges: String,  $organizer_id: String) {
          registrationhistoryUpdate (  id: $id,  actiondate: $actiondate,  user_id: $user_id,  customer_id: $customer_id,  tour_id: $tour_id,  tourroom_id: $tourroom_id,  dateregistration: $dateregistration,  registrationstatus_id: $registrationstatus_id,  confirmationstatus_id: $confirmationstatus_id,  notes: $notes,  registration_id: $registration_id,  changes: $changes,  oldchanges: $oldchanges,  organizer_id: $organizer_id) {
              id
  actiondate
  user_id
  customer_id
  tour_id
  tourroom_id
  dateregistration
  registrationstatus_id
  confirmationstatus_id
  notes
  registration_id
  changes
  oldchanges

          }    
        }
        `;
defQls['registrationhistory']['Add'] = gql`
        mutation registrationhistoryAdd(  $id: ID!,  $actiondate: String,  $user_id: String,  $customer_id: String,  $tour_id: String,  $tourroom_id: String,  $dateregistration: String,  $registrationstatus_id: String,  $confirmationstatus_id: String,  $notes: String,  $registration_id: String,  $changes: String,  $oldchanges: String,  $organizer_id: String) {
          registrationhistoryAdd (  id: $id,  actiondate: $actiondate,  user_id: $user_id,  customer_id: $customer_id,  tour_id: $tour_id,  tourroom_id: $tourroom_id,  dateregistration: $dateregistration,  registrationstatus_id: $registrationstatus_id,  confirmationstatus_id: $confirmationstatus_id,  notes: $notes,  registration_id: $registration_id,  changes: $changes,  oldchanges: $oldchanges,  organizer_id: $organizer_id) {
              id
  actiondate
  user_id
  customer_id
  tour_id
  tourroom_id
  dateregistration
  registrationstatus_id
  confirmationstatus_id
  notes
  registration_id
  changes
  oldchanges
  organizer_id

          }    
        }
        `;
defQls['payment'] = {};

defQls['payment']['ListMini'] = gql`
        query PaymentList ($check_number: String, $organizer_id: String, $application_customer_id: String, $application_tour_id: String, $paymentdocstatus_id: String, $paymentmethod_id: String, $paymentmethod_id_ajournalcode_id: String, $_balanceinit: Float, $name: String, $amount: Float, $_depositedcheck: Int, $_multidate: String, $_multidate1: String, $_multidate2: String, $_multidateField: String, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          payments  (check_number: $check_number, organizer_id: $organizer_id, application_customer_id: $application_customer_id, application_tour_id: $application_tour_id, paymentdocstatus_id: $paymentdocstatus_id, paymentmethod_id: $paymentmethod_id, paymentmethod_id_ajournalcode_id: $paymentmethod_id_ajournalcode_id, _balanceinit: $_balanceinit, name: $name, amount: $amount, _depositedcheck: $_depositedcheck, _multidate: $_multidate, _multidate1: $_multidate1, _multidate2: $_multidate2, _multidateField: $_multidateField, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
               id
  name
  amount
  datereception
  datedeferred
  dateaccounting
  bank_id
  info

          }    
        }
        `;
defQls['payment']['List'] = gql`
        query PaymentList ($check_number: String, $organizer_id: String, $application_customer_id: String, $application_tour_id: String, $paymentdocstatus_id: String, $paymentmethod_id: String, $paymentmethod_id_ajournalcode_id: String, $_balanceinit: Float, $name: String, $amount: Float, $_depositedcheck: Int, $_multidate: String, $_multidate1: String, $_multidate2: String, $_multidateField: String, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          payments  (check_number: $check_number, organizer_id: $organizer_id, application_customer_id: $application_customer_id, application_tour_id: $application_tour_id, paymentdocstatus_id: $paymentdocstatus_id, paymentmethod_id: $paymentmethod_id, paymentmethod_id_ajournalcode_id: $paymentmethod_id_ajournalcode_id, _balanceinit: $_balanceinit, name: $name, amount: $amount, _depositedcheck: $_depositedcheck, _multidate: $_multidate, _multidate1: $_multidate1, _multidate2: $_multidate2, _multidateField: $_multidateField, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
             ${strFragments.payment}
          }    
        }
        `;
defQls['payment']['ListPage'] = gql`${strQls.payment.ListPage}`;

defQls['payment']['View'] = gql`
        query PaymentView($id: ID!, $organizer_id: String) {
          payment (id: $id, organizer_id: $organizer_id)  {
              id
  name
  paymentmethod_id
  amount
  datereception
  datedeferred
  dateaccounting
  bank_id
  paymentdocstatus_id
  application
  aentry
  balance
  info
  checkdeposit_id
  counterhistory
  person_groupid
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id
  paymentbank_id  {
    name
 }
  paymentpaymentmethod_id  {
    name
    color
    statusbase
    usedatedeferred
 }
  paymentcheckdeposit_id  {
    name
 }
  paymentpaymentdocstatus_id  {
    name
    color
    statusbase
 }
  paymentorganizer_id  {
    name
 }
  paymentupdated_user_id  {
    name
 }
  paymentcreated_user_id  {
    name
 }

          }    
        }
        `;
defQls['payment']['Update'] = gql`
        mutation paymentUpdate(  $id: ID!,  $name: String,  $paymentmethod_id: String,  $amount: Float,  $datereception: String,  $datedeferred: String,  $dateaccounting: String,  $bank_id: String,  $paymentdocstatus_id: String,  $application: String,  $aentry: String,  $balance: Float,  $info: String,  $checkdeposit_id: String,  $counterhistory: Int,  $person_groupid: ID,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          paymentUpdate (  id: $id,  name: $name,  paymentmethod_id: $paymentmethod_id,  amount: $amount,  datereception: $datereception,  datedeferred: $datedeferred,  dateaccounting: $dateaccounting,  bank_id: $bank_id,  paymentdocstatus_id: $paymentdocstatus_id,  application: $application,  aentry: $aentry,  balance: $balance,  info: $info,  checkdeposit_id: $checkdeposit_id,  counterhistory: $counterhistory,  person_groupid: $person_groupid,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  paymentmethod_id
  amount
  datereception
  datedeferred
  dateaccounting
  bank_id
  paymentdocstatus_id
  application
  aentry
  balance
  info
  checkdeposit_id
  counterhistory
  person_groupid
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id

          }    
        }
        `;
defQls['payment']['Delete'] = gql`
        mutation paymentDelete($id: ID!) {
          paymentDelete (id: $id) {
              id
  name
  paymentmethod_id
  amount
  datereception
  datedeferred
  dateaccounting
  bank_id
  paymentdocstatus_id
  application
  aentry
  balance
  info
  checkdeposit_id
  counterhistory
  person_groupid
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['payment']['Add'] = gql`
        mutation paymentAdd(  $id: ID!,  $name: String,  $paymentmethod_id: String,  $amount: Float,  $datereception: String,  $datedeferred: String,  $dateaccounting: String,  $bank_id: String,  $paymentdocstatus_id: String,  $application: String,  $aentry: String,  $balance: Float,  $info: String,  $checkdeposit_id: String,  $counterhistory: Int,  $person_groupid: ID,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          paymentAdd (  id: $id,  name: $name,  paymentmethod_id: $paymentmethod_id,  amount: $amount,  datereception: $datereception,  datedeferred: $datedeferred,  dateaccounting: $dateaccounting,  bank_id: $bank_id,  paymentdocstatus_id: $paymentdocstatus_id,  application: $application,  aentry: $aentry,  balance: $balance,  info: $info,  checkdeposit_id: $checkdeposit_id,  counterhistory: $counterhistory,  person_groupid: $person_groupid,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  paymentmethod_id
  amount
  datereception
  datedeferred
  dateaccounting
  bank_id
  paymentdocstatus_id
  application
  aentry
  balance
  info
  checkdeposit_id
  counterhistory
  person_groupid
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
// only defined one time, is going to insert globallyl in defQks.js
// then don't need for checkdepositdetList that use
defQls['setCheckDepositedDet'] = gql`
  mutation setCheckDepositedDet(
    $payments: String
    $source: String
    $checkdeposithead_id: String
  ) {
    setCheckDepositedDet(
      payments: $payments
      source: $source
      checkdeposithead_id: $checkdeposithead_id
    ) {
      answer
      errors {
        key
        value
      }
    }
  }
`;

defQls['checkdeposithead'] = {};

defQls['checkdeposithead']['ListMini'] = gql`
        query CheckdepositheadList ($organizer_id: String, $name: String, $id: ID, $_qlType: String, $_filter: String) {
          checkdepositheads  (organizer_id: $organizer_id, name: $name, id: $id, _qlType: $_qlType, _filter: $_filter) {
               id
  name
  statusbase

          }    
        }
        `;
defQls['checkdeposithead']['List'] = gql`
        query CheckdepositheadList ($organizer_id: String, $name: String, $id: ID, $_qlType: String, $_filter: String) {
          checkdepositheads  (organizer_id: $organizer_id, name: $name, id: $id, _qlType: $_qlType, _filter: $_filter) {
             ${strFragments.checkdeposithead}
          }    
        }
        `;
defQls['checkdeposithead']['ListPage'] = gql`${strQls.checkdeposithead.ListPage}`;

defQls['checkdeposithead']['View'] = gql`
        query CheckdepositheadView($id: ID!, $organizer_id: String) {
          checkdeposithead (id: $id, organizer_id: $organizer_id)  {
              id
  name
  statusbase
  date
  dateaccounting
  info
  lastcheckdepositopen
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id
  checkdepositheadorganizer_id  {
    name
 }
  checkdepositheadupdated_user_id  {
    name
 }
  checkdepositheadcreated_user_id  {
    name
 }

          }    
        }
        `;
defQls['checkdeposithead']['Update'] = gql`
        mutation checkdepositheadUpdate(  $id: ID!,  $name: String,  $statusbase: Int,  $date: String,  $dateaccounting: String,  $info: String,  $lastcheckdepositopen: ID,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          checkdepositheadUpdate (  id: $id,  name: $name,  statusbase: $statusbase,  date: $date,  dateaccounting: $dateaccounting,  info: $info,  lastcheckdepositopen: $lastcheckdepositopen,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  statusbase
  date
  dateaccounting
  info
  lastcheckdepositopen
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id

          }    
        }
        `;
defQls['checkdeposithead']['Delete'] = gql`
        mutation checkdepositheadDelete($id: ID!) {
          checkdepositheadDelete (id: $id) {
              id
  name
  statusbase
  date
  dateaccounting
  info
  lastcheckdepositopen
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['checkdeposithead']['Add'] = gql`
        mutation checkdepositheadAdd(  $id: ID!,  $name: String,  $statusbase: Int,  $date: String,  $dateaccounting: String,  $info: String,  $lastcheckdepositopen: ID,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          checkdepositheadAdd (  id: $id,  name: $name,  statusbase: $statusbase,  date: $date,  dateaccounting: $dateaccounting,  info: $info,  lastcheckdepositopen: $lastcheckdepositopen,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  statusbase
  date
  dateaccounting
  info
  lastcheckdepositopen
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['checkdepositdet'] = {};

defQls['checkdepositdet']['ListMini'] = gql`
        query CheckdepositdetList ($organizer_id: String, $checkdeposithead_id: String, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          checkdepositdets  (organizer_id: $organizer_id, checkdeposithead_id: $checkdeposithead_id, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
               id
checkdepositdetpayment_id  {
    name
    datereception
    datedeferred
    dateaccounting
    bank_id
info
    amount
    application
 }

          }    
        }
        `;
defQls['checkdepositdet']['List'] = gql`
        query CheckdepositdetList ($organizer_id: String, $checkdeposithead_id: String, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          checkdepositdets  (organizer_id: $organizer_id, checkdeposithead_id: $checkdeposithead_id, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
             ${strFragments.checkdepositdet}
          }    
        }
        `;
defQls['checkdepositdet']['ListPage'] = gql`${strQls.checkdepositdet.ListPage}`;

defQls['checkdepositdet']['View'] = gql`
        query CheckdepositdetView($id: ID!, $organizer_id: String) {
          checkdepositdet (id: $id, organizer_id: $organizer_id)  {
              id
  payment_id
  checkdeposithead_id
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id
  checkdepositdetpayment_id  {
    name
    datereception
    datedeferred
    dateaccounting
    bank_id
  paymentbank_id  {
    name
 }
    info
    amount
    application
 }
  checkdepositdetcheckdeposithead_id  {
    name
 }
  checkdepositdetorganizer_id  {
    name
 }
  checkdepositdetupdated_user_id  {
    name
 }
  checkdepositdetcreated_user_id  {
    name
 }

          }    
        }
        `;
defQls['checkdepositdet']['Update'] = gql`
        mutation checkdepositdetUpdate(  $id: ID!,  $payment_id: String,  $checkdeposithead_id: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          checkdepositdetUpdate (  id: $id,  payment_id: $payment_id,  checkdeposithead_id: $checkdeposithead_id,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  payment_id
  checkdeposithead_id
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id

          }    
        }
        `;
defQls['checkdepositdet']['Delete'] = gql`
        mutation checkdepositdetDelete($id: ID!) {
          checkdepositdetDelete (id: $id) {
              id
  payment_id
  checkdeposithead_id
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['checkdepositdet']['Add'] = gql`
        mutation checkdepositdetAdd(  $id: ID!,  $payment_id: String,  $checkdeposithead_id: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          checkdepositdetAdd (  id: $id,  payment_id: $payment_id,  checkdeposithead_id: $checkdeposithead_id,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  payment_id
  checkdeposithead_id
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['export'] = {};

defQls['export']['ListMini'] = gql`
        query ExportList ($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          exports  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
               id
  name
  state

          }    
        }
        `;
defQls['export']['List'] = gql`
        query ExportList ($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          exports  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
             ${strFragments.export}
          }    
        }
        `;
defQls['export']['ListPage'] = gql`${strQls.export.ListPage}`;

defQls['export']['View'] = gql`
        query ExportView($id: ID!, $organizer_id: String) {
          export (id: $id, organizer_id: $organizer_id)  {
              id
  name
  state
  exportdate
  type
  files
  registration_fields
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id
  exportorganizer_id  {
    name
 }
  exportupdated_user_id  {
    name
 }
  exportcreated_user_id  {
    name
 }

          }    
        }
        `;
defQls['export']['Update'] = gql`
        mutation exportUpdate(  $id: ID!,  $name: String!,  $state: Int,  $exportdate: String,  $type: Int,  $files: String,  $registration_fields: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          exportUpdate (  id: $id,  name: $name,  state: $state,  exportdate: $exportdate,  type: $type,  files: $files,  registration_fields: $registration_fields,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  state
  exportdate
  type
  files
  registration_fields
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id

          }    
        }
        `;
defQls['export']['Delete'] = gql`
        mutation exportDelete($id: ID!) {
          exportDelete (id: $id) {
              id
  name
  state
  exportdate
  type
  files
  registration_fields
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['export']['Add'] = gql`
        mutation exportAdd(  $id: ID!,  $name: String!,  $state: Int,  $exportdate: String,  $type: Int,  $files: String,  $registration_fields: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          exportAdd (  id: $id,  name: $name,  state: $state,  exportdate: $exportdate,  type: $type,  files: $files,  registration_fields: $registration_fields,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  state
  exportdate
  type
  files
  registration_fields
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['hotel'] = {};

defQls['hotel']['ListMini'] = gql`
        query HotelList ($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          hotels  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
               id
  name

          }    
        }
        `;
defQls['hotel']['List'] = gql`
        query HotelList ($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          hotels  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
             ${strFragments.hotel}
          }    
        }
        `;
defQls['hotel']['ListPage'] = gql`${strQls.hotel.ListPage}`;

defQls['hotel']['View'] = gql`
        query HotelView($id: ID!, $organizer_id: String) {
          hotel (id: $id, organizer_id: $organizer_id)  {
              id
  name
  longname
  email
  phone
  tour_id
  info
  contacts
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id
  hoteltour_id  {
    name
 }
  hotelorganizer_id  {
    name
 }
  hotelupdated_user_id  {
    name
 }
  hotelcreated_user_id  {
    name
 }

          }    
        }
        `;
defQls['hotel']['Update'] = gql`
        mutation hotelUpdate(  $id: ID!,  $name: String,  $longname: String,  $email: String,  $phone: String,  $tour_id: String,  $info: String,  $contacts: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          hotelUpdate (  id: $id,  name: $name,  longname: $longname,  email: $email,  phone: $phone,  tour_id: $tour_id,  info: $info,  contacts: $contacts,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  longname
  email
  phone
  tour_id
  info
  contacts
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id

          }    
        }
        `;
defQls['hotel']['Delete'] = gql`
        mutation hotelDelete($id: ID!) {
          hotelDelete (id: $id) {
              id
  name
  longname
  email
  phone
  tour_id
  info
  contacts
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['hotel']['Add'] = gql`
        mutation hotelAdd(  $id: ID!,  $name: String,  $longname: String,  $email: String,  $phone: String,  $tour_id: String,  $info: String,  $contacts: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          hotelAdd (  id: $id,  name: $name,  longname: $longname,  email: $email,  phone: $phone,  tour_id: $tour_id,  info: $info,  contacts: $contacts,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  longname
  email
  phone
  tour_id
  info
  contacts
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['stopspoint'] = {};

defQls['stopspoint']['ListMini'] = gql`
        query StopspointList ($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          stopspoints  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
               id
  name

          }    
        }
        `;
defQls['stopspoint']['List'] = gql`
        query StopspointList ($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          stopspoints  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
             ${strFragments.stopspoint}
          }    
        }
        `;
defQls['stopspoint']['ListPage'] = gql`${strQls.stopspoint.ListPage}`;

defQls['stopspoint']['View'] = gql`
        query StopspointView($id: ID!, $organizer_id: String) {
          stopspoint (id: $id, organizer_id: $organizer_id)  {
              id
  name
  sinfo
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id
  stopspointorganizer_id  {
    name
 }
  stopspointupdated_user_id  {
    name
 }
  stopspointcreated_user_id  {
    name
 }

          }    
        }
        `;
defQls['stopspoint']['Update'] = gql`
        mutation stopspointUpdate(  $id: ID!,  $name: String,  $sinfo: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          stopspointUpdate (  id: $id,  name: $name,  sinfo: $sinfo,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  sinfo
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id

          }    
        }
        `;
defQls['stopspoint']['Delete'] = gql`
        mutation stopspointDelete($id: ID!) {
          stopspointDelete (id: $id) {
              id
  name
  sinfo
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['stopspoint']['Add'] = gql`
        mutation stopspointAdd(  $id: ID!,  $name: String,  $sinfo: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          stopspointAdd (  id: $id,  name: $name,  sinfo: $sinfo,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  sinfo
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['service'] = {};

defQls['service']['ListMini'] = gql`
        query ServiceList ($organizer_id: String, $name: String, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          services  (organizer_id: $organizer_id, name: $name, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
               id
  name
  nameinregistration
  filterlist

          }    
        }
        `;
defQls['service']['List'] = gql`
        query ServiceList ($organizer_id: String, $name: String, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          services  (organizer_id: $organizer_id, name: $name, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
             ${strFragments.service}
          }    
        }
        `;
defQls['service']['ListPage'] = gql`${strQls.service.ListPage}`;

defQls['service']['View'] = gql`
        query ServiceView($id: ID!, $organizer_id: String) {
          service (id: $id, organizer_id: $organizer_id)  {
              id
  name
  statusbase
  nameinregistration
  filterlist
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id
  serviceorganizer_id  {
    name
 }
  serviceupdated_user_id  {
    name
 }
  servicecreated_user_id  {
    name
 }

          }    
        }
        `;
defQls['service']['Update'] = gql`
        mutation serviceUpdate(  $id: ID!,  $name: String!,  $statusbase: Int,  $nameinregistration: Boolean,  $filterlist: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          serviceUpdate (  id: $id,  name: $name,  statusbase: $statusbase,  nameinregistration: $nameinregistration,  filterlist: $filterlist,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  statusbase
  nameinregistration
  filterlist
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id

          }    
        }
        `;
defQls['service']['Delete'] = gql`
        mutation serviceDelete($id: ID!) {
          serviceDelete (id: $id) {
              id
  name
  statusbase
  nameinregistration
  filterlist
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['service']['Add'] = gql`
        mutation serviceAdd(  $id: ID!,  $name: String!,  $statusbase: Int,  $nameinregistration: Boolean,  $filterlist: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          serviceAdd (  id: $id,  name: $name,  statusbase: $statusbase,  nameinregistration: $nameinregistration,  filterlist: $filterlist,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  statusbase
  nameinregistration
  filterlist
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['city'] = {};

defQls['city']['ListMini'] = gql`
        query CityList ($organizer_id: String, $name: String, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          citys  (organizer_id: $organizer_id, name: $name, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
               id
  name
  pcs

          }    
        }
        `;
defQls['city']['List'] = gql`
        query CityList ($organizer_id: String, $name: String, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          citys  (organizer_id: $organizer_id, name: $name, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
             ${strFragments.city}
          }    
        }
        `;
defQls['city']['ListPage'] = gql`${strQls.city.ListPage}`;

defQls['city']['View'] = gql`
        query CityView($id: ID!, $organizer_id: String) {
          city (id: $id, organizer_id: $organizer_id)  {
              id
  name
  pcs
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id
  cityorganizer_id  {
    name
 }
  cityupdated_user_id  {
    name
 }
  citycreated_user_id  {
    name
 }

          }    
        }
        `;
defQls['city']['Update'] = gql`
        mutation cityUpdate(  $id: ID!,  $name: String,  $pcs: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          cityUpdate (  id: $id,  name: $name,  pcs: $pcs,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  pcs
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id

          }    
        }
        `;
defQls['city']['Delete'] = gql`
        mutation cityDelete($id: ID!) {
          cityDelete (id: $id) {
              id
  name
  pcs
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['city']['Add'] = gql`
        mutation cityAdd(  $id: ID!,  $name: String,  $pcs: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          cityAdd (  id: $id,  name: $name,  pcs: $pcs,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  pcs
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['registrationstatus'] = {};

defQls['registrationstatus']['ListMini'] = gql`
        query RegistrationstatusList ($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          registrationstatuss  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
               id
  name
  statusbase
  color

          }    
        }
        `;
defQls['registrationstatus']['List'] = gql`
        query RegistrationstatusList ($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          registrationstatuss  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
             ${strFragments.registrationstatus}
          }    
        }
        `;
defQls['registrationstatus']['ListPage'] = gql`${strQls.registrationstatus.ListPage}`;

defQls['registrationstatus']['View'] = gql`
        query RegistrationstatusView($id: ID!, $organizer_id: String) {
          registrationstatus (id: $id, organizer_id: $organizer_id)  {
              id
  name
  statusbase
  color
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id
  registrationstatusorganizer_id  {
    name
 }
  registrationstatusupdated_user_id  {
    name
 }
  registrationstatuscreated_user_id  {
    name
 }

          }    
        }
        `;
defQls['registrationstatus']['Update'] = gql`
        mutation registrationstatusUpdate(  $id: ID!,  $name: String!,  $statusbase: Int,  $color: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          registrationstatusUpdate (  id: $id,  name: $name,  statusbase: $statusbase,  color: $color,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  statusbase
  color
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id

          }    
        }
        `;
defQls['registrationstatus']['Delete'] = gql`
        mutation registrationstatusDelete($id: ID!) {
          registrationstatusDelete (id: $id) {
              id
  name
  statusbase
  color
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['registrationstatus']['Add'] = gql`
        mutation registrationstatusAdd(  $id: ID!,  $name: String!,  $statusbase: Int,  $color: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          registrationstatusAdd (  id: $id,  name: $name,  statusbase: $statusbase,  color: $color,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  statusbase
  color
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['confirmationstatus'] = {};

defQls['confirmationstatus']['ListMini'] = gql`
        query ConfirmationstatusList ($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          confirmationstatuss  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
               id
  name
  statusbase
  color

          }    
        }
        `;
defQls['confirmationstatus']['List'] = gql`
        query ConfirmationstatusList ($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          confirmationstatuss  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
             ${strFragments.confirmationstatus}
          }    
        }
        `;
defQls['confirmationstatus']['ListPage'] = gql`${strQls.confirmationstatus.ListPage}`;

defQls['confirmationstatus']['View'] = gql`
        query ConfirmationstatusView($id: ID!, $organizer_id: String) {
          confirmationstatus (id: $id, organizer_id: $organizer_id)  {
              id
  name
  statusbase
  color
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id
  confirmationstatusorganizer_id  {
    name
 }
  confirmationstatusupdated_user_id  {
    name
 }
  confirmationstatuscreated_user_id  {
    name
 }

          }    
        }
        `;
defQls['confirmationstatus']['Update'] = gql`
        mutation confirmationstatusUpdate(  $id: ID!,  $name: String!,  $statusbase: Int,  $color: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          confirmationstatusUpdate (  id: $id,  name: $name,  statusbase: $statusbase,  color: $color,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  statusbase
  color
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id

          }    
        }
        `;
defQls['confirmationstatus']['Delete'] = gql`
        mutation confirmationstatusDelete($id: ID!) {
          confirmationstatusDelete (id: $id) {
              id
  name
  statusbase
  color
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['confirmationstatus']['Add'] = gql`
        mutation confirmationstatusAdd(  $id: ID!,  $name: String!,  $statusbase: Int,  $color: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          confirmationstatusAdd (  id: $id,  name: $name,  statusbase: $statusbase,  color: $color,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  statusbase
  color
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['bank'] = {};

defQls['bank']['ListMini'] = gql`
        query BankList ($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          banks  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
               id
  name

          }    
        }
        `;
defQls['bank']['List'] = gql`
        query BankList ($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          banks  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
             ${strFragments.bank}
          }    
        }
        `;
defQls['bank']['ListPage'] = gql`${strQls.bank.ListPage}`;

defQls['bank']['View'] = gql`
        query BankView($id: ID!, $organizer_id: String) {
          bank (id: $id, organizer_id: $organizer_id)  {
              id
  name
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id
  bankorganizer_id  {
    name
 }
  bankupdated_user_id  {
    name
 }
  bankcreated_user_id  {
    name
 }

          }    
        }
        `;
defQls['bank']['Update'] = gql`
        mutation bankUpdate(  $id: ID!,  $name: String!,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          bankUpdate (  id: $id,  name: $name,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id

          }    
        }
        `;
defQls['bank']['Delete'] = gql`
        mutation bankDelete($id: ID!) {
          bankDelete (id: $id) {
              id
  name
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['bank']['Add'] = gql`
        mutation bankAdd(  $id: ID!,  $name: String!,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          bankAdd (  id: $id,  name: $name,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['paymentmethod'] = {};

defQls['paymentmethod']['ListMini'] = gql`
        query PaymentmethodList ($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          paymentmethods  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
               id
  name
  statusbase
  usedatedeferred
  ajournalcode_id
  anature
paymentmethodajournalcode_id  {
    name
    title
    counterpart_id
ajournalcodecounterpart_id  {
    name
 }
 }

          }    
        }
        `;
defQls['paymentmethod']['List'] = gql`
        query PaymentmethodList ($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          paymentmethods  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
             ${strFragments.paymentmethod}
          }    
        }
        `;
defQls['paymentmethod']['ListPage'] = gql`${strQls.paymentmethod.ListPage}`;

defQls['paymentmethod']['View'] = gql`
        query PaymentmethodView($id: ID!, $organizer_id: String) {
          paymentmethod (id: $id, organizer_id: $organizer_id)  {
              id
  name
  statusbase
  color
  usedatedeferred
  ajournalcode_id
  anature
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id
  paymentmethodajournalcode_id  {
    name
    title
    counterpart_id
  ajournalcodecounterpart_id  {
    name
 }
 }
  paymentmethodorganizer_id  {
    name
 }
  paymentmethodupdated_user_id  {
    name
 }
  paymentmethodcreated_user_id  {
    name
 }

          }    
        }
        `;
defQls['paymentmethod']['Update'] = gql`
        mutation paymentmethodUpdate(  $id: ID!,  $name: String!,  $statusbase: Int,  $color: String,  $usedatedeferred: Boolean,  $ajournalcode_id: String,  $anature: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          paymentmethodUpdate (  id: $id,  name: $name,  statusbase: $statusbase,  color: $color,  usedatedeferred: $usedatedeferred,  ajournalcode_id: $ajournalcode_id,  anature: $anature,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  statusbase
  color
  usedatedeferred
  ajournalcode_id
  anature
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id

          }    
        }
        `;
defQls['paymentmethod']['Delete'] = gql`
        mutation paymentmethodDelete($id: ID!) {
          paymentmethodDelete (id: $id) {
              id
  name
  statusbase
  color
  usedatedeferred
  ajournalcode_id
  anature
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['paymentmethod']['Add'] = gql`
        mutation paymentmethodAdd(  $id: ID!,  $name: String!,  $statusbase: Int,  $color: String,  $usedatedeferred: Boolean,  $ajournalcode_id: String,  $anature: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          paymentmethodAdd (  id: $id,  name: $name,  statusbase: $statusbase,  color: $color,  usedatedeferred: $usedatedeferred,  ajournalcode_id: $ajournalcode_id,  anature: $anature,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  statusbase
  color
  usedatedeferred
  ajournalcode_id
  anature
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['paymentdocstatus'] = {};

defQls['paymentdocstatus']['ListMini'] = gql`
        query PaymentdocstatusList ($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          paymentdocstatuss  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
               id
  name
  statusbase
  color

          }    
        }
        `;
defQls['paymentdocstatus']['List'] = gql`
        query PaymentdocstatusList ($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          paymentdocstatuss  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
             ${strFragments.paymentdocstatus}
          }    
        }
        `;
defQls['paymentdocstatus']['ListPage'] = gql`${strQls.paymentdocstatus.ListPage}`;

defQls['paymentdocstatus']['View'] = gql`
        query PaymentdocstatusView($id: ID!, $organizer_id: String) {
          paymentdocstatus (id: $id, organizer_id: $organizer_id)  {
              id
  name
  statusbase
  color
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id
  paymentdocstatusorganizer_id  {
    name
 }
  paymentdocstatusupdated_user_id  {
    name
 }
  paymentdocstatuscreated_user_id  {
    name
 }

          }    
        }
        `;
defQls['paymentdocstatus']['Update'] = gql`
        mutation paymentdocstatusUpdate(  $id: ID!,  $name: String!,  $statusbase: Int,  $color: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          paymentdocstatusUpdate (  id: $id,  name: $name,  statusbase: $statusbase,  color: $color,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  statusbase
  color
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id

          }    
        }
        `;
defQls['paymentdocstatus']['Delete'] = gql`
        mutation paymentdocstatusDelete($id: ID!) {
          paymentdocstatusDelete (id: $id) {
              id
  name
  statusbase
  color
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['paymentdocstatus']['Add'] = gql`
        mutation paymentdocstatusAdd(  $id: ID!,  $name: String!,  $statusbase: Int,  $color: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          paymentdocstatusAdd (  id: $id,  name: $name,  statusbase: $statusbase,  color: $color,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  statusbase
  color
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['customerstatus'] = {};

defQls['customerstatus']['ListMini'] = gql`
        query CustomerstatusList ($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String, $_orders: String) {
          customerstatuss  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
               id
  name
  substatus

          }    
        }
        `;
defQls['customerstatus']['List'] = gql`
        query CustomerstatusList ($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String, $_orders: String) {
          customerstatuss  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
             ${strFragments.customerstatus}
          }    
        }
        `;
defQls['customerstatus']['ListPage'] = gql`${strQls.customerstatus.ListPage}`;

defQls['customerstatus']['View'] = gql`
        query CustomerstatusView($id: ID!, $organizer_id: String) {
          customerstatus (id: $id, organizer_id: $organizer_id)  {
              id
  name
  longname
  substatus
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id
  customerstatusorganizer_id  {
    name
 }
  customerstatusupdated_user_id  {
    name
 }
  customerstatuscreated_user_id  {
    name
 }

          }    
        }
        `;
defQls['customerstatus']['Update'] = gql`
        mutation customerstatusUpdate(  $id: ID!,  $name: String!,  $longname: String,  $substatus: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          customerstatusUpdate (  id: $id,  name: $name,  longname: $longname,  substatus: $substatus,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  longname
  substatus
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id

          }    
        }
        `;
defQls['customerstatus']['Delete'] = gql`
        mutation customerstatusDelete($id: ID!) {
          customerstatusDelete (id: $id) {
              id
  name
  longname
  substatus
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['customerstatus']['Add'] = gql`
        mutation customerstatusAdd(  $id: ID!,  $name: String!,  $longname: String,  $substatus: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          customerstatusAdd (  id: $id,  name: $name,  longname: $longname,  substatus: $substatus,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  longname
  substatus
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['gendertype'] = {};

defQls['gendertype']['ListMini'] = gql`
        query GendertypeList ($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          gendertypes  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
               id
  name

          }    
        }
        `;
defQls['gendertype']['List'] = gql`
        query GendertypeList ($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          gendertypes  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
             ${strFragments.gendertype}
          }    
        }
        `;
defQls['gendertype']['ListPage'] = gql`${strQls.gendertype.ListPage}`;

defQls['gendertype']['View'] = gql`
        query GendertypeView($id: ID!, $organizer_id: String) {
          gendertype (id: $id, organizer_id: $organizer_id)  {
              id
  name
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id
  gendertypeorganizer_id  {
    name
 }
  gendertypeupdated_user_id  {
    name
 }
  gendertypecreated_user_id  {
    name
 }

          }    
        }
        `;
defQls['gendertype']['Update'] = gql`
        mutation gendertypeUpdate(  $id: ID!,  $name: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          gendertypeUpdate (  id: $id,  name: $name,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id

          }    
        }
        `;
defQls['gendertype']['Delete'] = gql`
        mutation gendertypeDelete($id: ID!) {
          gendertypeDelete (id: $id) {
              id
  name
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['gendertype']['Add'] = gql`
        mutation gendertypeAdd(  $id: ID!,  $name: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          gendertypeAdd (  id: $id,  name: $name,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['userlog'] = {};

defQls['userlog']['ListMini'] = gql`
        query UserlogList ($organizer_id: String, $user_id: String, $action: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          userlogs  (organizer_id: $organizer_id, user_id: $user_id, action: $action, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
               id
  name

          }    
        }
        `;
defQls['userlog']['List'] = gql`
        query UserlogList ($organizer_id: String, $user_id: String, $action: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          userlogs  (organizer_id: $organizer_id, user_id: $user_id, action: $action, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
             ${strFragments.userlog}
          }    
        }
        `;
defQls['userlog']['ListPage'] = gql`${strQls.userlog.ListPage}`;

defQls['userlog']['View'] = gql`
        query UserlogView($id: ID!, $organizer_id: String) {
          userlog (id: $id, organizer_id: $organizer_id)  {
              id
  name
  actiondate
  user_id
  record_id
  record_parentid
  record_table
  action
  ip
  organizer_id
  userloguser_id  {
    name
 }
  userlogorganizer_id  {
    name
 }

          }    
        }
        `;
defQls['userlog']['Update'] = gql`
        mutation userlogUpdate(  $id: ID!,  $name: String,  $actiondate: String,  $user_id: String,  $record_id: String,  $record_parentid: String,  $record_table: String,  $action: String,  $ip: String,  $organizer_id: String) {
          userlogUpdate (  id: $id,  name: $name,  actiondate: $actiondate,  user_id: $user_id,  record_id: $record_id,  record_parentid: $record_parentid,  record_table: $record_table,  action: $action,  ip: $ip,  organizer_id: $organizer_id) {
              id
  name
  actiondate
  user_id
  record_id
  record_parentid
  record_table
  action
  ip

          }    
        }
        `;
defQls['userlog']['Add'] = gql`
        mutation userlogAdd(  $id: ID!,  $name: String,  $actiondate: String,  $user_id: String,  $record_id: String,  $record_parentid: String,  $record_table: String,  $action: String,  $ip: String,  $organizer_id: String) {
          userlogAdd (  id: $id,  name: $name,  actiondate: $actiondate,  user_id: $user_id,  record_id: $record_id,  record_parentid: $record_parentid,  record_table: $record_table,  action: $action,  ip: $ip,  organizer_id: $organizer_id) {
              id
  name
  actiondate
  user_id
  record_id
  record_parentid
  record_table
  action
  ip
  organizer_id

          }    
        }
        `;
defQls['user'] = {};

defQls['user']['ListMini'] = gql`
        query UserList ($organizer_id: String, $nick: String, $longname: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          users  (organizer_id: $organizer_id, nick: $nick, longname: $longname, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
               id
  name

          }    
        }
        `;
defQls['user']['List'] = gql`
        query UserList ($organizer_id: String, $nick: String, $longname: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          users  (organizer_id: $organizer_id, nick: $nick, longname: $longname, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
             ${strFragments.user}
          }    
        }
        `;
defQls['user']['ListPage'] = gql`${strQls.user.ListPage}`;

defQls['user']['View'] = gql`
        query UserView($id: ID!, $organizer_id: String) {
          user (id: $id, organizer_id: $organizer_id)  {
              id
  nick
  password
  name
  longname
  email
  isadmin
  usergroup_id
  lastaccessdate
  lastactiondate
  countactionsmonth
  phones
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id
  userusergroup_id  {
    name
 }
  userorganizer_id  {
    name
 }

          }    
        }
        `;
defQls['user']['Update'] = gql`
        mutation userUpdate(  $id: ID!,  $nick: String,  $password: String,  $name: String,  $longname: String,  $email: String,  $isadmin: Boolean,  $usergroup_id: String,  $lastaccessdate: String,  $lastactiondate: String,  $countactionsmonth: Int,  $phones: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          userUpdate (  id: $id,  nick: $nick,  password: $password,  name: $name,  longname: $longname,  email: $email,  isadmin: $isadmin,  usergroup_id: $usergroup_id,  lastaccessdate: $lastaccessdate,  lastactiondate: $lastactiondate,  countactionsmonth: $countactionsmonth,  phones: $phones,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  nick
  password
  name
  longname
  email
  isadmin
  usergroup_id
  lastaccessdate
  lastactiondate
  countactionsmonth
  phones
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id

          }    
        }
        `;
defQls['user']['Delete'] = gql`
        mutation userDelete($id: ID!) {
          userDelete (id: $id) {
              id
  nick
  password
  name
  longname
  email
  isadmin
  usergroup_id
  lastaccessdate
  lastactiondate
  countactionsmonth
  phones
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['user']['Add'] = gql`
        mutation userAdd(  $id: ID!,  $nick: String,  $password: String,  $name: String,  $longname: String,  $email: String,  $isadmin: Boolean,  $usergroup_id: String,  $lastaccessdate: String,  $lastactiondate: String,  $countactionsmonth: Int,  $phones: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          userAdd (  id: $id,  nick: $nick,  password: $password,  name: $name,  longname: $longname,  email: $email,  isadmin: $isadmin,  usergroup_id: $usergroup_id,  lastaccessdate: $lastaccessdate,  lastactiondate: $lastactiondate,  countactionsmonth: $countactionsmonth,  phones: $phones,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  nick
  password
  name
  longname
  email
  isadmin
  usergroup_id
  lastaccessdate
  lastactiondate
  countactionsmonth
  phones
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['usergroup'] = {};

defQls['usergroup']['ListMini'] = gql`
        query UsergroupList ($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          usergroups  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
               id
  name

          }    
        }
        `;
defQls['usergroup']['List'] = gql`
        query UsergroupList ($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          usergroups  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter) {
             ${strFragments.usergroup}
          }    
        }
        `;
defQls['usergroup']['ListPage'] = gql`${strQls.usergroup.ListPage}`;

defQls['usergroup']['View'] = gql`
        query UsergroupView($id: ID!, $organizer_id: String) {
          usergroup (id: $id, organizer_id: $organizer_id)  {
              id
  name
  accessrestrictedto
  permissions
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id
  usergrouporganizer_id  {
    name
 }
  usergroupupdated_user_id  {
    name
 }
  usergroupcreated_user_id  {
    name
 }

          }    
        }
        `;
defQls['usergroup']['Update'] = gql`
        mutation usergroupUpdate(  $id: ID!,  $name: String,  $accessrestrictedto: String,  $permissions: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          usergroupUpdate (  id: $id,  name: $name,  accessrestrictedto: $accessrestrictedto,  permissions: $permissions,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  accessrestrictedto
  permissions
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id

          }    
        }
        `;
defQls['usergroup']['Add'] = gql`
        mutation usergroupAdd(  $id: ID!,  $name: String,  $accessrestrictedto: String,  $permissions: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          usergroupAdd (  id: $id,  name: $name,  accessrestrictedto: $accessrestrictedto,  permissions: $permissions,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  accessrestrictedto
  permissions
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['ejobmail'] = {};

defQls['ejobmail']['ListMini'] = gql`
        query EjobmailList ($organizer_id: String, $title: String, $customer_id: String, $tour_id: ID, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          ejobmails  (organizer_id: $organizer_id, title: $title, customer_id: $customer_id, tour_id: $tour_id, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
               id
  state
ejobmailcustomer_id  {
    name
 }
ejobmailtour_id  {
    name
 }
ejobmailetemplate_id  {
    name
 }

          }    
        }
        `;
defQls['ejobmail']['List'] = gql`
        query EjobmailList ($organizer_id: String, $title: String, $customer_id: String, $tour_id: ID, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          ejobmails  (organizer_id: $organizer_id, title: $title, customer_id: $customer_id, tour_id: $tour_id, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
             ${strFragments.ejobmail}
          }    
        }
        `;
defQls['ejobmail']['ListPage'] = gql`${strQls.ejobmail.ListPage}`;

defQls['ejobmail']['View'] = gql`
        query EjobmailView($id: ID!, $organizer_id: String) {
          ejobmail (id: $id, organizer_id: $organizer_id)  {
              id
  customer_id
  channels
  channelsfilters
  etemplate_id
  title
  tour_id
  limitexceeded
  totalMessaging
  confirmationstatus_id
  state
  customersrecipients
  templatedata
  channel
  sent
  templatesms
  sms
  template
  body
  files
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id
  ejobmailcustomer_id  {
    name
 }
  ejobmailtour_id  {
    name
 }
  ejobmailetemplate_id  {
    name
 }
  ejobmailorganizer_id  {
    name
 }
  ejobmailupdated_user_id  {
    name
 }
  ejobmailcreated_user_id  {
    name
 }

          }    
        }
        `;
defQls['ejobmail']['Update'] = gql`
        mutation ejobmailUpdate(  $id: ID!,  $customer_id: String,  $channels: String,  $channelsfilters: Int,  $etemplate_id: String,  $title: String,  $tour_id: ID,  $limitexceeded: String,  $totalMessaging: Int,  $confirmationstatus_id: String,  $state: Int,  $customersrecipients: String,  $templatedata: String,  $channel: Int,  $sent: String,  $templatesms: String,  $sms: String,  $template: String,  $body: String,  $files: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          ejobmailUpdate (  id: $id,  customer_id: $customer_id,  channels: $channels,  channelsfilters: $channelsfilters,  etemplate_id: $etemplate_id,  title: $title,  tour_id: $tour_id,  limitexceeded: $limitexceeded,  totalMessaging: $totalMessaging,  confirmationstatus_id: $confirmationstatus_id,  state: $state,  customersrecipients: $customersrecipients,  templatedata: $templatedata,  channel: $channel,  sent: $sent,  templatesms: $templatesms,  sms: $sms,  template: $template,  body: $body,  files: $files,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  customer_id
  channels
  channelsfilters
  etemplate_id
  title
  tour_id
  limitexceeded
  totalMessaging
  confirmationstatus_id
  state
  customersrecipients
  templatedata
  channel
  sent
  templatesms
  sms
  template
  body
  files
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id

          }    
        }
        `;
defQls['ejobmail']['Delete'] = gql`
        mutation ejobmailDelete($id: ID!) {
          ejobmailDelete (id: $id) {
              id
  customer_id
  channels
  channelsfilters
  etemplate_id
  title
  tour_id
  limitexceeded
  totalMessaging
  confirmationstatus_id
  state
  customersrecipients
  templatedata
  channel
  sent
  templatesms
  sms
  template
  body
  files
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['ejobmail']['Add'] = gql`
        mutation ejobmailAdd(  $id: ID!,  $customer_id: String,  $channels: String,  $channelsfilters: Int,  $etemplate_id: String,  $title: String,  $tour_id: ID,  $limitexceeded: String,  $totalMessaging: Int,  $confirmationstatus_id: String,  $state: Int,  $customersrecipients: String,  $templatedata: String,  $channel: Int,  $sent: String,  $templatesms: String,  $sms: String,  $template: String,  $body: String,  $files: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          ejobmailAdd (  id: $id,  customer_id: $customer_id,  channels: $channels,  channelsfilters: $channelsfilters,  etemplate_id: $etemplate_id,  title: $title,  tour_id: $tour_id,  limitexceeded: $limitexceeded,  totalMessaging: $totalMessaging,  confirmationstatus_id: $confirmationstatus_id,  state: $state,  customersrecipients: $customersrecipients,  templatedata: $templatedata,  channel: $channel,  sent: $sent,  templatesms: $templatesms,  sms: $sms,  template: $template,  body: $body,  files: $files,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  customer_id
  channels
  channelsfilters
  etemplate_id
  title
  tour_id
  limitexceeded
  totalMessaging
  confirmationstatus_id
  state
  customersrecipients
  templatedata
  channel
  sent
  templatesms
  sms
  template
  body
  files
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['etemplate'] = {};

defQls['etemplate']['ListMini'] = gql`
        query EtemplateList ($organizer_id: String, $name: String, $title: String, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          etemplates  (organizer_id: $organizer_id, name: $name, title: $title, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
               id
  name
  title
  body
  sms

          }    
        }
        `;
defQls['etemplate']['List'] = gql`
        query EtemplateList ($organizer_id: String, $name: String, $title: String, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          etemplates  (organizer_id: $organizer_id, name: $name, title: $title, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
             ${strFragments.etemplate}
          }    
        }
        `;
defQls['etemplate']['ListPage'] = gql`${strQls.etemplate.ListPage}`;

defQls['etemplate']['View'] = gql`
        query EtemplateView($id: ID!, $organizer_id: String) {
          etemplate (id: $id, organizer_id: $organizer_id)  {
              id
  name
  channel
  title
  body
  sms
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id
  etemplateorganizer_id  {
    name
 }
  etemplateupdated_user_id  {
    name
 }
  etemplatecreated_user_id  {
    name
 }

          }    
        }
        `;
defQls['etemplate']['Update'] = gql`
        mutation etemplateUpdate(  $id: ID!,  $name: String,  $channel: Int,  $title: String,  $body: String,  $sms: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          etemplateUpdate (  id: $id,  name: $name,  channel: $channel,  title: $title,  body: $body,  sms: $sms,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  channel
  title
  body
  sms
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id

          }    
        }
        `;
defQls['etemplate']['Delete'] = gql`
        mutation etemplateDelete($id: ID!) {
          etemplateDelete (id: $id) {
              id
  name
  channel
  title
  body
  sms
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['etemplate']['Add'] = gql`
        mutation etemplateAdd(  $id: ID!,  $name: String,  $channel: Int,  $title: String,  $body: String,  $sms: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          etemplateAdd (  id: $id,  name: $name,  channel: $channel,  title: $title,  body: $body,  sms: $sms,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  channel
  title
  body
  sms
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['ejobday'] = {};

defQls['ejobday']['ListMini'] = gql`
        query EjobdayList ($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String, $_orders: String) {
          ejobdays  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
               id
  name

          }    
        }
        `;
defQls['ejobday']['List'] = gql`
        query EjobdayList ($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String, $_orders: String) {
          ejobdays  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
             ${strFragments.ejobday}
          }    
        }
        `;
defQls['ejobday']['ListPage'] = gql`${strQls.ejobday.ListPage}`;

defQls['ejobday']['View'] = gql`
        query EjobdayView($id: ID!, $organizer_id: String) {
          ejobday (id: $id, organizer_id: $organizer_id)  {
              id
  name
  qty
  qtysms
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id
  ejobdayorganizer_id  {
    name
 }
  ejobdayupdated_user_id  {
    name
 }
  ejobdaycreated_user_id  {
    name
 }

          }    
        }
        `;
defQls['ejobday']['Update'] = gql`
        mutation ejobdayUpdate(  $id: ID!,  $name: String,  $qty: String,  $qtysms: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          ejobdayUpdate (  id: $id,  name: $name,  qty: $qty,  qtysms: $qtysms,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  qty
  qtysms
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id

          }    
        }
        `;
defQls['ejobday']['Add'] = gql`
        mutation ejobdayAdd(  $id: ID!,  $name: String,  $qty: String,  $qtysms: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          ejobdayAdd (  id: $id,  name: $name,  qty: $qty,  qtysms: $qtysms,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  qty
  qtysms
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['ejobmonth'] = {};

defQls['ejobmonth']['ListMini'] = gql`
        query EjobmonthList ($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String, $_orders: String) {
          ejobmonths  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
               id
  name

          }    
        }
        `;
defQls['ejobmonth']['List'] = gql`
        query EjobmonthList ($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String, $_orders: String) {
          ejobmonths  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
             ${strFragments.ejobmonth}
          }    
        }
        `;
defQls['ejobmonth']['ListPage'] = gql`${strQls.ejobmonth.ListPage}`;

defQls['ejobmonth']['View'] = gql`
        query EjobmonthView($id: ID!, $organizer_id: String) {
          ejobmonth (id: $id, organizer_id: $organizer_id)  {
              id
  name
  qty
  qtysms
  amount
  paid
  balance
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id
  ejobmonthorganizer_id  {
    name
 }
  ejobmonthupdated_user_id  {
    name
 }
  ejobmonthcreated_user_id  {
    name
 }

          }    
        }
        `;
defQls['ejobmonth']['Update'] = gql`
        mutation ejobmonthUpdate(  $id: ID!,  $name: String,  $qty: String,  $qtysms: String,  $amount: Float,  $paid: Float,  $balance: Float,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          ejobmonthUpdate (  id: $id,  name: $name,  qty: $qty,  qtysms: $qtysms,  amount: $amount,  paid: $paid,  balance: $balance,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  qty
  qtysms
  amount
  paid
  balance
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id

          }    
        }
        `;
defQls['ejobmonth']['Add'] = gql`
        mutation ejobmonthAdd(  $id: ID!,  $name: String,  $qty: String,  $qtysms: String,  $amount: Float,  $paid: Float,  $balance: Float,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          ejobmonthAdd (  id: $id,  name: $name,  qty: $qty,  qtysms: $qtysms,  amount: $amount,  paid: $paid,  balance: $balance,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  qty
  qtysms
  amount
  paid
  balance
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['aplan'] = {};

defQls['aplan']['ListMini'] = gql`
        query AplanList ($organizer_id: String, $name: String, $title: String, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          aplans  (organizer_id: $organizer_id, name: $name, title: $title, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
               id
  name
  title

          }    
        }
        `;
defQls['aplan']['List'] = gql`
        query AplanList ($organizer_id: String, $name: String, $title: String, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          aplans  (organizer_id: $organizer_id, name: $name, title: $title, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
             ${strFragments.aplan}
          }    
        }
        `;
defQls['aplan']['ListPage'] = gql`${strQls.aplan.ListPage}`;

defQls['aplan']['View'] = gql`
        query AplanView($id: ID!, $organizer_id: String) {
          aplan (id: $id, organizer_id: $organizer_id)  {
              id
  name
  title
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id
  aplanorganizer_id  {
    name
 }
  aplanupdated_user_id  {
    name
 }
  aplancreated_user_id  {
    name
 }

          }    
        }
        `;
defQls['aplan']['Update'] = gql`
        mutation aplanUpdate(  $id: ID!,  $name: String,  $title: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          aplanUpdate (  id: $id,  name: $name,  title: $title,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  title
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id

          }    
        }
        `;
defQls['aplan']['Delete'] = gql`
        mutation aplanDelete($id: ID!) {
          aplanDelete (id: $id) {
              id
  name
  title
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['aplan']['Add'] = gql`
        mutation aplanAdd(  $id: ID!,  $name: String,  $title: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          aplanAdd (  id: $id,  name: $name,  title: $title,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  title
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['acostplan'] = {};

defQls['acostplan']['ListMini'] = gql`
        query AcostplanList ($organizer_id: String, $name: String, $title: String, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          acostplans  (organizer_id: $organizer_id, name: $name, title: $title, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
               id
  name
  title

          }    
        }
        `;
defQls['acostplan']['List'] = gql`
        query AcostplanList ($organizer_id: String, $name: String, $title: String, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          acostplans  (organizer_id: $organizer_id, name: $name, title: $title, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
             ${strFragments.acostplan}
          }    
        }
        `;
defQls['acostplan']['ListPage'] = gql`${strQls.acostplan.ListPage}`;

defQls['acostplan']['View'] = gql`
        query AcostplanView($id: ID!, $organizer_id: String) {
          acostplan (id: $id, organizer_id: $organizer_id)  {
              id
  name
  title
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id
  acostplanorganizer_id  {
    name
 }
  acostplanupdated_user_id  {
    name
 }
  acostplancreated_user_id  {
    name
 }

          }    
        }
        `;
defQls['acostplan']['Update'] = gql`
        mutation acostplanUpdate(  $id: ID!,  $name: String,  $title: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          acostplanUpdate (  id: $id,  name: $name,  title: $title,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  title
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id

          }    
        }
        `;
defQls['acostplan']['Delete'] = gql`
        mutation acostplanDelete($id: ID!) {
          acostplanDelete (id: $id) {
              id
  name
  title
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['acostplan']['Add'] = gql`
        mutation acostplanAdd(  $id: ID!,  $name: String,  $title: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          acostplanAdd (  id: $id,  name: $name,  title: $title,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  title
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['ajournalcode'] = {};

defQls['ajournalcode']['ListMini'] = gql`
        query AjournalcodeList ($organizer_id: String, $name: String, $title: String, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          ajournalcodes  (organizer_id: $organizer_id, name: $name, title: $title, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
               id
  name
  title
  counterpart_id
ajournalcodecounterpart_id  {
    name
 }

          }    
        }
        `;
defQls['ajournalcode']['List'] = gql`
        query AjournalcodeList ($organizer_id: String, $name: String, $title: String, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          ajournalcodes  (organizer_id: $organizer_id, name: $name, title: $title, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders) {
             ${strFragments.ajournalcode}
          }    
        }
        `;
defQls['ajournalcode']['ListPage'] = gql`${strQls.ajournalcode.ListPage}`;

defQls['ajournalcode']['View'] = gql`
        query AjournalcodeView($id: ID!, $organizer_id: String) {
          ajournalcode (id: $id, organizer_id: $organizer_id)  {
              id
  name
  title
  counterpart_id
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id
  ajournalcodecounterpart_id  {
    name
 }
  ajournalcodeorganizer_id  {
    name
 }
  ajournalcodeupdated_user_id  {
    name
 }
  ajournalcodecreated_user_id  {
    name
 }

          }    
        }
        `;
defQls['ajournalcode']['Update'] = gql`
        mutation ajournalcodeUpdate(  $id: ID!,  $name: String,  $title: String,  $counterpart_id: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          ajournalcodeUpdate (  id: $id,  name: $name,  title: $title,  counterpart_id: $counterpart_id,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  title
  counterpart_id
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id

          }    
        }
        `;
defQls['ajournalcode']['Delete'] = gql`
        mutation ajournalcodeDelete($id: ID!) {
          ajournalcodeDelete (id: $id) {
              id
  name
  title
  counterpart_id
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
defQls['ajournalcode']['Add'] = gql`
        mutation ajournalcodeAdd(  $id: ID!,  $name: String,  $title: String,  $counterpart_id: String,  $deletedAt: String,  $deleted: Boolean,  $updated_user_id: String,  $created_user_id: String,  $deleted_user_id: String,  $organizer_id: String) {
          ajournalcodeAdd (  id: $id,  name: $name,  title: $title,  counterpart_id: $counterpart_id,  deletedAt: $deletedAt,  deleted: $deleted,  updated_user_id: $updated_user_id,  created_user_id: $created_user_id,  deleted_user_id: $deleted_user_id,  organizer_id: $organizer_id) {
              id
  name
  title
  counterpart_id
  updatedAt
  createdAt
  deletedAt
  deleted
  updated_user_id
  created_user_id
  deleted_user_id
  organizer_id

          }    
        }
        `;
export default defQls;