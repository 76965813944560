/*
part of model:
https://stackoverflow.com/questions/38563679/react-redux-dispatch-action-on-app-load-init
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { graphql, compose } from "react-apollo";

import { appSettingsLoad } from "./actions/appActions";
import defQls from "./defQls";
import { resolvePathObj } from "./utils/commonutils";
import Notif from "./components/Notif";
import { checkLoadCrud } from "./utils/helper";
import { MsgError } from "./components/ListData";

class SettingsLoad extends Component {
  constructor(props) {
    super(props);
  }

  /* LOGIC TO BLOCK SHOW APP, BEFORE LOAD SETTINGS AVOIDED,
        Very complex, give bug some computers, when they are disconnected or with the authentication expired */

  componentWillUpdate(newProps) {
    /*
    control if there are errors, if not session will be error connection or req.is not found
    so setTimeOut is not launched, no possible to look for t, but
     */
    const resultCheck = checkLoadCrud({ getOrgSettings: {} }, this.props);
    if (!resultCheck.messageError) {
      let objQl = resolvePathObj(
        this.props,
        "getOrgSettings.getOrgSettings.record"
      );
      let objSettings = resolvePathObj(this.props, "myState.app.appSettings");

      /*
      launch only if ql is already loaded
       */
      if (typeof objSettings === "undefined") {
        setTimeout(() => {
          /*
          launch update store only if there is not store loaded, that is loaded when browser load the app
           */
          // doublec control, re-check after timeout (1 second) the variables
          // so don't launch settings when there are pools of settimeout because re-renders
          let objQl = resolvePathObj(
            this.props,
            "getOrgSettings.getOrgSettings.record"
          );
          let objSettings = resolvePathObj(
            this.props,
            "myState.app.appSettings"
          );
          if (
            typeof objQl !== "undefined" &&
            typeof objSettings === "undefined"
          ) {
            this.props.appSettingsLoad(
              JSON.parse(this.props.getOrgSettings.getOrgSettings.record)
            );
          }
        }, 1000);
      }
    }
  }

  render() {
    // only way is show childrens, there show errors connections, login or others
    // don't insert control errors here, will be in a infinite loop
    return this.props.children;
  }
}

const withGraphql = compose(
  graphql(defQls.getOrgSettings, {
    name: "getOrgSettings",
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = "network-only";
      return optionsValues;
    },
  })
)(SettingsLoad);

const mapStateToProps = (state, ownProps) => {
  return {
    myState: state,
    notifications: state.notifications,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ appSettingsLoad, dispatch }, dispatch); // to set this.props.dispatch
};

const ComponentFull = connect(mapStateToProps, mapDispatchToProps)(withGraphql);

export default ComponentFull;
