import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  showNotificationWithTimeout,
  hideNotification,
} from "../../actions/notifActions";

import { withRouter } from "react-router";
import { graphql, compose } from "react-apollo";

import defQls from "../../defQls";
import { processError } from "../../utils/helper";
import Form from "./ChangePassForm";

class ChangePass extends Component {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
  }

  handleSubmit = async (values, dispatch, props) => {
    // important add no-cache, on the execution, section declaration nocache at the end is not working
    // TWIN QLACTION029
    const response = await this.props
      .changePass({ fetchPolicy: "no-cache", variables: values })
      .catch((err) => {
        // only for graphql error error ( different to 200 code response)
        console.log("there are errorsr");
        processError(err, props, true); // new 19 Dec. 2017
      });
    if (!response.errors) {
      // manage errores with 200 code
      this.props.showNotificationWithTimeout(
        this.props.t("alert.datasaved"),
        false
      );
      this.props.history.push("/more");
    } else {
      processError(response.errors, this.props, true);
    }
  };
  render() {
    const { t } = this.props;
    return (
      <Form
        onSubmit={this.handleSubmit}
        errors={this.state.errors}
        t={t}
        containerPropsForm={this.props}
      />
    );
  }
}

const withRouterList = withRouter(ChangePass);
const withGraphqlandRouter = compose(
  graphql(defQls.changePass, {
    name: "changePass",
    options: (props) => {
      const optionsValues = { variables: { id: props.match.params.id } };
      optionsValues.fetchPolicy = "no-cache";
      return optionsValues;
    },
  })
)(withRouterList);

const mapStateToProps = (state) => {
  return {
    myState: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  // dont need showNotificationWithTimeout: comes from props from Layout parent component
  //return bindActionCreators ({ hideNotification, showNotificationWithTimeout  }, dispatch );
};
const ComponentFull = connect(
  mapStateToProps,
  mapDispatchToProps
)(withGraphqlandRouter);

export default ComponentFull;
