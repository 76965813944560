/*
WARNING:
  formProps is artificial when is launched from containerForm, in the case initForm or manual change
    from container.
    this send his props himself and not containerForm
  so don't use formProps to use special props, form myState and tt is ok, container and Form have these same props
  getInputValue use myState, myState is present in container so is ok to use it,
    but send always the name of form { nameForm }, because if formProps is artificil will fail
  use tt , for translate, have dependency with props
 */

import React from "react";
import ShowIcon from "../icons/ShowIcon";
import {
  getInputValue,
  processValues,
  tt,
  templateEngine,
  setTemplateData,
  getOptions,
  getOptionFromSource,
  getObjFromListById,
  processPayments,
  getSMS,
  resolvePathObj,
  getMainAddress,
  disassemblyId,
  assemblyId,
  getRelatedTourService,
} from "../../utils/commonutils";
import { getRecordFromOptionsIndirectOnFilter } from "../../utils/helper";
import { submit, change } from "redux-form";
import strFragments from "../../defStrFragmentsQls.js";
import strArgs from "../../defStrArgsQls";
import { fetchQl, fetchDirect } from "../../apolloClient";
const crudCode = {};

crudCode.onSubmit = async (params) => {
  const { nameForm, formProps } = params;
  const body = getInputValue(formProps, "body", { nameForm });
  if (body && body.includes("{{")) {
    return { etemplate_id: "validator.template" };
  }
};

crudCode.onChangeInput = async (params) => {
  //console.log('params client onChangeInput', params);
  //console.log('onChangeInput params:' , params);
  const {
    nameForm,
    popup,
    tableCrud,
    inputFullName,
    inputName,
    line,
    action,
    parentField,
    event,
    newValue,
    previousValue,
    props,
    formProps,
    formState,
  } = params;

  /*
   popup denote that value is sent through window, and is here not because there is change, but
   must execute change manually and follow the same logic that onChange
   */
  const result = {};

  let newStates = {
    disabledFields: {},
    hiddenFields: {},
    warningFields: {},
    errors: {},
  };

  let body;
  let template;
  let templatesms = ""; // need to don't display null case, have sms is null from template
  let sms;
  if (formState.actionsave === "add") {
    /*
    block resolve single customer; important is before block resolver list customer
    because the update of 'name' = email
     */
    if (inputFullName === "customer_id") {
      let customer_id;
      if (inputFullName === "customer_id") {
        customer_id = newValue;
      } else {
        //if ( action!== 'initForm') {
        customer_id = getInputValue(formProps, "customer_id", { nameForm });
      }

      let email = "";
      //console.log('customer_id', customer_id);
      if (customer_id) {
        const filter = {
          _and: [{ id: customer_id.id ? customer_id.id : customer_id }],
        };
        const resCheck = await fetchQl(
          `
          query CustomerList ($_filter: String) {
            customers (_filter: $_filter) {
              ${strFragments.customer}
            }
          }`,
          [{ name: "_filter", type: "String", value: JSON.stringify(filter) }],
          {
            dataName: "customers",
            props: formProps, // important object that contains myState , for pelemaster resolver org
          }
        );
        //console.log('resCheck ', resCheck);
        if (resCheck && resCheck.length) {
          email = resCheck[0].email;
        }
      }
      formProps.change("name", email);
    }

    /*
    block resolve single customer; important is after block resolver single customer
    because the update of 'name' = email
     */

    /*
          { id: 5, name: 'form.onlyNoEmailSendSMS'},
          { id:10, name: 'form.sendAllSMS'},
          { id:20, name: 'form.sendOnlySMS'},
          { id:30, name: 'form.sendOnlyEmail'},
          { id:40, name: 'form.genOnlyDoc', },
     */
    let channelsfilters;
    if (inputFullName === "channelsfilters") {
      channelsfilters = newValue;
    } else {
      channelsfilters = getInputValue(formProps, "channelsfilters", {
        nameForm,
      });
    }
    const channelEmail = resolvePathObj(
      formProps,
      "myState.app.appSettings.modules.EMAIL",
      { notFound: null }
    );
    const channelSMS = resolvePathObj(
      formProps,
      "myState.app.appSettings.modules.SMS",
      { notFound: null }
    );
    if (channelsfilters === "10" && (!channelSMS || !channelEmail)) {
      newStates.errors.channelsfilters = "info.channelinactive";
    }
    if (channelsfilters === "20" && !channelSMS) {
      newStates.errors.channelsfilters = "info.channelinactive";
    }
    if (channelsfilters === "30" && !channelEmail) {
      newStates.errors.channelsfilters = "info.channelinactive";
    }
    if (action === "initForm" && formProps.containerPropsForm) {
      let filter;
      let vars = {};
      if (
        formProps.containerPropsForm.containerState.mainForm ===
        "registrationForm"
      ) {
        /*
        // customer dont needed read registration form
        //    is alread sent by default for initvalues from /registration/toobarView.js
        const customer_id = getInputValue(formProps, 'customer_id', { nameForm: 'registrationForm' });
        formProps.change('customer_id', customersWithEmail);
         */

        /*
        better use 'registrationForm' than nameForm, because nameForm on initForm
        can not be exist while load
         */
        const id = getInputValue(formProps, "id", {
          nameForm: "registrationForm",
        });

        vars.id = id;
        /*filter = {
            _and: [{ id }], // registration id
          };*/
      } else if (
        formProps.containerPropsForm.containerState.mainForm ===
        "listFilterregistration"
      ) {
        //console.log('formProps.containerPropsForm.crud_list_registration', formProps.containerPropsForm.crud_list_registration);
        for (const [key, value] of Object.entries(
          formProps.containerPropsForm.crud_list_registration.variables
        )) {
          // %%% _qlType ='ListPage' /// looks dont affect retrive List Simple
          //if (key.substr(0,1) !== '_' && value)
          // importanta take _fields (virtuals) because filter must be exactly the same
          // than previous filter registations
          if (value) vars[key] = value;
        }
      }
      //if (filter) {
      if (Object.keys(vars).length > 0) {
        const strFragmentsRegistration = `
          id
          customer_id
          registrationcustomer_id  {
            name
            email
            mobile
          }
          tourroom_id
          transportation
          price
          paid
          balance
          objects
          numbering
          `;

        const objTour_id = getInputValue(formProps, "tour_id", { nameForm });
        const tour_id = objTour_id.id || objTour_id;
        //console.log('tour_id', tour_id);
        let resTour = await fetchDirect("tour", formProps, "List", tour_id);
        //console.log('resTour', resTour);

        // services for the tour is already processed, just get data from the ql
        // for all the tours, later will look for  every list of service in registration
        // with the globalTourServices
        let resTourServices = await fetchQl(
          `
          query getTourServices ($tour_id: ID) {
            getTourServices (tour_id: $tour_id) {
              id
              name
              nameOnly
              typeEntry
              tour_id
              operator
              amount
              listOptions {
                id
                name
                amount
                nameOnly
              }
              cancellednotzero
              service_id
            }
          }`,
          [{ name: "tour_id", type: "ID", value: tour_id }],
          {
            dataName: "getTourServices",
            props: formProps, // important object that contains myState , for pelemaster resolver org
          }
        );

        // is gonne retrive mobile and phone customer
        let resRegistrations = await fetchQl(
          `
          query RegistrationList ${strArgs.registration} {
              ${strFragmentsRegistration} 
            }
          }`,
          vars,
          {
            noProcessParams: true,
            dataName: "registrations",
            props: formProps, // important object that contains myState , for pelemaster resolver org
          }
        );

        const customersWithEmail = [];
        let templateData = {};
        //console.log('resRegistrations', resRegistrations);

        for (let resRegistration of resRegistrations) {
          //console.log('resRegistration', resRegistration);
          // now same if the person has no email, a message is generated, for possible pdf print
          //if (resRegistration.registrationcustomer_id.email) {
          const customer_id = JSON.parse(resRegistration.customer_id).id;
          customersWithEmail.push({
            id: customer_id,
            registration_id: resRegistration.id,
            ...resRegistration.registrationcustomer_id,
          });
          if (customersWithEmail.length === 1) {
            /*
              replace customer_id with the first element found
               */
            formProps.change("customer_id", customersWithEmail[0]);
            formProps.change(
              "name",
              resRegistration.registrationcustomer_id.email
            );

            let resRoom = {};
            let resHotel = {};

            if (resRegistration.tourroom_id) {
              resRoom = await fetchDirect(
                "tourroom",
                formProps,
                "List",
                resRegistration.tourroom_id
              );
              if (resRoom) {
                if (resRoom.bookings) {
                  templateData.bookings = JSON.parse(resRoom.bookings);
                  templateData.bookingcustomers = JSON.parse(resRoom.customers);
                }
                //console.log('customerroom', customerroom);
                resHotel = await fetchDirect(
                  "hotel",
                  formProps,
                  "List",
                  resRoom.hotel_id
                );
                //console.log('resHotel', resHotel);
              }
            }

            /*
               CUSTOMER
               */
            // execute normal fetchql without _filter {_and}, because need resolve
            // where by subkey id in json field
            const resCustomers = await fetchQl(
              `
                query CustomerList ( $id: ID) {
                  customers (id: $id) {
                    name
                    lastname
                    firstname
                    addresses
                    customergendertype_id {
                      name
                    }  
                  }
                }`,
              [{ name: "id", type: "ID", value: customer_id }],
              {
                dataName: "customers",
                props: formProps, // important object that contains myState , for pelemaster resolver org
              }
            );
            //console.log('resPayments', resPayments);
            const aCustomers = resCustomers[0];
            aCustomers.fulladdress = getMainAddress(aCustomers);

            /*
               TOUR
               */
            // execute normal fetchql without _filter {_and}, because need resolve
            // where by subkey id in json field
            const resTours = await fetchQl(
              `
                query TourList ( $id: ID) {
                  tours (id: $id) {
                    name
                    longname
                    datestart
                    dateend
                  }
                }`,
              [{ name: "id", type: "ID", value: tour_id }],
              {
                dataName: "tours",
                props: formProps, // important object that contains myState , for pelemaster resolver org
              }
            );
            //console.log('resPayments', resPayments);
            const aTours = resTours[0];

            /*
               STOPPOINTS
               */
            // execute normal fetchql without _filter {_and}, because need resolve
            // where by subkey id in json field
            const resStopsPoints = await fetchQl(
              `
              query StopspointList {
                stopspoints  {
                     id
                     name
                     sinfo
                }    
              }`,
              [{}],
              {
                dataName: "stopspoints",
                props: formProps, // important object that contains myState , for pelemaster resolver org
              }
            );
            // console.log('resStopsPoints', resStopsPoints);

            /*
               PAYMENTS
               */
            // execute normal fetchql without _filter {_and}, because need resolve
            // where by subkey id in json field
            const resPayments = await fetchQl(
              `
                query PaymentList ($application_customer_id: String, $application_tour_id: String, $_orders: String ) {
                  payments (application_customer_id: $application_customer_id, application_tour_id: $application_tour_id, _orders: $_orders ) {
                    ${strFragments.payment}
                  }
                }`,
              [
                {
                  name: "application_customer_id",
                  type: "String",
                  value: customer_id,
                },
                { name: "application_tour_id", type: "String", value: tour_id },
                { name: "_orders", type: "String", value: "datereception" },
              ],
              {
                dataName: "payments",
                props: formProps, // important object that contains myState , for pelemaster resolver org
              }
            );
            //console.log('resPayments', resPayments);
            const aPayments = processPayments(
              resPayments,
              tour_id,
              customer_id
            );
            //console.log('aPayments ', aPayments);

            // TWIN Client SERV7101
            let aServices = [];
            if (resRegistration.objects) {
              const objects = JSON.parse(resRegistration.objects);
              for (let [key, object] of Object.entries(objects)) {
                // get the value amount, on the case listoptions, resolve name & price too
                let servicetour = getRelatedTourService(
                  resTourServices,
                  key,
                  object
                );
                if (servicetour) {
                  aServices.push(servicetour);
                }
              }
            }

            /*
              TRANSPORTS
               */
            let aTransports = [];
            if (resRegistration.transportation) {
              const transportations = JSON.parse(
                resRegistration.transportation
              );
              for (let transportation of transportations) {
                if (transportation.stopspoint_id) {
                  const stopspoint_id = transportation.stopspoint_id.id;
                  const recTransport = await fetchDirect(
                    "transportation",
                    formProps,
                    "List",
                    transportation.transportation_id.id
                  );
                  //console.log('stopspoint_id, recTransport', stopspoint_id, recTransport);
                  if (recTransport) {
                    aTransports.push({ ...recTransport, stopspoint_id });
                  }
                }
              }
            }
            templateData = {
              ...templateData,
              ...resRegistration, // insert record Registration
              resRoom,
              resTour: aTours,
              resHotel,
              aServices,
              aCustomers,
              customer_id,
              aTransports,
              aPayments,
              resStopsPoints,
            };
            //console.log('templateData', templateData);
          }
          //}
        }
        formProps.change("templatedata", templateData);

        formProps.change("customersrecipients", customersWithEmail);
      }
    }
    let title;
    if (action === "initForm" || inputFullName === "etemplate_id") {
      let etemplate_id;
      if (inputFullName === "etemplate_id") {
        etemplate_id = newValue;
      } else {
        //if ( action!== 'initForm') {
        etemplate_id = getInputValue(formProps, "etemplate_id", { nameForm });
      }

      if (etemplate_id) {
        const etemplateRecord = getRecordFromOptionsIndirectOnFilter(
          formProps,
          "etemplate",
          etemplate_id
        );
        //console.log('etemplateRecord', etemplateRecord);
        template = etemplateRecord.body || "";
        templatesms = etemplateRecord.sms || "";
        title = etemplateRecord.title;
        formProps.change("template", template);
        formProps.change("templatesms", templatesms);
        formProps.change("title", title);
      }
    }
    /*
    a template was trought a template was choosed then load the content
    or if textarea is changed by hand load that content
    template ; has value before enter here when a template listbox is choosen
     */
    if (template || inputFullName === "template" || inputFullName === "title") {
      if (inputFullName === "title") {
        title = newValue;
        // important to get last value from template, because is typing title
        template = getInputValue(formProps, "template", { nameForm });
      } else if (!title) {
        // only if template is not just selected and value come from there
        //  formProps.change('title', title); // has no time to update the real value, so dont use getInputValue()
        title = getInputValue(formProps, "title", { nameForm });
      }
      if (inputFullName === "template") {
        template = newValue; //getInputValue(formProps, 'template', { nameForm });
      }
      const templatedata = setTemplateData(
        getInputValue(formProps, "templatedata", { nameForm }),
        "€",
        formProps.t
      );
      //console.log('templatedata  FINAL', templatedata);
      if (title) {
        // title can be undefined, when 'listbox template' is not selected
        title = templateEngine(title, templatedata, true).trim();
        body = title + "\n\n";
      } else {
        body = "";
      }
      if (template) {
        body += templateEngine(template, templatedata, true).trim();
        // TWIN Server SI0213
        if (
          resolvePathObj(
            formProps,
            "myState.app.appSettings.esettings.signature"
          )
        ) {
          body +=
            "\n\n" +
            formProps.myState.app.appSettings.esettings.signature.trim();
        }
        if (
          resolvePathObj(formProps, "myState.app.appSettings.esettings.header")
        ) {
          body +=
            "\n\n" + formProps.myState.app.appSettings.esettings.header.trim();
        }
      }

      formProps.change("body", body);
    }
    /*
    a template was trought a template was choosed then load the content
    or if textarea is changed by hand load that content to SMS
     */
    if (templatesms || inputFullName === "templatesms") {
      if (inputFullName === "templatesms") templatesms = newValue; //getInputValue(formProps, 'template', { nameForm });
      if (templatesms) {
        // make sur SMS has content
        const templatedata = setTemplateData(
          getInputValue(formProps, "templatedata", { nameForm }),
          "€",
          formProps.t
        );

        sms = templateEngine(templatesms, templatedata, true).trim();

        // TWIN Server SI0213
        if (
          resolvePathObj(
            formProps,
            "myState.app.appSettings.esettings.signaturesms"
          )
        ) {
          sms +=
            "\n\n" +
            formProps.myState.app.appSettings.esettings.signaturesms.trim();
        }
        formProps.change("sms", sms);
      }

      // TWIN BLOCK SMS19
      const { lengthsms, qtysms } = getSMS(sms); // bases in previous resolved sms processesd content
      newStates.warningFields.sms = [
        tt(formProps.t, "form.sizechars::" + lengthsms) +
          ", " +
          tt(formProps.t, "form.sms") +
          ": " +
          qtysms,
      ];
    }
  }
  result.newStates = newStates;
  return result;
};

export default crudCode;
