/*
This program is not overwritten by generator.js
 */
import React, { Component } from 'react';
import { Route, Redirect,withRouter } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { connect } from 'react-redux';

import {
  graphql,
  compose,
} from 'react-apollo';

import { translate } from 'react-i18next';

import defQls from '../defQls';
import { bindActionCreators } from 'redux';
import { checkAuth } from '../utils/auth';
import { ISOtoDate, getTheme, tt } from '../utils/commonutils';
import { getFieldsChanged } from '../utils/helper';
import { appSetOrg, appSettingsLoad } from '../actions/appActions';
import themes from './themes';
import LayoutContainerTop  from './LayoutContainerTop';
import Layout from './LayoutPage';
import PopupLayout from './LayoutPopup';
import { showNotificationWithTimeout, hideNotification } from '../actions/notifActions';
import ErrorBoundary from './ErrorBoundary';

//moment.locale('fr');
const theme =  process.env.REACT_APP_THEME;
class LayoutContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPopup: this.props.location.search.includes('popup'),
      menuGrow: false,
    };
  }
  myAppSettingsLoad =() => {
    // combo org is changed; must load org config (paremeters)
    this.props.getOrgSettings.refetch();
    // need time to load record, and transfert to redux store
    setTimeout(() => {
      let config = JSON.parse(this.props.getOrgSettings.getOrgSettings.record);
      this.props.appSettingsLoad(config);
    }, 2000);
  };


  goUrl = (url ='') => {
    /*
    url can be empty, then just control changes, but not redirect to URL, useful for customs toolbars
    with special logic contrl
     */
    const log = false;
    if (log) console.log('goUrl url', url);
    if (log) console.log('this.props.myState', this.props.myState);
    if (this.props.myState.form) {
      let listFields = '';
      for (const [keyForm, objForm] of Object.entries(this.props.myState.form)) {
        if (log) console.log(keyForm, objForm);
        let tableCrud = keyForm.substr(-4) ==='Form' ? keyForm.substr(0, keyForm.length -4): '';
        //console.log('tableCrud/anyTouched',  tableCrud ,objForm.anyTouched);
        if (tableCrud && objForm.anyTouched) {
          // Due to fakefield is changed always is touched , see if it's need optimize and do'nt use fake values? i think is not really slow and it's more organzer let it like this
          listFields = getFieldsChanged(objForm, keyForm, this.props.t);
          if (listFields) break;
        }
      }
      if (listFields) {
        if(!window.confirm( tt(this.props.t,'form.goWithoutSave::'+listFields  ))) {
          return false;
        }
      }
    }
    if (url) this.props.history.push(url);
    return true;
  };

  render() {
    //console.log('layout contianer props');
    //console.log(this.props);
    // const mystore = store.getState();
    /* //console.log('---- redux notifications');
     //console.log(this.props.notifications); */
    const changeLanguage = (lng) => {
      this.props.i18n.changeLanguage(lng);
    };

    const {t, i18n} = this.props;
    if (!this.props.public) { /* Check auth only for private pages */
      const auth = checkAuth();
      if (!auth) {
        setTimeout(() => this.props.showNotificationWithTimeout(this.props.t('login.tokenInvalid'), 3, 7), 300);
        return (<Redirect to="/signin" />);
      }
    }
    const popup = this.props.location.search.includes('popup');
    return (
      <ErrorBoundary>
        <Route
          exact
          path={this.props.path}
          render={matchProps => (
            <div>

              <ThemeProvider theme={themes[theme]}>

                <div>
                  <LayoutContainerTop
                    containerPropsForm={this.props}
                    myAppSettingsLoad={this.myAppSettingsLoad}
                  />
                  <Layout
                    nameApp="PELEMASTER"
                    invisibleHeader={true}
                    i18n={i18n}
                    t={t}
                    goUrl={this.goUrl}
                    showNotificationWithTimeout={this.props.showNotificationWithTimeout}
                    hideNotification={this.props.hideNotification}
                    notifications={this.props.notifications}
                    public={this.props.public}
                    changeLanguage={changeLanguage}
                    title={this.props.title}
                    theme={themes[theme]}
                    component={this.props.component}
                    myState={this.props.myState}
                    {...matchProps}
                  />
                  {(popup || this.state.isPopup) && <PopupLayout
                    nameApp="PELEMASTER"
                    i18n={i18n}
                    t={t}
                    goUrl={this.goUrl}
                    showNotificationWithTimeout={this.props.showNotificationWithTimeout}
                    hideNotification={this.props.hideNotification}
                    notifications={this.props.notifications}
                    public={this.props.public}
                    changeLanguage={changeLanguage}
                    title={this.props.title}
                    theme={themes[theme]}
                    component={this.props.component}
                    myState={this.props.myState}
                    {...matchProps}
                  />}

                <div style={{ textAlign: 'right', color: '#888888', paddingRight: '20px' }}>
                  Version: <strong>{process.env.REACT_APP_VERSION}</strong> ({ISOtoDate(process.env.REACT_APP_DATE)})<br/>
                  
                  <br/>
                </div>
                </div>
              </ThemeProvider>
            </div>
          )}
        />
      </ErrorBoundary>

    );
  }
}



const withGraphqlandRouter = compose(

  graphql(defQls.organizer.ListMini, {
    name: 'list_organizer',
    options: props => {
      const optionsValues = {} ;
      optionsValues.fetchPolicy = 'network-only';
      optionsValues.variables = { _qlType: 'ListMini' };
      return optionsValues;
    },
  }),
  graphql(defQls.getOrgSettings, {
    name: 'getOrgSettings',
    options: props => {
      const optionsValues = {  };
      if (props.myState && props.myState.app) optionsValues.variables= { organizer_id: props.myState.app.org };
      optionsValues.fetchPolicy = 'network-only';
      return optionsValues ;
    },
  }),
)(withRouter(LayoutContainer));

const mapStateToProps = state => ({
  myState: state,
  themeName: state.app.themeName,
  toasts: state.toasts,
  notifications: state.notifications,
});


const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ appSettingsLoad, appSetOrg, showNotificationWithTimeout, hideNotification, dispatch }, dispatch);
};

const App = connect(mapStateToProps, mapDispatchToProps)(withGraphqlandRouter);
export default translate('translations')(App);
