import React from "react";
import { Field, reduxForm, change } from "redux-form";
import { bindActionCreators } from "redux";
import Portal from "../Portal";
import { fetchQl } from "../../apolloClient";
import FormField from "../FormField";
import crudCode from "./crudCode";
import { processNewValues, uuid } from "../../utils/helper";
import { connect } from "react-redux";
import {
  processValues,
  resolvePathObj,
  getInputValue,
  sourcesOptions,
  getObjFromListById,
} from "../../utils/commonutils";
import { Tables } from "../../defTables";
import { appSubmitStart, appSubmitStop } from "../../actions/appActions";
import { showConfirm } from "../../actions/confirmActions";
import IconLoading from "../icons/IconLoading";
import ShowIcon from "../icons/ShowIcon";
import { Button } from "../../layouts/cssstyled";

const nameForm = "MemberAddress";
class MyComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };
  }

  executeCode = async (methodCode = "", params = {}) => {};

  render() {
    const { showModal } = this.state;

    let customerList = [];
    let countCustomers;

    if (this.props.containerPropsForm.containerState.actionsave === "list") {
      let recRegistration =
        this.props.containerPropsForm.crud_list_registration.pageregistrations;
      countCustomers = recRegistration.page._totalrecords;
      /*for (let i = 0; i < recRegistration.nodes.length && i<3; i++) {
        // customer is a field inside recRegistration
        const customer = JSON.parse(recRegistration.nodes[i].customer_id);
        customerList.push(<div key={i}>{customer.name}</div>);
      }*/

      /*
      here get the  customer list from the virtual hidden field customersrecipients
      that was filled in ejobmail/crudCode.js
      in the same form
       */
    } else {
      countCustomers = 1; // always 1 if not come from list
    }

    const customers = getInputValue(
      this.props.containerPropsForm,
      "customersrecipients",
      { nameForm: "ejobmailForm" }
    );
    let countStatCustomers;
    if (customers) {
      if (customers.length) {
        // make sure that ql loading is finished
        for (let i = 0; i < customers.length; i++) {
          // && i<10
          customerList.push(
            <div key={i}>
              {i + 1}. {customers[i].name}
              <span style={{ color: "#777777" }}>
                {customers[i].email}
              </span>{" "}
              <span style={{ color: "#777777" }}>{customers[i].mobile}</span>
            </div>
          );
        }
        //countStatCustomers = customers.length + '/' + countCustomers;
        countStatCustomers = customers.length;
      } else {
        // not found customer with email
        customerList.push(
          <div key="x" style={{ color: "rgb(190, 0, 0)" }}>
            {this.props.containerPropsForm.t("info.hasnoEmail")}
          </div>
        );
      }
    } else {
      customerList.push(<IconLoading key="icon" size="25" />);
    }
    /*
    countCustomers is total, taking customer without email
     */

    // if (countCustomers > 3)  customerList.push(<div key="etc"> ...{this.props.containerPropsForm.t('form.etc')}</div>);

    return (
      <div
        style={{
          height: "190px",
          overflow: "auto",
        }}
      >
        <div>
          <label className="formLabel" style={{ display: "inline" }}>
            {this.props.containerPropsForm.t("table.customers")} (
            {countStatCustomers}) :
          </label>
        </div>
        <div style={{ fontSize: "14px" }}> {customerList} </div>
      </div>
    );
  }
}
//

function mapStateToProps(state, ownProps) {
  const initialValues = {};
  const statesReturn = { myState: state, initialValues };
  return statesReturn;
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { appSubmitStart, appSubmitStop, showConfirm, dispatch },
    dispatch
  );
};

const ComponentWithDataAndState = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyComponent);

export default ComponentWithDataAndState;
