import React, { Component } from "react";
import debounce from "lodash/debounce";
import { Field, FieldArray, reduxForm, change } from "redux-form";
import { connect } from "react-redux";

import { Button } from "../../layouts/cssstyled";
import FormField from "../FormField";
import FormArrayField from "../FormArrayField";
import ShowIcon from "../icons/ShowIcon";
import {
  fieldsValidator,
  getOptions,
  processNewValues,
} from "../../utils/helper";
import {
  resolvePathObj,
  processValues,
  realTypeOf,
  getInputValue,
  getPermission,
  tryParseJSON,
  getNameHotelAndRoom,
} from "../../utils/commonutils";
import { getSession } from "../../utils/auth";

import { Tables } from "../../defTables";
import { normalizes } from "../../utils/normalizes";

const tableCrud = "tour";
let nameForm = `${tableCrud}Form`;
const validate = (values, props) => {
  if (values.body && values.body.includes("{{")) {
    return { etemplate_id: "validator.template" };
  }
  return true;
};
const aServices = {
  type: "String",
  dbtype: "Json",
  labelChildsShow: true,
  placeHolderShowChildsShow: false,
  subfields: {
    id: {
      type: "ID",
      typeInput: "hidden",
      label: "form.id",
    },
    tourservices_id: {
      type: "String",
      label: "form.service",
      typeInput: "selectAutocomplete",
      validators: ["required"],
      onChange: true,
    },
    validation: {
      label: "validator.validation",
      type: "String", // virtual parameter needs to define type for graphql
      realType: "Float", // real type target field
      typeInput: "selectBox",
      listOptions: [
        { id: "", name: "form.selectInputLabel" },
        { id: "1", name: "validator.required" },
      ],
      defaultValue: "", // always in string
    },
  },
  formNewSection: true,
  typeInput: "text",
  label: "form.services",
  mode: {},
};
const aTourRooms = {
  type: "String",
  dbtype: "Json",
  labelChildsShow: true,
  placeHolderShowChildsShow: false,
  subfields: {
    id: {
      type: "ID",
      typeInput: "hidden",
      label: "form.id",
    },
    tourroom_id: {
      type: "String",
      label: "table.tourroom",
      typeInput: "selectAutocomplete",
      validators: ["required"],
      onChange: true,
    },
    priceroom: {
      type: "Float",
      label: "form.price",
      typeValue: "Price",
      normalize: "PriceNegative",
    },
  },
  formNewSection: true,
  typeInput: "text",
  label: "table.tourrooms",
  mode: {},
};

class Form extends Component {
  constructor(props) {
    super(props);
    let session = getSession();
    // TWIN TAI231
    let permission = getPermission(
      session,
      tableCrud,
      props.formState.actionsave
    );
    this.state = {
      itineraryVisible: false,
      dialogOpened: {},
      permission,
    };
  }

  componentDidMount() {
    this.timerHandle = setTimeout(() => {
      this.props.dispatch(change(nameForm, "_fakefield", Math.random()));
      this.props.executeCode("onChangeInput", {
        nameForm,
        action: "initForm",
        props: this.props,
        formProps: this.props,
        formState: this.props.formState,
      });
    }, 1000);
    window.addEventListener("message", this.readMessage, true);
  }

  componentWillUnmount() {
    if (this.timerHandle) {
      clearTimeout(this.timerHandle);
      this.timerHandle = 0;
    }
    window.removeEventListener("message", this.readMessage, true);
  }

  toolbarFunctions = (toolbar, params = {}) => {
    if (toolbar === "gcu") {
      /*const tour_id =  getInputValue( this.props,  'tour_id', {nameForm: 'listFilterregistration'});
      if (!tour_id) {
        alert ( this.props.t('validator.required') + ': '+ this.props.t('table.tour'));
        return false;
      }*/

      this.setState({
        dialogOpened: { ...this.state.dialogOpened, RichText: true },
        defaultValues: {
          /*customer_id: null,
            tour_id: tour_id,*/
        },
      });
    }
    if (toolbar === "itinerary") {
      const tour_id = getInputValue(this.props, "tour_id", {
        nameForm: "listFilterregistration",
      });
      if (!tour_id) {
        alert(
          this.props.t("validator.required") + ": " + this.props.t("table.tour")
        );
        return false;
      }
      this.setState({
        dialogOpened: { ...this.state.dialogOpened, Itinerary: true },
        defaultValues: {
          customer_id: null,
          tour_id: tour_id,
        },
      });
    }

    if (toolbar === "closeDialog") {
      let dialogOpenedNew = this.state.dialogOpened;
      if (dialogOpenedNew[params.dialogName]) {
        delete dialogOpenedNew[params.dialogName];
        this.setState({ dialogOpened: dialogOpenedNew });
      }
    }
  };

  readMessage = (event) => {
    if (!event.origin.includes(window.location.hostname)) return;
    if (event && event.data) {
      const params = tryParseJSON(event.data, {});
      if (params.popup) {
        params.inputFullName = params.popup;
        if (!params.formProps) params.formProps = this.props;
        this.props.executeCode("onChangeInput", params);
      }
    }
  };

  hocdebounced = debounce((methodCode, params) => {
    this.props.executeCode(methodCode, params);
  }, 1500);

  render() {
    const {
      error,
      handleSubmit,
      invalid,
      pristine,
      change,
      submitting,
      submitFailed,
      containerPropsForm,
      customerstatusid,
      t,
      ...otherProps
    } = this.props;

    const listCustomerStatuss = [];
    let customerStatuss = [];

    if (this.props.formState._resTourCustomerStatusAndServices) {
      customerStatuss = this.props.formState._resTourCustomerStatusAndServices;
    }

    const tourServices = containerPropsForm.getTourServices.getTourServices;
    const tourRooms = containerPropsForm.list_tourroom.tourrooms;
    /* const tourRooms = [];
    tourRoomsBrute.map((tourroom, index) => {
      // const name = getNameHotelAndRoom(t, tourroom, false);
      console.log("tourroom", index, tourroom.name);
      let tourroomBis = Object.assign(
        {},
        { id: tourroom.id, name: tourroom.name }
      );
      console.log('tourroomBis', tourroomBis);
      tourRooms.push(tourroomBis);
    });
    console.log("tourRooms", tourRooms);*/

    for (const customerStatus of customerStatuss) {
      const idInputFilteredServices = `filteredservices__${customerStatus.id}`;
      const idInputFilteredHotels = `filteredhotels__${customerStatus.id}`;

      const idInputPrice = `tourprice_price__${customerStatus.id}`;

      let inputSubstatuss = [];

      customerStatus.substatuss.map((substatus) => {
        // twin CSUB1401
        const idInputSubstatusPrice = `tourprice_price__substatus_${customerStatus.id}_${substatus.id}`;
        const idInputSubstatusFilteredServices = `tourprice_filteredservices__substatus_${customerStatus.id}_${substatus.id}`;
        const idInputSubstatusFilteredHotels = `tourprice_filteredhotels__substatus_${customerStatus.id}_${substatus.id}`;
        inputSubstatuss.push(
          <div key={idInputSubstatusPrice}>
            <div style={{ marginLeft: "30px", fontSize: "21px" }}>
              {substatus.name}
            </div>
            <div style={{ marginLeft: "30px" }}>
              <Field
                inputName={idInputSubstatusPrice}
                name={idInputSubstatusPrice}
                label="form.baseprice"
                component={FormField}
                width="200px"
                formProps={this.props}
                formState={this.props.formState}
                executeCode={this.props.executeCode}
                syncCode={this.props.syncCode}
                type="text"
                normalize={normalizes.Price}
                typeInput="text"
              />
            </div>

            <div className="formSection" style={{ marginLeft: "60px" }}>
              <FieldArray
                inputName={idInputSubstatusFilteredHotels}
                name={idInputSubstatusFilteredHotels}
                pathInObject={aTourRooms}
                fieldParentKey={idInputSubstatusFilteredHotels}
                formProps={this.props}
                formState={this.props.formState}
                nameForm={nameForm}
                component={FormArrayField}
                tableCrud={tableCrud}
                noFooter={true}
                executeCode={this.props.executeCode}
                syncCode={this.props.syncCode}
                t={t}
                mode={{}}
                labelChildsShow={true}
              />
            </div>
            <div className="formSection" style={{ marginLeft: "60px" }}>
              <FieldArray
                inputName={idInputSubstatusFilteredServices}
                name={idInputSubstatusFilteredServices}
                pathInObject={aServices}
                fieldParentKey={idInputSubstatusFilteredServices}
                formProps={this.props}
                formState={this.props.formState}
                nameForm={nameForm}
                component={FormArrayField}
                tableCrud={tableCrud}
                noFooter={true}
                executeCode={this.props.executeCode}
                syncCode={this.props.syncCode}
                t={t}
                mode={{}}
                labelChildsShow={true}
              />
            </div>
          </div>
        );
      });
      listCustomerStatuss.push(
        <div key={customerStatus.id} style={{ padding: "10px" }}>
          <div>
            <div style={{ fontSize: "26px" }}>{customerStatus.nameOnly}</div>
            <div style={{ marginLeft: "0px" }}>
              <Field
                inputName={idInputPrice}
                name={idInputPrice}
                label="form.baseprice"
                component={FormField}
                width="200px"
                formProps={this.props}
                formState={this.props.formState}
                executeCode={this.props.executeCode}
                syncCode={this.props.syncCode}
                type="text"
                normalize={normalizes.Price}
                typeInput="text"
              />
            </div>
          </div>

          <div className="formSection" style={{ marginLeft: "30px" }}>
            <FieldArray
              inputName={idInputFilteredHotels}
              name={idInputFilteredHotels}
              pathInObject={aTourRooms}
              fieldParentKey={idInputFilteredHotels}
              formProps={this.props}
              formState={this.props.formState}
              nameForm={nameForm}
              component={FormArrayField}
              tableCrud={tableCrud}
              noFooter={true}
              executeCode={this.props.executeCode}
              syncCode={this.props.syncCode}
              t={t}
              mode={{}}
              labelChildsShow={true}
            />
          </div>
          <div className="formSection" style={{ marginLeft: "30px" }}>
            <FieldArray
              inputName={idInputFilteredServices}
              name={idInputFilteredServices}
              pathInObject={aServices}
              fieldParentKey={idInputFilteredServices}
              formProps={this.props}
              formState={this.props.formState}
              nameForm={nameForm}
              component={FormArrayField}
              tableCrud={tableCrud}
              noFooter={true}
              executeCode={this.props.executeCode}
              syncCode={this.props.syncCode}
              t={t}
              mode={{}}
              labelChildsShow={true}
            />
          </div>
          <div>{inputSubstatuss}</div>
        </div>
      );
    }
    aServices.subfields.tourservices_id.listOptions = tourServices;
    aTourRooms.subfields.tourroom_id.listOptions = tourRooms;

    return (
      <form onSubmit={handleSubmit}>
        <div style={{ fontSize: "19px", color: "#bbbbbb" }}>
          {containerPropsForm.crud_view_tour.tour.longname}
        </div>
        <div className="appBodyTitleSeparator" />

        {listCustomerStatuss}
        <Field
          inputName="_formstate"
          name="_formstate"
          typeInput="hidden"
          component={FormField}
          label=""
        />
        <div key="toolbar" style={{ flexDirection: "column" }}>
          <div className="formRow">
            <div style={{ position: "relative", margin: "auto" }}>
              <div className="iconInput">
                <ShowIcon size="25" color="#ffffff" icon="saveblack" />
              </div>
              <Button
                type="submit"
                disabled={submitting}
                style={{ paddingLeft: "35px" }}
              >
                {t("form.save")}
              </Button>
            </div>
          </div>
          <div>
            {error && <strong>{error}</strong>}

            <div className="formError">
              {invalid && submitFailed && t("form.haserrors")}
            </div>
          </div>
        </div>
      </form>
    );
  }
}

const ComponentWithData = reduxForm({
  form: nameForm,
  touchOnChange: true,

  enableReinitialize: true,
  validate,
})(Form);

function mapStateToProps(state, ownProps) {
  const statesReturn = { myState: state };
  let initialValues = {};

  if (
    ownProps.containerPropsForm.getTourCustomerStatusAndServices &&
    ownProps.containerPropsForm.getTourCustomerStatusAndServices
      .getTourCustomerStatusAndServices
  ) {
    /* 
    
    moved to crud code
    for (const customerStatus of ownProps.containerPropsForm
      .getTourCustomerStatusAndServices.getTourCustomerStatusAndServices) {
      const idInputPrice = `tourprice_price__${customerStatus.id}`;
      const idInputFilteredServices = `filteredservices__${customerStatus.id}`;
      const idInputFilteredHotels = `filteredhotels__${customerStatus.id}`;

      initialValues[idInputPrice] = customerStatus.tourprice_price;
      let substatuss = tryParseJSON(customerStatus.substatuss, []);
      substatuss.map((substatus) => {
        // twin CSUB1401

        const idInputSubstatusPrice = `tourprice_price__substatus_${customerStatus.id}_${substatus.id}`;
        const idInputSubstatusFilteredServices = `tourprice_filteredservices__substatus_${customerStatus.id}_${substatus.id}`;
        const idInputSubstatusFilteredHotels = `tourprice_filteredhotels__substatus_${customerStatus.id}_${substatus.id}`;
        initialValues[idInputSubstatusPrice] = substatus.inputprice;
        initialValues[idInputSubstatusFilteredServices] = tryParseJSON(
          substatus.filteredservices,
          []
        );
        initialValues[idInputSubstatusFilteredHotels] = tryParseJSON(
          substatus.filteredhotels,
          []
        );
      });

      //
      // console.log("price", idInputPrice, customerStatus.tourprice_price);
      initialValues[idInputFilteredServices] = tryParseJSON(
        customerStatus.filteredservices,
        []
      );
      initialValues[idInputFilteredHotels] = tryParseJSON(
        customerStatus.filteredhotels,
        []
      );

    }*/
  }

  statesReturn.initialValues = initialValues;

  return statesReturn;
}

const ComponentWithDataAndState = connect(
  mapStateToProps,
  null
)(ComponentWithData);

export default ComponentWithDataAndState;
