
// import strQls from './defStrQls.js';
import strFragments from './defStrFragmentsQls.js'
import strArgs from './defStrArgsQls.js'
const strQls = {};
strQls['organizer'] = {};

strQls['organizer']['ListPage'] = `
      query OrganizerPageList ($name: String, $province: String, $city: String, $email: String, $id: ID, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String, $_orders: String) {
        pageorganizers  (name: $name, province: $province, city: $city, email: $email, id: $id, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process, _orders: $_orders) {
            page {
              _page
              _totalrecords
              _totalpages
              _recordsperpage
            }
            nodes {
               ${strFragments.organizer}
            }   
        }    
      }
      `;
strQls['customeraddress'] = {};

strQls['customeraddress']['ListPage'] = `
      query CustomeraddressPageList ($organizer_id: String, $id: ID, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String) {
        pagecustomeraddresss  (organizer_id: $organizer_id, id: $id, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process) {
            page {
              _page
              _totalrecords
              _totalpages
              _recordsperpage
            }
            nodes {
               ${strFragments.customeraddress}
            }   
        }    
      }
      `;
strQls['person'] = {};

strQls['person']['ListPage'] = `
      query PersonPageList ($organizer_id: String, $lastname: String, $firstname: String, $addresses_city_id: ID, $inactive: Boolean, $id: ID, $name: String, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String, $_orders: String) {
        pagepersons  (organizer_id: $organizer_id, lastname: $lastname, firstname: $firstname, addresses_city_id: $addresses_city_id, inactive: $inactive, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process, _orders: $_orders) {
            page {
              _page
              _totalrecords
              _totalpages
              _recordsperpage
            }
            nodes {
               ${strFragments.person}
            }   
        }    
      }
      `;
strQls['customer'] = {};

strQls['customer']['ListPage'] = `
      query CustomerPageList ($organizer_id: String, $numbering: String, $lastname: String, $firstname: String, $customerstatus_id: String, $addresses_city_id: ID, $inactive: Boolean, $nosharedata: Boolean, $nonewsletter: Boolean, $id: ID, $name: String, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String, $_orders: String) {
        pagecustomers  (organizer_id: $organizer_id, numbering: $numbering, lastname: $lastname, firstname: $firstname, customerstatus_id: $customerstatus_id, addresses_city_id: $addresses_city_id, inactive: $inactive, nosharedata: $nosharedata, nonewsletter: $nonewsletter, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process, _orders: $_orders) {
            page {
              _page
              _totalrecords
              _totalpages
              _recordsperpage
            }
            nodes {
               ${strFragments.customer}
            }   
        }    
      }
      `;
strQls['tour'] = {};

strQls['tour']['ListPage'] = `
      query TourPageList ($organizer_id: String, $name: String, $_multidate: String, $_multidate1: String, $_multidate2: String, $_multidateField: String, $inactive: Boolean, $id: ID, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String) {
        pagetours  (organizer_id: $organizer_id, name: $name, _multidate: $_multidate, _multidate1: $_multidate1, _multidate2: $_multidate2, _multidateField: $_multidateField, inactive: $inactive, id: $id, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process) {
            page {
              _page
              _totalrecords
              _totalpages
              _recordsperpage
            }
            nodes {
               ${strFragments.tour}
            }   
        }    
      }
      `;
strQls['tourcategory'] = {};

strQls['tourcategory']['ListPage'] = `
      query TourcategoryPageList ($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String) {
        pagetourcategorys  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process) {
            page {
              _page
              _totalrecords
              _totalpages
              _recordsperpage
            }
            nodes {
               ${strFragments.tourcategory}
            }   
        }    
      }
      `;
strQls['transportation'] = {};

strQls['transportation']['ListPage'] = `
      query TransportationPageList ($organizer_id: String, $tour_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String) {
        pagetransportations  (organizer_id: $organizer_id, tour_id: $tour_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process) {
            page {
              _page
              _totalrecords
              _totalpages
              _recordsperpage
            }
            nodes {
               ${strFragments.transportation}
            }   
        }    
      }
      `;
strQls['tourprice'] = {};

strQls['tourprice']['ListPage'] = `
      query TourpricePageList ($organizer_id: String, $tour_id: String, $id: ID, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String) {
        pagetourprices  (organizer_id: $organizer_id, tour_id: $tour_id, id: $id, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process) {
            page {
              _page
              _totalrecords
              _totalpages
              _recordsperpage
            }
            nodes {
               ${strFragments.tourprice}
            }   
        }    
      }
      `;
strQls['tourroom'] = {};

strQls['tourroom']['ListPage'] = `
      query TourroomPageList ($organizer_id: String, $tour_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String, $_orders: String) {
        pagetourrooms  (organizer_id: $organizer_id, tour_id: $tour_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process, _orders: $_orders) {
            page {
              _page
              _totalrecords
              _totalpages
              _recordsperpage
            }
            nodes {
               ${strFragments.tourroom}
            }   
        }    
      }
      `;
strQls['regonline'] = {};

strQls['regonline']['ListPage'] = `
      query RegonlinePageList ($organizer_id: String, $tour_id: ID, $state: Int, $id: ID, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String, $_orders: String) {
        pageregonlines  (organizer_id: $organizer_id, tour_id: $tour_id, state: $state, id: $id, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process, _orders: $_orders) {
            page {
              _page
              _totalrecords
              _totalpages
              _recordsperpage
            }
            nodes {
               ${strFragments.regonline}
            }   
        }    
      }
      `;
strQls['registration'] = {};

strQls['registration']['ListPage'] = `
      query RegistrationPageList ($organizer_id: String, $numbering: String, $customer_id: ID, $tour_id: ID, $transportation_transportation_id: String, $tourroom_id: String, $registrationstatus_id_statusbase: Int, $customerstatus_id: String, $customersubstatus_id: String, $confirmationstatus_id_statusbase: Int, $_balance: String, $assignedroom: Boolean, $_channel: String, $_withmobile: String, $inactive: Boolean, $id: ID, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String, $_orders: String) {
        pageregistrations  (organizer_id: $organizer_id, numbering: $numbering, customer_id: $customer_id, tour_id: $tour_id, transportation_transportation_id: $transportation_transportation_id, tourroom_id: $tourroom_id, registrationstatus_id_statusbase: $registrationstatus_id_statusbase, customerstatus_id: $customerstatus_id, customersubstatus_id: $customersubstatus_id, confirmationstatus_id_statusbase: $confirmationstatus_id_statusbase, _balance: $_balance, assignedroom: $assignedroom, _channel: $_channel, _withmobile: $_withmobile, inactive: $inactive, id: $id, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process, _orders: $_orders) {
            page {
              _page
              _totalrecords
              _totalpages
              _recordsperpage
            }
            nodes {
               ${strFragments.registration}
            }   
        }    
      }
      `;
strQls['customerhistory'] = {};

strQls['customerhistory']['ListPage'] = `
      query CustomerhistoryPageList ($organizer_id: String, $customer_id: String, $id: ID, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String) {
        pagecustomerhistorys  (organizer_id: $organizer_id, customer_id: $customer_id, id: $id, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process) {
            page {
              _page
              _totalrecords
              _totalpages
              _recordsperpage
            }
            nodes {
               ${strFragments.customerhistory}
            }   
        }    
      }
      `;
strQls['paymenthistory'] = {};

strQls['paymenthistory']['ListPage'] = `
      query PaymenthistoryPageList ($organizer_id: String, $payment_id: String, $id: ID, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String) {
        pagepaymenthistorys  (organizer_id: $organizer_id, payment_id: $payment_id, id: $id, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process) {
            page {
              _page
              _totalrecords
              _totalpages
              _recordsperpage
            }
            nodes {
               ${strFragments.paymenthistory}
            }   
        }    
      }
      `;
strQls['registrationhistory'] = {};

strQls['registrationhistory']['ListPage'] = `
      query RegistrationhistoryPageList ($organizer_id: String, $registration_id: String, $id: ID, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String) {
        pageregistrationhistorys  (organizer_id: $organizer_id, registration_id: $registration_id, id: $id, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process) {
            page {
              _page
              _totalrecords
              _totalpages
              _recordsperpage
            }
            nodes {
               ${strFragments.registrationhistory}
            }   
        }    
      }
      `;
strQls['payment'] = {};

strQls['payment']['ListPage'] = `
      query PaymentPageList ($check_number: String, $organizer_id: String, $application_customer_id: String, $application_tour_id: String, $paymentdocstatus_id: String, $paymentmethod_id: String, $paymentmethod_id_ajournalcode_id: String, $_balanceinit: Float, $name: String, $amount: Float, $_depositedcheck: Int, $_multidate: String, $_multidate1: String, $_multidate2: String, $_multidateField: String, $id: ID, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String, $_orders: String) {
        pagepayments  (check_number: $check_number, organizer_id: $organizer_id, application_customer_id: $application_customer_id, application_tour_id: $application_tour_id, paymentdocstatus_id: $paymentdocstatus_id, paymentmethod_id: $paymentmethod_id, paymentmethod_id_ajournalcode_id: $paymentmethod_id_ajournalcode_id, _balanceinit: $_balanceinit, name: $name, amount: $amount, _depositedcheck: $_depositedcheck, _multidate: $_multidate, _multidate1: $_multidate1, _multidate2: $_multidate2, _multidateField: $_multidateField, id: $id, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process, _orders: $_orders) {
            page {
              _page
              _totalrecords
              _totalpages
              _recordsperpage
            }
            nodes {
               ${strFragments.payment}
            }   
        }    
      }
      `;
strQls['checkdeposithead'] = {};

strQls['checkdeposithead']['ListPage'] = `
      query CheckdepositheadPageList ($organizer_id: String, $name: String, $id: ID, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String) {
        pagecheckdepositheads  (organizer_id: $organizer_id, name: $name, id: $id, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process) {
            page {
              _page
              _totalrecords
              _totalpages
              _recordsperpage
            }
            nodes {
               ${strFragments.checkdeposithead}
            }   
        }    
      }
      `;
strQls['checkdepositdet'] = {};

strQls['checkdepositdet']['ListPage'] = `
      query CheckdepositdetPageList ($organizer_id: String, $checkdeposithead_id: String, $id: ID, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String, $_orders: String) {
        pagecheckdepositdets  (organizer_id: $organizer_id, checkdeposithead_id: $checkdeposithead_id, id: $id, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process, _orders: $_orders) {
            page {
              _page
              _totalrecords
              _totalpages
              _recordsperpage
            }
            nodes {
               ${strFragments.checkdepositdet}
            }   
        }    
      }
      `;
strQls['export'] = {};

strQls['export']['ListPage'] = `
      query ExportPageList ($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String) {
        pageexports  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process) {
            page {
              _page
              _totalrecords
              _totalpages
              _recordsperpage
            }
            nodes {
               ${strFragments.export}
            }   
        }    
      }
      `;
strQls['hotel'] = {};

strQls['hotel']['ListPage'] = `
      query HotelPageList ($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String) {
        pagehotels  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process) {
            page {
              _page
              _totalrecords
              _totalpages
              _recordsperpage
            }
            nodes {
               ${strFragments.hotel}
            }   
        }    
      }
      `;
strQls['stopspoint'] = {};

strQls['stopspoint']['ListPage'] = `
      query StopspointPageList ($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String) {
        pagestopspoints  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process) {
            page {
              _page
              _totalrecords
              _totalpages
              _recordsperpage
            }
            nodes {
               ${strFragments.stopspoint}
            }   
        }    
      }
      `;
strQls['service'] = {};

strQls['service']['ListPage'] = `
      query ServicePageList ($organizer_id: String, $name: String, $id: ID, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String, $_orders: String) {
        pageservices  (organizer_id: $organizer_id, name: $name, id: $id, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process, _orders: $_orders) {
            page {
              _page
              _totalrecords
              _totalpages
              _recordsperpage
            }
            nodes {
               ${strFragments.service}
            }   
        }    
      }
      `;
strQls['city'] = {};

strQls['city']['ListPage'] = `
      query CityPageList ($organizer_id: String, $name: String, $id: ID, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String, $_orders: String) {
        pagecitys  (organizer_id: $organizer_id, name: $name, id: $id, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process, _orders: $_orders) {
            page {
              _page
              _totalrecords
              _totalpages
              _recordsperpage
            }
            nodes {
               ${strFragments.city}
            }   
        }    
      }
      `;
strQls['registrationstatus'] = {};

strQls['registrationstatus']['ListPage'] = `
      query RegistrationstatusPageList ($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String) {
        pageregistrationstatuss  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process) {
            page {
              _page
              _totalrecords
              _totalpages
              _recordsperpage
            }
            nodes {
               ${strFragments.registrationstatus}
            }   
        }    
      }
      `;
strQls['confirmationstatus'] = {};

strQls['confirmationstatus']['ListPage'] = `
      query ConfirmationstatusPageList ($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String) {
        pageconfirmationstatuss  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process) {
            page {
              _page
              _totalrecords
              _totalpages
              _recordsperpage
            }
            nodes {
               ${strFragments.confirmationstatus}
            }   
        }    
      }
      `;
strQls['bank'] = {};

strQls['bank']['ListPage'] = `
      query BankPageList ($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String) {
        pagebanks  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process) {
            page {
              _page
              _totalrecords
              _totalpages
              _recordsperpage
            }
            nodes {
               ${strFragments.bank}
            }   
        }    
      }
      `;
strQls['paymentmethod'] = {};

strQls['paymentmethod']['ListPage'] = `
      query PaymentmethodPageList ($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String) {
        pagepaymentmethods  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process) {
            page {
              _page
              _totalrecords
              _totalpages
              _recordsperpage
            }
            nodes {
               ${strFragments.paymentmethod}
            }   
        }    
      }
      `;
strQls['paymentdocstatus'] = {};

strQls['paymentdocstatus']['ListPage'] = `
      query PaymentdocstatusPageList ($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String) {
        pagepaymentdocstatuss  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process) {
            page {
              _page
              _totalrecords
              _totalpages
              _recordsperpage
            }
            nodes {
               ${strFragments.paymentdocstatus}
            }   
        }    
      }
      `;
strQls['customerstatus'] = {};

strQls['customerstatus']['ListPage'] = `
      query CustomerstatusPageList ($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String, $_orders: String) {
        pagecustomerstatuss  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process, _orders: $_orders) {
            page {
              _page
              _totalrecords
              _totalpages
              _recordsperpage
            }
            nodes {
               ${strFragments.customerstatus}
            }   
        }    
      }
      `;
strQls['gendertype'] = {};

strQls['gendertype']['ListPage'] = `
      query GendertypePageList ($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String) {
        pagegendertypes  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process) {
            page {
              _page
              _totalrecords
              _totalpages
              _recordsperpage
            }
            nodes {
               ${strFragments.gendertype}
            }   
        }    
      }
      `;
strQls['userlog'] = {};

strQls['userlog']['ListPage'] = `
      query UserlogPageList ($organizer_id: String, $user_id: String, $action: String, $id: ID, $name: String, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String) {
        pageuserlogs  (organizer_id: $organizer_id, user_id: $user_id, action: $action, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process) {
            page {
              _page
              _totalrecords
              _totalpages
              _recordsperpage
            }
            nodes {
               ${strFragments.userlog}
            }   
        }    
      }
      `;
strQls['user'] = {};

strQls['user']['ListPage'] = `
      query UserPageList ($organizer_id: String, $nick: String, $longname: String, $id: ID, $name: String, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String) {
        pageusers  (organizer_id: $organizer_id, nick: $nick, longname: $longname, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process) {
            page {
              _page
              _totalrecords
              _totalpages
              _recordsperpage
            }
            nodes {
               ${strFragments.user}
            }   
        }    
      }
      `;
strQls['usergroup'] = {};

strQls['usergroup']['ListPage'] = `
      query UsergroupPageList ($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String) {
        pageusergroups  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process) {
            page {
              _page
              _totalrecords
              _totalpages
              _recordsperpage
            }
            nodes {
               ${strFragments.usergroup}
            }   
        }    
      }
      `;
strQls['ejobmail'] = {};

strQls['ejobmail']['ListPage'] = `
      query EjobmailPageList ($organizer_id: String, $title: String, $customer_id: String, $tour_id: ID, $id: ID, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String, $_orders: String) {
        pageejobmails  (organizer_id: $organizer_id, title: $title, customer_id: $customer_id, tour_id: $tour_id, id: $id, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process, _orders: $_orders) {
            page {
              _page
              _totalrecords
              _totalpages
              _recordsperpage
            }
            nodes {
               ${strFragments.ejobmail}
            }   
        }    
      }
      `;
strQls['etemplate'] = {};

strQls['etemplate']['ListPage'] = `
      query EtemplatePageList ($organizer_id: String, $name: String, $title: String, $id: ID, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String, $_orders: String) {
        pageetemplates  (organizer_id: $organizer_id, name: $name, title: $title, id: $id, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process, _orders: $_orders) {
            page {
              _page
              _totalrecords
              _totalpages
              _recordsperpage
            }
            nodes {
               ${strFragments.etemplate}
            }   
        }    
      }
      `;
strQls['ejobday'] = {};

strQls['ejobday']['ListPage'] = `
      query EjobdayPageList ($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String, $_orders: String) {
        pageejobdays  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process, _orders: $_orders) {
            page {
              _page
              _totalrecords
              _totalpages
              _recordsperpage
            }
            nodes {
               ${strFragments.ejobday}
            }   
        }    
      }
      `;
strQls['ejobmonth'] = {};

strQls['ejobmonth']['ListPage'] = `
      query EjobmonthPageList ($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String, $_orders: String) {
        pageejobmonths  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process, _orders: $_orders) {
            page {
              _page
              _totalrecords
              _totalpages
              _recordsperpage
            }
            nodes {
               ${strFragments.ejobmonth}
            }   
        }    
      }
      `;
strQls['aplan'] = {};

strQls['aplan']['ListPage'] = `
      query AplanPageList ($organizer_id: String, $name: String, $title: String, $id: ID, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String, $_orders: String) {
        pageaplans  (organizer_id: $organizer_id, name: $name, title: $title, id: $id, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process, _orders: $_orders) {
            page {
              _page
              _totalrecords
              _totalpages
              _recordsperpage
            }
            nodes {
               ${strFragments.aplan}
            }   
        }    
      }
      `;
strQls['acostplan'] = {};

strQls['acostplan']['ListPage'] = `
      query AcostplanPageList ($organizer_id: String, $name: String, $title: String, $id: ID, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String, $_orders: String) {
        pageacostplans  (organizer_id: $organizer_id, name: $name, title: $title, id: $id, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process, _orders: $_orders) {
            page {
              _page
              _totalrecords
              _totalpages
              _recordsperpage
            }
            nodes {
               ${strFragments.acostplan}
            }   
        }    
      }
      `;
strQls['ajournalcode'] = {};

strQls['ajournalcode']['ListPage'] = `
      query AjournalcodePageList ($organizer_id: String, $name: String, $title: String, $id: ID, $_qlType: String, $_filter: String, $_page: Int, $_template: String, $_process: String, $_orders: String) {
        pageajournalcodes  (organizer_id: $organizer_id, name: $name, title: $title, id: $id, _qlType: $_qlType, _filter: $_filter, _page: $_page, _template: $_template, _process: $_process, _orders: $_orders) {
            page {
              _page
              _totalrecords
              _totalpages
              _recordsperpage
            }
            nodes {
               ${strFragments.ajournalcode}
            }   
        }    
      }
      `;
export default strQls;