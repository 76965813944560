import { getInputValue } from "../../utils/commonutils";
const crudCode = {};

crudCode.onChangeInput = async (params) => {
  let log = false;
  if (log) console.log("onChangeInput params:", params);
  const result = {};
  let newStates = { disabledFields: {}, hiddenFields: {} };

  const {
    popup,
    tableCrud,
    inputFullName,
    line,
    action,
    parentField,
    event,
    newValue,
    previousValue,
    props,
    formProps,
  } = params;

  let _multidate;

  if (inputFullName === "_multidate") {
    _multidate = newValue;
  } else {
    _multidate = getInputValue(formProps, "_multidate");
  }
  if (_multidate !== "501") {
    newStates.disabledFields._multidate1 = true;
    newStates.disabledFields._multidate2 = true;
  }

  result.newStates = newStates;
  return result;
};

export default crudCode;
