import React from "react";
import { Field, reduxForm } from "redux-form";

import { graphql, compose } from "react-apollo";

import debounce from "lodash/debounce";
import { submit, change, touch, initialize } from "redux-form";

import { bindActionCreators } from "redux";
import { withRouter } from "react-router";

import defQls from "../../defQls";
import {
  processError,
  errorTranslate,
  checkLoadCrud,
  cleanFilters,
  getFilters,
  preSubmitValidation,
  submitValues,
  deleteRecord,
  uuid,
  getRecordFromOptionsIndirectOnFilter,
  resolveLink,
} from "../../utils/helper";

import IconLoading from "../icons/IconLoading";
import {
  appSubmitStart,
  appSubmitStop,
  appSettingsLoad,
} from "../../actions/appActions";
import { showConfirm, hideConfirm } from "../../actions/confirmActions";

import Portal from "../Portal";
import { fetchQl } from "../../apolloClient";
import EjobmailForm from "../ejobmail/EjobmailForm";

import FormField from "../FormField";
import crudCode from "../ejobmail/crudCode";
import { connect } from "react-redux";
import {
  processValues,
  resolvePathObj,
  getInputValue,
  sourcesOptions,
  getObjFromListById,
} from "../../utils/commonutils";
import { Tables } from "../../defTables";

import ShowIcon from "../icons/ShowIcon";
import { Button } from "../../layouts/cssstyled";

import strArgs from "../../defStrArgsQls";

const templates = [];

//const tableCrud = 'etemplate';
const tableCrud = "ejobmail";
const action = "add";
const nameForm = tableCrud + "Form";
const formFilterName = "listFilterejobmail";

class FormContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //always show, because component have an conditional in container // false,
      showModal: true, // value important to open and close the modal
      clicked: false, // to control click save one time
      // to redirect defaultValues from containerState  component caller. Eg: RegistrationView
      //  but id , must be a new
      defaultValues: { ...this.props.containerState.defaultValues, id: uuid() },
      // below values is copied from normal template 'add'
      actionsave: "add",
      actionsave_origen: "add",
      crudAction: "Add",
      crudTable: tableCrud,
      id: this.props.match.params.id || "",

      dialogOpened: {},
      parentid:
        this.props.match.params && this.props.match.params.parentid
          ? this.props.match.params.parentid
          : "",
      disabledFields: [],
      hiddenFields: [],
      warningFields: {},
      emails: {},
      loaded: false,
    };
  }

  componentDidMount() {
    setTimeout(
      function () {
        this.readEmails();
      }.bind(this),
      1000
    );
  }

  readEmails = async () => {
    const formProps = this.props;
    let vars = {};
    for (const [key, value] of Object.entries(
      formProps.crud_list_registration.variables
    )) {
      if (value) vars[key] = value;
    }

    if (Object.keys(vars).length > 0) {
      const strFragmentsRegistration = `
        id
        registrationcustomer_id  {
          email
        }
        `;

      // is gonne retrive mobile and phone customer
      let resRegistrations = await fetchQl(
        `
        query RegistrationList ${strArgs.registration} {
            ${strFragmentsRegistration} 
          }
        }`,
        vars,
        {
          noProcessParams: true,
          dataName: "registrations",
          props: formProps, // important object that contains myState , for pelemaster resolver org
        }
      );

      const customersWithEmail = {};
      let templateData = {};
      //console.log('resRegistrations', resRegistrations);

      for (let resRegistration of resRegistrations) {
        if (resRegistration.registrationcustomer_id.email) {
          const email = resRegistration.registrationcustomer_id.email;
          if (!customersWithEmail[email]) customersWithEmail[email] = "ok";
        }
      }

      this.setState({ emails: customersWithEmail, loaded: true });
    }
  };

  toolbarFunctions = (toolbar, params = {}) => {};

  hocdebounced = debounce((methodCode, params) => {
    this.executeCode(methodCode, params);
  }, 1500);

  closeModal = () => {
    // remove dialog name from the array list of dialog opened, in containter state
    // because need control state dialog, more one dialog can be opened at same time
    // lanunched from container,  don't support more one dialog same type
    this.props.toolbarFunctionsContainer("closeDialog", {
      dialogName: "MailCopyPaste",
    });
    // hidde modal
    this.setState({
      showModal: false,
    });
  };

  render() {
    const { showModal } = this.state;
    let emailsGroups = [];
    let emails = "";
    let counter = 0;
    let totalcounter = 0;
    let line = 1;
    for (const [key, value] of Object.entries(this.state.emails)) {
      totalcounter++;
      counter++;
      emails += (emails ? ", " : "") + key;

      if (
        counter === 50 ||
        totalcounter === Object.keys(this.state.emails).length
      ) {
        emailsGroups.push(
          <div key={"tit" + line}>
            {line + ") "}
            <a href={`mailto:${emails}`}> {counter + " emails"} @ </a>
          </div>
        );
        emailsGroups.push(<div key={line}>{emails}</div>);
        counter = 0;
        line++;
        emails = "";
      }
    }

    return (
      <div
        style={{ marginLeft: "30px", display: "flex", alignItems: "center" }}
      >
        <Portal
          scroll="1"
          header={this.props.t("menu.arroba")}
          close="1"
          backgroundColor="#EFEFEF"
          open={showModal}
          onClose={() => this.closeModal()}
          buttons={[
            { title: this.props.t("info.vClose"), action: this.closeModal },
          ]}
        >
          <div style={{ padding: "30px" }}>
            <div style={{ fontSize: "20px" }}>
              Emails: {this.state.loaded && totalcounter}
              {!this.state.loaded && <span>...</span>}
            </div>
            {emailsGroups}
          </div>
        </Portal>
      </div>
    );
  }
}
//

const withGraphqlandRouter = compose(
  graphql(defQls.tour.ListMini, {
    name: "list_tour",
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : "network-only";
      optionsValues.variables = { _qlType: "ListMini" };
      return optionsValues;
    },
  })
)(withRouter(FormContainer));

const mapStateToProps = (state) => {
  return {
    myState: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      appSubmitStart,
      appSubmitStop,
      showConfirm,
      initialize,
      change,
      touch,
      dispatch,
    },
    dispatch
  ); // to set this.props.dispatch
};

const withState = connect(
  mapStateToProps,
  mapDispatchToProps
)(withGraphqlandRouter);

const ComponentFull = withState;

export default ComponentFull;
