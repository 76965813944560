
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { withRouter } from 'react-router';
import {
  graphql,
  compose,
} from 'react-apollo';
import { bindActionCreators } from 'redux';

import PageHeader from '../PageHeader';
import { transMsg as t } from '../../utils/commonutils';
import defQls from '../../defQls';
import { processError } from '../../utils/helper';
import Form from './SettingsForm';
import { gqlActions } from '../../gqls/gqlactions';
import { appSettingsLoad } from '../../actions/appActions';

class Settings extends Component {

  constructor(props) {
    super(props);
  }

  handleSubmit = async (values) => {
   
    const newValues = {};

   
   

    const newSettings = {
      settingsforms: { registration_tip: values.registration_tip },
    };

   
    newValues.settingsforms = JSON.stringify({
      registration_tip: values.registration_tip,
      export_gender: values.export_gender,
      });
    newValues.accounting = JSON.stringify({
      orgAccountingName: values.orgAccountingName,
      orgBankId: values.orgBankId,
      orgBankAccountNumber: values.orgBankAccountNumber,
      inbank_paymentdocstatus_id: values.inbank_paymentdocstatus_id,
    });

   
    gqlActions('', 'setOrgSettings', this.props, newValues)
      .then( (response)=> {
        this.props.appSettingsLoad(newSettings);
      })
      .catch((err) => {
        processError(err, this.props, true);
      });
    
  };

  render() {
    const { error, pristine, invalid, reset, submitting, t } = this.props;

    let aElements = [];
    aElements.push(<PageHeader key="pageheader" icon ="settings" title = "menu.settings"  t={t}   />);

    aElements.push(
      <Form key="form" onSubmit={this.handleSubmit}
        containerPropsForm={this.props} />
    );

    return (
      <div>
        {aElements}
      </div>
    );
  }
}

const withRouterList = withRouter(Settings);
const withGraphqlandRouter = compose(

  graphql(defQls.setOrgSettings, {
    name: 'setOrgSettings',
    options: props => {
      const optionsValues = { variables: {id: props.match.params.id } };
      optionsValues.fetchPolicy = 'network-only';
      return optionsValues ;
    },
  }),
  graphql(defQls.getOrgSettings, {
    name: 'getOrgSettings',
    options: props => {
      const optionsValues = {  };
      optionsValues.fetchPolicy = 'network-only';
      let filters = {};
      
      if (props.myState.app.org) {
         filters['organizer_id'] = props.myState.app.org;
      } else {
         filters['organizer_id'] = props.myState.app.org;
      }
      optionsValues.variables = { ...filters };
      return optionsValues ;
    },
  }),
  graphql(defQls.paymentdocstatus.ListMini, {
    name: 'list_paymentdocstatus',
    options: props => {
      const optionsValues = {} ;
      optionsValues.fetchPolicy = 'network-only';
      optionsValues.variables = { _qlType: 'ListMini' };
      return optionsValues;
    },
  }),
)(withRouterList);


const mapStateToProps = (state, ownProps) => {
  return {
    myState: state,
  };
};


const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ appSettingsLoad, dispatch }, dispatch);
};

const ComponentFull = connect(
  mapStateToProps ,
  mapDispatchToProps,
)(withGraphqlandRouter);


export default ComponentFull;
