import React from "react";
import { Link } from "react-router-dom";
import ShowIcon from "../icons/ShowIcon";

import { Tables } from "../../defTables";

const CustomerHistoryHeader = (props) => {
  if (!props.view_customer || !props.view_customer.customer) {
    // if table have no realated then have no info
    return null;
  }
  let record = props.view_customer.customer;
  return (
    <div>
      <div>
        <div style={{ fontSize: "20px" }}>
          {props.t("table.customer")}: {record.name}
        </div>
        <Link className="aToolBarExtend" to={`/customer-view/${record.id}`}>
          <ShowIcon size="40" color="gray" icon="eye" />
        </Link>
      </div>
    </div>
  );
};

export default CustomerHistoryHeader;
