import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import "./index.css";
import "./i18n";
// import registerServiceWorker from './registerServiceWorker';
import { unregister } from "./registerServiceWorker";
unregister();
ReactDOM.render(<App />, document.getElementById("root"));
