import React from "react";
import iconlibrary1 from "./iconlibrary";

/*
  animatePath
    * = inserta animate en el svg para todos los paths
    0 o cualquier otro numero = inserta el animate en el # de path indicado

    animate Inside path, don't use this property like hardcode in svg global animate, in tue future convert like props and not hardcode, just login use special case de animate start on dom  when input get focis

       begin="DOMNodeInserted"

 */
const getConditionalProps = (mystring) =>
  /* let result=mystring.split(' ').reduce(function(acc,item,i){
    let keyAndValue=item.split('=');
    acc[keyAndValue[0]]=keyAndValue[1].replace(/\D/g,'');
    return acc;
  },{});
  return result; */

  Object.assign(
    {},
    ...mystring
      .split(" ")
      .map((p) => p.split("="))
      .map(([k, v]) => ({ [k]: +v.match(/\d+/) }))
  );
const ShowIcon = (props) => {
  const icon = props.icon || "";
  const color = props.color || "#000000";

  let viewBox = "0 0 24 24";
  if (props.viewBox) {
    // viewBox in props just send height and width like one value
    //viewBox = '0 0 ' + props.viewBox + ' ' + props.viewBox;
    viewBox = props.viewBox;
  }

  const animateFrom = props.animateFrom || "90 0 0";
  const animateTo = props.animateTo || "0 0 0";
  const animateDur = props.animateDur || ".5s";
  const animateRepeatCount = props.animateRepeatCount || "1";

  const pathfill = props.pathfill || "";

  const pathopacity = props.opacity || "";
  const pathfills = pathfill.split("::");
  const pathopacities = pathopacity.split("::");

  if (icon !== "" && iconlibrary1[props.icon]) {
    const iconElement = iconlibrary1[props.icon];
    if (iconlibrary1[props.icon].viewBox)
      viewBox = iconlibrary1[props.icon].viewBox;
    let style = {};
    if (props.verticalAlign) style.verticalAlign = props.verticalAlign;
    const size = props.size || iconlibrary1[props.icon].size || "24";
    const height = props.height || size;
    const width = props.width || size;
    const fillrule = iconElement.fillrule || "nonzero"; // only support for global fillrule , not for path
    return (
      <svg
        style={style}
        width={width}
        height={height}
        viewBox={viewBox}
        fill={color}
      >
        {iconElement.circle && (
          <circle {...getConditionalProps(iconElement.circle[0])} />
        )}
        {iconElement.path.map((path, index) => (
          <path
            key={index}
            fillRule={fillrule}
            d={path}
            fill={index + 1 <= pathfills.length ? pathfills[index] : ""}
            opacity={
              index + 1 <= pathopacities.length ? pathopacities[index] : ""
            }
          >
            {props.animatePath === String(index) && (
              <animateTransform
                attributeType="xml"
                additive="sum"
                attributeName="transform"
                type="rotate"
                from={animateFrom}
                to={animateTo}
                dur={animateDur}
                repeatCount={animateRepeatCount}
              />
            )}
          </path>
        ))}

        {props.animatePath === "*" && (
          <animateTransform
            attributeType="xml"
            attributeName="transform"
            type="rotate"
            additive="sum"
            begin="DOMNodeInserted"
            from={animateFrom}
            to={animateTo}
            dur={animateDur}
            repeatCount={animateRepeatCount}
          />
        )}
      </svg>
    );
  }
  return null;
};
export default ShowIcon;
