// www.mobilsem.com  - Copyright ../src/layouts/LayoutPage.js
import React, { Component } from "react";
import { withRouter } from "react-router";
import { Menu, MenuItem } from "./menu";
import { ISOtoDate } from "../utils/commonutils";
import LogoPeleAdmin from "../components/icons/logopele-bgdark";
import Notif from "../components/Notif";
import { getSession } from "../utils/auth";
import ShowIcon from "../components/icons/ShowIcon";
import { Button } from "../layouts/cssstyled";

const LayoutPopup = ({
  component: DisplayComponent,
  t,
  changeLanguage,
  ...props
}) => {
  const localPreHeaderStyle = {
    background: props.theme.PrimaryDark,
  };
  const localHeaderStyle = {
    background: props.theme.Primary,
    color: props.theme.SecondaryText,
    textAlign: "center",
  };
  const localFooterStyle = {
    background: props.theme.Background,
    color: props.theme.TextOnBackgroundLight,
    padding: "10px",
    textAlign: "right",
    height: "30px",
  };
  const localMenuStyle = {
    background: props.theme.Background,
  };
  const localTitleStyle = {};
  //console.log( 'window.opener',  window.opener)

  const pagePrivate = !props.public;

  let localAppContentStyle = "";
  if (pagePrivate) {
    localAppContentStyle = { textAlign: "left", paddingTop: "20px" };
  } else {
    localAppContentStyle = { textAlign: "center", paddingTop: "50px" };
  }
  ////console.log('Layout Component props');
  ////console.log(props);
  /* moved to CrudToolBar.js
      <div style={{ position: 'relative', margin: 'auto' }}>
        <div className="iconInput"> <ShowIcon icon="close" color="#FFFFFF" /></div>
        <Button  style={{ paddingLeft: '35px' }}  onClick={ ()=> closeWindow() } >
          {t('info.popupCloseNoSave')}
        </Button>
      </div>
    // add and select is automatic; old message:
      form.popupClose: `Après avoir enregistré, saisir la nouvelle fiche.`,

    <div key ="warn" style = {{ display: 'flex', flexDirection: 'row', fontSize:'24px', color:'#999999', padding:'15px', textAlign: 'center' }}>
        <ShowIcon  key="icon" icon="info" size="25" color="orange" style = {{ marginTop: '5px'}} />
        <div key="msg">{t('info.popupClose')}</div>
      </div>

*/
  return (
    <div className="appContainerColumnDirection">
      <div key="topbar" className="preHeaderStyle" style={localPreHeaderStyle}>
        {props.nameApp}
        <strong>
          {process.env.REACT_APP_VERSION} (
          {ISOtoDate(process.env.REACT_APP_DATE, "D MMMh:m a")})
        </strong>
      </div>
      <div key="container" className="appContainerRowDirection">
        <div className="appContent" style={localAppContentStyle}>
          <DisplayComponent t={t} {...props} />
        </div>
      </div>
      {props.notifications.length > 0 && (
        <div key="notif" className="appHeaderNotif">
          {props.notifications.map((notification) => {
            return (
              <Notif
                hideNotification={props.hideNotification}
                key={notification.id}
                notification={notification}
              />
            );
          })}
        </div>
      )}

      {/* <div
        className="appFooter"
        style={localFooterStyle}
      >
        <button onClick={() => changeLanguage('fr')}>fr</button>
        <button onClick={() => changeLanguage('fr-PELE')}>Pele</button>
        <button onClick={() => changeLanguage('en')}>en</button>

        PeleAdmin 2017
      </div> */}
    </div>
  );
};

//export default withRouter(LayoutComponent);
export default LayoutPopup;
