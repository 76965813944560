/*
WARNING:
  formProps is artificial when is launched from containerForm, in the case initForm or manual change
    from container.
    this send his props himself and not containerForm
  so don't use formProps to use special props, form myState and tt is ok, container and Form have these same props
  getInputValue use myState, myState is present in container so is ok to use it,
    but send always the name of form { nameForm }, because if formProps is artificil will fail
  use tt , for translate, have dependency with props
 */

import {
  toPrice,
  getInputValue,
  getToday,
  resolvePathObj,
  tryParseJSON,
  getObjFromListById,
  realTypeOf,
  tt,
  assemblyId,
  disassemblyId,
  getPriceService,
} from "../../utils/commonutils";
import { getRecordFromOptionsIndirectOnFilter } from "../../utils/helper";
import { fetchQl } from "../../apolloClient";
import { arrayPush } from "redux-form";

const crudCode = {};

const getObjects = (inputFullName, _services, formProps, nameForm) => {
  const position = inputFullName.indexOf("__");
  // search on _services the id
  const idService = inputFullName.substr(position + 2);
  let serviceFound;
  _services.map((service, index) => {
    if (service.idOnly === idService) {
      serviceFound = service;
    }
  });
  if (serviceFound) {
    return { idService };
  } else {
    console.log("error not found service", idService);
    return { idService: undefined };
  }
};

crudCode.onChangeInput = async (params) => {
  //const log = true;
  let log = false;
  if (log) console.log("onChangeInput params:", params);
  const {
    nameForm,
    popup,
    tableCrud,
    inputFullName,
    line,
    action,
    parentField,
    event,
    newValue,
    previousValue,
    props,
    formProps,
    fns,
    rowsObjInmutable,
  } = params;

  /*
   popup denote that value is sent through window, and is here not because there is change, but
   must execute change manually and follow the same logic that onChange
   */
  if (popup) {
    if (inputFullName === "customer_id") {
      formProps.change(inputFullName, newValue);
    }
  }

  // dont add hiddenFields, the logic ist just update when is initForm or on change affected fields
  let newStates = { disabledFields: {}, warningFields: {} };

  const result = {};

  let customer_id;
  let tourroom_id;
  let tour_id;
  let registrationstatus_id;
  let registationStatusRecord;

  let realTourId;

  if (action === "initForm" || inputFullName !== "tour_id") {
    tour_id = getInputValue(formProps, "tour_id", { nameForm });
    realTourId = tour_id && tour_id.id ? tour_id.id : tour_id;
  }

  let customerStatus;
  let customerSubStatus;
  let realcustomerStatusId;
  let realcustomerSubStatusId;
  if (action === "initForm" || inputFullName !== "customerstatus_id") {
    customerStatus = getInputValue(formProps, "customerstatus_id", {
      nameForm,
    });
    realcustomerStatusId =
      customerStatus && customerStatus.id ? customerStatus.id : customerStatus;
  }
  if (action === "initForm" || inputFullName !== "customersubstatus_id") {
    customerSubStatus = getInputValue(formProps, "customersubstatus_id", {
      nameForm,
    });
    realcustomerSubStatusId =
      customerSubStatus && customerSubStatus.id
        ? customerSubStatus.id
        : customerSubStatus;
  }

  if (inputFullName === "customer_id") {
    //customer_id = newValue;
    //console.log('newValue/ event', newValue, event);
    // it's better take event, because newValue is only: id (string) we need { id, name, customerstatus_id}
    // event is forward from autocomplete from native event js
    customer_id = event;
    // make sure that customer_id is not undefined, when select is cleaned with 'x' can happen
    if (customer_id && customer_id.id) {
      if (!realcustomerStatusId) {
        // only if input has no previous value
        if (customer_id.customerstatus_id) {
          formProps.change("customerstatus_id", customer_id.customerstatus_id);
        }

        formProps.change(
          "customersubstatus_id",
          customer_id.customersubstatus_id
        );
      }

      // customerStatusRecord = getRecordFromOptionsIndirectOnFilter(formProps, 'customerstatus', customer_id.customerstatus_id);
      // console.log('customerStatusRecord', customerStatusRecord);
    }
  } else {
    customer_id = getInputValue(formProps, "customer_id", {
      nameForm,
      log: log,
    });
    if (log)
      console.log("crudCode.js() customer_id from getInputValue:", customer_id);
  }

  if (inputFullName === "tourroom_id") {
    tourroom_id = newValue;
  } else {
    tourroom_id = getInputValue(formProps, "tourroom_id", { nameForm });
  }

  if (inputFullName === "registrationstatus_id") {
    registrationstatus_id = newValue;
  } else {
    registrationstatus_id = getInputValue(formProps, "registrationstatus_id", {
      nameForm,
    });
  }

  if (registrationstatus_id) {
    registationStatusRecord = getRecordFromOptionsIndirectOnFilter(
      formProps,
      "registrationstatus",
      registrationstatus_id
    );
  }

  // fill registration date to today, when registrationstatus is registration confirmed
  // future, restore accord initvalues on edition , if registrationstatus change to state no registrated
  if (inputFullName === "registrationstatus_id" && registationStatusRecord) {
    const dateregistration = getInputValue(formProps, "dateregistration", {
      nameForm,
    });
    if (registationStatusRecord.statusbase === 4) {
      const registrationstatusInitial = getInputValue(
        formProps,
        "registrationstatus_id",
        { nameForm, initial: true }
      );
      let registationStatusRecordInitial;
      if (registrationstatusInitial) {
        registationStatusRecordInitial = getRecordFromOptionsIndirectOnFilter(
          formProps,
          "registrationstatus",
          registrationstatusInitial
        );
      } else {
        registationStatusRecordInitial = null;
      }
      // if status was different to 4, or date is emtpy always change to today
      if (
        !dateregistration ||
        !registationStatusRecordInitial ||
        registationStatusRecordInitial.statusbase !== 4
      ) {
        formProps.change("dateregistration", getToday());
      }
    } else {
      const dateregistrationInitial = getInputValue(
        formProps,
        "dateregistration",
        { nameForm, initial: true }
      );
      // very confuse to user, to force to restore date if he is playing wiht date manually
      //if (!dateregistration && dateregistrationInitial) {
      //  formProps.change('dateregistration', dateregistrationInitial);
      //}
    }
  }

  if (inputFullName === "tour_id" || action === "initForm") {
    if (action !== "initForm") {
      tour_id = newValue;

      realTourId = tour_id && tour_id.id ? tour_id.id : tour_id;
      let tour_idOld = getInputValue(formProps, "tour_id", { nameForm });
      if (tour_idOld && tour_idOld.id) tour_idOld = tour_idOld.id;

      if (realTourId !== tour_idOld) {
        tourroom_id = null;
        // suspended: need time out to don't break cycle redux changements
        // it's seems work know, maybe in dev mode was not loaded changes functions to async
        formProps.change("tourroom_id", null);
        formProps.change("transportation", null);
        formProps.change("objects", null);
      }
    }
    // default case tour is empty
    let resTourCustomerStatusAndServices = [];
    let resTourRooms = [];
    let resTourServices = [];

    if (realTourId) {
      resTourCustomerStatusAndServices = await fetchQl(
        `
        query getTourCustomerStatusAndServices($tour_id: ID, , $_qlType: String) {
          getTourCustomerStatusAndServices(tour_id: $tour_id,  _qlType: $_qlType) {
            id
            name
            nameOnly
            tourprice_id
            tourprice_price
            substatuss {
              id
              name
              price
              inputprice
              availablehotels {
                id
                name
                priceroom
              }
              availableservices {
                id
                idOnly
                name
                nameOnly
                typeEntry
                tour_id
                operator
                amount
                listOptions {
                  id
                  name
                  amount
                }
                cancellednotzero
                service_id
              }
            }
            filterbyservice
            filteredservices
            availablehotels {
              id
              name
              priceroom
            }
            availableservices {
              id
              idOnly
              name
              nameOnly
              typeEntry
              tour_id
              operator
              amount
              listOptions {
                id
                name
                amount
              }
              cancellednotzero
              service_id
            }
          }
        }
      `,
        [
          { name: "tour_id", type: "ID", value: realTourId },
          { name: "_qlType", type: "String", value: "availability" },
        ],
        {
          dataName: "getTourCustomerStatusAndServices",
          props: formProps, // important object that contains myState , for pelemaster resolver org
        }
      );

      resTourRooms = await fetchQl(
        `
      query TourroomList(
        $tour_id: String
        $_qlType: String
        $_orders: String
      ) {
        tourrooms(
          tour_id: $tour_id
          _qlType: $_qlType
          _orders: $_orders
        ) {
          id
          hotel_id
          room_type
          name
          tour_id
          isfullname
          people_available
          priceroom
          tourroomhotel_id {
            name
          }
        }
      }`,
        [
          { name: "tour_id", type: "ID", value: tour_id },
          { name: "_qlType", type: "ID", value: "ListMini" },
          { name: "_orders", type: "String", value: "hotel_id" },
        ],
        {
          dataName: "tourrooms",
          props: formProps, // important object that contains myState , for pelemaster resolver org
        }
      );

      resTourServices = await fetchQl(
        `
        query getTourServices ($tour_id: ID) {
          getTourServices (tour_id: $tour_id) {
            id
            idOnly
            name
            nameOnly
            typeEntry
            tour_id
            operator
            amount
            listOptions {
              id
              name
              amount
            }
            cancellednotzero
            service_id
          }
        }`,
        [{ name: "tour_id", type: "ID", value: tour_id }],
        {
          dataName: "getTourServices",
          props: formProps, // important object that contains myState , for pelemaster resolver org
        }
      );
    }

    // search record for customer status
    newStates._allcustomerStatuss =
      formProps.containerPropsForm.list_customerstatus.customerstatuss;

    // _customerStatuss is important for selectbox status
    // and _resTourCustomerStatusAndServices is used to calculate prices
    //          and load services & hotels
    newStates._customerStatuss = resTourCustomerStatusAndServices;

    if (resTourCustomerStatusAndServices.length === 0) {
      newStates._customerStatuss =
        formProps.containerPropsForm.list_customerstatus.customerstatuss;
    }
    newStates._resTourCustomerStatusAndServices =
      resTourCustomerStatusAndServices;

    newStates._allservices = resTourServices;
    newStates._alltourRooms = resTourRooms;
    newStates._services = resTourServices;
    newStates._tourRooms = resTourRooms;
    if (realcustomerStatusId) {
      // when there is real customer load from resTourCustomerStatusAndServices
      const resCustomerStatusFound = resTourCustomerStatusAndServices.find(
        (resTourStat) => resTourStat.id === realcustomerStatusId
      );
      if (resCustomerStatusFound) {
        // by default no substatus
        newStates._services = resCustomerStatusFound.availableservices;
        newStates._tourRooms = resCustomerStatusFound.availablehotels;
        newStates._customerSubStatuss = resCustomerStatusFound.substatuss;
        if (resCustomerStatusFound) {
          // TWIN ST-1931
          // search for substatus
          const resCustomerSubStatusFound =
            resCustomerStatusFound.substatuss.find(
              (sub) => realcustomerSubStatusId === sub.id
            );

          if (
            resCustomerSubStatusFound &&
            resCustomerSubStatusFound.availablehotels.length > 0
          ) {
            newStates._tourRooms = resCustomerSubStatusFound.availablehotels;
          }
          if (
            resCustomerSubStatusFound &&
            resCustomerSubStatusFound.availableservices.length > 0
          ) {
            newStates._services = resCustomerSubStatusFound.availableservices;
          }
        }
      }
    }
  }
  if (inputFullName === "customersubstatus_id") {
    customerSubStatus = newValue;
    realcustomerSubStatusId =
      customerSubStatus && customerSubStatus.id
        ? customerSubStatus.id
        : customerSubStatus;

    // twin BK141
    // by default store all services, but if customer status is valid
    // reemplace with those values
    // newStates._services = formProps.formState._allservices;
    newStates._tourRooms = formProps.formState._alltourRooms;
    if (realcustomerSubStatusId) {
      // read direct from state, because was already loaded on the initform or change tour_id
      // only _resTourCustomerStatusAndServices has availableservices,availableservices

      const resCustomerStatusFound =
        formProps.formState._resTourCustomerStatusAndServices.find(
          (resTourStat) => resTourStat.id === realcustomerStatusId
        );
      if (resCustomerStatusFound) {
        // TWIN ST-1931
        // search for substatus
        const resCustomerSubStatusFound =
          resCustomerStatusFound.substatuss.find(
            (sub) => realcustomerSubStatusId === sub.id
          );
        if (
          resCustomerSubStatusFound &&
          resCustomerSubStatusFound.availablehotels.length > 0
        ) {
          newStates._tourRooms = resCustomerSubStatusFound.availablehotels;
        }
        if (
          resCustomerSubStatusFound &&
          resCustomerSubStatusFound.availableservices.length > 0
        ) {
          newStates._services = resCustomerSubStatusFound.availableservices;
        }
      }
      // console.log("resFound", resFound);
    }
  }
  if (inputFullName === "customerstatus_id") {
    customerStatus = newValue;
    realcustomerStatusId =
      customerStatus && customerStatus.id ? customerStatus.id : customerStatus;

    let oldcustomerStatus = getInputValue(formProps, "customerstatus_id");
    if (oldcustomerStatus && oldcustomerStatus.id)
      oldcustomerStatus = oldcustomerStatus.id;

    // twin BK141
    // by default store all services, but if customer status is valid
    // reemplace with those values
    newStates._services = formProps.formState._allservices;
    newStates._tourRooms = formProps.formState._alltourRooms;

    if (realcustomerStatusId) {
      // read direct from state, because was already loaded on the initform or change tour_id
      // only _resTourCustomerStatusAndServices has availableservices,availableservices

      const resCustomerStatusFound =
        formProps.formState._resTourCustomerStatusAndServices.find(
          (resTourStat) => resTourStat.id === realcustomerStatusId
        );

      if (resCustomerStatusFound) {
        newStates._customerSubStatuss = resCustomerStatusFound.substatuss;
      }
      // check value for substatus found, pareil en initform section code
      if (
        resCustomerStatusFound &&
        resCustomerStatusFound.availableservices.length > 0
      ) {
        newStates._services = resCustomerStatusFound.availableservices;
      }
      if (
        resCustomerStatusFound &&
        resCustomerStatusFound.availablehotels.length > 0
      ) {
        newStates._tourRooms = resCustomerStatusFound.availablehotels;
      }
      // console.log("resFound", resFound);
    }
    if (oldcustomerStatus !== realcustomerStatusId) {
      // import reset value tourroom when change status
      // after see if available tourroom %%% belongs same group status
      // scan old tourroom before reset:
      const existInNewTourRoomList = newStates._tourRooms.find(
        (tr) => tr.id === tourroom_id
      );

      if (!existInNewTourRoomList) {
        formProps.change("tourroom_id", null);
      }

      // reset old inputs
      if (formProps.formState._services) {
        formProps.formState._services.map((service) => {
          const existInNewServiceList = newStates._services.find(
            (sr) => sr.id === service.idOnly
          );
          if (!existInNewServiceList) {
            formProps.change(`objectsInput__${service.idOnly}`, null);
            formProps.change(`objectsq__${service.idOnly}`, null);
          }
        });
      }
      formProps.change("objects", null);
    }
  }

  if (action === "initForm" || inputFullName === "transportationwagon") {
    const fullTransportation = getInputValue(formProps, "transportation", {
      nameForm,
    });
    if (fullTransportation) {
      for (let a = 0; a < fullTransportation.length; a++) {
        let listOptions = [];
        const transportationLine = fullTransportation[a];
        if (fullTransportation[a].wagon) {
          /*const wagon = getObjFromListById(
              formProps.containerPropsForm.getTourWagons.getTourWagons, 
              { tour_id: tour_id, transportation_id: transportationLine.transportation_id.id});*/
          const wagon = getObjFromListById(
            formProps.containerPropsForm.getTourWagons.getTourWagons,
            inputFullName === "transportationwagon" && line === a
              ? newValue
              : fullTransportation[a].wagon.id
          );

          if (wagon.seatingrange) {
            let listOptions = [];
            const seatingrange = JSON.parse(wagon.seatingrange);
            for (let b = 0; b < seatingrange.length; b++) {
              if (seatingrange[b].seats) {
                // TWIN DLENGTH-3  // -4 because add a blank space in hidden field
                const seats = seatingrange[b].seats
                  .substr(2, seatingrange[b].seats.length - 4)
                  .split(",");
                seats.map((seat) => {
                  listOptions.push({ id: seat, name: seat });
                });
              }
            }
            if (!newStates.listOptions) newStates.listOptions = {};
            newStates.listOptions[`transportationseat${a}`] = listOptions;
            // console.log('sealistOptionsts', listOptions);
          }
          // listOptions
        }
      }
    }
  }
  // variables to resolve price by registation status TWIN Server CST119
  let hasRegistrationStatusCanceledPrice = false;
  let registrationStatusPrice = 0; // on the future, price will come not only from cancelled status
  //  prix room is not added when inscription is cancelled
  let isCancelled = false;

  // here price is resolved for  onchange on some inputs: tollfree, registration status
  let resolvedMethodPrice = [];
  // now price is always resolved, same with simple initform on edit registration

  // initForm dispatched on form creation, have no .fns, so logic can't be executed

  let tollfree;
  if (inputFullName === "tollfree") {
    tollfree = newValue || false;
  } else {
    tollfree = getInputValue(formProps, "tollfree", { nameForm });
  }
  /* getRecordValues reemplaced, because autocomplet does not change anymore the value before is sent
      it's newValue the real value when the same field is changed, so, only getRecordFromOptionsIndirectOnFilter
      can give us the value sending the parameter value, because getRecordValues, get it from redux, and
      it's not changed yet at this moment
    //const tourRecord = getRecordValues(formProps ,'tour_id', 'tour',log);
    */
  const tourRecord = getRecordFromOptionsIndirectOnFilter(formProps, "tour", {
    id: tour_id,
  });

  /*
    cancelled, customer ql is not loaded in props, it's async
    if (inputFullName === 'customer_id') {
      const customerRecord = getRecordValues(formProps ,'customer_id', 'customer', log);
      if (log) console.log('customerRecord',customerRecord );
      if (customerRecord.id )  formProps.change('customerstatus_id', customerRecord.customerstatus_id );
    }*/

  let price;

  //console.log('tollfree',tollfree);
  if (tollfree) {
    // checkbox  valiue is priority to know if is toll free
    price = "0";
  } else if (!tourRecord) {
    // tourNotFound ( imposible case, but anyway I control) or tour has no price defined, then no price
    // if there are customerstatus_id, without tour price, by the moment price can't be defined
    if (log) console.log(" tour Record not found", tourRecord);
    price = "0";
  } else {
    if (registrationstatus_id) {
      if (registationStatusRecord) {
        if (parseInt(registationStatusRecord.statusbase) === 14) {
          isCancelled = true;
          if (log)
            console.log(
              "typeof tourRecord.cancelledprice",
              realTypeOf(tourRecord.cancelledprice)
            );
        }
        if (realTypeOf(tourRecord.cancelledprice) === "[object Number]") {
          // has price
          hasRegistrationStatusCanceledPrice = true;
          registrationStatusPrice = tourRecord.cancelledprice;
        }
      }
    }
    if (log)
      console.log(
        " customerStatus from getValue input customerstatus_id:",
        customerStatus
      );

    let _resTourCustomerStatusAndServices = resolvePathObj(
      formProps,
      "formState._resTourCustomerStatusAndServices"
    );
    if (!_resTourCustomerStatusAndServices) {
      _resTourCustomerStatusAndServices =
        newStates._resTourCustomerStatusAndServices;
    }
    if (!_resTourCustomerStatusAndServices) {
      _resTourCustomerStatusAndServices = [];
    }

    if (isCancelled && hasRegistrationStatusCanceledPrice) {
      price = registrationStatusPrice;
      resolvedMethodPrice.push({
        source:
          tt(formProps.t, "table.registrationstatus") +
          ": " +
          tt(formProps.t, "registrationBaseStatus.cancelled"),
        price,
      });
    } else if (!customerStatus) {
      // not customer status defined, then price come from 'tour'
      price = tourRecord.price;
      resolvedMethodPrice.push({
        source: tt(formProps.t, "table.tour"),
        price,
      });
    } else {
      if (_resTourCustomerStatusAndServices) {
        // it need repeat logic resCustomerStatusFound, befor for general initform, and now for price
        const resCustomerStatusFound = _resTourCustomerStatusAndServices.find(
          (resTourStat) => resTourStat.id === realcustomerStatusId
        );
        if (resCustomerStatusFound) {
          newStates._customerSubStatuss = resCustomerStatusFound.substatuss;
        }

        //------ analyze substatus
        let subStatusFound;
        // if form is loading, content is string, not object from select
        if (realcustomerSubStatusId && resCustomerStatusFound) {
          subStatusFound = resCustomerStatusFound.substatuss.find(
            (subs) => subs.id === realcustomerSubStatusId
          );
        }
        //------ end analyze substatus

        if (
          resCustomerStatusFound &&
          (resCustomerStatusFound.tourprice_price ||
            resCustomerStatusFound.tourprice_price === 0 ||
            (subStatusFound && subStatusFound.inputprice))
        ) {
          // important check if is cero or not
          //console.log('take price from  , price customer status:',tourpriceRecord.price)
          price = resCustomerStatusFound.tourprice_price;
          // check if substatus has price
          if (realcustomerSubStatusId && subStatusFound) {
            price = subStatusFound.price;
          }
          resolvedMethodPrice.push({
            source: tt(formProps.t, "table.customerstatus"),
            price,
          });
        } else {
          price = tourRecord.price;
          resolvedMethodPrice.push({
            source: tt(formProps.t, "table.tour"),
            price,
          });
        }
      }
      if (log) console.log(" price after tourprice = ", price);
    }

    // TWIN Server PRO032, for cancelled dont add room price
    if (!tollfree && !isCancelled) {
      // search on loaded tourrooms or recent loading tourooms (future states)
      let availablehotels =
        newStates._tourRooms || formProps.formState._tourRooms;
      let tourroomRecord = availablehotels.find(
        (ahotel) => ahotel.id === tourroom_id
      );

      if (tourroomRecord && tourroomRecord.priceroom) {
        // important check if is cero or not
        if (log)
          console.log("add price from  , tourroom:", tourroomRecord.priceroom);
        price += tourroomRecord.priceroom;
        resolvedMethodPrice.push({
          source: tt(formProps.t, "table.tourroom"),
          price: tourroomRecord.priceroom,
        });
      }
    }
  }

  let totalServices = 0;

  /*
    START calc services
     */

  // guess the name form automatically, the event is produced on the same input

  let _services = resolvePathObj(formProps, "formState._services");
  if (!_services) {
    _services = newStates._services;
  }
  if (!_services) {
    _services = [];
  }

  // get the objects from input, before process onchange input service, so on the change part bbelow
  // can modify the variable objects, and do the calcs, because input objects is not on the fly updated
  // take one boucle render  more
  let objects = tryParseJSON(
    getInputValue(formProps, "objects", { nameForm }),
    {}
  );

  if (inputFullName && inputFullName.substr(0, 10) === "objectsq__") {
    // completar
    const { idService } = getObjects(
      inputFullName,
      _services,
      formProps,
      nameForm
    );
    if (objects) {
      const realNewValue = parseFloat(newValue || 0);
      if (!realNewValue) {
        // for checkbox or selectbox, false or blacn must delete from array
        delete objects[idService];
        formProps.change("objectsInput__" + idService, false);
      } else {
        // is checked or list selected
        // console.log("set object", { value: true, q: realNewValue });
        objects[idService] = { value: true, q: realNewValue }; // by default create service with qty = 1
        formProps.change("objectsInput__" + idService, true);
      }

      formProps.change("objects", objects);
      /*console.log(
          "set new value for id",
          idService,
          "value",
          newValue,
          "final objects",
          objects
        );*/
    }
  }

  if (inputFullName && inputFullName.substr(0, 14) === "objectsInput__") {
    // search on _services the id

    const { idService } = getObjects(
      inputFullName,
      _services,
      formProps,
      nameForm
    );
    if (objects) {
      if (!newValue) {
        // for checkbox or selectbox, false or blacn must delete from array
        delete objects[idService];
        formProps.change("objectsq__" + idService, "");
      } else {
        // is checked or list selected
        objects[idService] = { value: newValue, q: 1 }; // by default create service with qty = 1
        formProps.change("objectsq__" + idService, 1);
      }

      formProps.change("objects", objects);
      /*console.log(
          "set new value for id",
          idService,
          "value",
          newValue,
          "final objects",
          objects
        );*/
    }
  }

  for (let [key, object] of Object.entries(objects)) {
    let servicesObj = _services.find((service) => service.idOnly === key);

    if (log)
      console.log(
        "services line  RESULT # " + key + ")  servicesObj:",
        servicesObj
      );
    // TWIN Server SC019
    if (
      servicesObj &&
      !(
        isCancelled &&
        hasRegistrationStatusCanceledPrice &&
        !servicesObj.cancellednotzero
      )
    ) {
      // console.log("object", object, "servicesObj", servicesObj);
      let serviceAmount = getPriceService(object, servicesObj);

      /*console.log(
          "object",
          object,

          "serviceAmount",
          serviceAmount
        );*/
      resolvedMethodPrice.push({
        source: tt(formProps.t, "table.service") + ": " + servicesObj.nameOnly,
        price: serviceAmount,
      });
      totalServices += serviceAmount;
      if (log)
        console.log(
          "services to count :" +
            servicesObj.services_id +
            " amount:" +
            servicesObj.amount,
          " totalServices",
          totalServices
        );
    }
  }

  /*
    END calc services
     */

  /*
    Check changes on transport:
     */

  if (inputFullName === "transportationtransportation_id") {
    let changeRow = false;

    let resetStopPoint = true;
    const fullTransportation = getInputValue(formProps, "transportation", {
      nameForm,
    });
    let currentTransportationLine = getInputValue(formProps, "transportation", {
      nameForm,
      line,
      notFound: undefined,
      log: false,
    });

    // console.log('newValue', newValue);
    // console.log('currentTransportationLine ', currentTransportationLine);

    // important this block avant the block study the changemente transport id, to keep the same stoppoint,
    // because the way to change the stoppoint is updating 'currentTransportationLine'  and not 'resetStopPoint'
    currentTransportationLine = {
      ...currentTransportationLine,
      transportation_id: newValue,
    };
    /*if (resetStopPoint && resolvePathObj(formProps, 'myState.app.appSettings.settingsforms.registration_stopspointfree', { compare: true, notFound: false})) {
        // for free stop point dont reset
        resetStopPoint = false;
      }*/

    if (!newValue) {
      // if idNew is null, then clean related field
      currentTransportationLine.stopspoint_id = null;
      changeRow = true;
    }

    /* need a lot of search to get the real stop id from the table records loaded, because the main ids are only representative for every record
      newValue  is just the real id, but after is selected, the id is generated aleatory, the newValue is part of .transportation_id.id
      the way to change the stoppoint is updating 'currentTransportationLine'  and not 'resetStopPoint'
      */
    if (
      newValue &&
      newValue.id &&
      previousValue &&
      previousValue.id &&
      currentTransportationLine
    ) {
      // console.log('previousValue', previousValue);
      // dont use: currentTransportationLine = getInputValue(... / but previousValue, in select autocomplete, all the value is changed
      // there is not way to get the old value using getInputValue, always will be the current value updated
      // use previousValue
      const oldTransportationId = previousValue.id;
      const oldStopPoint =
        currentTransportationLine.stopspoint_id &&
        currentTransportationLine.stopspoint_id
          ? currentTransportationLine.stopspoint_id.id
          : null;
      // console.log('oldTransportationId ', oldTransportationId );
      // console.log('oldStopPoint ', oldStopPoint );
      const newTransportationId = newValue.id;
      // console.log('newTransportationId', newTransportationId);
      let oldRecordTransport = getRecordFromOptionsIndirectOnFilter(
        formProps,
        "transportation",
        { id: oldTransportationId },
        true,
        log
      );
      // console.log('oldRecordTransport', oldRecordTransport);
      let newRecordTransport = getRecordFromOptionsIndirectOnFilter(
        formProps,
        "transportation",
        { id: newTransportationId },
        true,
        log
      );
      // console.log('newRecordTransport', newRecordTransport);

      if (
        oldStopPoint &&
        oldRecordTransport &&
        oldRecordTransport.routeplanner &&
        newRecordTransport &&
        newRecordTransport.routeplanner
      ) {
        const newRouterPlanner = JSON.parse(newRecordTransport.routeplanner);
        const oldRouterPlanner = JSON.parse(oldRecordTransport.routeplanner);
        // console.log('newRouterPlanner', newRouterPlanner);
        // console.log('oldRouterPlanner', oldRouterPlanner);
        // search if old stop id is foound in the routerplanner for the new transportid selected
        let objRealOldStop = getObjFromListById(oldRouterPlanner, {
          id: oldStopPoint,
        });
        // console.log('objRealOldStop', objRealOldStop);
        if (objRealOldStop && objRealOldStop.from_stopspoint_id) {
          const realOldStopId = objRealOldStop.from_stopspoint_id.id;
          // console.log('realOldStopId ', realOldStopId );
          // search realOldStopId in New route planner
          for (const [key, value] of Object.entries(newRouterPlanner)) {
            // console.log('value', value.from_stopspoint_id);
            // important de hacer ensemble transportid and stopid, to let a old transport, danger don't find stopid
            if (value.from_stopspoint_id.id === realOldStopId) {
              // console.log('value *************',value);
              // dont use 'from_stopspoint_id.id' because is from table 'stopspoints'.id, but the id from the multiline record  table "tourid" field: transports {id }
              currentTransportationLine.stopspoint_id = {
                id: value.id,
                name: value.from_stopspoint_id.name,
              }; // {...value};
              // console.log('value ************* newcurrentTransportationLine', currentTransportationLine);
              resetStopPoint = false;
              break;
            }
          }
        }
      }
    }

    if (resetStopPoint) {
      currentTransportationLine.stopspoint_id = null;
    }
    //console.log('currentAddressLine', currentAddressLine);
    fullTransportation[line] = currentTransportationLine;
    changeRow = true;
    if (changeRow) {
      result.changeFieldsLater = { transportation: fullTransportation }; // best modern way to change fields, it's more sure
      // formProps.change('transportation', fullTransportation);
    }
  }

  // transport is not take when has registration cancelled, but when has canceled priced
  // to keep compatibility with old registrations , take in count price transport for cancelled
  if (!(isCancelled && hasRegistrationStatusCanceledPrice)) {
    /*
      START calc transport (add to variables services
       */

    // guess the name form automatically, the event is produced on the same input
    const transportationsValue = getInputValue(formProps, "transportation", {
      nameForm,
    });
    if (log)
      console.log("crudCode() transportationsValue ", transportationsValue);
    let totalTransportations = 0;
    if (transportationsValue) {
      // only if there are items
      for (let a = 0; a < transportationsValue.length; a++) {
        let transportation_id;
        let transportationObj;
        if (log)
          console.log(
            "crudCode()  transport line  ORIGINAL # " + a + ") :",
            transportationsValue[a]
          );
        if (inputFullName === "transportationtransportation_id" && line === a) {
          if (log)
            console.log("crudCode() line just be typed , newValue:", newValue);
          // new value Is an object { id, name}
          // or is null when clic on 'x' to delete value
          if (newValue) transportation_id = newValue.id;
        } else {
          if (log) console.log("crudCode()  line  existed before ");
          // important: id is just the id of the row, the real id of the services is inside: .services_id.id
          // control  empty line, if not return false
          transportation_id = transportationsValue[a].transportation_id
            ? transportationsValue[a].transportation_id.id
            : false;
        }
        if (
          !(
            action === "delete" &&
            line === a &&
            parentField === "transportation"
          ) &&
          transportation_id
        ) {
          // check if this line is not deleting
          transportationObj = getRecordFromOptionsIndirectOnFilter(
            formProps,
            "transportation",
            { id: transportation_id },
            true,
            log
          );
          if (log)
            console.log(
              "crudCode()  transport line  RESULT # " + a + ") :",
              transportationObj
            );
          if (transportationObj) {
            if (log)
              console.log(
                "crudCode() transport price:" +
                  transportationObj.transportation_id +
                  " price:" +
                  transportationObj.price
              );
            const transportationAmount =
              parseFloat(transportationObj.price) || 0; //isolation before to sum, so invalid amounts don't set total like NAN
            totalServices += transportationAmount;
            resolvedMethodPrice.push({
              source: tt(formProps.t, "table.transportation"),
              price: transportationAmount,
            });
          }
        }
      }
    }
    /*
      END calc transport
       */
  }

  formProps.change("_priceformula", JSON.stringify(resolvedMethodPrice));
  // make sure valid type numeric
  totalServices = parseFloat(totalServices);
  if (totalServices) price += totalServices;
  if (log)
    console.log(
      "crudCode() totalServices:" + totalServices + " pricetotal:" + price
    );

  formProps.change("price", toPrice(price));
  let paid = getInputValue(formProps, "paid", { nameForm });
  if (paid && typeof paid === "string") {
    paid = paid.replace(" ", "");
  }
  paid = parseFloat(paid) || 0; // %% harcode, but replair
  if (price && typeof price === "string") {
    price = price.replace(" ", "");
  }

  price = parseFloat(price) || 0;
  const balance = price - paid;
  /* console.log('price',price);
    console.log('paid',paid);
    console.log('balance',balance); */
  formProps.change("balance", toPrice(balance));

  // TWIN DUPLICATE SER910
  // DISCONTINUED. SERVICES IS NOT MORE ADDED MANUALLY
  if (
    action === "xxinitForm" ||
    (action === "xxaddLine" && params.parentField === "xxservices") ||
    inputFullName === "xxcustomerstatus_id" ||
    inputFullName === "xxcustomersubstatus_id" ||
    inputFullName === "xxservicestourservices_id"
  ) {
    // only if there are items

    let customerstatus_id;
    if (inputFullName === "customerstatus_id") {
      // TWIN SU10902
      customerstatus_id = newValue && newValue.id ? newValue.id : newValue;
    } else {
      customerstatus_id = getInputValue(formProps, "customerstatus_id", {
        nameForm,
      });
      if (customerstatus_id && customerstatus_id.id)
        customerstatus_id = customerstatus_id.id;
    }
    let customersubstatus_id;
    if (inputFullName === "customersubstatus_id") {
      // TWIN SU10902
      customersubstatus_id = newValue && newValue.id ? newValue.id : newValue;
    } else {
      customersubstatus_id = getInputValue(formProps, "customersubstatus_id", {
        nameForm,
      });
      if (customersubstatus_id && customersubstatus_id.id)
        customersubstatus_id = customersubstatus_id.id;
    }

    const servicesValue = tryParseJSON(
      getInputValue(formProps, "objects", { nameForm }),
      {}
    );

    // set visible fields
    let totalLines;
    if (
      typeof servicesValue === "undefined" ||
      servicesValue === null ||
      !servicesValue ||
      !servicesValue.length
    ) {
      // important for the logic that value is not undefined or null, but 0, is adding a new line, then simulate have a first line (0) empty
      totalLines = 0;
    } else {
      totalLines = servicesValue.length; // with empty lines value is = [] , means, length = 0, || 0 to make sure get numeric value
    }
    if (action === "addLine") totalLines++;

    for (let a = 0; a < totalLines; a++) {
      let services_id;
      let servicesObj;
      // log = true;
      if (log)
        console.log("services line  ORIGINAL # " + a + ") :", servicesValue[a]);
      if (inputFullName === "servicestourservices_id" && line === a) {
        if (log)
          console.log("services line just be typed , newValue:", newValue);
        // new value Is an object { id, name}
        // or is null when clic on 'x' to delete value
        if (newValue) services_id = newValue.id;
      } else {
        // important: id is just the id of the row, the real id of the services is inside: .services_id.id
        // control  empty line, if not return false

        services_id = assemblyId(
          servicesValue && servicesValue[a] && servicesValue[a].tourservices_id
            ? servicesValue[a].tourservices_id.id
            : false
        );
        if (log)
          console.log(
            "services line  existed before , servicesValue,services_id  ",
            servicesValue[a].tourservices_id.id,
            services_id
          );
      }
      // check if this line is not deleting
      let serviceSource = {};

      let inputOptionsRead = [];
      if (log) console.log("services_id", services_id);
      if (
        !(action === "delete" && line === a && parentField === "services") &&
        services_id
      ) {
        servicesObj = getRecordFromOptionsIndirectOnFilter(
          formProps,
          { ql: "getTourServices" },
          { id: services_id },
          true,
          log
        );
        if (log) console.log("servicesObj", servicesObj);
        if (servicesObj && servicesObj.service_id) {
          serviceSource = getObjFromListById(
            formProps.containerPropsForm.list_service.services,
            servicesObj.service_id
          );
          // console.log('serviceSource', a, serviceSource) ;
          if (log) console.log("serviceSource", serviceSource);
          const inputOptions = tryParseJSON(serviceSource.options, []);
          inputOptionsRead = inputOptions.map((option) => {
            return { id: option.id, name: option.option };
          });
          // console.log('inputOptionsRead', inputOptionsRead);
        }
      }
      if (!newStates.hiddenFields) {
        newStates.hiddenFields = {}; // important to dont reset eveery time, just read the changes after initForm for all situations where add hiddenFields
      }

      // only if there are at least 2 options , make visible field, because checks our radio without options is not well setted
      newStates.hiddenFields["servicesinputText" + a] =
        serviceSource.entrytype !== 1 ? true : inputOptionsRead;
      newStates.hiddenFields["servicesinputChecks" + a] =
        serviceSource.entrytype !== 5 || inputOptionsRead.length < 2
          ? true
          : inputOptionsRead;
      newStates.hiddenFields["servicesinputRadios" + a] =
        serviceSource.entrytype !== 8 || inputOptionsRead.length < 2
          ? true
          : inputOptionsRead;
      newStates.hiddenFields["servicesinputSelect" + a] =
        serviceSource.entrytype !== 10 || inputOptionsRead.length < 2
          ? true
          : inputOptionsRead;
    }

    /*
    // dont need anticipate future linea, is already consider on the block, add a virtual line in the case action === 'addLine'
    if (action === 'addLine') {
      newStates.hiddenFields['servicesinputText'+servicesValue.length] = true; ....
    }*/

    // loop all services to match services with the customerstatus/substatus
  }

  if (inputFullName === "customerstatus_id") {
    // TWIN SU10902

    // autocomplete  get only pure id, before compare changes with old (not really old, but previous value)
    const idNew = newValue && newValue.id ? newValue.id : newValue;
    let idOld = getInputValue(formProps, inputFullName, { nameForm });
    if (idOld && idOld.id) idOld = idOld.id;
    // change tour id then reset room, transport, and services
    if (idNew !== idOld || !idNew) {
      // if idNew is null, then clean too
      result.changeFieldsLater = { customersubstatus_id: null };
    }
  }

  if (log)
    console.log(
      "crudCode() customer_id typeof:" + typeof customer_id,
      customer_id
    );
  if (log) console.log("crudCode() tour_id typeof:" + typeof tour_id, tour_id);

  if (typeof tour_id === "undefined" || !tour_id) {
    newStates.disabledFields["tourroom_id"] = true;
  } else {
    newStates.disabledFields["tourroom_id"] = false;
  }
  if (log) console.log("crudCode() newStates", newStates);
  result.newStates = newStates;
  //console.log('result.newStates ', result.newStates );
  return result;
};

export default crudCode;
