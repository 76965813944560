



import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloLink, concat } from "apollo-link";

import Constants from "./utils/environment";
import { getSession } from "./utils/auth";

import { processError } from "./utils/helper";

import { Tables } from "./defTables";
import strFragments from "./defStrFragmentsQls.js";

import defQls from "./defQls";



const errorLink = onError((Error) => {
 

  

  const { networkError, graphQLErrors } = Error;

 

  if (networkError && !graphQLErrors) {
    localStorage.setItem(
      "Error",
      JSON.stringify({ message: "mobilsemError.network" })
    );
  } else {
    localStorage.setItem("Error", JSON.stringify(Error));
  }
  localStorage.removeItem("ErrorDate");
  localStorage.setItem("ErrorDate", Date.now());
});

export const getHeaders = () => {
  const session = getSession();
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    version: process.env.REACT_APP_VERSION,
    is: session.is,
  };

  headers["Authorization"] = "Bearer " + session.token;
  return headers;
};

export const fetchQl = async (
  tablename = "",
  variables = [],
  options = { input: null, props: {}, noProcessParams: false }
) => {
 
  
  let log = false;
  if (log)
    console.log(
      "tablename/variables/options",
      JSON.stringify(tablename),
      options,
      variables
    );
  if (typeof options.noProcessParams === "undefined")
    options.noProcessParams = false;
  let qlVars = {};
  if (!tablename) {
    console.error(" fetchQl: empty tablename");
    return {};
  }
  let strVariablesDef = "";
  let strVariablesParams = "";
 
 
 

  if (log) console.log("==fetchQl , tablename" + tablename);

  const isTable = tablename.substr(0, 3) !== "get" && !tablename.includes("{");
  let query;
  let tableNameOneRecordOrMore = tablename;
  let pureQl = false;
  if (options._qlType) {
    if (tablename === "customer" || tablename === "person") {
      options._qlType = "selectbox";
    }
    variables.push({ name: "_qlType", type: "String", value: options._qlType });
  }
  if (!isTable) {
    if (tablename.includes("{")) {
      query = tablename;
      pureQl = true;
    } else {
      query = defQls[tablename];
    }
    if (options.noProcessParams) qlVars = variables;
  } else {
    tableNameOneRecordOrMore = `${tablename}${!options.singular ? "s" : ""}`;
  }

  if (!options.noProcessParams) {
    if (log)
      console.log(
        "==fetchQl , tablename" + tablename + " / process params",
        variables
      );
    let vars = 0;
        variables.push({ name: 'organizer_id', type:'String', value: options.props.myState.app.org});
    variables.map((variable) => {
      vars++;
      strVariablesDef +=
        (strVariablesDef ? " ," : "") +
        "$" +
        variable.name +
        ": " +
        variable.type;
      strVariablesParams +=
        (strVariablesParams ? " ," : "") +
        variable.name +
        ": $" +
        variable.name;
      qlVars[variable.name] = variable.value;
    });
    if (log)
      console.log(
        "==fetchQl , tablename" + tablename + " / counts vars",
        vars,
        qlVars
      );
    if (
      (!(pureQl && query.includes("mutation")) &&
        typeof qlVars.name !== "undefined" &&
        qlVars.name.trim() === "") ||
      (typeof qlVars.id !== "undefined" && !qlVars.id)
    ) {
     
     
     
     
      if (log)
        console.log(
          "==fetchQl , tablename" +
            tablename +
            " / one parameter with empty string, return []"
        );
      return Promise.resolve([]);
    }
  } else {
    if (log)
      console.log(
        "==fetchQl , tablename" + tablename + " / NO process params",
        variables
      );
  }
 
  if (isTable) {
    let defaultMiniFields = `
        id
        name`;

    if (Tables[tablename].miniFields) {
     
      defaultMiniFields = "";
      Tables[tablename].miniFields.map((nameField) => {
        defaultMiniFields += `\n      ${nameField}`;
      });
    }
    query = `query ${tablename}NotImportantName ( ${strVariablesDef} )   {   
      ${tableNameOneRecordOrMore} ( ${strVariablesParams} ){
  ${defaultMiniFields}
      }
     }
    `;
  }
  const qlBody = JSON.stringify({ query, variables: qlVars });

  if (log)
    console.log("==fetchQl , tablename" + tablename + " / body: ", qlBody);
  const headers = getHeaders();
  try {
    let response = await fetch(Constants.URI + "/graphql", {
     
      headers,
      method: "POST",
      body: qlBody,
    });
    const datajson = await response.json();
    if (log)
      console.log(
        "==fetchQl , tablename" +
          tableNameOneRecordOrMore +
          " / data json / options",
        datajson,
        options
      );

   
    if (options.dataName) {
     
      return datajson.data[options.dataName];
    }
    if (pureQl && !options.dataName) {
     
      return datajson;
    } else {
      return { options: datajson.data[tableNameOneRecordOrMore] };
    }
  } catch (err) {
    console.log('err',err);
    throw err;
  }
};

export const fetchDirect = async (
  table,
  formProps,
  mode = "List",
  filters = ""
) => {
  let oneRecord = false;
  if (typeof filters === "string") {
    filters = { _and: [{ id: filters }] };
    oneRecord = true;
  }
  try {
    let resRoom = await fetchQl(
      `
      query ${table}${mode} ($_filter: String) {
        ${table}s (_filter: $_filter) {
          ${strFragments[table]}
        }
      }`,
      [{ name: "_filter", type: "String", value: JSON.stringify(filters) }],
      {
        dataName: `${table}s`,
        props: formProps,
      }
    );
    if (oneRecord) {
      return resRoom.length ? resRoom[0] : null;
    } else {
      return resRoom;
    }
  } catch (err) {
    console.log('err', err);
    throw(err);
  }

};

const createClient = (store) => {
 

  const httpLink = new HttpLink({
    uri: Constants.URI + "/graphql",
  });

  
  const cache = new InMemoryCache({
    dataIdFromObject: (o) => o.id,
  });

  const authMiddleware = new ApolloLink((operation, forward) => {
    let headers = {};
    const session = getSession();
   
    headers.authorization = session.token ? `Bearer ${session.token}` : null;
    if (session.token) {
      headers.version = process.env.REACT_APP_VERSION;
      headers.is = session.is;
    }

    operation.setContext({
      headers,
    });

    return forward(operation);
  });

  const defaultOptions = {
    watchQuery: {
     
      fetchPolicy: "network-only",
     
      errorPolicy: "all",
    },
    query: {
     
     
     
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
    mutate: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
  };

  const link = ApolloLink.from([errorLink, authMiddleware, httpLink]);

  const client = new ApolloClient({
   
    link,
   
   
    cache,
    ssrMode: true,
    ssrForceFetchDelay: 100,
    connectToDevTools: true,
    queryDeduplication: true,
    defaultOptions,
  });

  return client;
  

  
};

export default createClient;
