import { toPrice, getInputValue, getToday } from "../../utils/commonutils";
import {
  getRecordFromOptionsIndirectOnFilter,
  getRecordValues,
} from "../../utils/helper";

const crudCode = {};

crudCode.onChangeInput = async (params) => {
  //const log = true;
  const log = false;
  if (log) console.log("onChangeInput crudListCode params:", params);
  const {
    nameForm,
    popup,
    tableCrud,
    inputFullName,
    line,
    action,
    parentField,
    event,
    newValue,
    previousValue,
    props,
    formProps,
    fns,
  } = params;

  /*
   popup denote that value is sent through window, and is here not because there is change, but
   must execute change manually and follow the same logic that onChange
   */
  if (popup) {
    if (inputFullName === "customer_id") {
      formProps.change(inputFullName, newValue);
    }
  }

  const result = {};

  //  if (inputName === 'tour_id' || inputName === 'customerstatus_id' || inputName === 'registrationstatus_id') {
  let customer_id;
  let tourroom_id;
  let tour_id;
  let registrationstatus_id;
  let registationStatusRecord;

  if (inputFullName === "tour_id") {
    tour_id = newValue;
  } else if (action !== "initForm") {
    tour_id = getInputValue(formProps, "tour_id", { nameForm });
  }
  let newStates = { disabledFields: {} };

  if (action !== "initForm") {
    // initForm dispatched on form creation, have no .fns, so logic can't be executed
    let tollfree;
    if (inputFullName === "tollfree") {
      tollfree = newValue || false;
    } else {
      tollfree = getInputValue(formProps, "tollfree", { nameForm });
    }
  }

  if (log)
    console.log(
      "crudCode() customer_id typeof:" + typeof customer_id,
      customer_id
    );
  if (log) console.log("crudCode() tour_id typeof:" + typeof tour_id, tour_id);

  // %%%  truchado: action !== 'initForm' , no permite muodificar un  registro guardado
  if (
    action !== "initForm" ||
    inputFullName === "customer_id" ||
    inputFullName === "tour_id"
  ) {
    //if (typeof customer_id === 'undefined' || typeof tour_id === 'undefined' || !customer_id || !tour_id) {
    if (typeof tour_id === "undefined" || !tour_id) {
      //console.log('va disabled');
      newStates.disabledFields["tourroom_id"] = true;
      if (log) console.log("crudCode() newStates en, disabeld", newStates);
    } else {
      if (log) console.log("crudCode() no disabled");
    }
  } else {
    //console.log('no enter procesus disabled');
  }
  if (log) console.log("crudCode() newStates", newStates);
  result.newStates = newStates;

  return result;
};

export default crudCode;
