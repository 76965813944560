
import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import { Field, FieldArray, reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';
import FormField  from '../FormField';
import FormArrayField from '../FormArrayField';

import { fieldsValidator, getOptions, processNewValues } from '../../utils/helper';
import {resolvePathObj, processValues, realTypeOf, tryParseJSON} from '../../utils/commonutils';

import { Tables } from '../../defTables';
import { normalizes } from '../../utils/normalizes';

import RegistrationGroupping from './RegistrationGroupping'; 
import LinkedRegonlineRoom from './LinkedRegonlineRoom'; 
import LinkedRegonlineRoomShared from './LinkedRegonlineRoomShared'; 
import LinkedRegonlineBed from './LinkedRegonlineBed'; 
import PriceFormula from './PriceFormula'; 
import LinkedRegonlineTransport from './LinkedRegonlineTransport'; 
import LinkedRegonlineNotes from './LinkedRegonlineNotes'; 

const tableCrud = 'registration';
let nameForm = `${tableCrud}Form`;
const validate = (values, props) => fieldsValidator('registration', values, props, nameForm);

class Form extends Component {

  constructor(props) {
    super(props);

    


  }

  componentDidMount() {
   
   
   
    this.timerHandle = setTimeout(() => {
     
      this.props.dispatch ( change (nameForm, '_fakefield',  Math.random() ));
      this.props.executeCode ( 'onChangeInput', { nameForm, action: 'initForm' , props: this.props, formProps: this.props, formState: this.props.formState  });
    }, 1000);
    window.addEventListener('message', this.readMessage, true);
  }

  componentWillUnmount() {
    if (this.timerHandle) {
      clearTimeout(this.timerHandle);
      this.timerHandle = 0;
    }
    window.removeEventListener('message', this.readMessage, true);
  }

  readMessage = (event) => {
    if (!event.origin.includes(window.location.hostname)) return;
    if (event && event.data) {
      const params = tryParseJSON(event.data, {});
      if (params.popup) {
        params.inputFullName = params.popup;
        if (!params.formProps) params.formProps = this.props;
        this.props.executeCode('onChangeInput', params);
      }
  
    }
  };

  hocdebounced = debounce(( methodCode, params)=> {
  
    this.props.executeCode( methodCode, params);
  }, 1500);

  

  render() {
   
    const { error, handleSubmit, invalid, pristine, change, submitting, submitFailed, t, ...otherProps } = this.props;
    return (

      <form onSubmit={handleSubmit}>
        <div className="appBodyTitleSeparator" />
        
        <div className="formSection">
          <Field
            inputName="customer_id"
            name="customer_id"
            formProps={this.props}
            pathInTables="registration.fields.customer_id"
            formState={this.props.formState}
            nameForm="registrationForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="selectAutocomplete"
            preComponentSet = {{"condition":{"compareTo":"formState.actionsave","compareValue":"add"},"position":"insideBottom"} }
            preComponent = {RegistrationGroupping }
            tableCrud={tableCrud}
            typeInput="selectAutocomplete"
            disabled={this.props.id ? true : false}
            mustOnChange = {true}
          />
          <Field
            inputName="registrationgroupping_customers"
            name="registrationgroupping_customers"
            formProps={this.props}
            pathInTables="registration.fields.registrationgroupping_customers"
            formState={this.props.formState}
            nameForm="registrationForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="hidden"
            typeInput="hidden"
          />
          <Field
            inputName="tour_id"
            name="tour_id"
            formProps={this.props}
            pathInTables="registration.fields.tour_id"
            formState={this.props.formState}
            nameForm="registrationForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="selectAutocomplete"
            tableCrud={tableCrud}
            typeInput="selectAutocomplete"
            disabled={this.props.id ? true : false}
            mustOnChange = {true}
          />
        </div>
          <Field
            inputName="_services"
            name="_services"
            formProps={this.props}
            pathInTables="registration.fields._services"
            formState={this.props.formState}
            nameForm="registrationForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="hidden"
            typeInput="hidden"
          />
        <div className="formSection">
{ (resolvePathObj(this.props, 'myState.app.appSettings.modules.DOSSIER', true)) &&
          <Field
            inputName="numbering"
            name="numbering"
            formProps={this.props}
            pathInTables="registration.fields.numbering"
            formState={this.props.formState}
            nameForm="registrationForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            typeInput="text"
          />
 }
          <Field
            inputName="registrationstatus_id"
            name="registrationstatus_id"
            formProps={this.props}
            pathInTables="registration.fields.registrationstatus_id"
            formState={this.props.formState}
            nameForm="registrationForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="selectAutocomplete"
            tableCrud={tableCrud}
            typeInput="selectAutocomplete"
            mustOnChange = {true}
          />
          <Field
            inputName="dateregistration"
            name="dateregistration"
            formProps={this.props}
            pathInTables="registration.fields.dateregistration"
            formState={this.props.formState}
            nameForm="registrationForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            typeInput="text"
            onChange={(event, newValue, previousValue) => {
              this.props.executeCode( 'onChangeInput', { nameForm, inputFullName: 'dateregistration',formProps: this.props, formState: this.props.formState, event, newValue, previousValue });
            }}
          />
          <Field
            inputName="confirmationstatus_id"
            name="confirmationstatus_id"
            formProps={this.props}
            pathInTables="registration.fields.confirmationstatus_id"
            formState={this.props.formState}
            nameForm="registrationForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="selectAutocomplete"
            tableCrud={tableCrud}
            typeInput="selectAutocomplete"
          />
          <Field
            inputName="inactive"
            name="inactive"
            formProps={this.props}
            pathInTables="registration.fields.inactive"
            formState={this.props.formState}
            nameForm="registrationForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="checkbox"
            typeInput="checkbox"
          />
        </div>
        <div className="formSection">
          <Field
            inputName="customerstatus_id"
            name="customerstatus_id"
            formProps={this.props}
            pathInTables="registration.fields.customerstatus_id"
            formState={this.props.formState}
            nameForm="registrationForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="selectAutocomplete"
            tableCrud={tableCrud}
            typeInput="selectAutocomplete"
            mustOnChange = {true}
          />
          <Field
            inputName="customersubstatus_id"
            name="customersubstatus_id"
            formProps={this.props}
            pathInTables="registration.fields.customersubstatus_id"
            formState={this.props.formState}
            nameForm="registrationForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="selectAutocomplete"
            tableCrud={tableCrud}
            filterBy={["customerstatus_id"] }
            typeInput="selectAutocomplete"
            mustOnChange = {true}
          />
        </div>
        <div className="formSection">
          <Field
            inputName="tourroom_id"
            name="tourroom_id"
            formProps={this.props}
            pathInTables="registration.fields.tourroom_id"
            formState={this.props.formState}
            nameForm="registrationForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="selectAutocomplete"
            preComponentSet = {{"position":"insideBottom"} }
            preComponent = {LinkedRegonlineRoom }
            tableCrud={tableCrud}
            filterBy={["tour_id"] }
            typeInput="selectAutocomplete"
            disabled={ 
              this.props.formState.disabledFields &&  this.props.formState.disabledFields.tourroom_id 
            }
            mustOnChange = {true}
          />
          <Field
            inputName="room_shared"
            name="room_shared"
            formProps={this.props}
            pathInTables="registration.fields.room_shared"
            formState={this.props.formState}
            nameForm="registrationForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            preComponentSet = {{"position":"insideBottom"} }
            preComponent = {LinkedRegonlineRoomShared }
            typeInput="text"
          />
          <Field
            inputName="assignedroom"
            name="assignedroom"
            formProps={this.props}
            pathInTables="registration.fields.assignedroom"
            formState={this.props.formState}
            nameForm="registrationForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="checkbox"
            preComponentSet = {{"position":"insideBottom"} }
            preComponent = {LinkedRegonlineBed }
            typeInput="checkbox"
            disabled={true}
          />
        </div>
          <Field
            inputName="_roommates"
            name="_roommates"
            formProps={this.props}
            pathInTables="registration.fields._roommates"
            formState={this.props.formState}
            nameForm="registrationForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="hidden"
            typeInput="hidden"
          />
        <div className="formSection">
          <Field
            inputName="tollfree"
            name="tollfree"
            formProps={this.props}
            pathInTables="registration.fields.tollfree"
            formState={this.props.formState}
            nameForm="registrationForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="checkbox"
            typeInput="checkbox"
            onChange={(event, newValue, previousValue) => {
              this.props.executeCode( 'onChangeInput', { nameForm, inputFullName: 'tollfree',formProps: this.props, formState: this.props.formState, event, newValue, previousValue });
            }}
          />
          <Field
            inputName="price"
            name="price"
            formProps={this.props}
            pathInTables="registration.fields.price"
            formState={this.props.formState}
            nameForm="registrationForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            normalize={normalizes.Price}
            typeInput="text"
            disabled={true}
          />
          <Field
            inputName="paid"
            name="paid"
            formProps={this.props}
            pathInTables="registration.fields.paid"
            formState={this.props.formState}
            nameForm="registrationForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            normalize={normalizes.Price}
            typeInput="text"
            disabled={true}
          />
          <Field
            inputName="balance"
            name="balance"
            formProps={this.props}
            pathInTables="registration.fields.balance"
            formState={this.props.formState}
            nameForm="registrationForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            normalize={normalizes.Price}
            typeInput="text"
            disabled={true}
          />
          <Field
            inputName="_priceformula"
            name="_priceformula"
            formProps={this.props}
            pathInTables="registration.fields._priceformula"
            formState={this.props.formState}
            nameForm="registrationForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="hidden"
            typeInput="hidden"
          />
        </div>
        <PriceFormula {...this.props} containerState={this.state} formProps={this.props} formState={this.props.formState} executeCode={this.props.executeCode} t={t} />
        <div className="formSection">
          <FieldArray
            inputName="transportation"
            name="transportation"
            formProps={this.props}
            pathInTables="registration.fields.transportation"
            formState={this.props.formState}
            nameForm="registrationForm"
            fieldParentKey="transportation"
            component={FormArrayField}
            tableCrud={tableCrud}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            t={t}
            mode={{} }
            labelChildsShow={true}
            preComponentSet = {{"position":"insideAfterTitle"} }
            preComponent = {LinkedRegonlineTransport }
          />
        </div>
        <div className="formSection">
          <FieldArray
            inputName="services"
            name="services"
            formProps={this.props}
            pathInTables="registration.fields.services"
            formState={this.props.formState}
            nameForm="registrationForm"
            fieldParentKey="services"
            component={FormArrayField}
            tableCrud={tableCrud}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            t={t}
            mode={{} }
            labelChildsShow={true}
          />
        </div>
          <Field
            inputName="notes"
            name="notes"
            formProps={this.props}
            pathInTables="registration.fields.notes"
            formState={this.props.formState}
            nameForm="registrationForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="textarea"
            preComponentSet = {{"position":"insideBottom"} }
            preComponent = {LinkedRegonlineNotes }
            typeInput="textarea"
          />
          <Field
            inputName="organizer_id"
            name="organizer_id"
            formProps={this.props}
            pathInTables="registration.fields.organizer_id"
            formState={this.props.formState}
            nameForm="registrationForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="selectAutocomplete"
            tableCrud={tableCrud}
            typeInput="selectAutocomplete"
            disabled={this.props.id ? true : false}
          />
        <Field
          inputName="_formstate"
          name="_formstate"
          typeInput="hidden"
          component={FormField}
          label=""
        />

        <div>
          {error && <strong>{error}</strong>}

          <div className="formError">
            {invalid && t('form.haserrors')}
          </div>

        </div>

      </form>
    );
  }
}


const ComponentWithData = reduxForm({
  form: nameForm,
  touchOnChange: true,
  
  enableReinitialize: true,
  validate,
})(Form);

function mapStateToProps(state, ownProps) {
 
 
  const log = false;
  const statesReturn = { myState: state };
  let initialValues;
  
  
  if (ownProps.formState.actionsave_origen === 'update' ||  ownProps.formState.actionsave_origen === 'duplicate' ) {
    
    if (ownProps.data) {

      initialValues = processValues(ownProps, tableCrud, ownProps.data, 'toClient', 'view');
      
      if (ownProps.formState.defaultValues && ownProps.formState.defaultValues.id) {
        initialValues.id = ownProps.formState.defaultValues.id;
      }
    }
  } else {
    initialValues = processNewValues(ownProps, tableCrud, ownProps.data, 'toClient','new' );
    if (ownProps.containerPropsForm.history && Object.keys(Tables[tableCrud].listFilters)) {
     
      const search = ownProps.containerPropsForm.history.location.search;
      const params = new URLSearchParams(search);
     
      Object.keys(Tables[tableCrud].listFilters.fields).map((fieldKey) => {
       
        if (log) console.log('fieldKey',fieldKey);
        let fieldFilter = Tables[tableCrud].listFilters.fields[fieldKey] ;
        let defaultValue, defaultValueName;
        if (ownProps.formState.defaultValues && ownProps.formState.defaultValues[fieldKey] ) {
         
          defaultValue = ownProps.formState.defaultValues[fieldKey];
        } else {
          defaultValue = params.get(fieldKey);
          defaultValueName = params.get(fieldKey.replace('_id','_name'));
        }
        if (defaultValue) {
          let initialVal;
          const isFieldArray = fieldFilter.fieldContainer && Tables[tableCrud].fields[fieldFilter.fieldContainer].subfields;
          if (fieldFilter.fieldSource
            && fieldFilter.fieldSource.typeInput === 'selectAutocomplete'
            && (fieldFilter.fieldSource.saveonly )
            && !defaultValue.id
            ) {
           

            initialVal = { id: defaultValue, name: defaultValueName };
          } else {
            initialVal = defaultValue;
          }
          if (isFieldArray) {
            fieldKey = fieldFilter.fieldContainer;
            if (!initialValues[fieldKey]) initialValues[fieldKey] = [ {id: ''} ];
            initialValues[fieldKey][0][fieldFilter.subfield] = initialVal;

          } else {
            initialValues[fieldKey] = initialVal;
          }
          if (log) console.log('initialValues[' + fieldKey+'] ', initialValues[fieldKey]);
        }
      });
    }
  }
  statesReturn.initialValues = initialValues ;
  
  return statesReturn;
}

const ComponentWithDataAndState = connect(
  mapStateToProps,
  null,
)(ComponentWithData);

export default ComponentWithDataAndState;
