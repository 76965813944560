import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { appSubmitStart, appSubmitStop } from "../actions/appActions";
import ShowIcon from "../components/icons/ShowIcon";
import IconLoading from "../components/icons/IconLoading";

export default class PageHeader extends Component {
  render() {
    const props = this.props;
    const t = this.props.t;
    const table = props.tableCrud ? props.tables[props.tableCrud] : {};
    const title = props.title || `table.${props.tableCrud}s`;
    const icon = props.icon || table.icon;
    const templates = props.templates || [];
    let link = "";
    if (table.help) {
      const linkhelp = { ...table.help };
      link = linkhelp[Object.keys(linkhelp)[0]];
      if (templates.length) {
        const firstTemplateName = templates[0];
        if (table.help.templates && table.help.templates[firstTemplateName]) {
          link = table.help.templates[firstTemplateName];
        }
      }
    }
    if (props.helpLink) link = props.helpLink;
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            display: "flex",
            fontWeight: "normal",
            letterSpacing: "2px",
            paddingBottom: "10px",
          }}
        >
          <ShowIcon
            icon={icon}
            size="40"
            color={table.iconColor ? table.iconColor : "#CCCCCC"}
          />
          <div className="appBodyTitleLabel">{t(title, { long: true })}</div>
          {table.iconColor && (
            <ShowIcon
              icon={icon}
              size="40"
              color={table.iconColor ? table.iconColor : "#CCCCCC"}
            />
          )}
        </div>

        <div
          style={{
            marginLeft: "70px",
            marginTop: "7px",
            display: "flex",
            verticalAlign: "bottom",
          }}
        >
          {link && process.env.REACT_APP_DISTRIBUTION !== "beta" && (
            <a target="_blank" href={link}>
              <ShowIcon
                verticalAlign="middle"
                icon="help"
                size="30"
                color="#C59E2F"
              />
              <span style={{ verticalAlign: "middle" }}>
                {props.t("info.help")}
              </span>
            </a>
          )}
        </div>
      </div>
    );
  }
}
