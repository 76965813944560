const Tables = {
    "organizer": {
        "uniquefields": {
            "1": [
                "name"
            ]
        },
        "apps": [
            "phone"
        ],
        "orders": {
            "noOptionBlank": true,
            "defaultValue": "updatedAt",
            "label": "form.order",
            "inLine": true,
            "fields": {
                "name": {},
                "updatedAt": {
                    "label": "form.updateAtDesc",
                    "order": "DESC"
                },
                "createdAt": {
                    "label": "form.createAtDesc",
                    "order": "DESC"
                }
            }
        },
        "fields": {
            "id": {
                "type": "ID!",
                "listmini": true,
                "dbtype": "ID",
                "typeInput": "text"
            },
            "name": {
                "type": "String",
                "validators": [
                    "required"
                ],
                "listmini": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.name"
            },
            "province": {
                "type": "String",
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.province"
            },
            "contact": {
                "type": "String",
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.contact"
            },
            "email": {
                "type": "String",
                "validators": [
                    "email"
                ],
                "normalize": "LowerCase",
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.email"
            },
            "limitemail": {
                "type": "Int",
                "dbtype": "Int",
                "typeInput": "text",
                "label": "form.limitemail"
            },
            "limitsms": {
                "type": "Int",
                "dbtype": "Int",
                "typeInput": "text",
                "label": "form.limitsms"
            },
            "info": {
                "type": "String",
                "dbtype": "Json",
                "fatherGroup": true,
                "typeInput": "text",
                "label": "form.info"
            },
            "invoicename": {
                "type": "String",
                "placeHolderShow": true,
                "nameGroup": "info",
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.invoicename"
            },
            "addressline1": {
                "type": "String",
                "placeHolderShow": true,
                "nameGroup": "info",
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.addressline1"
            },
            "addressline2": {
                "type": "String",
                "placeHolderShow": true,
                "nameGroup": "info",
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.addressline2"
            },
            "addressline3": {
                "type": "String",
                "placeHolderShow": true,
                "nameGroup": "info",
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.addressline3"
            },
            "cp": {
                "type": "String",
                "placeHolderShow": true,
                "inputSize": 7,
                "nameGroup": "info",
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.cp"
            },
            "city": {
                "type": "String",
                "placeHolderShow": true,
                "nameGroup": "info",
                "sublabel": "info.citywillnotbeadd",
                "sublabeldown": true,
                "validators": [
                    "required"
                ],
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.city"
            },
            "cityid": {
                "type": "ID",
                "placeHolderShow": true,
                "typeInput": "hidden",
                "nameGroup": "info",
                "dbtype": "ID",
                "label": "form.cityid"
            },
            "country": {
                "type": "String",
                "validators": [
                    "required"
                ],
                "typeInput": "selectAutocomplete",
                "listSource": "countries",
                "nameGroup": "info",
                "saveIdOnly": true,
                "dbtype": "String",
                "translateDisabledSource": true,
                "label": "form.country"
            },
            "lang": {
                "type": "String",
                "validators": [
                    "required"
                ],
                "typeInput": "selectAutocomplete",
                "listSource": "aLanguages",
                "nameGroup": "info",
                "saveIdOnly": true,
                "dbtype": "String",
                "translateDisabledSource": true,
                "label": "form.lang"
            },
            "status": {
                "type": "Int",
                "dbtype": "Int",
                "typeInput": "selectBox",
                "listSource": "orgBase",
                "translateDisabledSource": true,
                "label": "form.status"
            },
            "contacts": {
                "type": "String",
                "dbtype": "Json",
                "placeHolderShowChildsShow": true,
                "subfields": {
                    "id": {
                        "type": "ID",
                        "typeInput": "hidden",
                        "label": "form.id"
                    },
                    "name": {
                        "type": "String",
                        "typeInput": "text",
                        "label": "form.name"
                    },
                    "phone": {
                        "type": "String",
                        "validators": [
                            "required"
                        ],
                        "icon": "phoneblack",
                        "typeInput": "text",
                        "label": "form.phone"
                    },
                    "address": {
                        "type": "String",
                        "typeInput": "text",
                        "label": "form.address"
                    },
                    "email": {
                        "type": "String",
                        "validators": [
                            "email"
                        ],
                        "normalize": "LowerCase",
                        "typeInput": "text",
                        "label": "form.email"
                    },
                    "otherinfo": {
                        "type": "String",
                        "typeInput": "text",
                        "label": "form.otherinfo"
                    }
                },
                "typeInput": "text",
                "label": "form.contacts",
                "mode": {}
            },
            "modules": {
                "type": "String",
                "dbtype": "Json",
                "placeHolderShowChildsShow": true,
                "saveidinrow": true,
                "subfields": {
                    "id": {
                        "label": "form.module",
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "listSource": "orgModules",
                        "validators": [
                            "required"
                        ],
                        "translateDisabledSource": true
                    },
                    "values": {
                        "type": "String",
                        "dbtype": "Json",
                        "subfields": {
                            "id": {
                                "type": "ID",
                                "typeInput": "hidden"
                            },
                            "code": {
                                "type": "String"
                            },
                            "value": {
                                "type": "String"
                            },
                            "otherinfo": {
                                "type": "String"
                            },
                            "hidden": {
                                "type": "Boolean",
                                "typeInput": "checkbox"
                            }
                        },
                        "typeInput": "text",
                        "label": "form.values"
                    }
                },
                "typeInput": "text",
                "label": "form.modules",
                "mode": {}
            },
            "numberingformat": {
                "type": "String",
                "formNewSection": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.numberingformat"
            },
            "notes": {
                "type": "String",
                "typeInput": "textarea",
                "formNewSection": true,
                "dbtype": "String",
                "label": "form.notes"
            },
            "settingsforms": {
                "type": "String",
                "dbtype": "Json",
                "excludedInForm": true,
                "typeInput": "text",
                "label": "form.settingsforms"
            },
            "textgcu": {
                "type": "String",
                "dbtype": "Json",
                "excludedInForm": true,
                "typeInput": "text",
                "label": "form.textgcu"
            },
            "updatedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "dbAutoUpdate": true,
                "excludedInForm": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.updatedAt"
            },
            "createdAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "dbAutoUpdate": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.createdAt"
            },
            "deletedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "disabledMutation": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.deletedAt"
            },
            "deleted": {
                "type": "Boolean",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.deleted"
            },
            "updated_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.updated_user_id"
            },
            "created_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.created_user_id"
            },
            "deleted_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.deleted_user_id"
            }
        },
        "icon": "assignment",
        "destination": [
            "serverSchema",
            "serverResolver",
            "ormModels",
            "components",
            "app",
            "menus"
        ],
        "listview": {
            "fields": {
                "name": true,
                "province": true,
                "city": true,
                "contact": true,
                "email": true,
                "contacts": {
                    "type": "String",
                    "dbtype": "Json",
                    "subfields": {
                        "name": true,
                        "phone": true,
                        "address": true,
                        "otherinfo": true
                    },
                    "limit": 2,
                    "joinsubfields": false
                },
                "createdAt": true,
                "updatedAt": true
            }
        },
        "listFilters": {
            "fields": {
                "name": {
                    "target": [
                        "maincrud",
                        "listmini"
                    ],
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "name",
                    "label": "form.name",
                    "fieldSource": {
                        "type": "String",
                        "validators": [
                            "required"
                        ],
                        "listmini": true,
                        "dbtype": "String",
                        "typeInput": "text",
                        "label": "form.name"
                    },
                    "tableRelated": "",
                    "type": "String",
                    "typeInput": "text",
                    "listSource": ""
                },
                "province": {
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "province",
                    "target": [
                        "maincrud"
                    ],
                    "label": "form.province",
                    "fieldSource": {
                        "type": "String",
                        "dbtype": "String",
                        "typeInput": "text",
                        "label": "form.province"
                    },
                    "tableRelated": "",
                    "type": "String",
                    "typeInput": "text",
                    "listSource": ""
                },
                "city": {
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "city",
                    "target": [
                        "maincrud"
                    ],
                    "label": "form.city",
                    "fieldSource": {
                        "type": "String",
                        "placeHolderShow": true,
                        "nameGroup": "info",
                        "sublabel": "info.citywillnotbeadd",
                        "sublabeldown": true,
                        "validators": [
                            "required"
                        ],
                        "dbtype": "String",
                        "typeInput": "text",
                        "label": "form.city"
                    },
                    "tableRelated": "",
                    "type": "String",
                    "typeInput": "text",
                    "listSource": ""
                },
                "email": {
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "email",
                    "target": [
                        "maincrud"
                    ],
                    "label": "form.email",
                    "fieldSource": {
                        "type": "String",
                        "validators": [
                            "email"
                        ],
                        "normalize": "LowerCase",
                        "dbtype": "String",
                        "typeInput": "text",
                        "label": "form.email"
                    },
                    "tableRelated": "",
                    "type": "String",
                    "typeInput": "text",
                    "listSource": ""
                },
                "id": {
                    "target": "listmini",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "id",
                    "fieldSource": {
                        "type": "ID!",
                        "listmini": true,
                        "dbtype": "ID",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "ID!",
                    "typeInput": "text",
                    "listSource": ""
                }
            }
        },
        "name": {
            "singular": "organizer",
            "plural": "organizers",
            "singularCase": "Organizer",
            "pluralCase": "Organizers"
        },
        "mainfield": "name",
        "related": {
            "updatedUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "updated_user_id",
                "qlDisable": true,
                "alias": "organizerupdated_user_id"
            },
            "createdUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "created_user_id",
                "qlDisable": true,
                "alias": "organizercreated_user_id"
            }
        },
        "miniFields": [
            "id",
            "name"
        ],
        "componentName": "OrganizerView",
        "path": "/organizer-view/:id"
    },
    "customeraddress": {
        "destination": [
            "serverSchema",
            "serverResolver",
            "ormModels"
        ],
        "fields": {
            "id": {
                "type": "ID!",
                "listmini": true,
                "dbtype": "ID",
                "typeInput": "text"
            },
            "customers": {
                "type": "String",
                "dbtype": "Json",
                "labelChildsShow": true,
                "listmini": true,
                "subfields": {
                    "id": {
                        "type": "ID",
                        "typeInput": "hidden",
                        "label": "form.id"
                    },
                    "name": {
                        "type": "String",
                        "typeInput": "text",
                        "label": "form.name"
                    }
                },
                "typeInput": "text",
                "label": "form.customers",
                "mode": {}
            },
            "addresses": {
                "type": "String",
                "dbtype": "Json",
                "labelChildsShow": true,
                "listmini": true,
                "subfields": {
                    "id": {
                        "type": "ID",
                        "typeInput": "hidden",
                        "label": "form.id"
                    },
                    "type": {
                        "type": "String",
                        "placeHolderShow": true,
                        "label": "form.addresses_type",
                        "typeInput": "selectAutocomplete",
                        "listSource": "addressesType",
                        "validators": [
                            "required"
                        ]
                    },
                    "addressline1": {
                        "type": "String",
                        "validators": [
                            "required"
                        ],
                        "placeHolderShow": true,
                        "validateRequiredIfEmpty": "addressline2",
                        "sublabel": "info.addressline1",
                        "sublabeldown": true,
                        "typeInput": "text",
                        "label": "form.addressline1"
                    },
                    "addressline2": {
                        "type": "String",
                        "placeHolderShow": true,
                        "validators": [
                            "required"
                        ],
                        "validateRequiredIfEmpty": "addressline1",
                        "sublabel": "info.addressline2",
                        "sublabeldown": true,
                        "typeInput": "text",
                        "label": "form.addressline2"
                    },
                    "addressline3": {
                        "type": "String",
                        "placeHolderShow": true,
                        "typeInput": "text",
                        "label": "form.addressline3"
                    },
                    "city_id": {
                        "type": "ID",
                        "typeInput": "selectAutocomplete",
                        "table": "city",
                        "add": true,
                        "validators": [
                            "required"
                        ],
                        "onChange": true,
                        "loadAsync": true,
                        "saveonly": "id",
                        "dbtype": "Json",
                        "label": "table.city"
                    },
                    "cp": {
                        "type": "String",
                        "placeHolderShow": true,
                        "inputSize": 7,
                        "loadAsync": true,
                        "typeInput": "selectAutocomplete",
                        "ql": "getCitiesByCp",
                        "validators": [
                            "required"
                        ],
                        "onChange": true,
                        "nameisid": true,
                        "loadMin": 5,
                        "saveonly": "id",
                        "dbtype": "Json",
                        "label": "form.cp"
                    },
                    "country": {
                        "type": "String",
                        "validators": [
                            "required"
                        ],
                        "typeInput": "selectAutocomplete",
                        "listSource": "countries",
                        "saveIdOnly": true,
                        "translateDisabledSource": true,
                        "label": "form.country"
                    }
                },
                "typeInput": "text",
                "label": "form.addresses",
                "mode": {}
            },
            "updatedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "dbAutoUpdate": true,
                "excludedInForm": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.updatedAt"
            },
            "createdAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "dbAutoUpdate": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.createdAt"
            },
            "deletedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "disabledMutation": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.deletedAt"
            },
            "deleted": {
                "type": "Boolean",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.deleted"
            },
            "updated_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.updated_user_id"
            },
            "created_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.created_user_id"
            },
            "deleted_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.deleted_user_id"
            },
            "organizer_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "readOnlyUpdate": true,
                "disabledUpdate": true,
                "validators": [
                    "required"
                ],
                "formNewSection": true,
                "dbtype": "String",
                "table": "organizer",
                "label": "table.organizer"
            }
        },
        "listFilters": {
            "fields": {
                "organizer_id": {
                    "target": [],
                    "tableRelatedAlias": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "customeraddressorganizer_id"
                    },
                    "table": "organizer",
                    "fieldName": "organizer_id",
                    "label": "table.organizer",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "readOnlyUpdate": true,
                        "disabledUpdate": true,
                        "validators": [
                            "required"
                        ],
                        "formNewSection": true,
                        "dbtype": "String",
                        "table": "organizer",
                        "label": "table.organizer"
                    },
                    "tableRelated": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "customeraddressorganizer_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "id": {
                    "target": "listmini",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "id",
                    "fieldSource": {
                        "type": "ID!",
                        "listmini": true,
                        "dbtype": "ID",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "ID!",
                    "typeInput": "text",
                    "listSource": ""
                }
            }
        },
        "listview": {
            "fields": {
                "addresses": {
                    "subfields": {
                        "addressline1": true,
                        "addressline2": true,
                        "addressline3": true
                    },
                    "limit": 1,
                    "joinsubfields": true
                },
                "addresses2": {
                    "field": "addresses",
                    "label": "form.city",
                    "subfields": {
                        "city_id": true
                    },
                    "limit": 1,
                    "joinsubfields": true
                },
                "organizer_id": true
            }
        },
        "norelated": {
            "customer": {
                "norelated": true,
                "table": "customer",
                "fields": [
                    "name"
                ]
            }
        },
        "name": {
            "singular": "customeraddress",
            "plural": "customeraddresss",
            "singularCase": "Customeraddress",
            "pluralCase": "Customeraddresss"
        },
        "mainfield": "customers",
        "related": {
            "organizer_id": {
                "table": "organizer",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "organizer_id",
                "alias": "customeraddressorganizer_id"
            },
            "updatedUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "updated_user_id",
                "qlDisable": true,
                "alias": "customeraddressupdated_user_id"
            },
            "createdUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "created_user_id",
                "qlDisable": true,
                "alias": "customeraddresscreated_user_id"
            }
        },
        "miniFields": [
            "id",
            "customers",
            "addresses"
        ]
    },
    "person": {
        "triggers": true,
        "orders": {
            "noOptionBlank": true,
            "defaultValue": "updatedAt",
            "label": "form.order",
            "inLine": true,
            "fields": {
                "name": {},
                "updatedAt": {
                    "label": "form.updateAtDesc",
                    "order": "DESC"
                },
                "createdAt": {}
            }
        },
        "toolbar": {
            "menus": {
                "filterregistration": {
                    "icon": "registration",
                    "label": "table.registrations",
                    "urlType": "filter",
                    "inView": true,
                    "inList": true
                },
                "filterregistrationAdd": {
                    "icon": "registrationadd",
                    "label": "form.addregistration",
                    "urlType": "filter",
                    "inView": true,
                    "inList": false
                },
                "filterpayment": {
                    "icon": "monetization",
                    "label": "table.payments",
                    "urlType": "filter",
                    "inView": true,
                    "inList": true
                },
                "customerhistory": {
                    "icon": "log",
                    "label": "table.registrationhistory",
                    "component": "RegistrationHistory",
                    "urlType": "child",
                    "inList": false,
                    "inView": true
                }
            }
        },
        "listOrder": "\"person\".\"updatedAt\":DESC",
        "afterAddGoView": true,
        "icon": "tagfaces",
        "preloadHeight": "700px",
        "loadAsync": true,
        "formToolBarBottom": true,
        "destination": [
            "serverSchema",
            "serverResolver",
            "ormModels",
            "components",
            "app"
        ],
        "fields": {
            "id": {
                "type": "ID!",
                "listmini": true,
                "dbtype": "ID",
                "typeInput": "text"
            },
            "gendertype_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "listmini": false,
                "dbtype": "String",
                "table": "gendertype",
                "label": "table.gendertype"
            },
            "name": {
                "type": "String",
                "disabledMutation": true,
                "excludedInForm": true,
                "listmini": true,
                "label": "form.fullname",
                "dbtype": "String",
                "typeInput": "text"
            },
            "lastname": {
                "type": "String",
                "validators": [
                    "required",
                    "alphai18n",
                    "minLength:2"
                ],
                "normalize": "UpperCase",
                "onDebounce": 1500,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.lastname"
            },
            "firstname": {
                "type": "String",
                "validators": [
                    "required",
                    "alphai18n",
                    "minLength:2"
                ],
                "normalize": "StartCase",
                "onDebounce": 1500,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.firstname"
            },
            "email": {
                "type": "String",
                "validators": [
                    "email"
                ],
                "normalize": "LowerCase",
                "listmini": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.email"
            },
            "nick": {
                "type": "String",
                "validators": [
                    "required",
                    "minLength:6",
                    "maxLength:15"
                ],
                "disabledUpdate": true,
                "normalize": "LowerCaseTrim",
                "sublabel": "info.uniquenick",
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.nick"
            },
            "password": {
                "type": "String",
                "typeInput": "password",
                "encrpyt": true,
                "sublabel": "info.emptyWillNotChangeValue",
                "excludedInList": true,
                "queryExcluded": true,
                "validators": [
                    "password"
                ],
                "dbtype": "String",
                "label": "form.password"
            },
            "socialsn": {
                "type": "String",
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.socialsn"
            },
            "occupation": {
                "type": "String",
                "nameGroup": "info",
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.occupation"
            },
            "imageright": {
                "type": "Int",
                "dbtype": "Int",
                "typeInput": "selectBox",
                "listSource": "yesNoEmpty",
                "label": "form.imageright"
            },
            "inactive": {
                "type": "Boolean",
                "sublabel": "info.inactive",
                "sublabeldown": true,
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.inactive"
            },
            "deceased": {
                "type": "Boolean",
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.deceased"
            },
            "birthdate": {
                "type": "String",
                "dbtype": "Date",
                "formNewSection": true,
                "typeInput": "text",
                "validators": [
                    "date"
                ],
                "label": "form.birthdate"
            },
            "grouped_customer_id": {
                "type": "String",
                "label": "form.grouped_customer_id",
                "typeInput": "selectAutocomplete",
                "formNewSection": true,
                "dbtype": "Json",
                "table": "person",
                "loadAsync": true,
                "saveonly": "id"
            },
            "grouped_filiation": {
                "type": "Int",
                "dbtype": "Int",
                "typeInput": "selectBox",
                "label": "form.grouped_filiation",
                "listSource": "filiation"
            },
            "info": {
                "type": "String",
                "dbtype": "Json",
                "fatherGroup": true,
                "typeInput": "text",
                "label": "form.info"
            },
            "birthplace": {
                "type": "String",
                "nameGroup": "info",
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.birthplace"
            },
            "birthcountry": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "listSource": "countries",
                "nameGroup": "info",
                "dbtype": "String",
                "translateDisabledSource": true,
                "label": "form.birthcountry"
            },
            "nationality": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "listSource": "countries",
                "dbtype": "String",
                "translateDisabledSource": true,
                "label": "form.nationality"
            },
            "residence": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "listSource": "countries",
                "dbtype": "String",
                "translateDisabledSource": true,
                "label": "form.residence"
            },
            "fullnameparent1": {
                "type": "String",
                "nameGroup": "info",
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.fullnameparent1"
            },
            "fullnameparent2": {
                "type": "String",
                "nameGroup": "info",
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.fullnameparent2"
            },
            "nameunmarried": {
                "type": "String",
                "nameGroup": "info",
                "sublabel": "info.nameunmarried",
                "sublabeldown": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.nameunmarried"
            },
            "contacts": {
                "type": "String",
                "dbtype": "Json",
                "placeHolderShowChildsShow": true,
                "subfields": {
                    "id": {
                        "type": "ID",
                        "typeInput": "hidden",
                        "noExport": true,
                        "label": "form.id"
                    },
                    "type": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "listSource": "contactType",
                        "validators": [
                            "required"
                        ],
                        "label": "form.type"
                    },
                    "name": {
                        "type": "String",
                        "validators": [
                            "required"
                        ],
                        "typeInput": "text",
                        "label": "form.name"
                    },
                    "phone": {
                        "type": "String",
                        "validators": [
                            "required"
                        ],
                        "icon": "phoneblack",
                        "typeInput": "text",
                        "label": "form.phone"
                    },
                    "otherinfo": {
                        "type": "String",
                        "typeInput": "text",
                        "label": "form.otherinfo"
                    }
                },
                "typeInput": "text",
                "label": "form.contacts",
                "mode": {}
            },
            "phones": {
                "type": "String",
                "dbtype": "Json",
                "placeHolderShowChildsShow": true,
                "subfields": {
                    "id": {
                        "type": "ID",
                        "typeInput": "hidden",
                        "label": "form.id"
                    },
                    "type": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "listSource": "phonesType",
                        "validators": [
                            "required"
                        ],
                        "label": "form.type"
                    },
                    "phone": {
                        "type": "String",
                        "validators": [
                            "required",
                            "valPhone"
                        ],
                        "icon": "phoneblack",
                        "typeInput": "text",
                        "label": "form.phone"
                    },
                    "otherinfo": {
                        "type": "String",
                        "typeInput": "text",
                        "label": "form.otherinfo"
                    }
                },
                "typeInput": "text",
                "label": "form.phones",
                "mode": {}
            },
            "groupedaddress_customer_id": {
                "type": "String",
                "label": "form.grouped_address",
                "typeInput": "selectAutocomplete",
                "formNewSection": true,
                "listmini": true,
                "dbtype": "Json",
                "table": "person",
                "loadAsync": true,
                "saveonly": "id"
            },
            "customeraddress_customers": {
                "type": "String",
                "typeInput": "hidden",
                "virtual": true,
                "preventExit": true,
                "dbtype": "String",
                "label": "form.customeraddress_customers"
            },
            "addresses": {
                "type": "String",
                "dbtype": "Json",
                "labelChildsShow": true,
                "subfields": {
                    "id": {
                        "type": "ID",
                        "typeInput": "hidden",
                        "label": "form.id"
                    },
                    "type": {
                        "type": "String",
                        "placeHolderShow": true,
                        "label": "form.addresses_type",
                        "typeInput": "selectAutocomplete",
                        "listSource": "addressesType",
                        "validators": [
                            "required"
                        ]
                    },
                    "addressline1": {
                        "type": "String",
                        "validators": [
                            "required"
                        ],
                        "placeHolderShow": true,
                        "validateRequiredIfEmpty": "addressline2",
                        "sublabel": "info.addressline1",
                        "sublabeldown": true,
                        "typeInput": "text",
                        "label": "form.addressline1"
                    },
                    "addressline2": {
                        "type": "String",
                        "placeHolderShow": true,
                        "validators": [
                            "required"
                        ],
                        "validateRequiredIfEmpty": "addressline1",
                        "sublabel": "info.addressline2",
                        "sublabeldown": true,
                        "typeInput": "text",
                        "label": "form.addressline2"
                    },
                    "addressline3": {
                        "type": "String",
                        "placeHolderShow": true,
                        "typeInput": "text",
                        "label": "form.addressline3"
                    },
                    "city_id": {
                        "type": "ID",
                        "typeInput": "selectAutocomplete",
                        "table": "city",
                        "add": true,
                        "validators": [
                            "required"
                        ],
                        "onChange": true,
                        "loadAsync": true,
                        "saveonly": "id",
                        "dbtype": "Json",
                        "label": "table.city"
                    },
                    "cp": {
                        "type": "String",
                        "placeHolderShow": true,
                        "inputSize": 7,
                        "loadAsync": true,
                        "typeInput": "selectAutocomplete",
                        "ql": "getCitiesByCp",
                        "validators": [
                            "required"
                        ],
                        "onChange": true,
                        "nameisid": true,
                        "loadMin": 5,
                        "saveonly": "id",
                        "dbtype": "Json",
                        "label": "form.cp"
                    },
                    "country": {
                        "type": "String",
                        "validators": [
                            "required"
                        ],
                        "typeInput": "selectAutocomplete",
                        "listSource": "countries",
                        "saveIdOnly": true,
                        "translateDisabledSource": true,
                        "label": "form.country"
                    }
                },
                "typeInput": "text",
                "label": "form.addresses",
                "mode": {}
            },
            "identitydocs": {
                "type": "String",
                "dbtype": "Json",
                "labelChildsShow": true,
                "placeHolderShowChildsShow": false,
                "subfields": {
                    "id": {
                        "type": "ID",
                        "typeInput": "hidden",
                        "noExport": true,
                        "label": "form.id"
                    },
                    "type": {
                        "type": "String",
                        "label": "form.id_doctype",
                        "typeInput": "selectAutocomplete",
                        "listSource": "identitydocsType",
                        "validators": [
                            "required"
                        ]
                    },
                    "country": {
                        "type": "String",
                        "validators": [
                            "required"
                        ],
                        "typeInput": "selectAutocomplete",
                        "listSource": "countries",
                        "saveIdOnly": true,
                        "translateDisabledSource": true,
                        "label": "form.country"
                    },
                    "number": {
                        "type": "String",
                        "label": "form.id_docnumber",
                        "validators": [
                            "required"
                        ],
                        "typeInput": "text"
                    },
                    "idexpiration": {
                        "type": "String",
                        "dbtype": "Date",
                        "typeInput": "text",
                        "label": "form.idexpiration"
                    },
                    "idiauthority": {
                        "type": "String",
                        "typeInput": "text",
                        "label": "form.idiauthority"
                    },
                    "ididate": {
                        "type": "String",
                        "dbtype": "Date",
                        "typeInput": "text",
                        "label": "form.ididate"
                    },
                    "idnameisother": {
                        "type": "Boolean",
                        "typeInput": "checkbox",
                        "inLine": true,
                        "noContainerInLine": true,
                        "onChange": true,
                        "formNewSection": true,
                        "noExport": true,
                        "width": "700px",
                        "label": "form.idnameisother"
                    },
                    "lastname": {
                        "type": "String",
                        "validators": [
                            "alphai18n"
                        ],
                        "normalize": "UpperCase",
                        "onDebounce": 1500,
                        "onHidden": true,
                        "formNewSection": true,
                        "typeInput": "text",
                        "label": "form.lastname"
                    },
                    "firstname": {
                        "type": "String",
                        "validators": [
                            "alphai18n"
                        ],
                        "onHidden": true,
                        "onDebounce": 1500,
                        "typeInput": "text",
                        "label": "form.firstname"
                    },
                    "idaddressisother": {
                        "type": "Boolean",
                        "typeInput": "checkbox",
                        "inLine": true,
                        "onChange": true,
                        "formNewSection": true,
                        "noContainerInLine": true,
                        "noExport": true,
                        "width": "700px",
                        "label": "form.idaddressisother"
                    },
                    "addressline1": {
                        "type": "String",
                        "onHidden": true,
                        "formNewSection": true,
                        "typeInput": "text",
                        "label": "form.addressline1"
                    },
                    "addressline2": {
                        "type": "String",
                        "onHidden": true,
                        "typeInput": "text",
                        "label": "form.addressline2"
                    },
                    "addressline3": {
                        "type": "String",
                        "onHidden": true,
                        "typeInput": "text",
                        "label": "form.addressline3"
                    },
                    "cp": {
                        "type": "String",
                        "inputSize": 7,
                        "onHidden": true,
                        "typeInput": "text",
                        "label": "form.cp"
                    },
                    "city": {
                        "type": "String",
                        "placeHolderShow": true,
                        "sublabel": "info.citywillnotbeadd",
                        "sublabeldown": true,
                        "onHidden": true,
                        "typeInput": "text",
                        "label": "form.city"
                    },
                    "cityid": {
                        "type": "ID",
                        "typeInput": "hidden",
                        "noExport": true,
                        "label": "form.cityid"
                    }
                },
                "typeInput": "text",
                "label": "form.identitydocs",
                "mode": {}
            },
            "notes": {
                "type": "String",
                "typeInput": "textarea",
                "nameGroup": "info",
                "formNewSection": true,
                "dbtype": "String",
                "label": "form.notes"
            },
            "counterhistory": {
                "type": "Int",
                "disabledMutation": true,
                "excludedInForm": true,
                "label": "form.crm",
                "dbtype": "Int",
                "typeInput": "text"
            },
            "person_groupid": {
                "excludedInForm": true,
                "disabledSystemUpdate": true,
                "type": "ID",
                "dbtype": "ID",
                "typeInput": "text",
                "label": "form.person_groupid"
            },
            "pending_tourid": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.pending_tourid"
            },
            "member": {
                "type": "Int",
                "excludedInForm": true,
                "disabledSystemUpdate": true,
                "dbtype": "Int",
                "typeInput": "text",
                "label": "form.member"
            },
            "updatedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "dbAutoUpdate": true,
                "excludedInForm": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.updatedAt"
            },
            "createdAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "dbAutoUpdate": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.createdAt"
            },
            "deletedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "disabledMutation": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.deletedAt"
            },
            "deleted": {
                "type": "Boolean",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.deleted"
            },
            "updated_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.updated_user_id"
            },
            "created_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.created_user_id"
            },
            "deleted_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.deleted_user_id"
            },
            "organizer_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "readOnlyUpdate": true,
                "disabledUpdate": true,
                "validators": [
                    "required"
                ],
                "formNewSection": true,
                "dbtype": "String",
                "table": "organizer",
                "label": "table.organizer"
            }
        },
        "listFilters": {
            "fields": {
                "organizer_id": {
                    "target": [],
                    "tableRelatedAlias": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "personorganizer_id"
                    },
                    "table": "organizer",
                    "fieldName": "organizer_id",
                    "label": "table.organizer",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "readOnlyUpdate": true,
                        "disabledUpdate": true,
                        "validators": [
                            "required"
                        ],
                        "formNewSection": true,
                        "dbtype": "String",
                        "table": "organizer",
                        "label": "table.organizer"
                    },
                    "tableRelated": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "personorganizer_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "lastname": {
                    "preload": true,
                    "minLength": 3,
                    "normalize": "UpperCase",
                    "sublabel": "info.filterMinLength::3",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "lastname",
                    "target": [
                        "maincrud"
                    ],
                    "label": "form.lastname",
                    "fieldSource": {
                        "type": "String",
                        "validators": [
                            "required",
                            "alphai18n",
                            "minLength:2"
                        ],
                        "normalize": "UpperCase",
                        "onDebounce": 1500,
                        "dbtype": "String",
                        "typeInput": "text",
                        "label": "form.lastname"
                    },
                    "tableRelated": "",
                    "type": "String",
                    "typeInput": "text",
                    "listSource": ""
                },
                "firstname": {
                    "preload": true,
                    "minLength": 3,
                    "anyCase": true,
                    "normalize": "StartCase",
                    "sublabel": "info.filterMinLength::3",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "firstname",
                    "target": [
                        "maincrud"
                    ],
                    "label": "form.firstname",
                    "fieldSource": {
                        "type": "String",
                        "validators": [
                            "required",
                            "alphai18n",
                            "minLength:2"
                        ],
                        "normalize": "StartCase",
                        "onDebounce": 1500,
                        "dbtype": "String",
                        "typeInput": "text",
                        "label": "form.firstname"
                    },
                    "tableRelated": "",
                    "type": "String",
                    "typeInput": "text",
                    "listSource": ""
                },
                "addresses_city_id": {
                    "fieldContainer": "addresses",
                    "subfield": "city_id",
                    "table": "city",
                    "typeInput": "selectAutocomplete",
                    "tableRelatedAlias": "city",
                    "fieldName": "addresses.city_id",
                    "target": [
                        "maincrud"
                    ],
                    "label": "table.city",
                    "fieldSource": {
                        "type": "ID",
                        "typeInput": "selectAutocomplete",
                        "table": "city",
                        "add": true,
                        "validators": [
                            "required"
                        ],
                        "onChange": true,
                        "loadAsync": true,
                        "saveonly": "id",
                        "dbtype": "Json",
                        "label": "table.city"
                    },
                    "tableRelated": "city",
                    "type": "ID",
                    "listSource": ""
                },
                "inactive": {
                    "typeInput": "radio",
                    "listOptions": [
                        {
                            "id": "false",
                            "name": "form.no"
                        },
                        {
                            "id": "true",
                            "name": "form.yes"
                        },
                        {
                            "id": "null",
                            "name": "form.all"
                        }
                    ],
                    "showLabel": true,
                    "inLine": true,
                    "defaultValue": "false",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "inactive",
                    "target": [
                        "maincrud"
                    ],
                    "label": "form.inactive",
                    "fieldSource": {
                        "type": "Boolean",
                        "sublabel": "info.inactive",
                        "sublabeldown": true,
                        "dbtype": "Boolean",
                        "typeInput": "checkbox",
                        "label": "form.inactive"
                    },
                    "tableRelated": "",
                    "type": "Boolean",
                    "listSource": ""
                },
                "id": {
                    "target": "listmini",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "id",
                    "fieldSource": {
                        "type": "ID!",
                        "listmini": true,
                        "dbtype": "ID",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "ID!",
                    "typeInput": "text",
                    "listSource": ""
                },
                "name": {
                    "target": [
                        "listmini"
                    ],
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "name",
                    "label": "form.fullname",
                    "fieldSource": {
                        "type": "String",
                        "disabledMutation": true,
                        "excludedInForm": true,
                        "listmini": true,
                        "label": "form.fullname",
                        "dbtype": "String",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "String",
                    "typeInput": "text",
                    "listSource": ""
                }
            }
        },
        "listview": {
            "fields": {
                "name": true,
                "counterhistory": {
                    "icon": "log",
                    "childTable": "customerhistory",
                    "filter": "!Empty"
                },
                "email": true,
                "addresses": {
                    "subfields": {
                        "addressline1": true,
                        "addressline2": true,
                        "addressline3": true
                    },
                    "limit": 1,
                    "joinsubfields": true
                },
                "addresses2": {
                    "field": "addresses",
                    "label": "form.city",
                    "subfields": {
                        "city_id": true
                    },
                    "limit": 1,
                    "joinsubfields": true
                },
                "phones": {
                    "type": "String",
                    "dbtype": "Json",
                    "subfields": {
                        "phone": true
                    },
                    "limit": 2,
                    "joinsubfields": true,
                    "separator": ""
                },
                "organizer_id": true
            }
        },
        "norelated": {
            "city": {
                "norelated": true,
                "table": "city",
                "fields": [
                    "name"
                ]
            }
        },
        "related": {
            "grouped_customer_id": {
                "table": "person",
                "label": "form.grouped_customer_id",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "grouped_customer_id",
                "alias": "persongrouped_customer_id"
            },
            "groupedaddress_customer_id": {
                "table": "person",
                "label": "form.grouped_customer_id",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "groupedaddress_customer_id",
                "alias": "persongroupedaddress_customer_id"
            },
            "gendertype_id": {
                "table": "gendertype",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "gendertype_id",
                "listmini": true,
                "alias": "persongendertype_id"
            },
            "pending_tourid": {
                "table": "tour",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "pending_tourid",
                "listmini": true,
                "alias": "personpending_tourid"
            },
            "organizer_id": {
                "table": "organizer",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "organizer_id",
                "alias": "personorganizer_id"
            },
            "updatedUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "updated_user_id",
                "qlDisable": true,
                "alias": "personupdated_user_id"
            },
            "createdUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "created_user_id",
                "qlDisable": true,
                "alias": "personcreated_user_id"
            }
        },
        "name": {
            "singular": "person",
            "plural": "persons",
            "singularCase": "Person",
            "pluralCase": "Persons"
        },
        "mainfield": "name",
        "miniFields": [
            "id",
            "name",
            "email",
            "groupedaddress_customer_id"
        ],
        "componentName": "PersonView",
        "path": "/person-view/:id"
    },
    "customer": {
        "help": {
            "fr-PELE": "https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.t0gc6h8dkoou"
        },
        "disableRecreateTemplateList": true,
        "triggers": true,
        "pagesettings": true,
        "orders": {
            "noOptionBlank": true,
            "defaultValue": "updatedAt",
            "label": "form.order",
            "inLine": true,
            "fields": {
                "numbering": {
                    "label": "form.numbering",
                    "order": "ASC",
                    "module": "DOSSIERCUSTOMER"
                },
                "name": {},
                "updatedAt": {
                    "label": "form.updateAtDesc",
                    "order": "DESC"
                },
                "createdAt": {}
            }
        },
        "toolbar": {
            "menus": {
                "export": {
                    "icon": "export",
                    "label": "table.export",
                    "urlType": "link",
                    "url": "/export-customeradd",
                    "inView": false,
                    "inListHeader": true,
                    "inList": false
                },
                "labels": {
                    "icon": "labels",
                    "label": "form.labels",
                    "inView": false,
                    "inListHeader": true,
                    "inList": false
                },
                "filterregistration": {
                    "icon": "registration",
                    "label": "table.registrations",
                    "urlType": "filter",
                    "inView": true,
                    "inList": true
                },
                "filterregistrationAdd": {
                    "icon": "registrationadd",
                    "label": "form.addregistration",
                    "urlType": "filter",
                    "inView": true,
                    "inList": false
                },
                "filterpayment": {
                    "icon": "monetization",
                    "label": "table.payments",
                    "urlType": "filter",
                    "inView": true,
                    "inList": true
                },
                "customerhistory": {
                    "icon": "log",
                    "label": "table.customerhistory",
                    "component": "RegistrationHistory",
                    "urlType": "child",
                    "inList": false,
                    "inView": true
                },
                "duplicate": {
                    "inView": true,
                    "label": "info.duplicate",
                    "urlType": "fn"
                }
            }
        },
        "listOrder": "\"customer\".\"updatedAt\":DESC",
        "afterAddGoView": true,
        "icon": "pele",
        "preloadHeight": "700px",
        "loadAsync": true,
        "formToolBarBottom": true,
        "destination": [
            "serverSchema",
            "serverResolver",
            "ormModels",
            "components",
            "app",
            "menus"
        ],
        "fields": {
            "id": {
                "type": "ID!",
                "listmini": true,
                "dbtype": "ID",
                "typeInput": "text"
            },
            "gendertype_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "listmini": false,
                "dbtype": "String",
                "table": "gendertype",
                "label": "table.gendertype"
            },
            "name": {
                "type": "String",
                "disabledMutation": true,
                "excludedInForm": true,
                "listmini": true,
                "label": "form.fullname",
                "dbtype": "String",
                "typeInput": "text"
            },
            "lastname": {
                "type": "String",
                "validators": [
                    "required",
                    "alphai18n",
                    "minLength:2"
                ],
                "normalize": "UpperCase",
                "onDebounce": 1500,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.lastname"
            },
            "firstname": {
                "type": "String",
                "validators": [
                    "required",
                    "alphai18n",
                    "minLength:2"
                ],
                "normalize": "StartCase",
                "onDebounce": 1500,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.firstname"
            },
            "numbering": {
                "type": "String",
                "disabled": true,
                "disabledSystemUpdate": true,
                "module": "DOSSIERCUSTOMER",
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.numbering"
            },
            "extrafieldstring1": {
                "type": "String",
                "nameGroup": "info",
                "label": "form.otherinfo",
                "dbtype": "String",
                "typeInput": "text"
            },
            "email": {
                "type": "String",
                "validators": [
                    "email"
                ],
                "normalize": "LowerCase",
                "listmini": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.email"
            },
            "socialsn": {
                "type": "String",
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.socialsn"
            },
            "occupation": {
                "type": "String",
                "nameGroup": "info",
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.occupation"
            },
            "imageright": {
                "type": "Int",
                "dbtype": "Int",
                "typeInput": "selectBox",
                "listSource": "yesNoEmpty",
                "label": "form.imageright"
            },
            "nosharedata": {
                "type": "Boolean",
                "typeInput": "checkbox",
                "dbtype": "Boolean",
                "label": "form.nosharedata"
            },
            "nonewsletter": {
                "type": "Boolean",
                "typeInput": "checkbox",
                "dbtype": "Boolean",
                "label": "form.nonewsletter"
            },
            "inactive": {
                "type": "Boolean",
                "sublabel": "info.inactive",
                "sublabeldown": true,
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.inactive"
            },
            "deceased": {
                "type": "Boolean",
                "preComponents": {
                    "components": {
                        "LinkedPersonMainData": {
                            "position": "outsideAfterCloseFieldsContainer"
                        }
                    }
                },
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.deceased"
            },
            "customerstatus_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "formNewSection": true,
                "listmini": true,
                "onChange": true,
                "dbtype": "String",
                "table": "customerstatus",
                "label": "table.customerstatus"
            },
            "customersubstatus_id": {
                "type": "String",
                "label": "form.substatus",
                "typeInput": "selectAutocomplete",
                "filterBy": [
                    "customerstatus_id"
                ],
                "listmini": true,
                "subfield": "substatus",
                "table": "customerstatus",
                "dbtype": "String"
            },
            "dietnotes": {
                "formNewSection": true,
                "type": "String",
                "typeInput": "textarea",
                "dbtype": "String",
                "label": "form.dietnotes"
            },
            "handicapnotes": {
                "type": "String",
                "typeInput": "textarea",
                "dbtype": "String",
                "label": "form.handicapnotes"
            },
            "grouped_customer_id": {
                "type": "String",
                "label": "form.grouped_customer_id",
                "typeInput": "selectAutocomplete",
                "formNewSection": true,
                "dbtype": "Json",
                "table": "customer",
                "loadAsync": true,
                "saveonly": "id"
            },
            "grouped_filiation": {
                "type": "Int",
                "dbtype": "Int",
                "typeInput": "selectBox",
                "label": "form.grouped_filiation",
                "listSource": "filiation"
            },
            "birthdate": {
                "type": "String",
                "dbtype": "Date",
                "formNewSection": true,
                "typeInput": "text",
                "validators": [
                    "date"
                ],
                "label": "form.birthdate"
            },
            "info": {
                "type": "String",
                "dbtype": "Json",
                "fatherGroup": true,
                "typeInput": "text",
                "label": "form.info"
            },
            "birthplace": {
                "type": "String",
                "nameGroup": "info",
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.birthplace"
            },
            "birthcountry": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "listSource": "countries",
                "nameGroup": "info",
                "dbtype": "String",
                "translateDisabledSource": true,
                "label": "form.birthcountry"
            },
            "nationality": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "listSource": "countries",
                "dbtype": "String",
                "translateDisabledSource": true,
                "label": "form.nationality"
            },
            "residence": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "listSource": "countries",
                "dbtype": "String",
                "translateDisabledSource": true,
                "label": "form.residence"
            },
            "fullnameparent1": {
                "type": "String",
                "nameGroup": "info",
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.fullnameparent1"
            },
            "fullnameparent2": {
                "type": "String",
                "nameGroup": "info",
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.fullnameparent2"
            },
            "nameunmarried": {
                "type": "String",
                "nameGroup": "info",
                "sublabel": "info.nameunmarried",
                "sublabeldown": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.nameunmarried"
            },
            "contacts": {
                "type": "String",
                "dbtype": "Json",
                "placeHolderShowChildsShow": true,
                "preComponents": {
                    "components": {
                        "LinkedPersonContacts": {
                            "position": "insideUnderTitle"
                        }
                    }
                },
                "subfields": {
                    "id": {
                        "type": "ID",
                        "typeInput": "hidden",
                        "noExport": true,
                        "label": "form.id"
                    },
                    "type": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "listSource": "contactType",
                        "validators": [
                            "required"
                        ],
                        "label": "form.type"
                    },
                    "name": {
                        "type": "String",
                        "validators": [
                            "required"
                        ],
                        "typeInput": "text",
                        "label": "form.name"
                    },
                    "phone": {
                        "type": "String",
                        "validators": [
                            "required"
                        ],
                        "icon": "phoneblack",
                        "typeInput": "text",
                        "label": "form.phone"
                    },
                    "otherinfo": {
                        "type": "String",
                        "typeInput": "text",
                        "label": "form.otherinfo"
                    }
                },
                "typeInput": "text",
                "label": "form.contacts",
                "mode": {}
            },
            "smsdisabled": {
                "type": "Boolean",
                "typeInput": "checkbox",
                "inLine": true,
                "noContainerInLine": true,
                "formNewSection": true,
                "nameGroup": "info",
                "width": "700px",
                "dbtype": "Boolean",
                "label": "form.smsdisabled"
            },
            "mobile": {
                "type": "String",
                "inLine": true,
                "listmini": true,
                "noContainerInLine": true,
                "width": "700px",
                "disabledAdd": true,
                "disabledUpdate": true,
                "typeInput": "hidden",
                "dbtype": "String",
                "label": "form.mobile"
            },
            "phones": {
                "type": "String",
                "dbtype": "Json",
                "placeHolderShowChildsShow": true,
                "preComponents": {
                    "components": {
                        "LinkedPersonPhones": {
                            "position": "insideUnderTitle"
                        }
                    }
                },
                "subfields": {
                    "id": {
                        "type": "ID",
                        "typeInput": "hidden",
                        "label": "form.id"
                    },
                    "type": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "listSource": "phonesType",
                        "validators": [
                            "required"
                        ],
                        "sublabel": "info.phonetype",
                        "label": "form.type"
                    },
                    "phone": {
                        "type": "String",
                        "validators": [
                            "required",
                            "valPhone"
                        ],
                        "normalize": "Phone",
                        "icon": "phoneblack",
                        "onChange": true,
                        "sublabel": "info.phone",
                        "typeInput": "text",
                        "label": "form.phone"
                    },
                    "ph": {
                        "type": "String",
                        "label": "",
                        "typeInput": "hidden",
                        "disabledOnAdd": true,
                        "disabledOnUpdate": true
                    },
                    "otherinfo": {
                        "type": "String",
                        "sublabel": "info.phoneotherinfo",
                        "typeInput": "text",
                        "label": "form.otherinfo"
                    }
                },
                "typeInput": "text",
                "label": "form.phones",
                "mode": {}
            },
            "customeraddress_id": {
                "type": "String",
                "typeInput": "hidden",
                "label": "form.customeraddress_id",
                "dbtype": "String"
            },
            "customeraddress_customers": {
                "type": "String",
                "typeInput": "hidden",
                "virtual": true,
                "preventExit": true,
                "dbtype": "String",
                "label": "form.customeraddress_customers"
            },
            "addresses": {
                "preComponents": {
                    "components": {
                        "MemberAddress": {
                            "position": "insideAfterTitle"
                        },
                        "LinkedPersonAddresses": {
                            "position": "insideUnderTitle"
                        }
                    }
                },
                "type": "String",
                "dbtype": "Json",
                "labelChildsShow": true,
                "subfields": {
                    "id": {
                        "type": "ID",
                        "typeInput": "hidden",
                        "label": "form.id"
                    },
                    "type": {
                        "type": "String",
                        "placeHolderShow": true,
                        "label": "form.addresses_type",
                        "typeInput": "selectAutocomplete",
                        "listSource": "addressesType",
                        "validators": [
                            "required"
                        ]
                    },
                    "addressline1": {
                        "type": "String",
                        "validators": [
                            "required"
                        ],
                        "placeHolderShow": true,
                        "validateRequiredIfEmpty": "addressline2",
                        "sublabel": "info.addressline1",
                        "sublabeldown": true,
                        "typeInput": "text",
                        "label": "form.addressline1"
                    },
                    "addressline2": {
                        "type": "String",
                        "placeHolderShow": true,
                        "validators": [
                            "required"
                        ],
                        "validateRequiredIfEmpty": "addressline1",
                        "sublabel": "info.addressline2",
                        "sublabeldown": true,
                        "typeInput": "text",
                        "label": "form.addressline2"
                    },
                    "addressline3": {
                        "type": "String",
                        "placeHolderShow": true,
                        "typeInput": "text",
                        "label": "form.addressline3"
                    },
                    "city_id": {
                        "type": "ID",
                        "typeInput": "selectAutocomplete",
                        "table": "city",
                        "add": true,
                        "validators": [
                            "required"
                        ],
                        "onChange": true,
                        "loadAsync": true,
                        "saveonly": "id",
                        "dbtype": "Json",
                        "label": "table.city"
                    },
                    "cp": {
                        "type": "String",
                        "placeHolderShow": true,
                        "inputSize": 7,
                        "loadAsync": true,
                        "typeInput": "selectAutocomplete",
                        "ql": "getCitiesByCp",
                        "validators": [
                            "required"
                        ],
                        "onChange": true,
                        "nameisid": true,
                        "loadMin": 5,
                        "saveonly": "id",
                        "dbtype": "Json",
                        "label": "form.cp"
                    },
                    "country": {
                        "type": "String",
                        "validators": [
                            "required"
                        ],
                        "typeInput": "selectAutocomplete",
                        "listSource": "countries",
                        "saveIdOnly": true,
                        "translateDisabledSource": true,
                        "label": "form.country"
                    }
                },
                "typeInput": "text",
                "label": "form.addresses",
                "mode": {}
            },
            "identitydocs": {
                "type": "String",
                "dbtype": "Json",
                "labelChildsShow": true,
                "placeHolderShowChildsShow": false,
                "preComponents": {
                    "components": {
                        "LinkedPersonIdentitydocs": {
                            "position": "insideUnderTitle"
                        }
                    }
                },
                "subfields": {
                    "id": {
                        "type": "ID",
                        "typeInput": "hidden",
                        "noExport": true,
                        "label": "form.id"
                    },
                    "type": {
                        "type": "String",
                        "label": "form.id_doctype",
                        "typeInput": "selectAutocomplete",
                        "listSource": "identitydocsType",
                        "validators": [
                            "required"
                        ],
                        "expWidth": 12
                    },
                    "country": {
                        "type": "String",
                        "validators": [
                            "required"
                        ],
                        "typeInput": "selectAutocomplete",
                        "listSource": "countries",
                        "saveIdOnly": true,
                        "expWidth": 5,
                        "translateDisabledSource": true,
                        "label": "form.country"
                    },
                    "number": {
                        "type": "String",
                        "label": "form.id_docnumber",
                        "validators": [
                            "required"
                        ],
                        "expWidth": 11,
                        "typeInput": "text"
                    },
                    "idexpiration": {
                        "type": "String",
                        "dbtype": "Date",
                        "expWidth": 9,
                        "typeInput": "text",
                        "label": "form.idexpiration"
                    },
                    "idiauthority": {
                        "type": "String",
                        "expWidth": 25,
                        "typeInput": "text",
                        "label": "form.idiauthority"
                    },
                    "ididate": {
                        "type": "String",
                        "dbtype": "Date",
                        "expWidth": 9,
                        "typeInput": "text",
                        "label": "form.ididate"
                    },
                    "idnameisother": {
                        "type": "Boolean",
                        "typeInput": "checkbox",
                        "inLine": true,
                        "noContainerInLine": true,
                        "onChange": true,
                        "formNewSection": true,
                        "noExport": true,
                        "width": "700px",
                        "label": "form.idnameisother"
                    },
                    "lastname": {
                        "type": "String",
                        "validators": [
                            "alphai18n"
                        ],
                        "normalize": "UpperCase",
                        "onHidden": true,
                        "formNewSection": true,
                        "expWidth": 15,
                        "typeInput": "text",
                        "label": "form.lastname"
                    },
                    "firstname": {
                        "type": "String",
                        "validators": [
                            "alphai18n"
                        ],
                        "onHidden": true,
                        "expWidth": 15,
                        "typeInput": "text",
                        "label": "form.firstname"
                    },
                    "idaddressisother": {
                        "type": "Boolean",
                        "typeInput": "checkbox",
                        "inLine": true,
                        "onChange": true,
                        "formNewSection": true,
                        "noContainerInLine": true,
                        "noExport": true,
                        "width": "700px",
                        "label": "form.idaddressisother"
                    },
                    "addressline1": {
                        "type": "String",
                        "onHidden": true,
                        "formNewSection": true,
                        "expWidth": 18,
                        "typeInput": "text",
                        "label": "form.addressline1"
                    },
                    "addressline2": {
                        "type": "String",
                        "onHidden": true,
                        "typeInput": "text",
                        "label": "form.addressline2"
                    },
                    "addressline3": {
                        "type": "String",
                        "onHidden": true,
                        "typeInput": "text",
                        "label": "form.addressline3"
                    },
                    "cp": {
                        "type": "String",
                        "inputSize": 7,
                        "onHidden": true,
                        "typeInput": "text",
                        "label": "form.cp"
                    },
                    "city": {
                        "type": "String",
                        "placeHolderShow": true,
                        "sublabel": "info.citywillnotbeadd",
                        "sublabeldown": true,
                        "onHidden": true,
                        "expWidth": 18,
                        "typeInput": "text",
                        "label": "form.city"
                    },
                    "cityid": {
                        "type": "ID",
                        "typeInput": "hidden",
                        "noExport": true,
                        "label": "form.cityid"
                    }
                },
                "typeInput": "text",
                "label": "form.identitydocs",
                "mode": {}
            },
            "notes": {
                "type": "String",
                "typeInput": "textarea",
                "nameGroup": "info",
                "formNewSection": true,
                "dbtype": "String",
                "label": "form.notes"
            },
            "counterhistory": {
                "type": "Int",
                "disabledMutation": true,
                "excludedInForm": true,
                "label": "form.crm",
                "dbtype": "Int",
                "typeInput": "text"
            },
            "person_id": {
                "type": "ID",
                "typeInput": "selectAutocomplete",
                "disabledUpdate": true,
                "inViewLink": "",
                "excludedInForm": true,
                "dbtype": "ID",
                "label": "form.person_id"
            },
            "updatedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "dbAutoUpdate": true,
                "excludedInForm": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.updatedAt"
            },
            "createdAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "dbAutoUpdate": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.createdAt"
            },
            "deletedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "disabledMutation": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.deletedAt"
            },
            "deleted": {
                "type": "Boolean",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.deleted"
            },
            "updated_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.updated_user_id"
            },
            "created_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.created_user_id"
            },
            "deleted_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.deleted_user_id"
            },
            "organizer_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "readOnlyUpdate": true,
                "disabledUpdate": true,
                "validators": [
                    "required"
                ],
                "formNewSection": true,
                "dbtype": "String",
                "table": "organizer",
                "label": "table.organizer"
            }
        },
        "listFilters": {
            "fields": {
                "organizer_id": {
                    "target": [],
                    "tableRelatedAlias": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "customerorganizer_id"
                    },
                    "table": "organizer",
                    "fieldName": "organizer_id",
                    "label": "table.organizer",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "readOnlyUpdate": true,
                        "disabledUpdate": true,
                        "validators": [
                            "required"
                        ],
                        "formNewSection": true,
                        "dbtype": "String",
                        "table": "organizer",
                        "label": "table.organizer"
                    },
                    "tableRelated": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "customerorganizer_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "numbering": {
                    "module": "DOSSIERCUSTOMER",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "numbering",
                    "target": [
                        "maincrud"
                    ],
                    "label": "form.numbering",
                    "fieldSource": {
                        "type": "String",
                        "disabled": true,
                        "disabledSystemUpdate": true,
                        "module": "DOSSIERCUSTOMER",
                        "dbtype": "String",
                        "typeInput": "text",
                        "label": "form.numbering"
                    },
                    "tableRelated": "",
                    "type": "String",
                    "typeInput": "text",
                    "listSource": ""
                },
                "lastname": {
                    "preload": true,
                    "minLength": 3,
                    "normalize": "UpperCase",
                    "sublabel": "info.filterMinLength::3",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "lastname",
                    "target": [
                        "maincrud"
                    ],
                    "label": "form.lastname",
                    "fieldSource": {
                        "type": "String",
                        "validators": [
                            "required",
                            "alphai18n",
                            "minLength:2"
                        ],
                        "normalize": "UpperCase",
                        "onDebounce": 1500,
                        "dbtype": "String",
                        "typeInput": "text",
                        "label": "form.lastname"
                    },
                    "tableRelated": "",
                    "type": "String",
                    "typeInput": "text",
                    "listSource": ""
                },
                "firstname": {
                    "preload": true,
                    "minLength": 3,
                    "anyCase": true,
                    "normalize": "StartCase",
                    "sublabel": "info.filterMinLength::3",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "firstname",
                    "target": [
                        "maincrud"
                    ],
                    "label": "form.firstname",
                    "fieldSource": {
                        "type": "String",
                        "validators": [
                            "required",
                            "alphai18n",
                            "minLength:2"
                        ],
                        "normalize": "StartCase",
                        "onDebounce": 1500,
                        "dbtype": "String",
                        "typeInput": "text",
                        "label": "form.firstname"
                    },
                    "tableRelated": "",
                    "type": "String",
                    "typeInput": "text",
                    "listSource": ""
                },
                "customerstatus_id": {
                    "tableRelatedAlias": {
                        "table": "customerstatus",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "customerstatus_id",
                        "alias": "customercustomerstatus_id"
                    },
                    "table": "customerstatus",
                    "fieldName": "customerstatus_id",
                    "target": [
                        "maincrud",
                        "listmini"
                    ],
                    "label": "table.customerstatus",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "formNewSection": true,
                        "listmini": true,
                        "onChange": true,
                        "dbtype": "String",
                        "table": "customerstatus",
                        "label": "table.customerstatus"
                    },
                    "tableRelated": {
                        "table": "customerstatus",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "customerstatus_id",
                        "alias": "customercustomerstatus_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "addresses_city_id": {
                    "fieldContainer": "addresses",
                    "subfield": "city_id",
                    "table": "city",
                    "typeInput": "selectAutocomplete",
                    "tableRelatedAlias": "city",
                    "fieldName": "addresses.city_id",
                    "target": [
                        "maincrud"
                    ],
                    "label": "table.city",
                    "fieldSource": {
                        "type": "ID",
                        "typeInput": "selectAutocomplete",
                        "table": "city",
                        "add": true,
                        "validators": [
                            "required"
                        ],
                        "onChange": true,
                        "loadAsync": true,
                        "saveonly": "id",
                        "dbtype": "Json",
                        "label": "table.city"
                    },
                    "tableRelated": "city",
                    "type": "ID",
                    "listSource": ""
                },
                "inactive": {
                    "typeInput": "radio",
                    "listOptions": [
                        {
                            "id": "false",
                            "name": "form.no"
                        },
                        {
                            "id": "true",
                            "name": "form.yes"
                        },
                        {
                            "id": "null",
                            "name": "form.all"
                        }
                    ],
                    "showLabel": true,
                    "inLine": true,
                    "defaultValue": "false",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "inactive",
                    "target": [
                        "maincrud"
                    ],
                    "label": "form.inactive",
                    "fieldSource": {
                        "type": "Boolean",
                        "sublabel": "info.inactive",
                        "sublabeldown": true,
                        "dbtype": "Boolean",
                        "typeInput": "checkbox",
                        "label": "form.inactive"
                    },
                    "tableRelated": "",
                    "type": "Boolean",
                    "listSource": ""
                },
                "nosharedata": {
                    "showLabel": true,
                    "typeInput": "radio",
                    "listOptions": [
                        {
                            "id": "false",
                            "name": "form.no"
                        },
                        {
                            "id": "true",
                            "name": "form.yes"
                        },
                        {
                            "id": "null",
                            "name": "form.all"
                        }
                    ],
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "nosharedata",
                    "target": [
                        "maincrud"
                    ],
                    "label": "form.nosharedata",
                    "fieldSource": {
                        "type": "Boolean",
                        "typeInput": "checkbox",
                        "dbtype": "Boolean",
                        "label": "form.nosharedata"
                    },
                    "tableRelated": "",
                    "type": "Boolean",
                    "listSource": ""
                },
                "nonewsletter": {
                    "showLabel": true,
                    "typeInput": "radio",
                    "listOptions": [
                        {
                            "id": "false",
                            "name": "form.no"
                        },
                        {
                            "id": "true",
                            "name": "form.yes"
                        },
                        {
                            "id": "null",
                            "name": "form.all"
                        }
                    ],
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "nonewsletter",
                    "target": [
                        "maincrud"
                    ],
                    "label": "form.nonewsletter",
                    "fieldSource": {
                        "type": "Boolean",
                        "typeInput": "checkbox",
                        "dbtype": "Boolean",
                        "label": "form.nonewsletter"
                    },
                    "tableRelated": "",
                    "type": "Boolean",
                    "listSource": ""
                },
                "id": {
                    "target": "listmini",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "id",
                    "fieldSource": {
                        "type": "ID!",
                        "listmini": true,
                        "dbtype": "ID",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "ID!",
                    "typeInput": "text",
                    "listSource": ""
                },
                "name": {
                    "target": [
                        "listmini"
                    ],
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "name",
                    "label": "form.fullname",
                    "fieldSource": {
                        "type": "String",
                        "disabledMutation": true,
                        "excludedInForm": true,
                        "listmini": true,
                        "label": "form.fullname",
                        "dbtype": "String",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "String",
                    "typeInput": "text",
                    "listSource": ""
                }
            }
        },
        "listview": {
            "fields": {
                "name": true,
                "numbering": {
                    "module": "DOSSIERCUSTOMER"
                },
                "counterhistory": {
                    "icon": "log",
                    "childTable": "customerhistory",
                    "filter": "!Empty"
                },
                "email": true,
                "addresses": {
                    "subfields": {
                        "addressline1": true,
                        "addressline2": true,
                        "addressline3": true
                    },
                    "limit": 1,
                    "joinsubfields": true
                },
                "addresses2": {
                    "field": "addresses",
                    "label": "form.city",
                    "subfields": {
                        "city_id": true
                    },
                    "limit": 1,
                    "joinsubfields": true
                },
                "phones": {
                    "type": "String",
                    "dbtype": "Json",
                    "subfields": {
                        "phone": true
                    },
                    "limit": 2,
                    "separator": "",
                    "joinsubfields": true
                },
                "nosharedata": {
                    "invisible": true
                },
                "nonewsletter": {
                    "invisible": true
                },
                "organizer_id": true
            }
        },
        "norelated": {
            "city": {
                "norelated": true,
                "table": "city",
                "fields": [
                    "name"
                ]
            }
        },
        "related": {
            "customeraddress_id": {
                "table": "customeraddress",
                "relation": "oneChild",
                "fields": [
                    "customers",
                    "addresses"
                ],
                "key": "customeraddress_id",
                "alias": "customercustomeraddress_id"
            },
            "grouped_customer_id": {
                "table": "customer",
                "label": "form.grouped_customer_id",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "grouped_customer_id",
                "alias": "customergrouped_customer_id"
            },
            "gendertype_id": {
                "table": "gendertype",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "gendertype_id",
                "listmini": true,
                "alias": "customergendertype_id"
            },
            "customerstatus_id": {
                "table": "customerstatus",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "customerstatus_id",
                "alias": "customercustomerstatus_id"
            },
            "person_id": {
                "table": "person",
                "relation": "oneChild",
                "fields": [
                    "name",
                    "email"
                ],
                "fieldsView": [
                    "*"
                ],
                "key": "person_id",
                "listmini": true,
                "alias": "customerperson_id"
            },
            "organizer_id": {
                "table": "organizer",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "organizer_id",
                "alias": "customerorganizer_id"
            },
            "updatedUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "updated_user_id",
                "qlDisable": true,
                "alias": "customerupdated_user_id"
            },
            "createdUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "created_user_id",
                "qlDisable": true,
                "alias": "customercreated_user_id"
            }
        },
        "name": {
            "singular": "customer",
            "plural": "customers",
            "singularCase": "Customer",
            "pluralCase": "Customers"
        },
        "mainfield": "name",
        "miniFields": [
            "id",
            "name",
            "email",
            "customerstatus_id",
            "customersubstatus_id",
            "mobile"
        ],
        "componentName": "CustomerView",
        "path": "/customer-view/:id"
    },
    "tour": {
        "help": {
            "fr-PELE": "https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.t0gc6h8dkoou"
        },
        "apps": [
            "phone",
            "admin"
        ],
        "triggers": true,
        "listOrder": "\"tour\".\"datestart\":ASC",
        "disableRecreateTemplateForm": true,
        "pagesettings": true,
        "uniquefields": {
            "1": [
                "name"
            ],
            "2": [
                "longname"
            ]
        },
        "listFilters": {
            "fields": {
                "organizer_id": {
                    "target": [],
                    "tableRelatedAlias": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "tourorganizer_id"
                    },
                    "table": "organizer",
                    "fieldName": "organizer_id",
                    "label": "table.organizer",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "readOnlyUpdate": true,
                        "disabledUpdate": true,
                        "validators": [
                            "required"
                        ],
                        "formNewSection": true,
                        "dbtype": "String",
                        "table": "organizer",
                        "label": "table.organizer"
                    },
                    "tableRelated": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "tourorganizer_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "name": {
                    "target": [
                        "maincrud",
                        "listmini"
                    ],
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "name",
                    "label": "form.name",
                    "fieldSource": {
                        "type": "String",
                        "validators": [
                            "required",
                            "minLength:3",
                            "maxLength:22"
                        ],
                        "onDisabled": true,
                        "sublabel": "info.tourshortname",
                        "sublabeldown": true,
                        "listmini": true,
                        "dbtype": "String",
                        "typeInput": "text",
                        "label": "form.name"
                    },
                    "tableRelated": "",
                    "type": "String",
                    "typeInput": "text",
                    "listSource": ""
                },
                "_multidate": {
                    "placeHolder": "form.selectTypeDate",
                    "uniqueField": true,
                    "multiField": {
                        "fields": {
                            "datestart": {
                                "label": "form.year",
                                "templates": [
                                    "."
                                ]
                            }
                        }
                    },
                    "formNewSection": true,
                    "typeInput": "selectBox",
                    "typeShowInput": "Date",
                    "type": "String",
                    "placeHolderShow": true,
                    "label": "form.year",
                    "translateDisabledSource": true,
                    "listOptions": [
                        {
                            "id": "",
                            "name": "form.year"
                        },
                        {
                            "id": "205",
                            "name": "form.lastYear"
                        },
                        {
                            "id": "200",
                            "name": "form.currentYear"
                        },
                        {
                            "id": "210",
                            "name": "form.nextYear"
                        }
                    ],
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "_multidate",
                    "target": [
                        "maincrud"
                    ],
                    "fieldSource": {
                        "placeHolder": "form.selectTypeDate",
                        "uniqueField": true,
                        "multiField": {
                            "fields": {
                                "datestart": {
                                    "label": "form.year",
                                    "templates": [
                                        "."
                                    ]
                                }
                            }
                        },
                        "formNewSection": true,
                        "typeInput": "selectBox",
                        "typeShowInput": "Date",
                        "type": "String",
                        "placeHolderShow": true,
                        "label": "form.year",
                        "translateDisabledSource": true,
                        "listOptions": [
                            {
                                "id": "",
                                "name": "form.year"
                            },
                            {
                                "id": "205",
                                "name": "form.lastYear"
                            },
                            {
                                "id": "200",
                                "name": "form.currentYear"
                            },
                            {
                                "id": "210",
                                "name": "form.nextYear"
                            }
                        ],
                        "tableRelatedAlias": ""
                    },
                    "tableRelated": "",
                    "listSource": ""
                },
                "inactive": {
                    "typeInput": "radio",
                    "listOptions": [
                        {
                            "id": "false",
                            "name": "form.no"
                        },
                        {
                            "id": "true",
                            "name": "form.yes"
                        },
                        {
                            "id": "null",
                            "name": "form.all"
                        }
                    ],
                    "showLabel": true,
                    "inLine": true,
                    "defaultValue": "false",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "inactive",
                    "target": [
                        "maincrud",
                        "listmini"
                    ],
                    "label": "form.inactive",
                    "fieldSource": {
                        "type": "Boolean",
                        "sublabel": "info.inactive",
                        "sublabeldown": true,
                        "listmini": true,
                        "dbtype": "Boolean",
                        "typeInput": "checkbox",
                        "label": "form.inactive"
                    },
                    "tableRelated": "",
                    "type": "Boolean",
                    "listSource": ""
                },
                "id": {
                    "target": "listmini",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "id",
                    "fieldSource": {
                        "type": "ID!",
                        "listmini": true,
                        "dbtype": "ID",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "ID!",
                    "typeInput": "text",
                    "listSource": ""
                }
            }
        },
        "fields": {
            "id": {
                "type": "ID!",
                "listmini": true,
                "dbtype": "ID",
                "typeInput": "text"
            },
            "name": {
                "type": "String",
                "validators": [
                    "required",
                    "minLength:3",
                    "maxLength:22"
                ],
                "onDisabled": true,
                "sublabel": "info.tourshortname",
                "sublabeldown": true,
                "listmini": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.name"
            },
            "longname": {
                "type": "String",
                "validators": [
                    "required",
                    "minLength:3"
                ],
                "onDisabled": true,
                "sublabel": "info.longname",
                "sublabeldown": true,
                "listmini": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.longname"
            },
            "country": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "listSource": "countries",
                "validators": [],
                "saveIdOnly": true,
                "dbtype": "String",
                "translateDisabledSource": true,
                "label": "form.country"
            },
            "description": {
                "type": "String",
                "typeInput": "textarea",
                "formNewSection": true,
                "sublabel": "info.tourdescription",
                "dbtype": "String",
                "label": "form.description"
            },
            "seatsmax": {
                "type": "Int",
                "normalize": "IntPositive",
                "onDisabled": true,
                "validators": [
                    "required",
                    "IntRange:1:2500"
                ],
                "sublabel": "info.seatsmax",
                "sublabeldown": true,
                "formNewSection": true,
                "dbtype": "Int",
                "typeInput": "text",
                "label": "form.seatsmax"
            },
            "seatsmin": {
                "type": "Int",
                "normalize": "IntPositive",
                "onDisabled": true,
                "sublabel": "info.seatsmin",
                "sublabeldown": true,
                "dbtype": "Int",
                "typeInput": "text",
                "label": "form.seatsmin"
            },
            "datestart": {
                "type": "String",
                "validators": [
                    "required",
                    "date"
                ],
                "dbtype": "Date",
                "onChange": true,
                "formNewSection": true,
                "typeInput": "text",
                "label": "form.datestart"
            },
            "dateend": {
                "type": "String",
                "validators": [
                    "required",
                    "date"
                ],
                "dbtype": "Date",
                "onChange": true,
                "typeInput": "text",
                "label": "form.dateend"
            },
            "inactive": {
                "type": "Boolean",
                "sublabel": "info.inactive",
                "sublabeldown": true,
                "listmini": true,
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.inactive"
            },
            "registrationstart": {
                "type": "String",
                "validators": [
                    "date"
                ],
                "dbtype": "Date",
                "onChange": true,
                "formNewSection": true,
                "typeInput": "text",
                "label": "form.registrationstart"
            },
            "registrationend": {
                "type": "String",
                "validators": [
                    "date"
                ],
                "dbtype": "Date",
                "onChange": true,
                "typeInput": "text",
                "label": "form.registrationend"
            },
            "tourcategory_id": {
                "type": "String",
                "label": "table.tourcategory_long",
                "typeInput": "selectAutocomplete",
                "listmini": true,
                "onChange": true,
                "preComponents": {
                    "components": {
                        "OnlineInscriptionUrl": {
                            "condition": "(this.props.formState.actionsave ==='update')",
                            "position": "outsideAfterCloseFieldsContainer"
                        }
                    }
                },
                "dbtype": "String",
                "table": "tourcategory"
            },
            "onlineregtype": {
                "type": "Int",
                "dbtype": "Int",
                "typeInput": "selectBox",
                "listSource": "onlineRegType",
                "onChange": true,
                "validators": [],
                "formNewSection": true,
                "label": "form.onlineregtype"
            },
            "gcu": {
                "type": "Int",
                "dbtype": "Int",
                "typeInput": "radio",
                "listOptions": [
                    {
                        "id": "",
                        "name": "form.bydefault"
                    },
                    {
                        "id": 1,
                        "name": "form.bytext"
                    },
                    {
                        "id": 2,
                        "name": "form.bylink"
                    }
                ],
                "styleInput": {
                    "fontSize": "17px",
                    "color": "#555555",
                    "padding": "5px 0px 5px 0px"
                },
                "showLabel": true,
                "defaultValue": "",
                "inLine": true,
                "itemShow": "row",
                "onChange": true,
                "label": "form.gcu"
            },
            "urlgcu": {
                "type": "String",
                "sectionStyle": {},
                "inLine": true,
                "noLabel": true,
                "placeHolderShow": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.urlgcu"
            },
            "textgcu": {
                "type": "String",
                "dbtype": "Json",
                "typeInput": "hidden",
                "excludedInForm": true,
                "label": "form.textgcu"
            },
            "itinerary": {
                "type": "String",
                "dbtype": "Json",
                "indexLabel": "form.day",
                "excludedInForm": true,
                "subfields": {
                    "id": {
                        "type": "ID",
                        "typeInput": "hidden",
                        "label": "form.id"
                    },
                    "title": {
                        "type": "String",
                        "validators": [
                            "minLength:3",
                            "maxLength:50"
                        ],
                        "width": "424px",
                        "typeInput": "text",
                        "label": "form.title"
                    },
                    "where": {
                        "type": "String",
                        "width": "200px",
                        "typeInput": "text",
                        "label": "form.where"
                    },
                    "description": {
                        "type": "String",
                        "typeInput": "textarea",
                        "width": "550px",
                        "styleInput": {
                            "width": "100%",
                            "maxWidth": "610px"
                        },
                        "noMainContainer": true,
                        "label": "form.description"
                    },
                    "timetable": {
                        "type": "String",
                        "dbtype": "Json",
                        "subfields": {
                            "id": {
                                "type": "ID",
                                "typeInput": "hidden"
                            },
                            "when": {
                                "type": "String",
                                "typeInput": "selectBox",
                                "label": "form.hour",
                                "width": "180px",
                                "listOptions": [
                                    {
                                        "id": "ad",
                                        "name": "form.ad"
                                    },
                                    {
                                        "id": "am",
                                        "name": "form.am"
                                    },
                                    {
                                        "id": "06",
                                        "name": "06 h"
                                    },
                                    {
                                        "id": "07",
                                        "name": "07 h"
                                    },
                                    {
                                        "id": "08",
                                        "name": "08 h"
                                    },
                                    {
                                        "id": "09",
                                        "name": "09 h"
                                    },
                                    {
                                        "id": "10",
                                        "name": "10 h"
                                    },
                                    {
                                        "id": "11",
                                        "name": "11 h"
                                    },
                                    {
                                        "id": "12",
                                        "name": "12 h"
                                    },
                                    {
                                        "id": "mi",
                                        "name": "form.mi"
                                    },
                                    {
                                        "id": "13",
                                        "name": "13 h"
                                    },
                                    {
                                        "id": "14",
                                        "name": "14 h"
                                    },
                                    {
                                        "id": "pm",
                                        "name": "form.pm"
                                    },
                                    {
                                        "id": "15",
                                        "name": "15 h"
                                    },
                                    {
                                        "id": "16",
                                        "name": "16 h"
                                    },
                                    {
                                        "id": "17",
                                        "name": "17 h"
                                    },
                                    {
                                        "id": "18",
                                        "name": "18 h"
                                    },
                                    {
                                        "id": "ni",
                                        "name": "form.ni"
                                    },
                                    {
                                        "id": "19",
                                        "name": "19 h"
                                    },
                                    {
                                        "id": "20",
                                        "name": "20 h"
                                    },
                                    {
                                        "id": "21",
                                        "name": "21 h"
                                    },
                                    {
                                        "id": "22",
                                        "name": "22 h"
                                    },
                                    {
                                        "id": "23",
                                        "name": "23 h"
                                    },
                                    {
                                        "id": "00",
                                        "name": "00 h"
                                    },
                                    {
                                        "id": "01",
                                        "name": "01 h"
                                    },
                                    {
                                        "id": "02",
                                        "name": "02 h"
                                    },
                                    {
                                        "id": "03",
                                        "name": "03 h"
                                    },
                                    {
                                        "id": "04",
                                        "name": "04 h"
                                    },
                                    {
                                        "id": "05",
                                        "name": "05 h"
                                    }
                                ],
                                "validators": [
                                    "required"
                                ],
                                "translateDisabledNumbers": true,
                                "defaultValue": ""
                            },
                            "whenminute": {
                                "type": "String",
                                "typeInput": "selectBox",
                                "width": "90px",
                                "label": "form.minute",
                                "listOptions": [
                                    {
                                        "id": "10",
                                        "name": "10'"
                                    },
                                    {
                                        "id": "15",
                                        "name": "15'"
                                    },
                                    {
                                        "id": "20",
                                        "name": "20'"
                                    },
                                    {
                                        "id": "30",
                                        "name": "30'"
                                    },
                                    {
                                        "id": "40",
                                        "name": "40'"
                                    },
                                    {
                                        "id": "45",
                                        "name": "45'"
                                    },
                                    {
                                        "id": "50",
                                        "name": "50'"
                                    },
                                    {
                                        "id": "60",
                                        "name": "60'"
                                    }
                                ],
                                "defaultValue": "",
                                "translateDisabledSource": true
                            },
                            "duration": {
                                "type": "String",
                                "typeInput": "selectBox",
                                "width": "90px",
                                "listOptions": [
                                    {
                                        "id": "15",
                                        "name": "15'"
                                    },
                                    {
                                        "id": "30",
                                        "name": "35'"
                                    },
                                    {
                                        "id": "45",
                                        "name": "45'"
                                    },
                                    {
                                        "id": "60",
                                        "name": "1h"
                                    },
                                    {
                                        "id": "90",
                                        "name": "1h 30'"
                                    },
                                    {
                                        "id": "120",
                                        "name": "2h'"
                                    },
                                    {
                                        "id": "150",
                                        "name": "2h 30'"
                                    },
                                    {
                                        "id": "180",
                                        "name": "3h'"
                                    },
                                    {
                                        "id": "240",
                                        "name": "4h'"
                                    }
                                ],
                                "defaultValue": "",
                                "translateDisabledSource": true
                            },
                            "where": {
                                "type": "String"
                            },
                            "gps": {
                                "type": "String",
                                "validators": [
                                    "gps"
                                ]
                            },
                            "description": {
                                "type": "String",
                                "typeInput": "textarea",
                                "styleInput": {
                                    "width": "100%",
                                    "maxWidth": "610px"
                                },
                                "width": "550px",
                                "noMainContainer": true
                            }
                        },
                        "typeInput": "text",
                        "label": "form.timetable"
                    }
                },
                "typeInput": "text",
                "label": "form.itinerary"
            },
            "total": {
                "type": "Float",
                "typeValue": "Price",
                "disabledMutation": true,
                "formNewSection": true,
                "preComponents": {
                    "components": {
                        "TourSummaryRegistrations": {
                            "position": "inside"
                        }
                    }
                },
                "dbtype": "Float",
                "typeInput": "text",
                "normalize": "Price",
                "label": "form.total"
            },
            "paid": {
                "type": "Float",
                "typeValue": "Price",
                "disabledMutation": true,
                "defaultValue": 0,
                "dbtype": "Float",
                "typeInput": "text",
                "normalize": "Price",
                "label": "form.paid"
            },
            "balance": {
                "type": "Float",
                "typeValue": "Price",
                "disabledMutation": true,
                "dbtype": "Float",
                "typeInput": "text",
                "normalize": "Price",
                "label": "form.balance"
            },
            "price": {
                "type": "Float",
                "typeValue": "Price",
                "validators": [],
                "listmini": true,
                "onDisabled": true,
                "formNewSection": true,
                "label": "form.baseprice",
                "sublabel": "info.baseprice",
                "sublabeldown": true,
                "formRowExpand": true,
                "dbtype": "Float",
                "typeInput": "text",
                "normalize": "Price"
            },
            "cancelledprice": {
                "type": "Float",
                "typeValue": "Price",
                "defaultValue": 0,
                "validators": [],
                "listmini": true,
                "onDisabled": true,
                "formNewSection": true,
                "label": "form.cancelledprice",
                "formRowExpand": true,
                "dbtype": "Float",
                "typeInput": "text",
                "normalize": "Price"
            },
            "acode_id": {
                "formNewSection": true,
                "module": "ACCO",
                "type": "String",
                "typeInput": "selectAutocomplete",
                "label": "form.accountingnumber",
                "listmini": true,
                "fieldDisplayedOptions": {
                    "subfields": {
                        "name": true,
                        "title": true
                    },
                    "separatorsubfield": " / ",
                    "joinsubfields": false
                },
                "dbtype": "String",
                "table": "aplan"
            },
            "acostcode_id": {
                "module": "ACCO",
                "type": "String",
                "typeInput": "selectAutocomplete",
                "label": "table.acostplan",
                "listmini": true,
                "fieldDisplayedOptions": {
                    "subfields": {
                        "name": true,
                        "title": true
                    },
                    "separatorsubfield": " / ",
                    "joinsubfields": false
                },
                "dbtype": "String",
                "table": "acostplan"
            },
            "servicesbystatus": {
                "type": "String",
                "dbtype": "Json",
                "typeInput": "hidden",
                "inputTypeObject": true,
                "label": "form.servicesbystatus"
            },
            "additionalservices": {
                "type": "String",
                "dbtype": "Json",
                "sublabel": "info.additionalServices",
                "subfields": {
                    "id": {
                        "type": "ID",
                        "typeInput": "hidden",
                        "label": "form.id"
                    },
                    "amount": {
                        "type": "Float",
                        "typeValue": "PriceNegative",
                        "onChange": true,
                        "validators": [
                            "required"
                        ],
                        "width": "200px",
                        "typeInput": "text",
                        "normalize": "PriceNegative",
                        "label": "form.amount"
                    },
                    "sinfo": {
                        "type": "String",
                        "label": "form.servicetourname",
                        "sublabel": "info.servicetourname",
                        "sublabeldown": true,
                        "validators": [
                            "required",
                            "minLength:3",
                            "maxLength:32"
                        ],
                        "validateRequiredIfEmpty": "service_id",
                        "typeInput": "text"
                    },
                    "service_id": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "table": "service",
                        "label": "form.typeservice",
                        "sublabel": "info.optionalstats",
                        "sublabeldown": true,
                        "validators": [
                            "required"
                        ],
                        "validateIfEmpty": "sinfo"
                    },
                    "typeEntry": {
                        "type": "String",
                        "typeInput": "selectBox",
                        "listSource": "typeEntry",
                        "label": "form.typeEntry"
                    },
                    "visibility": {
                        "type": "String",
                        "typeInput": "selectBox",
                        "listSource": "serviceVisibility",
                        "label": "form.visibility"
                    },
                    "onlineregtype": {
                        "type": "Boolean",
                        "typeInput": "checkbox",
                        "label": "form.onlineregtype"
                    },
                    "cancellednotzero": {
                        "type": "Boolean",
                        "typeInput": "checkbox",
                        "sublabel": "info.cancellednotzero",
                        "label": "form.cancellednotzero"
                    },
                    "options": {
                        "type": "String",
                        "dbtype": "Json",
                        "subfields": {
                            "id": {
                                "type": "ID",
                                "typeInput": "hidden"
                            },
                            "option": {
                                "type": "String",
                                "label": "form.option",
                                "validators": [
                                    "required",
                                    "minLength:3",
                                    "maxLength:32"
                                ]
                            },
                            "amount": {
                                "type": "Float",
                                "typeValue": "Price",
                                "normalize": "PriceNegative",
                                "onChange": true,
                                "width": "200px"
                            }
                        },
                        "typeInput": "text",
                        "label": "form.options"
                    }
                },
                "typeInput": "text",
                "label": "form.additionalservices",
                "mode": {}
            },
            "updatedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "dbAutoUpdate": true,
                "excludedInForm": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.updatedAt"
            },
            "createdAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "dbAutoUpdate": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.createdAt"
            },
            "deletedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "disabledMutation": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.deletedAt"
            },
            "deleted": {
                "type": "Boolean",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.deleted"
            },
            "updated_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.updated_user_id"
            },
            "created_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.created_user_id"
            },
            "deleted_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.deleted_user_id"
            },
            "organizer_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "readOnlyUpdate": true,
                "disabledUpdate": true,
                "validators": [
                    "required"
                ],
                "formNewSection": true,
                "dbtype": "String",
                "table": "organizer",
                "label": "table.organizer"
            }
        },
        "listview": {
            "fields": {
                "name": true,
                "longname": {
                    "invisible": true
                },
                "inactive": {
                    "invisible": true
                },
                "tourcategory_id": {
                    "invisible": true
                },
                "onlineregtype": {
                    "invisible": true
                },
                "country": {
                    "hiddenPhone": true
                },
                "seatsmax": true,
                "datestart": {
                    "hiddenPhone": true
                },
                "dateend": {
                    "invisible": true
                },
                "registrationstart": {
                    "invisible": true
                },
                "registrationend": {
                    "invisible": true
                },
                "price": true,
                "total": true,
                "paid": true,
                "balance": true,
                "acode_id": {
                    "invisible": true
                },
                "additionalservices": {
                    "invisible": true,
                    "subfields": {
                        "amount": true,
                        "sinfo": true,
                        "service_id": true
                    }
                },
                "organizer_id": true
            }
        },
        "related": {
            "tourcategory_id": {
                "table": "tourcategory",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "tourcategory_id",
                "alias": "tourtourcategory_id"
            },
            "acode_id": {
                "table": "aplan",
                "label": "table.aplan",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "acode_id",
                "listmini": true,
                "alias": "touracode_id"
            },
            "acostcode_id": {
                "table": "acostplan",
                "label": "table.acostplan",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "acostcode_id",
                "listmini": true,
                "alias": "touracostcode_id"
            },
            "organizer_id": {
                "table": "organizer",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "organizer_id",
                "alias": "tourorganizer_id"
            },
            "updatedUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "updated_user_id",
                "qlDisable": true,
                "alias": "tourupdated_user_id"
            },
            "createdUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "created_user_id",
                "qlDisable": true,
                "alias": "tourcreated_user_id"
            }
        },
        "toolbar": {
            "menus": {
                "filterregistration": {
                    "icon": "pele",
                    "label": "table.customers",
                    "urlType": "filter",
                    "inView": false,
                    "inList": true
                },
                "filterpayment": {
                    "icon": "monetization",
                    "label": "table.payments",
                    "urlType": "filter",
                    "inView": false,
                    "inList": true
                },
                "tourroom": {
                    "icon": "room",
                    "label": "table.tourrooms",
                    "component": "TourArrangementRoom",
                    "urlType": "child",
                    "inView": true,
                    "inList": true
                },
                "toursettingsprice": {
                    "icon": "pricelist",
                    "label": "table.tourprices",
                    "component": "TourSettingsRegistration",
                    "urlType": "link",
                    "url": "/tour-settings-registration/{id}",
                    "noChild": true,
                    "inList": true,
                    "inView": true
                },
                "transportation": {
                    "icon": "transportation",
                    "label": "table.transportations",
                    "component": "Transportation",
                    "urlType": "child",
                    "inList": true,
                    "inView": true
                },
                "duplicate": {
                    "inView": true,
                    "label": "info.duplicate",
                    "urlType": "fn"
                }
            }
        },
        "icon": "flight",
        "formToolBarBottom": true,
        "destination": [
            "serverSchema",
            "serverResolver",
            "ormModels",
            "components",
            "app",
            "menus"
        ],
        "name": {
            "singular": "tour",
            "plural": "tours",
            "singularCase": "Tour",
            "pluralCase": "Tours"
        },
        "mainfield": "name",
        "miniFields": [
            "id",
            "name",
            "longname",
            "inactive",
            "tourcategory_id",
            "price",
            "cancelledprice",
            "acode_id",
            "acostcode_id"
        ],
        "componentName": "TourView",
        "path": "/tour-view/:id"
    },
    "tourcategory": {
        "help": {
            "fr-PELE": "https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.xi4dzr4h6ilb"
        },
        "apps": [
            "phone",
            "admin"
        ],
        "triggers": true,
        "disableRecreateTemplateForm": true,
        "listOrder": "\"tourcategory\".\"name\":ASC",
        "fields": {
            "id": {
                "type": "ID!",
                "listmini": true,
                "dbtype": "ID",
                "typeInput": "text"
            },
            "name": {
                "type": "String!",
                "validators": [
                    "required",
                    "minLength:3",
                    "maxLength:22"
                ],
                "onDisabled": true,
                "preComponents": {
                    "components": {
                        "TitleRegistrationOnlinePerson": {
                            "position": "outsideAfterCloseFieldsContainer"
                        }
                    }
                },
                "listmini": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.name"
            },
            "regonline_occupation": {
                "type": "Boolean",
                "label": "form.occupation",
                "typeInput": "checkbox",
                "nameGroup": "settings",
                "formNewSection": true,
                "dbtype": "Boolean"
            },
            "regonline_birthdata": {
                "type": "Boolean",
                "typeInput": "checkbox",
                "label": "form.birthdata",
                "nameGroup": "settings",
                "dbtype": "Boolean"
            },
            "regonline_contactEmergency": {
                "type": "Boolean",
                "typeInput": "checkbox",
                "label": "contactType.Emergency",
                "nameGroup": "settings",
                "dbtype": "Boolean"
            },
            "regonline_nationality": {
                "type": "Boolean",
                "typeInput": "checkbox",
                "label": "form.nationality",
                "nameGroup": "settings",
                "dbtype": "Boolean"
            },
            "regonline_nameunmarried": {
                "type": "Boolean",
                "typeInput": "checkbox",
                "label": "form.nameunmarried",
                "nameGroup": "settings",
                "dbtype": "Boolean"
            },
            "regonline_phones": {
                "type": "Boolean",
                "label": "form.phones",
                "typeInput": "checkbox",
                "nameGroup": "settings",
                "dbtype": "Boolean"
            },
            "regonline_address": {
                "type": "Boolean",
                "label": "form.address",
                "typeInput": "checkbox",
                "nameGroup": "settings",
                "dbtype": "Boolean"
            },
            "regonline_NationalIdentity": {
                "type": "Boolean",
                "label": "identitydocsType.NationalIdentity",
                "typeInput": "checkbox",
                "nameGroup": "settings",
                "dbtype": "Boolean"
            },
            "regonline_passport": {
                "type": "Boolean",
                "label": "identitydocsType.Passport",
                "typeInput": "checkbox",
                "nameGroup": "settings",
                "dbtype": "Boolean"
            },
            "regonline_imageright": {
                "type": "Boolean",
                "label": "form.imageright",
                "typeInput": "checkbox",
                "nameGroup": "settings",
                "preComponents": {
                    "components": {
                        "TitleRegistrationOnlineTour": {
                            "position": "outsideAfterCloseFieldsContainer"
                        }
                    }
                },
                "dbtype": "Boolean"
            },
            "regonline_status": {
                "formNewSection": true,
                "type": "Boolean",
                "label": "table.customerstatus",
                "typeInput": "checkbox",
                "nameGroup": "settings",
                "dbtype": "Boolean"
            },
            "regonline_stopspoint": {
                "type": "Boolean",
                "label": "table.stopspoint",
                "typeInput": "checkbox",
                "nameGroup": "settings",
                "dbtype": "Boolean"
            },
            "regonline_room_type": {
                "type": "Boolean",
                "label": "form.room_type",
                "typeInput": "checkbox",
                "nameGroup": "settings",
                "dbtype": "Boolean"
            },
            "regonline_room_share": {
                "type": "Boolean",
                "label": "form.room_shared",
                "typeInput": "checkbox",
                "nameGroup": "settings",
                "dbtype": "Boolean"
            },
            "settings": {
                "type": "String",
                "dbtype": "Json",
                "fatherGroup": true,
                "typeInput": "text",
                "label": "form.settings"
            },
            "filterbycustomerstatus": {
                "type": "Int",
                "typeInput": "checkbox",
                "onChange": true,
                "dbtype": "Int",
                "label": "form.filterbycustomerstatus"
            },
            "filteredcustomerstatuss": {
                "type": "String",
                "dbtype": "Json",
                "typeInput": "hidden",
                "label": "table.customerstatus"
            },
            "updatedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "dbAutoUpdate": true,
                "excludedInForm": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.updatedAt"
            },
            "createdAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "dbAutoUpdate": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.createdAt"
            },
            "deletedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "disabledMutation": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.deletedAt"
            },
            "deleted": {
                "type": "Boolean",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.deleted"
            },
            "updated_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.updated_user_id"
            },
            "created_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.created_user_id"
            },
            "deleted_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.deleted_user_id"
            },
            "organizer_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "readOnlyUpdate": true,
                "disabledUpdate": true,
                "validators": [
                    "required"
                ],
                "formNewSection": true,
                "dbtype": "String",
                "table": "organizer",
                "label": "table.organizer"
            }
        },
        "listview": {
            "fields": {
                "name": true,
                "organizer_id": true
            }
        },
        "icon": "tourcategory",
        "destination": [
            "serverSchema",
            "serverResolver",
            "ormModels",
            "components",
            "app",
            "menus"
        ],
        "name": {
            "singular": "tourcategory",
            "plural": "tourcategorys",
            "singularCase": "Tourcategory",
            "pluralCase": "Tourcategorys"
        },
        "mainfield": "name",
        "related": {
            "organizer_id": {
                "table": "organizer",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "organizer_id",
                "alias": "tourcategoryorganizer_id"
            },
            "updatedUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "updated_user_id",
                "qlDisable": true,
                "alias": "tourcategoryupdated_user_id"
            },
            "createdUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "created_user_id",
                "qlDisable": true,
                "alias": "tourcategorycreated_user_id"
            }
        },
        "miniFields": [
            "id",
            "name"
        ],
        "listFilters": {
            "fields": {
                "organizer_id": {
                    "target": [],
                    "tableRelatedAlias": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "tourcategoryorganizer_id"
                    },
                    "table": "organizer",
                    "fieldName": "organizer_id",
                    "label": "table.organizer",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "readOnlyUpdate": true,
                        "disabledUpdate": true,
                        "validators": [
                            "required"
                        ],
                        "formNewSection": true,
                        "dbtype": "String",
                        "table": "organizer",
                        "label": "table.organizer"
                    },
                    "tableRelated": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "tourcategoryorganizer_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "id": {
                    "target": "listmini",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "id",
                    "fieldSource": {
                        "type": "ID!",
                        "listmini": true,
                        "dbtype": "ID",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "ID!",
                    "typeInput": "text",
                    "listSource": ""
                },
                "name": {
                    "target": [
                        "listmini"
                    ],
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "name",
                    "label": "form.name",
                    "fieldSource": {
                        "type": "String!",
                        "validators": [
                            "required",
                            "minLength:3",
                            "maxLength:22"
                        ],
                        "onDisabled": true,
                        "preComponents": {
                            "components": {
                                "TitleRegistrationOnlinePerson": {
                                    "position": "outsideAfterCloseFieldsContainer"
                                }
                            }
                        },
                        "listmini": true,
                        "dbtype": "String",
                        "typeInput": "text",
                        "label": "form.name"
                    },
                    "tableRelated": "",
                    "type": "String!",
                    "typeInput": "text",
                    "listSource": ""
                }
            }
        },
        "componentName": "TourcategoryView",
        "path": "/tourcategory-view/:id"
    },
    "transportation": {
        "help": {
            "fr-PELE": "https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.u8eg39hqkk6r"
        },
        "triggers": true,
        "icon": "transportation",
        "parentTable": "tour",
        "parentRelationField": "tour_id",
        "formToolBarBottom": true,
        "toolbar": {
            "menus": {}
        },
        "fields": {
            "id": {
                "type": "ID!",
                "listmini": true,
                "dbtype": "ID",
                "typeInput": "text"
            },
            "tour_id": {
                "type": "String",
                "typeInput": "hidden",
                "listmini": true,
                "dbtype": "String",
                "label": "form.tour_id"
            },
            "name": {
                "type": "String",
                "validators": [
                    "required",
                    "minLength:3",
                    "maxLength:40"
                ],
                "sublabel": "info.transportationname",
                "sublabeldown": true,
                "listmini": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.name"
            },
            "seats": {
                "type": "Int",
                "validators": [],
                "normalize": "IntPositive",
                "onDisabled": true,
                "dbtype": "Int",
                "typeInput": "text",
                "label": "form.seats"
            },
            "price": {
                "type": "Float",
                "typeValue": "Price",
                "listmini": true,
                "sublabel": "info.priceaddbase",
                "sublabeldown": true,
                "dbtype": "Float",
                "typeInput": "text",
                "normalize": "Price",
                "label": "form.price"
            },
            "driverinfo": {
                "type": "String",
                "typeInput": "textarea",
                "formNewSection": true,
                "dbtype": "String",
                "label": "form.driverinfo"
            },
            "responsibleinfo": {
                "type": "String",
                "typeInput": "textarea",
                "formNewSection": true,
                "dbtype": "String",
                "label": "form.responsibleinfo"
            },
            "routeplanner": {
                "type": "String",
                "dbtype": "Json",
                "sublabel": "info.routeplanner",
                "listmini": true,
                "subfields": {
                    "id": {
                        "type": "ID",
                        "typeInput": "hidden",
                        "label": "form.id"
                    },
                    "from_stopspoint_id": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "table": "stopspoint",
                        "sublabel": "",
                        "sublabeldown": true,
                        "validators": [
                            "required"
                        ],
                        "label": "table.stopspoint"
                    },
                    "sinfo": {
                        "type": "String",
                        "sublabel": "info.stoppoint",
                        "sublabeldown": true,
                        "typeInput": "text",
                        "label": "form.sinfo"
                    }
                },
                "typeInput": "text",
                "label": "form.routeplanner",
                "mode": {}
            },
            "seatingconfiguration": {
                "type": "String",
                "dbtype": "Json",
                "listmini": true,
                "subfields": {
                    "id": {
                        "type": "ID",
                        "typeInput": "hidden",
                        "label": "form.id"
                    },
                    "wagon": {
                        "type": "String",
                        "validators": [
                            "required"
                        ],
                        "typeInput": "text",
                        "label": "form.wagon"
                    },
                    "otherinfo": {
                        "type": "String",
                        "typeInput": "text",
                        "label": "form.otherinfo"
                    },
                    "seatingrange": {
                        "type": "String",
                        "dbtype": "Json",
                        "subfields": {
                            "id": {
                                "type": "ID",
                                "typeInput": "hidden"
                            },
                            "range": {
                                "type": "String",
                                "onChange": true,
                                "validators": [
                                    "required"
                                ]
                            },
                            "seats": {
                                "type": "String",
                                "typeInput": "hidden"
                            },
                            "seatingType": {
                                "type": "String",
                                "typeInput": "selectBox",
                                "listSource": "seatingType"
                            },
                            "seatingfloor": {
                                "type": "String",
                                "typeInput": "selectBox",
                                "listOptions": [
                                    {
                                        "id": "B",
                                        "name": "form.down"
                                    },
                                    {
                                        "id": "H",
                                        "name": "form.up"
                                    }
                                ],
                                "defaultValue": ""
                            },
                            "otherinfo": {
                                "type": "String"
                            }
                        },
                        "typeInput": "text",
                        "label": "form.seatingrange"
                    }
                },
                "typeInput": "text",
                "label": "form.seatingconfiguration",
                "mode": {}
            },
            "bookings": {
                "type": "String",
                "dbtype": "Json",
                "typeInput": "hidden",
                "label": "table.roombooking"
            },
            "updatedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "dbAutoUpdate": true,
                "excludedInForm": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.updatedAt"
            },
            "createdAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "dbAutoUpdate": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.createdAt"
            },
            "deletedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "disabledMutation": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.deletedAt"
            },
            "deleted": {
                "type": "Boolean",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.deleted"
            },
            "updated_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.updated_user_id"
            },
            "created_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.created_user_id"
            },
            "deleted_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.deleted_user_id"
            },
            "organizer_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "readOnlyUpdate": true,
                "disabledUpdate": true,
                "validators": [
                    "required"
                ],
                "formNewSection": true,
                "dbtype": "String",
                "table": "organizer",
                "label": "table.organizer"
            }
        },
        "listview": {
            "fields": {
                "name": true,
                "seats": true,
                "price": true,
                "organizer_id": true
            }
        },
        "destination": [
            "serverSchema",
            "serverResolver",
            "ormModels",
            "components",
            "app"
        ],
        "related": {
            "tour_id": {
                "table": "tour",
                "relation": "oneChild",
                "fields": [
                    "name",
                    "seatsmax"
                ],
                "key": "tour_id",
                "isParent": true,
                "alias": "transportationtour_id"
            },
            "organizer_id": {
                "table": "organizer",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "organizer_id",
                "alias": "transportationorganizer_id"
            },
            "updatedUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "updated_user_id",
                "qlDisable": true,
                "alias": "transportationupdated_user_id"
            },
            "createdUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "created_user_id",
                "qlDisable": true,
                "alias": "transportationcreated_user_id"
            }
        },
        "name": {
            "singular": "transportation",
            "plural": "transportations",
            "singularCase": "Transportation",
            "pluralCase": "Transportations"
        },
        "mainfield": "name",
        "miniFields": [
            "id",
            "tour_id",
            "name",
            "price",
            "routeplanner",
            "seatingconfiguration"
        ],
        "listFilters": {
            "fields": {
                "organizer_id": {
                    "target": [],
                    "tableRelatedAlias": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "transportationorganizer_id"
                    },
                    "table": "organizer",
                    "fieldName": "organizer_id",
                    "label": "table.organizer",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "readOnlyUpdate": true,
                        "disabledUpdate": true,
                        "validators": [
                            "required"
                        ],
                        "formNewSection": true,
                        "dbtype": "String",
                        "table": "organizer",
                        "label": "table.organizer"
                    },
                    "tableRelated": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "transportationorganizer_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "tour_id": {
                    "tableRelatedAlias": {
                        "table": "tour",
                        "relation": "oneChild",
                        "fields": [
                            "name",
                            "seatsmax"
                        ],
                        "key": "tour_id",
                        "isParent": true,
                        "alias": "transportationtour_id"
                    },
                    "table": "tour",
                    "fieldName": "tour_id",
                    "target": [
                        "maincrud",
                        "listmini"
                    ],
                    "label": "form.tour_id",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "hidden",
                        "listmini": true,
                        "dbtype": "String",
                        "label": "form.tour_id"
                    },
                    "tableRelated": {
                        "table": "tour",
                        "relation": "oneChild",
                        "fields": [
                            "name",
                            "seatsmax"
                        ],
                        "key": "tour_id",
                        "isParent": true,
                        "alias": "transportationtour_id"
                    },
                    "type": "String",
                    "typeInput": "hidden",
                    "listSource": ""
                },
                "id": {
                    "target": "listmini",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "id",
                    "fieldSource": {
                        "type": "ID!",
                        "listmini": true,
                        "dbtype": "ID",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "ID!",
                    "typeInput": "text",
                    "listSource": ""
                },
                "name": {
                    "target": [
                        "listmini"
                    ],
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "name",
                    "label": "form.name",
                    "fieldSource": {
                        "type": "String",
                        "validators": [
                            "required",
                            "minLength:3",
                            "maxLength:40"
                        ],
                        "sublabel": "info.transportationname",
                        "sublabeldown": true,
                        "listmini": true,
                        "dbtype": "String",
                        "typeInput": "text",
                        "label": "form.name"
                    },
                    "tableRelated": "",
                    "type": "String",
                    "typeInput": "text",
                    "listSource": ""
                }
            }
        },
        "componentName": "TransportationView",
        "path": "/tour-child/:parentid/transportation-view/:id"
    },
    "tourprice": {
        "help": {
            "fr-PELE": "https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.4qemdobz21io"
        },
        "icon": "pricelist",
        "parentTable": "tour",
        "parentRelationField": "tour_id",
        "uniquefields": {
            "1": [
                "customerstatus_id",
                "tour_id"
            ]
        },
        "fields": {
            "id": {
                "type": "ID!",
                "listmini": true,
                "dbtype": "ID",
                "typeInput": "text"
            },
            "customerstatus_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "formNewSection": true,
                "disabledUpdate": true,
                "listmini": true,
                "dbtype": "String",
                "table": "customerstatus",
                "label": "table.customerstatus"
            },
            "tour_id": {
                "type": "String",
                "typeInput": "hidden",
                "listmini": true,
                "dbtype": "String",
                "label": "form.tour_id"
            },
            "price": {
                "type": "Float",
                "typeValue": "Price",
                "listmini": true,
                "label": "form.baseprice",
                "sublabel": "info.tourpricebaseprice",
                "sublabeldown": true,
                "dbtype": "Float",
                "typeInput": "text",
                "normalize": "Price"
            },
            "substatuss": {
                "type": "String",
                "dbtype": "Json",
                "typeInput": "hidden",
                "label": "form.substatus"
            },
            "notes": {
                "type": "String",
                "typeInput": "textarea",
                "formNewSection": true,
                "dbtype": "String",
                "label": "form.notes"
            },
            "updatedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "dbAutoUpdate": true,
                "excludedInForm": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.updatedAt"
            },
            "createdAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "dbAutoUpdate": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.createdAt"
            },
            "deletedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "disabledMutation": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.deletedAt"
            },
            "deleted": {
                "type": "Boolean",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.deleted"
            },
            "updated_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.updated_user_id"
            },
            "created_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.created_user_id"
            },
            "deleted_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.deleted_user_id"
            },
            "organizer_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "readOnlyUpdate": true,
                "disabledUpdate": true,
                "validators": [
                    "required"
                ],
                "formNewSection": true,
                "dbtype": "String",
                "table": "organizer",
                "label": "table.organizer"
            }
        },
        "listview": {
            "fields": {
                "customerstatus_id": true,
                "price": true,
                "organizer_id": true
            }
        },
        "destination": [
            "serverSchema",
            "serverResolver",
            "ormModels",
            "components",
            "app"
        ],
        "related": {
            "customerstatus_id": {
                "table": "customerstatus",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "customerstatus_id",
                "alias": "tourpricecustomerstatus_id"
            },
            "tour_id": {
                "table": "tour",
                "relation": "oneChild",
                "fields": [
                    "name",
                    "seatsmax"
                ],
                "key": "tour_id",
                "isParent": true,
                "alias": "tourpricetour_id"
            },
            "organizer_id": {
                "table": "organizer",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "organizer_id",
                "alias": "tourpriceorganizer_id"
            },
            "updatedUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "updated_user_id",
                "qlDisable": true,
                "alias": "tourpriceupdated_user_id"
            },
            "createdUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "created_user_id",
                "qlDisable": true,
                "alias": "tourpricecreated_user_id"
            }
        },
        "name": {
            "singular": "tourprice",
            "plural": "tourprices",
            "singularCase": "Tourprice",
            "pluralCase": "Tourprices"
        },
        "mainfield": "customerstatus_id",
        "miniFields": [
            "id",
            "customerstatus_id",
            "tour_id",
            "price"
        ],
        "listFilters": {
            "fields": {
                "organizer_id": {
                    "target": [],
                    "tableRelatedAlias": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "tourpriceorganizer_id"
                    },
                    "table": "organizer",
                    "fieldName": "organizer_id",
                    "label": "table.organizer",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "readOnlyUpdate": true,
                        "disabledUpdate": true,
                        "validators": [
                            "required"
                        ],
                        "formNewSection": true,
                        "dbtype": "String",
                        "table": "organizer",
                        "label": "table.organizer"
                    },
                    "tableRelated": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "tourpriceorganizer_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "tour_id": {
                    "tableRelatedAlias": {
                        "table": "tour",
                        "relation": "oneChild",
                        "fields": [
                            "name",
                            "seatsmax"
                        ],
                        "key": "tour_id",
                        "isParent": true,
                        "alias": "tourpricetour_id"
                    },
                    "table": "tour",
                    "fieldName": "tour_id",
                    "target": [
                        "maincrud",
                        "listmini"
                    ],
                    "label": "form.tour_id",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "hidden",
                        "listmini": true,
                        "dbtype": "String",
                        "label": "form.tour_id"
                    },
                    "tableRelated": {
                        "table": "tour",
                        "relation": "oneChild",
                        "fields": [
                            "name",
                            "seatsmax"
                        ],
                        "key": "tour_id",
                        "isParent": true,
                        "alias": "tourpricetour_id"
                    },
                    "type": "String",
                    "typeInput": "hidden",
                    "listSource": ""
                },
                "id": {
                    "target": "listmini",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "id",
                    "fieldSource": {
                        "type": "ID!",
                        "listmini": true,
                        "dbtype": "ID",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "ID!",
                    "typeInput": "text",
                    "listSource": ""
                }
            }
        },
        "componentName": "TourpriceView",
        "path": "/tour-child/:parentid/tourprice-view/:id"
    },
    "tourroom": {
        "help": {
            "fr-PELE": "https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.i3jzvapxpdot"
        },
        "logFields": {
            "hotel_id": true,
            "room_type": true
        },
        "orders": {
            "noOptionBlank": true,
            "defaultValue": "hotel_id",
            "label": "form.order",
            "inLine": true,
            "fields": {
                "hotel_id": {
                    "label": "table.hotel"
                },
                "room_type": {},
                "updatedAt": {
                    "label": "form.updateAtDesc",
                    "order": "DESC"
                },
                "createdAt": {}
            }
        },
        "triggers": true,
        "icon": "room",
        "destination": [
            "serverSchema",
            "serverResolver",
            "ormModels",
            "components",
            "app"
        ],
        "parentTable": "tour",
        "parentRelationField": "tour_id",
        "disableRecreateTemplateView": true,
        "toolbar": {
            "menus": {
                "roombooking": {
                    "icon": "bed",
                    "label": "table.roombookings",
                    "component": "TourroomBooking",
                    "twinPath": "booking",
                    "inList": true,
                    "inView": true
                }
            }
        },
        "fields": {
            "id": {
                "type": "ID!",
                "listmini": true,
                "dbtype": "ID",
                "typeInput": "text"
            },
            "hotel_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "validators": [
                    "required"
                ],
                "listmini": true,
                "onChange": true,
                "dbtype": "String",
                "table": "hotel",
                "label": "table.hotel"
            },
            "room_type": {
                "type": "Int",
                "dbtype": "Int",
                "typeInput": "selectBox",
                "listSource": "roomBase",
                "validators": [
                    "required"
                ],
                "listmini": true,
                "onChange": true,
                "label": "form.room_type"
            },
            "name": {
                "type": "String",
                "label": "form.nameadded",
                "onChange": true,
                "listmini": true,
                "dbtype": "String",
                "typeInput": "text"
            },
            "tour_id": {
                "type": "String",
                "typeInput": "hidden",
                "listmini": true,
                "onChange": true,
                "dbtype": "String",
                "label": "form.tour_id"
            },
            "_name": {
                "type": "String",
                "disabledAdd": true,
                "disabledUpdate": true,
                "virtual": true,
                "label": "form.name",
                "sublabel": "info.toorroom_name",
                "sublabeldown": true,
                "formNewSection": true,
                "dbtype": "String",
                "typeInput": "text"
            },
            "isfullname": {
                "type": "Boolean",
                "label": "form.roomisfullname",
                "listmini": true,
                "onChange": true,
                "dbtype": "Boolean",
                "typeInput": "checkbox"
            },
            "room_quantity": {
                "type": "Int",
                "validators": [
                    "IntRange:0:100"
                ],
                "normalize": "IntPositive",
                "onChange": true,
                "formNewSection": true,
                "dbtype": "Int",
                "typeInput": "text",
                "label": "form.room_quantity"
            },
            "people_capacity": {
                "type": "Int",
                "disabledAdd": true,
                "disabledUpdate": true,
                "dbtype": "Int",
                "typeInput": "text",
                "label": "form.people_capacity"
            },
            "people_registered": {
                "type": "Int",
                "disabledAdd": true,
                "disabledUpdate": true,
                "dbtype": "Int",
                "typeInput": "text",
                "label": "form.people_registered"
            },
            "people_pre_registered": {
                "type": "Int",
                "disabledAdd": true,
                "disabledUpdate": true,
                "dbtype": "Int",
                "typeInput": "text",
                "label": "form.people_pre_registered"
            },
            "people_available": {
                "type": "Int",
                "disabledAdd": true,
                "disabledUpdate": true,
                "formNewSection": true,
                "listmini": true,
                "dbtype": "Int",
                "typeInput": "text",
                "label": "form.people_available"
            },
            "people_allocated": {
                "formNewSection": true,
                "type": "Int",
                "disabledAdd": true,
                "disabledUpdate": true,
                "dbtype": "Int",
                "typeInput": "text",
                "label": "form.people_allocated"
            },
            "people_to_allocate": {
                "type": "Int",
                "disabledAdd": true,
                "disabledUpdate": true,
                "dbtype": "Int",
                "typeInput": "text",
                "label": "form.people_to_allocate"
            },
            "priceroom": {
                "type": "Float",
                "typeValue": "Price",
                "sublabel": "info.priceaddbase",
                "sublabeldown": true,
                "listmini": true,
                "formNewSection": true,
                "onChange": true,
                "dbtype": "Float",
                "typeInput": "text",
                "normalize": "Price",
                "label": "form.priceroom"
            },
            "bookings": {
                "type": "String",
                "dbtype": "Json",
                "typeInput": "hidden",
                "label": "table.seatbooking"
            },
            "customers": {
                "type": "String",
                "dbtype": "Json",
                "typeInput": "hidden",
                "label": "form.customers"
            },
            "room_numbers": {
                "type": "String",
                "dbtype": "Json",
                "typeInput": "hidden",
                "label": "form.room_numbers"
            },
            "updatedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "dbAutoUpdate": true,
                "excludedInForm": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.updatedAt"
            },
            "createdAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "dbAutoUpdate": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.createdAt"
            },
            "deletedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "disabledMutation": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.deletedAt"
            },
            "deleted": {
                "type": "Boolean",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.deleted"
            },
            "updated_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.updated_user_id"
            },
            "created_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.created_user_id"
            },
            "deleted_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.deleted_user_id"
            },
            "organizer_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "readOnlyUpdate": true,
                "disabledUpdate": true,
                "validators": [
                    "required"
                ],
                "formNewSection": true,
                "dbtype": "String",
                "table": "organizer",
                "label": "table.organizer"
            }
        },
        "listview": {
            "fields": {
                "room_type": true,
                "hotel_id": true,
                "priceroom": true,
                "name": true,
                "room_quantity": true,
                "people_capacity": true,
                "people_registered": true,
                "people_pre_registered": true,
                "people_available": true,
                "people_allocated": true,
                "people_to_allocate": true,
                "organizer_id": true
            }
        },
        "related": {
            "tour_id": {
                "table": "tour",
                "relation": "oneChild",
                "listmini": true,
                "fields": [
                    "name"
                ],
                "key": "tour_id",
                "isParent": true,
                "alias": "tourroomtour_id"
            },
            "hotel_id": {
                "table": "hotel",
                "relation": "oneChild",
                "listmini": true,
                "fields": [
                    "name"
                ],
                "key": "hotel_id",
                "alias": "tourroomhotel_id"
            },
            "organizer_id": {
                "table": "organizer",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "organizer_id",
                "alias": "tourroomorganizer_id"
            },
            "updatedUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "updated_user_id",
                "qlDisable": true,
                "alias": "tourroomupdated_user_id"
            },
            "createdUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "created_user_id",
                "qlDisable": true,
                "alias": "tourroomcreated_user_id"
            }
        },
        "name": {
            "singular": "tourroom",
            "plural": "tourrooms",
            "singularCase": "Tourroom",
            "pluralCase": "Tourrooms"
        },
        "mainfield": "name",
        "miniFields": [
            "id",
            "hotel_id",
            "room_type",
            "name",
            "tour_id",
            "isfullname",
            "people_available",
            "priceroom"
        ],
        "listFilters": {
            "fields": {
                "organizer_id": {
                    "target": [],
                    "tableRelatedAlias": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "tourroomorganizer_id"
                    },
                    "table": "organizer",
                    "fieldName": "organizer_id",
                    "label": "table.organizer",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "readOnlyUpdate": true,
                        "disabledUpdate": true,
                        "validators": [
                            "required"
                        ],
                        "formNewSection": true,
                        "dbtype": "String",
                        "table": "organizer",
                        "label": "table.organizer"
                    },
                    "tableRelated": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "tourroomorganizer_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "tour_id": {
                    "tableRelatedAlias": {
                        "table": "tour",
                        "relation": "oneChild",
                        "listmini": true,
                        "fields": [
                            "name"
                        ],
                        "key": "tour_id",
                        "isParent": true,
                        "alias": "tourroomtour_id"
                    },
                    "table": "tour",
                    "fieldName": "tour_id",
                    "target": [
                        "maincrud",
                        "listmini"
                    ],
                    "label": "form.tour_id",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "hidden",
                        "listmini": true,
                        "onChange": true,
                        "dbtype": "String",
                        "label": "form.tour_id"
                    },
                    "tableRelated": {
                        "table": "tour",
                        "relation": "oneChild",
                        "listmini": true,
                        "fields": [
                            "name"
                        ],
                        "key": "tour_id",
                        "isParent": true,
                        "alias": "tourroomtour_id"
                    },
                    "type": "String",
                    "typeInput": "hidden",
                    "listSource": ""
                },
                "id": {
                    "target": "listmini",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "id",
                    "fieldSource": {
                        "type": "ID!",
                        "listmini": true,
                        "dbtype": "ID",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "ID!",
                    "typeInput": "text",
                    "listSource": ""
                },
                "name": {
                    "target": [
                        "listmini"
                    ],
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "name",
                    "label": "form.nameadded",
                    "fieldSource": {
                        "type": "String",
                        "label": "form.nameadded",
                        "onChange": true,
                        "listmini": true,
                        "dbtype": "String",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "String",
                    "typeInput": "text",
                    "listSource": ""
                }
            }
        },
        "componentName": "TourroomView",
        "path": "/tour-child/:parentid/tourroom-view/:id"
    },
    "regonline": {
        "triggers": true,
        "help": {
            "fr-PELE": "https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.9kdeszs5gucv"
        },
        "formToolBarBottom": true,
        "icon": "tablet",
        "disabledUpdate": false,
        "disabledAdd": true,
        "disableRecreateTemplateView": true,
        "disableRecreateTemplateForm": true,
        "disableRecreateTemplateList": true,
        "relatedRetrieveId": true,
        "orders": {
            "noOptionBlank": true,
            "label": "form.order",
            "inLine": true,
            "defaultValue": "updatedAt",
            "fields": {
                "name": {
                    "mainRelated": "regonline",
                    "subfields": [
                        {
                            "related": "person_id",
                            "field": "name"
                        }
                    ]
                },
                "updatedAt": {
                    "label": "form.updateAtDesc",
                    "order": "DESC"
                },
                "createdAt": {}
            }
        },
        "afterAddGoView": true,
        "preloadHeight": "500px",
        "detail": {
            "person_id": true,
            "tour_id": true
        },
        "logFields": {
            "person_id": true,
            "tour_id": true
        },
        "uniquefields": {
            "1": [
                "person_id",
                "tour_id"
            ]
        },
        "fields": {
            "id": {
                "type": "ID!",
                "listmini": true,
                "dbtype": "ID",
                "typeInput": "text"
            },
            "customer_id": {
                "type": "ID",
                "typeInput": "selectAutocomplete",
                "onChange": true,
                "inViewLink": "",
                "dbtype": "Json",
                "table": "customer",
                "loadAsync": true,
                "saveonly": "id",
                "label": "table.customer"
            },
            "person_id": {
                "type": "ID",
                "typeInput": "selectAutocomplete",
                "disabledUpdate": true,
                "inViewLink": "",
                "dbtype": "Json",
                "table": "person",
                "loadAsync": true,
                "saveonly": "id",
                "label": "table.person"
            },
            "tour_id": {
                "type": "ID",
                "typeInput": "selectAutocomplete",
                "disabledUpdate": true,
                "onChange": true,
                "inViewLink": "",
                "dbtype": "ID",
                "table": "tour",
                "label": "table.tour"
            },
            "registration_id": {
                "type": "ID",
                "typeInput": "selectAutocomplete",
                "disabledUpdate": true,
                "onChange": true,
                "inViewLink": "",
                "excludedInForm": true,
                "dbtype": "ID",
                "label": "form.registration_id"
            },
            "customerstatus_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "formNewSection": true,
                "onChange": true,
                "dbtype": "String",
                "table": "customerstatus",
                "label": "table.customerstatus"
            },
            "customersubstatus_id": {
                "label": "form.substatus",
                "type": "String",
                "typeInput": "selectAutocomplete",
                "stateListOptions": true,
                "formNewSection": true,
                "onChange": true,
                "dbtype": "String"
            },
            "dateregistration": {
                "type": "String",
                "dbtype": "Date",
                "disabled": true,
                "typeInput": "text",
                "validators": [
                    "date"
                ],
                "label": "form.dateregistration"
            },
            "tourroom_id": {
                "type": "String",
                "typeInput": "selectBox",
                "disabledUpdate": true,
                "dbtype": "String",
                "table": "tourroom",
                "label": "table.tourroom"
            },
            "room_type": {
                "type": "Int",
                "dbtype": "Int",
                "typeInput": "selectBox",
                "disabledUpdate": true,
                "listSource": "roomBase",
                "listmini": true,
                "onChange": true,
                "label": "form.room_type"
            },
            "room_shared": {
                "type": "String",
                "disabledUpdate": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.room_shared"
            },
            "stopspoint_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "table": "stopspoint",
                "disabledUpdate": true,
                "sublabel": "",
                "sublabeldown": true,
                "dbtype": "String",
                "label": "table.stopspoint"
            },
            "items": {
                "type": "String",
                "dbtype": "Json",
                "typeInput": "hidden",
                "label": "form.items"
            },
            "objects": {
                "type": "String",
                "dbtype": "Json",
                "typeInput": "hidden",
                "inputTypeObject": true,
                "label": "form.objects"
            },
            "price": {
                "type": "Float",
                "typeValue": "Price",
                "disabledUpdate": true,
                "disabledAdd": true,
                "dbtype": "Float",
                "typeInput": "text",
                "normalize": "Price",
                "label": "form.price"
            },
            "partialpayment": {
                "type": "Float",
                "typeValue": "Price",
                "sublabel": "info.partialpayment",
                "sublabeldown": true,
                "dbtype": "Float",
                "typeInput": "text",
                "normalize": "Price",
                "label": "form.partialpayment"
            },
            "registrationstatus_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "onChange": true,
                "dbtype": "String",
                "table": "registrationstatus",
                "label": "table.registrationstatus"
            },
            "signature": {
                "type": "String",
                "typeInput": "hidden",
                "disabledSystemUpdate": true,
                "dbtype": "String",
                "label": "form.signature"
            },
            "paid": {
                "type": "Float",
                "typeValue": "Price",
                "disabledMutation": true,
                "defaultValue": 0,
                "dbtype": "Float",
                "typeInput": "text",
                "normalize": "Price",
                "label": "form.paid"
            },
            "payinput": {
                "type": "String",
                "dbtype": "Json",
                "typeInput": "hidden",
                "label": "form.payinput"
            },
            "payoutput": {
                "type": "String",
                "dbtype": "Json",
                "typeInput": "hidden",
                "label": "form.payoutput"
            },
            "paymethod": {
                "type": "Int",
                "dbtype": "Int",
                "typeInput": "hidden",
                "label": "form.paymethod"
            },
            "state": {
                "type": "Int",
                "dbtype": "Int",
                "typeInput": "selectBox",
                "listSource": "processRegonline",
                "listmini": true,
                "disabledUpdate": true,
                "disabledOnAdd": true,
                "defaultValue": 1,
                "label": "form.state"
            },
            "notes": {
                "type": "String",
                "typeInput": "textarea",
                "formNewSection": true,
                "disabledUpdate": true,
                "dbtype": "String",
                "label": "form.notes"
            },
            "person_groupid": {
                "type": "String",
                "typeInput": "text",
                "excludedInForm": true,
                "label": "form.person_groupid",
                "dbtype": "String"
            },
            "modetest": {
                "type": "Boolean",
                "disabledUpdate": true,
                "disabledAdd": true,
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.modetest"
            },
            "updatedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "dbAutoUpdate": true,
                "excludedInForm": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.updatedAt"
            },
            "createdAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "dbAutoUpdate": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.createdAt"
            },
            "deletedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "disabledMutation": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.deletedAt"
            },
            "deleted": {
                "type": "Boolean",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.deleted"
            },
            "updated_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.updated_user_id"
            },
            "created_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.created_user_id"
            },
            "deleted_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.deleted_user_id"
            },
            "organizer_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "readOnlyUpdate": true,
                "disabledUpdate": true,
                "validators": [
                    "required"
                ],
                "formNewSection": true,
                "dbtype": "String",
                "table": "organizer",
                "label": "table.organizer"
            }
        },
        "toolbar": {
            "menus": {}
        },
        "listFilters": {
            "fields": {
                "organizer_id": {
                    "target": [],
                    "tableRelatedAlias": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name",
                            "id"
                        ],
                        "key": "organizer_id",
                        "alias": "regonlineorganizer_id"
                    },
                    "table": "organizer",
                    "fieldName": "organizer_id",
                    "label": "table.organizer",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "readOnlyUpdate": true,
                        "disabledUpdate": true,
                        "validators": [
                            "required"
                        ],
                        "formNewSection": true,
                        "dbtype": "String",
                        "table": "organizer",
                        "label": "table.organizer"
                    },
                    "tableRelated": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name",
                            "id"
                        ],
                        "key": "organizer_id",
                        "alias": "regonlineorganizer_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "tour_id": {
                    "preload": true,
                    "tableRelatedAlias": {
                        "table": "tour",
                        "relation": "oneChild",
                        "fields": [
                            "name",
                            "seatsmax",
                            "id"
                        ],
                        "key": "tour_id",
                        "listmini": true,
                        "alias": "regonlinetour_id"
                    },
                    "table": "tour",
                    "fieldName": "tour_id",
                    "target": [
                        "maincrud"
                    ],
                    "label": "table.tour",
                    "fieldSource": {
                        "type": "ID",
                        "typeInput": "selectAutocomplete",
                        "disabledUpdate": true,
                        "onChange": true,
                        "inViewLink": "",
                        "dbtype": "ID",
                        "table": "tour",
                        "label": "table.tour"
                    },
                    "tableRelated": {
                        "table": "tour",
                        "relation": "oneChild",
                        "fields": [
                            "name",
                            "seatsmax",
                            "id"
                        ],
                        "key": "tour_id",
                        "listmini": true,
                        "alias": "regonlinetour_id"
                    },
                    "type": "ID",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "state": {
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "state",
                    "target": [
                        "maincrud",
                        "listmini"
                    ],
                    "label": "form.state",
                    "fieldSource": {
                        "type": "Int",
                        "dbtype": "Int",
                        "typeInput": "selectBox",
                        "listSource": "processRegonline",
                        "listmini": true,
                        "disabledUpdate": true,
                        "disabledOnAdd": true,
                        "defaultValue": 1,
                        "label": "form.state"
                    },
                    "tableRelated": "",
                    "type": "Int",
                    "typeInput": "selectBox",
                    "listSource": "processRegonline"
                },
                "id": {
                    "target": "listmini",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "id",
                    "fieldSource": {
                        "type": "ID!",
                        "listmini": true,
                        "dbtype": "ID",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "ID!",
                    "typeInput": "text",
                    "listSource": ""
                }
            }
        },
        "listview": {
            "fields": {
                "tour_id": true,
                "person_id": true,
                "dateregistration": true,
                "paid": true,
                "state": true,
                "organizer_id": true
            }
        },
        "destination": [
            "serverSchema",
            "serverResolver",
            "ormModels",
            "components",
            "app",
            "menus"
        ],
        "listOrder": "\"regonline\".\"updatedAt\":DESC",
        "related": {
            "person_id": {
                "table": "person",
                "relation": "oneChild",
                "fields": [
                    "name",
                    "email",
                    "id"
                ],
                "key": "person_id",
                "listmini": true,
                "alias": "regonlineperson_id"
            },
            "registration_id": {
                "table": "registration",
                "relation": "oneChild",
                "fields": [
                    "registrationstatus_id",
                    "id"
                ],
                "key": "registration_id",
                "listmini": true,
                "alias": "regonlineregistration_id"
            },
            "customer_id": {
                "table": "customer",
                "relation": "oneChild",
                "fields": [
                    "name",
                    "email",
                    "id"
                ],
                "key": "customer_id",
                "listmini": true,
                "alias": "regonlinecustomer_id"
            },
            "tour_id": {
                "table": "tour",
                "relation": "oneChild",
                "fields": [
                    "name",
                    "seatsmax",
                    "id"
                ],
                "key": "tour_id",
                "listmini": true,
                "alias": "regonlinetour_id"
            },
            "tourroom_id": {
                "table": "tourroom",
                "relation": "oneChild",
                "fields": [
                    "room_type",
                    "hotel_id",
                    "id"
                ],
                "subfields": {
                    "room_type": true,
                    "hotel_id": true
                },
                "key": "tourroom_id",
                "subrelated": true,
                "related": {
                    "hotel_id": {
                        "table": "hotel",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "hotel_id"
                    }
                },
                "alias": "regonlinetourroom_id"
            },
            "registrationstatus_id": {
                "table": "registrationstatus",
                "relation": "oneChild",
                "fields": [
                    "name",
                    "color",
                    "statusbase",
                    "id"
                ],
                "key": "registrationstatus_id",
                "alias": "regonlineregistrationstatus_id"
            },
            "stopspoint_id": {
                "table": "stopspoint",
                "relation": "oneChild",
                "fields": [
                    "name",
                    "id"
                ],
                "key": "stopspoint_id",
                "alias": "regonlinestopspoint_id"
            },
            "customerstatus_id": {
                "table": "customerstatus",
                "relation": "oneChild",
                "fields": [
                    "name",
                    "id"
                ],
                "key": "customerstatus_id",
                "alias": "regonlinecustomerstatus_id"
            },
            "organizer_id": {
                "table": "organizer",
                "relation": "oneChild",
                "fields": [
                    "name",
                    "id"
                ],
                "key": "organizer_id",
                "alias": "regonlineorganizer_id"
            },
            "updatedUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name",
                    "id"
                ],
                "key": "updated_user_id",
                "qlDisable": true,
                "alias": "regonlineupdated_user_id"
            },
            "createdUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name",
                    "id"
                ],
                "key": "created_user_id",
                "qlDisable": true,
                "alias": "regonlinecreated_user_id"
            }
        },
        "name": {
            "singular": "regonline",
            "plural": "regonlines",
            "singularCase": "Regonline",
            "pluralCase": "Regonlines"
        },
        "mainfield": "customer_id",
        "miniFields": [
            "id",
            "room_type",
            "state"
        ],
        "componentName": "RegonlineView",
        "path": "/regonline-view/:id"
    },
    "registration": {
        "help": {
            "fr-PELE": "https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.f1i2xryuseac"
        },
        "apps": [
            "phone",
            "admin"
        ],
        "formToolBarBottom": true,
        "icon": "registration",
        "triggers": true,
        "pagesettings": true,
        "disableRecreateTemplateList": true,
        "disableRecreateTemplateForm": true,
        "disableRecreateTemplateAdd": true,
        "disableRecreateTemplateView": true,
        "orders": {
            "noOptionBlank": true,
            "label": "form.order",
            "inLine": true,
            "defaultValue": "updatedAt",
            "fields": {
                "name": {
                    "mainRelated": "registration",
                    "subfields": [
                        {
                            "related": "customer_id",
                            "field": "name"
                        }
                    ]
                },
                "numbering": {
                    "label": "form.numbering",
                    "order": "DESC",
                    "module": "DOSSIER"
                },
                "updatedAt": {
                    "label": "form.updateAtDesc",
                    "order": "DESC"
                },
                "createdAt": {},
                "balanceAsc": {
                    "label": "form.balance",
                    "order": "ASC",
                    "labelorder": true,
                    "fieldName": "balance"
                },
                "balanceDesc": {
                    "label": "form.balance",
                    "order": "DESC",
                    "labelorder": true,
                    "fieldName": "balance"
                }
            }
        },
        "afterAddGoView": true,
        "preloadHeight": "500px",
        "detail": {
            "customer_id": true,
            "tour_id": true
        },
        "logFields": {
            "customer_id": true,
            "tour_id": true
        },
        "uniquefields": {
            "1": [
                "customer_id",
                "tour_id"
            ]
        },
        "fields": {
            "id": {
                "type": "ID!",
                "listmini": true,
                "dbtype": "ID",
                "typeInput": "text"
            },
            "customer_id": {
                "preComponents": {
                    "components": {
                        "RegistrationGroupping": {
                            "condition": {
                                "compareTo": "formState.actionsave",
                                "compareValue": "add"
                            },
                            "position": "insideBottom"
                        }
                    }
                },
                "type": "ID",
                "typeInput": "selectAutocomplete",
                "disabledUpdate": true,
                "onChange": true,
                "validators": [
                    "required"
                ],
                "add": true,
                "inViewLink": "",
                "recView": true,
                "recZoom": true,
                "dbtype": "Json",
                "table": "customer",
                "loadAsync": true,
                "saveonly": "id",
                "label": "table.customer"
            },
            "registrationgroupping_customers": {
                "type": "String",
                "typeInput": "hidden",
                "virtual": true,
                "disabledPreventExit": true,
                "dbtype": "String",
                "label": "form.registrationgroupping_customers"
            },
            "regonline_id": {
                "type": "ID",
                "typeInput": "selectAutocomplete",
                "disabledUpdate": true,
                "onChange": true,
                "inViewLink": "",
                "excludedInForm": true,
                "dbtype": "ID",
                "label": "form.regonline_id"
            },
            "person_id": {
                "type": "ID",
                "disabledUpdate": true,
                "excludedInForm": true,
                "dbtype": "ID",
                "typeInput": "text",
                "label": "form.person_id"
            },
            "tour_id": {
                "type": "ID",
                "typeInput": "selectAutocomplete",
                "disabledUpdate": true,
                "onChange": true,
                "validators": [
                    "required"
                ],
                "inViewLink": "",
                "dbtype": "ID",
                "table": "tour",
                "label": "table.tour"
            },
            "_services": {
                "type": "String",
                "typeInput": "hidden",
                "dbtype": "Json",
                "virtual": true,
                "label": "form._services"
            },
            "numbering": {
                "type": "String",
                "disabled": true,
                "disabledSystemUpdate": true,
                "module": "DOSSIER",
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.numbering"
            },
            "registrationstatus_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "validators": [
                    "required"
                ],
                "onChange": true,
                "dbtype": "String",
                "table": "registrationstatus",
                "label": "table.registrationstatus"
            },
            "dateregistration": {
                "type": "String",
                "dbtype": "Date",
                "onChange": true,
                "typeInput": "text",
                "validators": [
                    "date"
                ],
                "label": "form.dateregistration"
            },
            "confirmationstatus_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "dbtype": "String",
                "table": "confirmationstatus",
                "label": "table.confirmationstatus"
            },
            "inactive": {
                "type": "Boolean",
                "sublabel": "info.inactive",
                "sublabeldown": true,
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.inactive"
            },
            "customerstatus_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "formNewSection": true,
                "onChange": true,
                "dbtype": "String",
                "table": "customerstatus",
                "label": "table.customerstatus"
            },
            "customersubstatus_id": {
                "type": "String",
                "label": "form.substatus",
                "typeInput": "selectAutocomplete",
                "filterBy": [
                    "customerstatus_id"
                ],
                "subfield": "substatus",
                "table": "customerstatus",
                "onChange": true,
                "dbtype": "String"
            },
            "tourroom_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "formNewSection": true,
                "onDisabled": true,
                "filterBy": [
                    "tour_id"
                ],
                "onChange": true,
                "toolBar": [
                    {
                        "icon": "bed",
                        "onClick": "TourroomBooking",
                        "urlType": "fn"
                    }
                ],
                "preComponents": {
                    "components": {
                        "LinkedRegonlineRoom": {
                            "position": "insideBottom"
                        }
                    }
                },
                "dbtype": "String",
                "table": "tourroom",
                "label": "table.tourroom"
            },
            "room_shared": {
                "type": "String",
                "label": "form.room_shared_intended",
                "preComponents": {
                    "components": {
                        "LinkedRegonlineRoomShared": {
                            "position": "insideBottom"
                        }
                    }
                },
                "dbtype": "String",
                "typeInput": "text"
            },
            "assignedroom": {
                "type": "Boolean",
                "disabledMutation": true,
                "view": true,
                "preComponents": {
                    "components": {
                        "LinkedRegonlineBed": {
                            "position": "insideBottom"
                        }
                    }
                },
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.assignedroom"
            },
            "_roommates": {
                "type": "String",
                "typeInput": "hidden",
                "dbtype": "Json",
                "virtual": true,
                "label": "form._roommates"
            },
            "tollfree": {
                "type": "Boolean",
                "typeInput": "checkbox",
                "onChange": true,
                "formNewSection": true,
                "dbtype": "Boolean",
                "label": "form.tollfree"
            },
            "price": {
                "type": "Float",
                "typeValue": "Price",
                "disabledUpdate": true,
                "disabledAdd": true,
                "dbtype": "Float",
                "typeInput": "text",
                "normalize": "Price",
                "label": "form.price"
            },
            "paid": {
                "type": "Float",
                "typeValue": "Price",
                "disabledUpdate": true,
                "disabledAdd": true,
                "defaultValue": 0,
                "dbtype": "Float",
                "typeInput": "text",
                "normalize": "Price",
                "label": "form.paid"
            },
            "balance": {
                "type": "Float",
                "typeValue": "Price",
                "disabledUpdate": true,
                "disabledAdd": true,
                "preComponents": {
                    "components": {
                        "PriceFormula": {
                            "position": "outsideAfterCloseFieldsContainer"
                        }
                    }
                },
                "dbtype": "Float",
                "typeInput": "text",
                "normalize": "Price",
                "label": "form.balance"
            },
            "_priceformula": {
                "type": "String",
                "typeInput": "hidden",
                "virtual": true,
                "dbtype": "String",
                "label": "form._priceformula"
            },
            "transportation": {
                "type": "String",
                "dbtype": "Json",
                "label": "table.transportations",
                "labelChildsShow": true,
                "placeHolderShowChildsShow": false,
                "preComponents": {
                    "components": {
                        "LinkedRegonlineTransport": {
                            "position": "insideAfterTitle"
                        }
                    }
                },
                "subfields": {
                    "id": {
                        "type": "ID",
                        "typeInput": "hidden",
                        "label": "form.id"
                    },
                    "transportation_id": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "filterBy": [
                            "tour_id"
                        ],
                        "label": "form.routeplanner",
                        "table": "transportation",
                        "onChange": true,
                        "validators": [
                            "required"
                        ],
                        "conditionalRequired": [
                            "*isStopsPointFree",
                            false
                        ]
                    },
                    "stopspoint_id": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "filterBy": [
                            "transportation[*].transportation_id",
                            "tour_id"
                        ],
                        "conditionalfilterBy": [
                            "*isStopsPointFree",
                            false
                        ],
                        "table": "stopspoint",
                        "conditionalTable": [
                            "*isStopsPointFree",
                            true
                        ],
                        "ql": "getTourStopsPoints",
                        "label": "table.stopspoint"
                    },
                    "wagon": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "onChange": true,
                        "filterBy": [
                            "transportation[*].transportation_id",
                            "tour_id"
                        ],
                        "ql": "getTourWagons",
                        "conditional": [
                            "formProps.myState.app.appSettings.settingsforms.transport_seats",
                            true
                        ],
                        "label": "form.wagon"
                    },
                    "seat": {
                        "type": "String",
                        "typeInput": "selectBox",
                        "listOptions": [],
                        "validators": [
                            "maxLength:20"
                        ],
                        "styleInput": {
                            "minWidth": "98%"
                        },
                        "sectionStyle": {
                            "formSection": {
                                "maxWidth": "80px",
                                "marginTop": "7px"
                            }
                        },
                        "conditional": [
                            "formProps.myState.app.appSettings.settingsforms.transport_seats",
                            true
                        ],
                        "label": "form.seat"
                    }
                },
                "formNewSection": true,
                "typeInput": "text",
                "mode": {}
            },
            "objects": {
                "type": "String",
                "dbtype": "Json",
                "typeInput": "hidden",
                "inputTypeObject": true,
                "label": "form.objects"
            },
            "notes": {
                "type": "String",
                "typeInput": "textarea",
                "formNewSection": true,
                "preComponents": {
                    "components": {
                        "LinkedRegonlineNotes": {
                            "position": "insideBottom"
                        }
                    }
                },
                "dbtype": "String",
                "label": "form.notes"
            },
            "counterhistory": {
                "type": "Int",
                "disabledMutation": true,
                "excludedInForm": true,
                "label": "form.crm",
                "dbtype": "Int",
                "typeInput": "text"
            },
            "person_groupid": {
                "disabledSystemUpdate": true,
                "type": "ID",
                "excludedInForm": true,
                "dbtype": "ID",
                "typeInput": "text",
                "label": "form.person_groupid"
            },
            "updatedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "dbAutoUpdate": true,
                "excludedInForm": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.updatedAt"
            },
            "createdAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "dbAutoUpdate": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.createdAt"
            },
            "deletedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "disabledMutation": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.deletedAt"
            },
            "deleted": {
                "type": "Boolean",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.deleted"
            },
            "updated_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.updated_user_id"
            },
            "created_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.created_user_id"
            },
            "deleted_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.deleted_user_id"
            },
            "organizer_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "readOnlyUpdate": true,
                "disabledUpdate": true,
                "validators": [
                    "required"
                ],
                "formNewSection": true,
                "dbtype": "String",
                "table": "organizer",
                "label": "table.organizer"
            }
        },
        "toolbar": {
            "menus": {
                "registrationhistory": {
                    "icon": "log",
                    "label": "table.registrationhistory",
                    "component": "RegistrationHistory",
                    "urlType": "child",
                    "inList": false,
                    "inView": true
                },
                "bookingroom": {
                    "icon": "room",
                    "label": "table.tourroom",
                    "inList": false,
                    "inView": true
                },
                "filterpaymentAdd": {
                    "icon": "paymentadd",
                    "label": "form.addpayment",
                    "urlType": "filter",
                    "inView": true,
                    "inList": false
                },
                "email": {
                    "icon": "mailing",
                    "label": "menu.messaging",
                    "inView": true,
                    "inListHeader": true,
                    "inList": false
                },
                "arroba": {
                    "icon": "arroba",
                    "label": "menu.arroba",
                    "inView": false,
                    "inListHeader": true,
                    "inList": false
                }
            }
        },
        "listFilters": {
            "fields": {
                "organizer_id": {
                    "target": [],
                    "tableRelatedAlias": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "registrationorganizer_id"
                    },
                    "table": "organizer",
                    "fieldName": "organizer_id",
                    "label": "table.organizer",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "readOnlyUpdate": true,
                        "disabledUpdate": true,
                        "validators": [
                            "required"
                        ],
                        "formNewSection": true,
                        "dbtype": "String",
                        "table": "organizer",
                        "label": "table.organizer"
                    },
                    "tableRelated": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "registrationorganizer_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "numbering": {
                    "module": "DOSSIER",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "numbering",
                    "target": [
                        "maincrud"
                    ],
                    "label": "form.numbering",
                    "fieldSource": {
                        "type": "String",
                        "disabled": true,
                        "disabledSystemUpdate": true,
                        "module": "DOSSIER",
                        "dbtype": "String",
                        "typeInput": "text",
                        "label": "form.numbering"
                    },
                    "tableRelated": "",
                    "type": "String",
                    "typeInput": "text",
                    "listSource": ""
                },
                "customer_id": {
                    "preload": true,
                    "tableRelatedAlias": {
                        "table": "customer",
                        "relation": "oneChild",
                        "fields": [
                            "name",
                            "email"
                        ],
                        "key": "customer_id",
                        "listmini": true,
                        "alias": "registrationcustomer_id"
                    },
                    "table": "customer",
                    "fieldName": "customer_id",
                    "target": [
                        "maincrud"
                    ],
                    "label": "table.customer",
                    "fieldSource": {
                        "preComponents": {
                            "components": {
                                "RegistrationGroupping": {
                                    "condition": {
                                        "compareTo": "formState.actionsave",
                                        "compareValue": "add"
                                    },
                                    "position": "insideBottom"
                                }
                            }
                        },
                        "type": "ID",
                        "typeInput": "selectAutocomplete",
                        "disabledUpdate": true,
                        "onChange": true,
                        "validators": [
                            "required"
                        ],
                        "add": true,
                        "inViewLink": "",
                        "recView": true,
                        "recZoom": true,
                        "dbtype": "Json",
                        "table": "customer",
                        "loadAsync": true,
                        "saveonly": "id",
                        "label": "table.customer"
                    },
                    "tableRelated": {
                        "table": "customer",
                        "relation": "oneChild",
                        "fields": [
                            "name",
                            "email"
                        ],
                        "key": "customer_id",
                        "listmini": true,
                        "alias": "registrationcustomer_id"
                    },
                    "type": "ID",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "tour_id": {
                    "preload": true,
                    "tableRelatedAlias": {
                        "table": "tour",
                        "relation": "oneChild",
                        "fields": [
                            "name",
                            "seatsmax"
                        ],
                        "key": "tour_id",
                        "listmini": true,
                        "alias": "registrationtour_id"
                    },
                    "table": "tour",
                    "fieldName": "tour_id",
                    "target": [
                        "maincrud"
                    ],
                    "label": "table.tour",
                    "fieldSource": {
                        "type": "ID",
                        "typeInput": "selectAutocomplete",
                        "disabledUpdate": true,
                        "onChange": true,
                        "validators": [
                            "required"
                        ],
                        "inViewLink": "",
                        "dbtype": "ID",
                        "table": "tour",
                        "label": "table.tour"
                    },
                    "tableRelated": {
                        "table": "tour",
                        "relation": "oneChild",
                        "fields": [
                            "name",
                            "seatsmax"
                        ],
                        "key": "tour_id",
                        "listmini": true,
                        "alias": "registrationtour_id"
                    },
                    "type": "ID",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "transportation_transportation_id": {
                    "fieldContainer": "transportation",
                    "arraySubfield": "transportation_id",
                    "filterBy": [
                        "tour_id"
                    ],
                    "label": "table.transportation",
                    "tableRelatedAlias": "transportation",
                    "table": "transportation",
                    "fieldName": "transportation.transportation_id",
                    "target": [
                        "maincrud"
                    ],
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "filterBy": [
                            "tour_id"
                        ],
                        "label": "form.routeplanner",
                        "table": "transportation",
                        "onChange": true,
                        "validators": [
                            "required"
                        ],
                        "conditionalRequired": [
                            "*isStopsPointFree",
                            false
                        ]
                    },
                    "tableRelated": "transportation",
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "tourroom_id": {
                    "filterBy": [
                        "tour_id"
                    ],
                    "tableRelatedAlias": {
                        "table": "tourroom",
                        "relation": "oneChild",
                        "fields": [
                            "room_type",
                            "hotel_id"
                        ],
                        "subfields": {
                            "room_type": true,
                            "hotel_id": true
                        },
                        "key": "tourroom_id",
                        "subrelated": true,
                        "related": {
                            "hotel_id": {
                                "table": "hotel",
                                "relation": "oneChild",
                                "fields": [
                                    "name"
                                ],
                                "key": "hotel_id"
                            }
                        },
                        "alias": "registrationtourroom_id"
                    },
                    "table": "tourroom",
                    "fieldName": "tourroom_id",
                    "target": [
                        "maincrud"
                    ],
                    "label": "table.tourroom",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "formNewSection": true,
                        "onDisabled": true,
                        "filterBy": [
                            "tour_id"
                        ],
                        "onChange": true,
                        "toolBar": [
                            {
                                "icon": "bed",
                                "onClick": "TourroomBooking",
                                "urlType": "fn"
                            }
                        ],
                        "preComponents": {
                            "components": {
                                "LinkedRegonlineRoom": {
                                    "position": "insideBottom"
                                }
                            }
                        },
                        "dbtype": "String",
                        "table": "tourroom",
                        "label": "table.tourroom"
                    },
                    "tableRelated": {
                        "table": "tourroom",
                        "relation": "oneChild",
                        "fields": [
                            "room_type",
                            "hotel_id"
                        ],
                        "subfields": {
                            "room_type": true,
                            "hotel_id": true
                        },
                        "key": "tourroom_id",
                        "subrelated": true,
                        "related": {
                            "hotel_id": {
                                "table": "hotel",
                                "relation": "oneChild",
                                "fields": [
                                    "name"
                                ],
                                "key": "hotel_id"
                            }
                        },
                        "alias": "registrationtourroom_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "registrationstatus_id_statusbase": {
                    "fieldContainer": "registrationstatus_id",
                    "subfield": "statusbase",
                    "notJson": true,
                    "tableRelatedAlias": "registrationregistrationstatus_id",
                    "fieldName": "registrationregistrationstatus_id.statusbase",
                    "target": [
                        "maincrud",
                        "listmini"
                    ],
                    "label": "form.registration_statusbase",
                    "fieldSource": {
                        "type": "Int",
                        "dbtype": "Int",
                        "typeInput": "selectAutocomplete",
                        "label": "form.registration_statusbase",
                        "sublabel": "form.registration_statusbase_sublabel",
                        "listSource": "registrationBaseStatus",
                        "validators": [
                            "required"
                        ],
                        "listmini": true,
                        "onChange": true
                    },
                    "tableRelated": "registrationstatus",
                    "type": "Int",
                    "typeInput": "selectAutocomplete",
                    "listSource": "registrationBaseStatus"
                },
                "customerstatus_id": {
                    "tableRelatedAlias": {
                        "table": "customerstatus",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "customerstatus_id",
                        "alias": "registrationcustomerstatus_id"
                    },
                    "table": "customerstatus",
                    "fieldName": "customerstatus_id",
                    "target": [
                        "maincrud"
                    ],
                    "label": "table.customerstatus",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "formNewSection": true,
                        "onChange": true,
                        "dbtype": "String",
                        "table": "customerstatus",
                        "label": "table.customerstatus"
                    },
                    "tableRelated": {
                        "table": "customerstatus",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "customerstatus_id",
                        "alias": "registrationcustomerstatus_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "customersubstatus_id": {
                    "invisible": true,
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "customersubstatus_id",
                    "target": [
                        "maincrud"
                    ],
                    "label": "form.substatus",
                    "fieldSource": {
                        "type": "String",
                        "label": "form.substatus",
                        "typeInput": "selectAutocomplete",
                        "filterBy": [
                            "customerstatus_id"
                        ],
                        "subfield": "substatus",
                        "table": "customerstatus",
                        "onChange": true,
                        "dbtype": "String"
                    },
                    "tableRelated": "",
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "confirmationstatus_id_statusbase": {
                    "fieldContainer": "confirmationstatus_id",
                    "subfield": "statusbase",
                    "notJson": true,
                    "tableRelatedAlias": "registrationconfirmationstatus_id",
                    "fieldName": "registrationconfirmationstatus_id.statusbase",
                    "target": [
                        "maincrud",
                        "listmini"
                    ],
                    "label": "form.confirmation_statusbase",
                    "fieldSource": {
                        "type": "Int",
                        "dbtype": "Int",
                        "validators": [
                            "required"
                        ],
                        "typeInput": "selectAutocomplete",
                        "listSource": "confirmationBaseStatus",
                        "label": "form.confirmation_statusbase",
                        "sublabel": "form.confirmation_statusbase_sublabel",
                        "onChange": true,
                        "listmini": true
                    },
                    "tableRelated": "confirmationstatus",
                    "type": "Int",
                    "typeInput": "selectAutocomplete",
                    "listSource": "confirmationBaseStatus"
                },
                "_balance": {
                    "type": "String",
                    "realType": "Float",
                    "realFieldName": "balance",
                    "typeInput": "selectBox",
                    "listOptions": [
                        {
                            "id": "",
                            "name": "form.balancetype"
                        },
                        {
                            "id": "=0",
                            "name": "form.zero"
                        },
                        {
                            "id": "<0",
                            "name": "form.creditor"
                        },
                        {
                            "id": ">0",
                            "name": "form.debtor"
                        }
                    ],
                    "defaultValue": "",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "_balance",
                    "target": [
                        "maincrud"
                    ],
                    "fieldSource": {
                        "type": "String",
                        "realType": "Float",
                        "realFieldName": "balance",
                        "typeInput": "selectBox",
                        "listOptions": [
                            {
                                "id": "",
                                "name": "form.balancetype"
                            },
                            {
                                "id": "=0",
                                "name": "form.zero"
                            },
                            {
                                "id": "<0",
                                "name": "form.creditor"
                            },
                            {
                                "id": ">0",
                                "name": "form.debtor"
                            }
                        ],
                        "defaultValue": "",
                        "tableRelatedAlias": ""
                    },
                    "tableRelated": "",
                    "listSource": ""
                },
                "assignedroom": {
                    "typeInput": "radio",
                    "listOptions": [
                        {
                            "id": "false",
                            "name": "form.no"
                        },
                        {
                            "id": "true",
                            "name": "form.yes"
                        },
                        {
                            "id": "null",
                            "name": "form.all"
                        }
                    ],
                    "showLabel": true,
                    "defaultValue": "null",
                    "inLine": true,
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "assignedroom",
                    "target": [
                        "maincrud"
                    ],
                    "label": "form.assignedroom",
                    "fieldSource": {
                        "type": "Boolean",
                        "disabledMutation": true,
                        "view": true,
                        "preComponents": {
                            "components": {
                                "LinkedRegonlineBed": {
                                    "position": "insideBottom"
                                }
                            }
                        },
                        "dbtype": "Boolean",
                        "typeInput": "checkbox",
                        "label": "form.assignedroom"
                    },
                    "tableRelated": "",
                    "type": "Boolean",
                    "listSource": ""
                },
                "_channel": {
                    "type": "String",
                    "notJson": true,
                    "realType": "String",
                    "realFieldName": "\"registrationcustomer_id\".\"email\"",
                    "label": "form.withEmail",
                    "typeInput": "radio",
                    "listOptions": [
                        {
                            "id": "COALESCE(NULLIF(\"registrationcustomer_id\".\"email\",''), NULL) IS NULL",
                            "name": "form.no"
                        },
                        {
                            "id": "COALESCE(NULLIF(\"registrationcustomer_id\".\"email\",''), NULL) IS NOT NULL",
                            "name": "form.yes"
                        },
                        {
                            "id": "",
                            "name": "form.all"
                        }
                    ],
                    "defaultValue": "",
                    "inLine": true,
                    "showLabel": true,
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "_channel",
                    "target": [
                        "maincrud"
                    ],
                    "fieldSource": {
                        "type": "String",
                        "notJson": true,
                        "realType": "String",
                        "realFieldName": "\"registrationcustomer_id\".\"email\"",
                        "label": "form.withEmail",
                        "typeInput": "radio",
                        "listOptions": [
                            {
                                "id": "COALESCE(NULLIF(\"registrationcustomer_id\".\"email\",''), NULL) IS NULL",
                                "name": "form.no"
                            },
                            {
                                "id": "COALESCE(NULLIF(\"registrationcustomer_id\".\"email\",''), NULL) IS NOT NULL",
                                "name": "form.yes"
                            },
                            {
                                "id": "",
                                "name": "form.all"
                            }
                        ],
                        "defaultValue": "",
                        "inLine": true,
                        "showLabel": true,
                        "tableRelatedAlias": ""
                    },
                    "tableRelated": "",
                    "listSource": ""
                },
                "_withmobile": {
                    "type": "String",
                    "notJson": true,
                    "realType": "String",
                    "realFieldName": "\"registrationcustomer_id\".\"mobile\"",
                    "label": "form.withMobile",
                    "typeInput": "radio",
                    "listOptions": [
                        {
                            "id": "COALESCE(NULLIF(\"registrationcustomer_id\".\"mobile\",''), NULL) IS NULL",
                            "name": "form.no"
                        },
                        {
                            "id": "COALESCE(NULLIF(\"registrationcustomer_id\".\"mobile\",''), NULL) IS NOT NULL",
                            "name": "form.yes"
                        },
                        {
                            "id": "",
                            "name": "form.all"
                        }
                    ],
                    "defaultValue": "",
                    "inLine": true,
                    "showLabel": true,
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "_withmobile",
                    "target": [
                        "maincrud"
                    ],
                    "fieldSource": {
                        "type": "String",
                        "notJson": true,
                        "realType": "String",
                        "realFieldName": "\"registrationcustomer_id\".\"mobile\"",
                        "label": "form.withMobile",
                        "typeInput": "radio",
                        "listOptions": [
                            {
                                "id": "COALESCE(NULLIF(\"registrationcustomer_id\".\"mobile\",''), NULL) IS NULL",
                                "name": "form.no"
                            },
                            {
                                "id": "COALESCE(NULLIF(\"registrationcustomer_id\".\"mobile\",''), NULL) IS NOT NULL",
                                "name": "form.yes"
                            },
                            {
                                "id": "",
                                "name": "form.all"
                            }
                        ],
                        "defaultValue": "",
                        "inLine": true,
                        "showLabel": true,
                        "tableRelatedAlias": ""
                    },
                    "tableRelated": "",
                    "listSource": ""
                },
                "inactive": {
                    "typeInput": "radio",
                    "listOptions": [
                        {
                            "id": "false",
                            "name": "form.no"
                        },
                        {
                            "id": "true",
                            "name": "form.yes"
                        },
                        {
                            "id": "null",
                            "name": "form.all"
                        }
                    ],
                    "showLabel": true,
                    "defaultValue": "false",
                    "inLine": true,
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "inactive",
                    "target": [
                        "maincrud"
                    ],
                    "label": "form.inactive",
                    "fieldSource": {
                        "type": "Boolean",
                        "sublabel": "info.inactive",
                        "sublabeldown": true,
                        "dbtype": "Boolean",
                        "typeInput": "checkbox",
                        "label": "form.inactive"
                    },
                    "tableRelated": "",
                    "type": "Boolean",
                    "listSource": ""
                },
                "id": {
                    "target": "listmini",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "id",
                    "fieldSource": {
                        "type": "ID!",
                        "listmini": true,
                        "dbtype": "ID",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "ID!",
                    "typeInput": "text",
                    "listSource": ""
                }
            }
        },
        "listview": {
            "fields": {
                "tour_id": true,
                "numbering": {
                    "module": "DOSSIER"
                },
                "customer_id": {
                    "linkview": "customer"
                },
                "counterhistory": {
                    "icon": "log",
                    "childTable": "registrationhistory",
                    "filter": "!Empty"
                },
                "registrationstatus_id": true,
                "confirmationstatus_id": {
                    "label": "form.confirmation_short",
                    "onlycolor": true,
                    "hiddenPhone": true
                },
                "price": true,
                "balance": true,
                "assignedroom": true,
                "transportation": {
                    "subfields": {
                        "transportation_id": {
                            "maxLength": 15
                        },
                        "stopspoint_id": {
                            "maxLength": 15
                        }
                    },
                    "separatorsubfield": " / ",
                    "joinsubfields": false
                },
                "customerstatus_id": true,
                "organizer_id": true
            }
        },
        "destination": [
            "serverSchema",
            "serverResolver",
            "ormModels",
            "components",
            "app",
            "menus"
        ],
        "listOrder": "\"registration\".\"updatedAt\":DESC",
        "related": {
            "customer_id": {
                "table": "customer",
                "relation": "oneChild",
                "fields": [
                    "name",
                    "email"
                ],
                "key": "customer_id",
                "listmini": true,
                "alias": "registrationcustomer_id"
            },
            "tour_id": {
                "table": "tour",
                "relation": "oneChild",
                "fields": [
                    "name",
                    "seatsmax"
                ],
                "key": "tour_id",
                "listmini": true,
                "alias": "registrationtour_id"
            },
            "regonline_id": {
                "table": "regonline",
                "relation": "oneChild",
                "fields": [
                    "state"
                ],
                "fieldsView": [
                    "*"
                ],
                "key": "regonline_id",
                "listmini": true,
                "alias": "registrationregonline_id"
            },
            "tourroom_id": {
                "table": "tourroom",
                "relation": "oneChild",
                "fields": [
                    "room_type",
                    "hotel_id"
                ],
                "subfields": {
                    "room_type": true,
                    "hotel_id": true
                },
                "key": "tourroom_id",
                "subrelated": true,
                "related": {
                    "hotel_id": {
                        "table": "hotel",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "hotel_id"
                    }
                },
                "alias": "registrationtourroom_id"
            },
            "registrationstatus_id": {
                "table": "registrationstatus",
                "relation": "oneChild",
                "fields": [
                    "name",
                    "color",
                    "statusbase"
                ],
                "key": "registrationstatus_id",
                "alias": "registrationregistrationstatus_id"
            },
            "confirmationstatus_id": {
                "table": "confirmationstatus",
                "relation": "oneChild",
                "fields": [
                    "name",
                    "color"
                ],
                "key": "confirmationstatus_id",
                "alias": "registrationconfirmationstatus_id"
            },
            "customerstatus_id": {
                "table": "customerstatus",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "customerstatus_id",
                "alias": "registrationcustomerstatus_id"
            },
            "organizer_id": {
                "table": "organizer",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "organizer_id",
                "alias": "registrationorganizer_id"
            },
            "updatedUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "updated_user_id",
                "qlDisable": true,
                "alias": "registrationupdated_user_id"
            },
            "createdUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "created_user_id",
                "qlDisable": true,
                "alias": "registrationcreated_user_id"
            }
        },
        "name": {
            "singular": "registration",
            "plural": "registrations",
            "singularCase": "Registration",
            "pluralCase": "Registrations"
        },
        "mainfield": "customer_id",
        "miniFields": [
            "id"
        ],
        "componentName": "RegistrationView",
        "path": "/registration-view/:id"
    },
    "customerhistory": {
        "help": {
            "fr-PELE": "https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.9j6oizhpr0eu"
        },
        "parentTable": "customer",
        "parentRelationField": "customer_id",
        "isLogHistory": true,
        "disabledView": true,
        "disabledUpdate": true,
        "disabledAdd": true,
        "disabledDelete": true,
        "disabledPermissionOption": true,
        "permissionTable": "customer",
        "disableRecreateTemplateList": true,
        "listSendNextRecord": true,
        "disabledRecordInfo": true,
        "fields": {
            "id": {
                "type": "ID!",
                "listmini": true,
                "dbtype": "ID",
                "typeInput": "text"
            },
            "actiondate": {
                "type": "String",
                "dbtype": "Datetime",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "label": "form.actiondate"
            },
            "user_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "dbtype": "String",
                "table": "user",
                "label": "table.user"
            },
            "customer_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "dbtype": "Json",
                "table": "customer",
                "loadAsync": true,
                "saveonly": "id",
                "label": "table.customer"
            },
            "changes": {
                "type": "String",
                "dbtype": "Json",
                "typeInput": "text",
                "label": "form.changes"
            },
            "oldchanges": {
                "type": "String",
                "dbtype": "Json",
                "typeInput": "text",
                "label": "form.oldchanges"
            },
            "organizer_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "readOnlyUpdate": true,
                "disabledUpdate": true,
                "validators": [
                    "required"
                ],
                "formNewSection": true,
                "dbtype": "String",
                "table": "organizer",
                "label": "table.organizer"
            }
        },
        "listview": {
            "fields": {
                "actiondate": true,
                "user_id": true,
                "organizer_id": true
            }
        },
        "icon": "log",
        "destination": [
            "serverSchema",
            "serverResolver",
            "ormModels",
            "components",
            "app"
        ],
        "listOrder": "\"customerhistory\".\"actiondate\":DESC",
        "related": {
            "user_id": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "user_id",
                "alias": "customerhistoryuser_id"
            },
            "customer_id": {
                "table": "customer",
                "isParent": true,
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "customer_id",
                "alias": "customerhistorycustomer_id"
            },
            "organizer_id": {
                "table": "organizer",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "organizer_id",
                "alias": "customerhistoryorganizer_id"
            }
        },
        "name": {
            "singular": "customerhistory",
            "plural": "customerhistorys",
            "singularCase": "Customerhistory",
            "pluralCase": "Customerhistorys"
        },
        "mainfield": "actiondate",
        "miniFields": [
            "id"
        ],
        "listFilters": {
            "fields": {
                "organizer_id": {
                    "target": [],
                    "tableRelatedAlias": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "customerhistoryorganizer_id"
                    },
                    "table": "organizer",
                    "fieldName": "organizer_id",
                    "label": "table.organizer",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "readOnlyUpdate": true,
                        "disabledUpdate": true,
                        "validators": [
                            "required"
                        ],
                        "formNewSection": true,
                        "dbtype": "String",
                        "table": "organizer",
                        "label": "table.organizer"
                    },
                    "tableRelated": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "customerhistoryorganizer_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "customer_id": {
                    "tableRelatedAlias": {
                        "table": "customer",
                        "isParent": true,
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "customer_id",
                        "alias": "customerhistorycustomer_id"
                    },
                    "table": "customer",
                    "fieldName": "customer_id",
                    "target": [
                        "maincrud"
                    ],
                    "label": "table.customer",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "dbtype": "Json",
                        "table": "customer",
                        "loadAsync": true,
                        "saveonly": "id",
                        "label": "table.customer"
                    },
                    "tableRelated": {
                        "table": "customer",
                        "isParent": true,
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "customer_id",
                        "alias": "customerhistorycustomer_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "id": {
                    "target": "listmini",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "id",
                    "fieldSource": {
                        "type": "ID!",
                        "listmini": true,
                        "dbtype": "ID",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "ID!",
                    "typeInput": "text",
                    "listSource": ""
                }
            }
        },
        "componentName": "CustomerhistoryList",
        "path": "/customer-child/:parentid/customerhistory"
    },
    "paymenthistory": {
        "help": {
            "fr-PELE": "https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.9j6oizhpr0eu"
        },
        "parentTable": "payment",
        "parentRelationField": "payment_id",
        "isLogHistory": true,
        "disabledView": true,
        "disabledUpdate": true,
        "disabledPermissionOption": true,
        "permissionTable": "payment",
        "disabledAdd": true,
        "disableRecreateTemplateList": true,
        "disabledDelete": true,
        "listSendNextRecord": true,
        "disabledRecordInfo": true,
        "fields": {
            "id": {
                "type": "ID!",
                "listmini": true,
                "dbtype": "ID",
                "typeInput": "text"
            },
            "actiondate": {
                "type": "String",
                "dbtype": "Datetime",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "label": "form.actiondate"
            },
            "user_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "dbtype": "String",
                "table": "user",
                "label": "table.user"
            },
            "payment_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "dbtype": "String",
                "table": "payment",
                "label": "table.payment"
            },
            "changes": {
                "type": "String",
                "dbtype": "Json",
                "typeInput": "text",
                "label": "form.changes"
            },
            "oldchanges": {
                "type": "String",
                "dbtype": "Json",
                "typeInput": "text",
                "label": "form.oldchanges"
            },
            "organizer_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "readOnlyUpdate": true,
                "disabledUpdate": true,
                "validators": [
                    "required"
                ],
                "formNewSection": true,
                "dbtype": "String",
                "table": "organizer",
                "label": "table.organizer"
            }
        },
        "listview": {
            "fields": {
                "actiondate": true,
                "user_id": true,
                "organizer_id": true
            }
        },
        "icon": "log",
        "destination": [
            "serverSchema",
            "serverResolver",
            "ormModels",
            "components",
            "app"
        ],
        "listOrder": "\"paymenthistory\".\"actiondate\":DESC",
        "related": {
            "user_id": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "user_id",
                "alias": "paymenthistoryuser_id"
            },
            "payment_id": {
                "table": "payment",
                "isParent": true,
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "payment_id",
                "alias": "paymenthistorypayment_id"
            },
            "organizer_id": {
                "table": "organizer",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "organizer_id",
                "alias": "paymenthistoryorganizer_id"
            }
        },
        "name": {
            "singular": "paymenthistory",
            "plural": "paymenthistorys",
            "singularCase": "Paymenthistory",
            "pluralCase": "Paymenthistorys"
        },
        "mainfield": "actiondate",
        "miniFields": [
            "id"
        ],
        "listFilters": {
            "fields": {
                "organizer_id": {
                    "target": [],
                    "tableRelatedAlias": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "paymenthistoryorganizer_id"
                    },
                    "table": "organizer",
                    "fieldName": "organizer_id",
                    "label": "table.organizer",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "readOnlyUpdate": true,
                        "disabledUpdate": true,
                        "validators": [
                            "required"
                        ],
                        "formNewSection": true,
                        "dbtype": "String",
                        "table": "organizer",
                        "label": "table.organizer"
                    },
                    "tableRelated": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "paymenthistoryorganizer_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "payment_id": {
                    "tableRelatedAlias": {
                        "table": "payment",
                        "isParent": true,
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "payment_id",
                        "alias": "paymenthistorypayment_id"
                    },
                    "table": "payment",
                    "fieldName": "payment_id",
                    "target": [
                        "maincrud"
                    ],
                    "label": "table.payment",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "dbtype": "String",
                        "table": "payment",
                        "label": "table.payment"
                    },
                    "tableRelated": {
                        "table": "payment",
                        "isParent": true,
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "payment_id",
                        "alias": "paymenthistorypayment_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "id": {
                    "target": "listmini",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "id",
                    "fieldSource": {
                        "type": "ID!",
                        "listmini": true,
                        "dbtype": "ID",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "ID!",
                    "typeInput": "text",
                    "listSource": ""
                }
            }
        },
        "componentName": "PaymenthistoryList",
        "path": "/payment-child/:parentid/paymenthistory"
    },
    "registrationhistory": {
        "help": {
            "fr-PELE": "https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.9j6oizhpr0eu"
        },
        "parentTable": "registration",
        "parentRelationField": "registration_id",
        "isLogHistory": true,
        "disabledView": true,
        "disabledUpdate": true,
        "disabledPermissionOption": true,
        "permissionTable": "registration",
        "disabledAdd": true,
        "disableRecreateTemplateList": true,
        "disabledDelete": true,
        "listSendNextRecord": true,
        "disabledRecordInfo": true,
        "fields": {
            "id": {
                "type": "ID!",
                "listmini": true,
                "dbtype": "ID",
                "typeInput": "text"
            },
            "actiondate": {
                "type": "String",
                "dbtype": "Datetime",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "label": "form.actiondate"
            },
            "user_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "dbtype": "String",
                "table": "user",
                "label": "table.user"
            },
            "customer_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "dbtype": "Json",
                "table": "customer",
                "loadAsync": true,
                "saveonly": "id",
                "label": "table.customer"
            },
            "tour_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "dbtype": "String",
                "table": "tour",
                "label": "table.tour"
            },
            "tourroom_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "dbtype": "String",
                "table": "tourroom",
                "label": "table.tourroom"
            },
            "dateregistration": {
                "type": "String",
                "dbtype": "Date",
                "typeInput": "text",
                "validators": [
                    "date"
                ],
                "label": "form.dateregistration"
            },
            "registrationstatus_id": {
                "type": "String",
                "typeInput": "hidden",
                "validators": [
                    "required"
                ],
                "label": "table.registrationstatus",
                "dbtype": "String"
            },
            "confirmationstatus_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "dbtype": "String",
                "table": "confirmationstatus",
                "label": "table.confirmationstatus"
            },
            "notes": {
                "type": "String",
                "typeInput": "textarea",
                "formNewSection": true,
                "nextCompareText": true,
                "dbtype": "String",
                "label": "form.notes"
            },
            "registration_id": {
                "type": "String",
                "typeInput": "hidden",
                "isId": true,
                "dbtype": "String",
                "label": "form.registration_id"
            },
            "changes": {
                "type": "String",
                "dbtype": "Json",
                "typeInput": "text",
                "label": "form.changes"
            },
            "oldchanges": {
                "type": "String",
                "dbtype": "Json",
                "typeInput": "text",
                "label": "form.oldchanges"
            },
            "organizer_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "readOnlyUpdate": true,
                "disabledUpdate": true,
                "validators": [
                    "required"
                ],
                "formNewSection": true,
                "dbtype": "String",
                "table": "organizer",
                "label": "table.organizer"
            }
        },
        "listview": {
            "fields": {
                "actiondate": true,
                "user_id": true,
                "organizer_id": true
            }
        },
        "icon": "log",
        "destination": [
            "serverSchema",
            "serverResolver",
            "ormModels",
            "components",
            "app"
        ],
        "listOrder": "\"registrationhistory\".\"actiondate\":DESC",
        "related": {
            "user_id": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "user_id",
                "alias": "registrationhistoryuser_id"
            },
            "customer_id": {
                "table": "customer",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "customer_id",
                "alias": "registrationhistorycustomer_id"
            },
            "tour_id": {
                "table": "tour",
                "relation": "oneChild",
                "fields": [
                    "name",
                    "seatsmax"
                ],
                "key": "tour_id",
                "alias": "registrationhistorytour_id"
            },
            "tourroom_id": {
                "table": "tourroom",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "tourroom_id",
                "alias": "registrationhistorytourroom_id"
            },
            "registrationstatus_id": {
                "table": "registrationstatus",
                "relation": "oneChild",
                "fields": [
                    "name",
                    "color",
                    "statusbase"
                ],
                "key": "registrationstatus_id",
                "alias": "registrationhistoryregistrationstatus_id"
            },
            "confirmationstatus_id": {
                "table": "confirmationstatus",
                "relation": "oneChild",
                "fields": [
                    "name",
                    "color"
                ],
                "key": "confirmationstatus_id",
                "alias": "registrationhistoryconfirmationstatus_id"
            },
            "registration_id": {
                "table": "registration",
                "isParent": true,
                "relation": "oneChild",
                "fields": [
                    "registrationstatus_id"
                ],
                "key": "registration_id",
                "alias": "registrationhistoryregistration_id"
            },
            "organizer_id": {
                "table": "organizer",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "organizer_id",
                "alias": "registrationhistoryorganizer_id"
            }
        },
        "name": {
            "singular": "registrationhistory",
            "plural": "registrationhistorys",
            "singularCase": "Registrationhistory",
            "pluralCase": "Registrationhistorys"
        },
        "mainfield": "actiondate",
        "miniFields": [
            "id"
        ],
        "listFilters": {
            "fields": {
                "organizer_id": {
                    "target": [],
                    "tableRelatedAlias": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "registrationhistoryorganizer_id"
                    },
                    "table": "organizer",
                    "fieldName": "organizer_id",
                    "label": "table.organizer",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "readOnlyUpdate": true,
                        "disabledUpdate": true,
                        "validators": [
                            "required"
                        ],
                        "formNewSection": true,
                        "dbtype": "String",
                        "table": "organizer",
                        "label": "table.organizer"
                    },
                    "tableRelated": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "registrationhistoryorganizer_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "registration_id": {
                    "tableRelatedAlias": {
                        "table": "registration",
                        "isParent": true,
                        "relation": "oneChild",
                        "fields": [
                            "registrationstatus_id"
                        ],
                        "key": "registration_id",
                        "alias": "registrationhistoryregistration_id"
                    },
                    "table": "registration",
                    "fieldName": "registration_id",
                    "target": [
                        "maincrud"
                    ],
                    "label": "form.registration_id",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "hidden",
                        "isId": true,
                        "dbtype": "String",
                        "label": "form.registration_id"
                    },
                    "tableRelated": {
                        "table": "registration",
                        "isParent": true,
                        "relation": "oneChild",
                        "fields": [
                            "registrationstatus_id"
                        ],
                        "key": "registration_id",
                        "alias": "registrationhistoryregistration_id"
                    },
                    "type": "String",
                    "typeInput": "hidden",
                    "listSource": ""
                },
                "id": {
                    "target": "listmini",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "id",
                    "fieldSource": {
                        "type": "ID!",
                        "listmini": true,
                        "dbtype": "ID",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "ID!",
                    "typeInput": "text",
                    "listSource": ""
                }
            }
        },
        "componentName": "RegistrationhistoryList",
        "path": "/registration-child/:parentid/registrationhistory"
    },
    "payment": {
        "help": {
            "fr-PELE": "https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#bookmark=id.6q6jc9i1en12",
            "templates": {
                "ajournal": "https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#bookmark=id.auunr41vt05"
            }
        },
        "formToolBarBottom": true,
        "triggers": true,
        "pagesettings": true,
        "preloadHeight": "600px",
        "destination": [
            "serverSchema",
            "serverResolver",
            "ormModels",
            "components",
            "app",
            "menus"
        ],
        "orders": {
            "noOptionBlank": true,
            "label": "form.order",
            "inLine": true,
            "defaultValue": "updatedAt",
            "defaultajournal": "dateaccounting",
            "fields": {
                "updatedAt": {
                    "label": "form.updateAtDesc",
                    "order": "DESC"
                },
                "paymentdocstatus_id": {
                    "label": "table.paymentdocstatus"
                },
                "datereception": {
                    "templates": [
                        "."
                    ]
                },
                "datedeferred": {},
                "dateaccounting": {},
                "paymentmethod_id": {
                    "label": "table.paymentmethod"
                },
                "checkdeposit_id": {
                    "label": "table.checkdeposithead"
                },
                "amountAsc": {
                    "label": "form.amount",
                    "order": "ASC",
                    "labelorder": true,
                    "fieldName": "amount"
                },
                "amountDesc": {
                    "label": "form.amount",
                    "order": "DESC",
                    "labelorder": true,
                    "fieldName": "amount"
                },
                "createdAt": {},
                "check_number": {}
            }
        },
        "icon": "monetization",
        "iconColor": "#af925c",
        "afterAddGoView": true,
        "disableRecreateTemplateList": true,
        "listFilters": {
            "fields": {
                "organizer_id": {
                    "target": [],
                    "tableRelatedAlias": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "paymentorganizer_id"
                    },
                    "table": "organizer",
                    "fieldName": "organizer_id",
                    "label": "table.organizer",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "readOnlyUpdate": true,
                        "disabledUpdate": true,
                        "validators": [
                            "required"
                        ],
                        "formNewSection": true,
                        "dbtype": "String",
                        "table": "organizer",
                        "label": "table.organizer"
                    },
                    "tableRelated": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "paymentorganizer_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "application_customer_id": {
                    "fieldContainer": "application",
                    "subfield": "customer_id",
                    "templates": [
                        "."
                    ],
                    "tableRelatedAlias": "customer",
                    "table": "customer",
                    "fieldName": "application.customer_id",
                    "target": [
                        "maincrud"
                    ],
                    "label": "table.customer",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "table": "customer",
                        "add": true,
                        "onDebounce": 1500,
                        "recView": true,
                        "recZoom": true,
                        "loadAsync": true,
                        "saveonly": "id",
                        "dbtype": "Json",
                        "label": "table.customer"
                    },
                    "tableRelated": "customer",
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "application_tour_id": {
                    "fieldContainer": "application",
                    "subfield": "tour_id",
                    "templates": [
                        "."
                    ],
                    "tableRelatedAlias": "tour",
                    "table": "tour",
                    "fieldName": "application.tour_id",
                    "target": [
                        "maincrud"
                    ],
                    "label": "table.tour",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "table": "tour",
                        "validators": [
                            "required"
                        ],
                        "onDebounce": 1500,
                        "label": "table.tour"
                    },
                    "tableRelated": "tour",
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "paymentdocstatus_id": {
                    "templates": [
                        "."
                    ],
                    "tableRelatedAlias": {
                        "table": "paymentdocstatus",
                        "relation": "oneChild",
                        "fields": [
                            "name",
                            "color",
                            "statusbase"
                        ],
                        "key": "paymentdocstatus_id",
                        "alias": "paymentpaymentdocstatus_id"
                    },
                    "table": "paymentdocstatus",
                    "fieldName": "paymentdocstatus_id",
                    "target": [
                        "maincrud"
                    ],
                    "label": "table.paymentdocstatus",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "dbtype": "String",
                        "table": "paymentdocstatus",
                        "label": "table.paymentdocstatus"
                    },
                    "tableRelated": {
                        "table": "paymentdocstatus",
                        "relation": "oneChild",
                        "fields": [
                            "name",
                            "color",
                            "statusbase"
                        ],
                        "key": "paymentdocstatus_id",
                        "alias": "paymentpaymentdocstatus_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "paymentmethod_id": {
                    "tableRelatedAlias": {
                        "table": "paymentmethod",
                        "relation": "oneChild",
                        "fields": [
                            "name",
                            "color",
                            "statusbase",
                            "usedatedeferred"
                        ],
                        "key": "paymentmethod_id",
                        "alias": "paymentpaymentmethod_id"
                    },
                    "table": "paymentmethod",
                    "fieldName": "paymentmethod_id",
                    "target": [
                        "maincrud"
                    ],
                    "label": "table.paymentmethod",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "validators": [
                            "required"
                        ],
                        "onChange": true,
                        "dbtype": "String",
                        "table": "paymentmethod",
                        "label": "table.paymentmethod"
                    },
                    "tableRelated": {
                        "table": "paymentmethod",
                        "relation": "oneChild",
                        "fields": [
                            "name",
                            "color",
                            "statusbase",
                            "usedatedeferred"
                        ],
                        "key": "paymentmethod_id",
                        "alias": "paymentpaymentmethod_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "paymentmethod_id_ajournalcode_id": {
                    "label": "table.ajournalcode",
                    "fieldContainer": "paymentmethod_id",
                    "subfield": "ajournalcode_id",
                    "table": "ajournalcode",
                    "typeInput": "selectAutocomplete",
                    "notJson": true,
                    "templates": [
                        "ajournal"
                    ],
                    "tableRelatedAlias": "paymentpaymentmethod_id",
                    "fieldName": "paymentpaymentmethod_id.ajournalcode_id",
                    "target": [
                        "maincrud",
                        "listmini"
                    ],
                    "fieldSource": {
                        "formNewSection": true,
                        "module": "ACCO",
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "label": "table.ajournalcode",
                        "listmini": true,
                        "fieldDisplayedOptions": {
                            "subfields": {
                                "name": true,
                                "title": true,
                                "counterpart_id": true
                            },
                            "separatorsubfield": " / ",
                            "joinsubfields": false
                        },
                        "dbtype": "String",
                        "table": "ajournalcode"
                    },
                    "tableRelated": "paymentmethod",
                    "type": "String",
                    "listSource": ""
                },
                "_balanceinit": {
                    "virtual": true,
                    "label": "form.balance",
                    "type": "Float",
                    "typeValue": "Price",
                    "templates": [
                        "ajournal"
                    ],
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "_balanceinit",
                    "target": [
                        "maincrud"
                    ],
                    "fieldSource": {
                        "virtual": true,
                        "label": "form.balance",
                        "type": "Float",
                        "typeValue": "Price",
                        "templates": [
                            "ajournal"
                        ],
                        "tableRelatedAlias": ""
                    },
                    "tableRelated": "",
                    "typeInput": "text",
                    "listSource": ""
                },
                "name": {
                    "target": [
                        "maincrud",
                        "listmini"
                    ],
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "name",
                    "label": "form.issuer",
                    "fieldSource": {
                        "type": "String",
                        "label": "form.issuer",
                        "validators": [
                            "required"
                        ],
                        "listmini": true,
                        "dbtype": "String",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "String",
                    "typeInput": "text",
                    "listSource": ""
                },
                "amount": {
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "amount",
                    "target": [
                        "maincrud",
                        "listmini"
                    ],
                    "label": "form.amount",
                    "fieldSource": {
                        "type": "Float",
                        "typeValue": "Price",
                        "normalize": "PriceNegative",
                        "onChange": true,
                        "validators": [
                            "required"
                        ],
                        "listmini": true,
                        "dbtype": "Float",
                        "typeInput": "text",
                        "label": "form.amount"
                    },
                    "tableRelated": "",
                    "type": "Float",
                    "typeInput": "text",
                    "listSource": ""
                },
                "_depositedcheck": {
                    "typeInput": "radio",
                    "type": "Int",
                    "listOptions": [
                        {
                            "id": 1,
                            "name": "form.all"
                        },
                        {
                            "id": 3,
                            "name": "form.yes"
                        },
                        {
                            "id": 5,
                            "name": "form.no"
                        }
                    ],
                    "label": "form.depositedcheck",
                    "showLabel": true,
                    "defaultValue": 1,
                    "templates": [
                        "."
                    ],
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "_depositedcheck",
                    "target": [
                        "maincrud"
                    ],
                    "fieldSource": {
                        "typeInput": "radio",
                        "type": "Int",
                        "listOptions": [
                            {
                                "id": 1,
                                "name": "form.all"
                            },
                            {
                                "id": 3,
                                "name": "form.yes"
                            },
                            {
                                "id": 5,
                                "name": "form.no"
                            }
                        ],
                        "label": "form.depositedcheck",
                        "showLabel": true,
                        "defaultValue": 1,
                        "templates": [
                            "."
                        ],
                        "tableRelatedAlias": ""
                    },
                    "tableRelated": "",
                    "listSource": ""
                },
                "_orders": {
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "_orders",
                    "target": [
                        "maincrud"
                    ],
                    "fieldSource": {
                        "tableRelatedAlias": ""
                    },
                    "tableRelated": "",
                    "typeInput": "text",
                    "listSource": ""
                },
                "_multidate": {
                    "placeHolder": "form.selectTypeDate",
                    "multiField": {
                        "fields": {
                            "datedeferred": {
                                "label": "form.datedeferred",
                                "templates": [
                                    "."
                                ]
                            },
                            "datereception": {
                                "label": "form.datereception",
                                "templates": [
                                    "."
                                ]
                            },
                            "dateaccounting": {
                                "label": "form.dateaccounting"
                            }
                        }
                    },
                    "defaultajournal": "dateaccounting",
                    "defaultajournal_option": "15",
                    "defaultajournal_disabled": true,
                    "formNewSection": true,
                    "typeInput": "selectBox",
                    "typeShowInput": "Date",
                    "type": "String",
                    "placeHolderShow": true,
                    "label": "form.datetype",
                    "translateDisabledSource": true,
                    "listOptions": [
                        {
                            "id": "",
                            "name": "form.allDates",
                            "templates": [
                                "."
                            ]
                        },
                        {
                            "id": "3",
                            "name": "form.today",
                            "templates": [
                                "."
                            ]
                        },
                        {
                            "id": "5",
                            "name": "form.yesterday",
                            "templates": [
                                "."
                            ]
                        },
                        {
                            "id": "7",
                            "name": "form.thisweek",
                            "templates": [
                                "."
                            ]
                        },
                        {
                            "id": "12",
                            "name": "form.last7days"
                        },
                        {
                            "id": "15",
                            "name": "form.last45days"
                        },
                        {
                            "id": "30",
                            "name": "form.untilToday",
                            "templates": [
                                "."
                            ]
                        },
                        {
                            "id": "42",
                            "name": "form.nextweek",
                            "templates": [
                                "."
                            ]
                        },
                        {
                            "id": "45",
                            "name": "form.next7days",
                            "templates": [
                                "."
                            ]
                        },
                        {
                            "id": "50",
                            "name": "form.prevMonth"
                        },
                        {
                            "id": "60",
                            "name": "form.currentMonth"
                        },
                        {
                            "id": "70",
                            "name": "form.nextMonth",
                            "templates": [
                                "."
                            ]
                        },
                        {
                            "id": "501",
                            "name": "form.rangedate"
                        }
                    ],
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "_multidate",
                    "target": [
                        "maincrud"
                    ],
                    "fieldSource": {
                        "placeHolder": "form.selectTypeDate",
                        "multiField": {
                            "fields": {
                                "datedeferred": {
                                    "label": "form.datedeferred",
                                    "templates": [
                                        "."
                                    ]
                                },
                                "datereception": {
                                    "label": "form.datereception",
                                    "templates": [
                                        "."
                                    ]
                                },
                                "dateaccounting": {
                                    "label": "form.dateaccounting"
                                }
                            }
                        },
                        "defaultajournal": "dateaccounting",
                        "defaultajournal_option": "15",
                        "defaultajournal_disabled": true,
                        "formNewSection": true,
                        "typeInput": "selectBox",
                        "typeShowInput": "Date",
                        "type": "String",
                        "placeHolderShow": true,
                        "label": "form.datetype",
                        "translateDisabledSource": true,
                        "listOptions": [
                            {
                                "id": "",
                                "name": "form.allDates",
                                "templates": [
                                    "."
                                ]
                            },
                            {
                                "id": "3",
                                "name": "form.today",
                                "templates": [
                                    "."
                                ]
                            },
                            {
                                "id": "5",
                                "name": "form.yesterday",
                                "templates": [
                                    "."
                                ]
                            },
                            {
                                "id": "7",
                                "name": "form.thisweek",
                                "templates": [
                                    "."
                                ]
                            },
                            {
                                "id": "12",
                                "name": "form.last7days"
                            },
                            {
                                "id": "15",
                                "name": "form.last45days"
                            },
                            {
                                "id": "30",
                                "name": "form.untilToday",
                                "templates": [
                                    "."
                                ]
                            },
                            {
                                "id": "42",
                                "name": "form.nextweek",
                                "templates": [
                                    "."
                                ]
                            },
                            {
                                "id": "45",
                                "name": "form.next7days",
                                "templates": [
                                    "."
                                ]
                            },
                            {
                                "id": "50",
                                "name": "form.prevMonth"
                            },
                            {
                                "id": "60",
                                "name": "form.currentMonth"
                            },
                            {
                                "id": "70",
                                "name": "form.nextMonth",
                                "templates": [
                                    "."
                                ]
                            },
                            {
                                "id": "501",
                                "name": "form.rangedate"
                            }
                        ],
                        "tableRelatedAlias": ""
                    },
                    "tableRelated": "",
                    "listSource": ""
                },
                "id": {
                    "target": "listmini",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "id",
                    "fieldSource": {
                        "type": "ID!",
                        "listmini": true,
                        "dbtype": "ID",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "ID!",
                    "typeInput": "text",
                    "listSource": ""
                }
            }
        },
        "toolbar": {
            "menus": {
                "duplicate": {
                    "inView": true,
                    "label": "info.duplicate",
                    "urlType": "fn"
                },
                "checkdeposithead": {
                    "inListHeader": true,
                    "label": "table.checkdeposithead",
                    "urlType": "link",
                    "icon": "checkdepositlist",
                    "url": "/checkdeposithead"
                },
                "paymenthistory": {
                    "icon": "log",
                    "label": "table.paymenthistory",
                    "component": "PaymentHistory",
                    "urlType": "child",
                    "inList": false,
                    "inView": true
                }
            }
        },
        "detail": {
            "name": true,
            "amount": true
        },
        "logFields": {
            "name": true,
            "amount": true
        },
        "fields": {
            "id": {
                "type": "ID!",
                "listmini": true,
                "dbtype": "ID",
                "typeInput": "text"
            },
            "name": {
                "type": "String",
                "label": "form.issuer",
                "validators": [
                    "required"
                ],
                "listmini": true,
                "dbtype": "String",
                "typeInput": "text"
            },
            "paymentmethod_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "validators": [
                    "required"
                ],
                "onChange": true,
                "dbtype": "String",
                "table": "paymentmethod",
                "label": "table.paymentmethod"
            },
            "amount": {
                "type": "Float",
                "typeValue": "Price",
                "normalize": "PriceNegative",
                "onChange": true,
                "validators": [
                    "required"
                ],
                "listmini": true,
                "dbtype": "Float",
                "typeInput": "text",
                "label": "form.amount"
            },
            "datereception": {
                "type": "String",
                "dbtype": "Date",
                "listmini": true,
                "defaultValue": "f:today",
                "onChange": true,
                "validators": [
                    "daysLimitOld:90",
                    "daysLimitOldToday",
                    "date"
                ],
                "typeInput": "text",
                "label": "form.datereception"
            },
            "datedeferred": {
                "type": "String",
                "dbtype": "Date",
                "listmini": true,
                "onChange": true,
                "onDisabled": true,
                "validators": [
                    "daysLimitOld:60",
                    "daysLimitFuture:367",
                    "date"
                ],
                "typeInput": "text",
                "label": "form.datedeferred"
            },
            "dateaccounting": {
                "type": "String",
                "dbtype": "Date",
                "listmini": true,
                "onChange": true,
                "defaultValue": "f:today",
                "validators": [
                    "required",
                    "daysLimitOld:90",
                    "daysLimitFuture:367",
                    "date"
                ],
                "typeInput": "text",
                "label": "form.dateaccounting"
            },
            "bank_id": {
                "formNewSection": true,
                "type": "String",
                "dbtype": "Json",
                "typeInput": "selectAutocomplete",
                "add": true,
                "listmini": true,
                "table": "bank",
                "loadAsync": true,
                "saveonly": "id",
                "label": "table.bank"
            },
            "bank_agence": {
                "type": "String",
                "nameGroup": "info",
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.bank_agence"
            },
            "paymentdocstatus_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "dbtype": "String",
                "table": "paymentdocstatus",
                "label": "table.paymentdocstatus"
            },
            "check_number": {
                "type": "String",
                "nameGroup": "info",
                "listmini": true,
                "validators": [
                    "maxLength:25"
                ],
                "onDebounce": 1500,
                "qlFilter": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.check_number"
            },
            "application": {
                "type": "String",
                "dbtype": "Json",
                "label": "form.payment_application",
                "sublabel": "info.payment_application",
                "labelChildsShow": true,
                "placeHolderShowChildsShow": false,
                "mode": {
                    "insertRowOnAdd": 1
                },
                "subfields": {
                    "id": {
                        "type": "ID",
                        "typeInput": "hidden",
                        "label": "form.id"
                    },
                    "customer_id": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "table": "customer",
                        "add": true,
                        "onDebounce": 1500,
                        "recView": true,
                        "recZoom": true,
                        "loadAsync": true,
                        "saveonly": "id",
                        "dbtype": "Json",
                        "label": "table.customer"
                    },
                    "tour_id": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "table": "tour",
                        "validators": [
                            "required"
                        ],
                        "onDebounce": 1500,
                        "label": "table.tour"
                    },
                    "amount": {
                        "type": "Float",
                        "typeValue": "Price",
                        "normalize": "PriceNegative",
                        "validators": [
                            "required"
                        ],
                        "onChange": true,
                        "typeInput": "text",
                        "label": "form.amount"
                    }
                },
                "formNewSection": true,
                "typeInput": "text"
            },
            "aentry": {
                "module": "ACCO",
                "type": "String",
                "dbtype": "Json",
                "label": "form.aentry",
                "labelChildsShow": true,
                "placeHolderShowChildsShow": false,
                "subfields": {
                    "id": {
                        "type": "ID",
                        "typeInput": "hidden",
                        "label": "form.id"
                    },
                    "aplan": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "table": "aplan",
                        "add": true,
                        "validators": [
                            "required"
                        ],
                        "label": "table.aplan"
                    },
                    "atitle": {
                        "type": "String",
                        "validators": [
                            "maxLength:50"
                        ],
                        "typeInput": "text",
                        "label": "form.atitle"
                    },
                    "amount": {
                        "type": "Float",
                        "typeValue": "Price",
                        "normalize": "PriceNegative",
                        "validators": [
                            "required"
                        ],
                        "onChange": true,
                        "typeInput": "text",
                        "label": "form.amount"
                    },
                    "reference": {
                        "type": "String",
                        "validators": [
                            "maxLength:7"
                        ],
                        "typeInput": "text",
                        "label": "form.reference"
                    }
                },
                "formNewSection": true,
                "typeInput": "text",
                "mode": {}
            },
            "balance": {
                "disabledMutation": true,
                "label": "form.applicationBalance",
                "type": "Float",
                "typeValue": "Price",
                "formNewSection": true,
                "dbtype": "Float",
                "typeInput": "text",
                "normalize": "Price"
            },
            "notes": {
                "type": "String",
                "typeInput": "textarea",
                "nameGroup": "info",
                "formNewSection": true,
                "dbtype": "String",
                "label": "form.notes"
            },
            "info": {
                "type": "String",
                "dbtype": "Json",
                "fatherGroup": true,
                "listmini": true,
                "typeInput": "text",
                "label": "form.info"
            },
            "checkdeposit_id": {
                "disabledMutation": true,
                "type": "String",
                "excludedInForm": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.checkdeposit_id"
            },
            "counterhistory": {
                "type": "Int",
                "disabledMutation": true,
                "excludedInForm": true,
                "label": "form.crm",
                "dbtype": "Int",
                "typeInput": "text"
            },
            "person_groupid": {
                "excludedInForm": true,
                "disabledSystemUpdate": true,
                "type": "ID",
                "dbtype": "ID",
                "typeInput": "text",
                "label": "form.person_groupid"
            },
            "updatedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "dbAutoUpdate": true,
                "excludedInForm": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.updatedAt"
            },
            "createdAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "dbAutoUpdate": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.createdAt"
            },
            "deletedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "disabledMutation": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.deletedAt"
            },
            "deleted": {
                "type": "Boolean",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.deleted"
            },
            "updated_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.updated_user_id"
            },
            "created_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.created_user_id"
            },
            "deleted_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.deleted_user_id"
            },
            "organizer_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "readOnlyUpdate": true,
                "disabledUpdate": true,
                "validators": [
                    "required"
                ],
                "formNewSection": true,
                "dbtype": "String",
                "table": "organizer",
                "label": "table.organizer"
            }
        },
        "listview": {
            "fields": {
                "name": true,
                "counterhistory": {
                    "icon": "log",
                    "childTable": "paymenthistory",
                    "filter": "!Empty"
                },
                "ajournalcode": {
                    "templates": [
                        "ajournal"
                    ],
                    "virtual": true,
                    "label": "table.ajournalcode"
                },
                "paymentmethod_id": true,
                "adebit": {
                    "templates": [
                        "ajournal"
                    ],
                    "virtual": true,
                    "type": "String",
                    "dbtype": "Json",
                    "label": "form.debit",
                    "style": {
                        "color": "#ed2939",
                        "fontWeight": "bold",
                        "textAlign": "right"
                    },
                    "subfields": {
                        "amount": {
                            "type": "Float",
                            "typeValue": "Price"
                        }
                    }
                },
                "acredit": {
                    "templates": [
                        "ajournal"
                    ],
                    "virtual": true,
                    "type": "String",
                    "dbtype": "Json",
                    "label": "form.credit",
                    "style": {
                        "color": "#3551de",
                        "fontWeight": "bold",
                        "textAlign": "right"
                    },
                    "subfields": {
                        "amount": {
                            "type": "Float",
                            "typeValue": "Price"
                        }
                    }
                },
                "abalance": {
                    "templates": [
                        "ajournal"
                    ],
                    "virtual": true,
                    "type": "String",
                    "dbtype": "Json",
                    "label": "form.balance",
                    "style": {
                        "color": "#000000",
                        "fontWeight": "bold",
                        "textAlign": "right"
                    },
                    "subfields": {
                        "amount": {
                            "type": "Float",
                            "typeValue": "Price"
                        }
                    }
                },
                "aplan": {
                    "templates": [
                        "ajournal"
                    ],
                    "virtual": true,
                    "type": "String",
                    "dbtype": "Json",
                    "label": "table.aplan",
                    "labelChildsShow": true,
                    "placeHolderShowChildsShow": false,
                    "subfields": {
                        "acode_id": {
                            "type": "String"
                        }
                    },
                    "separatorsubfield": " / ",
                    "joinsubfields": false
                },
                "amount": true,
                "application": {
                    "subfields": {
                        "amount": true,
                        "customer_id": {
                            "linkview": "customer"
                        },
                        "tour_id": true,
                        "acode_id": {
                            "templates": [
                                "ajournal"
                            ]
                        }
                    },
                    "separatorsubfield": " / ",
                    "joinsubfields": false
                },
                "balance": {
                    "hiddenZero": true
                },
                "dateaccounting": {},
                "datedeferred": {
                    "hiddenPhone": true,
                    "templates": [
                        "."
                    ]
                },
                "datereception": {
                    "templates": [
                        "."
                    ]
                },
                "check_number": {
                    "hiddenPhone": true
                },
                "paymentdocstatus_id": true,
                "checkdeposit_id": {
                    "label": "form.checkdeposit_id"
                },
                "organizer_id": true
            }
        },
        "norelated": {
            "customer": {
                "norelated": true,
                "table": "customer",
                "fields": [
                    "name"
                ]
            },
            "paymentmethod_id_ajournalcode_id": {
                "norelated": true,
                "table": "ajournalcode",
                "fields": [
                    "name"
                ]
            }
        },
        "related": {
            "bank_id": {
                "table": "bank",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "bank_id",
                "alias": "paymentbank_id"
            },
            "paymentmethod_id": {
                "table": "paymentmethod",
                "relation": "oneChild",
                "fields": [
                    "name",
                    "color",
                    "statusbase",
                    "usedatedeferred"
                ],
                "key": "paymentmethod_id",
                "alias": "paymentpaymentmethod_id"
            },
            "checkdeposit_id": {
                "table": "checkdeposithead",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "checkdeposit_id",
                "alias": "paymentcheckdeposit_id"
            },
            "paymentdocstatus_id": {
                "table": "paymentdocstatus",
                "relation": "oneChild",
                "fields": [
                    "name",
                    "color",
                    "statusbase"
                ],
                "key": "paymentdocstatus_id",
                "alias": "paymentpaymentdocstatus_id"
            },
            "organizer_id": {
                "table": "organizer",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "organizer_id",
                "alias": "paymentorganizer_id"
            },
            "updatedUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "updated_user_id",
                "qlDisable": true,
                "alias": "paymentupdated_user_id"
            },
            "createdUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "created_user_id",
                "qlDisable": true,
                "alias": "paymentcreated_user_id"
            }
        },
        "name": {
            "singular": "payment",
            "plural": "payments",
            "singularCase": "Payment",
            "pluralCase": "Payments"
        },
        "mainfield": "name",
        "miniFields": [
            "id",
            "name",
            "amount",
            "datereception",
            "datedeferred",
            "dateaccounting",
            "bank_id",
            "info"
        ],
        "componentName": "PaymentView",
        "path": "/payment-view/:id"
    },
    "checkdeposithead": {
        "help": {
            "fr-PELE": "https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.ljmqmn7j538g"
        },
        "formToolBarBottom": true,
        "triggers": true,
        "preloadHeight": "600px",
        "destination": [
            "serverSchema",
            "serverResolver",
            "ormModels",
            "components",
            "app"
        ],
        "listOrder": "\"checkdeposithead\".\"createdAt\":DESC",
        "icon": "checkdepositlist",
        "iconColor": "#af925c",
        "disableRecreateTemplateView": true,
        "disabledDeleteWhen": {
            "statusbase": {
                "$equal": 5
            }
        },
        "hardReloadAfterSave": true,
        "listFilters": {
            "fields": {
                "organizer_id": {
                    "target": [],
                    "tableRelatedAlias": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "checkdepositheadorganizer_id"
                    },
                    "table": "organizer",
                    "fieldName": "organizer_id",
                    "label": "table.organizer",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "readOnlyUpdate": true,
                        "disabledUpdate": true,
                        "validators": [
                            "required"
                        ],
                        "formNewSection": true,
                        "dbtype": "String",
                        "table": "organizer",
                        "label": "table.organizer"
                    },
                    "tableRelated": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "checkdepositheadorganizer_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "name": {
                    "target": [
                        "maincrud",
                        "listmini"
                    ],
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "name",
                    "label": "form.number",
                    "fieldSource": {
                        "type": "String",
                        "label": "form.number",
                        "validators": [
                            "required"
                        ],
                        "disabledWhen": {
                            "init_statusbase": {
                                "$equal": 5
                            }
                        },
                        "listmini": true,
                        "dbtype": "String",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "String",
                    "typeInput": "text",
                    "listSource": ""
                },
                "id": {
                    "target": "listmini",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "id",
                    "fieldSource": {
                        "type": "ID!",
                        "listmini": true,
                        "dbtype": "ID",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "ID!",
                    "typeInput": "text",
                    "listSource": ""
                }
            }
        },
        "toolbar": {
            "menus": {
                "payment": {
                    "icon": "monetization",
                    "label": "table.payments",
                    "urlType": "link",
                    "url": "/payment",
                    "inListHeader": true
                },
                "download": {
                    "icon": "download",
                    "label": "table.checkdepositdet",
                    "urlType": "filter",
                    "inList": true,
                    "inView": true
                }
            }
        },
        "detail": {
            "name": true,
            "amount": true
        },
        "logFields": {
            "name": true
        },
        "fields": {
            "id": {
                "type": "ID!",
                "listmini": true,
                "dbtype": "ID",
                "typeInput": "text"
            },
            "name": {
                "type": "String",
                "label": "form.number",
                "validators": [
                    "required"
                ],
                "disabledWhen": {
                    "init_statusbase": {
                        "$equal": 5
                    }
                },
                "listmini": true,
                "dbtype": "String",
                "typeInput": "text"
            },
            "statusbase": {
                "type": "Int",
                "dbtype": "Int",
                "typeInput": "selectBox",
                "listSource": "processTransaction",
                "listmini": true,
                "hiddenOnAdd": true,
                "disabledOnAdd": true,
                "disabledWhen": {
                    "init_statusbase": {
                        "$equal": 5
                    }
                },
                "defaultValue": 3,
                "validators": [
                    "required"
                ],
                "label": "form.statusbase"
            },
            "date": {
                "type": "String",
                "dbtype": "Date",
                "defaultValue": "f:today",
                "validators": [
                    "daysLimitOld:30",
                    "date"
                ],
                "disabledWhen": {
                    "init_statusbase": {
                        "$equal": 5
                    }
                },
                "formNewSection": true,
                "typeInput": "text",
                "label": "form.date"
            },
            "dateaccounting": {
                "type": "String",
                "dbtype": "Date",
                "validators": [
                    "daysLimitFuture:30",
                    {
                        "validator": "cantBeLowerThan",
                        "field": "date"
                    },
                    "date"
                ],
                "typeInput": "text",
                "label": "form.dateaccounting"
            },
            "notes": {
                "type": "String",
                "typeInput": "textarea",
                "nameGroup": "info",
                "formNewSection": true,
                "dbtype": "String",
                "label": "form.notes"
            },
            "info": {
                "type": "String",
                "dbtype": "Json",
                "fatherGroup": true,
                "typeInput": "text",
                "label": "form.info"
            },
            "lastcheckdepositopen": {
                "type": "ID",
                "excludedInForm": true,
                "disabledMutation": true,
                "virtual": true,
                "dbtype": "ID",
                "typeInput": "text",
                "label": "form.lastcheckdepositopen"
            },
            "updatedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "dbAutoUpdate": true,
                "excludedInForm": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.updatedAt"
            },
            "createdAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "dbAutoUpdate": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.createdAt"
            },
            "deletedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "disabledMutation": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.deletedAt"
            },
            "deleted": {
                "type": "Boolean",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.deleted"
            },
            "updated_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.updated_user_id"
            },
            "created_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.created_user_id"
            },
            "deleted_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.deleted_user_id"
            },
            "organizer_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "readOnlyUpdate": true,
                "disabledUpdate": true,
                "validators": [
                    "required"
                ],
                "formNewSection": true,
                "dbtype": "String",
                "table": "organizer",
                "label": "table.organizer"
            }
        },
        "listview": {
            "fields": {
                "name": true,
                "statusbase": true,
                "date": true,
                "dateaccounting": true,
                "organizer_id": true
            }
        },
        "name": {
            "singular": "checkdeposithead",
            "plural": "checkdepositheads",
            "singularCase": "Checkdeposithead",
            "pluralCase": "Checkdepositheads"
        },
        "mainfield": "name",
        "related": {
            "organizer_id": {
                "table": "organizer",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "organizer_id",
                "alias": "checkdepositheadorganizer_id"
            },
            "updatedUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "updated_user_id",
                "qlDisable": true,
                "alias": "checkdepositheadupdated_user_id"
            },
            "createdUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "created_user_id",
                "qlDisable": true,
                "alias": "checkdepositheadcreated_user_id"
            }
        },
        "miniFields": [
            "id",
            "name",
            "statusbase"
        ],
        "componentName": "CheckdepositheadView",
        "path": "/checkdeposithead-view/:id"
    },
    "checkdepositdet": {
        "help": {
            "fr-PELE": "https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.ljmqmn7j538g"
        },
        "parentTable": "checkdeposithead",
        "parentRelationField": "checkdeposithead_id",
        "disableRecreateTemplateList": true,
        "disabledView": true,
        "disabledUpdate": true,
        "disableRecreateQlFragment": true,
        "disabledPermissionOption": true,
        "permissionTable": "checkdeposithead",
        "disabledAdd": true,
        "toolbarTable": "payment",
        "fields": {
            "id": {
                "type": "ID!",
                "listmini": true,
                "dbtype": "ID",
                "typeInput": "text"
            },
            "payment_id": {
                "type": "String",
                "typeInput": "hidden",
                "isId": true,
                "label": "form.checkbank",
                "fieldDisplayedOptions": {
                    "subfields": {
                        "name": {
                            "linkview": "payment"
                        },
                        "bank_id": true,
                        "bank_agence": true,
                        "check_number": true,
                        "datedeferred": true,
                        "amount": true,
                        "dateaccounting": true,
                        "datereception": true,
                        "application": {
                            "subfields": {
                                "amount": true,
                                "customer_id": true,
                                "tour_id": true,
                                "acode_id": {
                                    "templates": [
                                        "ajournal"
                                    ]
                                }
                            },
                            "separatorsubfield": " / ",
                            "joinsubfields": false
                        }
                    },
                    "separatorsubfield": "col"
                },
                "dbtype": "String"
            },
            "checkdeposithead_id": {
                "type": "String",
                "typeInput": "hidden",
                "isId": true,
                "dbtype": "String",
                "label": "form.checkdeposithead_id"
            },
            "updatedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "dbAutoUpdate": true,
                "excludedInForm": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.updatedAt"
            },
            "createdAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "dbAutoUpdate": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.createdAt"
            },
            "deletedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "disabledMutation": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.deletedAt"
            },
            "deleted": {
                "type": "Boolean",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.deleted"
            },
            "updated_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.updated_user_id"
            },
            "created_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.created_user_id"
            },
            "deleted_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.deleted_user_id"
            },
            "organizer_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "readOnlyUpdate": true,
                "disabledUpdate": true,
                "validators": [
                    "required"
                ],
                "formNewSection": true,
                "dbtype": "String",
                "table": "organizer",
                "label": "table.organizer"
            }
        },
        "listview": {
            "fields": {
                "payment_id": {},
                "organizer_id": true
            }
        },
        "icon": "checkbank",
        "destination": [
            "serverSchema",
            "serverResolver",
            "ormModels",
            "components",
            "app"
        ],
        "orders": {
            "noOptionBlank": true,
            "defaultValue": "datedeferredDesc",
            "label": "form.order",
            "inLine": true,
            "fields": {
                "datedeferredDesc": {
                    "label": "form.datedeferred",
                    "mainRelated": "checkdepositdetpayment_id",
                    "order": "ASC",
                    "fieldName": "datedeferred"
                },
                "datereception": {
                    "label": "form.datereception",
                    "mainRelated": "checkdepositdetpayment_id",
                    "order": "ASC",
                    "fieldName": "datereception"
                },
                "amountAsc": {
                    "label": "form.amount",
                    "mainRelated": "checkdepositdetpayment_id",
                    "order": "ASC",
                    "fieldName": "amount"
                }
            }
        },
        "related": {
            "payment_id": {
                "table": "payment",
                "relation": "oneChild",
                "fields": [
                    "name",
                    "datereception",
                    "datedeferred",
                    "dateaccounting",
                    "bank_id",
                    "bank_agence",
                    "check_number",
                    "amount",
                    "application"
                ],
                "key": "payment_id",
                "listmini": true,
                "subrelated": true,
                "alias": "checkdepositdetpayment_id"
            },
            "checkdeposithead_id": {
                "table": "checkdeposithead",
                "isParent": true,
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "checkdeposithead_id",
                "alias": "checkdepositdetcheckdeposithead_id"
            },
            "organizer_id": {
                "table": "organizer",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "organizer_id",
                "alias": "checkdepositdetorganizer_id"
            },
            "updatedUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "updated_user_id",
                "qlDisable": true,
                "alias": "checkdepositdetupdated_user_id"
            },
            "createdUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "created_user_id",
                "qlDisable": true,
                "alias": "checkdepositdetcreated_user_id"
            }
        },
        "name": {
            "singular": "checkdepositdet",
            "plural": "checkdepositdets",
            "singularCase": "Checkdepositdet",
            "pluralCase": "Checkdepositdets"
        },
        "mainfield": "payment_id",
        "miniFields": [
            "id"
        ],
        "listFilters": {
            "fields": {
                "organizer_id": {
                    "target": [],
                    "tableRelatedAlias": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "checkdepositdetorganizer_id"
                    },
                    "table": "organizer",
                    "fieldName": "organizer_id",
                    "label": "table.organizer",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "readOnlyUpdate": true,
                        "disabledUpdate": true,
                        "validators": [
                            "required"
                        ],
                        "formNewSection": true,
                        "dbtype": "String",
                        "table": "organizer",
                        "label": "table.organizer"
                    },
                    "tableRelated": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "checkdepositdetorganizer_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "checkdeposithead_id": {
                    "tableRelatedAlias": {
                        "table": "checkdeposithead",
                        "isParent": true,
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "checkdeposithead_id",
                        "alias": "checkdepositdetcheckdeposithead_id"
                    },
                    "table": "checkdeposithead",
                    "fieldName": "checkdeposithead_id",
                    "target": [
                        "maincrud"
                    ],
                    "label": "form.checkdeposithead_id",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "hidden",
                        "isId": true,
                        "dbtype": "String",
                        "label": "form.checkdeposithead_id"
                    },
                    "tableRelated": {
                        "table": "checkdeposithead",
                        "isParent": true,
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "checkdeposithead_id",
                        "alias": "checkdepositdetcheckdeposithead_id"
                    },
                    "type": "String",
                    "typeInput": "hidden",
                    "listSource": ""
                },
                "id": {
                    "target": "listmini",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "id",
                    "fieldSource": {
                        "type": "ID!",
                        "listmini": true,
                        "dbtype": "ID",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "ID!",
                    "typeInput": "text",
                    "listSource": ""
                }
            }
        },
        "componentName": "CheckdepositdetList",
        "path": "/checkdeposithead-child/:parentid/checkdepositdet"
    },
    "export": {
        "disableRecreateTemplateAdd": true,
        "disableRecreateTemplateForm": true,
        "pollInterval": 5,
        "listOrder": "\"export\".\"updatedAt\":DESC",
        "help": {
            "fr-PELE": "https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#bookmark=id.h1zyok6hg9u2"
        },
        "fields": {
            "id": {
                "type": "ID!",
                "listmini": true,
                "dbtype": "ID",
                "typeInput": "text"
            },
            "name": {
                "type": "String!",
                "validators": [
                    "minLength:3",
                    "maxLength:30"
                ],
                "disabledOnAdd": true,
                "hiddenOnAdd": true,
                "defaultValue": "Export",
                "listmini": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.name"
            },
            "state": {
                "type": "Int",
                "dbtype": "Int",
                "typeInput": "selectBox",
                "listSource": "processBaseStatus",
                "listmini": true,
                "disabledOnAdd": true,
                "sublabel": "info.exportstate",
                "defaultValue": 1,
                "icon": true,
                "hiddenOnAdd": true,
                "label": "form.state"
            },
            "exportdate": {
                "disabled": true,
                "type": "String",
                "dbtype": "Datetime",
                "hiddenOnAdd": true,
                "disabledSystemUpdate": true,
                "typeInput": "text",
                "label": "form.exportdate"
            },
            "type": {
                "type": "Int",
                "dbtype": "Int",
                "typeInput": "radio",
                "listOptions": [
                    {
                        "id": "",
                        "name": "table.tours"
                    },
                    {
                        "id": 1,
                        "name": "table.customers"
                    }
                ],
                "showLabel": true,
                "onChange": true,
                "disabled": true,
                "label": "form.type"
            },
            "files": {
                "type": "String",
                "dbtype": "Json",
                "label": "table.tours",
                "placeHolderShowChildsShow": true,
                "onHidden": true,
                "mode": {
                    "disabledRowAddOnUpdate": true,
                    "disabledRowInputsOnUpdate": true,
                    "disabledRowDeleteOnUpdate": true,
                    "insertRowOnAdd": 1
                },
                "subfields": {
                    "id": {
                        "type": "ID",
                        "typeInput": "hidden",
                        "label": "form.id"
                    },
                    "tour_id": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "table": "tour",
                        "validators": [
                            "required"
                        ],
                        "validateIfEmpty": "sinfo",
                        "hiddenOnUpdate": true,
                        "label": "table.tour"
                    },
                    "badges": {
                        "type": "Boolean",
                        "typeInput": "checkbox",
                        "disabledOnUpdate": true,
                        "label": "form.badges"
                    },
                    "labels": {
                        "type": "Boolean",
                        "typeInput": "checkbox",
                        "disabledOnUpdate": true,
                        "label": "form.labels"
                    },
                    "filters": {
                        "type": "Int",
                        "dbtype": "Int",
                        "typeInput": "selectBox",
                        "listOptions": [
                            {
                                "id": "",
                                "name": "form.no"
                            },
                            {
                                "id": 10,
                                "name": "table.registrations"
                            },
                            {
                                "id": 20,
                                "name": "table.transportations"
                            },
                            {
                                "id": 30,
                                "name": "table.hotel"
                            },
                            {
                                "id": 40,
                                "name": "table.services"
                            },
                            {
                                "id": 50,
                                "name": "table.payments"
                            }
                        ],
                        "disabledOnUpdate": true,
                        "label": "form.filters"
                    },
                    "sinfo": {
                        "label": "table.tour",
                        "type": "String",
                        "hiddenOnAdd": true,
                        "disabled": true,
                        "typeInput": "text"
                    },
                    "file": {
                        "type": "String",
                        "disabled": true,
                        "hiddenOnAdd": true,
                        "url": "{DOWN_URI}{link}",
                        "typeInput": "urlLink",
                        "noLabel": true,
                        "label": "form.file"
                    }
                },
                "typeInput": "text"
            },
            "registration_fields": {
                "type": "String",
                "dbtype": "Json",
                "typeInput": "hidden",
                "label": "table.registration"
            },
            "updatedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "dbAutoUpdate": true,
                "excludedInForm": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.updatedAt"
            },
            "createdAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "dbAutoUpdate": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.createdAt"
            },
            "deletedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "disabledMutation": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.deletedAt"
            },
            "deleted": {
                "type": "Boolean",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.deleted"
            },
            "updated_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.updated_user_id"
            },
            "created_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.created_user_id"
            },
            "deleted_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.deleted_user_id"
            },
            "organizer_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "readOnlyUpdate": true,
                "disabledUpdate": true,
                "validators": [
                    "required"
                ],
                "formNewSection": true,
                "dbtype": "String",
                "table": "organizer",
                "label": "table.organizer"
            }
        },
        "listview": {
            "fields": {
                "name": true,
                "type": true,
                "state": true,
                "exportdate": true,
                "files": {
                    "subfields": {
                        "file": true,
                        "sinfo": true
                    },
                    "joinsubfields": true
                },
                "organizer_id": true
            }
        },
        "icon": "export",
        "formlayout": [
            "1Row1Field"
        ],
        "destination": [
            "serverSchema",
            "serverResolver",
            "ormModels",
            "components",
            "app",
            "menus"
        ],
        "name": {
            "singular": "export",
            "plural": "exports",
            "singularCase": "Export",
            "pluralCase": "Exports"
        },
        "mainfield": "name",
        "related": {
            "organizer_id": {
                "table": "organizer",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "organizer_id",
                "alias": "exportorganizer_id"
            },
            "updatedUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "updated_user_id",
                "qlDisable": true,
                "alias": "exportupdated_user_id"
            },
            "createdUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "created_user_id",
                "qlDisable": true,
                "alias": "exportcreated_user_id"
            }
        },
        "miniFields": [
            "id",
            "name",
            "state"
        ],
        "listFilters": {
            "fields": {
                "organizer_id": {
                    "target": [],
                    "tableRelatedAlias": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "exportorganizer_id"
                    },
                    "table": "organizer",
                    "fieldName": "organizer_id",
                    "label": "table.organizer",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "readOnlyUpdate": true,
                        "disabledUpdate": true,
                        "validators": [
                            "required"
                        ],
                        "formNewSection": true,
                        "dbtype": "String",
                        "table": "organizer",
                        "label": "table.organizer"
                    },
                    "tableRelated": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "exportorganizer_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "id": {
                    "target": "listmini",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "id",
                    "fieldSource": {
                        "type": "ID!",
                        "listmini": true,
                        "dbtype": "ID",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "ID!",
                    "typeInput": "text",
                    "listSource": ""
                },
                "name": {
                    "target": [
                        "listmini"
                    ],
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "name",
                    "label": "form.name",
                    "fieldSource": {
                        "type": "String!",
                        "validators": [
                            "minLength:3",
                            "maxLength:30"
                        ],
                        "disabledOnAdd": true,
                        "hiddenOnAdd": true,
                        "defaultValue": "Export",
                        "listmini": true,
                        "dbtype": "String",
                        "typeInput": "text",
                        "label": "form.name"
                    },
                    "tableRelated": "",
                    "type": "String!",
                    "typeInput": "text",
                    "listSource": ""
                }
            }
        },
        "componentName": "ExportView",
        "path": "/export-view/:id"
    },
    "hotel": {
        "help": {
            "fr-PELE": "https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.v9cg88egwi1d"
        },
        "uniquefields": {
            "1": [
                "name"
            ]
        },
        "pagesettings": true,
        "fields": {
            "id": {
                "type": "ID!",
                "listmini": true,
                "dbtype": "ID",
                "typeInput": "text"
            },
            "name": {
                "type": "String",
                "validators": [
                    "required",
                    "minLength:3",
                    "maxLength:22"
                ],
                "sublabel": "info.hotelshortname",
                "sublabeldown": true,
                "listmini": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.name"
            },
            "longname": {
                "type": "String",
                "validators": [
                    "required",
                    "minLength:3"
                ],
                "sublabel": "info.longname",
                "sublabeldown": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.longname"
            },
            "email": {
                "type": "String",
                "validators": [
                    "email"
                ],
                "normalize": "LowerCase",
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.email"
            },
            "phone": {
                "type": "String",
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.phone"
            },
            "tour_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "dbtype": "String",
                "table": "tour",
                "label": "table.tour"
            },
            "info": {
                "type": "String",
                "dbtype": "Json",
                "fatherGroup": true,
                "typeInput": "text",
                "label": "form.info"
            },
            "addressline1": {
                "type": "String",
                "placeHolderShow": true,
                "nameGroup": "info",
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.addressline1"
            },
            "addressline2": {
                "type": "String",
                "placeHolderShow": true,
                "nameGroup": "info",
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.addressline2"
            },
            "cp": {
                "type": "String",
                "placeHolderShow": true,
                "inputSize": 7,
                "nameGroup": "info",
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.cp"
            },
            "city": {
                "type": "String",
                "placeHolderShow": true,
                "nameGroup": "info",
                "sublabel": "info.citywillnotbeadd",
                "sublabeldown": true,
                "validators": [
                    "required"
                ],
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.city"
            },
            "cityid": {
                "type": "ID",
                "placeHolderShow": true,
                "typeInput": "hidden",
                "nameGroup": "info",
                "dbtype": "ID",
                "label": "form.cityid"
            },
            "country": {
                "type": "String",
                "validators": [
                    "required"
                ],
                "typeInput": "selectAutocomplete",
                "listSource": "countries",
                "saveIdOnly": true,
                "nameGroup": "info",
                "dbtype": "String",
                "translateDisabledSource": true,
                "label": "form.country"
            },
            "contacts": {
                "type": "String",
                "dbtype": "Json",
                "subfields": {
                    "id": {
                        "type": "ID",
                        "typeInput": "hidden",
                        "label": "form.id"
                    },
                    "name": {
                        "type": "String",
                        "placeHolderShow": true,
                        "validators": [
                            "required"
                        ],
                        "typeInput": "text",
                        "label": "form.name"
                    },
                    "phone": {
                        "type": "String",
                        "icon": "phoneblack",
                        "typeInput": "text",
                        "label": "form.phone"
                    },
                    "email": {
                        "type": "String",
                        "validators": [
                            "email"
                        ],
                        "typeInput": "text",
                        "label": "form.email"
                    }
                },
                "typeInput": "text",
                "label": "form.contacts",
                "mode": {}
            },
            "updatedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "dbAutoUpdate": true,
                "excludedInForm": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.updatedAt"
            },
            "createdAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "dbAutoUpdate": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.createdAt"
            },
            "deletedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "disabledMutation": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.deletedAt"
            },
            "deleted": {
                "type": "Boolean",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.deleted"
            },
            "updated_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.updated_user_id"
            },
            "created_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.created_user_id"
            },
            "deleted_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.deleted_user_id"
            },
            "organizer_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "readOnlyUpdate": true,
                "disabledUpdate": true,
                "validators": [
                    "required"
                ],
                "formNewSection": true,
                "dbtype": "String",
                "table": "organizer",
                "label": "table.organizer"
            }
        },
        "listview": {
            "fields": {
                "name": true,
                "phone": true,
                "email": true,
                "city": true,
                "country": true,
                "contacts": {
                    "subfields": {
                        "name": true,
                        "phone": true,
                        "email": true
                    },
                    "joinsubfields": true
                },
                "organizer_id": true
            }
        },
        "related": {
            "tour_id": {
                "table": "tour",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "tour_id",
                "alias": "hoteltour_id"
            },
            "organizer_id": {
                "table": "organizer",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "organizer_id",
                "alias": "hotelorganizer_id"
            },
            "updatedUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "updated_user_id",
                "qlDisable": true,
                "alias": "hotelupdated_user_id"
            },
            "createdUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "created_user_id",
                "qlDisable": true,
                "alias": "hotelcreated_user_id"
            }
        },
        "icon": "hotel",
        "formToolBarBottom": true,
        "destination": [
            "serverSchema",
            "serverResolver",
            "ormModels",
            "components",
            "app",
            "menus"
        ],
        "name": {
            "singular": "hotel",
            "plural": "hotels",
            "singularCase": "Hotel",
            "pluralCase": "Hotels"
        },
        "mainfield": "name",
        "miniFields": [
            "id",
            "name"
        ],
        "listFilters": {
            "fields": {
                "organizer_id": {
                    "target": [],
                    "tableRelatedAlias": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "hotelorganizer_id"
                    },
                    "table": "organizer",
                    "fieldName": "organizer_id",
                    "label": "table.organizer",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "readOnlyUpdate": true,
                        "disabledUpdate": true,
                        "validators": [
                            "required"
                        ],
                        "formNewSection": true,
                        "dbtype": "String",
                        "table": "organizer",
                        "label": "table.organizer"
                    },
                    "tableRelated": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "hotelorganizer_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "id": {
                    "target": "listmini",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "id",
                    "fieldSource": {
                        "type": "ID!",
                        "listmini": true,
                        "dbtype": "ID",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "ID!",
                    "typeInput": "text",
                    "listSource": ""
                },
                "name": {
                    "target": [
                        "listmini"
                    ],
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "name",
                    "label": "form.name",
                    "fieldSource": {
                        "type": "String",
                        "validators": [
                            "required",
                            "minLength:3",
                            "maxLength:22"
                        ],
                        "sublabel": "info.hotelshortname",
                        "sublabeldown": true,
                        "listmini": true,
                        "dbtype": "String",
                        "typeInput": "text",
                        "label": "form.name"
                    },
                    "tableRelated": "",
                    "type": "String",
                    "typeInput": "text",
                    "listSource": ""
                }
            }
        },
        "componentName": "HotelView",
        "path": "/hotel-view/:id"
    },
    "stopspoint": {
        "help": {
            "fr-PELE": "https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.jydy3evs3108"
        },
        "icon": "stopspoint",
        "uniquefields": {
            "1": [
                "name"
            ]
        },
        "fields": {
            "id": {
                "type": "ID!",
                "listmini": true,
                "dbtype": "ID",
                "typeInput": "text"
            },
            "name": {
                "type": "String",
                "sublabel": "info.stopspointname",
                "sublabeldown": true,
                "validators": [
                    "required",
                    "minLength:3",
                    "maxLength:40"
                ],
                "listmini": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.name"
            },
            "sinfo": {
                "type": "String",
                "typeInput": "textarea",
                "formNewSection": true,
                "sublabel": "info.stopspoint_sinfo",
                "sublabeldown": true,
                "dbtype": "String",
                "label": "form.sinfo"
            },
            "updatedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "dbAutoUpdate": true,
                "excludedInForm": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.updatedAt"
            },
            "createdAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "dbAutoUpdate": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.createdAt"
            },
            "deletedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "disabledMutation": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.deletedAt"
            },
            "deleted": {
                "type": "Boolean",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.deleted"
            },
            "updated_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.updated_user_id"
            },
            "created_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.created_user_id"
            },
            "deleted_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.deleted_user_id"
            },
            "organizer_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "readOnlyUpdate": true,
                "disabledUpdate": true,
                "validators": [
                    "required"
                ],
                "formNewSection": true,
                "dbtype": "String",
                "table": "organizer",
                "label": "table.organizer"
            }
        },
        "listview": {
            "fields": {
                "name": true,
                "sinfo": true,
                "organizer_id": true
            }
        },
        "destination": [
            "serverSchema",
            "serverResolver",
            "ormModels",
            "components",
            "app",
            "menus"
        ],
        "name": {
            "singular": "stopspoint",
            "plural": "stopspoints",
            "singularCase": "Stopspoint",
            "pluralCase": "Stopspoints"
        },
        "mainfield": "name",
        "related": {
            "organizer_id": {
                "table": "organizer",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "organizer_id",
                "alias": "stopspointorganizer_id"
            },
            "updatedUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "updated_user_id",
                "qlDisable": true,
                "alias": "stopspointupdated_user_id"
            },
            "createdUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "created_user_id",
                "qlDisable": true,
                "alias": "stopspointcreated_user_id"
            }
        },
        "miniFields": [
            "id",
            "name"
        ],
        "listFilters": {
            "fields": {
                "organizer_id": {
                    "target": [],
                    "tableRelatedAlias": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "stopspointorganizer_id"
                    },
                    "table": "organizer",
                    "fieldName": "organizer_id",
                    "label": "table.organizer",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "readOnlyUpdate": true,
                        "disabledUpdate": true,
                        "validators": [
                            "required"
                        ],
                        "formNewSection": true,
                        "dbtype": "String",
                        "table": "organizer",
                        "label": "table.organizer"
                    },
                    "tableRelated": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "stopspointorganizer_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "id": {
                    "target": "listmini",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "id",
                    "fieldSource": {
                        "type": "ID!",
                        "listmini": true,
                        "dbtype": "ID",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "ID!",
                    "typeInput": "text",
                    "listSource": ""
                },
                "name": {
                    "target": [
                        "listmini"
                    ],
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "name",
                    "label": "form.name",
                    "fieldSource": {
                        "type": "String",
                        "sublabel": "info.stopspointname",
                        "sublabeldown": true,
                        "validators": [
                            "required",
                            "minLength:3",
                            "maxLength:40"
                        ],
                        "listmini": true,
                        "dbtype": "String",
                        "typeInput": "text",
                        "label": "form.name"
                    },
                    "tableRelated": "",
                    "type": "String",
                    "typeInput": "text",
                    "listSource": ""
                }
            }
        },
        "componentName": "StopspointView",
        "path": "/stopspoint-view/:id"
    },
    "service": {
        "help": {
            "fr-PELE": "https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.9taif8hta3qn"
        },
        "triggers": true,
        "pagesettings": true,
        "orders": {
            "noOptionBlank": true,
            "defaultValue": "updatedAt",
            "label": "form.order",
            "inLine": true,
            "fields": {
                "name": {},
                "createdAt": {},
                "updatedAt": {
                    "label": "form.updateAtDesc",
                    "order": "DESC"
                },
                "statusbase": {
                    "label": "form.servicebase",
                    "order": "ASC"
                }
            }
        },
        "listFilters": {
            "fields": {
                "organizer_id": {
                    "target": [],
                    "tableRelatedAlias": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "serviceorganizer_id"
                    },
                    "table": "organizer",
                    "fieldName": "organizer_id",
                    "label": "table.organizer",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "readOnlyUpdate": true,
                        "disabledUpdate": true,
                        "validators": [
                            "required"
                        ],
                        "formNewSection": true,
                        "dbtype": "String",
                        "table": "organizer",
                        "label": "table.organizer"
                    },
                    "tableRelated": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "serviceorganizer_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "name": {
                    "target": [
                        "maincrud",
                        "listmini"
                    ],
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "name",
                    "label": "form.name",
                    "fieldSource": {
                        "type": "String!",
                        "validators": [
                            "required",
                            "minLength:3",
                            "maxLength:28"
                        ],
                        "listmini": true,
                        "dbtype": "String",
                        "typeInput": "text",
                        "label": "form.name"
                    },
                    "tableRelated": "",
                    "type": "String!",
                    "typeInput": "text",
                    "listSource": ""
                },
                "id": {
                    "target": "listmini",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "id",
                    "fieldSource": {
                        "type": "ID!",
                        "listmini": true,
                        "dbtype": "ID",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "ID!",
                    "typeInput": "text",
                    "listSource": ""
                }
            }
        },
        "uniquefields": {
            "1": [
                "name"
            ]
        },
        "fields": {
            "id": {
                "type": "ID!",
                "listmini": true,
                "dbtype": "ID",
                "typeInput": "text"
            },
            "name": {
                "type": "String!",
                "validators": [
                    "required",
                    "minLength:3",
                    "maxLength:28"
                ],
                "listmini": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.name"
            },
            "statusbase": {
                "type": "Int",
                "dbtype": "Int",
                "label": "form.servicebase",
                "typeInput": "selectBox",
                "validators": [
                    "required"
                ],
                "listSource": "servicetBaseStatus"
            },
            "nameinregistration": {
                "type": "Boolean",
                "typeInput": "checkbox",
                "listmini": true,
                "dbtype": "Boolean",
                "label": "form.nameinregistration"
            },
            "filterlist": {
                "type": "String",
                "dbtype": "Json",
                "listmini": true,
                "label": "form.filterCustStat",
                "subfields": {
                    "id": {
                        "type": "ID",
                        "typeInput": "hidden",
                        "label": "form.id"
                    },
                    "customerstatus_id": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "label": "table.customerstatus",
                        "onChange": true,
                        "validators": [
                            "required"
                        ],
                        "table": "customerstatus"
                    },
                    "customersubstatus_id": {
                        "type": "String",
                        "label": "form.substatus",
                        "typeInput": "selectAutocomplete",
                        "filterBy": [
                            "customerstatus_id"
                        ],
                        "subfield": "substatus",
                        "table": "customerstatus"
                    }
                },
                "typeInput": "text",
                "mode": {}
            },
            "updatedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "dbAutoUpdate": true,
                "excludedInForm": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.updatedAt"
            },
            "createdAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "dbAutoUpdate": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.createdAt"
            },
            "deletedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "disabledMutation": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.deletedAt"
            },
            "deleted": {
                "type": "Boolean",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.deleted"
            },
            "updated_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.updated_user_id"
            },
            "created_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.created_user_id"
            },
            "deleted_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.deleted_user_id"
            },
            "organizer_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "readOnlyUpdate": true,
                "disabledUpdate": true,
                "validators": [
                    "required"
                ],
                "formNewSection": true,
                "dbtype": "String",
                "table": "organizer",
                "label": "table.organizer"
            }
        },
        "listview": {
            "fields": {
                "name": true,
                "statusbase": true,
                "nameinregistration": true,
                "organizer_id": true
            }
        },
        "icon": "service",
        "destination": [
            "serverSchema",
            "serverResolver",
            "ormModels",
            "components",
            "app",
            "menus"
        ],
        "name": {
            "singular": "service",
            "plural": "services",
            "singularCase": "Service",
            "pluralCase": "Services"
        },
        "mainfield": "name",
        "related": {
            "organizer_id": {
                "table": "organizer",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "organizer_id",
                "alias": "serviceorganizer_id"
            },
            "updatedUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "updated_user_id",
                "qlDisable": true,
                "alias": "serviceupdated_user_id"
            },
            "createdUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "created_user_id",
                "qlDisable": true,
                "alias": "servicecreated_user_id"
            }
        },
        "miniFields": [
            "id",
            "name",
            "nameinregistration",
            "filterlist"
        ],
        "componentName": "ServiceView",
        "path": "/service-view/:id"
    },
    "city": {
        "help": {
            "fr-PELE": "https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.w5n5bayg1e6m"
        },
        "loadAsync": true,
        "icon": "city",
        "afterAddGoView": true,
        "destination": [
            "serverSchema",
            "serverResolver",
            "ormModels",
            "components",
            "app",
            "menus"
        ],
        "orders": {
            "noOptionBlank": true,
            "defaultValue": "name",
            "label": "form.order",
            "inLine": true,
            "fields": {
                "name": {},
                "createdAt": {},
                "updatedAt": {
                    "label": "form.updateAtDesc",
                    "order": "DESC"
                }
            }
        },
        "listview": {
            "fields": {
                "name": true,
                "pcs": {
                    "subfields": {
                        "pc": true
                    },
                    "joinsubfields": true
                },
                "createdAt": true,
                "updatedAt": true,
                "organizer_id": true
            }
        },
        "listFilters": {
            "fields": {
                "organizer_id": {
                    "target": [],
                    "tableRelatedAlias": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "cityorganizer_id"
                    },
                    "table": "organizer",
                    "fieldName": "organizer_id",
                    "label": "table.organizer",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "readOnlyUpdate": true,
                        "disabledUpdate": true,
                        "validators": [
                            "required"
                        ],
                        "formNewSection": true,
                        "dbtype": "String",
                        "table": "organizer",
                        "label": "table.organizer"
                    },
                    "tableRelated": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "cityorganizer_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "name": {
                    "target": [
                        "maincrud",
                        "listmini"
                    ],
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "name",
                    "label": "form.name",
                    "fieldSource": {
                        "type": "String",
                        "validators": [
                            "required"
                        ],
                        "listmini": true,
                        "dbtype": "String",
                        "typeInput": "text",
                        "label": "form.name"
                    },
                    "tableRelated": "",
                    "type": "String",
                    "typeInput": "text",
                    "listSource": ""
                },
                "id": {
                    "target": "listmini",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "id",
                    "fieldSource": {
                        "type": "ID!",
                        "listmini": true,
                        "dbtype": "ID",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "ID!",
                    "typeInput": "text",
                    "listSource": ""
                }
            }
        },
        "uniquefields": {
            "1": [
                "name",
                "country"
            ]
        },
        "fields": {
            "id": {
                "type": "ID!",
                "listmini": true,
                "dbtype": "ID",
                "typeInput": "text"
            },
            "name": {
                "type": "String",
                "validators": [
                    "required"
                ],
                "listmini": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.name"
            },
            "pcs": {
                "type": "String",
                "dbtype": "Json",
                "mode": {
                    "insertRowOnAdd": 1,
                    "minimalRows": 1
                },
                "listmini": true,
                "subfields": {
                    "id": {
                        "type": "ID",
                        "typeInput": "hidden",
                        "label": "form.id"
                    },
                    "pc": {
                        "type": "String",
                        "placeHolderShow": true,
                        "validators": [
                            "required",
                            "minLength:5",
                            "maxLength:5"
                        ],
                        "typeInput": "text",
                        "label": "form.pc"
                    }
                },
                "typeInput": "text",
                "label": "form.pcs"
            },
            "updatedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "dbAutoUpdate": true,
                "excludedInForm": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.updatedAt"
            },
            "createdAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "dbAutoUpdate": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.createdAt"
            },
            "deletedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "disabledMutation": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.deletedAt"
            },
            "deleted": {
                "type": "Boolean",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.deleted"
            },
            "updated_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.updated_user_id"
            },
            "created_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.created_user_id"
            },
            "deleted_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.deleted_user_id"
            },
            "organizer_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "readOnlyUpdate": true,
                "disabledUpdate": true,
                "validators": [
                    "required"
                ],
                "formNewSection": true,
                "dbtype": "String",
                "table": "organizer",
                "label": "table.organizer"
            }
        },
        "name": {
            "singular": "city",
            "plural": "citys",
            "singularCase": "City",
            "pluralCase": "Citys"
        },
        "mainfield": "name",
        "related": {
            "organizer_id": {
                "table": "organizer",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "organizer_id",
                "alias": "cityorganizer_id"
            },
            "updatedUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "updated_user_id",
                "qlDisable": true,
                "alias": "cityupdated_user_id"
            },
            "createdUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "created_user_id",
                "qlDisable": true,
                "alias": "citycreated_user_id"
            }
        },
        "miniFields": [
            "id",
            "name",
            "pcs"
        ],
        "componentName": "CityView",
        "path": "/city-view/:id"
    },
    "registrationstatus": {
        "help": {
            "fr-PELE": "https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.34nu25nhigx0"
        },
        "listOrder": "statusbase:ASC",
        "uniquefields": {
            "1": [
                "name"
            ]
        },
        "fields": {
            "id": {
                "type": "ID!",
                "listmini": true,
                "dbtype": "ID",
                "typeInput": "text"
            },
            "name": {
                "type": "String!",
                "validators": [
                    "required",
                    "minLength:3",
                    "maxLength:25"
                ],
                "listmini": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.name"
            },
            "statusbase": {
                "type": "Int",
                "dbtype": "Int",
                "typeInput": "selectAutocomplete",
                "label": "form.registration_statusbase",
                "sublabel": "form.registration_statusbase_sublabel",
                "listSource": "registrationBaseStatus",
                "validators": [
                    "required"
                ],
                "listmini": true,
                "onChange": true
            },
            "color": {
                "type": "String",
                "typeInput": "color",
                "validators": [
                    "minLength:7",
                    "maxLength:7"
                ],
                "sublabel": "form.colorIsDefaultYouCanChange",
                "listmini": true,
                "dbtype": "String",
                "label": "form.color"
            },
            "updatedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "dbAutoUpdate": true,
                "excludedInForm": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.updatedAt"
            },
            "createdAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "dbAutoUpdate": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.createdAt"
            },
            "deletedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "disabledMutation": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.deletedAt"
            },
            "deleted": {
                "type": "Boolean",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.deleted"
            },
            "updated_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.updated_user_id"
            },
            "created_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.created_user_id"
            },
            "deleted_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.deleted_user_id"
            },
            "organizer_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "readOnlyUpdate": true,
                "disabledUpdate": true,
                "validators": [
                    "required"
                ],
                "formNewSection": true,
                "dbtype": "String",
                "table": "organizer",
                "label": "table.organizer"
            }
        },
        "listview": {
            "fields": {
                "name": true,
                "statusbase": true,
                "color": true,
                "organizer_id": true
            }
        },
        "icon": "permcontactcalendar",
        "formlayout": [
            "1Row1Field"
        ],
        "destination": [
            "serverSchema",
            "serverResolver",
            "ormModels",
            "components",
            "app",
            "menus"
        ],
        "name": {
            "singular": "registrationstatus",
            "plural": "registrationstatuss",
            "singularCase": "Registrationstatus",
            "pluralCase": "Registrationstatuss"
        },
        "mainfield": "name",
        "related": {
            "organizer_id": {
                "table": "organizer",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "organizer_id",
                "alias": "registrationstatusorganizer_id"
            },
            "updatedUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "updated_user_id",
                "qlDisable": true,
                "alias": "registrationstatusupdated_user_id"
            },
            "createdUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "created_user_id",
                "qlDisable": true,
                "alias": "registrationstatuscreated_user_id"
            }
        },
        "miniFields": [
            "id",
            "name",
            "statusbase",
            "color"
        ],
        "listFilters": {
            "fields": {
                "organizer_id": {
                    "target": [],
                    "tableRelatedAlias": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "registrationstatusorganizer_id"
                    },
                    "table": "organizer",
                    "fieldName": "organizer_id",
                    "label": "table.organizer",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "readOnlyUpdate": true,
                        "disabledUpdate": true,
                        "validators": [
                            "required"
                        ],
                        "formNewSection": true,
                        "dbtype": "String",
                        "table": "organizer",
                        "label": "table.organizer"
                    },
                    "tableRelated": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "registrationstatusorganizer_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "id": {
                    "target": "listmini",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "id",
                    "fieldSource": {
                        "type": "ID!",
                        "listmini": true,
                        "dbtype": "ID",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "ID!",
                    "typeInput": "text",
                    "listSource": ""
                },
                "name": {
                    "target": [
                        "listmini"
                    ],
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "name",
                    "label": "form.name",
                    "fieldSource": {
                        "type": "String!",
                        "validators": [
                            "required",
                            "minLength:3",
                            "maxLength:25"
                        ],
                        "listmini": true,
                        "dbtype": "String",
                        "typeInput": "text",
                        "label": "form.name"
                    },
                    "tableRelated": "",
                    "type": "String!",
                    "typeInput": "text",
                    "listSource": ""
                }
            }
        },
        "componentName": "RegistrationstatusView",
        "path": "/registrationstatus-view/:id"
    },
    "confirmationstatus": {
        "help": {
            "fr-PELE": "https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.uk840qoaxn54"
        },
        "listOrder": "statusbase:ASC",
        "uniquefields": {
            "1": [
                "name"
            ]
        },
        "fields": {
            "id": {
                "type": "ID!",
                "listmini": true,
                "dbtype": "ID",
                "typeInput": "text"
            },
            "name": {
                "type": "String!",
                "validators": [
                    "required",
                    "minLength:3",
                    "maxLength:25"
                ],
                "listmini": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.name"
            },
            "statusbase": {
                "type": "Int",
                "dbtype": "Int",
                "validators": [
                    "required"
                ],
                "typeInput": "selectAutocomplete",
                "listSource": "confirmationBaseStatus",
                "label": "form.confirmation_statusbase",
                "sublabel": "form.confirmation_statusbase_sublabel",
                "onChange": true,
                "listmini": true
            },
            "color": {
                "type": "String",
                "typeInput": "color",
                "validators": [
                    "minLength:7",
                    "maxLength:7"
                ],
                "sublabel": "form.colorIsDefaultYouCanChange",
                "listmini": true,
                "dbtype": "String",
                "label": "form.color"
            },
            "updatedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "dbAutoUpdate": true,
                "excludedInForm": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.updatedAt"
            },
            "createdAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "dbAutoUpdate": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.createdAt"
            },
            "deletedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "disabledMutation": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.deletedAt"
            },
            "deleted": {
                "type": "Boolean",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.deleted"
            },
            "updated_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.updated_user_id"
            },
            "created_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.created_user_id"
            },
            "deleted_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.deleted_user_id"
            },
            "organizer_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "readOnlyUpdate": true,
                "disabledUpdate": true,
                "validators": [
                    "required"
                ],
                "formNewSection": true,
                "dbtype": "String",
                "table": "organizer",
                "label": "table.organizer"
            }
        },
        "listview": {
            "fields": {
                "name": true,
                "statusbase": true,
                "color": true,
                "organizer_id": true
            }
        },
        "icon": "check",
        "formlayout": [
            "1Row1Field"
        ],
        "destination": [
            "serverSchema",
            "serverResolver",
            "ormModels",
            "components",
            "app",
            "menus"
        ],
        "name": {
            "singular": "confirmationstatus",
            "plural": "confirmationstatuss",
            "singularCase": "Confirmationstatus",
            "pluralCase": "Confirmationstatuss"
        },
        "mainfield": "name",
        "related": {
            "organizer_id": {
                "table": "organizer",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "organizer_id",
                "alias": "confirmationstatusorganizer_id"
            },
            "updatedUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "updated_user_id",
                "qlDisable": true,
                "alias": "confirmationstatusupdated_user_id"
            },
            "createdUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "created_user_id",
                "qlDisable": true,
                "alias": "confirmationstatuscreated_user_id"
            }
        },
        "miniFields": [
            "id",
            "name",
            "statusbase",
            "color"
        ],
        "listFilters": {
            "fields": {
                "organizer_id": {
                    "target": [],
                    "tableRelatedAlias": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "confirmationstatusorganizer_id"
                    },
                    "table": "organizer",
                    "fieldName": "organizer_id",
                    "label": "table.organizer",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "readOnlyUpdate": true,
                        "disabledUpdate": true,
                        "validators": [
                            "required"
                        ],
                        "formNewSection": true,
                        "dbtype": "String",
                        "table": "organizer",
                        "label": "table.organizer"
                    },
                    "tableRelated": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "confirmationstatusorganizer_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "id": {
                    "target": "listmini",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "id",
                    "fieldSource": {
                        "type": "ID!",
                        "listmini": true,
                        "dbtype": "ID",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "ID!",
                    "typeInput": "text",
                    "listSource": ""
                },
                "name": {
                    "target": [
                        "listmini"
                    ],
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "name",
                    "label": "form.name",
                    "fieldSource": {
                        "type": "String!",
                        "validators": [
                            "required",
                            "minLength:3",
                            "maxLength:25"
                        ],
                        "listmini": true,
                        "dbtype": "String",
                        "typeInput": "text",
                        "label": "form.name"
                    },
                    "tableRelated": "",
                    "type": "String!",
                    "typeInput": "text",
                    "listSource": ""
                }
            }
        },
        "componentName": "ConfirmationstatusView",
        "path": "/confirmationstatus-view/:id"
    },
    "bank": {
        "help": {
            "fr-PELE": "https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.tbws6cah3vw"
        },
        "afterAddGoView": true,
        "loadAsync": true,
        "fields": {
            "id": {
                "type": "ID!",
                "listmini": true,
                "dbtype": "ID",
                "typeInput": "text"
            },
            "name": {
                "type": "String!",
                "validators": [
                    "required",
                    "minLength:3"
                ],
                "ilikefull": true,
                "listmini": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.name"
            },
            "updatedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "dbAutoUpdate": true,
                "excludedInForm": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.updatedAt"
            },
            "createdAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "dbAutoUpdate": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.createdAt"
            },
            "deletedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "disabledMutation": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.deletedAt"
            },
            "deleted": {
                "type": "Boolean",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.deleted"
            },
            "updated_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.updated_user_id"
            },
            "created_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.created_user_id"
            },
            "deleted_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.deleted_user_id"
            },
            "organizer_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "readOnlyUpdate": true,
                "disabledUpdate": true,
                "validators": [
                    "required"
                ],
                "formNewSection": true,
                "dbtype": "String",
                "table": "organizer",
                "label": "table.organizer"
            }
        },
        "icon": "bank",
        "destination": [
            "serverSchema",
            "serverResolver",
            "ormModels",
            "components",
            "app",
            "menus"
        ],
        "listview": {
            "fields": {
                "name": true,
                "organizer_id": true
            }
        },
        "name": {
            "singular": "bank",
            "plural": "banks",
            "singularCase": "Bank",
            "pluralCase": "Banks"
        },
        "mainfield": "name",
        "related": {
            "organizer_id": {
                "table": "organizer",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "organizer_id",
                "alias": "bankorganizer_id"
            },
            "updatedUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "updated_user_id",
                "qlDisable": true,
                "alias": "bankupdated_user_id"
            },
            "createdUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "created_user_id",
                "qlDisable": true,
                "alias": "bankcreated_user_id"
            }
        },
        "miniFields": [
            "id",
            "name"
        ],
        "listFilters": {
            "fields": {
                "organizer_id": {
                    "target": [],
                    "tableRelatedAlias": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "bankorganizer_id"
                    },
                    "table": "organizer",
                    "fieldName": "organizer_id",
                    "label": "table.organizer",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "readOnlyUpdate": true,
                        "disabledUpdate": true,
                        "validators": [
                            "required"
                        ],
                        "formNewSection": true,
                        "dbtype": "String",
                        "table": "organizer",
                        "label": "table.organizer"
                    },
                    "tableRelated": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "bankorganizer_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "id": {
                    "target": "listmini",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "id",
                    "fieldSource": {
                        "type": "ID!",
                        "listmini": true,
                        "dbtype": "ID",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "ID!",
                    "typeInput": "text",
                    "listSource": ""
                },
                "name": {
                    "target": [
                        "listmini"
                    ],
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "name",
                    "label": "form.name",
                    "fieldSource": {
                        "type": "String!",
                        "validators": [
                            "required",
                            "minLength:3"
                        ],
                        "ilikefull": true,
                        "listmini": true,
                        "dbtype": "String",
                        "typeInput": "text",
                        "label": "form.name"
                    },
                    "tableRelated": "",
                    "type": "String!",
                    "typeInput": "text",
                    "listSource": ""
                }
            }
        },
        "componentName": "BankView",
        "path": "/bank-view/:id"
    },
    "paymentmethod": {
        "help": {
            "fr-PELE": "https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.cnrq085ryfil"
        },
        "uniquefields": {
            "1": [
                "name"
            ]
        },
        "pagesettings": true,
        "fields": {
            "id": {
                "type": "ID!",
                "listmini": true,
                "dbtype": "ID",
                "typeInput": "text"
            },
            "name": {
                "type": "String!",
                "formNewSection": true,
                "validators": [
                    "required",
                    "minLength:3",
                    "maxLength:32"
                ],
                "listmini": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.name"
            },
            "statusbase": {
                "formNewSection": true,
                "type": "Int",
                "dbtype": "Int",
                "typeInput": "selectBox",
                "listSource": "paymentMethodBaseStatus",
                "validators": [
                    "required"
                ],
                "listmini": true,
                "onChange": true,
                "label": "form.statusbase"
            },
            "color": {
                "type": "String",
                "typeInput": "color",
                "validators": [
                    "minLength:7",
                    "maxLength:7"
                ],
                "dbtype": "String",
                "label": "form.color"
            },
            "usedatedeferred": {
                "type": "Boolean",
                "typeInput": "checkbox",
                "sublabel": "info.usedatedeferred",
                "listmini": true,
                "formNewSection": true,
                "dbtype": "Boolean",
                "label": "form.usedatedeferred"
            },
            "ajournalcode_id": {
                "formNewSection": true,
                "module": "ACCO",
                "type": "String",
                "typeInput": "selectAutocomplete",
                "label": "table.ajournalcode",
                "listmini": true,
                "fieldDisplayedOptions": {
                    "subfields": {
                        "name": true,
                        "title": true,
                        "counterpart_id": true
                    },
                    "separatorsubfield": " / ",
                    "joinsubfields": false
                },
                "dbtype": "String",
                "table": "ajournalcode"
            },
            "anature": {
                "formNewSection": true,
                "listmini": true,
                "sublabel": "info.anature",
                "type": "String",
                "typeInput": "selectBox",
                "validators": [
                    "required"
                ],
                "listOptions": [
                    {
                        "id": "C",
                        "name": "form.credit"
                    },
                    {
                        "id": "D",
                        "name": "form.debit"
                    }
                ],
                "dbtype": "String",
                "label": "form.anature"
            },
            "updatedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "dbAutoUpdate": true,
                "excludedInForm": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.updatedAt"
            },
            "createdAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "dbAutoUpdate": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.createdAt"
            },
            "deletedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "disabledMutation": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.deletedAt"
            },
            "deleted": {
                "type": "Boolean",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.deleted"
            },
            "updated_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.updated_user_id"
            },
            "created_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.created_user_id"
            },
            "deleted_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.deleted_user_id"
            },
            "organizer_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "readOnlyUpdate": true,
                "disabledUpdate": true,
                "validators": [
                    "required"
                ],
                "formNewSection": true,
                "dbtype": "String",
                "table": "organizer",
                "label": "table.organizer"
            }
        },
        "related": {
            "ajournalcode_id": {
                "table": "ajournalcode",
                "label": "table.ajournalcode",
                "relation": "oneChild",
                "fields": [
                    "name",
                    "title",
                    "counterpart_id"
                ],
                "key": "ajournalcode_id",
                "listmini": true,
                "subrelated": true,
                "alias": "paymentmethodajournalcode_id"
            },
            "organizer_id": {
                "table": "organizer",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "organizer_id",
                "alias": "paymentmethodorganizer_id"
            },
            "updatedUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "updated_user_id",
                "qlDisable": true,
                "alias": "paymentmethodupdated_user_id"
            },
            "createdUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "created_user_id",
                "qlDisable": true,
                "alias": "paymentmethodcreated_user_id"
            }
        },
        "listview": {
            "fields": {
                "name": true,
                "statusbase": true,
                "usedatedeferred": true,
                "color": true,
                "ajournalcode_id": {
                    "module": "ACCO"
                },
                "anature": {},
                "organizer_id": true
            }
        },
        "icon": "localatm",
        "destination": [
            "serverSchema",
            "serverResolver",
            "ormModels",
            "components",
            "app",
            "menus"
        ],
        "name": {
            "singular": "paymentmethod",
            "plural": "paymentmethods",
            "singularCase": "Paymentmethod",
            "pluralCase": "Paymentmethods"
        },
        "mainfield": "name",
        "miniFields": [
            "id",
            "name",
            "statusbase",
            "usedatedeferred",
            "ajournalcode_id",
            "anature"
        ],
        "listFilters": {
            "fields": {
                "organizer_id": {
                    "target": [],
                    "tableRelatedAlias": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "paymentmethodorganizer_id"
                    },
                    "table": "organizer",
                    "fieldName": "organizer_id",
                    "label": "table.organizer",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "readOnlyUpdate": true,
                        "disabledUpdate": true,
                        "validators": [
                            "required"
                        ],
                        "formNewSection": true,
                        "dbtype": "String",
                        "table": "organizer",
                        "label": "table.organizer"
                    },
                    "tableRelated": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "paymentmethodorganizer_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "id": {
                    "target": "listmini",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "id",
                    "fieldSource": {
                        "type": "ID!",
                        "listmini": true,
                        "dbtype": "ID",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "ID!",
                    "typeInput": "text",
                    "listSource": ""
                },
                "name": {
                    "target": [
                        "listmini"
                    ],
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "name",
                    "label": "form.name",
                    "fieldSource": {
                        "type": "String!",
                        "formNewSection": true,
                        "validators": [
                            "required",
                            "minLength:3",
                            "maxLength:32"
                        ],
                        "listmini": true,
                        "dbtype": "String",
                        "typeInput": "text",
                        "label": "form.name"
                    },
                    "tableRelated": "",
                    "type": "String!",
                    "typeInput": "text",
                    "listSource": ""
                }
            }
        },
        "componentName": "PaymentmethodView",
        "path": "/paymentmethod-view/:id"
    },
    "paymentdocstatus": {
        "help": {
            "fr-PELE": "https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.xj6s8vc0k0xb"
        },
        "listOrder": "statusbase:ASC",
        "uniquefields": {
            "1": [
                "name"
            ]
        },
        "fields": {
            "id": {
                "type": "ID!",
                "listmini": true,
                "dbtype": "ID",
                "typeInput": "text"
            },
            "name": {
                "type": "String!",
                "validators": [
                    "required",
                    "minLength:3",
                    "maxLength:25"
                ],
                "listmini": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.name"
            },
            "statusbase": {
                "type": "Int",
                "dbtype": "Int",
                "typeInput": "selectBox",
                "listSource": "paymentDocBaseStatus",
                "validators": [
                    "required"
                ],
                "onChange": true,
                "listmini": true,
                "label": "form.statusbase"
            },
            "color": {
                "type": "String",
                "typeInput": "color",
                "sublabel": "form.colorIsDefaultYouCanChange",
                "validators": [
                    "minLength:7",
                    "maxLength:7"
                ],
                "listmini": true,
                "dbtype": "String",
                "label": "form.color"
            },
            "updatedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "dbAutoUpdate": true,
                "excludedInForm": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.updatedAt"
            },
            "createdAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "dbAutoUpdate": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.createdAt"
            },
            "deletedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "disabledMutation": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.deletedAt"
            },
            "deleted": {
                "type": "Boolean",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.deleted"
            },
            "updated_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.updated_user_id"
            },
            "created_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.created_user_id"
            },
            "deleted_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.deleted_user_id"
            },
            "organizer_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "readOnlyUpdate": true,
                "disabledUpdate": true,
                "validators": [
                    "required"
                ],
                "formNewSection": true,
                "dbtype": "String",
                "table": "organizer",
                "label": "table.organizer"
            }
        },
        "listview": {
            "fields": {
                "name": true,
                "statusbase": true,
                "color": true,
                "organizer_id": true
            }
        },
        "icon": "paymentdocstatus",
        "formlayout": [
            "1Row1Field"
        ],
        "destination": [
            "serverSchema",
            "serverResolver",
            "ormModels",
            "components",
            "app",
            "menus"
        ],
        "name": {
            "singular": "paymentdocstatus",
            "plural": "paymentdocstatuss",
            "singularCase": "Paymentdocstatus",
            "pluralCase": "Paymentdocstatuss"
        },
        "mainfield": "name",
        "related": {
            "organizer_id": {
                "table": "organizer",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "organizer_id",
                "alias": "paymentdocstatusorganizer_id"
            },
            "updatedUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "updated_user_id",
                "qlDisable": true,
                "alias": "paymentdocstatusupdated_user_id"
            },
            "createdUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "created_user_id",
                "qlDisable": true,
                "alias": "paymentdocstatuscreated_user_id"
            }
        },
        "miniFields": [
            "id",
            "name",
            "statusbase",
            "color"
        ],
        "listFilters": {
            "fields": {
                "organizer_id": {
                    "target": [],
                    "tableRelatedAlias": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "paymentdocstatusorganizer_id"
                    },
                    "table": "organizer",
                    "fieldName": "organizer_id",
                    "label": "table.organizer",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "readOnlyUpdate": true,
                        "disabledUpdate": true,
                        "validators": [
                            "required"
                        ],
                        "formNewSection": true,
                        "dbtype": "String",
                        "table": "organizer",
                        "label": "table.organizer"
                    },
                    "tableRelated": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "paymentdocstatusorganizer_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "id": {
                    "target": "listmini",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "id",
                    "fieldSource": {
                        "type": "ID!",
                        "listmini": true,
                        "dbtype": "ID",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "ID!",
                    "typeInput": "text",
                    "listSource": ""
                },
                "name": {
                    "target": [
                        "listmini"
                    ],
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "name",
                    "label": "form.name",
                    "fieldSource": {
                        "type": "String!",
                        "validators": [
                            "required",
                            "minLength:3",
                            "maxLength:25"
                        ],
                        "listmini": true,
                        "dbtype": "String",
                        "typeInput": "text",
                        "label": "form.name"
                    },
                    "tableRelated": "",
                    "type": "String!",
                    "typeInput": "text",
                    "listSource": ""
                }
            }
        },
        "componentName": "PaymentdocstatusView",
        "path": "/paymentdocstatus-view/:id"
    },
    "customerstatus": {
        "help": {
            "fr-PELE": "https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.ocfrv7wxqy0c"
        },
        "uniquefields": {
            "1": [
                "name"
            ]
        },
        "orders": {
            "noOptionBlank": true,
            "defaultValue": "name",
            "label": "form.order",
            "inLine": true,
            "fields": {
                "name": {},
                "createdAt": {}
            }
        },
        "listview": {
            "fields": {
                "name": true,
                "createdAt": true,
                "organizer_id": true
            }
        },
        "fields": {
            "id": {
                "type": "ID!",
                "listmini": true,
                "dbtype": "ID",
                "typeInput": "text"
            },
            "name": {
                "type": "String!",
                "validators": [
                    "required",
                    "minLength:3",
                    "maxLength:29"
                ],
                "listmini": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.name"
            },
            "longname": {
                "type": "String",
                "validators": [
                    "minLength:3"
                ],
                "label": "form.longname",
                "dbtype": "String",
                "typeInput": "text"
            },
            "substatus": {
                "type": "String",
                "dbtype": "Json",
                "listmini": true,
                "subfields": {
                    "id": {
                        "type": "ID",
                        "typeInput": "hidden",
                        "label": "form.id"
                    },
                    "option": {
                        "type": "String",
                        "validators": [
                            "required",
                            "minLength:3"
                        ],
                        "typeInput": "text",
                        "label": "form.option"
                    }
                },
                "typeInput": "text",
                "label": "form.substatus",
                "mode": {}
            },
            "updatedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "dbAutoUpdate": true,
                "excludedInForm": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.updatedAt"
            },
            "createdAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "dbAutoUpdate": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.createdAt"
            },
            "deletedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "disabledMutation": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.deletedAt"
            },
            "deleted": {
                "type": "Boolean",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.deleted"
            },
            "updated_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.updated_user_id"
            },
            "created_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.created_user_id"
            },
            "deleted_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.deleted_user_id"
            },
            "organizer_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "readOnlyUpdate": true,
                "disabledUpdate": true,
                "validators": [
                    "required"
                ],
                "formNewSection": true,
                "dbtype": "String",
                "table": "organizer",
                "label": "table.organizer"
            }
        },
        "icon": "recordvoice",
        "formlayout": [
            "1Row1Field"
        ],
        "destination": [
            "serverSchema",
            "serverResolver",
            "ormModels",
            "components",
            "app",
            "menus"
        ],
        "name": {
            "singular": "customerstatus",
            "plural": "customerstatuss",
            "singularCase": "Customerstatus",
            "pluralCase": "Customerstatuss"
        },
        "mainfield": "name",
        "related": {
            "organizer_id": {
                "table": "organizer",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "organizer_id",
                "alias": "customerstatusorganizer_id"
            },
            "updatedUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "updated_user_id",
                "qlDisable": true,
                "alias": "customerstatusupdated_user_id"
            },
            "createdUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "created_user_id",
                "qlDisable": true,
                "alias": "customerstatuscreated_user_id"
            }
        },
        "miniFields": [
            "id",
            "name",
            "substatus"
        ],
        "listFilters": {
            "fields": {
                "organizer_id": {
                    "target": [],
                    "tableRelatedAlias": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "customerstatusorganizer_id"
                    },
                    "table": "organizer",
                    "fieldName": "organizer_id",
                    "label": "table.organizer",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "readOnlyUpdate": true,
                        "disabledUpdate": true,
                        "validators": [
                            "required"
                        ],
                        "formNewSection": true,
                        "dbtype": "String",
                        "table": "organizer",
                        "label": "table.organizer"
                    },
                    "tableRelated": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "customerstatusorganizer_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "id": {
                    "target": "listmini",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "id",
                    "fieldSource": {
                        "type": "ID!",
                        "listmini": true,
                        "dbtype": "ID",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "ID!",
                    "typeInput": "text",
                    "listSource": ""
                },
                "name": {
                    "target": [
                        "listmini"
                    ],
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "name",
                    "label": "form.name",
                    "fieldSource": {
                        "type": "String!",
                        "validators": [
                            "required",
                            "minLength:3",
                            "maxLength:29"
                        ],
                        "listmini": true,
                        "dbtype": "String",
                        "typeInput": "text",
                        "label": "form.name"
                    },
                    "tableRelated": "",
                    "type": "String!",
                    "typeInput": "text",
                    "listSource": ""
                }
            }
        },
        "componentName": "CustomerstatusView",
        "path": "/customerstatus-view/:id"
    },
    "gendertype": {
        "help": {
            "fr-PELE": "https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.lbh1p9lh3l54"
        },
        "uniquefields": {
            "1": [
                "name"
            ]
        },
        "fields": {
            "id": {
                "type": "ID!",
                "listmini": true,
                "dbtype": "ID",
                "typeInput": "text"
            },
            "name": {
                "type": "String",
                "validators": [
                    "required"
                ],
                "listmini": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.name"
            },
            "updatedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "dbAutoUpdate": true,
                "excludedInForm": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.updatedAt"
            },
            "createdAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "dbAutoUpdate": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.createdAt"
            },
            "deletedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "disabledMutation": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.deletedAt"
            },
            "deleted": {
                "type": "Boolean",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.deleted"
            },
            "updated_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.updated_user_id"
            },
            "created_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.created_user_id"
            },
            "deleted_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.deleted_user_id"
            },
            "organizer_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "readOnlyUpdate": true,
                "disabledUpdate": true,
                "validators": [
                    "required"
                ],
                "formNewSection": true,
                "dbtype": "String",
                "table": "organizer",
                "label": "table.organizer"
            }
        },
        "icon": "heart",
        "formlayout": [
            "1Row1Field"
        ],
        "destination": [
            "serverSchema",
            "serverResolver",
            "ormModels",
            "components",
            "app"
        ],
        "listview": {
            "fields": {
                "name": true,
                "organizer_id": true
            }
        },
        "name": {
            "singular": "gendertype",
            "plural": "gendertypes",
            "singularCase": "Gendertype",
            "pluralCase": "Gendertypes"
        },
        "mainfield": "name",
        "related": {
            "organizer_id": {
                "table": "organizer",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "organizer_id",
                "alias": "gendertypeorganizer_id"
            },
            "updatedUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "updated_user_id",
                "qlDisable": true,
                "alias": "gendertypeupdated_user_id"
            },
            "createdUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "created_user_id",
                "qlDisable": true,
                "alias": "gendertypecreated_user_id"
            }
        },
        "miniFields": [
            "id",
            "name"
        ],
        "listFilters": {
            "fields": {
                "organizer_id": {
                    "target": [],
                    "tableRelatedAlias": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "gendertypeorganizer_id"
                    },
                    "table": "organizer",
                    "fieldName": "organizer_id",
                    "label": "table.organizer",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "readOnlyUpdate": true,
                        "disabledUpdate": true,
                        "validators": [
                            "required"
                        ],
                        "formNewSection": true,
                        "dbtype": "String",
                        "table": "organizer",
                        "label": "table.organizer"
                    },
                    "tableRelated": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "gendertypeorganizer_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "id": {
                    "target": "listmini",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "id",
                    "fieldSource": {
                        "type": "ID!",
                        "listmini": true,
                        "dbtype": "ID",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "ID!",
                    "typeInput": "text",
                    "listSource": ""
                },
                "name": {
                    "target": [
                        "listmini"
                    ],
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "name",
                    "label": "form.name",
                    "fieldSource": {
                        "type": "String",
                        "validators": [
                            "required"
                        ],
                        "listmini": true,
                        "dbtype": "String",
                        "typeInput": "text",
                        "label": "form.name"
                    },
                    "tableRelated": "",
                    "type": "String",
                    "typeInput": "text",
                    "listSource": ""
                }
            }
        },
        "componentName": "GendertypeView",
        "path": "/gendertype-view/:id"
    },
    "userlog": {
        "help": {
            "fr-PELE": "https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.6oaxhjt7whj0"
        },
        "disableRecreateTemplateList": true,
        "fields": {
            "id": {
                "type": "ID!",
                "listmini": true,
                "dbtype": "ID",
                "typeInput": "text"
            },
            "name": {
                "type": "String",
                "listmini": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.name"
            },
            "actiondate": {
                "type": "String",
                "dbtype": "Datetime",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "label": "form.actiondate"
            },
            "user_id": {
                "type": "String",
                "label": "table.user",
                "disabledMutation": true,
                "typeInput": "selectAutocomplete",
                "dbtype": "String",
                "table": "user"
            },
            "record_id": {
                "type": "String",
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.record_id"
            },
            "record_parentid": {
                "type": "String",
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.record_parentid"
            },
            "record_table": {
                "type": "String",
                "titlePrefix": "table",
                "excludedInForm": true,
                "label": "form.record",
                "dbtype": "String",
                "typeInput": "text"
            },
            "action": {
                "type": "String",
                "typeInput": "selectBox",
                "listSource": "actionLOG",
                "placeHolderShow": true,
                "dbtype": "String",
                "label": "form.action"
            },
            "ip": {
                "type": "String",
                "typeInput": "hidden",
                "dbtype": "String",
                "label": "form.ip"
            },
            "organizer_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "readOnlyUpdate": true,
                "disabledUpdate": true,
                "validators": [
                    "required"
                ],
                "formNewSection": true,
                "dbtype": "String",
                "table": "organizer",
                "label": "table.organizer"
            }
        },
        "related": {
            "user_id": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "user_id",
                "alias": "userloguser_id"
            },
            "organizer_id": {
                "table": "organizer",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "organizer_id",
                "alias": "userlogorganizer_id"
            }
        },
        "listOrder": "actiondate:DESC",
        "listview": {
            "fields": {
                "actiondate": true,
                "user_id": true,
                "action": true,
                "record_table": true,
                "name": true,
                "organizer_id": true
            }
        },
        "listFilters": {
            "fields": {
                "organizer_id": {
                    "target": [],
                    "tableRelatedAlias": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "userlogorganizer_id"
                    },
                    "table": "organizer",
                    "fieldName": "organizer_id",
                    "label": "table.organizer",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "readOnlyUpdate": true,
                        "disabledUpdate": true,
                        "validators": [
                            "required"
                        ],
                        "formNewSection": true,
                        "dbtype": "String",
                        "table": "organizer",
                        "label": "table.organizer"
                    },
                    "tableRelated": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "userlogorganizer_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "user_id": {
                    "tableRelatedAlias": {
                        "table": "user",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "user_id",
                        "alias": "userloguser_id"
                    },
                    "table": "user",
                    "fieldName": "user_id",
                    "target": [
                        "maincrud"
                    ],
                    "label": "table.user",
                    "fieldSource": {
                        "type": "String",
                        "label": "table.user",
                        "disabledMutation": true,
                        "typeInput": "selectAutocomplete",
                        "dbtype": "String",
                        "table": "user"
                    },
                    "tableRelated": {
                        "table": "user",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "user_id",
                        "alias": "userloguser_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "action": {
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "action",
                    "target": [
                        "maincrud"
                    ],
                    "label": "form.action",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectBox",
                        "listSource": "actionLOG",
                        "placeHolderShow": true,
                        "dbtype": "String",
                        "label": "form.action"
                    },
                    "tableRelated": "",
                    "type": "String",
                    "typeInput": "selectBox",
                    "listSource": "actionLOG"
                },
                "id": {
                    "target": "listmini",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "id",
                    "fieldSource": {
                        "type": "ID!",
                        "listmini": true,
                        "dbtype": "ID",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "ID!",
                    "typeInput": "text",
                    "listSource": ""
                },
                "name": {
                    "target": [
                        "listmini"
                    ],
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "name",
                    "label": "form.name",
                    "fieldSource": {
                        "type": "String",
                        "listmini": true,
                        "dbtype": "String",
                        "typeInput": "text",
                        "label": "form.name"
                    },
                    "tableRelated": "",
                    "type": "String",
                    "typeInput": "text",
                    "listSource": ""
                }
            }
        },
        "icon": "log",
        "destination": [
            "serverSchema",
            "serverResolver",
            "ormModels",
            "components",
            "app",
            "menus"
        ],
        "disabledUpdate": true,
        "disabledAdd": true,
        "disabledDelete": true,
        "disabledRecordInfo": true,
        "name": {
            "singular": "userlog",
            "plural": "userlogs",
            "singularCase": "Userlog",
            "pluralCase": "Userlogs"
        },
        "mainfield": "name",
        "miniFields": [
            "id",
            "name"
        ],
        "componentName": "UserlogView",
        "path": "/userlog-view/:id"
    },
    "user": {
        "help": {
            "fr-PELE": "https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.xha5jp6ly0n4"
        },
        "listOrder": "\"user\".\"lastaccessdate\":DESC NULLS LAST",
        "uniquefields": {
            "1": [
                "nick"
            ]
        },
        "ai": true,
        "fields": {
            "id": {
                "type": "ID!",
                "listmini": true,
                "dbtype": "ID",
                "typeInput": "text"
            },
            "nick": {
                "type": "String",
                "validators": [
                    "required",
                    "minLength:6",
                    "maxLength:15"
                ],
                "disabledUpdate": true,
                "normalize": "LowerCaseTrim",
                "readonlyUpdate": true,
                "sublabel": "info.uniquenick",
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.nick"
            },
            "password": {
                "type": "String",
                "typeInput": "password",
                "encrpyt": true,
                "sublabel": "info.emptyWillNotChangeValue",
                "excludedInList": true,
                "queryExcluded": true,
                "validators": [
                    "password"
                ],
                "dbtype": "String",
                "label": "form.password"
            },
            "name": {
                "type": "String",
                "validators": [
                    "required",
                    "minLength:3",
                    "maxLength:15"
                ],
                "formNewSection": true,
                "label": "form.shortname",
                "sublabel": "info.usershortname",
                "sublabeldown": true,
                "listmini": true,
                "dbtype": "String",
                "typeInput": "text"
            },
            "longname": {
                "type": "String",
                "validators": [
                    "required",
                    "minLength:3"
                ],
                "label": "form.longname",
                "dbtype": "String",
                "typeInput": "text"
            },
            "email": {
                "type": "String",
                "validators": [
                    "required"
                ],
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.email"
            },
            "isadmin": {
                "type": "Boolean",
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.isadmin"
            },
            "usergroup_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "onHidden": true,
                "sublabel": "info.usergroup_id",
                "sublabeldown": true,
                "dbtype": "String",
                "table": "usergroup",
                "label": "table.usergroup"
            },
            "lastaccessdate": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "disabledMutation": true,
                "typeInput": "text",
                "label": "form.lastaccessdate"
            },
            "lastactiondate": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "disabledMutation": true,
                "typeInput": "text",
                "label": "form.lastactiondate"
            },
            "countactionsmonth": {
                "type": "Int",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "Int",
                "typeInput": "text",
                "label": "form.countactionsmonth"
            },
            "phones": {
                "type": "String",
                "dbtype": "Json",
                "placeHolderShowChildsShow": true,
                "formNewSection": true,
                "subfields": {
                    "id": {
                        "type": "ID",
                        "typeInput": "hidden",
                        "label": "form.id"
                    },
                    "type": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "listSource": "phonesType",
                        "validators": [
                            "required"
                        ],
                        "label": "form.type"
                    },
                    "phone": {
                        "type": "String",
                        "validators": [
                            "required"
                        ],
                        "icon": "phoneblack",
                        "typeInput": "text",
                        "label": "form.phone"
                    },
                    "otherinfo": {
                        "type": "String",
                        "typeInput": "text",
                        "label": "form.otherinfo"
                    }
                },
                "typeInput": "text",
                "label": "form.phones",
                "mode": {}
            },
            "updatedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "dbAutoUpdate": true,
                "excludedInForm": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.updatedAt"
            },
            "createdAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "dbAutoUpdate": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.createdAt"
            },
            "deletedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "disabledMutation": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.deletedAt"
            },
            "deleted": {
                "type": "Boolean",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.deleted"
            },
            "updated_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.updated_user_id"
            },
            "created_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.created_user_id"
            },
            "deleted_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.deleted_user_id"
            },
            "organizer_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "readOnlyUpdate": true,
                "disabledUpdate": true,
                "validators": [
                    "required"
                ],
                "formNewSection": true,
                "dbtype": "String",
                "table": "organizer",
                "label": "table.organizer"
            }
        },
        "related": {
            "usergroup_id": {
                "table": "usergroup",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "usergroup_id",
                "alias": "userusergroup_id"
            },
            "organizer_id": {
                "table": "organizer",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "organizer_id",
                "alias": "userorganizer_id"
            }
        },
        "listview": {
            "fields": {
                "nick": true,
                "name": true,
                "longname": true,
                "isadmin": true,
                "lastaccessdate": true,
                "lastactiondate": true,
                "countactionsmonth": true,
                "organizer_id": true
            }
        },
        "listFilters": {
            "fields": {
                "organizer_id": {
                    "target": [],
                    "tableRelatedAlias": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "userorganizer_id"
                    },
                    "table": "organizer",
                    "fieldName": "organizer_id",
                    "label": "table.organizer",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "readOnlyUpdate": true,
                        "disabledUpdate": true,
                        "validators": [
                            "required"
                        ],
                        "formNewSection": true,
                        "dbtype": "String",
                        "table": "organizer",
                        "label": "table.organizer"
                    },
                    "tableRelated": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "userorganizer_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "nick": {
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "nick",
                    "target": [
                        "maincrud"
                    ],
                    "label": "form.nick",
                    "fieldSource": {
                        "type": "String",
                        "validators": [
                            "required",
                            "minLength:6",
                            "maxLength:15"
                        ],
                        "disabledUpdate": true,
                        "normalize": "LowerCaseTrim",
                        "readonlyUpdate": true,
                        "sublabel": "info.uniquenick",
                        "dbtype": "String",
                        "typeInput": "text",
                        "label": "form.nick"
                    },
                    "tableRelated": "",
                    "type": "String",
                    "typeInput": "text",
                    "listSource": ""
                },
                "longname": {
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "longname",
                    "target": [
                        "maincrud"
                    ],
                    "label": "form.longname",
                    "fieldSource": {
                        "type": "String",
                        "validators": [
                            "required",
                            "minLength:3"
                        ],
                        "label": "form.longname",
                        "dbtype": "String",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "String",
                    "typeInput": "text",
                    "listSource": ""
                },
                "id": {
                    "target": "listmini",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "id",
                    "fieldSource": {
                        "type": "ID!",
                        "listmini": true,
                        "dbtype": "ID",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "ID!",
                    "typeInput": "text",
                    "listSource": ""
                },
                "name": {
                    "target": [
                        "listmini"
                    ],
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "name",
                    "label": "form.shortname",
                    "fieldSource": {
                        "type": "String",
                        "validators": [
                            "required",
                            "minLength:3",
                            "maxLength:15"
                        ],
                        "formNewSection": true,
                        "label": "form.shortname",
                        "sublabel": "info.usershortname",
                        "sublabeldown": true,
                        "listmini": true,
                        "dbtype": "String",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "String",
                    "typeInput": "text",
                    "listSource": ""
                }
            }
        },
        "icon": "person",
        "destination": [
            "serverSchema",
            "serverResolver",
            "ormModels",
            "components",
            "app"
        ],
        "disabledUserNotAdmin": {
            "add": {},
            "update": {},
            "view": {},
            "fields": {
                "password": true
            }
        },
        "name": {
            "singular": "user",
            "plural": "users",
            "singularCase": "User",
            "pluralCase": "Users"
        },
        "mainfield": "name",
        "miniFields": [
            "id",
            "name"
        ],
        "componentName": "UserView",
        "path": "/user-view/:id"
    },
    "usergroup": {
        "help": {
            "fr-PELE": "https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.xha5jp6ly0n4"
        },
        "ai": true,
        "listOrder": "\"usergroup\".\"updatedAt\":DESC",
        "uniquefields": {
            "1": [
                "name"
            ]
        },
        "fields": {
            "id": {
                "type": "ID!",
                "listmini": true,
                "dbtype": "ID",
                "typeInput": "text"
            },
            "name": {
                "type": "String",
                "validators": [
                    "required",
                    "minLength:3"
                ],
                "listmini": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.name"
            },
            "accessrestrictedto": {
                "type": "String",
                "dbtype": "Json",
                "labelChildsShow": true,
                "placeHolderShowChildsShow": false,
                "subfields": {
                    "id": {
                        "type": "ID",
                        "typeInput": "hidden",
                        "label": "form.id"
                    },
                    "tour_id": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "table": "tour",
                        "validators": [
                            "required"
                        ],
                        "label": "table.tour"
                    }
                },
                "formNewSection": true,
                "typeInput": "text",
                "label": "form.accessrestrictedto",
                "mode": {}
            },
            "permissions": {
                "type": "String",
                "dbtype": "Json",
                "placeHolderShowChildsShow": true,
                "mode": {
                    "disabledAdd": true,
                    "disabledDelete": true,
                    "disabledEnum": true,
                    "disabledFieldUpdates": [
                        "option"
                    ],
                    "insertMatchAllValues": {
                        "field": "option",
                        "si": {
                            "disabledPermissionOption": false
                        }
                    }
                },
                "subfields": {
                    "id": {
                        "type": "ID",
                        "typeInput": "hidden",
                        "label": "form.id"
                    },
                    "option": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "listSource": "table",
                        "label": "form.option"
                    },
                    "view": {
                        "type": "Boolean",
                        "label": "info.vView",
                        "typeInput": "checkbox"
                    },
                    "add": {
                        "type": "Boolean",
                        "label": "info.vAdd",
                        "typeInput": "checkbox"
                    },
                    "update": {
                        "type": "Boolean",
                        "label": "info.vUpdate",
                        "typeInput": "checkbox"
                    },
                    "delete": {
                        "type": "Boolean",
                        "label": "info.vDelete",
                        "typeInput": "checkbox"
                    }
                },
                "typeInput": "text",
                "label": "form.permissions"
            },
            "updatedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "dbAutoUpdate": true,
                "excludedInForm": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.updatedAt"
            },
            "createdAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "dbAutoUpdate": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.createdAt"
            },
            "deletedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "disabledMutation": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.deletedAt"
            },
            "deleted": {
                "type": "Boolean",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.deleted"
            },
            "updated_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.updated_user_id"
            },
            "created_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.created_user_id"
            },
            "deleted_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.deleted_user_id"
            },
            "organizer_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "readOnlyUpdate": true,
                "disabledUpdate": true,
                "validators": [
                    "required"
                ],
                "formNewSection": true,
                "dbtype": "String",
                "table": "organizer",
                "label": "table.organizer"
            }
        },
        "listview": {
            "fields": {
                "name": true,
                "organizer_id": true
            }
        },
        "icon": "people",
        "destination": [
            "serverSchema",
            "serverResolver",
            "ormModels",
            "components",
            "app"
        ],
        "disabledOverWrite": {
            "form": true
        },
        "disabledNormalUser": {
            "add": {},
            "update": {},
            "view": {},
            "list": {}
        },
        "disabledDelete": true,
        "formlayout": [
            "1Row1Field"
        ],
        "name": {
            "singular": "usergroup",
            "plural": "usergroups",
            "singularCase": "Usergroup",
            "pluralCase": "Usergroups"
        },
        "mainfield": "name",
        "related": {
            "organizer_id": {
                "table": "organizer",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "organizer_id",
                "alias": "usergrouporganizer_id"
            },
            "updatedUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "updated_user_id",
                "qlDisable": true,
                "alias": "usergroupupdated_user_id"
            },
            "createdUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "created_user_id",
                "qlDisable": true,
                "alias": "usergroupcreated_user_id"
            }
        },
        "miniFields": [
            "id",
            "name"
        ],
        "listFilters": {
            "fields": {
                "organizer_id": {
                    "target": [],
                    "tableRelatedAlias": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "usergrouporganizer_id"
                    },
                    "table": "organizer",
                    "fieldName": "organizer_id",
                    "label": "table.organizer",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "readOnlyUpdate": true,
                        "disabledUpdate": true,
                        "validators": [
                            "required"
                        ],
                        "formNewSection": true,
                        "dbtype": "String",
                        "table": "organizer",
                        "label": "table.organizer"
                    },
                    "tableRelated": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "usergrouporganizer_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "id": {
                    "target": "listmini",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "id",
                    "fieldSource": {
                        "type": "ID!",
                        "listmini": true,
                        "dbtype": "ID",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "ID!",
                    "typeInput": "text",
                    "listSource": ""
                },
                "name": {
                    "target": [
                        "listmini"
                    ],
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "name",
                    "label": "form.name",
                    "fieldSource": {
                        "type": "String",
                        "validators": [
                            "required",
                            "minLength:3"
                        ],
                        "listmini": true,
                        "dbtype": "String",
                        "typeInput": "text",
                        "label": "form.name"
                    },
                    "tableRelated": "",
                    "type": "String",
                    "typeInput": "text",
                    "listSource": ""
                }
            }
        },
        "componentName": "UsergroupView",
        "path": "/usergroup-view/:id"
    },
    "ejobmail": {
        "help": {
            "fr-PELE": "https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.ot370tiexjki"
        },
        "module": "MAIL",
        "disabledUpdate": true,
        "disableRecreateTemplateForm": true,
        "pollInterval": 7,
        "destination": [
            "serverSchema",
            "serverResolver",
            "ormModels",
            "components",
            "app"
        ],
        "toolbar": {
            "menus": {
                "mailing": {
                    "inListHeader": true,
                    "inView": true,
                    "label": "menu.messaging",
                    "urlType": "link",
                    "icon": "mailing",
                    "url": "/mailing"
                }
            }
        },
        "orders": {
            "noOptionBlank": true,
            "defaultValue": "updatedAt",
            "label": "form.order",
            "inLine": true,
            "fields": {
                "createdAt": {},
                "updatedAt": {
                    "label": "form.updateAtDesc",
                    "order": "DESC"
                }
            }
        },
        "listview": {
            "fields": {
                "state": true,
                "channels": {
                    "subfields": {
                        "d": true,
                        "r": true,
                        "q": true
                    },
                    "joinsubfields": true,
                    "separator": ""
                },
                "title": true,
                "customer_id": true,
                "tour_id": true,
                "createdAt": true,
                "files": {
                    "subfields": {
                        "file": true,
                        "sinfo": true
                    },
                    "joinsubfields": true
                },
                "organizer_id": true
            }
        },
        "listFilters": {
            "fields": {
                "organizer_id": {
                    "target": [],
                    "tableRelatedAlias": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "ejobmailorganizer_id"
                    },
                    "table": "organizer",
                    "fieldName": "organizer_id",
                    "label": "table.organizer",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "readOnlyUpdate": true,
                        "disabledUpdate": true,
                        "validators": [
                            "required"
                        ],
                        "formNewSection": true,
                        "dbtype": "String",
                        "table": "organizer",
                        "label": "table.organizer"
                    },
                    "tableRelated": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "ejobmailorganizer_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "title": {
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "title",
                    "target": [
                        "maincrud"
                    ],
                    "label": "form.esubject",
                    "fieldSource": {
                        "type": "String",
                        "sublabel": "form.messagingtitle",
                        "label": "form.esubject",
                        "validators": [
                            "required",
                            "minLength:10",
                            "maxLength:70"
                        ],
                        "formNewSection": true,
                        "formRowExpand": true,
                        "onChange": true,
                        "styleInput": {
                            "minWidth": "98%"
                        },
                        "sectionStyle": {
                            "formSection": {
                                "maxWidth": "650px"
                            }
                        },
                        "dbtype": "String",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "String",
                    "typeInput": "text",
                    "listSource": ""
                },
                "customer_id": {
                    "tableRelatedAlias": {
                        "table": "customer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "customer_id",
                        "listmini": true,
                        "alias": "ejobmailcustomer_id"
                    },
                    "table": "customer",
                    "fieldName": "customer_id",
                    "target": [
                        "maincrud"
                    ],
                    "label": "table.customer",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "disabledUpdate": true,
                        "onChange": true,
                        "validators": [
                            "required"
                        ],
                        "hiddenOnAdd": true,
                        "prehtml": "<div className=\"columnBigContainer\"><div className = \"columnContainerLeft\">",
                        "dbtype": "Json",
                        "table": "customer",
                        "loadAsync": true,
                        "saveonly": "id",
                        "label": "table.customer"
                    },
                    "tableRelated": {
                        "table": "customer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "customer_id",
                        "listmini": true,
                        "alias": "ejobmailcustomer_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "tour_id": {
                    "tableRelatedAlias": {
                        "table": "tour",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "tour_id",
                        "listmini": true,
                        "alias": "ejobmailtour_id"
                    },
                    "table": "tour",
                    "fieldName": "tour_id",
                    "target": [
                        "maincrud"
                    ],
                    "label": "table.tour",
                    "fieldSource": {
                        "type": "ID",
                        "typeInput": "selectAutocomplete",
                        "onChange": true,
                        "disabledAdd": true,
                        "disabledUpdate": true,
                        "dbtype": "ID",
                        "table": "tour",
                        "label": "table.tour"
                    },
                    "tableRelated": {
                        "table": "tour",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "tour_id",
                        "listmini": true,
                        "alias": "ejobmailtour_id"
                    },
                    "type": "ID",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "id": {
                    "target": "listmini",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "id",
                    "fieldSource": {
                        "type": "ID!",
                        "listmini": true,
                        "dbtype": "ID",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "ID!",
                    "typeInput": "text",
                    "listSource": ""
                }
            }
        },
        "fields": {
            "id": {
                "type": "ID!",
                "listmini": true,
                "dbtype": "ID",
                "typeInput": "text"
            },
            "customer_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "disabledUpdate": true,
                "onChange": true,
                "validators": [
                    "required"
                ],
                "hiddenOnAdd": true,
                "prehtml": "<div className=\"columnBigContainer\"><div className = \"columnContainerLeft\">",
                "dbtype": "Json",
                "table": "customer",
                "loadAsync": true,
                "saveonly": "id",
                "label": "table.customer"
            },
            "channels": {
                "type": "String",
                "dbtype": "Json",
                "label": "form.channelsfilters",
                "excludedInForm": true,
                "subfields": {
                    "t": {
                        "type": "Int",
                        "label": "form.type",
                        "dbtype": "Int",
                        "typeInput": "selectBox",
                        "listSource": "messagesChannel",
                        "translateDisabledSource": true
                    },
                    "d": {
                        "type": "String",
                        "label": "",
                        "typeInput": "text"
                    },
                    "r": {
                        "type": "Boolean",
                        "icons": {
                            "true": {
                                "icon": "check",
                                "color": "LightGreen"
                            },
                            "false": {
                                "icon": "close",
                                "color": "Red"
                            }
                        },
                        "label": "",
                        "typeInput": "checkbox"
                    },
                    "q": {
                        "type": "Int",
                        "labeltemplate": " ({value})",
                        "typeInput": "text",
                        "label": "form.q"
                    }
                },
                "typeInput": "text"
            },
            "channelsfilters": {
                "formNewSection": true,
                "onChange": true,
                "type": "Int",
                "dbtype": "Int",
                "typeInput": "selectBox",
                "listOptions": [
                    {
                        "id": 7,
                        "name": "form.sendEmailorDoc"
                    },
                    {
                        "id": 30,
                        "name": "form.sendOnlyEmail"
                    },
                    {
                        "id": 40,
                        "name": "form.genOnlyDoc"
                    }
                ],
                "showLabel": true,
                "defaultValue": 7,
                "virtual": true,
                "validators": [],
                "preComponents": {
                    "components": {
                        "CustomersRecipients": {
                            "condition": "(this.props.containerPropsForm.containerState) ",
                            "position": "outsideAfterCloseFieldsContainer"
                        }
                    }
                },
                "label": "form.channelsfilters"
            },
            "etemplate_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "disabledUpdate": true,
                "onChange": true,
                "formNewSection": true,
                "validators": [
                    "required"
                ],
                "preComponents": {
                    "components": {
                        "ChannelsMessaging": {
                            "position": "outsideAfterCloseFieldsContainer"
                        }
                    }
                },
                "dbtype": "String",
                "table": "etemplate",
                "label": "table.etemplate"
            },
            "title": {
                "type": "String",
                "sublabel": "form.messagingtitle",
                "label": "form.esubject",
                "validators": [
                    "required",
                    "minLength:10",
                    "maxLength:70"
                ],
                "formNewSection": true,
                "formRowExpand": true,
                "onChange": true,
                "styleInput": {
                    "minWidth": "98%"
                },
                "sectionStyle": {
                    "formSection": {
                        "maxWidth": "650px"
                    }
                },
                "dbtype": "String",
                "typeInput": "text"
            },
            "tour_id": {
                "type": "ID",
                "typeInput": "selectAutocomplete",
                "onChange": true,
                "disabledAdd": true,
                "disabledUpdate": true,
                "dbtype": "ID",
                "table": "tour",
                "label": "table.tour"
            },
            "limitexceeded": {
                "type": "String",
                "typeInput": "hidden",
                "virtual": true,
                "validators": [
                    "required"
                ],
                "dbtype": "String",
                "label": "form.limitexceeded"
            },
            "totalMessaging": {
                "type": "Int",
                "typeInput": "hidden",
                "virtual": true,
                "validators": [
                    "minValue:1"
                ],
                "dbtype": "Int",
                "label": "form.totalMessaging"
            },
            "confirmationstatus_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "hiddenOnUpdate": true,
                "virtual": true,
                "norelated": "confirmationstatus",
                "dbtype": "String",
                "table": "confirmationstatus",
                "label": "table.confirmationstatus"
            },
            "state": {
                "type": "Int",
                "dbtype": "Int",
                "typeInput": "selectBox",
                "listSource": "processBaseStatus",
                "listmini": true,
                "hiddenOnAdd": true,
                "hiddenOnUpdate": true,
                "disabledUpdate": true,
                "defaultValue": 1,
                "icon": true,
                "label": "form.state"
            },
            "customersrecipients": {
                "type": "String",
                "typeInput": "hidden",
                "dbtype": "Json",
                "virtual": true,
                "label": "form.customersrecipients"
            },
            "templatedata": {
                "type": "String",
                "dbtype": "Json",
                "hiddenOnUpdate": true,
                "hiddenOnAdd": true,
                "virtual": true,
                "typeInput": "text",
                "label": "form.templatedata"
            },
            "channel": {
                "type": "Int",
                "typeInput": "hidden",
                "defaultValue": "1",
                "dbtype": "Int",
                "label": "form.channel"
            },
            "sent": {
                "type": "String",
                "hiddenOnUpdate": true,
                "hiddenOnAdd": true,
                "disabledUpdate": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.sent"
            },
            "templatesms": {
                "type": "String",
                "label": "form.templatesms",
                "typeInput": "textarea",
                "styleInput": {
                    "minHeight": "70px",
                    "height": "70px"
                },
                "formNewSection": true,
                "onChange": true,
                "hiddenOnUpdate": true,
                "virtual": true,
                "preventExit": true,
                "warningUp": true,
                "dbtype": "String"
            },
            "sms": {
                "posthtml": "</div>",
                "type": "String",
                "label": "form.smsprocessed",
                "typeInput": "textarea",
                "styleInput": {
                    "backgroundColor": "#EFEFEF",
                    "minHeight": "100px",
                    "height": "100px",
                    "fontSize": "11px"
                },
                "readonly": true,
                "warningUp": true,
                "errorUp": true,
                "dbtype": "String"
            },
            "template": {
                "prehtml": "<div className = \"columnContainerRight\">",
                "type": "String",
                "label": "form.emailtemplate",
                "typeInput": "textarea",
                "formNewSection": true,
                "onChange": true,
                "validators": [
                    "required"
                ],
                "hiddenOnUpdate": true,
                "virtual": true,
                "preventExit": true,
                "styleInput": {
                    "height": "174px"
                },
                "dbtype": "String"
            },
            "body": {
                "posthtml": "</div></div>",
                "type": "String",
                "label": "form.emailtprocessed",
                "typeInput": "textarea",
                "styleInput": {
                    "backgroundColor": "#EFEFEF",
                    "height": "210px",
                    "fontSize": "11px"
                },
                "readonly": true,
                "dbtype": "String"
            },
            "files": {
                "type": "String",
                "dbtype": "Json",
                "placeHolderShowChildsShow": true,
                "mode": {
                    "disabledRowAddOnUpdate": true,
                    "disabledRowInputsOnUpdate": true,
                    "disabledRowDeleteOnUpdate": true
                },
                "excludedInForm": true,
                "disabledMutation": true,
                "subfields": {
                    "id": {
                        "type": "ID",
                        "typeInput": "hidden",
                        "label": "form.id"
                    },
                    "sinfo": {
                        "noLabel": true,
                        "type": "String",
                        "hiddenOnAdd": true,
                        "disabled": true,
                        "typeInput": "text",
                        "label": "form.sinfo"
                    },
                    "file": {
                        "type": "String",
                        "disabled": true,
                        "hiddenOnAdd": true,
                        "url": "{DOWN_URI}{link}",
                        "typeInput": "urlLink",
                        "noLabel": true,
                        "label": "form.file"
                    }
                },
                "typeInput": "text",
                "label": "form.files"
            },
            "updatedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "dbAutoUpdate": true,
                "excludedInForm": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.updatedAt"
            },
            "createdAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "dbAutoUpdate": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.createdAt"
            },
            "deletedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "disabledMutation": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.deletedAt"
            },
            "deleted": {
                "type": "Boolean",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.deleted"
            },
            "updated_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.updated_user_id"
            },
            "created_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.created_user_id"
            },
            "deleted_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.deleted_user_id"
            },
            "organizer_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "readOnlyUpdate": true,
                "disabledUpdate": true,
                "validators": [
                    "required"
                ],
                "formNewSection": true,
                "dbtype": "String",
                "table": "organizer",
                "label": "table.organizer"
            }
        },
        "norelated": {
            "confirmationstatus": {
                "table": "confirmationstatus",
                "relation": "oneChild",
                "fields": [
                    "name",
                    "color"
                ],
                "key": "confirmationstatus_id",
                "norelated": true
            }
        },
        "related": {
            "customer_id": {
                "table": "customer",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "customer_id",
                "listmini": true,
                "alias": "ejobmailcustomer_id"
            },
            "tour_id": {
                "table": "tour",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "tour_id",
                "listmini": true,
                "alias": "ejobmailtour_id"
            },
            "etemplate_id": {
                "table": "etemplate",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "etemplate_id",
                "listmini": true,
                "alias": "ejobmailetemplate_id"
            },
            "organizer_id": {
                "table": "organizer",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "organizer_id",
                "alias": "ejobmailorganizer_id"
            },
            "updatedUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "updated_user_id",
                "qlDisable": true,
                "alias": "ejobmailupdated_user_id"
            },
            "createdUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "created_user_id",
                "qlDisable": true,
                "alias": "ejobmailcreated_user_id"
            }
        },
        "name": {
            "singular": "ejobmail",
            "plural": "ejobmails",
            "singularCase": "Ejobmail",
            "pluralCase": "Ejobmails"
        },
        "mainfield": "customer_id",
        "miniFields": [
            "id",
            "state"
        ],
        "componentName": "EjobmailView",
        "path": "/ejobmail-view/:id"
    },
    "etemplate": {
        "help": {
            "fr-PELE": "https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.yfysncjhnvjm"
        },
        "module": "MAIL",
        "afterAddGoView": true,
        "destination": [
            "serverSchema",
            "serverResolver",
            "ormModels",
            "components",
            "app"
        ],
        "toolbar": {
            "menus": {
                "mailing": {
                    "inListHeader": true,
                    "inView": true,
                    "label": "menu.messaging",
                    "urlType": "link",
                    "icon": "mailing",
                    "url": "/mailing"
                },
                "duplicate": {
                    "inView": true,
                    "label": "info.duplicate",
                    "urlType": "fn"
                }
            }
        },
        "orders": {
            "noOptionBlank": true,
            "defaultValue": "name",
            "label": "form.order",
            "inLine": true,
            "fields": {
                "name": {},
                "title": {},
                "createdAt": {},
                "updatedAt": {
                    "label": "form.updateAtDesc",
                    "order": "DESC"
                }
            }
        },
        "listview": {
            "fields": {
                "name": true,
                "title": true,
                "createdAt": true,
                "updatedAt": true,
                "organizer_id": true
            }
        },
        "listFilters": {
            "fields": {
                "organizer_id": {
                    "target": [],
                    "tableRelatedAlias": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "etemplateorganizer_id"
                    },
                    "table": "organizer",
                    "fieldName": "organizer_id",
                    "label": "table.organizer",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "readOnlyUpdate": true,
                        "disabledUpdate": true,
                        "validators": [
                            "required"
                        ],
                        "formNewSection": true,
                        "dbtype": "String",
                        "table": "organizer",
                        "label": "table.organizer"
                    },
                    "tableRelated": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "etemplateorganizer_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "name": {
                    "target": [
                        "maincrud",
                        "listmini"
                    ],
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "name",
                    "label": "form.name",
                    "fieldSource": {
                        "type": "String",
                        "listmini": true,
                        "formNewSection": true,
                        "validators": [
                            "required",
                            "minLength:3",
                            "maxLength:25"
                        ],
                        "dbtype": "String",
                        "typeInput": "text",
                        "label": "form.name"
                    },
                    "tableRelated": "",
                    "type": "String",
                    "typeInput": "text",
                    "listSource": ""
                },
                "title": {
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "title",
                    "target": [
                        "maincrud",
                        "listmini"
                    ],
                    "label": "form.esubject",
                    "fieldSource": {
                        "type": "String",
                        "label": "form.esubject",
                        "width": "700px",
                        "listmini": true,
                        "formNewSection": true,
                        "validators": [
                            "required",
                            "minLength:10",
                            "maxLength:70"
                        ],
                        "dbtype": "String",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "String",
                    "typeInput": "text",
                    "listSource": ""
                },
                "id": {
                    "target": "listmini",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "id",
                    "fieldSource": {
                        "type": "ID!",
                        "listmini": true,
                        "dbtype": "ID",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "ID!",
                    "typeInput": "text",
                    "listSource": ""
                }
            }
        },
        "uniquefields": {
            "1": [
                "name"
            ]
        },
        "fields": {
            "id": {
                "type": "ID!",
                "listmini": true,
                "dbtype": "ID",
                "typeInput": "text"
            },
            "name": {
                "type": "String",
                "listmini": true,
                "formNewSection": true,
                "validators": [
                    "required",
                    "minLength:3",
                    "maxLength:25"
                ],
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.name"
            },
            "channel": {
                "type": "Int",
                "typeInput": "hidden",
                "defaultValue": "1",
                "dbtype": "Int",
                "label": "form.channel"
            },
            "title": {
                "type": "String",
                "label": "form.esubject",
                "width": "700px",
                "listmini": true,
                "formNewSection": true,
                "validators": [
                    "required",
                    "minLength:10",
                    "maxLength:70"
                ],
                "dbtype": "String",
                "typeInput": "text"
            },
            "body": {
                "type": "String",
                "label": "form.emailtemplate",
                "typeInput": "textarea",
                "formNewSection": true,
                "listmini": true,
                "validators": [
                    "required"
                ],
                "dbtype": "String"
            },
            "sms": {
                "type": "String",
                "label": "form.templatesms",
                "sublabel": "info.templatesms",
                "typeInput": "hidden",
                "formNewSection": true,
                "listmini": true,
                "onChange": true,
                "validators": [
                    "maxLength:550"
                ],
                "dbtype": "String"
            },
            "updatedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "dbAutoUpdate": true,
                "excludedInForm": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.updatedAt"
            },
            "createdAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "dbAutoUpdate": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.createdAt"
            },
            "deletedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "disabledMutation": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.deletedAt"
            },
            "deleted": {
                "type": "Boolean",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.deleted"
            },
            "updated_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.updated_user_id"
            },
            "created_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.created_user_id"
            },
            "deleted_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.deleted_user_id"
            },
            "organizer_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "readOnlyUpdate": true,
                "disabledUpdate": true,
                "validators": [
                    "required"
                ],
                "formNewSection": true,
                "dbtype": "String",
                "table": "organizer",
                "label": "table.organizer"
            }
        },
        "related": {
            "organizer_id": {
                "table": "organizer",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "organizer_id",
                "alias": "etemplateorganizer_id"
            },
            "updatedUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "updated_user_id",
                "qlDisable": true,
                "alias": "etemplateupdated_user_id"
            },
            "createdUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "created_user_id",
                "qlDisable": true,
                "alias": "etemplatecreated_user_id"
            }
        },
        "name": {
            "singular": "etemplate",
            "plural": "etemplates",
            "singularCase": "Etemplate",
            "pluralCase": "Etemplates"
        },
        "mainfield": "name",
        "miniFields": [
            "id",
            "name",
            "title",
            "body",
            "sms"
        ],
        "componentName": "EtemplateView",
        "path": "/etemplate-view/:id"
    },
    "ejobday": {
        "module": "MAIL",
        "disabledView": true,
        "disabledUpdate": true,
        "disabledAdd": true,
        "disabledDelete": true,
        "destination": [
            "serverSchema",
            "serverResolver",
            "ormModels",
            "components",
            "app"
        ],
        "toolbar": {
            "menus": {
                "mailing": {
                    "inListHeader": true,
                    "inView": true,
                    "label": "menu.messaging",
                    "urlType": "link",
                    "icon": "mailing",
                    "url": "/mailing"
                }
            }
        },
        "orders": {
            "noOptionBlank": true,
            "defaultValue": "nameDesc",
            "label": "form.order",
            "inLine": true,
            "fields": {
                "nameDesc": {
                    "label": "form.date",
                    "order": "DESC",
                    "labelorder": true,
                    "fieldName": "name"
                },
                "nameAsc": {
                    "label": "form.date",
                    "order": "DESC",
                    "labelorder": true,
                    "fieldName": "name"
                }
            }
        },
        "listview": {
            "fields": {
                "name": true,
                "qty": true,
                "qtysms": true,
                "organizer_id": true
            }
        },
        "listFilters": {
            "fields": {
                "organizer_id": {
                    "target": [],
                    "tableRelatedAlias": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "ejobdayorganizer_id"
                    },
                    "table": "organizer",
                    "fieldName": "organizer_id",
                    "label": "table.organizer",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "readOnlyUpdate": true,
                        "disabledUpdate": true,
                        "validators": [
                            "required"
                        ],
                        "formNewSection": true,
                        "dbtype": "String",
                        "table": "organizer",
                        "label": "table.organizer"
                    },
                    "tableRelated": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "ejobdayorganizer_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "id": {
                    "target": "listmini",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "id",
                    "fieldSource": {
                        "type": "ID!",
                        "listmini": true,
                        "dbtype": "ID",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "ID!",
                    "typeInput": "text",
                    "listSource": ""
                },
                "name": {
                    "target": [
                        "listmini"
                    ],
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "name",
                    "label": "form.date",
                    "fieldSource": {
                        "type": "String",
                        "dbtype": "Date",
                        "validators": [
                            "required",
                            "date"
                        ],
                        "listmini": true,
                        "label": "form.date",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "String",
                    "typeInput": "text",
                    "listSource": ""
                }
            }
        },
        "fields": {
            "id": {
                "type": "ID!",
                "listmini": true,
                "dbtype": "ID",
                "typeInput": "text"
            },
            "name": {
                "type": "String",
                "dbtype": "Date",
                "validators": [
                    "required",
                    "date"
                ],
                "listmini": true,
                "label": "form.date",
                "typeInput": "text"
            },
            "qty": {
                "type": "String",
                "label": "form.qtyemail",
                "dbtype": "String",
                "typeInput": "text"
            },
            "qtysms": {
                "type": "String",
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.qtysms"
            },
            "updatedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "dbAutoUpdate": true,
                "excludedInForm": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.updatedAt"
            },
            "createdAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "dbAutoUpdate": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.createdAt"
            },
            "deletedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "disabledMutation": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.deletedAt"
            },
            "deleted": {
                "type": "Boolean",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.deleted"
            },
            "updated_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.updated_user_id"
            },
            "created_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.created_user_id"
            },
            "deleted_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.deleted_user_id"
            },
            "organizer_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "readOnlyUpdate": true,
                "disabledUpdate": true,
                "validators": [
                    "required"
                ],
                "formNewSection": true,
                "dbtype": "String",
                "table": "organizer",
                "label": "table.organizer"
            }
        },
        "name": {
            "singular": "ejobday",
            "plural": "ejobdays",
            "singularCase": "Ejobday",
            "pluralCase": "Ejobdays"
        },
        "mainfield": "name",
        "related": {
            "organizer_id": {
                "table": "organizer",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "organizer_id",
                "alias": "ejobdayorganizer_id"
            },
            "updatedUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "updated_user_id",
                "qlDisable": true,
                "alias": "ejobdayupdated_user_id"
            },
            "createdUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "created_user_id",
                "qlDisable": true,
                "alias": "ejobdaycreated_user_id"
            }
        },
        "miniFields": [
            "id",
            "name"
        ],
        "componentName": "EjobdayList",
        "path": "/ejobday"
    },
    "ejobmonth": {
        "module": "MAIL",
        "disabledView": true,
        "disabledUpdate": true,
        "disabledAdd": true,
        "disabledDelete": true,
        "destination": [
            "serverSchema",
            "serverResolver",
            "ormModels",
            "components",
            "app"
        ],
        "toolbar": {
            "menus": {
                "mailing": {
                    "inListHeader": true,
                    "inView": true,
                    "label": "menu.messaging",
                    "urlType": "link",
                    "icon": "mailing",
                    "url": "/mailing"
                }
            }
        },
        "orders": {
            "noOptionBlank": true,
            "defaultValue": "nameDesc",
            "label": "form.order",
            "inLine": true,
            "fields": {
                "nameDesc": {
                    "label": "form.month",
                    "order": "DESC",
                    "labelorder": true,
                    "fieldName": "name"
                },
                "nameAsc": {
                    "label": "form.date",
                    "order": "DESC",
                    "labelorder": true,
                    "fieldName": "name"
                }
            }
        },
        "listview": {
            "fields": {
                "name": true,
                "qty": true,
                "qtysms": true,
                "amount": true,
                "paid": true,
                "balance": true,
                "organizer_id": true
            }
        },
        "listFilters": {
            "fields": {
                "organizer_id": {
                    "target": [],
                    "tableRelatedAlias": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "ejobmonthorganizer_id"
                    },
                    "table": "organizer",
                    "fieldName": "organizer_id",
                    "label": "table.organizer",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "readOnlyUpdate": true,
                        "disabledUpdate": true,
                        "validators": [
                            "required"
                        ],
                        "formNewSection": true,
                        "dbtype": "String",
                        "table": "organizer",
                        "label": "table.organizer"
                    },
                    "tableRelated": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "ejobmonthorganizer_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "id": {
                    "target": "listmini",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "id",
                    "fieldSource": {
                        "type": "ID!",
                        "listmini": true,
                        "dbtype": "ID",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "ID!",
                    "typeInput": "text",
                    "listSource": ""
                },
                "name": {
                    "target": [
                        "listmini"
                    ],
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "name",
                    "label": "form.date",
                    "fieldSource": {
                        "type": "String",
                        "validators": [
                            "required"
                        ],
                        "listmini": true,
                        "label": "form.date",
                        "dbtype": "String",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "String",
                    "typeInput": "text",
                    "listSource": ""
                }
            }
        },
        "fields": {
            "id": {
                "type": "ID!",
                "listmini": true,
                "dbtype": "ID",
                "typeInput": "text"
            },
            "name": {
                "type": "String",
                "validators": [
                    "required"
                ],
                "listmini": true,
                "label": "form.date",
                "dbtype": "String",
                "typeInput": "text"
            },
            "qty": {
                "type": "String",
                "label": "form.qtyemail",
                "dbtype": "String",
                "typeInput": "text"
            },
            "qtysms": {
                "type": "String",
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.qtysms"
            },
            "amount": {
                "type": "Float",
                "typeValue": "Price",
                "defaultValue": 0,
                "dbtype": "Float",
                "typeInput": "text",
                "normalize": "Price",
                "label": "form.amount"
            },
            "paid": {
                "type": "Float",
                "typeValue": "Price",
                "defaultValue": 0,
                "dbtype": "Float",
                "typeInput": "text",
                "normalize": "Price",
                "label": "form.paid"
            },
            "balance": {
                "type": "Float",
                "typeValue": "Price",
                "defaultValue": 0,
                "dbtype": "Float",
                "typeInput": "text",
                "normalize": "Price",
                "label": "form.balance"
            },
            "updatedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "dbAutoUpdate": true,
                "excludedInForm": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.updatedAt"
            },
            "createdAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "dbAutoUpdate": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.createdAt"
            },
            "deletedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "disabledMutation": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.deletedAt"
            },
            "deleted": {
                "type": "Boolean",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.deleted"
            },
            "updated_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.updated_user_id"
            },
            "created_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.created_user_id"
            },
            "deleted_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.deleted_user_id"
            },
            "organizer_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "readOnlyUpdate": true,
                "disabledUpdate": true,
                "validators": [
                    "required"
                ],
                "formNewSection": true,
                "dbtype": "String",
                "table": "organizer",
                "label": "table.organizer"
            }
        },
        "name": {
            "singular": "ejobmonth",
            "plural": "ejobmonths",
            "singularCase": "Ejobmonth",
            "pluralCase": "Ejobmonths"
        },
        "mainfield": "name",
        "related": {
            "organizer_id": {
                "table": "organizer",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "organizer_id",
                "alias": "ejobmonthorganizer_id"
            },
            "updatedUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "updated_user_id",
                "qlDisable": true,
                "alias": "ejobmonthupdated_user_id"
            },
            "createdUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "created_user_id",
                "qlDisable": true,
                "alias": "ejobmonthcreated_user_id"
            }
        },
        "miniFields": [
            "id",
            "name"
        ],
        "componentName": "EjobmonthList",
        "path": "/ejobmonth"
    },
    "aplan": {
        "module": "ACCO",
        "afterAddGoView": true,
        "destination": [
            "serverSchema",
            "serverResolver",
            "ormModels",
            "components",
            "app"
        ],
        "toolbar": {
            "menus": {
                "accounting": {
                    "inListHeader": true,
                    "inView": true,
                    "label": "menu.accounting",
                    "urlType": "link",
                    "icon": "accounting",
                    "url": "/accounting"
                }
            }
        },
        "orders": {
            "noOptionBlank": true,
            "defaultValue": "name",
            "label": "form.order",
            "inLine": true,
            "fields": {
                "name": {},
                "title": {},
                "createdAt": {},
                "updatedAt": {
                    "label": "form.updateAtDesc",
                    "order": "DESC"
                }
            }
        },
        "listview": {
            "fields": {
                "name": true,
                "title": true,
                "createdAt": true,
                "updatedAt": true,
                "organizer_id": true
            }
        },
        "listFilters": {
            "fields": {
                "organizer_id": {
                    "target": [],
                    "tableRelatedAlias": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "aplanorganizer_id"
                    },
                    "table": "organizer",
                    "fieldName": "organizer_id",
                    "label": "table.organizer",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "readOnlyUpdate": true,
                        "disabledUpdate": true,
                        "validators": [
                            "required"
                        ],
                        "formNewSection": true,
                        "dbtype": "String",
                        "table": "organizer",
                        "label": "table.organizer"
                    },
                    "tableRelated": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "aplanorganizer_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "name": {
                    "target": [
                        "maincrud",
                        "listmini"
                    ],
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "name",
                    "label": "form.accountingnumber",
                    "fieldSource": {
                        "type": "String",
                        "validators": [
                            "required"
                        ],
                        "listmini": true,
                        "label": "form.accountingnumber",
                        "dbtype": "String",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "String",
                    "typeInput": "text",
                    "listSource": ""
                },
                "title": {
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "title",
                    "target": [
                        "maincrud",
                        "listmini"
                    ],
                    "label": "form.title",
                    "fieldSource": {
                        "type": "String",
                        "validators": [
                            "required"
                        ],
                        "listmini": true,
                        "dbtype": "String",
                        "typeInput": "text",
                        "label": "form.title"
                    },
                    "tableRelated": "",
                    "type": "String",
                    "typeInput": "text",
                    "listSource": ""
                },
                "id": {
                    "target": "listmini",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "id",
                    "fieldSource": {
                        "type": "ID!",
                        "listmini": true,
                        "dbtype": "ID",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "ID!",
                    "typeInput": "text",
                    "listSource": ""
                }
            }
        },
        "uniquefields": {
            "1": [
                "name"
            ],
            "2": [
                "title"
            ]
        },
        "fields": {
            "id": {
                "type": "ID!",
                "listmini": true,
                "dbtype": "ID",
                "typeInput": "text"
            },
            "name": {
                "type": "String",
                "validators": [
                    "required"
                ],
                "listmini": true,
                "label": "form.accountingnumber",
                "dbtype": "String",
                "typeInput": "text"
            },
            "title": {
                "type": "String",
                "validators": [
                    "required"
                ],
                "listmini": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.title"
            },
            "updatedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "dbAutoUpdate": true,
                "excludedInForm": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.updatedAt"
            },
            "createdAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "dbAutoUpdate": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.createdAt"
            },
            "deletedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "disabledMutation": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.deletedAt"
            },
            "deleted": {
                "type": "Boolean",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.deleted"
            },
            "updated_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.updated_user_id"
            },
            "created_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.created_user_id"
            },
            "deleted_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.deleted_user_id"
            },
            "organizer_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "readOnlyUpdate": true,
                "disabledUpdate": true,
                "validators": [
                    "required"
                ],
                "formNewSection": true,
                "dbtype": "String",
                "table": "organizer",
                "label": "table.organizer"
            }
        },
        "name": {
            "singular": "aplan",
            "plural": "aplans",
            "singularCase": "Aplan",
            "pluralCase": "Aplans"
        },
        "mainfield": "name",
        "related": {
            "organizer_id": {
                "table": "organizer",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "organizer_id",
                "alias": "aplanorganizer_id"
            },
            "updatedUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "updated_user_id",
                "qlDisable": true,
                "alias": "aplanupdated_user_id"
            },
            "createdUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "created_user_id",
                "qlDisable": true,
                "alias": "aplancreated_user_id"
            }
        },
        "miniFields": [
            "id",
            "name",
            "title"
        ],
        "componentName": "AplanView",
        "path": "/aplan-view/:id"
    },
    "acostplan": {
        "module": "ACCO",
        "afterAddGoView": true,
        "destination": [
            "serverSchema",
            "serverResolver",
            "ormModels",
            "components",
            "app"
        ],
        "toolbar": {
            "menus": {
                "accounting": {
                    "inListHeader": true,
                    "inView": true,
                    "label": "menu.accounting",
                    "urlType": "link",
                    "icon": "accounting",
                    "url": "/accounting"
                }
            }
        },
        "orders": {
            "noOptionBlank": true,
            "defaultValue": "name",
            "label": "form.order",
            "inLine": true,
            "fields": {
                "name": {},
                "title": {},
                "createdAt": {},
                "updatedAt": {
                    "label": "form.updateAtDesc",
                    "order": "DESC"
                }
            }
        },
        "listview": {
            "fields": {
                "name": true,
                "title": true,
                "createdAt": true,
                "updatedAt": true,
                "organizer_id": true
            }
        },
        "listFilters": {
            "fields": {
                "organizer_id": {
                    "target": [],
                    "tableRelatedAlias": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "acostplanorganizer_id"
                    },
                    "table": "organizer",
                    "fieldName": "organizer_id",
                    "label": "table.organizer",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "readOnlyUpdate": true,
                        "disabledUpdate": true,
                        "validators": [
                            "required"
                        ],
                        "formNewSection": true,
                        "dbtype": "String",
                        "table": "organizer",
                        "label": "table.organizer"
                    },
                    "tableRelated": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "acostplanorganizer_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "name": {
                    "target": [
                        "maincrud",
                        "listmini"
                    ],
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "name",
                    "label": "form.code",
                    "fieldSource": {
                        "type": "String",
                        "validators": [
                            "required"
                        ],
                        "listmini": true,
                        "label": "form.code",
                        "dbtype": "String",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "String",
                    "typeInput": "text",
                    "listSource": ""
                },
                "title": {
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "title",
                    "target": [
                        "maincrud",
                        "listmini"
                    ],
                    "label": "form.title",
                    "fieldSource": {
                        "type": "String",
                        "validators": [
                            "required"
                        ],
                        "listmini": true,
                        "dbtype": "String",
                        "typeInput": "text",
                        "label": "form.title"
                    },
                    "tableRelated": "",
                    "type": "String",
                    "typeInput": "text",
                    "listSource": ""
                },
                "id": {
                    "target": "listmini",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "id",
                    "fieldSource": {
                        "type": "ID!",
                        "listmini": true,
                        "dbtype": "ID",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "ID!",
                    "typeInput": "text",
                    "listSource": ""
                }
            }
        },
        "uniquefields": {
            "1": [
                "name"
            ],
            "2": [
                "title"
            ]
        },
        "fields": {
            "id": {
                "type": "ID!",
                "listmini": true,
                "dbtype": "ID",
                "typeInput": "text"
            },
            "name": {
                "type": "String",
                "validators": [
                    "required"
                ],
                "listmini": true,
                "label": "form.code",
                "dbtype": "String",
                "typeInput": "text"
            },
            "title": {
                "type": "String",
                "validators": [
                    "required"
                ],
                "listmini": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.title"
            },
            "updatedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "dbAutoUpdate": true,
                "excludedInForm": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.updatedAt"
            },
            "createdAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "dbAutoUpdate": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.createdAt"
            },
            "deletedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "disabledMutation": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.deletedAt"
            },
            "deleted": {
                "type": "Boolean",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.deleted"
            },
            "updated_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.updated_user_id"
            },
            "created_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.created_user_id"
            },
            "deleted_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.deleted_user_id"
            },
            "organizer_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "readOnlyUpdate": true,
                "disabledUpdate": true,
                "validators": [
                    "required"
                ],
                "formNewSection": true,
                "dbtype": "String",
                "table": "organizer",
                "label": "table.organizer"
            }
        },
        "name": {
            "singular": "acostplan",
            "plural": "acostplans",
            "singularCase": "Acostplan",
            "pluralCase": "Acostplans"
        },
        "mainfield": "name",
        "related": {
            "organizer_id": {
                "table": "organizer",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "organizer_id",
                "alias": "acostplanorganizer_id"
            },
            "updatedUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "updated_user_id",
                "qlDisable": true,
                "alias": "acostplanupdated_user_id"
            },
            "createdUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "created_user_id",
                "qlDisable": true,
                "alias": "acostplancreated_user_id"
            }
        },
        "miniFields": [
            "id",
            "name",
            "title"
        ],
        "componentName": "AcostplanView",
        "path": "/acostplan-view/:id"
    },
    "ajournalcode": {
        "afterAddGoView": true,
        "module": "ACCO",
        "destination": [
            "serverSchema",
            "serverResolver",
            "ormModels",
            "components",
            "app"
        ],
        "toolbar": {
            "menus": {
                "accounting": {
                    "inListHeader": true,
                    "inView": true,
                    "label": "menu.accounting",
                    "urlType": "link",
                    "icon": "accounting",
                    "url": "/accounting"
                }
            }
        },
        "orders": {
            "noOptionBlank": true,
            "defaultValue": "name",
            "label": "form.order",
            "inLine": true,
            "fields": {
                "name": {},
                "title": {},
                "createdAt": {},
                "updatedAt": {
                    "label": "form.updateAtDesc",
                    "order": "DESC"
                }
            }
        },
        "listview": {
            "fields": {
                "name": true,
                "title": true,
                "createdAt": true,
                "updatedAt": true,
                "organizer_id": true
            }
        },
        "listFilters": {
            "fields": {
                "organizer_id": {
                    "target": [],
                    "tableRelatedAlias": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "ajournalcodeorganizer_id"
                    },
                    "table": "organizer",
                    "fieldName": "organizer_id",
                    "label": "table.organizer",
                    "fieldSource": {
                        "type": "String",
                        "typeInput": "selectAutocomplete",
                        "readOnlyUpdate": true,
                        "disabledUpdate": true,
                        "validators": [
                            "required"
                        ],
                        "formNewSection": true,
                        "dbtype": "String",
                        "table": "organizer",
                        "label": "table.organizer"
                    },
                    "tableRelated": {
                        "table": "organizer",
                        "relation": "oneChild",
                        "fields": [
                            "name"
                        ],
                        "key": "organizer_id",
                        "alias": "ajournalcodeorganizer_id"
                    },
                    "type": "String",
                    "typeInput": "selectAutocomplete",
                    "listSource": ""
                },
                "name": {
                    "target": [
                        "maincrud",
                        "listmini"
                    ],
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "name",
                    "label": "form.code",
                    "fieldSource": {
                        "type": "String",
                        "validators": [
                            "required"
                        ],
                        "listmini": true,
                        "label": "form.code",
                        "dbtype": "String",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "String",
                    "typeInput": "text",
                    "listSource": ""
                },
                "title": {
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "title",
                    "target": [
                        "maincrud",
                        "listmini"
                    ],
                    "label": "form.title",
                    "fieldSource": {
                        "type": "String",
                        "validators": [
                            "required"
                        ],
                        "listmini": true,
                        "dbtype": "String",
                        "typeInput": "text",
                        "label": "form.title"
                    },
                    "tableRelated": "",
                    "type": "String",
                    "typeInput": "text",
                    "listSource": ""
                },
                "id": {
                    "target": "listmini",
                    "tableRelatedAlias": "",
                    "table": "",
                    "fieldName": "id",
                    "fieldSource": {
                        "type": "ID!",
                        "listmini": true,
                        "dbtype": "ID",
                        "typeInput": "text"
                    },
                    "tableRelated": "",
                    "type": "ID!",
                    "typeInput": "text",
                    "listSource": ""
                }
            }
        },
        "uniquefields": {
            "1": [
                "name"
            ],
            "2": [
                "title"
            ]
        },
        "fields": {
            "id": {
                "type": "ID!",
                "listmini": true,
                "dbtype": "ID",
                "typeInput": "text"
            },
            "name": {
                "type": "String",
                "validators": [
                    "required"
                ],
                "listmini": true,
                "label": "form.code",
                "dbtype": "String",
                "typeInput": "text"
            },
            "title": {
                "type": "String",
                "validators": [
                    "required"
                ],
                "listmini": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.title"
            },
            "counterpart_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "label": "form.counterpart_id",
                "listmini": true,
                "fieldDisplayedOptions": {
                    "subfields": {
                        "name": true,
                        "title": true
                    },
                    "separatorsubfield": " / ",
                    "joinsubfields": false
                },
                "dbtype": "String",
                "table": "aplan"
            },
            "updatedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "dbAutoUpdate": true,
                "excludedInForm": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.updatedAt"
            },
            "createdAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "dbAutoUpdate": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.createdAt"
            },
            "deletedAt": {
                "type": "String",
                "dbtype": "Datetime",
                "excludedInForm": true,
                "disabledMutation": true,
                "formatValue": "dddd D MMM ⏱h:mm a",
                "typeInput": "text",
                "formatListValue": "dddd D MMM ⏱h:mm a",
                "formatViewValue": "dddd D MMM ⏱h:mm a",
                "label": "form.deletedAt"
            },
            "deleted": {
                "type": "Boolean",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "Boolean",
                "typeInput": "checkbox",
                "label": "form.deleted"
            },
            "updated_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.updated_user_id"
            },
            "created_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.created_user_id"
            },
            "deleted_user_id": {
                "type": "String",
                "excludedInForm": true,
                "disabledMutation": true,
                "dbtype": "String",
                "typeInput": "text",
                "label": "form.deleted_user_id"
            },
            "organizer_id": {
                "type": "String",
                "typeInput": "selectAutocomplete",
                "readOnlyUpdate": true,
                "disabledUpdate": true,
                "validators": [
                    "required"
                ],
                "formNewSection": true,
                "dbtype": "String",
                "table": "organizer",
                "label": "table.organizer"
            }
        },
        "related": {
            "counterpart_id": {
                "table": "aplan",
                "label": "table.aplan",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "counterpart_id",
                "listmini": true,
                "alias": "ajournalcodecounterpart_id"
            },
            "organizer_id": {
                "table": "organizer",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "organizer_id",
                "alias": "ajournalcodeorganizer_id"
            },
            "updatedUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "updated_user_id",
                "qlDisable": true,
                "alias": "ajournalcodeupdated_user_id"
            },
            "createdUser": {
                "table": "user",
                "relation": "oneChild",
                "fields": [
                    "name"
                ],
                "key": "created_user_id",
                "qlDisable": true,
                "alias": "ajournalcodecreated_user_id"
            }
        },
        "name": {
            "singular": "ajournalcode",
            "plural": "ajournalcodes",
            "singularCase": "Ajournalcode",
            "pluralCase": "Ajournalcodes"
        },
        "mainfield": "name",
        "miniFields": [
            "id",
            "name",
            "title",
            "counterpart_id"
        ],
        "componentName": "AjournalcodeView",
        "path": "/ajournalcode-view/:id"
    }
}
  ;
  module.exports.Tables = Tables;
  
  