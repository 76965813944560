import React from "react";
import { Field, reduxForm } from "redux-form";

import { graphql, compose } from "react-apollo";

import debounce from "lodash/debounce";
import { submit, change, touch, initialize } from "redux-form";

import { bindActionCreators } from "redux";
import { withRouter } from "react-router";

import defQls from "../../defQls";
import {
  processError,
  errorTranslate,
  checkLoadCrud,
  cleanFilters,
  getFilters,
  preSubmitValidation,
  submitValues,
  deleteRecord,
  uuid,
  getRecordFromOptionsIndirectOnFilter,
  resolveLink,
} from "../../utils/helper";

import IconLoading from "../icons/IconLoading";
import {
  appSubmitStart,
  appSubmitStop,
  appSettingsLoad,
} from "../../actions/appActions";
import { showConfirm, hideConfirm } from "../../actions/confirmActions";

import Portal from "../Portal";
import { fetchQl } from "../../apolloClient";
import LabelForm from "./LabelForm";

import FormField from "../FormField";
import crudCode from "./labelcrudCode";
import { connect } from "react-redux";
import {
  processValues,
  resolvePathObj,
  getInputValue,
  sourcesOptions,
  getObjFromListById,
} from "../../utils/commonutils";
import { Tables } from "../../defTables";

import ShowIcon from "../icons/ShowIcon";
import { Button } from "../../layouts/cssstyled";

const templates = [];

const tableCrud = "label";
//const tableCrud = 'ejobmail';
const action = "add";
const nameForm = tableCrud + "Form";
const formFilterName = "listFilterejobmail";
const dialogName = "Labels";

class FormContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //always show, because component have an conditional in container // false,
      showModal: true, // value important to open and close the modal
      clicked: false, // to control click save one time
      // to redirect defaultValues from containerState  component caller. Eg: RegistrationView
      //  but id , must be a new
      defaultValues: { ...this.props.containerState.defaultValues, id: uuid() },
      // below values is copied from normal template 'add'
      actionsave: "add",
      actionsave_origen: "add",
      crudAction: "Add",
      //crudTable: tableCrud,
      id: this.props.match.params.id || "",

      dialogOpened: {},
      parentid:
        this.props.match.params && this.props.match.params.parentid
          ? this.props.match.params.parentid
          : "",
      disabledFields: [],
      hiddenFields: [],
      warningFields: {},
    };
  }
  componentDidUpdate() {
    /*
    // not needed, in state construct is activate at the start
    if (this.props.containerState.dialogOpened.MailCompose && !this.state.showModal) {
      this.setState({ showModal: true});
    }*/
  }

  send = () => {
    // twin code from crudToolBar.handleClick()
    if (this.props.myState.app.submitting) {
      //ctrl submiting global , really neded, new block ctrl can reemplace this ?
      ////console.error('submit twice');
      return;
    }
    if (!this.state.clicked) {
      // TWIN CTR231 ctrl is not saving this
      this.setState({ clicked: true }, async () => {
        this.props.appSubmitStart();
        await this.handleSubmit();
        this.props.appSubmitStop();
        this.setState({ clicked: false });
      });
    }
  };

  handleSubmit = async (values) => {
    try {
      this.setState({
        actionsave: "update",
        actionsave_origen: "update",
      });

      // important to close modal
      this.setState({
        showModal: false,
      });

      const res = crudCode.genLabels(
        {
          action: "",
          nameForm,
          formProps: { ...this.props, containerPropsForm: this.props },
          formState: this.state,
          event: null,
          newValue: null,
        },
        true
      );
      return res;
    } catch (err) {
      // don't need show error here, because inside submitvalues() logic is done
    }
  };

  toolbarFunctions = (toolbar, params = {}) => {};

  hocdebounced = debounce((methodCode, params) => {
    this.executeCode(methodCode, params);
  }, 1500);

  executeCode = async (methodCode = "", params = {}) => {
    // important to getInputValue() or anothers where actions get complicated to pass values like this
    //params.tableCrud = tableCrud ;
    params.formState = this.state; // real updated state

    if (!crudCode[methodCode]) {
      return;
    }

    const result = await crudCode[methodCode](params);

    /* two ways update states:
       1: return result.newStates with the states controlling the list of fields that has onChange or
          are importante to change state. Eg: customer/crudCode.js
       2: simple returning  result  { disabledFields: {}, hiddenFields: {} } with
         the list of disabledFields and hiddenFields always
       In both case current .state is compared with new result.newStates, and only .setState is changed
         when
     */

    if (result && result.newStates) {
      // update states
      //console.log ('result.newStates',result.newStates);
      let formStateChanged = false;
      let currentState = this.state; // manual variable in order to not use callback in .setState (here is partial set states)

      Object.keys(result.newStates).map((keyName, keyIndex) => {
        if (
          typeof this.state[keyName] === "undefined" ||
          JSON.stringify(this.state[keyName]) !==
            JSON.stringify(result.newStates[keyName])
        ) {
          // dont' needed a callback, because at the end will use twin variable currentState
          this.setState({ [keyName]: result.newStates[keyName] });
          currentState[keyName] = result.newStates[keyName];
          formStateChanged = true;
        }
      });
      if (formStateChanged) {
        // ues currentState and not state, because .newStates does not exist, but his content is transferred to state
        //console.log('new state', JSON.stringify(currentState));
        this.props.dispatch(
          change(nameForm, "_formstate", JSON.stringify(currentState))
        );
        //this.props.change('_formstate', JSON.stringify(currentState));
      }
    }
    // check not for true, but undefined, false is a valid value to return
    if (result && typeof result.valueToReturn !== "undefined") {
      // update states
      return result.valueToReturn;
    }
  };

  closeModal = () => {
    // remove dialog name from the array list of dialog opened, in containter state
    // because need control state dialog, more one dialog can be opened at same time
    // lanunched from container,  don't support more one dialog same type
    this.props.toolbarFunctionsContainer("closeDialog", { dialogName });
    // hidde modal
    this.setState({
      showModal: false,
    });
  };

  render() {
    let orgSettings = resolvePathObj(
      this.props,
      "getOrgSettings.getOrgSettings.record"
    );
    let mailing = {};
    if (orgSettings) {
      orgSettings = JSON.parse(orgSettings);
      mailing = orgSettings.mailing;
      //console.log('mailing', mailing );
    }

    const { showModal } = this.state;
    return (
      <div
        style={{ marginLeft: "30px", display: "flex", alignItems: "center" }}
      >
        <Portal
          scroll="1"
          header={this.props.t("form.labels")}
          close="1"
          backgroundColor="#EFEFEF"
          open={showModal}
          onClose={() => this.closeModal()}
          buttons={[
            { title: this.props.t("info.vExport"), action: this.send },
            { title: this.props.t("info.vCancel"), action: this.closeModal },
          ]}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <div>
              {this.props.crud_list_customer.pagecustomers.page._totalrecords}{" "}
              {this.props.t("table.customers")}
            </div>

            <div>
              {this.props.t("form.labels")} 63,5mm x 38,1mm / AGIPA Ref: 118984{" "}
            </div>

            <LabelForm
              executeCode={this.executeCode}
              crudAction="Add"
              key="mainform"
              id=""
              onSubmit={this.handleSubmit}
              containerPropsForm={this.props}
              formState={this.state}
              t={this.props.t}
            />
          </div>
        </Portal>
      </div>
    );
  }
}
//

const withGraphqlandRouter = compose(
  graphql(defQls.ejobmail.Add, {
    name: "crud_add_ejobmail",
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = "no-cache";
      optionsValues.variables = { _qlType: "Add" };
      return optionsValues;
    },
  }),
  graphql(defQls.getOrgSettings, {
    name: "getOrgSettings",
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = "network-only";
      let filters = {};

      optionsValues.variables = { ...filters };
      return optionsValues;
    },
  })
)(withRouter(FormContainer));

const mapStateToProps = (state) => {
  return {
    myState: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      appSubmitStart,
      appSubmitStop,
      showConfirm,
      initialize,
      change,
      touch,
      dispatch,
    },
    dispatch
  ); // to set this.props.dispatch
};

const withState = connect(
  mapStateToProps,
  mapDispatchToProps
)(withGraphqlandRouter);

const ComponentFull = withState;

export default ComponentFull;
