/*
WARNING:
  formProps is artificial when is launched from containerForm, in the case initForm or manual change
    from container.
    this send his props himself and not containerForm
  so don't use formProps to use special props, form myState and tt is ok, container and Form have these same props
  getInputValue use myState, myState is present in container so is ok to use it,
    but send always the name of form { nameForm }, because if formProps is artificil will fail
  use tt , for translate, have dependency with props
 */

import React from "react";
import ShowIcon from "../icons/ShowIcon";
import {
  getInputValue,
  processValues,
  tt,
  templateEngine,
  setTemplateData,
  getOptions,
  getOptionFromSource,
  getObjFromListById,
  processPayments,
  getSMS,
  resolvePathObj,
} from "../../utils/commonutils";
import { getRecordFromOptionsIndirectOnFilter } from "../../utils/helper";
import { submit, change } from "redux-form";
import strQls from "../../defStrQls";
import { fetchQl, fetchDirect } from "../../apolloClient";
const crudCode = {};

crudCode.onChangeInput = async (params) => {
  //console.log('onChangeInput params:' , params);
  const {
    nameForm,
    popup,
    tableCrud,
    inputFullName,
    inputName,
    line,
    action,
    parentField,
    event,
    newValue,
    previousValue,
    props,
    formProps,
    formState,
  } = params;

  /*
   popup denote that value is sent through window, and is here not because there is change, but
   must execute change manually and follow the same logic that onChange
   */
  const result = {};

  let newStates = {
    disabledFields: {},
    hiddenFields: {},
    warningFields: {},
    errors: {},
  };

  if (action === "initForm" && formProps.containerPropsForm) {
    crudCode.genLabels(params);
  }
  result.newStates = newStates;
  return result;
};

crudCode.genLabels = async (params, process = false) => {
  const { formProps, formState } = params;

  let vars = {};
  if (
    formProps.containerPropsForm.containerState.mainForm ===
    "listFiltercustomer"
  ) {
    for (const [key, value] of Object.entries(
      formProps.containerPropsForm.crud_list_customer.variables
    )) {
      if (value) vars[key] = value;
    }
  }

  if (Object.keys(vars).length > 0) {
    if (process) vars._process = "labels";
    let resCustomers = await fetchQl(strQls.customer.ListPage, vars, {
      noProcessParams: true,
      dataName: "pagecustomers",
      props: formProps, // important object that contains myState , for pelemaster resolver org
    });
  }
};

export default crudCode;
