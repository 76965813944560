import React from "react";
import { Field, reduxForm, change } from "redux-form";
import { bindActionCreators } from "redux";
import Portal from "../Portal";
import { fetchQl } from "../../apolloClient";
import FormField from "../FormField";
import crudCode from "./crudCode";
import { processNewValues, uuid } from "../../utils/helper";
import { connect } from "react-redux";
import {
  processValues,
  resolvePathObj,
  getInputValue,
  sourcesOptions,
  getObjFromListById,
  realTypeOf,
} from "../../utils/commonutils";
import { Tables } from "../../defTables";
import { appSubmitStart, appSubmitStop } from "../../actions/appActions";
import { showConfirm } from "../../actions/confirmActions";

import ShowIcon from "../icons/ShowIcon";
import { Button } from "../../layouts/cssstyled";

const nameForm = "MemberAddress";
class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      groupAddresses: null,
      groupCustomers: [],
      joinAddresses: [], // group and current customer included
      joinCustomers: [], // // group and current customer included
      currentAddresses: [],
      currentCustomer: { id: "", name: "" },
      customercustomeraddress_id: null,
      customeraddress_id: null,
    };
  }

  componentDidMount() {
    const currentCustomer = {
      // name customer always can change  so need reload always
      id: getInputValue(this.props, "id", { nameForm: "customerForm" }),
      name:
        getInputValue(this.props, "lastname", { nameForm: "customerForm" }) +
        ", " +
        getInputValue(this.props, "firstname", { nameForm: "customerForm" }),
    };
    this.setState({ currentCustomer }); // just load one time currentCustomer the first time dialog is created
  }

  showModalGroupping = async (evt) => {
    /*
    the same component is used for two things:
      A) Display on Form Customers, link to group, or linkoff to ungroup with the list of members names
      B) Display on Dialog Form Grouping

      Customer Name and Addresses can change all the time from form Customer, so:
        - for display of customers (A) names group, must always retrieve current name for form in render
        - for display B), use states values loaden in .showModalGroupping and .loadRelatedCustomer for
          all the operations

    ORDER LOGIC:
      1. Modal Show, load in state: (.showModalGroupping)
         - currentAddresses (accord form customer.addresses)
      2. If there is customer associated then go automatic to 4 (.showModalGroupping)
      3. selectbox customer association
      4. load in states: (this.loadRelatedCustomer)
         - groupAddresses
         - customercustomeraddress_id
         - customeraddress_id
         - joinAddresses
         * nul or [] if there is no customer selected, or
           values if there a customer and go 5.
      5. join addresses and customersin : .preJoin()
        - joinAddresses
        - joinCustomers
      6. Confirm association: .joinToGroup()
          set in 'customerForm':
            addresses:  this.state.joinAddresses
            customeraddress_customers: JSON.stringify(customeraddress_customers
     */

    /*
    2. automatic selection if customer associated is selected
     */

    let currentAddresses =
      getInputValue(this.props, "addresses", { nameForm: "customerForm" }) ||
      [];
    if (!currentAddresses) currentAddresses = [];
    // in 'add' action, id does not exist
    const currentCustomer = {
      id: getInputValue(this.props, "id", { nameForm: "customerForm" }),
      name:
        getInputValue(this.props, "lastname", { nameForm: "customerForm" }) +
        ", " +
        getInputValue(this.props, "firstname", { nameForm: "customerForm" }),
    };

    this.setState(
      {
        showModal: true,
        currentCustomer,
        currentAddresses,
      },
      () => {
        // cherche customer associated:
        const customerRelated = getInputValue(
          this.props,
          "grouped_customer_id",
          { nameForm: "customerForm" }
        );
        if (customerRelated && customerRelated.id) {
          this.props.dispatch(
            change(nameForm, "customer_id", {
              id: customerRelated.id,
              name: customerRelated.name,
            })
          ); // just copy {id, name} because input have another fields (mini)
          this.loadRelatedCustomer({ newValue: customerRelated.id }); // need to simulate on change
        }
      }
    );
  };

  preJoin = () => {
    let joinCustomers = [];
    // assign always currentAddress
    let joinAddresses = [...this.state.currentAddresses];

    // add parter or group addresses is not duplicate with currentAddress
    //console.log('this.state.groupAddresses', this.state.groupAddresses);
    this.state.groupAddresses.map((address) => {
      //console.log('address', address);
      const { id, ...addressDepured } = address;
      let addressDuplicate = false;
      this.state.currentAddresses.map((address2) => {
        const { id, ...addressCurrentDepured } = address2;
        //console.log('addressDepured / addressCurrentDepured',addressDepured, addressCurrentDepured);
        if (
          JSON.stringify(addressCurrentDepured) ===
          JSON.stringify(addressDepured)
        ) {
          //console.log('duplicated');
          addressDuplicate = true;
        }
      });
      if (!addressDuplicate) joinAddresses.push(address);
    });
    joinCustomers.push(this.state.currentCustomer);
    this.state.groupCustomers.map((customer) => {
      if (customer.id !== this.state.currentCustomer.id) {
        joinCustomers.push(customer);
      }
    });

    this.setState({ joinAddresses, joinCustomers });
  };

  delAssociation = (evt) => {
    /*if (!window.confirm(`Vous êtes sur de quitter la persone de l'adresse partagé?`)) {
      return false;
    }*/
    this.props.showConfirm({
      id: uuid(),
      type: "confirmation",
      text: this.props.t("form.uSureQuitGrouping"),
      buttons: [
        {
          label: this.props.t("form.yes"),
          onClick: () => {
            this.props.dispatch(
              change("customerForm", "customeraddress_customers", null)
            );
            this.props.dispatch(
              change("customerForm", "customeraddress_id", null)
            );
          },
        },
        {
          label: this.props.t("form.no"),
          onClick: () => null,
        },
      ],
    });
  };

  loadRelatedCustomer = async (params = {}) => {
    // reset State always like if have no related customer
    this.setState({
      groupAddresses: [],
      customercustomeraddress_id: null,
      customeraddress_id: null,
      customerPartner: null,
      joinAddresses: [],
      joinCustomers: [],
    }); // no address

    if (!params.newValue) {
      return;
    }
    const variables = [{ name: "id", type: "ID!", value: params.newValue }];
    const ql = `query CustomerView($id: ID!) {
      customer(id: $id) {
        id
        customeraddress_id
        name
        lastname
        firstname
        inactive
        info
        contacts
        phones
        addresses
        identitydocs
        customercustomeraddress_id  {
          customers
          addresses
        }
      }    
    }
    `;
    // send this.props , important for master resolves organizer id
    const valuesFetch = await fetchQl(ql, variables, {
      singular: true,
      props: this.props,
    });

    // has found, normally must found becase onChange autocomplete is after customer is selected

    if (resolvePathObj(valuesFetch, "data.customer.id")) {
      let recCustomer = valuesFetch.data.customer;
      let newState = {};
      newState.groupAddresses = JSON.parse(recCustomer.addresses);
      newState.customercustomeraddress_id =
        recCustomer.customercustomeraddress_id;
      newState.customeraddress_id = recCustomer.customeraddress_id;
      if (recCustomer.customercustomeraddress_id) {
        // related customer is in a group // load members
        newState.groupCustomers = JSON.parse(
          recCustomer.customercustomeraddress_id.customers
        );
      } else {
        // related customer has no group, add himself like unique member
        newState.groupCustomers = [
          { id: recCustomer.id, name: recCustomer.name },
        ];
      }
      newState.customerPartner = { id: recCustomer.id, name: recCustomer.name };
      //console.log('setState for Prejoin');
      this.setState({ ...newState }, () => {
        this.preJoin();
      });
    }
  };

  executeCode = async (methodCode = "", params = {}) => {
    await this.loadRelatedCustomer(params);
  };
  joinToGroup = () => {
    if (!this.state.currentCustomer.name) {
      alert("Vous devez chosir un nom avec qui se grouper");
      return false;
    }

    this.props.dispatch(
      change("customerForm", "addresses", this.state.joinAddresses)
    );
    this.props.dispatch(
      change(
        "customerForm",
        "customeraddress_id",
        this.state.customeraddress_id
      )
    );

    // save customer related / redux form need stringify
    this.props.dispatch(
      change(
        "customerForm",
        "customeraddress_customers",
        JSON.stringify(this.state.joinCustomers)
      )
    );
    this.setState({
      showModal: false,
    });
  };

  printCustomers = (customersPure, color = "red") => {
    let customers = [];
    if (!customersPure) {
      // is null
      customers = null;
    } else if (customersPure.length === 0) {
      customers = (
        <div key="noA" style={{ display: "flex", padding: "10px" }}>
          Sans Group
        </div>
      );
    } else {
      customersPure.map((customer, key) => {
        let customerJoined = customer.name;
        customers.push(
          <div
            key={key}
            style={{
              fontWeight:
                customer.id === this.state.currentCustomer.id ? "bold" : "none",
            }}
          >
            {customerJoined}
          </div>
        );
      });
    }
    return customers;
  };

  printAddress = (addressesPure, color = "#999999") => {
    let addresses = [];

    if (!addressesPure) {
      // is null
      addresses = null;
    } else if (addressesPure.length === 0) {
      addresses = (
        <div key="noA" style={{ display: "flex", padding: "10px" }}>
          Sans Adresses
        </div>
      );
    } else {
      addressesPure.map((address, key) => {
        let addressJoined = [];
        if (address.addressline1) addressJoined.push(address.addressline1);
        if (address.addressline2)
          addressJoined.push(" " + address.addressline2);
        if (address.addressline3)
          addressJoined.push(" " + address.addressline3);
        if (address.cp) {
          // twin server CP1203 fix cp value for wrong saved cp
          addressJoined.push(", " + (address.cp.name || address.cp));
          if (
            address.cp.name &&
            realTypeOf(address.cp.name) === "[object String]"
          ) {
            addressJoined.push(", " + address.cp.name);
          } else if (
            address.cp &&
            realTypeOf(address.cp) === "[object String]"
          ) {
            addressJoined.push(", " + address.cp);
          }
        }
        if (address.city_id && address.city_id.name) {
          addressJoined.push(", " + address.city_id.name);
        }
        if (address.country) {
          const countryDet = getObjFromListById(
            sourcesOptions.countries,
            address.country.id || address.country
          );
          if (countryDet) addressJoined.push(", " + countryDet.name); // can be object {id, name}
        }

        addresses.push(
          <div
            key={key}
            style={{
              display: "flex",
              padding: "10px",
              borderBottom: "dashed 1px " + color,
            }}
          >
            <div key="item" style={{ color: "#AAAAAA", paddingRight: "5px" }}>
              {key + 1})
            </div>
            <div key="cnt">{addressJoined}</div>
          </div>
        );
      });
    }
    return addresses;
  };

  /* old buttons:
            <div style= {{ display: 'flex', justifyContent: 'center'}} >
              <Button margin="1" onClick={() => this.joinToGroup()}> {this.props.t('info.vGroup')} </Button>
              <Button margin="1" onClick={() => this.setState({ showModal: false })}> {this.props.t('info.vCancel')} </Button>
            </div>
  */

  closeModal = () => {
    // remove dialog name from the array list of dialog opened, in containter state
    // because need control state dialog, more one dialog can be opened at same time
    // lanunched from container,  don't support more one dialog same type
    // console.log('this.props', this.props);
    //this.props.toolbarFunctionsContainer('closeDialog', {dialogName: 'MailCompose'});
    // in case this component that is called always inside the form and inside a <Field>
    // simply use formProps
    // another case like MailCompose is called outside Form, in view or List like
    // a independent component
    this.props.formProps.toolbarFunctions("closeDialog", {
      dialogName: "MailCompose",
    });
    // hidde modal
    this.setState({
      showModal: false,
    });
  };

  render() {
    const { showModal } = this.state;

    /* block for DISPLAY IN form CUSTOMER, NOT for Group Dialog Form
      display: Customers List

     customeraddress_customers, means is associated to group, it was loaded with the  record or was
     setted when pick the group
    */
    let customersRelated;
    const currentCustomer = {
      // name customer always can change  so need reload always
      id: getInputValue(this.props, "id", { nameForm: "customerForm" }),
      name:
        getInputValue(this.props, "lastname", { nameForm: "customerForm" }) +
        ", " +
        getInputValue(this.props, "firstname", { nameForm: "customerForm" }),
    };
    customersRelated = getInputValue(this.props, "customeraddress_customers", {
      nameForm: "customerForm",
    });
    let customerList = "";
    if (customersRelated) {
      customerList += currentCustomer.name; // add always current addresses
      if (customersRelated) {
        // to avoid duplicate, insert current customer and from the list only different customers
        customersRelated = JSON.parse(customersRelated);
        customersRelated.map((customer) => {
          // /TWIN ServermCT131 customer.id , by error is saving {name} and not {id, name}; ignore those wrong rows
          if (customer.id && customer.id !== currentCustomer.id)
            customerList += (customerList ? " / " : "") + customer.name;
        });
      }
    }

    /* end block display form*/

    /*
    Field need:
      - tableCrud, fake , any table that contains that field
      - pathInTables, fake, any path to table field
      - nameForm, the name in Form (important to update select value(and display), after selection
      - mustOnChange , need for autocomplete input to launch .executeCode
     */
    let groupAddresses = this.printAddress(this.state.groupAddresses);
    let groupCustomers = this.printCustomers(this.state.groupCustomers);
    let currentCustomerAddresses = this.printAddress(
      this.state.currentAddresses
    );
    let joinedAddresses = this.printAddress(this.state.joinAddresses, "red");
    let joinedCustomers = this.printCustomers(this.state.joinCustomers, "red");

    return (
      <div
        style={{ marginLeft: "30px", display: "flex", alignItems: "center" }}
      >
        {!customerList && (
          <div>
            <a onClick={(evt) => this.showModalGroupping(evt)}>
              {this.props.t("info.vGroup")}
              <ShowIcon size="25" color="#4984ab" icon="link" />
            </a>
          </div>
        )}
        {customerList && (
          <React.Fragment>
            <div>
              <a onClick={(evt) => this.delAssociation(evt)}>
                {this.props.t("info.vQuitGrouping")}{" "}
                <ShowIcon size="25" color="#4984ab" icon="linkoff" />
              </a>
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <div style={{ color: "red", marginRight: "22px" }}>
                {" "}
                {customerList}
              </div>
              <div></div>
            </div>
          </React.Fragment>
        )}
        <Portal
          header={this.props.t("form.grouping")}
          scroll="1"
          close="1"
          open={showModal}
          onClose={() =>
            this.setState({
              showModal: false,
            })
          }
          buttons={[
            { title: this.props.t("info.vGroup"), action: this.joinToGroup },
            { title: this.props.t("info.vCancel"), action: this.closeModal },
          ]}
        >
          <div>
            <div style={{ marginBottom: "15px" }}>
              <div style={{ color: "#777777", fontSize: "18px" }}>
                {this.state.currentCustomer.name}
              </div>
              {currentCustomerAddresses}
            </div>
            <div style={{ marginBottom: "15px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <div style={{ paddingRight: "10px", color: "#777777" }}>
                  {this.props.t("form.getGrouping")}
                </div>
                <div style={{ maxWidth: "300px" }}>
                  <Field
                    inputName="customer_id"
                    name="customer_id"
                    noMainContainer="1"
                    width="200px"
                    noLabel="1"
                    noErrors="1"
                    formProps={this.props}
                    pathInTables="registration.fields.customer_id"
                    formState={this.state}
                    nameForm={nameForm}
                    tableCrud="registration"
                    component={FormField}
                    executeCode={this.executeCode}
                    type="selectAutocomplete"
                    typeInput="selectAutocomplete"
                    disabled={this.props.id ? true : false}
                    mustOnChange={true}
                  />
                </div>
                {/*<div>
                  //show the actual members customers, if without save, unlink group and link to same group
                  //here show already the current customer on the group, really don't needed better to show
                  /the group joined
                  {groupCustomers}
                </div>*/}
              </div>
            </div>
            <div>{groupAddresses}</div>
            {this.state.customerPartner && (
              <div>
                <div>
                  <div
                    style={{
                      marginTop: "30px",
                      marginBottom: "20px",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    {this.props.t("form.resultGroup")}:
                  </div>
                  {joinedCustomers}
                </div>
                <div>{joinedAddresses}</div>
              </div>
            )}
          </div>
        </Portal>
      </div>
    );
  }
}
//
const ComponentWithData = reduxForm({
  form: nameForm, // a unique identifier for this form
  // TWIN FR141 very important , the values that are loading and changing  after form is created
  // so need reload the last variables in form
  //enableReinitialize: true,
  enableReinitialize: false,
  //validate,
})(Form);

function mapStateToProps(state, ownProps) {
  const initialValues = {};
  const statesReturn = { myState: state, initialValues };
  return statesReturn;
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { appSubmitStart, appSubmitStop, showConfirm, dispatch },
    dispatch
  );
};

const ComponentWithDataAndState = connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentWithData);

export default ComponentWithDataAndState;
