import uuidv4 from 'uuid/v4';
import React from 'react';
import { Link } from 'react-router-dom';
import { commonValidator } from './validators';
import { Tables } from './../defTables';
import { gqlActions } from '../gqls/gqlactions';
import { signOut } from '../actions/authActions';

import {
  isString,
  isObject,
  toPrice,
  dateToISO,
  getObjFromListById,
  getOptions,
  getInputValue,
  realTypeOf,
  processValues,
  getDefaultValue,
  tt,
  startCase,
  isNil,
  dateStandard,
} from '../utils/commonutils';
import { ISOtoDate, resolvePathObj } from './commonutils';
import { getSubFieldsToPrint } from '../utils/listCommon';

export const uuid = () => uuidv4();

export const getFilters = (tableCrud, props, formPagerName, formFilterName) => {
  let log = false;
  if (log) console.log('formFilterName', formFilterName);
  if (log) console.log('querydata', props.myState);
  let table = Tables[tableCrud];
  let filters = {};
  if (
    props.myState &&
    props.myState.form[formPagerName] &&
    props.myState.form[formPagerName].values
  ) {
    filters._page = props.myState.form[formPagerName].values._page;
  }
  if (table.parentTable) {
    filters[table.parentRelationField] = props.match.params.parentid;
  }
  // need add _orders on fly
  let vars = Object.assign(
    {},
    table.listFilters ? table.listFilters.fields : {}
  );
  // need add field filters orders if have it // maincrud is important when filter is for the main table
  if (table.orders)
    vars._orders = Object.assign(table.orders, { target: 'maincrud' });
  if (
    table.listFilters &&
    props.myState &&
    props.myState.form &&
    props.myState.form[formFilterName] &&
    props.myState.form[formFilterName].values
  ) {
    Object.keys(vars).map((fieldKey, keyIndex) => {
      let filter = vars[fieldKey]; // %% read fields like preload
      if (log) console.log('filter key:' + fieldKey, filter);

      let field = table.fields[fieldKey];
      let value = props.myState.form[formFilterName].values[fieldKey];
      let filterValue = value;
      // its object with id, and name properties
      if (filterValue && filterValue.id) filterValue = filterValue.id;
      let addFilter = true;
      // warning field is 'undefined' for fieldKey alias that contains subfield. Eg. addresses_city_id
      if (typeof value === 'undefined') {
        if (filter.defaultValue) filterValue = filter.defaultValue;
      }
      if (field && field.type === 'Boolean') {
        // twin DEFINI318, need double code to work, 1) filter for query, 2) initvalue for form CrudFilterOrderBar
        if (typeof filterValue === 'string') {
          if (filterValue === 'null') {
            //addFilter = false; this line produce frozen in registrations list when 'tous' is clicked for 'inactive' field, i think is is because radio, test with another inputs
            filterValue = undefined;
          } else if (filterValue === 'true') {
            filterValue = true;
          } else {
            filterValue = false;
          }
        }
      }

      if (
        filter.type === 'Int' || // case  _depositedcheck , that has no field source
        (field && (field.dbtype === 'Int' || field.dbtype === 'Float'))
      ) {
        // TWIN FI103

        if (typeof filterValue === 'undefined' || filterValue === '') {
          // important if is empty string consider null, to skip error graphl
          // numeric type cant have empy string, but correct is null (different to zero)
          filterValue = null;
        } else {
          // sometimes the value comes from store, already type number,
          // in this case not convert
          if (typeof filterValue === 'string') {
            // remove blank spaces
            // convert to float
            filterValue = parseFloat(filterValue.replace(/\s+/g, ''));
          }
        }
      }

      if (field && field.type === 'Date') {
        // twin DEFINI318, need double code to work, 1) filter for query, 2) initvalue for form CrudFilterOrderBar
        if (typeof filterValue === 'string') {
          if (filterValue === 'null') {
            //addFilter = false; this line produce frozen in registrations list when 'tous' is clicked for 'inactive' field, i think is is because radio,  with another inputs
            filterValue = undefined;
          } else {
            const xfilterValue = dateStandard(filterValue);
            if (xfilterValue !== 'Invalid Date') {
              filterValue = xfilterValue;
            }
          }
        }
      }
      if (filter.minLength && filterValue) {
        // just for type string, not booleans
        filterValue = filterValue.trim();
        if (filter.upperCase) filterValue = filterValue.toUpperCase();
        if (filterValue.length > 0 && filterValue.length < filter.minLength)
          addFilter = false;
      }
      if (addFilter) {
        filters[fieldKey] = filterValue;
        if (filter.multiField) {
          filters[fieldKey + 'Field'] =
            props.myState.form[formFilterName].values[fieldKey + 'Field'];
        }
        if (filter.typeShowInput === 'Date') {
          const xfilter1 = dateStandard(
            props.myState.form[formFilterName].values[fieldKey + '1']
          );
          const xfilter2 = dateStandard(
            props.myState.form[formFilterName].values[fieldKey + '2']
          );
          if (xfilter1 !== 'Invalid Date' && xfilter2 !== 'Invalid Date') {
            filters[fieldKey + '1'] = xfilter1;
            filters[fieldKey + '2'] = xfilter2;
          }
        }
      }
      if (log) console.log('filter value for key:' + fieldKey, filterValue);
    });
  }

  // Important this block after proces .listFilters, parent value is not brought from filter form
  // in the case by error is included , this part will over write the parent value accord url
  if (table.parentTable) {
    //console.log('parentrelationfield:' + table.parentRelationField,props.match.params, props.match.params.parentid);
    filters[table.parentRelationField] = props.match.params.parentid;
    //console.log('filters zero',filters);
  }
  if (log) console.log('final filters', filters);
  return filters;
};

/*
translate an error, this functions exclusive for app client, is similir to commonutils.js::tt()
can be merged on the future
 */
export const errorTranslate = (msg, t) => {
  /*
  important control undefined, EG; <SettingsLoad> wrapper component try loadsettings with
  no server connection, then there is not yet translate , no 't' possible passed by props
  but how render is execute a lot of times, then can t be not undefined, and never display
  the error 'Error Load APP 12'
   */
  // console.log('error translate', msg);
  if (isString(msg) && msg.substr(0, 2) === '?!') return msg.substr(3);

  /*
  SOMETIMES t comes like nukll, so don't translate !! ; just return same text, and no alerts
  TypeError: t is not a function
    errorTranslate
    src/utils/helper.js:165
      162 |   for (let p = 0; p + 1 < msgParts.length; p++) {
      163 |     params['p'+ (p+1)] = msgParts[p + 1];
      164 |   }
    > 165 |   let msgTranslated = t(mainPart ,params);
  */

  if (typeof t === 'undefined' || !t) {
    console.error('Error Load APP 12');
    return msg;
  }

  if (!isString(msg)) return t('mobilsemError.translate'); // translate only string,  if not unknow error

  const msgParts = msg.split('::');

  let mainPart = msgParts[0];
  let letSubParts;
  const params = {};

  if (mainPart.includes('.notUnique')) {
    /*
    special case, parameters are not really parameters but reference to link table/record,
    after translate mainPart, add link, see last block
     */
    letSubParts = msgParts[1].split(':');
    mainPart = mainPart.replace(`{{p1}}`, '');

    let msgTranslated = t(mainPart, params).split('<br/>');
    const aMessage = [];
    msgTranslated.map((line, index) => {
      aMessage.push(<div key={`m${index}`}>{line}</div>);
    });

    if (msgParts[0].includes('.notUnique')) {
      return (
        <React.Fragment>
          {aMessage}
          <div>
            <a
              className="aNotHover"
              rel="noopener"
              target="_blank"
              href={
                '/' + letSubParts[0] + '-view/' + letSubParts[1] + '?popup=1'
              }
            >
              {' '}
              {t('form.watchDupl')} 👀
            </a>
          </div>
        </React.Fragment>
      );
    }
  } else {
    // block TWIN TPAR211
    for (let p = 0; p + 1 < msgParts.length; p++) {
      params['p' + (p + 1)] = msgParts[p + 1];
    }
    let msgTranslated = t(mainPart, params);
    return msgTranslated;
  }

  /*
  discontinued, now follow logic real translater i18next
  if (typeof msgTranslated === 'undefined') {
    // Error is not formated = mobilsemError.[typerror][::detailError], asigne Error without Format
     msgTranslated  = 'mobilsemError.withoutFormat::' + msg ;
  }
  */
};

export const cleanFilters = (tableCrud, dispatch, change) => {
  let log = false;
  let filters = {};
  let table = Tables[tableCrud];
  filters._page = 1;
  // need add _orders on fly
  let vars = Object.assign({}, table.listFilters.fields);
  // need add field filters orders if have it // maincrud is important when filter is for the main table
  if (table.orders)
    vars._orders = Object.assign(table.orders, { target: 'maincrud' });
  if (table.listFilters) {
    Object.keys(vars).map((fieldKey, keyIndex) => {
      let addFilter = true;
      let filter = vars[fieldKey]; // %% read fields like preload
      if (log) console.log('filter key:' + fieldKey, filter);

      let field = table.fields[fieldKey];
      let filterValue = null;
      if (filter.defaultValue) filterValue = filter.defaultValue;
      if (field && field.type === 'Boolean') {
        // twin DEFINI318, need double code to work, 1) filter for query, 2) initvalue for form CrudFilterOrderBar
        // to Form: in this case filter Value must be string, is the inverse from list variables filters from form
        if (typeof filterValue === 'string') {
          if (filterValue === 'null') {
            //addFilter = false; this line produce frozen in registrations list when 'tous' is clicked for 'inactive' field, i think is is because radio, test with another inputs
            filterValue = undefined;
          } else if (filterValue === 'true') {
            filterValue = 'true';
          } else {
            filterValue = 'false';
          }
        }
      }
      if (filter.minLength && filterValue) {
        // just for type string, not booleans
        filterValue = filterValue.trim();
        if (filter.upperCase) filterValue = filterValue.toUpperCase();
        if (filterValue.length > 0 && filterValue.length < filter.minLength)
          addFilter = false;
      }
      if (addFilter) {
        filters[fieldKey] = filterValue;
        //console.log('final value for:'+fieldKey, filterValue);
        dispatch(change('listFilter' + tableCrud, fieldKey, filterValue));
        if (filter.multiField) {
          dispatch(change('listFilter' + tableCrud, fieldKey + 'Field', null));
        }
        if (filter.typeShowInput && filter.typeShowInput === 'Date') {
          //dispatch(change('listFilter' + tableCrud, fieldKey+'1', null));
          //dispatch(change('listFilter' + tableCrud, fieldKey+'2', null));
        }
      }
      if (log) console.log('filter value for key:' + fieldKey, filterValue);
    });
  }
};

export const resolveLink = (
  xtable,
  data,
  type = 'list',
  nameTableToolbar = ''
) => {
  let log = false;
  let linkPathBase = '/';
  let idParent = '';
  let id = '';
  let table = typeof xtable === 'string' ? Tables[xtable] : xtable;
  if (nameTableToolbar) {
    id = data[nameTableToolbar + '_id'];
  } else if (data && data.id) {
    id = data.id; // sometimes data is undefined
  }
  let idlog = 'resolveLink: table:' + table.name.singular + ' type: ' + type;
  if (log) console.log(idlog, ', data: ', data);

  if (table.parentTable && !nameTableToolbar) {
    // twin PAR029
    if (log) console.log('... have parentTable', table.parentTable);
    if (data && data.parentid) {
      // E.g. come froms crudToolBar, (props.parentid)
      idParent = data.parentid;
      if (log) console.log('... have id parent in url', idParent);
    } else if (data && data[table.parentRelationField]) {
      // E.g. come from record db, produced by graphql after to save
      idParent = data[table.parentRelationField];
      if (log) console.log('... have id parent in data', idParent);
    } else {
      console.trace();
      alert('Error parentTable id not found, ' + idlog + ', data:', data);
      throw 'Error parentTable id not found';
    }
    linkPathBase += table.parentTable + '-child/' + idParent + '/';
    if (log) console.log('... linkPathBase: ' + linkPathBase);
  }

  /* links are not standar like rest, add ,update is indepente from list . E.g.
      /tour
      /tour-view/:id
      /tour-update/:id
  */

  if (type === 'list') {
    return linkPathBase + table.name.singular;
  }

  if (type === 'add') {
    return linkPathBase + table.name.singular + '-add';
  }

  // block just with id mandatory
  if (!id)
    throw (
      'resolveLink: id not defined type:' +
      type +
      ', for table:' +
      table.name.singular +
      ' data:' +
      JSON.stringify(data)
    );

  //if (type === 'view') {
  return linkPathBase + table.name.singular + '-' + type + '/' + id;
  //} else {
  // if (!id) throw ( 'resolveLink: type: '+type +' not defined for table:' + table.name.singular  );
  //}
};

export const processError = (
  xerr,
  props = {},
  toast = true,
  tableName = '',
  translate = true
) => {
  /*
   error in token example response server;
   {"data":{"customers":null},"errors":[{"message":"[object Object]","locations":[{"line":2,"column":3}],"path":["customers"],"state":[{"key":"token.empty","value":"The user token is empty."}]}]}

   */
  const log = false;
  const eError = localStorage.getItem('Error'); // when there is nothing give null
  let err;
  if (eError !== null) {
    err = JSON.parse(eError);
    if (log) console.log('processError: error comes from storage');
  } else {
    err = xerr; // error comes not from apollo client, so error is sent like parameter, it's not in localStorage
  }

  if (log) console.error('error Process Error', typeof err, err);
  //console.log('props in processError', props);
  let errorMessage = '';
  let typeError = 1;
  let avoidError = false; // when it's need stop error, or hide it, it's not really an error

  if (isObject(err)) {
    if (err.graphQLErrors) {
      /* Error in server, always is showed like graphQLErrors because apollo server  */
      try {
        errorMessage = err.graphQLErrors[0].message;
        if (log)
          console.log(
            'processError: found msg error in err.graphQLErrors[0].message:' +
              errorMessage
          );
      } catch (e) {
        /* ignore the error */
      }
      /* Don't show description  error (value) for known customized erros
       (login, and add all future known errors, that key or code will be translated by the dictionary
       */

      let errorCustomized = false;
      if (errorMessage && typeof errorMessage === 'string') {
        //if ( err.graphQLErrors[0].state[0].key.includes('login.')) errorCustomized = true;

        // error created by programmation. Eg. in login, pass wrong, msg muss turn off in 6 seconds
        // normal error must not turn off, %% just if change page %%
        // warning new: error with key, can be produced by postgresql error sql, so show the detail
        //errorMessage = err.graphQLErrors[0].state[0].key;

        if (errorMessage.includes('login.')) {
          errorMessage = err.graphQLErrors[0].message;
          errorCustomized = true;
          if (log)
            console.log(
              'processError: customize error login. :' + errorMessage
            );
          typeError = 2; // make will be a pause and will dissapear, default typeError = 1 (not dissapear until click)
        }
        if (errorMessage.includes('mobilsemError.')) {
          // prority customize Errors
          if (log)
            console.log(
              'zzz processError: customize error mbobilsemError. :' +
                errorMessage
            );
          errorCustomized = true; // don't change the message
        }
        /*if ( err.graphQLErrors[0].state[0].value  && errorCustomized===false ) {
          errorMessage = err.graphQLErrors[0].state[0].value;
        }*/
      }
      if (log) console.log('processError() first error:', errorMessage);
      if (errorMessage === '') {
        try {
          errorMessage = err.graphQLErrors[0].state.errors[0].message; // case id unique, error very detailed
        } catch (e) {
          /* ignore the error */
        }
      }
      if (errorMessage === '') {
        try {
          errorMessage = err.graphQLErrors[0].message; // case id unique, error very detailed
        } catch (e) {
          /* ignore the error */
        }
      }

      if (errorMessage === '') {
        try {
          errorMessage = err.graphQLErrors[0].state.name; //  case id unique
        } catch (e) {
          /* ignore the error */
        }
      }

      if (errorMessage) {
        /*
        incorporate this type error for show  for a field, look for with regex > column "customer_id" < %%
        SequelizeDatabaseError: null value in column "customer_id" violates not-null constraint
         */

        if (log)
          console.log(
            'processError: check to customize error wth match text...'
          );
        let changeErrorType = '';
        let changeErrorMessage = '';
        let errorField = errorMessage.match(
          /variable\s+\"(.*)".*value\s+\"(.*)\"/i
        );
        // revisar: no esta funcionando para: Variable "$tour_id" of required type "String!" was not provide

        //console.error('errorField',errorField);
        if (log) console.error('match error for "variable": ', errorField);
        if (errorField) {
          let field;
          let fieldName = errorField[1].replace('$', '');
          let fieldValue = errorField[2];
          if (tableName && Tables[tableName]) {
            // tableName was sent in parameters, so it's possible to indentify the error
            let field = Tables[tableName].fields[fieldName];
            if (field) {
              //console.log('field:', fieldName);
              changeErrorType = 'mobilsemError.fieldError::';
              changeErrorMessage = `${props.t(field.label)}`;
            }
          } else {
            changeErrorType = 'mobilsemError.fieldError::';
            // guest the name, using translator for form section
            // sometimes errors can send props that is not from form, so .t does not exist, check:
            changeErrorMessage = `${
              props.t ? props.t('form.' + fieldName) : fieldName
            } = ${fieldValue} `;
          }
          //console.log( "variable ", errorField[1] );
          //console.log( "value ", errorField[2] );
        }

        errorField = errorMessage.match(/column\s+\"(.*)".*/i);
        if (log) console.error('match error for "column": ', errorField);
        if (errorField && tableName && Tables[tableName]) {
          let fieldName = errorField[1];
          let fieldOtherText = errorMessage
            .replace(fieldName, '')
            .replace('mobilsemError.server::', '');
          let field = Tables[tableName].fields[fieldName];
          if (field) {
            //console.log('field:', fieldName);
            //fieldName  = fieldName.replace('$','');
            changeErrorType = 'mobilsemError.fieldError::';
            changeErrorMessage = `${props.t(field.label)}: ${fieldOtherText} `;
          }
          //console.log( "variable ", errorField[1] );
          //console.log( "value ", errorField[2] );
        }

        if (changeErrorMessage) {
          errorCustomized = true;
          // look at if original message already had mobilsemError
          if (errorMessage.includes('mobilsemError.')) {
            errorMessage = changeErrorMessage.replace(
              'mobilsemError.',
              changeErrorType
            );
            console.log(
              'processError: message had mobilsemError, changed to:' +
                errorMessage
            );
          } else {
            errorMessage = changeErrorType + changeErrorMessage;
            console.log('processError: message add type:' + errorMessage);
          }
        }
      }

      if (errorMessage !== '' && !errorCustomized) {
        // if there are error until here, it's server error known, so add prefix server error, except when is customize error
        errorMessage = `mobilsemError.server::${errorMessage}`;
      }

      if (
        errorMessage === '' &&
        err.message &&
        typeof err.message === 'string'
      ) {
        //console.error(err);
        if (err.message.includes('Network')) {
          // ES6
          errorMessage = 'mobilsemError.network';
        } else {
          if (errorMessage === '')
            errorMessage = `mobilsemError.server::${err.message}`;
        }
      }

      if (!errorMessage) {
        // undefined or empty // last option, produce unknown error
        errorMessage = 'mobilsemError.unknownServer';
        // console.log('**** mobilsemError.unknownServer');
        // console.log(err);
        if (isString(err)) {
          // it's possible to show string error
          // console.log(' is stringgggggggggggggg');
          errorMessage = `::${errorMessage}`;
        }
      }
    } else {
      /* Error in client ( but include network Error) */
      if (err.message && err.message.includes('mobilsemError.')) {
        // prority customize Errors
        errorMessage = err.message;
      }
      if (errorMessage === '' && err.errors) {
        // E.g. error token
        try {
          errorMessage = err.errors[0].state[0].key;
        } catch (e) {
          /* ignore the error */
        }
      }
      // console.log('zzzzzzzzzzzzzzzzzz');
      // console.log(errorMessage);
      if (errorMessage === '' && isString(err.message)) {
        errorMessage = `mobilsemError.client::${err.message}`;
      }
      if (!errorMessage) {
        // undefined or empty
        errorMessage = 'mobilsemError.unknownClient';
        if (isString(err)) {
          // it's possible to show string error
          errorMessage += `::${err}`;
        }
      }
    }
  }
  if (!errorMessage) {
    // undefined or empty
    if (isString(err)) {
      // it's possible to show string error
      if (err.includes('mobilsemError.')) {
        errorMessage = err; // error have already format and type source error, just store
      } else {
        // there is not defined the source of error, so put unknown
        errorMessage = 'mobilsemError.unknownSource';
        errorMessage += `::${err}`;
      }
    } else {
      errorMessage = 'mobilsemError.unknownSource';
    }
  }
  // is not error, triger abort changes, e.g. save registration without changes trigger
  if (errorMessage.includes(`Cannot read property 'length' of null`)) {
    typeError = 3;
    errorMessage = 'alert.datanochangessaved';
  }
  if (errorMessage.includes(`notUnique`)) {
    typeError = 1; // no timeout
  }
  let msg = errorMessage;

  if (translate) {
    msg = errorTranslate(errorMessage, props.t);
    if (log) console.log('errorMessage translate:', errorMessage, msg);
  }
  if (toast) {
    // help to get double error, from catch, and from apollo catch, popup message have the priority
    localStorage.removeItem('Error');
    props.showNotificationWithTimeout(msg, typeError); // second parameter is error
    // in this case, delete storage error because it's sure will be showed
  } else {
    return msg;
  }
};

export const checkLoadCrud = (
  aQlFiltered,
  props,
  log = false,
  redirect = true
) => {
  // aQlFiltered is generead by generator.js automatically with list of related and norelated tables
  /* when there is nothing give null
    This type error persist, just to load the web,will clean in app.js mount,
    is an error launched by apolloClient, is an network error or error in query
  */
  // log = true;

  let errorFromStorage;
  let eError = localStorage.getItem('Error');

  if (eError !== null) {
    // check date error, if is > 3.5 seconds , clean and ignore
    if (log) console.log('error found in storage, check date');
    const errorDate = localStorage.getItem('ErrorDate');
    const errorTimeElapsed = Date.now() - errorDate;
    if (errorTimeElapsed > 4000) {
      errorFromStorage = false;
      localStorage.removeItem('Error');
      localStorage.removeItem('ErrorDate');
    } else {
      errorFromStorage = true;
    }
    if (log) console.log('errorTimeElapsed', errorTimeElapsed);
  }

  let globalLoading = false;
  let messageError = '';

  if (log) console.log('xxxxxxxxxprops', props);
  if (errorFromStorage) {
    //console.log('get error in Check Error , localstorage');
    const errmsg = processError(eError, props, false, '', false);

    if (
      errmsg === 'login.tokenInvalid' ||
      errmsg === 'login.security' ||
      errmsg === 'login.tokenEmpty'
    ) {
      signOut(); // it's more sure clean loggged data, because possible stored session saved for deleted user

      if (redirect) {
        // only is not redirect rare case, example: PreRegistration, when
        // it's possible let it pre registration, if you are logged or not
        // so, for the qls that give error; no problem, because you can
        // fill the form, signup, preregistration , etc
        setTimeout(() => {
          try {
            props.showNotificationWithTimeout(
              errorTranslate(errmsg, props.t),
              3,
              6
            );
            props.history.push('/signin');
          } catch (err) {
            // dont use alert,  problem with token can be launched before dictionary is loaded
            // so just sent to console.error (it's not really an error) and redirect
            console.error(errorTranslate(errmsg, props.t));
            // console.log('window.location.href', window.location.href);
            if (!window.location.href.includes('/signin')) {
              // disable infinit loop if already on the page
              window.location.href = '/signin';
            } else {
              // console.log('already on signin -------------------');
            }
          }
        }, 500);
      }
    } else {
      messageError = errmsg;
    }
  }

  let lastErrmsg = '';
  const detailedError = {};

  for (let qlName in aQlFiltered) {
    let grahQl = aQlFiltered[qlName];
    if (log) console.log('related:', qlName);

    let resultQlName;
    if (qlName.includes('crud_list')) {
      // crud list has a differen path, eg. pagecustomers.nodes
      resultQlName = 'page' + grahQl.table + 's';
    } else if (!grahQl.table) {
      // ql inserted by hand or another ql has no table reference, then result name is object (if was sent) or the same name ql
      resultQlName = grahQl.object ? grahQl.object : qlName;
    } else {
      resultQlName = grahQl.table + (qlName.includes('list_') ? 's' : ''); // only _list query is plural
    }
    if (props[qlName] && props[qlName].error) {
      // warning, empty can be a error that query or subquery has no id:
      // Error: Network error: Error writing result to store for query (Apollo Client)
      // only is visible activing the log = true, on this function and watching the props of
      // log, with this next consoloe that error is invisible %%%
      console.log(
        'error loading graphql',
        JSON.stringify(props[qlName].error, props[qlName].error.graphQLErrors)
      );
      const detailedOneError =
        props[qlName].error.graphQLErrors &&
        props[qlName].error.graphQLErrors &&
        props[qlName].error.graphQLErrors.length > 0 &&
        props[qlName].error.graphQLErrors[0].message
          ? props[qlName].error.graphQLErrors[0].message
          : '';
      if (
        !(
          grahQl.tokenInvalid &&
          props[qlName].error &&
          props[qlName].error.graphQLErrors &&
          props[qlName].error.graphQLErrors.length > 0 &&
          props[qlName].error.graphQLErrors[0].message.includes('tokenInvalid')
        )
      ) {
        let errorMessage = '';
        let typeError = 1;
        let avoidError = false; // when it's need stop error, or hide it, it's not really an error

        lastErrmsg = processError(props[qlName].error, props, false);
        if (log)
          console.log('get error in Check Error , props ' + qlName + '.error');
      } else {
        detailedError[qlName] = detailedOneError;
      }
    }

    // just check query , no mutations (list_ , view_ )
    if (
      qlName.includes('list_') ||
      qlName.includes('view_') ||
      qlName.substr(0, 3) === 'get'
    ) {
      if (log) console.log('check is loading? ' + qlName + '.' + resultQlName);

      if (
        typeof props[qlName] === 'undefined' ||
        typeof props[qlName][resultQlName] === 'undefined'
      ) {
        globalLoading = true;
        if (log) console.log('...loading');
      } else {
        if (log) console.log('...LOADED');
      }
    } else {
      // it's mutation, then no control in loading ...? when submit ? %%%
    }
  }
  if (lastErrmsg !== '' && messageError === '') {
    // priority error message is from apolo, first block
    messageError = lastErrmsg;
    if (log) console.log('error loading queryData', props);
    //resultComponent = <MsgError msg={lastErrmsg} t={props.t} />
  }
  if (log) console.log('globalLoading ', globalLoading);
  return { globalLoading, messageError, detailedError };
};

const stateValidator = (props, value, validationObj, nameForm) => {
  //console.log('stateValidator : props / nameForm', props, nameForm);
  if (!value) return '';
  let error = '';
  if (validationObj.validator === 'cantBeLowerThan') {
    /*
      from validate reduxform  (formProps is sent), need to look for state for that name, has:
        .name (name of form)
        .crudAction
        .initialValues
        .values
        .myState
      from submit (containerFormProps is sent ), need nameForm to get values, has:
        .appSubmitStart
        .crud_view_...
        .myState
        .dispatch

     */
    let fieldValue = getInputValue(
      props,
      validationObj.field,
      nameForm ? { nameForm } : {}
    );
    //console.log(' stateValidator value / stateValue', value, fieldValue);
    if (value < fieldValue) {
      return (
        'validator.cantBeLower::' + tt(props.t, 'form.' + validationObj.field)
      );
    }
  }

  return '';
};

const joinValidators = (
  fieldKey,
  fieldMembersPure,
  value,
  props,
  nameForm,
  index
) => {
  // dont modify variable directly, dangerous keep value forever for the obj, for all records same table
  // then give force validation wihtout reading real state
  const fieldMembers = Object.assign({}, fieldMembersPure);
  const listValidators = [...fieldMembers.validators]; // important dont use subkey to not conserve old values in memory
  // !index <-- is not prepared to validate subfields ( index = 0,1, etc)
  // props can be sent undefined, for login without tables -> Eg; fieldsValidator(fields, values) <-- no 'props' and 'nameForm' parameters
  // TWIN LINE CPR032
  if (
    index === null &&
    props &&
    props.containerPropsForm &&
    props.containerPropsForm.requiredFields &&
    props.containerPropsForm.requiredFields[fieldKey] &&
    !listValidators.includes('required')
  ) {
    listValidators.push('required');
  }
  if (fieldMembers.dbtype === 'Date') listValidators.push('date');

  let aValidators;
  let aFielderrors = [];

  for (const [key, validator] of Object.entries(listValidators)) {
    let fielderror = '';
    if (realTypeOf(validator) === '[object Object]') {
      // validator is object, then is a state validator (check another form values)
      // when form is loading, state validators don't see the value, but when users start typing
      // or try submit the error is showed (here  onchange for fake field 'validation'
      // to get show the errors)
      if (props) {
        // only when props is not undefined,  stateValidtor is special validator like 'lowerthan' at the moment
        fielderror = stateValidator(props, value, validator, nameForm);
      }
    } else {
      let aValidator = validator.split(':');
      let enterValid = true;
      if (aValidator[0].substr(0, 4) === 'days' && props) {
        // only when props is not undefined,
        // look for initialValue, dont validate if value has not changed (possible old records with old dates)
        const oldValue = getInputValue(
          props,
          fieldKey,
          nameForm ? { nameForm, initial: true } : {}
        );
        if (oldValue === value) {
          enterValid = false;
        }
      }
      // new param: index, in case fieldArray is >=0  else null
      // if props is undefined, is ok, only very few validators use props, like phones, and this last check if props exist, so it's ok
      if (enterValid) {
        fielderror = commonValidator(value, index, props, ...aValidator);
      }
    }
    if (fielderror !== '') {
      // console.log('fielderror fieldKey, index, listValidators', fieldKey, index, listValidators)
      aFielderrors.push(fielderror);
    } else {
    }
  }
  return aFielderrors;
};

export const fieldsValidator = (tableName, values, props, nameForm = '') => {
  let log = false;
  if (log) console.log('values', values, props);
  // props can be undefnid, so always there is a if (props)
  let fields;
  let realTable = true;
  if (realTypeOf(tableName) === '[object Object]') {
    // special case, primer parameter is not table name , but felds (object)
    fields = tableName;
    realTable = false;
  } else {
    fields = Tables[tableName].fields;
  }
  // priority validator must be in first position
  const errors = {};
  let _formstate;
  if (values._formstate) {
    _formstate = JSON.parse(values._formstate);
  }
  //forOwn(fields, (fieldMembers, fieldKey) => {
  for (const [fieldKey, fieldMembers] of Object.entries(fields)) {
    let aFielderrors = [];

    let value;
    if (log) console.log('fieldKey, value', fieldKey, values[fieldKey]);

    /*
     check form state local value,
     saved in field redux form,
     to ignore validation if field is listed in state like hidden or disable
     */
    let enterValidate = true;
    if (_formstate) {
      if (
        resolvePathObj(_formstate, 'hiddenFields.' + fieldKey) ||
        resolvePathObj(_formstate, 'disabledFields.' + fieldKey)
      ) {
        //console.log('is hidden dont validate');
        enterValidate = false; // is hidden, don't validate
      }
    }
    // check props because can be undefined, for logins without table, eg. SiginForm
    if (
      fieldMembers &&
      fieldMembers.readOnlyUpdate &&
      props &&
      props.crudAction === 'View'
    ) {
      enterValidate = false; // is hidden, don't validate
    }
    if (enterValidate) {
      //if (get(values, fieldKey) === undefined) {
      if (typeof values[fieldKey] === 'undefined') {
        value = ''; // %% sure for all type datas ?
      } else if (typeof values[fieldKey] === 'string') {
        value = values[fieldKey].trim();
      } else {
        value = values[fieldKey];
      }

      if (log) console.log(JSON.stringify(value), typeof value);
      if (realTable && Tables[tableName].fields[fieldKey].subfields) {
        // field width subfields
        if (log) console.log(`** field:${fieldKey}`, values);

        /*
         subfields need send error like
         containerField [index].field = [...errors];
         */
        if (realTypeOf(value) === '[object Array]') {
          value.map((rowValues, index) => {
            Object.keys(Tables[tableName].fields[fieldKey].subfields).forEach(
              (subfieldKey) => {
                aFielderrors = []; // aFielderrors is object for subfield, for normal is array
                if (log) {
                  console.log(
                    'index: ' +
                      index +
                      ', subfield: ' +
                      subfieldKey +
                      'typeof value:' +
                      typeof value[index][subfieldKey] +
                      ' ,value',
                    value[index][subfieldKey]
                  );
                }
                let subfield =
                  Tables[tableName].fields[fieldKey].subfields[subfieldKey];
                // undefined means the subfield is hidden by example; so don't add validators for hidden or disable fields

                if (subfield.subfields) {
                  // have grandchildrens

                  if (realTypeOf(rowValues[subfieldKey]) === '[object Array]') {
                    rowValues[subfieldKey].map((subrowValues, subindex) => {
                      Object.keys(subfield.subfields).forEach(
                        (subsubfieldKey) => {
                          aFielderrors = []; // need reset list errors for every children field
                          let subsubfield = subfield.subfields[subsubfieldKey];
                          let valueSubSubField =
                            rowValues[subfieldKey][subindex][subsubfieldKey];

                          //console.log('subsubfieldKey, subsubfield ,', subsubfieldKey, subsubfield );
                          if (subsubfield.validators) {
                            let jValidators = joinValidators(
                              subsubfieldKey,
                              subsubfield,
                              valueSubSubField,
                              props,
                              nameForm,
                              subindex
                            );
                            // console.log('subsubfield.validators', subsubfield.validators, jValidators);

                            if (jValidators.length > 0)
                              aFielderrors = jValidators[0];
                            if (aFielderrors.length > 0) {
                              if (log)
                                console.log(
                                  'index:' + index + ' aFielderrors:',
                                  aFielderrors
                                );
                              // TWIN ERBLO311
                              if (!errors[fieldKey]) errors[fieldKey] = [];
                              if (!errors[fieldKey][index])
                                errors[fieldKey][index] = {};
                              if (!errors[fieldKey][index][subfieldKey])
                                errors[fieldKey][index][subfieldKey] = [];
                              if (
                                !errors[fieldKey][index][subfieldKey][subindex]
                              )
                                errors[fieldKey][index][subfieldKey][subindex] =
                                  {};

                              errors[fieldKey][index][subfieldKey][subindex][
                                subsubfieldKey
                              ] = aFielderrors; //TWIN ET1012 errorTranslate support only 1 value,not array
                              // errors.itinerary[0].timetable[0] = { gps :'test'};
                              // console.log('errror ruta fieldKey][index][subfieldKey][subindex][subsubfieldKey', fieldKey,index,subfieldKey,subindex,subsubfieldKey, errors);
                            }
                          }
                          if (aFielderrors.length === 0) {
                            // format especial arbitrary for grandparent, child , grand child
                            const inputFullNameCtrl = `${fieldKey}${index}${subfieldKey}${subsubfieldKey}${subindex}`;
                            // TWIN ERRORS103 validate field  with manual state .errors, the field must has validators, at least empty = []
                            // look for another errors in state form: (normally creates by crudCode.js)
                            if (
                              resolvePathObj(
                                _formstate,
                                'errors.' + inputFullNameCtrl
                              )
                            ) {
                              // TWIN ERBLO311
                              if (!errors[fieldKey]) errors[fieldKey] = [];
                              if (!errors[fieldKey][index])
                                errors[fieldKey][index] = {};
                              if (!errors[fieldKey][index][subfieldKey])
                                errors[fieldKey][index][subfieldKey] = [];
                              if (
                                !errors[fieldKey][index][subfieldKey][subindex]
                              )
                                errors[fieldKey][index][subfieldKey][subindex] =
                                  {};
                              errors[fieldKey][index][subfieldKey][subindex][
                                subsubfieldKey
                              ] = _formstate.errors[inputFullNameCtrl];
                            }
                          }
                        }
                      );
                    });
                  }
                } else {
                  if (subfield.validators) {
                    // validateIfEmpty && validateRequiredIfEmpty useful to
                    // control validation if value exist in another field same row
                    let enterValid = true;
                    if (
                      subfield.validateIfEmpty &&
                      value[index][subfield.validateIfEmpty]
                    ) {
                      // here could be , a property to delete only some validators like 'required' and not all
                      enterValid = false;
                    }
                    if (
                      subfield.validateRequiredIfEmpty &&
                      value[index][subfield.validateRequiredIfEmpty]
                    ) {
                      // here could be , a property to delete only some validators like 'required' and not all
                      subfield.validators = subfield.validators.filter(
                        (validator) => validator !== 'required'
                      );
                    }

                    // BLOCK VALD891 ATTACHED TO BLOCK FF9289
                    // delete all validation, dont support to pick up only part of list of validations
                    // check props because can be undefined, for logins without table, eg. SiginForm
                    if (
                      typeof subfield.conditionalRequired !== 'undefined' &&
                      props
                    ) {
                      let fieldValidatorRequired;
                      if (
                        subfield.conditionalRequired[0].substr(0, 1) === '*' &&
                        typeof props.syncCode === 'function'
                      ) {
                        const resultSync = props.syncCode(
                          subfield.conditionalRequired[0],
                          { formProps: { ...props } }
                        );
                        fieldValidatorRequired =
                          resultSync === subfield.conditionalRequired[1];
                      } else {
                        fieldValidatorRequired = resolvePathObj(
                          props.myState,
                          subfield.conditionalRequired[0],
                          {
                            compare: subfield.conditionalRequired[1],
                            notFound: false,
                          },
                          false
                        );
                      }
                      enterValid = fieldValidatorRequired;
                    }
                    if (enterValid) {
                      let valueSubField = value[index][subfieldKey];
                      if (log)
                        console.log(
                          'subfield row ' + index + ', ',
                          valueSubField
                        );
                      // last parameter only for field array , here line = index ( >=0)
                      let jValidators = joinValidators(
                        fieldKey,
                        subfield,
                        valueSubField,
                        props,
                        nameForm,
                        index
                      );
                      if (log)
                        console.log('result validator errors:', jValidators);
                      if (jValidators.length > 0) aFielderrors = jValidators[0];
                    }
                  }

                  if (aFielderrors.length > 0) {
                    if (log)
                      console.log(
                        'index:' + index + ' aFielderrors:',
                        aFielderrors
                      );
                    if (!errors[fieldKey]) errors[fieldKey] = [];
                    if (!errors[fieldKey][index]) errors[fieldKey][index] = {};
                    errors[fieldKey][index][subfieldKey] = aFielderrors; //TWIN ET1012 errorTranslate support only 1 value,not array
                  }

                  if (log)
                    console.log('index:' + index + ' row values:', rowValues);
                }
                /* DISCONTINED: real value from warning, is delayed when keypress
              //const fieldNameWithLine = `${fieldKey}${subfieldKey}${index}`;
              // when there no errors, check if there is warnings of type Error
              //console.log('formState.warningFields:'+ fieldNameWithLine, props.formState.warningFields, resolvePathObj(props, 'formState.warningFields.' + fieldNameWithLine));
              if (aFielderrors.length === 0
                && resolvePathObj(props, 'formState.warningFields.' + fieldNameWithLine)) {
                if (props.formState.warningFields[fieldNameWithLine]  // check if not null
                  && isString(props.formState.warningFields[fieldNameWithLine][0])
                  && props.formState.warningFields[fieldNameWithLine][0].substr(0,5) === 'ERROR') {
                  aFielderrors = 'validator.' + props.formState.warningFields[fieldNameWithLine][0].substr(6);
                  //console.log('aFielderrors', aFielderrors);

                }
              }*/
              }
            );
          });
        }
      } else if (
        fieldMembers.validators || // Normal field; not arrayfield
        // check props because can be undefined, for logins without table, eg. SiginForm
        (props &&
          props.containerPropsForm &&
          props.containerPropsForm.requiredFields &&
          props.containerPropsForm.requiredFields[fieldKey])
      ) {
        // normal field, there are validatores
        // add on fly for type data his validator
        // last parameter only for field array , here line = null

        if (!fieldMembers.validators) fieldMembers.validators = [];

        let jValidators = joinValidators(
          fieldKey,
          fieldMembers,
          value,
          props,
          nameForm,
          null
        );
        aFielderrors = [...aFielderrors, ...jValidators];
        // //console.log('array errors');
        // //console.log(aFielderrors);
        // priority search errors accord to validators, if not found then search defined in state form on crudCode
        if (aFielderrors.length > 0) {
          // console.log('fieldkey props.formState.requiredFields ', fieldKey, props.formState );
          errors[fieldKey] = aFielderrors[0]; //TWIN ET1012 errorTranslate support only 1 value,not array
          if (log) console.log('errors in: ' + fieldKey, errors[fieldKey]);
          // errors[fieldKey] = aFielderrors.toString() ; // in the future with a set, can return all the errors
        } else {
          // TWIN ERRORS103 validate field  with manual state .errors, the field must has validators, at least empty = []
          // look for another errors in state form: (normally creates by crudCode.js)
          //console.log('_formstate, errors' + fieldKey, _formstate);
          if (resolvePathObj(_formstate, 'errors.' + fieldKey)) {
            errors[fieldKey] = _formstate.errors[fieldKey];
          }
        }
      }
      /*
      suspended, to validate field  with manual state .errors, the field must has validators, at least empty = []
      if (!errors[fieldKey]) {

            console.log('two _formstate, errors' + fieldKey, _formstate);
          if (resolvePathObj(_formstate, 'errors.' + fieldKey)) {

            errors[fieldKey] = _formstate.errors[fieldKey];
          }
      }*/
    }
  }
  // errors._error = 'forzado form error';
  if (log) console.log('errors', errors);
  return errors;
};

/*
  Return object with {id,name}
 */

export const preSubmitValidation = async (
  tableCrud,
  action,
  props,
  syncCode = null,
  values = null,
  crudCode = null
) => {
  // let newValues  = processValues (props, tableCrud, values,'toServer','update');
  const table = Tables[tableCrud];
  let nameForm = `${tableCrud}Form`;
  const containerPropsForm = props.containerPropsForm
    ? props.containerPropsForm
    : props;
  // this line get the submit values from redux form submit, but on the second time, the values are not updated
  // TWIN VALASTERR11
  // let newValues = values ? values : containerPropsForm.myState.form[nameForm].values; //props.myState.form[nameForm].values;
  let newValues = containerPropsForm.myState.form[nameForm].values; //props.myState.form[nameForm].values;
  //console.log('newValues state form', newValues);

  const newProps = { ...props }; // if dont' make spread and props is used directly, below with syncCode() hung the app
  if (typeof syncCode === 'function') {
    newProps.syncCode = (method, params = {}) => {
      return syncCode(method, params);
    };
  }
  let aErrors = fieldsValidator(tableCrud, newValues, newProps, nameForm);

  // first validate onSubmit de crudCode for most import errors
  if (Object.keys(aErrors).length === 0 && crudCode && crudCode['onSubmit']) {
    aErrors = await crudCode['onSubmit']({ nameForm, formProps: newProps });
    if (!aErrors) aErrors = {}; // convert undefined to {}, this is the right format to avoid error on next block
  }
  if (Object.keys(aErrors).length > 0) {
    // at least show the first field with error
    const keyFirstField = Object.keys(aErrors)[0];
    const labelField = table.fields[keyFirstField]
      ? table.fields[keyFirstField].label
      : '';
    let lcMessagePopup = tt(
      props.t,
      'form.haserrorsPopup::' + tt(props.t, labelField)
    );
    let errorFirstField = aErrors[keyFirstField];
    if (realTypeOf(errorFirstField) === '[object Array]') {
      // it's a subfield, for subfields, don't look for string on fieldarray
      const keyItemSubField = Object.keys(errorFirstField)[0];
      lcMessagePopup +=
        ': <br/> ' +
        props.t('form.line') +
        ' # ' +
        (parseInt(keyItemSubField) + 1);
      const itemErrors = errorFirstField[keyItemSubField]; // just errors for subfield
      const keyFirstSubField = Object.keys(itemErrors)[0];
      if (
        table.fields[keyFirstField] &&
        table.fields[keyFirstField].subfields[keyFirstSubField]
      ) {
        const labelSubField = tt(
          props.t,
          table.fields[keyFirstField].subfields[keyFirstSubField].label
        );
        lcMessagePopup +=
          ', ' +
          props.t('form.subfield') +
          ' "' +
          labelSubField +
          '": ' +
          tt(props.t, itemErrors[keyFirstSubField]);
      }
    } else {
      // it's not subfield, so talke string error to translate
      lcMessagePopup += ': ' + tt(props.t, errorFirstField);
    }
    containerPropsForm.showNotificationWithTimeout(lcMessagePopup, 2, 7);
    //console.log('there is errors in form', aErrors);
    if (containerPropsForm.appSubmitStop) {
      containerPropsForm.appSubmitStop(); // give no error if function is undefined, but it's better contrl
    } else {
      console.error(
        'no defined dispatcher props.appSubmitStop in submitValues()'
      );
    }
    return false;
  }
  return true;
};

export const preSubmitToolBar = async (
  tableCrud,
  action,
  props,
  syncCode = null,
  crudCode = null
) => {
  // caller must send always props.containerPropsForm
  /*
  Lee los errores grabados por fieldsValidator directamente desde  el state, 
  */

  const table = Tables[tableCrud];
  let nameForm = `${tableCrud}Form`;

  /// props is never used, the important here is containerPropsForm, to get values from state, and notifications popups
  const containerPropsForm = props.containerPropsForm;
  const aErrors = containerPropsForm.myState.form[nameForm].syncErrors; // form have no mystate, but container
  const { t } = containerPropsForm;
  if (aErrors && Object.keys(aErrors).length > 0) {
    // at least show the first field with error
    const keyFirstField = Object.keys(aErrors)[0];
    // when is a fake field, not found on tables.fields, use 'form.'+ key
    const labelField = table.fields[keyFirstField]
      ? table.fields[keyFirstField].label
      : 'form.' + keyFirstField;
    let lcMessagePopup = tt(t, 'form.haserrorsPopup::' + tt(t, labelField));
    if (tableCrud === 'regonline' && keyFirstField.includes('service_')) {
      lcMessagePopup = tt(t, 'form.haserrors::');
    }
    let errorFirstField = aErrors[keyFirstField];
    // console.log('keyFirstField; errorFirstField',keyFirstField, errorFirstField, props, aErrors)
    if (realTypeOf(errorFirstField) === '[object Array]') {
      // it's a subfield, for subfields, don't look for string on fieldarray
      const keyItemSubField = Object.keys(errorFirstField)[0];
      lcMessagePopup +=
        ': <br/> ' + t('form.line') + ' # ' + (parseInt(keyItemSubField) + 1);
      const itemErrors = errorFirstField[keyItemSubField]; // just errors for subfield
      if (itemErrors && Object.keys(itemErrors).length > 0) {
        const keyFirstSubField = Object.keys(itemErrors)[0];
        // console.log('itemErrors , keyFirstSubField', itemErrors , keyFirstSubField);
        if (realTypeOf(itemErrors[keyFirstSubField]) === '[object Array]') {
          // first error is a grandchild field,
          // subfield witho grandchild fields
          const keyItemSubSubField = Object.keys(
            itemErrors[keyFirstSubField]
          )[0]; // index array grand child
          const contentFirstSubSubField =
            itemErrors[keyFirstSubField][keyItemSubSubField]; // name of filed grandchild
          const keyFirstSubSubField = Object.keys(contentFirstSubSubField)[0];
          lcMessagePopup +=
            '  ' +
            t('form.subline') +
            ' # ' +
            (parseInt(keyItemSubSubField) + 1); // subline: 0 ou 1,2,3....
          //console.log('itemErrors,  keyItemSubSubField,  keyFirstSubSubField , keytFirstSubSubField', itemErrors, keyItemSubSubField,  keyFirstSubSubField);
          const labelSubField = tt(t, 'form.' + keyFirstSubSubField);
          //
          lcMessagePopup +=
            ',  "' +
            labelSubField +
            '": ' + // name field grandchild
            tt(t, contentFirstSubSubField[keyFirstSubSubField]); // code error
        } else {
          // subfield without grandchild fields
          const labelSubField = tt(
            t,
            table.fields[keyFirstField].subfields[keyFirstSubField].label
          );
          lcMessagePopup +=
            ', ' +
            t('form.subfield') +
            ' "' +
            labelSubField +
            '": ' +
            tt(t, itemErrors[keyFirstSubField]);
        }
      } else {
        // some errors in the browser like svg , launch this function error !! so if the format of error is not found
        // on the previous: if (itemErrors && Object.keys(itemErrors).length>0) { // then is not error, w
        // without this check the block launch nan error because dont find the object.keys
        console.log('---aErrors itemErrors empty', aErrors, itemErrors);
        return true;
      }
    } else {
      // it's not subfield, so talke string error to translate
      lcMessagePopup += ': ' + tt(t, errorFirstField);
    }
    containerPropsForm.showNotificationWithTimeout(lcMessagePopup, 2, 7);
    //console.log('there is errors in form', aErrors);
    if (containerPropsForm.appSubmitStop) {
      containerPropsForm.appSubmitStop(); // give no error if function is undefined, but it's better contrl
    } else {
      console.error(
        'no defined dispatcher props.appSubmitStop in submitValues()'
      );
    }
    return false;
  }
  return true;
};

export const submitValues = async (
  tableCrud,
  action,
  props,
  validate = true,
  syncCode = null,
  values = null,
  crudCode = null
) => {
  // let newValues  = processValues (props, tableCrud, values,'toServer','update');
  const table = Tables[tableCrud];
  let nameForm = `${tableCrud}Form`;
  const containerPropsForm = props.containerPropsForm
    ? props.containerPropsForm
    : props;
  // this line get the submit values from redux form submit, but on the second time, the values are not updated
  // TWIN VALASTERR11
  // let newValues = values ? values : containerPropsForm.myState.form[nameForm].values;
  let newValues = containerPropsForm.myState.form[nameForm].values;
  if (validate) {
    // eg. case TourroomBookingForm dont' validate
    const validation = await preSubmitValidation(
      tableCrud,
      action,
      props,
      syncCode,
      values,
      crudCode
    );
    if (!validation) return false;
  }
  // console.log('ValuesProcess Sin Procesar ------------- table:'+tableCrud+', action:'+action, newValues);
  // // important to save in child the parentid
  let parentid =
    containerPropsForm.match.params && containerPropsForm.match.params.parentid
      ? containerPropsForm.match.params.parentid
      : '';
  if (action === 'add' && table.parentTable) {
    // add value to field parent, in child table,  accord url
    if (!parentid) {
      throw (
        'processValues: Error idParent not defines for table child: ' +
        table.name.singular
      );
    }
    newValues[table.parentRelationField] = parentid;
    //console.log('newValues',newValues );
  }
  newValues = processValues(
    props,
    tableCrud,
    newValues,
    'toServer',
    action === 'view' ? 'update' : action
  );
  /* console.log(
    "ValuesProcess Procesado    ------------- table:" +
      tableCrud +
      ", action:" +
      action,
    " newValues",
    newValues
  );*/

  /*
  TWIN QLACTION029
  Very important to cath the error here .// if not the error will show only on the console
   */
  let wasError = false;
  const response = await gqlActions(
    tableCrud,
    startCase(action),
    props,
    newValues
  ).catch((err) => {
    wasError = true;
    // error graphql different to http 200 code
    processError(err, props, true, tableCrud);
  });
  if (wasError) {
    return 'dontreloaddata';
  }

  if (containerPropsForm.appSubmitStop) containerPropsForm.appSubmitStop();
  if (response === 'dontreloaddata') {
    // stop process that reload data in form (initialValues too)
    // because there are manual url change, and below 'props.initialize', modify
    // values of form after page is changed giving wrong value or states like empty autocomplete box customer
    // and possible stranges behaviours
    // (eg: add form save, change url to view)
    return response;
  }
  //console.error('submit values');

  // TWIN OPEN2222
  if (
    !process.env.REACT_APP_NAME.includes('Voyages') &&
    window.opener &&
    response &&
    response.id
  ) {
    // make sure save record was ok
    //console.log('response',response);
    const urlParams = new URLSearchParams(containerPropsForm.location.search);
    let popup = urlParams.get('popup'); // it will convert to inputFullName
    if (isNaN(popup)) {
      //number is not secondary window to save
      // TWIN FUL492, decompose input name with line and container form
      let line = null;
      let inputName;
      if (popup.includes('[')) {
        let inputNameArray = popup.match(/(\w+)\[([\d*]+)\]\.(\w+)/);
        popup = inputNameArray[1] + inputNameArray[3]; // real name
        inputName = inputNameArray[3];
        line = parseInt(inputNameArray[2]); // important integer to forms cruds logic; that wait integer for line
      } else {
        inputName = popup;
      }

      let newValue = { id: response.id, name: response.name };
      if (response.pcs) {
        // is city, send code postal:
        // let pcs with out parse it, because it's parse in on change, and subvalues are not parse always..
        // because there is no parse for subvalues of fieldarray *%%%
        //let pcs  = JSON.parse (response.pcs); // if have only one cp , send
        //if (pcs.length === 1 ) {
        newValue.pcs = response.pcs;
      }
      // exclude i18n that have circular reference , and gives error "Converting circular structure to JSON"
      const { i18n, ...theprops } = containerPropsForm;
      // popup dont' send nameForm, because nameForm is unknown, but on caller windows have formProps
      // that getInputValue use that to resolve name form
      const resMessage = JSON.stringify({
        line: line,
        popup,
        inputName,
        newValue,
        props: theprops,
      }); //, props: {props}
      //console.log('is going to send reponse', resMessage);
      window.opener.postMessage(resMessage, '*');
      window.close();
    }
  }
  //console.log('responsssssse', response);
  if (response) {
    // response = false, there was errors, like duplicated, etc
    const responseProcessed = processValues(
      props,
      tableCrud,
      response,
      'toClient',
      'view'
    );
    //console.log('responseProcessed', responseProcessed);
    // normally:  props.initialize, must to exist, if not, initialvalues is not upddate, and ctrl change will give wrong result field changes
    if (containerPropsForm.initialize) {
      //console.log('initialized ', nameForm, responseProcessed);
      containerPropsForm.initialize(nameForm, responseProcessed); // important update initialValues to ctrl changes before exit
    } else {
      //console.log('problem no .initialize found ', nameForm);
    }
  }
  return response;
};

export const deleteRecord = async (tableCrud, props) => {
  let newValues = { id: props.match.params.id };
  newValues = processValues(props, tableCrud, newValues, 'toServer', 'delete'); // add fields system auto, like deleted_user_id

  await gqlActions(tableCrud, 'Delete', props, newValues);
};

export const processNewValues = (props, tableName) => {
  let log = false;
  let table = Tables[tableName];
  const containerPropsForm = props.containerPropsForm
    ? props.containerPropsForm
    : props;
  //if (table === 'tour' && target==='toServer' && action==='update') log = true;
  //if (table === 'customer') log = true;

  if (process.env.NODE_ENV === 'production') log = false;
  if (log)
    console.error(`ProcessNewValues -----------, table:${table.name.singular}`);

  let newValues = {}; // skip inmutability values // Object.assign fails silent when try update values

  let newGroupedValues = {};
  const ValuesofGroup = {};
  let addField;
  for (const fieldName in table.fields) {
    addField = true;
    const field = table.fields[fieldName];
    let value = undefined;

    if (log)
      console.log(
        `${fieldName.toUpperCase()} *** ,type:${field.dbtype}, typeInput:${
          field.typeInput
        }, dbtype:${field.dbtype}, typeof: ` + typeof value
      );
    if (log) console.log('field def:', field);
    if (log) console.log('** value primitive:', value);

    if (typeof field.defaultValue !== 'undefined') {
      // zero is not undefined
      //console.log( field.defaultValue.toString().substr(0,2));
      value =
        field.defaultValue.toString().substr(0, 2) === 'f:'
          ? getDefaultValue(field.defaultValue)
          : field.defaultValue;
    }

    if (typeof value !== 'undefined') {
      newValues[fieldName] = value;
      if (log) console.log(`** value final:${value}`, value);
    } else {
      delete newValues[fieldName]; // newValues is copied from values, so need to delete
      if (log) console.log(`** field excluded`);
    }
  }
  if (containerPropsForm.myState.app.org)
    newValues.organizer_id = containerPropsForm.myState.app.org;

  const newValuesofGroupJson = {};
  newValues = Object.assign(newValues, newGroupedValues);

  if (log) console.log('newvalues-----------');
  if (log) console.log(newValues);
  return newValues;
};

export const Circle = (props) => {
  if (!props.color) return null;
  return (
    <svg height="17" width="17">
      <circle cx="8" cy="8" r="8" fill={props.color} />
    </svg>
  );
};
export const processDisplayValue = (
  value,
  fieldSource,
  t,
  listViewField = null
) => {
  if (fieldSource.titlePrefix) {
    value = t(fieldSource.titlePrefix + '.' + value);
  }
  if (fieldSource.typeValue && fieldSource.typeValue === 'Price') {
    value = toPrice(value);
    if (listViewField && listViewField.hiddenZero && parseFloat(value) === 0)
      value = ''; // don't print nothing if zero and config hiddenzero
  }
  if (fieldSource.typeInput === 'radio') {
    if (Array.isArray(fieldSource.listOptions)) {
      const optionFound = fieldSource.listOptions.find(
        (option) => option.id.toString() === (!value ? '' : value.toString())
      );
      if (optionFound) {
        value = t(optionFound.name);
      }
    }
  }
  if (fieldSource.dbtype === 'Boolean') {
    if (value) {
      value = t('form.yes');
    } else {
      value = t('form.no');
    }
  }
  if (fieldSource.dbtype === 'Date') {
    // In list need to convert from iso, in form is converted by the browser atuomatic.
    value = ISOtoDate(value);
  }
  return value;
};

export const formatCol = (fieldSource) => {
  let classCell = '';
  if (
    !fieldSource.listSource &&
    !fieldSource.typeInput === 'radio' &&
    (fieldSource.dbtype === 'Float' ||
      fieldSource.dbtype === 'Int' ||
      fieldSource.dbtype === 'Date')
  ) {
    // why %% without fieldSource.typeValue === 'Price'  dont get right zeros values?
    classCell += ' right';
  }
  return classCell;
};

export const processDisplayRelatedValue = (
  fieldSet,
  value,
  tableName,
  fieldSourceGenesis,
  realNameField,
  recordParsed,
  listViewField = null
) => {
  let log = false;
  //if (realNameField === 'ajournalcode_id') log = true;
  const table = Tables[tableName];
  let type = ''; // special for 'col', return array of columns to be printed like cols in list
  if (
    !fieldSourceGenesis.listSource &&
    (fieldSourceGenesis.typeInput === 'selectAutocomplete' ||
      (table.related && table.related[realNameField]))
  ) {
    let fieldsRelatedTabled = table.related[realNameField];
    let fieldsRelatedTabledAlias = fieldsRelatedTabled.alias;

    if (log)
      console.log(
        'fieldsRelatedTabled.alias',
        fieldsRelatedTabled.alias,
        'fieldSourceGenesis',
        fieldSourceGenesis,
        'recordParse',
        recordParsed,
        'tableName',
        tableName
      );
    if (!fieldsRelatedTabled || !recordParsed[fieldsRelatedTabledAlias]) {
      // value = 'related data for field: '+realNameField+', alias:'+ fieldsRelatedTabledAlias  + ' not found, wrong id ';
      value = '.'; // dont needed message error anymore, due regonline have organizer not real organizer, and new react seems charge component before data
    } else {
      if (log)
        console.log(
          'fieldsRelatedTabledAlias / ' + fieldsRelatedTabledAlias + '.name',
          fieldsRelatedTabledAlias,
          recordParsed[fieldsRelatedTabledAlias].name
        );
      //if (log) console.log (recordParsed[fieldsRelatedTabledAlias]);
      if (listViewField && listViewField.onlycolor) {
        value = <Circle color={recordParsed[fieldsRelatedTabledAlias].color} />;
      } else if (recordParsed[fieldsRelatedTabledAlias].color) {
        value = [
          <Circle
            key="color"
            color={recordParsed[fieldsRelatedTabledAlias].color}
          />,
          <span key="data">
            {' '}
            {recordParsed[fieldsRelatedTabledAlias].name}
          </span>,
        ];
      } else {
        // print special main field that contain a list of field (eg: checkdepositdet -> payment_id)
        if (fieldSourceGenesis.fieldDisplayedOptions) {
          let values = [];
          const separator =
            fieldSourceGenesis.fieldDisplayedOptions.separatorsubfield || ' / ';
          let newValue = '';
          if (log)
            console.log(
              'found fieldDisplayedOptions',
              fieldSourceGenesis.fieldDisplayedOptions
            );
          for (const [key, objField] of Object.entries(
            fieldSourceGenesis.fieldDisplayedOptions.subfields
          )) {
            let childRelatedFound = false;
            let fieldValue = recordParsed[fieldsRelatedTabledAlias][key];
            if (fieldValue && fieldValue.name) fieldValue = fieldValue.name;
            if (log) console.log('display option value for ' + key, fieldValue);

            /*
             grand child subrelated.
             Eg: paymentmethod {   // recordParsed = {}
                   paymentmethodajournalcode_id {  // recordParsed[fieldsRelatedTabledAlias]
                     ajournalcodecounterpart_id {  // recordParsed[fieldsRelatedTabledAlias][tableGrandChild +key]
                       name
                     }
                 }
             */

            /* console.log(
              "** field with subfields?",
              listViewField.subfields ? "yes" : "no",
              "key",
              key,
              "fieldSourceGenesis",
              fieldSourceGenesis,
              "fieldsRelatedTabledAlias",
              fieldsRelatedTabledAlias,
              "realNameField",
              realNameField,
              "value",
              value,
              "realvaluesubfields",
              recordParsed[fieldsRelatedTabledAlias],
              "recordParsed",
              recordParsed,
              "listViewField",
              listViewField
            );*/
            if (
              table.related &&
              table.related[realNameField] &&
              table.related[realNameField].subrelated
            ) {
              // field with his related table is subrelated, now we must detected if grandchild field is a table related
              // don't support recursive, just grandchild relateds...%% on future look for recursive, rare case
              // only support , get name of grand child table, not custome fields
              if (log)
                console.log(
                  'is subrelated table, trouhg field:  ' + realNameField
                );
              const tableGrandChild = table.related[realNameField].table;
              const fieldGrandChildSource = Tables[tableGrandChild].fields[key];
              if (fieldGrandChildSource && fieldGrandChildSource.table) {
                childRelatedFound = true;
                if (log)
                  console.log(
                    'grand child source field found: tableGrandChild/key/fieldSourceGenesis ',
                    tableGrandChild,
                    key,
                    fieldGrandChildSource
                  );
                if (log)
                  console.log(
                    'recordParsed[' +
                      fieldsRelatedTabledAlias +
                      '][' +
                      tableGrandChild +
                      key +
                      ']',
                    recordParsed[fieldsRelatedTabledAlias][
                      tableGrandChild + key
                    ]
                  );

                if (
                  recordParsed[fieldsRelatedTabledAlias] &&
                  recordParsed[fieldsRelatedTabledAlias][tableGrandChild + key]
                ) {
                  newValue +=
                    (newValue ? separator : '') +
                    recordParsed[fieldsRelatedTabledAlias][
                      tableGrandChild + key
                    ].name;
                }
              }
            }
            if (separator === 'col') {
              /* TWIN EXPCOL14
    expands cols from related table like main fields
  not call main field, but the subfields to get label
   */
              let classCell = 'cell';
              //console.log(key, fieldValue);
              const listViewField = Tables[fieldsRelatedTabled.table].listview
                .fields[key]
                ? Tables[fieldsRelatedTabled.table].listview.fields[key]
                : Tables[fieldsRelatedTabled.table].fields[key];
              if (listViewField.hiddenPhone) classCell += ' cellhiddenxPhone';
              if (listViewField.hiddenTablet) classCell += ' cellhiddenxTablet';
              classCell += formatCol(
                Tables[fieldsRelatedTabled.table].fields[key]
              );

              if (value && listViewField.subfields) {
                // call grand children subfields

                fieldValue = getSubFieldsToPrint(
                  objField, // normally = {true}, or other case have propieties like  { linkview: "..."},
                  fieldsRelatedTabledAlias,
                  fieldsRelatedTabled.table,
                  key,
                  realNameField,
                  listViewField,
                  fieldSourceGenesis,
                  recordParsed,
                  recordParsed[fieldsRelatedTabledAlias][key] // value
                );
                values.push(
                  <div className={classCell} key={key}>
                    {fieldValue}
                  </div>
                );
              } else {
                const realSubFieldSource =
                  fieldSourceGenesis.fieldDisplayedOptions
                    ? fieldSourceGenesis.fieldDisplayedOptions.subfields[key]
                    : fieldSourceGenesis.subfields[key];

                if (realSubFieldSource && realSubFieldSource.linkview) {
                  values.push(
                    <div className={classCell} key={key}>
                      <Link
                        to={`/${realSubFieldSource.linkview}-view/${value}`}
                      >
                        {fieldValue}
                      </Link>
                    </div>
                  );
                } else {
                  values.push(
                    <div className={classCell} key={key}>
                      {fieldValue}
                    </div>
                  );
                }
              }
            } else if (!childRelatedFound) {
              if (typeof fieldValue !== 'undefined') {
                newValue += (newValue ? separator : '') + fieldValue;
              }
            }
          }

          if (separator === 'col') {
            return { value: values, type: separator };
          } else {
            value = newValue;
          }
        } else if (recordParsed[fieldsRelatedTabledAlias]) {
          if (
            realTypeOf(listViewField) === '[object String]' &&
            recordParsed[fieldsRelatedTabledAlias][listViewField]
          ) {
            // example for regonline.tour.longname
            value = recordParsed[fieldsRelatedTabledAlias][listViewField];
          } else if (recordParsed[fieldsRelatedTabledAlias].name) {
            value = recordParsed[fieldsRelatedTabledAlias].name;
            if (
              recordParsed[realNameField] &&
              recordParsed[realNameField].id &&
              fieldSet.linkview
            ) {
              value = (
                <Link
                  to={`/${fieldSet.linkview}-view/${recordParsed[realNameField].id}`}
                >
                  {value}
                </Link>
              );
            }
          }
          if (log) console.log('found name');
        } else {
          value = 'error .name not found, for:' + fieldsRelatedTabledAlias;
        }
      }
    }
  }
  return { value, type };
};
export const getRelatedRecord = (tableName, relatedField) => {};

// get value id from record accord to  Input id value, and then return object record or value if  fieldRetrieve!=''
export const getRecordValues = (
  formProps,
  fieldsource,
  tableSource,
  log = false
) => {
  if (log)
    console.log(
      `== getRecordValue(), fieldsource: ${fieldsource}, tableSource: ${tableSource}, formProps`,
      formProps
    );
  const inputObjectValue = getInputValue(formProps, fieldsource);
  if (log)
    console.log(
      `getRecordValue() , fieldsource: ${fieldsource} / inputObjectValue =`,
      inputObjectValue
    );
  let id;
  if (inputObjectValue) {
    if (inputObjectValue.id) {
      id = inputObjectValue.id;
      if (log)
        console.log(
          `getRecordValue() , fieldsource: ${fieldsource} / found field value in object .id`
        );
    } else {
      if (log)
        console.log(
          `getRecordValue() , fieldsource: ${fieldsource} / found field value direct (maybe select in disable then value is direct string, not object) `
        );
      id = inputObjectValue;
    }
  } else {
    if (log)
      console.log(
        `getRecordValue() , fieldsource: ${fieldsource} / not found field value in state`
      );
    id = '';
  }

  if (log)
    console.log(`getRecordValue(), , fieldsource: ${fieldsource} /  id`, id);
  if (!id) return {}; // no id found, return {} or '' if fieldRetrive is sent
  return getRecordFromOptionsIndirectOnFilter(
    formProps,
    tableSource,
    id,
    false,
    log
  );
};

export const getRecordFromOptionsIndirectOnFilter = (
  formProps,
  tableSource,
  fieldsSearched,
  allfields = false,
  log = false
) => {
  // Warning tableSource, if is ql, need to be sent lile object { ql: qlName}

  // fieldsSearched can be a value for 'id' field, or un object with the fieldname and value to search

  if (log)
    console.log(
      `==getRecordFromOptionsIndirectOnFilter() fieldsSearched: ${fieldsSearched} for tableSource:${tableSource}`
    );

  const records = getOptions(tableSource, formProps, [], {}, true, log);
  if (log)
    console.log(
      `getRecordFromOptionsIndirectOnFilter() fieldsSearched: ${fieldsSearched}  / records from getOptions`,
      records
    );
  const oneRecord = getObjFromListById(records, fieldsSearched);
  // ///
  if (log)
    console.log(
      `getRecordFromOptionsIndirectOnFilter() fieldsSearched: ${fieldsSearched}  / One Records result using getObjFromListById():`,
      oneRecord
    );
  // until here, previous values can't be undefined or null because  are getting values
  // from the same records are loaded in selects and are retrived in props
  if (!oneRecord) {
    // not found record
    return {}; // if not field retrive , return empt obj
  }
  return oneRecord;
};

export const getFieldsChanged = (objForm, keyForm, t) => {
  const log = false;
  let tableCrud =
    keyForm.substr(-4) === 'Form' ? keyForm.substr(0, keyForm.length - 4) : '';
  if (log) console.log('objForm', objForm);
  let updatedFields = [];
  for (const [keyField, valuePure] of Object.entries(objForm.values)) {
    if (log) console.log('keyField/valuePure', keyField, valuePure);
    const value = JSON.stringify(valuePure);
    if (objForm.initial) {
      // rare cases  .initial does not exist, then no control
      const initialValue = objForm.initial[keyField];
      const initialValueStr = JSON.stringify(initialValue);
      // react asign to previous value 'null' to '' after field is touched
      // to avoid this it's needed use 'format={null}' %%% on <Field, but to don't modify all the logic
      // simply the control if previous value was null, and current ==='' means no change
      if (
        keyField.substr(0, 1) !== '_' &&
        initialValueStr !== value &&
        !(
          isNil(initialValue) &&
          (valuePure === '' || (Array.isArray(valuePure) && !valuePure.length))
        )
      ) {
        if (log)
          console.log('diff keyfield: ' + keyField, initialValue, valuePure);
        updatedFields.push(keyField);
      } else {
        //if (log)  console.log('NO diff '+ keyField, initialValue, value);
      }
    }
  }
  if (log) console.log('updatedFields', updatedFields);
  let listFields = '';
  if (updatedFields.length > 0) {
    // real table
    // twin CHF2387
    // first special casese
    if (keyForm === 'tourroomForm') {
      // unificar funcion con CrudToolBar, ver de meter cada buton que vaga a una funcion
      // unica de cambio de page; goURL ?
      listFields = t('table.roombooking');
    } else if (Tables[tableCrud]) {
      updatedFields.map((keyfield) => {
        let fieldLabel = '';
        if (Tables[tableCrud] && Tables[tableCrud].fields[keyfield]) {
          if (!Tables[tableCrud].fields[keyfield].disabledPreventExit) {
            fieldLabel = t(Tables[tableCrud].fields[keyfield].label);
          }
        }

        if (fieldLabel)
          listFields += (listFields ? ', -  ' : '- ') + fieldLabel;
      });
    }
  }
  return listFields;
};

export const getListviewFields = (
  propsQl,
  state,
  isModeArray = false,
  debug = false
) => {
  // debug = false;
  // it's executed just one time on load component, to load all pagesettings records
  if (state.listviewFields && Object.keys(state.listviewFields).length !== 0) {
    return isModeArray ? [] : {}; // exist already state, dont chnage
  }
  let pagesSettings = [];
  if (debug) {
    console.log(
      'propsQl.getPagesSetting.pagesSettings[0]',
      propsQl.getPagesSetting.pagesSettings[0]
    );
  }

  for (let r = 0; r < propsQl.getPagesSetting.pagesSettings.length; r++) {
    let record = propsQl.getPagesSetting.pagesSettings[r];
    const fields = JSON.parse(record.fields);
    // const fieldsForListView = JSON.parse(record.fields);
    if (debug) {
      console.log('fieldsForPS', fields);
    }
    pagesSettings.push({ ...record, fields });
  }

  return {
    listviewFields: JSON.parse(
      propsQl.getPagesSetting.currentPageSetting.listviewFields
    ),
    pagesSettings,
    pageSettingId: propsQl.getPagesSetting.currentPageSetting.id,
  };
};
