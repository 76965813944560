const confirmReducer = (state = [], action) => {
  // initialState ist just empty array
  //console.log('action', action);
  switch (action.type) {
    case "SHOW_CONFIRM":
      return [...state, { obj: action.obj }];
    case "HIDE_CONFIRM":
      return state.filter((confirm) => {
        //console.log('confirm/action', confirm, action);
        return confirm.obj.id !== action.id;
      });
    default:
      return state;
  }
};

export default confirmReducer;
