const strArgs = {};strArgs['organizer'] = `($name: String, $province: String, $city: String, $email: String, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          organizers  (name: $name, province: $province, city: $city, email: $email, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders)`;
strArgs['customeraddress'] = `($organizer_id: String, $id: ID, $_qlType: String, $_filter: String) {
          customeraddresss  (organizer_id: $organizer_id, id: $id, _qlType: $_qlType, _filter: $_filter)`;
strArgs['person'] = `($organizer_id: String, $lastname: String, $firstname: String, $addresses_city_id: ID, $inactive: Boolean, $id: ID, $name: String, $_qlType: String, $_filter: String, $_orders: String) {
          persons  (organizer_id: $organizer_id, lastname: $lastname, firstname: $firstname, addresses_city_id: $addresses_city_id, inactive: $inactive, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _orders: $_orders)`;
strArgs['customer'] = `($organizer_id: String, $numbering: String, $lastname: String, $firstname: String, $customerstatus_id: String, $addresses_city_id: ID, $inactive: Boolean, $nosharedata: Boolean, $nonewsletter: Boolean, $id: ID, $name: String, $_qlType: String, $_filter: String, $_orders: String) {
          customers  (organizer_id: $organizer_id, numbering: $numbering, lastname: $lastname, firstname: $firstname, customerstatus_id: $customerstatus_id, addresses_city_id: $addresses_city_id, inactive: $inactive, nosharedata: $nosharedata, nonewsletter: $nonewsletter, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _orders: $_orders)`;
strArgs['tour'] = `($organizer_id: String, $name: String, $_multidate: String, $_multidate1: String, $_multidate2: String, $_multidateField: String, $inactive: Boolean, $id: ID, $_qlType: String, $_filter: String) {
          tours  (organizer_id: $organizer_id, name: $name, _multidate: $_multidate, _multidate1: $_multidate1, _multidate2: $_multidate2, _multidateField: $_multidateField, inactive: $inactive, id: $id, _qlType: $_qlType, _filter: $_filter)`;
strArgs['tourcategory'] = `($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          tourcategorys  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter)`;
strArgs['transportation'] = `($organizer_id: String, $tour_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          transportations  (organizer_id: $organizer_id, tour_id: $tour_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter)`;
strArgs['tourprice'] = `($organizer_id: String, $tour_id: String, $id: ID, $_qlType: String, $_filter: String) {
          tourprices  (organizer_id: $organizer_id, tour_id: $tour_id, id: $id, _qlType: $_qlType, _filter: $_filter)`;
strArgs['tourroom'] = `($organizer_id: String, $tour_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String, $_orders: String) {
          tourrooms  (organizer_id: $organizer_id, tour_id: $tour_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _orders: $_orders)`;
strArgs['regonline'] = `($organizer_id: String, $tour_id: ID, $state: Int, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          regonlines  (organizer_id: $organizer_id, tour_id: $tour_id, state: $state, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders)`;
strArgs['registration'] = `($organizer_id: String, $numbering: String, $customer_id: ID, $tour_id: ID, $transportation_transportation_id: String, $tourroom_id: String, $registrationstatus_id_statusbase: Int, $customerstatus_id: String, $customersubstatus_id: String, $confirmationstatus_id_statusbase: Int, $_balance: String, $assignedroom: Boolean, $_channel: String, $_withmobile: String, $inactive: Boolean, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          registrations  (organizer_id: $organizer_id, numbering: $numbering, customer_id: $customer_id, tour_id: $tour_id, transportation_transportation_id: $transportation_transportation_id, tourroom_id: $tourroom_id, registrationstatus_id_statusbase: $registrationstatus_id_statusbase, customerstatus_id: $customerstatus_id, customersubstatus_id: $customersubstatus_id, confirmationstatus_id_statusbase: $confirmationstatus_id_statusbase, _balance: $_balance, assignedroom: $assignedroom, _channel: $_channel, _withmobile: $_withmobile, inactive: $inactive, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders)`;
strArgs['customerhistory'] = `($organizer_id: String, $customer_id: String, $id: ID, $_qlType: String, $_filter: String) {
          customerhistorys  (organizer_id: $organizer_id, customer_id: $customer_id, id: $id, _qlType: $_qlType, _filter: $_filter)`;
strArgs['paymenthistory'] = `($organizer_id: String, $payment_id: String, $id: ID, $_qlType: String, $_filter: String) {
          paymenthistorys  (organizer_id: $organizer_id, payment_id: $payment_id, id: $id, _qlType: $_qlType, _filter: $_filter)`;
strArgs['registrationhistory'] = `($organizer_id: String, $registration_id: String, $id: ID, $_qlType: String, $_filter: String) {
          registrationhistorys  (organizer_id: $organizer_id, registration_id: $registration_id, id: $id, _qlType: $_qlType, _filter: $_filter)`;
strArgs['payment'] = `($check_number: String, $organizer_id: String, $application_customer_id: String, $application_tour_id: String, $paymentdocstatus_id: String, $paymentmethod_id: String, $paymentmethod_id_ajournalcode_id: String, $_balanceinit: Float, $name: String, $amount: Float, $_depositedcheck: Int, $_multidate: String, $_multidate1: String, $_multidate2: String, $_multidateField: String, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          payments  (check_number: $check_number, organizer_id: $organizer_id, application_customer_id: $application_customer_id, application_tour_id: $application_tour_id, paymentdocstatus_id: $paymentdocstatus_id, paymentmethod_id: $paymentmethod_id, paymentmethod_id_ajournalcode_id: $paymentmethod_id_ajournalcode_id, _balanceinit: $_balanceinit, name: $name, amount: $amount, _depositedcheck: $_depositedcheck, _multidate: $_multidate, _multidate1: $_multidate1, _multidate2: $_multidate2, _multidateField: $_multidateField, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders)`;
strArgs['checkdeposithead'] = `($organizer_id: String, $name: String, $id: ID, $_qlType: String, $_filter: String) {
          checkdepositheads  (organizer_id: $organizer_id, name: $name, id: $id, _qlType: $_qlType, _filter: $_filter)`;
strArgs['checkdepositdet'] = `($organizer_id: String, $checkdeposithead_id: String, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          checkdepositdets  (organizer_id: $organizer_id, checkdeposithead_id: $checkdeposithead_id, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders)`;
strArgs['export'] = `($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          exports  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter)`;
strArgs['hotel'] = `($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          hotels  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter)`;
strArgs['stopspoint'] = `($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          stopspoints  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter)`;
strArgs['service'] = `($organizer_id: String, $name: String, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          services  (organizer_id: $organizer_id, name: $name, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders)`;
strArgs['city'] = `($organizer_id: String, $name: String, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          citys  (organizer_id: $organizer_id, name: $name, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders)`;
strArgs['registrationstatus'] = `($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          registrationstatuss  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter)`;
strArgs['confirmationstatus'] = `($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          confirmationstatuss  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter)`;
strArgs['bank'] = `($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          banks  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter)`;
strArgs['paymentmethod'] = `($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          paymentmethods  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter)`;
strArgs['paymentdocstatus'] = `($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          paymentdocstatuss  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter)`;
strArgs['customerstatus'] = `($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String, $_orders: String) {
          customerstatuss  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _orders: $_orders)`;
strArgs['gendertype'] = `($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          gendertypes  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter)`;
strArgs['userlog'] = `($organizer_id: String, $user_id: String, $action: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          userlogs  (organizer_id: $organizer_id, user_id: $user_id, action: $action, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter)`;
strArgs['user'] = `($organizer_id: String, $nick: String, $longname: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          users  (organizer_id: $organizer_id, nick: $nick, longname: $longname, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter)`;
strArgs['usergroup'] = `($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String) {
          usergroups  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter)`;
strArgs['ejobmail'] = `($organizer_id: String, $title: String, $customer_id: String, $tour_id: ID, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          ejobmails  (organizer_id: $organizer_id, title: $title, customer_id: $customer_id, tour_id: $tour_id, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders)`;
strArgs['etemplate'] = `($organizer_id: String, $name: String, $title: String, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          etemplates  (organizer_id: $organizer_id, name: $name, title: $title, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders)`;
strArgs['ejobday'] = `($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String, $_orders: String) {
          ejobdays  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _orders: $_orders)`;
strArgs['ejobmonth'] = `($organizer_id: String, $id: ID, $name: String, $_qlType: String, $_filter: String, $_orders: String) {
          ejobmonths  (organizer_id: $organizer_id, id: $id, name: $name, _qlType: $_qlType, _filter: $_filter, _orders: $_orders)`;
strArgs['aplan'] = `($organizer_id: String, $name: String, $title: String, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          aplans  (organizer_id: $organizer_id, name: $name, title: $title, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders)`;
strArgs['acostplan'] = `($organizer_id: String, $name: String, $title: String, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          acostplans  (organizer_id: $organizer_id, name: $name, title: $title, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders)`;
strArgs['ajournalcode'] = `($organizer_id: String, $name: String, $title: String, $id: ID, $_qlType: String, $_filter: String, $_orders: String) {
          ajournalcodes  (organizer_id: $organizer_id, name: $name, title: $title, id: $id, _qlType: $_qlType, _filter: $_filter, _orders: $_orders)`;

export default strArgs;