import { toPrice, getInputValue, getToday } from "../../utils/commonutils";

const crudCode = {};

crudCode.onChangeInput = async (params) => {
  const log = false;
  if (log) console.log("onChangeInput params:", params);
  const {
    nameForm,
    popup,
    tableCrud,
    inputFullName,
    line,
    action,
    parentField,
    event,
    newValue,
    previousValue,
    props,
    formProps,
    fns,
  } = params;

  const result = {};
  let statusbase;

  if (inputFullName === "statusbase") {
    statusbase = newValue;
    let statusbasePrevious = getInputValue(formProps, "statusbase", {
      nameForm,
    });
    if (statusbasePrevious !== newValue) {
      let usedatedeferred = false;
      if (statusbase && statusbase.toString() === "2") {
        // Check, default mark true usedatedeferred
        usedatedeferred = true;
      }
      formProps.change("usedatedeferred", usedatedeferred);
    }
  } else if (action !== "initForm") {
  }

  return result;
};

export default crudCode;
