


import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  graphql,
  compose,
} from 'react-apollo';
import { loader } from "graphql.macro";
import debounce from 'lodash/debounce';



import { Field,  reduxForm, change } from 'redux-form';

import ShowIcon from '../icons/ShowIcon';
import { gqlActions } from '../../gqls/gqlactions';
import ListForm from './PaymentListForm';
import defQls from '../../defQls';
import { submitValues, processError , errorTranslate, checkLoadCrud, cleanFilters, getFilters, getListviewFields } from '../../utils/helper';
import { showConfirm, hideConfirm } from "../../actions/confirmActions";
import { Tables } from '../../defTables';
import PageHeader from '../PageHeader';
import CrudToolBar from '../CrudToolBar';
import { CrudNav } from '../CrudNav';
import CrudFilterOrderBar from '../CrudFilterOrderBar';
import IconLoading from '../icons/IconLoading';
import { appSubmitStart, appSubmitStop } from '../../actions/appActions';
import { MsgError, ListDataRercord, ListDataHeader, PageSettings } from '../ListData';
import { getInputValue, resolvePathObj } from "../../utils/commonutils";
import { fetchQl } from '../../apolloClient';


import crudCode from './crudCode';
import filterCode from './filterCode';

const templates = [];

const QUERY = loader("./PaymentListPage.query.gql");

const tableCrud = 'payment';
const action ='list';
const nameForm = '';
const qlName = 'crud_list_'+tableCrud;
const formFilterName="listFilterpayment";
const formPagerName="paymentPager";
let table = Tables[tableCrud];

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      crudTable: tableCrud,
      mainForm: formFilterName,
      listMode: 'rows',
      counter: 0,
      dialogOpened: {},
      date: Date.now,
      actionsave: 'list',
      parentid: this.props.match.params && this.props.match.params.parentid ? this.props.match.params.parentid : '',
      id: '',
      listviewFields: {},
      pagesSettings: [],
      pageSettingId: null,
      visibleListViewFields: false,      
      
    };
  }

  componentDidMount() {
   
   
   
   
   
    
    const intervalId = setInterval(this.timer, 4000);
    this.setState({ intervalId });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps &&
      prevProps[qlName] &&
      prevProps[qlName].loading === true &&
      this.props &&
      this.props[qlName] &&
      this.props[qlName].loading === false &&
      this.props[qlName].getPagesSetting
    ) {
     
      const { listviewFields, pagesSettings, pageSettingId } =
        getListviewFields(
          this.props[qlName],
          this.state,
        );
      if (listviewFields) {
        this.setState({
          listviewFields,
          pagesSettings,
          pageSettingId,
        });
      }
    }
  }

  onChangePageSettingId = async (e) => {
    const pageSettingId = e.target.value;
    this.setState({
      pageSettingId,
    });
    const pagesSettings = this.state.pagesSettings;
    for (let r = 0; r < pagesSettings.length; r++) {
      if (pagesSettings[r].id === pageSettingId) {
       
       
        const listFieldsClone = JSON.parse(
          JSON.stringify(pagesSettings[r].fields)
        );
        this.updatePageSettingsFields(listFieldsClone);
      }
    }
  };
  updatePageSetting = (pageSettingId, action, values = {}) => {
   
    const pagesSettings = this.state.pagesSettings;
   
    if (action === "delete" || action === "afterSaveUpdate") {
      let rowFound = -1;
      for (let r = 0; r < pagesSettings.length; r++) {
        if (pagesSettings[r].id === pageSettingId) {
          rowFound = r;
          break;
        }
      }
      if (action === "delete") {
        pagesSettings.splice(rowFound, 1);
        this.setState({
          pagesSettings,
          pageSettingId: pagesSettings[0].id,
        });
       
        const listviewFieldsNew = JSON.parse(
          JSON.stringify(pagesSettings[0].fields)
        );
        this.updatePageSettingsFields(listviewFieldsNew);
      }
      if (action === "afterSaveUpdate") {
        pagesSettings[rowFound].fields = values.fields;
        this.setState({ pagesSettings });
      }
    }

    if (action === "addInit") {
      pagesSettings.push(values);
      this.setState({ pagesSettings, pageSettingId: "" });
    }
    if (action === "cancelAdd") {
      pagesSettings.splice(pagesSettings.length - 1, 1);
     
      const listviewFieldsNew = JSON.parse(
        JSON.stringify(pagesSettings[0].fields)
      );
      this.setState({
        pagesSettings,
        pageSettingId: "",
        listviewFields: listviewFieldsNew,
      });
    }

    if (action === "addAfterSave") {
     
      const pageRow = pagesSettings.length - 1;
      pagesSettings[pageRow].id = values.id;
      pagesSettings[pageRow].name = values.name;
      pagesSettings[pageRow].fields = values.fields;
      pagesSettings.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );

      this.setState({ pagesSettings, pageSettingId: values.id });
    }
  };

  updatePageSettingsFields = (e, isAdding = false) => {
    if (e.target) {
      let listviewFields = this.state.listviewFields;
      listviewFields[e.target.name].invisible = this.state.listviewFields[
        e.target.name
      ].invisible
        ? false
        : true;
      this.setState({
        listviewFields,
      });
    } else {
      this.setState({
        listviewFields: e,
      });
    }
   
    this.props.dispatch(change(formPagerName, "_timer", 1));
  };
  
  visibleFieldsToggle = () => {
    this.setState({
      visibleListViewFields: !this.state.visibleListViewFields,
    });
  };

  componentWillUnmount() {
   
    clearInterval(this.state.intervalId);
  }

  timer = () => {
    if (localStorage.getItem('Error') !== null) {
      this.setState({ date: Date.now() });
      clearInterval(this.state.intervalId);
    }
    if (Tables[tableCrud].pollInterval) {
      this.props[qlName].refetch();
    }
  };

  toolbarFunctions = ( toolbar, params ={} ) => {

  };

  
  hocdebounced = debounce(( methodCode, params)=> {
    this.executeCode( methodCode, params);
  }, 1500);

  executeCode = async ( methodCode ='', params = {} ) => {

   
    params.tableCrud = tableCrud ;
    params.formState = this.state;

   
   
    if (methodCode === 'onChangeInput' && params.action !== 'initForm') {
      const { inputFullName, line, action, event, newValue } = params;
      const oldValue = getInputValue( this.props, inputFullName, {nameForm: formFilterName });
      if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
        this.props.dispatch(change(formPagerName, '_page', 1));
      }
    }


    if (!crudCode[methodCode]) {
      return;
    }

    const result = await crudCode[methodCode](params);

    
    if (result && result.changeFieldsLater) {
      
      Object.keys(result.changeFieldsLater).map( (keyName, keyIndex) => {
        let valueField = result.changeFieldsLater[keyName];
        params.formProps.change(keyName, valueField);
      });
      
    }
    

    if (result && result.newStates) {
     
     
      let formStateChanged = false;
      let currentState = this.state;
      Object.keys(result.newStates).map( (keyName, keyIndex) => {
        if (typeof this.state[keyName] === 'undefined'
          || JSON.stringify(this.state[keyName]) !== JSON.stringify(result.newStates[keyName])) {
         
         
 
          let keyNewState = result.newStates[keyName];
         
         
          if (keyName === 'warningFields') { 
            keyNewState = { ...this.state[keyName], ...result.newStates[keyName]};
          }
          this.setState({ [keyName]: keyNewState});
          currentState[keyName] = result.newStates[keyName];
          formStateChanged = true;
        }
  		});
  		if (formStateChanged) {
  		 
       
        this.props.dispatch(change(nameForm, '_formstate', JSON.stringify(currentState)));
      }
    }
   
    if (result && typeof result.valueToReturn !=='undefined' ) {
     
      return result.valueToReturn;
    }
  };
  


  handleSubmit = async (values) => {
    await gqlActions('', 'setCheckDepositedDet', this.props, values)
      .then((response)=> {
      })
      .catch((err) => {
        processError(err, this.props, true);
      });
  };



  navPage= (navTo, page) => {
    let newPage = parseInt ('0'+page._page);
    if (navTo === 'right' && page._page < page._totalpages) {
      newPage++;
    }
    if (navTo === 'left' && page._page > 1) {
      newPage--;
    }
    if (navTo === 'first') {
      newPage= 1;
    }
    if (navTo === 'last') {
      newPage= page._totalpages;
    }
    let strNewpage = parseInt('0'+ newPage.toString());
    this.props.change('_page', strNewpage) ;
  };

  render() {
    const { t, ...otherProps} = this.props;
    let aElements = [];
    let aQlFiltered = {"crud_list_payment":{"table":"payment"},"list_tour":{"table":"tour"},"list_paymentmethod":{"table":"paymentmethod"},"list_paymentdocstatus":{"table":"paymentdocstatus"}}
    const resultCheck = checkLoadCrud (aQlFiltered,this.props);
    if (resultCheck.messageError) {
      return <MsgError msg={resultCheck.messageError} t={this.props.t} />;
    }
    aElements.push(<PageHeader key="pageheader" action={action} t={t} tableCrud={tableCrud} tables={Tables} />);

    aElements.push(<CrudToolBar
      containerPropsForm={this.props}
      toolbarFunctions={this.toolbarFunctions}
      handleSubmit={this.handleSubmit}
      handleDeleteRecord={this.handleDeleteRecord}
      t={this.props.t}
      tableCrud={tableCrud}
      containerState={this.state}
      nameCrudForm={nameForm}
      nameFilterForm={formFilterName}
      tables={Tables}
      key="crudToolBar1"
      crudCode={crudCode}
      executeCode={this.executeCode}
    />);
   
    aElements.push(<CrudFilterOrderBar
      containerPropsForm={this.props}
      toolbarFunctions={this.toolbarFunctions}
      handleSubmit={this.handleSubmit}
      handleDeleteRecord={this.handleDeleteRecord}
      t={this.props.t}
      tableCrud={tableCrud}
      containerState={this.state}
      nameCrudForm={nameForm}
      nameFilterForm={formFilterName}
      tables={Tables}
      key="crudFilter"
      executeCode={this.executeCode}
      templates={templates}
    />);

    let result;
    let recordsLoaded;

    if ( !resultCheck.globalLoading && !resultCheck.messageError
      && this.props[qlName] && this.props[qlName].pagepayments) {
     
      recordsLoaded = true;
      result = this.props[qlName].pagepayments;
    }

    aElements.push(
      <form key="listForm">
        <Field
          key="_page"
          name="_page"
          component="input"
          type="hidden"
        />
      </form>);

    if (resultCheck.globalLoading && !resultCheck.messageError) {
      aElements.push(<IconLoading key="load" />);
    }

    if (recordsLoaded ) {
      aElements.push(<CrudNav key="crudnav1" t={t} page={result.page} navPage={this.navPage} />);
    
        aElements.push(
          <PageSettings
            updatePageSetting={this.updatePageSetting}
            key="PageSettings"
            t={t}
            showNotificationWithTimeout={this.props.showNotificationWithTimeout}
            deletePagesetting={this.props.deletePagesetting}
            showConfirm={this.props.showConfirm}
            pagesSettings={this.state.pagesSettings}
            isVisiblePageSettings={this.state.visibleListViewFields}
            templates={templates}
            updatePageSettingsFields={this.updatePageSettingsFields}
            table={Tables[tableCrud]}
            listviewFields={this.state.listviewFields}
            tableCrud={tableCrud}
            visibleFieldsToggle={this.visibleFieldsToggle}
            pageSettingId={this.state.pageSettingId}
            onChangePageSettingId={this.onChangePageSettingId}
          />
      );
      aElements.push(<ListForm
        crudAction="View"
        key="mainform"
        result={result}
        id={ this.props.match.params.id }
        myState={this.props.myState}
        settings={this.state}
        onSubmit={this.handleSubmit}
        handleSubmit={this.handleSubmit}
        containerPropsForm={this.props}
        toolbarFunctions={this.toolbarFunctions}
        t={this.props.t}
      />);


      if (result.page._totalpages > 1) {
        aElements.push(<CrudNav key="crudnav2" t={t} page={result.page} navPage={this.navPage} />);
      } else {
        aElements.push(<div className="xrow" key="rfound" >
          <div className="cell" ></div>
          <div className="cell" style= { {color:'#999999', paddingTop: '20px' } }>
            {result.page._totalrecords} {t('info.recordsFound')}
          </div>
        </div>);
      }
    }

    return (
      <div>
        {aElements}
      </div>);
  }
}


const ComponentWithData = reduxForm({
  form: formPagerName,
  enableReinitialize: true,
})(List);


const withRouterList = withRouter(ComponentWithData );
const withGraphqlandRouter = compose(
  
   graphql(QUERY, {
    name: 'crud_list_payment',
    options: props => {
      let filters = getFilters(tableCrud, props, formPagerName, formFilterName);
      if (props.myState.app.org) {
         filters['organizer_id'] = props.myState.app.org;
      } else {
         filters['organizer_id'] = props.myState.app.org;
      }
      const optionsValues = { variables: {...filters, _qlType: 'ListPage' } };
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy ? Tables[tableCrud].fetchPolicy :'network-only';
      return optionsValues;
    },
    skip: props => {
      return !(props.myState
        && props.myState.form
        && props.myState.form[formFilterName]
        && props.myState.form[tableCrud+'Pager']
        && props.myState.form[formFilterName].values
        && props.myState.form[tableCrud+'Pager'].values);
    }
  }),
  graphql(defQls.tour.ListMini, {
    name: 'list_tour',
    options: props => {
      const optionsValues = {} ;
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy ? Tables[tableCrud].fetchPolicy :'network-only';
      optionsValues.variables= { _qlType: 'ListMini', organizer_id: props.myState.app.org };
      return optionsValues;
    },
  }),
  graphql(defQls.aplan.ListMini, {
    name: 'list_aplan',
    options: props => {
      const optionsValues = {} ;
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy ? Tables[tableCrud].fetchPolicy :'network-only';
      optionsValues.variables= { _qlType: 'ListMini', organizer_id: props.myState.app.org };
      return optionsValues;
    },
  }),
  graphql(defQls.paymentmethod.ListMini, {
    name: 'list_paymentmethod',
    options: props => {
      const optionsValues = {} ;
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy ? Tables[tableCrud].fetchPolicy :'network-only';
      optionsValues.variables= { _qlType: 'ListMini', organizer_id: props.myState.app.org };
      return optionsValues;
    },
  }),
  graphql(defQls.checkdeposithead.ListMini, {
    name: 'list_checkdeposithead',
    options: props => {
      const optionsValues = {} ;
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy ? Tables[tableCrud].fetchPolicy :'network-only';
      optionsValues.variables= { _qlType: 'ListMini', organizer_id: props.myState.app.org };
      return optionsValues;
    },
  }),
  graphql(defQls.paymentdocstatus.ListMini, {
    name: 'list_paymentdocstatus',
    options: props => {
      const optionsValues = {} ;
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy ? Tables[tableCrud].fetchPolicy :'network-only';
      optionsValues.variables= { _qlType: 'ListMini', organizer_id: props.myState.app.org };
      return optionsValues;
    },
  }),
  graphql(defQls.organizer.ListMini, {
    name: 'list_organizer',
    options: props => {
      const optionsValues = {} ;
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy ? Tables[tableCrud].fetchPolicy :'network-only';
      optionsValues.variables= { _qlType: 'ListMini', organizer_id: props.myState.app.org };
      return optionsValues;
    },
  }),
  graphql(defQls.ajournalcode.ListMini, {
    name: 'list_ajournalcode',
    options: props => {
      const optionsValues = {} ;
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy ? Tables[tableCrud].fetchPolicy :'network-only';
      optionsValues.variables= { _qlType: 'ListMini', organizer_id: props.myState.app.org };
      return optionsValues;
    },
  }),
 
 
 
  graphql(defQls.checkdepositdet.ListPage, {
    name: 'crud_list_checkdepositdet',
    options: props => {
      let log = false;
      if (log) console.log('formFilterName',formFilterName);
      if (log) console.log('querydata',props.myState);


     
      let checkdeposit = resolvePathObj(props.myState, 'app.appSettings.checkdeposit');
      let checkdeposit_id = typeof checkdeposit === 'undefined' ? null : checkdeposit;
     

      let filters = {};
      
      let table = Tables[tableCrud];
      filters._page = 1;
      filters.checkdeposithead_id = checkdeposit_id;

      if (log) console.log('final filters', filters);

      const optionsValues = { variables: {...filters, _qlType: 'ListPage' } };
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy ? Tables[tableCrud].fetchPolicy :'network-only';
      return optionsValues;
    },
    skip: props => {
      return !(props.myState
         && props.myState.app
         && props.myState.app.appSettings
         && props.myState.app.appSettings.checkdeposit);
    }
  }),
 
  graphql(defQls.setCheckDepositedDet, {
    name: 'setCheckDepositedDet',
    options: props => {
      const optionsValues = { variables: { } };
      optionsValues.fetchPolicy = 'network-only';
      return optionsValues ;
    },
  }),
  graphql(defQls.deletePagesetting, {
    name: "deletePagesetting",
    options: (props) => {
      const optionsValues = { variables: { id: props.id } };
      optionsValues.fetchPolicy = "no-cache";
      return optionsValues;
    },
  }),
)(withRouterList);

function mapStateToProps(state, ownProps) {
  let initialValues = { _page: 1};
  const statesReturn = { myState: state };
  if (state &&  state.form  &&  state.form[formPagerName]  && typeof state.form[formPagerName].values ) {
    statesReturn.initialValues = initialValues;
  }
  return statesReturn;
}


const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { change, showConfirm, hideConfirm, dispatch, appSubmitStart, appSubmitStop},
    dispatch
  );
};

const ComponentWithDataAndState = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withGraphqlandRouter );

export default ComponentWithDataAndState;
