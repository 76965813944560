import React from "react";
import { useState } from "react";
import ShowIcon from "../icons/ShowIcon";
import { fetchQl } from "../../apolloClient";
// all is procesed on server

const IconSave = (props) => {
  const { t, isAdding, nameInputVue, handleSaveView, label } = props;
  return (
    <div style={{ margin: "0px 10px 0px 0px" }}>
      <a
        key="save3"
        title={t("form.save")}
        onClick={async (e) =>
          isAdding && (!nameInputVue || nameInputVue.trim() === "")
            ? () => {}
            : await handleSaveView()
        }
      >
        <ShowIcon
          size="20"
          color={
            nameInputVue.trim() === "" && isAdding ? "#cecece" : "limegreen"
          }
          icon="markercheck"
        />
        {label && <span>{label}</span>}
      </a>
    </div>
  );
};
export const ColsExport = (props) => {
  let {
    updatePageSettingsFields,
    // settings: { listviewFields },
    t,
    pagesSettings,
    listviewFields,
    onChangePageSettingId,
    deletePagesetting,
    pageSettingId,
    updatePageSetting,
    showConfirm,
    showNotificationWithTimeout,
    tableCrud,
  } = props;

  const title = tableCrud.split("-")[0].toLowerCase();

  const pageCurrent = pageSettingId
    ? pagesSettings.find((pageS) => pageS.id === pageSettingId)
    : { name: "" };

  const [nameInputVue, setNameInputVue] = useState("");
  const [isAdding, setIsAdding] = useState(false);

  const handleCancelAdd = () => {
    // remove last item
    updatePageSetting(pageSettingId, "cancelAdd");
    setNameInputVue("");
    setIsAdding(false);
  };

  const addPageSetting = async () => {
    setIsAdding(true);

    const listviewFieldsClone = JSON.parse(JSON.stringify(listviewFields));
    updatePageSetting(pageSettingId, "addInit", {
      id: "",
      name: "",
      fields: listviewFieldsClone,
    });

    // onChangePageSettingId({ target: { value: pageSettingId } }); // change parent state combobox value
  };
  const onChangeLocalPageSettingId = async (e) => {
    const pageSettingId = e.target.value;
    onChangePageSettingId({ target: { value: pageSettingId } }); // change parent state combobox value

    try {
      // dont work if null or empty values is sent, just add when variables has values
      // dont add id for case for default pagesettings ( is not adding but have empty pageSettingId )
      let aParams = [
        { name: "page_name", type: "String!", value: tableCrud },
        {
          name: "fields",
          type: "String",
          // no change fields settings, just it's change default pageid,
          // can be pageid ='' when is default not saved with id
          value: "*justChangePageId",
        },
      ];
      if (pageSettingId) {
        aParams.push({
          name: "id",
          type: "ID",
          value: pageSettingId,
        });
      }

      await fetchQl(
        `
        mutation setPagesetting ($id: ID, $name: String, $page_name: String!, $fields: String) {
          setPagesetting  (id: $id, name: $name, page_name: $page_name, fields: $fields) {
            id
          }
        }`,
        aParams,
        {}
      );
    } catch (err) {
      console.log("err", err);
    }
  };

  const deletePageSettingsAction = async (e) => {
    const response = await deletePagesetting({
      variables: { id: pageSettingId },
      fetchPolicy: "no-cache",
    });
    updatePageSetting(pageSettingId, "delete");
    showNotificationWithTimeout(props.t("actionLOG.deleted"), false);
  };
  const handleSaveView = async (e) => {
    const aFieldsToSave = [];
    for (const [keyName, field] of Object.entries(listviewFields)) {
      aFieldsToSave.push({
        name: field.name,
        invisible: field.invisible ? true : false,
      });
    }
    try {
      let aParams = [
        { name: "page_name", type: "String!", value: tableCrud },
        {
          name: "fields",
          type: "String",
          value: JSON.stringify(aFieldsToSave),
        },
      ];
      // dont work if null or empty values is sent, just add when variables has values
      // dont add id for case for default pagesettings ( is not adding but have empty pageSettingId )
      if (!isAdding && pageSettingId) {
        // asterix means new pagesettings, then pageSettingId is really empty
        aParams.push({
          name: "id",
          type: "ID",
          value: pageSettingId,
        });
      }
      if (nameInputVue) {
        aParams.push({
          name: "name",
          type: "String",
          value: nameInputVue,
        });
      }
      const resCheck = await fetchQl(
        `
      mutation setPagesetting ($id: ID, $name: String, $page_name: String!, $fields: String) {
        setPagesetting  (id: $id, name: $name, page_name: $page_name, fields: $fields) {
          id
        }
      }`,
        aParams,
        {}
      );

      /// when isAdding, id has values, when is not adding can be too 'default pagesettings' (id and name empty)
      updatePageSetting(
        pageSettingId,
        pageSettingId ? "afterSaveUpdate" : "addAfterSave",
        {
          id: resCheck.data ? resCheck.data.setPagesetting.id : "",
          name: nameInputVue,
          fields: aFieldsToSave,
        }
      );

      showNotificationWithTimeout(props.t("alert.datasaved"), false);
      setIsAdding(false);
      setNameInputVue("");
    } catch (err) {
      console.log("err", err);
    }

    return;

    // const result = await this.props.dispatch(submit(this.props.nameCrudForm)); // modern way to submit
  };

  // let listviewFields = props.defColsPrint;
  let aFields = [];

  for (let [keyName, fieldSet] of Object.entries(listviewFields)) {
    keyName = `${fieldSet.table}.${fieldSet.field}`;
    // search field setting from pagesettings
    const viewFieldDb = listviewFields.find(
      (viewField) => fieldSet.name === viewField.name
    );
    let enter = true;

    if (fieldSet.excludeForm) {
      enter = false;
    }

    if (enter) {
      let invisible = fieldSet.invisible;

      let titleFieldFull = props.t(fieldSet.header);
      if (!fieldSet.descriptionTableDisabled) {
        titleFieldFull += " - " + props.t("table." + fieldSet.table);
      }
      if (fieldSet.descriptionGroupNameActive) {
        titleFieldFull = props.t(fieldSet.groupName) + " - " + titleFieldFull;
      }

      aFields.push(
        <div
          key={fieldSet.name}
          style={{
            fontSize: "11px",
            minWidth: "100px",
            maxWidtht: "150px",
            marginBottom: "4px",
          }}
        >
          <input
            type="checkbox"
            name={fieldSet.name}
            style={{ fontSize: "14px" }}
            checked={invisible ? false : true}
            onChange={(e) => updatePageSettingsFields(e)}
          />
          <label style={{ fontSize: "14px" }}>{titleFieldFull}</label>
        </div>
      );
    }
  }
  const listProccesed = [];
  let firstDefaulViewAdded = false;
  // pagesSettings comes already prepared and filled, same for default pagesettings that not exist on db
  for (let r = 0; r < pagesSettings.length; r++) {
    let pageSetting = pagesSettings[r];
    if (!(r === 0 && firstDefaulViewAdded && pageSetting.name === "")) {
      listProccesed.push({
        id: pageSetting.id,
        name: pageSetting.name ? pageSetting.name : t("form.bydefault"),
      });
    }
  }

  return (
    <div
      style={{
        marginTop: "30px",
        width: "100%",
      }}
    >
      <div style={{ fontSize: "22px", color: "#333333", marginBottom: "10px" }}>
        {props.t("info.exportfields")} ( {props.t("table." + title)} )
      </div>
      <div>
        <div style={{ color: isAdding ? "limegreen" : "#666666" }}>
          {t(isAdding ? "form.newviewlist" : "form.viewlist")}
          {": "}
        </div>
        <div style={{ display: "flex" }}>
          {!isAdding && (
            <div>
              <select
                key="lvn"
                autoComplete="new-password"
                className="formSelectBox"
                onChange={onChangeLocalPageSettingId}
                value={pageSettingId ? pageSettingId : ""}
              >
                {listProccesed.map((list) => (
                  <option key={list.id} value={list.id}>
                    {list.name}
                  </option>
                ))}
              </select>
            </div>
          )}

          {isAdding && (
            <div>
              {" "}
              <input
                autoComplete="new-password"
                className="formSelectBox"
                onChange={(e) => setNameInputVue(e.target.value)}
                value={nameInputVue}
              />
              <div
                className="formError"
                style={{ color: "#be0000", minHeight: "20px" }}
              >
                {nameInputVue.trim() === "" && (
                  <span>{t("validator.required")}</span>
                )}
              </div>
            </div>
          )}

          <div
            style={{ marginTop: "7px", display: "flex", marginLeft: "15px" }}
          >
            <IconSave
              handleSaveView={handleSaveView}
              t={t}
              isAdding={isAdding}
              nameInputVue={nameInputVue}
            />
            {isAdding && (
              <div>
                <a
                  key="save1"
                  title={t("info.vCancel")}
                  onClick={(e) => handleCancelAdd(e)}
                >
                  <ShowIcon size="20" icon="closebox" color="darkred" />
                </a>
              </div>
            )}
          </div>
          {!isAdding && (
            <div style={{ display: "flex" }}>
              <div style={{ margin: "5px 10px 0px 0px" }}>
                <a
                  key="save2"
                  title={t("info.vAdd")}
                  onClick={(e) => addPageSetting()}
                >
                  <ShowIcon size="22" color="#555555" icon="plusbox" />
                </a>
              </div>
              {!pageCurrent ||
                (pageCurrent.name !== "" && (
                  <div style={{ margin: "3px 0px 0px 0px" }}>
                    {" "}
                    <a
                      key="delete1"
                      title={t("info.vDelete")}
                      onClick={() => {
                        //if(window.confirm( props.t('form.deleteconfirm'))) {this.props.handleDeleteRecord()};
                        showConfirm({
                          id: "delpage",
                          type: "confirmation",
                          text: props.t("form.deleteviewconfirm"),
                          buttons: [
                            {
                              label: props.t("info.vDelete"),
                              onClick: () => deletePageSettingsAction(),
                            },
                            {
                              label: props.t("form.no"),
                              onClick: () => null,
                            },
                          ],
                        });
                      }}
                    >
                      <ShowIcon
                        size="25"
                        icon="deleteforever"
                        color="darkred"
                      />
                    </a>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          alignItems: "center",
          color: "#999999",
          paddingBottom: "10px",
          width: "100%",
        }}
      >
        <div>{aFields}</div>
        <IconSave
          handleSaveView={handleSaveView}
          t={t}
          isAdding={isAdding}
          nameInputVue={nameInputVue}
          label={t("form.save") + " " + t("form.viewlist")}
        />
      </div>
    </div>
  );
};
