import React from "react";
import { Link } from "react-router-dom";
import ShowIcon from "../icons/ShowIcon";

const RoomHeader = (props) => {
  let recordr = props.crud_view_tourroom.tourroom;
  let recordt = props.view_tour.tour;

  return (
    <div>
      <div className="formSectionData">
        <div className="formData" style={{ fontSize: "20px" }}>
          {recordt.name}/ {recordr.tourroomhotel_id.name}
        </div>

        <Link
          className="aToolBarExtend"
          to={`/tour-child/${recordt.id}/tourroom/`}
        >
          <ShowIcon size="30" color="gray" icon="eye" />
        </Link>

        <div className="formData" style={{ textAlign: "right" }}></div>
      </div>
      <div className="appBodyHeaderSeparator" />
    </div>
  );
};

export default RoomHeader;
