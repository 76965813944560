// www.mobilsem.com  - Copyright .src/components/payment/PaymentForm.js
import React, { Component } from "react";
import { Field, FieldArray, reduxForm } from "redux-form";
import { connect } from "react-redux";
import FormField from "../FormField";
import FormArrayField from "../FormArrayField";
import { Button } from "../../layouts/cssstyled";
import ShowIcon from "../icons/ShowIcon";
import IconLoading from "../icons/IconLoading";
import { appSubmitStart, appSubmitStop } from "../../actions/appActions";
import {
  fieldsValidator,
  getOptions,
  processNewValues,
} from "../../utils/helper";
import {
  resolvePathObj,
  getObjFromListById,
  getInputValue,
  realTypeOf,
} from "../../utils/commonutils";
import { MsgError, ListDataRercord, ListDataHeader } from "../ListData";

import { Tables } from "../../defTables";
import { normalizes } from "../../utils/normalizes";
import crudCode from "./crudCode";

const tableCrud = "payment";
const action = "list";
const qlName = "crud_list_" + tableCrud;
let table = Tables[tableCrud];
const nameForm = "ajournalList"; //distinct from payment
const validate = (values, props) => fieldsValidator("payment", values, props);

// case bank checks (maybe in the future for cash or another transactions)
// only show transactions are end (deposited)
const isShowCheckbox = (xrecord) => {
  let showCheckbox = true;
  // make sure xrecord.paymentpaymentdocstatus_id  is not null to avoid error ;  undefined of paymentpaymentdocstatus_id  = null
  // paymentpaymentmethod_id has only value, no probl
  if (
    xrecord.paymentpaymentmethod_id &&
    xrecord.paymentpaymentmethod_id.statusbase === 2 &&
    (!xrecord.paymentpaymentdocstatus_id ||
      xrecord.paymentpaymentdocstatus_id.statusbase !== 7)
  ) {
    showCheckbox = false;
  }
  return showCheckbox;
};

class Form extends Component {
  constructor(props) {
    super(props);
    // mix of states of crud list and crud view
    this.state = {
      crudAction: props.crudAction,
      crudTable: tableCrud,
      id: props.id ? props.id : "",
      disabledFields: [],
      hiddenFields: [],
      listMode: "rows",
      counter: 0,
      date: Date.now,
    };

    /*setTimeout(function() {
       this.executeCode ( 'onChangeInput', { action: 'initForm' , props: this.props, formProps: this.props, formState: this.state  });

    }.bind(this), 1000);
*/
  }

  handleClick = (e) => {
    if (this.props.myState.app.submitting) {
      e.preventDefault();
      ////console.error('submit twice');
      return;
    }
    //console.log('props', this.props.myState.form.paymentList.values);
    const seletedPayment = [];
    // twin CHB1209
    // check if line exist (input hidden exist for every line , after check if checkbox was touched)
    let l = 0;
    while (
      l <
      this.props.containerPropsForm.crud_list_payment.pagepayments.nodes.length
    ) {
      if (
        typeof this.props.myState.form[nameForm].values["itemcheck" + l] !==
        "undefined"
      ) {
        seletedPayment.push({
          id: this.props.myState.form[nameForm].values["itemid" + l],
          ok: this.props.myState.form[nameForm].values["itemcheck" + l],
        });
      }
      l++;
    }
    // console.log('seletedPayment', seletedPayment);
    let values = {
      payments: JSON.stringify(seletedPayment),
      source: "ajournal",
    };

    //console.log('seletedPayment', values);
    this.props.appSubmitStart();
    this.setState({ clicked: true });
    // only way to get refresh state and modifiy icon
    setTimeout(() => this.props.handleSubmit(values), 300);
  };

  executeCode = (methodCode = "", params = {}) => {
    //console.log('executeCode() methodCode:'+ methodCode + ', params:', params);
    if (!crudCode[methodCode]) {
      //do Nothing, silent, because can happens it's normal, crud have no definitions to actions , like onDisable,etc
      //alert ('error crudCode: '+methodCode+' not defined');
      return;
    }
    // important to getInputValue() or anothers where actions get complicated to pass values like this
    params.tableCrud = tableCrud;
    const result = crudCode[methodCode](params);
    if (result && result.newStates) {
      // update states

      //console.log ('result.newStates',result.newStates);
      Object.keys(result.newStates).map((keyName, keyIndex) => {
        //console.log('set state: '+keyName+' ,value: ',result.newStates[keyName]);
        this.setState({ [keyName]: result.newStates[keyName] });
      });
    }
    // check not for true, but undefined, false is a valid value to return
    if (result && typeof result.valueToReturn !== "undefined") {
      // update states

      return result.valueToReturn;
    }
  };

  checkAll = ({ target }) => {
    let marked = target.checked;
    const listRecords =
      this.props.containerPropsForm.crud_list_payment.pagepayments.nodes;
    let line = 0;
    listRecords.map((record) => {
      let checkitem = getInputValue(this.props, "itemid" + line); // dont check this, inexistent checkbox have value
      // let checkitem = getInputValue(this.props, 'itemcheck'+ line); // verify if checkbox input is created before to asign value
      //const balanceInit = getInputValue( this.props, '_balanceinit', {nameForm: 'listFilterjournal' });
      //'itemcheck'+props.line
      const showCheckbox = true;
      if (checkitem && showCheckbox)
        this.props.change("itemcheck" + line, marked);
      line++;
    });
  };

  render() {
    let result = this.props.result;

    // TWIN CHK209
    //console.log('myState.app.', this.props.myState.app);
    //console.log('inbank_paymentdocstatus_id',inbank_paymentdocstatus_id);

    //console.log(' state',this.state);
    const {
      error,
      handleSubmit,
      invalid,
      pristine,
      change,
      submitting,
      submitFailed,
      t,
      ...otherProps
    } = this.props;

    let aSubEle = [];

    if (!this.props.myState || !this.props.myState.app.submitting) {
      aSubEle.push(
        <div key="btnC" style={{ marginBottom: "15px" }}>
          <a
            key="save1"
            title={t("form.save")}
            onClick={(e) => this.handleClick(e)}
          >
            <ShowIcon size="35" color="#5995c3" icon="export" />
            {t("info.vExport")}
          </a>
        </div>
      );
    }

    if (this.props.myState && this.props.myState.app.submitting) {
      /* a wrapper to get same size button save */
      aSubEle.push(
        <a key="loading">
          <IconLoading size="35" />
        </a>
      );
    }

    let aMixSettings;
    if (this.props.settings) {
      aMixSettings = { ...this.state, ...this.props.settings };
    } else {
      aMixSettings = { ...this.props.settings };
    }

    aSubEle.push(
      <ListDataHeader
        key="list"
        myState={this.props.myState}
        settings={aMixSettings}
        templates={["ajournal"]}
        t={t}
        tableCrud={tableCrud}
        checkAll={this.checkAll}
        refresh={
          !Tables[tableCrud].pollInterval &&
          this.props.containerPropsForm[qlName]
            ? this.props.containerPropsForm[qlName].refetch
            : false
        }
      />
    );

    // check if field dols have value
    const balanceInit = getInputValue(this.props, "_balanceinit", {
      nameForm: "listFilterjournal",
    });
    // console.log('balanceInit', balanceInit);

    let soldeNew = parseFloat(balanceInit || 0);
    for (let r = 0; r < result.nodes.length; r++) {
      let xrecord = result.nodes[r];
      let nextrecord =
        table.listSendNextRecord && r + 1 < result.nodes.length
          ? result.nodes[r + 1]
          : {}; // send next record if exists
      let item = (result.page._page - 1) * result.page._recordsperpage + r + 1;

      // complete virtual fields , or modified fields
      let virtualFields = {};

      /*
      reformat application / color
       */
      let style;
      let showCheckbox = isShowCheckbox(xrecord);
      if (xrecord.application) {
        let appl = JSON.parse(xrecord.application);
        appl.map((ap, key) => {
          if (!ap.customer_id) {
            appl[key].customer_id = { id: "", name: t("form.nocustomer") };
            style = { color: "#777777" };
          }
        });
        virtualFields.application = [...appl];
        if (style) {
          virtualFields.applicationStyle = style;
        }
      } else {
        virtualFields.application = [];
      }
      if (xrecord.aentry) {
        JSON.parse(xrecord.aentry).map((line) => {
          virtualFields.application.push({
            aentry: true,
            aplan: line.aplan,
            tour_id: {
              id: "",
              name:
                line.aplan.name +
                " " +
                (line.atitle || "") +
                " " +
                (line.reference || ""),
            },
            amount: line.amount,
          });
        });
      }

      //console.log('xrecord', xrecord);
      const paymentmethod = getObjFromListById(
        this.props.containerPropsForm.list_paymentmethod.paymentmethods,
        xrecord.paymentmethod_id
      );

      if (paymentmethod && paymentmethod.ajournalcode_id) {
        const ajournalcode_id = paymentmethod.ajournalcode_id;
        const ajournalcode = getObjFromListById(
          this.props.containerPropsForm.list_ajournalcode.ajournalcodes,
          ajournalcode_id
        );
        if (ajournalcode && ajournalcode.name) {
          virtualFields.ajournalcode =
            ajournalcode.name +
            "-" +
            ajournalcode.title +
            " " +
            (ajournalcode.ajournalcodecounterpart_id &&
            ajournalcode.ajournalcodecounterpart_id.name
              ? ajournalcode.ajournalcodecounterpart_id.name
              : "");
        }
        virtualFields.adebit = [];
        virtualFields.acredit = [];
        virtualFields.abalance = [];
        virtualFields.aplan = [];
        if (virtualFields.application) {
          virtualFields.application.map((applica) => {
            if (applica.aentry) {
              virtualFields.aplan.push({ acode_id: applica.aplan.name });
            } else {
              if (applica.tour_id && applica.tour_id.id) {
                const atour = getObjFromListById(
                  this.props.containerPropsForm.list_tour.tours,
                  applica.tour_id.id
                );
                //console.log('atour', atour);
                const touracode = atour.touracode_id
                  ? atour.touracode_id.name
                  : "";
                virtualFields.aplan.push({ acode_id: touracode });
              }
            }
            //console.log('xrecord.paymentpaymentmethod', paymentmethod);
            //if (xrecord.paymentpaymentmethod)

            if (paymentmethod.anature === "D") {
              virtualFields.adebit.push({ amount: applica.amount });
              if (showCheckbox) soldeNew -= parseFloat(applica.amount || 0);
              // console.log('soldeNew', soldeNew, applica.amount);
              //console.log('debit', applica.amount );
            } else if (paymentmethod.anature === "C") {
              virtualFields.acredit.push({ amount: applica.amount });
              if (showCheckbox) soldeNew += parseFloat(applica.amount || 0);
              // console.log('soldeNew', soldeNew, applica.amount);
              //console.log('credit', applica.amount );
            } else {
              //console.error('paymentmethod no nature', paymentmethod);
            }
            if (balanceInit && showCheckbox) {
              virtualFields.abalance.push({ amount: soldeNew });
            }
          });
        }
      }
      let show = true;
      //moved to server, trought _template
      //if (xrecord.paymentpaymentdocstatus_id && xrecord.paymentpaymentdocstatus_id.statusbase === 11) show = false;
      if (show) {
        let record = { ...xrecord, ...virtualFields };
        aSubEle.push(
          <ListDataRercord
            key={record.id}
            templates={["ajournal"]}
            showCheckbox={showCheckbox}
            t={t}
            line={r}
            item={item}
            toolbarFunctions={this.toolbarFunctions}
            tableCrud={tableCrud}
            record={record}
            nextrecord={nextrecord}
            settings={aMixSettings}
            myState={this.props.myState}
          />
        );
      }
    }

    return (
      <form onSubmit={handleSubmit}>
        {aSubEle}
        <div>
          {error && <strong>{error}</strong>}

          <div className="formError">{invalid && t("form.haserrors")}</div>
        </div>
      </form>
    );
  }
}

const ComponentWithData = reduxForm({
  form: nameForm, // a unique identifier for this form
  // TWIN FR141 very important , the values that are loading and changing  after form is created
  // so need reload the last variables in form
  enableReinitialize: true,
  validate,
})(Form);

function mapStateToProps(state, ownProps) {
  // to view data , need to pass the values to redux-form trough initialValues
  // from graphl data , not important to 'add' action, there no values
  const statesReturn = { myState: state };
  let initialValues = {};

  /*
  import assign id to multiple checks
   */
  const listRecords =
    ownProps.containerPropsForm.crud_list_payment.pagepayments.nodes;
  let line = 0;
  listRecords.map((record) => {
    if (isShowCheckbox(record)) {
      initialValues["itemid" + line] = record.id;
    }
    line++;
  });

  statesReturn.initialValues = initialValues;
  return statesReturn;
}

const ComponentWithDataAndState = connect(mapStateToProps, {
  appSubmitStart,
  appSubmitStop,
})(ComponentWithData);

export default ComponentWithDataAndState;
