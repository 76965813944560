import React from "react";
import { Field, reduxForm, change } from "redux-form";
import { bindActionCreators } from "redux";

import { connect } from "react-redux";
import { resolvePathObj, getInputValue, getSMS } from "../../utils/commonutils";
import { appSubmitStart, appSubmitStop } from "../../actions/appActions";
import { showConfirm } from "../../actions/confirmActions";
import ShowIcon from "../icons/ShowIcon";

const nameForm = "MemberAddress";
class SubComponentInForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
  }

  showFormula = () => {
    this.props.executeCode("onChangeInput", {
      nameForm: "registrationForm",
      action: "calcule",
      formProps: this.props.formProps,
      formState: this.props.formState,
    });
    this.setState({ show: true });
  };

  hiddenFormula = () => {
    this.props.executeCode("onChangeInput", {
      nameForm: "registrationForm",
      action: "calcule",
      formProps: this.props.formProps,
      formState: this.props.formState,
    });
    this.setState({ show: false });
  };

  render() {
    const priceFormula = getInputValue(this.props, "_priceformula", {
      nameForm: "registrationForm",
    });
    const linesFormula = [];
    const formulaParsed = priceFormula ? JSON.parse(priceFormula) : [];
    let show = this.state.show;
    const { t } = this.props;
    if (priceFormula && formulaParsed) {
      let total = 0;
      formulaParsed.map((line, index) => {
        linesFormula.push(
          <div className="row" key={index}>
            <div className="cell">{line.source}</div>
            <div className="cell right">{line.price}</div>
          </div>
        );
        total += parseFloat(line.price);
      });
      linesFormula.push(
        <div className="row" key="total">
          <div
            className="cell"
            style={{ backgroundColor: "#666666", color: "white" }}
          >
            {t("form.total")}
          </div>
          <div className="cell right">{total}</div>
        </div>
      );
    } else {
      show = false; // after save form, the hidden field is clea, o
    }
    return (
      <div
        style={{
          width: "100%",
          backgroundColor: "#EFEFEF",
          borderBottom: "solid 10px white",
        }}
      >
        {!show && (
          <a className="aNotHover" onClick={() => this.showFormula()}>
            {" "}
            <ShowIcon icon="down" color="#4984ab" />
            {t("form.formula")}
          </a>
        )}
        {show && (
          <a className="aNotHover" onClick={() => this.hiddenFormula()}>
            {" "}
            <ShowIcon icon="up" color="#4984ab" />
            {t("form.formula")}
          </a>
        )}
        {show && (
          <div>
            <div className="cellhiddenxPhone row header">
              <div className="cell">{t("form.source")}</div>
              <div className="cell right">{t("form.price")}</div>
            </div>
            {linesFormula}
          </div>
        )}
      </div>
    );
  }
}
//

function mapStateToProps(state, ownProps) {
  const initialValues = {};
  const statesReturn = { myState: state, initialValues };
  return statesReturn;
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { appSubmitStart, appSubmitStop, showConfirm, dispatch, change },
    dispatch
  );
};

const ComponentWithDataAndState = connect(
  mapStateToProps,
  mapDispatchToProps
)(SubComponentInForm);

export default ComponentWithDataAndState;
