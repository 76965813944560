import React, { useEffect, useState } from "react";
import {
  resolvePathObj,
  getInputValue,
  sourcesOptions,
  getObjFromListById,
  tryParseJSON,
} from "../../utils/commonutils";
import ShowIcon from "../icons/ShowIcon";

const Comp = (props) => {
  const { t } = props;
  const room_shared = resolvePathObj(
    props,
    "formProps.containerPropsForm.crud_view_registration.registration.registrationregonline_id.room_shared",
    { notFound: null }
  );
  if (!room_shared) {
    return null;
  }
  return (
    <React.Fragment>
      {room_shared && (
        <div className="linkedContainer">
          <div>{room_shared && <div> ( {room_shared} )</div>}</div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Comp;
