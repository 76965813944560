import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { graphql, compose } from "react-apollo";
import { loader } from "graphql.macro";
import debounce from "lodash/debounce";

import { submit, change, touch, initialize } from "redux-form";

import { Tables } from "../../defTables";
import { fetchQl } from "../../apolloClient";
import defQls from "../../defQls";
import {
  processError,
  errorTranslate,
  checkLoadCrud,
  cleanFilters,
  getFilters,
  preSubmitValidation,
  submitValues,
  deleteRecord,
  uuid,
  getRecordFromOptionsIndirectOnFilter,
  resolveLink,
  getListviewFields,
} from "../../utils/helper";

import Form from "./ExportForm";

import PageHeader from "../PageHeader";
import CrudToolBar from "../CrudToolBar";
import IconLoading from "../icons/IconLoading";
import { MsgError } from "../ListData";
import { getInputValue, tt, getObjFromListById } from "../../utils/commonutils";

import {
  appSubmitStart,
  appSubmitStop,
  appSettingsLoad,
} from "../../actions/appActions";
import { showConfirm, hideConfirm } from "../../actions/confirmActions";
import crudCode from "./crudCode";

// especial code for export
import { ColsExport } from "./ColsExport";
const QUERYExportSettings = loader("./ExportSettingsCustomer.query.gql");

const templates = [];

const tableCrud = "export";
const action = "add";
const nameForm = tableCrud + "Form";
const formFilterName = "listFilterexport";

class FormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actionsave: "add",
      actionsave_origen: "add",

      defaultValues: { id: uuid() },
      crudAction: "Add",
      crudTable: tableCrud,
      mainForm: nameForm,
      id: this.props.match.params.id || "",
      dialogOpened: {},
      parentid:
        this.props.match.params && this.props.match.params.parentid
          ? this.props.match.params.parentid
          : "",
      disabledFields: [],
      hiddenFields: [],
      warningFields: {},

      // for page settings:
      listviewFields: [], // for export is array, not object // {},
      pagesSettings: [],
      pageSettingId: null,
      visibleListViewFields: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const qlNamePage1 = "ExportSettings";
    if (
      prevProps &&
      prevProps[qlNamePage1] &&
      prevProps[qlNamePage1].loading === true &&
      this.props &&
      this.props[qlNamePage1] &&
      this.props[qlNamePage1].loading === false &&
      this.props[qlNamePage1].getPagesSetting
    ) {
      // example: this.props.ExportSettingsRegistration.getPagesSetting.currentPageSetting .listviewFields
      const { listviewFields, pagesSettings, pageSettingId } =
        getListviewFields(this.props[qlNamePage1], this.state, true);

      if (listviewFields) {
        this.setState({
          listviewFields,
          pagesSettings,
          pageSettingId,
        });
        setTimeout(
          function () {
            this.props.dispatch(
              change(nameForm, "registration_fields", listviewFields)
            );
          }.bind(this),
          1000
        );
      }
    }
  }

  handleSubmit = async (values) => {
    try {
      if (
        this.state.warningFields &&
        this.state.warningFields._submit &&
        this.state.warningFields._submit.length
      ) {
        const validation = await preSubmitValidation(
          tableCrud,
          this.state.actionsave,
          this.props,
          this.syncCode,
          values,
          crudCode
        );
        if (!validation) return false;
        this.props.showConfirm({
          id: uuid(),
          type: "confirmation",
          text: [
            ...this.state.warningFields._submit,
            <div key="usure">{this.props.t("form.uSureSave")}</div>,
          ],
          buttons: [
            {
              label: this.props.t("form.yes"),
              onClick: async () => await this.handleSubmitOk(values),
            },
            {
              label: this.props.t("form.no"),
              onClick: () => null,
            },
          ],
        });
      } else {
        return await this.handleSubmitOk(values);
      }
    } catch (err) {
      throw err;
    }
  };

  handleSubmitOk = async (values) => {
    try {
      const res = await submitValues(
        tableCrud,
        this.state.actionsave,
        this.props,
        true,
        this.syncCode,
        values,
        crudCode
      );
      if (res) {
        if (res !== "dontreloaddata") {
          const newState = {
            actionsave: "update",
            actionsave_origen: "update",
          };
          if (res && res.id) newState.id = res.id;
          this.setState(newState, async () => {
            await this.executeCode("onChangeInput", {
              action: "initForm",
              nameForm,

              formProps: { ...this.props, containerPropsForm: this.props },
              formState: this.state,
              event: null,
              newValue: null,
            });
          });
        }
      } else {
        this.props.dispatch(change(nameForm, "_fakefield", Math.random()));
      }
      return res;
    } catch (err) {}
  };

  toolbarFunctions = (toolbar, params = {}) => {};

  hocdebounced = debounce((methodCode, params) => {
    this.executeCode(methodCode, params);
  }, 1500);

  executeCode = async (methodCode = "", params = {}) => {
    params.tableCrud = tableCrud;
    params.formState = this.state;

    if (!crudCode[methodCode]) {
      return;
    }

    const result = await crudCode[methodCode](params);

    if (result && result.changeFieldsLater) {
      Object.keys(result.changeFieldsLater).map((keyName, keyIndex) => {
        let valueField = result.changeFieldsLater[keyName];
        params.formProps.change(keyName, valueField);
      });
    }

    if (result && result.newStates) {
      let formStateChanged = false;
      let currentState = this.state;
      Object.keys(result.newStates).map((keyName, keyIndex) => {
        if (
          typeof this.state[keyName] === "undefined" ||
          JSON.stringify(this.state[keyName]) !==
            JSON.stringify(result.newStates[keyName])
        ) {
          let keyNewState = result.newStates[keyName];

          if (keyName === "warningFields") {
            keyNewState = {
              ...this.state[keyName],
              ...result.newStates[keyName],
            };
          }
          this.setState({ [keyName]: keyNewState });
          currentState[keyName] = result.newStates[keyName];
          formStateChanged = true;
        }
      });
      if (formStateChanged) {
        this.props.dispatch(
          change(nameForm, "_formstate", JSON.stringify(currentState))
        );
      }
    }

    if (result && typeof result.valueToReturn !== "undefined") {
      return result.valueToReturn;
    }
  };

  syncCode = (method, params = {}) => {
    let valueToReturn;

    if (typeof valueToReturn !== "undefined") return valueToReturn;
  };

  updatePageSettingsFields = (e, isAdding = false) => {
    // this.props.dispatch(change(nameForm, "_fakefield", Math.random()));
    if (e.target) {
      let listviewFields = [...this.state.listviewFields];
      let nIndexField = listviewFields.findIndex(
        (field) => field.name === e.target.name
      );

      listviewFields[nIndexField].invisible = listviewFields[nIndexField]
        .invisible
        ? false
        : true;
      this.setState({
        listviewFields,
      });
      this.props.dispatch(
        change(nameForm, "registration_fields", listviewFields)
      );
    } else {
      // change all fields; selectbox page is triggered
      this.setState({
        listviewFields: e,
      });
      this.props.dispatch(change(nameForm, "registration_fields", e));
    }
  };

  updatePageSetting = (pageSettingId, action, values = {}) => {
    const pagesSettings = this.state.pagesSettings;

    if (action === "delete" || action === "afterSaveUpdate") {
      let rowFound = -1;
      for (let r = 0; r < pagesSettings.length; r++) {
        if (pagesSettings[r].id === pageSettingId) {
          rowFound = r;
          break;
        }
      }
      if (action === "delete") {
        pagesSettings.splice(rowFound, 1);
        this.setState({
          pagesSettings,
          pageSettingId: pagesSettings[0].id,
        });

        const listviewFieldsNew = JSON.parse(
          JSON.stringify(pagesSettings[0].fields)
        );
        this.updatePageSettingsFields(listviewFieldsNew);
      }
      if (action === "afterSaveUpdate") {
        pagesSettings[rowFound].fields = values.fields;
        this.setState({ pagesSettings });
      }
    }

    if (action === "addInit") {
      pagesSettings.push(values);
      this.setState({ pagesSettings, pageSettingId: "" });
    }
    if (action === "cancelAdd") {
      pagesSettings.splice(pagesSettings.length - 1, 1);

      const listviewFieldsNew = JSON.parse(
        JSON.stringify(pagesSettings[0].fields)
      );
      this.setState({
        pagesSettings,
        pageSettingId: "",
        listviewFields: listviewFieldsNew,
      });
    }

    if (action === "addAfterSave") {
      const pageRow = pagesSettings.length - 1;
      pagesSettings[pageRow].id = values.id;
      pagesSettings[pageRow].name = values.name;
      pagesSettings[pageRow].fields = values.fields;
      pagesSettings.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );

      this.setState({ pagesSettings, pageSettingId: values.id });
    }
  };

  onChangePageSettingId = async (e) => {
    const pageSettingId = e.target.value;
    this.setState({
      pageSettingId,
    });
    const pagesSettings = this.state.pagesSettings;
    for (let r = 0; r < pagesSettings.length; r++) {
      if (pagesSettings[r].id === pageSettingId) {
        const listFieldsClone = JSON.parse(
          JSON.stringify(pagesSettings[r].fields)
        );
        this.updatePageSettingsFields(listFieldsClone);
      }
    }
  };

  render() {
    const { t, ...otherProps } = this.props;
    let aElements = [];
    let aQlFiltered = {
      crud_update_export: { table: "export" },
      crud_add_export: { table: "export" },
      crud_delete_export: { table: "export" },
      list_tour: { table: "tour" },
      ExportSettings: { object: "pagesSettings" },
    };
    const resultCheck = checkLoadCrud(aQlFiltered, this.props);
    if (resultCheck.messageError) {
      return <MsgError msg={resultCheck.messageError} t={this.props.t} />;
    }
    aElements.push(
      <PageHeader
        key="pageheader"
        action={action}
        t={t}
        tableCrud={tableCrud}
        tables={Tables}
        handleSubmit={this.handleSubmit}
        handleDeleteRecord={this.handleDeleteRecord}
        id={this.props.match.params.id}
      />
    );
    aElements.push(
      <CrudToolBar
        containerPropsForm={this.props}
        toolbarFunctions={this.toolbarFunctions}
        handleSubmit={this.handleSubmit}
        handleDeleteRecord={this.handleDeleteRecord}
        t={this.props.t}
        tableCrud={tableCrud}
        containerState={this.state}
        nameCrudForm={nameForm}
        nameFilterForm={formFilterName}
        tables={Tables}
        key="crudToolBar1"
        crudCode={crudCode}
        executeCode={this.executeCode}
      />
    );
    if (resultCheck.globalLoading && !resultCheck.messageError) {
      aElements.push(
        <div key="titleandloading">
          <div className="appBodyTitleSeparator" />
          <div
            className="animated-background"
            style={{ width: "100%", height: "300px" }}
          >
            <IconLoading key="icon" />
          </div>
        </div>
      );
    }
    if (this.state.actionsave === "add") {
      aElements.push(
        <div key="addlegend" style={{ color: "darkred" }}>
          {t("info." + this.state.actionsave_origen)}
        </div>
      );
    }
    if (
      !resultCheck.globalLoading &&
      !resultCheck.messageError &&
      this.props.ExportSettings.getPagesSetting
    ) {
      aElements.push(
        <Form
          target="Customer"
          executeCode={this.executeCode}
          syncCode={this.syncCode}
          toolbarFunctions={this.toolbarFunctions}
          crudAction="Add"
          key="mainform"
          id={""}
          onSubmit={this.handleSubmit}
          containerPropsForm={this.props}
          formState={this.state}
          t={this.props.t}
        />
      );
      if (!(this.state.hiddenFields && this.state.hiddenFields.files)) {
        aElements.push(
          <ColsExport
            key="colexport"
            t={this.props.t}
            // defColsPrint={defColsPrint}
            listviewFields={this.state.listviewFields}
            settings={this.state}
            updatePageSettingsFields={this.updatePageSettingsFields}
            updatePageSetting={this.updatePageSetting}
            showNotificationWithTimeout={this.props.showNotificationWithTimeout}
            deletePagesetting={this.props.deletePagesetting}
            showConfirm={this.props.showConfirm}
            pagesSettings={this.state.pagesSettings}
            templates={templates}
            tableCrud="Customer-export"
            visibleFieldsToggle={this.visibleFieldsToggle}
            pageSettingId={this.state.pageSettingId}
            onChangePageSettingId={this.onChangePageSettingId}
          />
        );
      }
    }
    aElements.push(
      <CrudToolBar
        containerPropsForm={this.props}
        toolbarFunctions={this.toolbarFunctions}
        handleSubmit={this.handleSubmit}
        handleDeleteRecord={this.handleDeleteRecord}
        t={this.props.t}
        tableCrud={tableCrud}
        containerState={this.state}
        nameCrudForm={nameForm}
        nameFilterForm={formFilterName}
        tables={Tables}
        key="crudToolBar2"
        crudCode={crudCode}
        executeCode={this.executeCode}
      />
    );
    return <div>{aElements}</div>;
  }
}

const withGraphqlandRouter = compose(
  graphql(QUERYExportSettings, {
    name: "ExportSettings",
    options: (props) => {
      const optionsValues = { fetchPolicy: "network-only" };
      return optionsValues;
    },
  }),
  graphql(defQls.export.Update, {
    name: "crud_update_export",
    options: (props) => {
      const optionsValues = {
        variables: { id: props.match.params.id, _qlType: "Update" },
      };
      optionsValues.fetchPolicy = "no-cache";
      return optionsValues;
    },
  }),
  graphql(defQls.export.Add, {
    name: "crud_add_export",
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = "no-cache";
      optionsValues.variables = { _qlType: "Add" };
      return optionsValues;
    },
  }),
  graphql(defQls.export.Delete, {
    name: "crud_delete_export",
    options: (props) => {
      const optionsValues = {
        variables: { id: props.match.params.id, _qlType: "Delete" },
      };
      optionsValues.fetchPolicy = "no-cache";
      return optionsValues;
    },
  }),
  graphql(defQls.tour.ListMini, {
    name: "list_tour",
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = Tables[tableCrud].fetchPolicy
        ? Tables[tableCrud].fetchPolicy
        : "network-only";
      optionsValues.variables = { _qlType: "ListMini", inactive: false };
      return optionsValues;
    },
  }),
  graphql(defQls.deletePagesetting, {
    name: "deletePagesetting",
    options: (props) => {
      const optionsValues = { variables: { id: props.id } };
      optionsValues.fetchPolicy = "no-cache";
      return optionsValues;
    },
  })
)(withRouter(FormContainer));

const mapStateToProps = (state) => {
  return {
    myState: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      appSubmitStart,
      appSubmitStop,
      showConfirm,
      initialize,
      change,
      touch,
      dispatch,
    },
    dispatch
  );
};

const withState = connect(
  mapStateToProps,
  mapDispatchToProps
)(withGraphqlandRouter);

const ComponentFull = withState;

export default ComponentFull;
