/* diferent from notifActions.js that receive a list of parameters, here all the paremeters are
 inside obj parameter, example:
  showConfirm({
    id: uuid(),
    type: 'confirmation',
    text: props.t('form.deleteconfirm'),
    onClose: () => console.log("fire at closing event"),
    onConfirm: () => console.log("fire at confirming event"),
  });
 because 'confirm' has a long list of parameters non obligatory
 The action return : 1. type , and 2. obj
*/
const showConfirm = (obj) => ({ type: "SHOW_CONFIRM", obj });

const hideConfirm = (id) => {
  return { type: "HIDE_CONFIRM", id };
};

export { showConfirm, hideConfirm };
