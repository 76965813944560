
import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import { Field, FieldArray, reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';
import FormField  from '../FormField';
import FormArrayField from '../FormArrayField';

import { fieldsValidator, getOptions, processNewValues } from '../../utils/helper';
import {resolvePathObj, processValues, realTypeOf, tryParseJSON} from '../../utils/commonutils';

import { Tables } from '../../defTables';
import { normalizes } from '../../utils/normalizes';


const tableCrud = 'transportation';
let nameForm = `${tableCrud}Form`;
const validate = (values, props) => fieldsValidator('transportation', values, props, nameForm);

class Form extends Component {

  constructor(props) {
    super(props);

    


  }

  componentDidMount() {
   
   
   
    this.timerHandle = setTimeout(() => {
     
      this.props.dispatch ( change (nameForm, '_fakefield',  Math.random() ));
      this.props.executeCode ( 'onChangeInput', { nameForm, action: 'initForm' , props: this.props, formProps: this.props, formState: this.props.formState  });
    }, 1000);
    window.addEventListener('message', this.readMessage, true);
  }

  componentWillUnmount() {
    if (this.timerHandle) {
      clearTimeout(this.timerHandle);
      this.timerHandle = 0;
    }
    window.removeEventListener('message', this.readMessage, true);
  }

  readMessage = (event) => {
    if (!event.origin.includes(window.location.hostname)) return;
    if (event && event.data) {
      const params = tryParseJSON(event.data, {});
      if (params.popup) {
        params.inputFullName = params.popup;
        if (!params.formProps) params.formProps = this.props;
        this.props.executeCode('onChangeInput', params);
      }
  
    }
  };

  hocdebounced = debounce(( methodCode, params)=> {
  
    this.props.executeCode( methodCode, params);
  }, 1500);

  

  render() {
   
    const { error, handleSubmit, invalid, pristine, change, submitting, submitFailed, t, ...otherProps } = this.props;
    return (

      <form onSubmit={handleSubmit}>
        <div className="appBodyTitleSeparator" />
        
          <Field
            inputName="tour_id"
            name="tour_id"
            formProps={this.props}
            pathInTables="transportation.fields.tour_id"
            formState={this.props.formState}
            nameForm="transportationForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="hidden"
            autoFocus={this.props.formState.id?'':'1'}
            typeInput="hidden"
          />
        <div className="formSection">
          <Field
            inputName="name"
            name="name"
            formProps={this.props}
            pathInTables="transportation.fields.name"
            formState={this.props.formState}
            nameForm="transportationForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            typeInput="text"
          />
          <Field
            inputName="seats"
            name="seats"
            formProps={this.props}
            pathInTables="transportation.fields.seats"
            formState={this.props.formState}
            nameForm="transportationForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            normalize={normalizes.IntPositive}
            typeInput="text"
            disabled={ 
              this.props.formState.disabledFields &&  this.props.formState.disabledFields.seats 
            }
          />
          <Field
            inputName="price"
            name="price"
            formProps={this.props}
            pathInTables="transportation.fields.price"
            formState={this.props.formState}
            nameForm="transportationForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="text"
            normalize={normalizes.Price}
            typeInput="text"
          />
        </div>
          <Field
            inputName="driverinfo"
            name="driverinfo"
            formProps={this.props}
            pathInTables="transportation.fields.driverinfo"
            formState={this.props.formState}
            nameForm="transportationForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="textarea"
            typeInput="textarea"
          />
          <Field
            inputName="responsibleinfo"
            name="responsibleinfo"
            formProps={this.props}
            pathInTables="transportation.fields.responsibleinfo"
            formState={this.props.formState}
            nameForm="transportationForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="textarea"
            typeInput="textarea"
          />
        <div className="formSection">
          <FieldArray
            inputName="routeplanner"
            name="routeplanner"
            formProps={this.props}
            pathInTables="transportation.fields.routeplanner"
            formState={this.props.formState}
            nameForm="transportationForm"
            fieldParentKey="routeplanner"
            component={FormArrayField}
            tableCrud={tableCrud}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            t={t}
            mode={{} }
          />
        </div>
        <div className="formSection">
        {(resolvePathObj(this.props, 'myState.app.appSettings.settingsforms.transport_seats', true)) && 
          <FieldArray
            inputName="seatingconfiguration"
            name="seatingconfiguration"
            formProps={this.props}
            pathInTables="transportation.fields.seatingconfiguration"
            formState={this.props.formState}
            nameForm="transportationForm"
            fieldParentKey="seatingconfiguration"
            component={FormArrayField}
            tableCrud={tableCrud}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            t={t}
            mode={{} }
          />
 }
        </div>
          <Field
            inputName="bookings"
            name="bookings"
            formProps={this.props}
            pathInTables="transportation.fields.bookings"
            formState={this.props.formState}
            nameForm="transportationForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="hidden"
            typeInput="hidden"
          />
          <Field
            inputName="organizer_id"
            name="organizer_id"
            formProps={this.props}
            pathInTables="transportation.fields.organizer_id"
            formState={this.props.formState}
            nameForm="transportationForm"
            component={FormField}
            executeCode={this.props.executeCode}
            syncCode={this.props.syncCode}
            type="selectAutocomplete"
            tableCrud={tableCrud}
            typeInput="selectAutocomplete"
            disabled={this.props.id ? true : false}
          />
        <Field
          inputName="_formstate"
          name="_formstate"
          typeInput="hidden"
          component={FormField}
          label=""
        />

        <div>
          {error && <strong>{error}</strong>}

          <div className="formError">
            {invalid && t('form.haserrors')}
          </div>

        </div>

      </form>
    );
  }
}


const ComponentWithData = reduxForm({
  form: nameForm,
  touchOnChange: true,
  
  enableReinitialize: true,
  validate,
})(Form);

function mapStateToProps(state, ownProps) {
 
 
  const log = false;
  const statesReturn = { myState: state };
  let initialValues;
  
  
  if (ownProps.formState.actionsave_origen === 'update' ||  ownProps.formState.actionsave_origen === 'duplicate' ) {
    
    if (ownProps.data) {

      initialValues = processValues(ownProps, tableCrud, ownProps.data, 'toClient', 'view');
      
      if (ownProps.formState.defaultValues && ownProps.formState.defaultValues.id) {
        initialValues.id = ownProps.formState.defaultValues.id;
      }
    }
  } else {
    initialValues = processNewValues(ownProps, tableCrud, ownProps.data, 'toClient','new' );
    if (ownProps.containerPropsForm.history && Object.keys(Tables[tableCrud].listFilters)) {
     
      const search = ownProps.containerPropsForm.history.location.search;
      const params = new URLSearchParams(search);
     
      Object.keys(Tables[tableCrud].listFilters.fields).map((fieldKey) => {
       
        if (log) console.log('fieldKey',fieldKey);
        let fieldFilter = Tables[tableCrud].listFilters.fields[fieldKey] ;
        let defaultValue, defaultValueName;
        if (ownProps.formState.defaultValues && ownProps.formState.defaultValues[fieldKey] ) {
         
          defaultValue = ownProps.formState.defaultValues[fieldKey];
        } else {
          defaultValue = params.get(fieldKey);
          defaultValueName = params.get(fieldKey.replace('_id','_name'));
        }
        if (defaultValue) {
          let initialVal;
          const isFieldArray = fieldFilter.fieldContainer && Tables[tableCrud].fields[fieldFilter.fieldContainer].subfields;
          if (fieldFilter.fieldSource
            && fieldFilter.fieldSource.typeInput === 'selectAutocomplete'
            && (fieldFilter.fieldSource.saveonly )
            && !defaultValue.id
            ) {
           

            initialVal = { id: defaultValue, name: defaultValueName };
          } else {
            initialVal = defaultValue;
          }
          if (isFieldArray) {
            fieldKey = fieldFilter.fieldContainer;
            if (!initialValues[fieldKey]) initialValues[fieldKey] = [ {id: ''} ];
            initialValues[fieldKey][0][fieldFilter.subfield] = initialVal;

          } else {
            initialValues[fieldKey] = initialVal;
          }
          if (log) console.log('initialValues[' + fieldKey+'] ', initialValues[fieldKey]);
        }
      });
    }
  }
  statesReturn.initialValues = initialValues ;
  
  return statesReturn;
}

const ComponentWithDataAndState = connect(
  mapStateToProps,
  null,
)(ComponentWithData);

export default ComponentWithDataAndState;
