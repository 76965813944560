import React from "react";
import { resolvePathObj } from "../../utils/commonutils";

const Comp = (props) => {
  return (
    <div>
      <div style={{ paddingTop: "7px", fontSize: "18px", color: "#aaaaaa" }}>
        {props.t("form.tourdata")}
      </div>
    </div>
  );
};

export default Comp;
