import React from "react";
import {
  resolvePathObj,
  getInputValue,
  sourcesOptions,
  getObjFromListById,
} from "../../utils/commonutils";

const Comp = (props) => {
  const { t } = props;
  const fieldValue = resolvePathObj(
    props,
    "formProps.containerPropsForm.crud_view_customer.customer.customerperson_id.phones",
    { notFound: null }
  );
  if (!fieldValue) {
    return null;
  }
  const resultField = [];
  let oLines;
  try {
    oLines = JSON.parse(fieldValue);
    if (oLines.length === 0) {
      resultField.push(<div>{props.t("login.errorEmptyField")}</div>);
    }
  } catch (err) {
    return null;
  }

  if (!fieldValue) {
    return null;
  }

  oLines.map((line, key) => {
    resultField.push(
      <div key={"add1" + key}>
        {t(
          "phonesType." +
            getObjFromListById(sourcesOptions.phonesType, line.type.id, null)
              .name
        )}
        : &nbsp;
        {line.phone}&nbsp;&nbsp;{line.otherinfo}
      </div>
    );
  });

  return (
    <div className="linkedContainer" style={{ marginLeft: "2px" }}>
      <div>{resultField}</div>
    </div>
  );
};

export default Comp;
