import { saveSession, deleteSession } from "../utils/auth";
export const AUTH_SIGNIN = "AUTH_SIGNIN";
export const AUTH_SIGNOUT = "AUTH_SIGNOUT";

export const signIn = (response) => {
  saveSession(response);
  return { type: AUTH_SIGNIN };
};

export const signOut = () => {
  deleteSession();
  return { type: AUTH_SIGNOUT };
};
