import React, { Component } from "react";
import { Link } from "react-router-dom";

import PageHeader from "../PageHeader";

class MyAccount extends Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        <PageHeader
          title="menu.mailing"
          icon="mailing"
          key="pageheader"
          t={t}
          helpLink="https://docs.google.com/document/d/1XSGd2FCnl3Ka-sHQR76qCCUx_UhFRXVX_MtxPpo1-20/edit#heading=h.ot370tiexjki"
        />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Link style={{ margin: "10px" }} to="/ejobmail">
            ► {t("table.ejobmails")}
          </Link>
          <Link style={{ margin: "10px" }} to="/ejobday">
            ► {t("table.ejobdays")}
          </Link>
          <Link style={{ margin: "10px" }} to="/ejobmonth">
            ► {t("table.ejobmonths")}
          </Link>
          <Link style={{ margin: "10px" }} to="/etemplate" className="navbar">
            ► {t("table.etemplates")}
          </Link>
          <Link style={{ margin: "10px" }} to="/esettings" className="navbar">
            ► {t("menu.settings")}
          </Link>
        </div>
      </div>
    );
  }
}
export default MyAccount;
