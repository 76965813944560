/*
WARNING:
  formProps is artificial when is launched from containerForm, in the case initForm or manual change
    from container.
    this send his props himself and not containerForm
  so don't use formProps to use special props, form myState and tt is ok, container and Form have these same props
  getInputValue use myState, myState is present in container so is ok to use it,
    but send always the name of form { nameForm }, because if formProps is artificil will fail
  use tt , for translate, have dependency with props
 */

/*
  input date in chrome and firefox are showed accord country language browser
  but his real value is YYYY-MM-DD
  Safari has not presentational input then real and showed value  is YYYY-MM-DD
  The last error stored is the showed error, only if there was no errors accord validators: helpers.js::fieldsValidator()
 */
import React from "react";
import ShowIcon from "../icons/ShowIcon";
import {
  getInputValue,
  resolvePathObj,
  getToday,
  tt,
  dateClean,
  dateStandard,
} from "../../utils/commonutils";
import {
  getRecordFromOptionsIndirectOnFilter,
  Circle,
} from "../../utils/helper";
import { date as dateInvalid } from "../../utils/validators";
import strFragments from "../../defStrFragmentsQls.js";
import { fetchQl } from "../../apolloClient";
import {
  parse as parseDate,
  differenceInCalendarDays as differenceInDays,
} from "date-fns";
const crudCode = {};

crudCode.onChangeInput = async (params) => {
  let log = false;
  if (log) console.log("onChangeInput params:", params);
  const {
    nameForm,
    popup,
    tableCrud,
    inputFullName,
    line,
    action,
    parentField,
    event,
    newValue,
    previousValue,
    props,
    formProps,
    formState,
  } = params;
  const containerPropsForm = formProps.containerPropsForm;
  /*
   popup denote that value is sent through window, and is here not because there is change, but
   must execute change manually and follow the same logic that onChange
   */
  const result = {};
  let newStates = {
    disabledFields: {},
    hiddenFields: {},
    warningFields: {},
    errors: {},
  };

  if (popup) {
    if (inputFullName === "bank_id") {
      formProps.change(inputFullName, newValue);
    }
  }

  /* dateaccounting now is enable for all  (same with not installed Accounting Module
  // dont put the code inside : if (action === 'initForm') {}
  // because every time a input change must to return all new states
    if (resolvePathObj(formProps, 'myState.app.appSettings.modules.ACCO', true)) {
      newStates.hiddenFields['dateaccounting'] = false;
    } else {
      newStates.hiddenFields['dateaccounting'] = true;
    }
  //}*/

  let paymentmethod_id;
  let paymentmethodRecord;
  let datedeferred;
  let dateaccounting;
  let datereception;

  if (inputFullName === "paymentmethod_id") {
    paymentmethod_id = newValue;
  } else {
    //if ( action!== 'initForm') {
    paymentmethod_id = getInputValue(formProps, "paymentmethod_id", {
      nameForm,
    });
  }

  /* Important store the real dates accord exisint values or future (newValue)
    before :
      = block thath changes values with .change() because un .change() dont update inmmediately
          the value to use: .getInputValue()
      = execute validations
   */
  if (inputFullName === "dateaccounting") {
    dateaccounting = newValue;
  } else if (action !== "initForm") {
    dateaccounting = getInputValue(formProps, "dateaccounting", { nameForm });
  }
  let dateaccountingStandard = dateStandard(dateaccounting);

  // formProps.id  , is update, no add
  if (inputFullName === "datereception") {
    datereception = newValue;
  } else if (action !== "initForm") {
    datereception = getInputValue(formProps, "datereception", { nameForm });
  }
  const datereceptionStandard = dateStandard(datereception);

  if (inputFullName === "datedeferred") {
    datedeferred = newValue;
  } else if (action !== "initForm") {
    datedeferred = getInputValue(formProps, "datedeferred", { nameForm });
  }

  const datedeferredStandard = dateStandard(datedeferred);

  if (inputFullName === "check_number" || action === "initForm") {
    let check_number;
    if (action === "initForm") {
      check_number = getInputValue(formProps, "check_number", { nameForm });
    } else {
      check_number = newValue;
    }
    if (check_number) check_number = check_number.trim();
    const filter = {
      _and: [
        { check_number },
        { _ne: { "paymentpaymentdocstatus_id.statusbase": 11 } },
      ],
    };
    let resCheck = [];
    if (check_number) {
      // only execute search if is not empty value
      if (formState.actionsave === "update") {
        filter._and.push({ _ne: { id: formState.id } });
      }

      resCheck = await fetchQl(
        `
        query PaymentList ($_filter: String) {
          payments  (_filter: $_filter) {
            ${strFragments.payment}
          }
        }`,
        [{ name: "_filter", type: "String", value: JSON.stringify(filter) }],
        {
          dataName: "payments",
          props: formProps, // important object that contains myState , for pelemaster resolver org
        }
      );
    }
    newStates.warningFields.check_number = [];
    if (resCheck && resCheck.length) {
      newStates.warningFields.check_number.push(
        <span key="msg">* {tt(formProps.t, "info.check_numberExist")}</span>
      );
      resCheck.map((payment) => {
        const paymentDetails = [];
        if (payment.dateaccounting) paymentDetails.push(payment.dateaccounting);
        if (payment.paymentbank_id && payment.paymentbank_id.name)
          paymentDetails.push(" " + payment.paymentbank_id.name);
        newStates.warningFields.check_number.push(
          <div key={payment.id}>
            <a
              target="_blank"
              className="aBlue"
              href={"/payment-view/" + payment.id + "?popup=1"}
            >
              <ShowIcon key="i" size="20" color="#0071e6" icon="monetization" />
              {paymentDetails}
            </a>
          </div>
        );
      });
    }
  }

  /*
  resolve disable and value datedeferred
   */
  if (paymentmethod_id) {
    paymentmethodRecord = getRecordFromOptionsIndirectOnFilter(
      formProps,
      "paymentmethod",
      paymentmethod_id
    );
    //console.log('paymentmethodRecord ', paymentmethodRecord );
  }
  // fill registration date to today, when registrationstatus is registration confirmed
  // future, restore accord initvalues on edition , if registrationstatus change to state no registrated
  let usedatedeferred = false;
  if (paymentmethodRecord) {
    if (paymentmethodRecord.usedatedeferred) {
      usedatedeferred = true;
    }

    if (inputFullName === "paymentmethod_id") {
      // control changes date
      if (paymentmethodRecord.usedatedeferred) {
        usedatedeferred = true;
        if (!datedeferred) {
          datedeferred = getToday(); //  // importante update these variable that modify retrieve in first block
          formProps.change("datedeferred", datedeferred);
        }
      } else {
        datedeferred = null; // importante update these variable that modify retrieve in first block
        formProps.change("datedeferred", datedeferred);
      }
    }
  }
  //console.log('usedatedeferred / paymentmethod_id/ paymentmethodRecord', usedatedeferred, paymentmethod_id, paymentmethodRecord);
  newStates.disabledFields["datedeferred"] = !usedatedeferred;
  /* */

  if (datereception && !dateInvalid(datereception)) {
    // only compare with datedeferred if datederred is not empty
    if (
      datereception &&
      datedeferred &&
      datereceptionStandard > datedeferredStandard
    ) {
      newStates.errors.datereception =
        "info.dateReceptionCantBeGreaterThanDateDeferred";
    }
  }

  // datedeffered can't be less than datereception

  if (datedeferred && !dateInvalid(datedeferred)) {
    if (
      !dateInvalid(datedeferred) &&
      datedeferredStandard < datereceptionStandard
    ) {
      newStates.errors.datedeferred =
        "info.dateDeferredCantBeLowerThanDateReception";
    }
  }
  /*
   update accounting date only if datereception && datedeferred are ok
   the newest date (normally datedeferred if exist) and update  dateaccounting when newest date is greater
  */
  let datenewest;
  let datenewestStandard;
  if (datedeferred && !dateInvalid(datedeferred)) {
    datenewest = datedeferred;
    datenewestStandard = datedeferredStandard;
    //console.log('datenewest :datedeferred', datedeferred);
  } else if (datereception && !dateInvalid(datereception)) {
    //console.log('datenewest :datereception', datereception);
    datenewest = datereception;
    datenewestStandard = datereceptionStandard;
  } else {
    //console.log('datenewest :null');
    datenewest = null;
    datenewestStandard = null;
  }

  /*
  with the new logic cant  see the errors here with newStates, %%% see to look for in state redux form
  to don't update dateaccounting
   */
  if (
    !newStates.errors.datereception &&
    !newStates.errors.datedeferred &&
    (!dateaccounting || dateaccountingStandard < datenewestStandard)
  ) {
    if (inputFullName === "datereception" || inputFullName === "datedeferred") {
      formProps.change("dateaccounting", datenewest);
      dateaccounting = datenewest; // important update for the next block validation
      dateaccountingStandard = datenewestStandard;
    }
  }

  /*
  if dateaccounting is hidden, these error will not show and won't block submit
   */
  if (dateaccounting && !dateInvalid(dateaccounting)) {
    //console.log('datenewest',datenewest);
    if (datenewest && dateaccountingStandard < datenewestStandard) {
      //console.log('dateAccountingWrong');
      newStates.errors.dateaccounting = "info.dateAccountingWrong";
    }
  }

  if (inputFullName === "applicationcustomer_id") {
  }

  if (inputFullName === "applicationcustomer_id") {
    // getInputValue is smart, if formname is in formProps, dont' need nameForm,
    // case popup call this code but have no nameForm; because is depende caller
    const fullApplication = getInputValue(formProps, "application", {
      nameForm,
    });
    //console.log('fullApplication', fullApplication);
    let currentApplicationLine = getInputValue(formProps, "application", {
      nameForm,
      line,
      notFound: undefined,
      log: false,
    });

    let changeRow = false;
    if (popup) {
      currentApplicationLine = {
        ...currentApplicationLine,
        customer_id: newValue,
      };
      //console.log('currentAddressLine', currentAddressLine);
      fullApplication[line] = currentApplicationLine;
      changeRow = true;
    }
    if (changeRow) {
      formProps.change("application", fullApplication);
    }
  }

  if (
    action === "delete" || // this case is for delete item, no subfield o line related, just the name of field container
    (inputFullName &&
      // repartition pelerin/pelerinage
      (inputFullName.substr(0, 11) === "application" || // check prefix name field for fieldarray
        // repartition comptable
        inputFullName.substr(0, 6) === "aentry" || // check prefix name field for fieldarray
        inputFullName === "amount")) ||
    action === "initForm"
  ) {
    /*|| inputFullName === 'applicationamount'
    || inputFullName === 'aentryamount'
    || inputFullName === 'amount'  ) {*/

    // guess the name form automatically, the event is produced on the same input
    const applicationValue = getInputValue(formProps, "application", {
      nameForm,
    });
    const aentryValue = getInputValue(formProps, "aentry", { nameForm });

    let totalApplication = 0;
    let totalAentry = 0;
    // need the name form , the event is produced on another input
    let paymentAmout;
    if (inputFullName === "amount") {
      paymentAmout = parseFloat(newValue) || 0;
    } else {
      paymentAmout =
        parseFloat(getInputValue(formProps, "amount", { nameForm })) || 0;
    }

    if (log) console.log("paymentAmout ", paymentAmout);

    if (applicationValue) {
      // only if there are items
      for (let a = 0; a < applicationValue.length; a++) {
        // generaic name for variabble but is just for subkey customer
        const customerFieldNameWithLine = `applicationcustomer_id${a}`;

        let lineAmount;
        if (
          action &&
          action === "delete" &&
          parentField === "application" &&
          line === a
        ) {
          // line is deleted, don't compute amout
          // line is deleted, needed manually remove warnings for that line field
          newStates.warningFields[customerFieldNameWithLine] = null; // clean warning for delete dline
        } else {
          /*
          control customer tour
           */
          let applicationcustomer_id;
          if (inputFullName === "applicationcustomer_id" && line === a) {
            applicationcustomer_id = newValue;
          } else {
            applicationcustomer_id = applicationValue[a].customer_id;
          }
          if (applicationcustomer_id && applicationcustomer_id.id)
            applicationcustomer_id = applicationcustomer_id.id;
          let applicationtour_id;
          if (inputFullName === "applicationtour_id" && line === a) {
            applicationtour_id = newValue;
          } else {
            applicationtour_id = applicationValue[a].tour_id;
          }
          if (applicationtour_id && applicationtour_id.id)
            applicationtour_id = applicationtour_id.id;
          if (
            inputFullName === "applicationcustomer_id" ||
            inputFullName === "applicationtour_id" ||
            action === "initForm"
          ) {
            //
            newStates.warningFields[customerFieldNameWithLine] = [];
            if (!applicationcustomer_id || !applicationtour_id) {
              // reset display registration
            } else {
              // show status registration / total and balance
              const filter = {
                _and: [
                  { tour_id: applicationtour_id },
                  { customer_id: applicationcustomer_id },
                ],
              };
              let res = [];
              res = await fetchQl(
                `
                query RegistrationList ($_filter: String) {
                  registrations  (_filter: $_filter) {
                    ${strFragments.registration}
                  }
                }`,
                [
                  {
                    name: "_filter",
                    type: "String",
                    value: JSON.stringify(filter),
                  },
                ],
                {
                  dataName: "registrations",
                  props: formProps, // important object that contains myState , for pelemaster resolver org
                }
              );
              if (res && res.length) {
                res = res[0]; // always bring one record
                //console.log('res', res);
                if (
                  res.registrationregistrationstatus_id &&
                  res.registrationregistrationstatus_id.name
                ) {
                  const url = "/registration-view/" + res.id;
                  newStates.warningFields[customerFieldNameWithLine].push(
                    <a
                      className="aBlue"
                      key="reg"
                      onClick={(e) => containerPropsForm.goUrl(url)}
                    >
                      <div style={{ display: "flex", alignItems: "middle" }}>
                        <div>
                          {res.registrationregistrationstatus_id &&
                            res.registrationregistrationstatus_id.color && (
                              <Circle
                                color={
                                  res.registrationregistrationstatus_id.color
                                }
                              />
                            )}
                        </div>
                        <div style={{ marginTop: "3px", leftMargin: "7px" }}>
                          {res.registrationregistrationstatus_id.name}
                          {" / " +
                            tt(formProps.t, "form.price") +
                            ": " +
                            res.price}
                          {" / " +
                            tt(formProps.t, "form.balance") +
                            ": " +
                            res.balance}
                        </div>
                        <ShowIcon
                          key="i"
                          size="20"
                          color="#0071e6"
                          icon="registration"
                        />
                      </div>
                    </a>
                  );
                }
              } else {
                newStates.warningFields[customerFieldNameWithLine].push(
                  "⚠️ " + tt(formProps.t, "info.noRegistration")
                );
              }
            }
          }
          /*
            end control customer tour
            */

          if (inputFullName === "applicationamount" && line === a) {
            lineAmount = newValue;
          } else {
            lineAmount = applicationValue[a].amount;
          }
        }
        totalApplication += parseFloat(lineAmount) || 0;
        if (log)
          console.log(
            "payment application amount,  total ",
            lineAmount,
            totalApplication
          );
      }
    }
    if (aentryValue) {
      // only if there are items
      for (let a = 0; a < aentryValue.length; a++) {
        let lineAmount;
        if (
          action &&
          action === "delete" &&
          parentField === "aentry" &&
          line === a
        ) {
          // line is deleted, don't take amout
        } else {
          if (inputFullName === "aentryamount" && line === a) {
            lineAmount = newValue;
          } else {
            lineAmount = aentryValue[a].amount;
          }
        }
        totalAentry += parseFloat(lineAmount) || 0;
        if (log)
          console.log(
            "payment totalAentry amount,  total ",
            lineAmount,
            totalAentry
          );
      }
    }

    let balance;
    if (action !== "initForm") {
      // disable false positivo changes control // and in fake initForm from containerForm
      // have no existe .change, only when come from real formProps
      // change is did it same if amout is not the same, because real value db and input presentation differences
      balance = paymentAmout - totalApplication - totalAentry;
      formProps.change("balance", balance.toFixed(2));
    } else {
      balance = getInputValue(formProps, "balance", { nameForm });
    }

    newStates.warningFields.balance = [];
    newStates.warningFields._submit = [];
    if (balance && balance !== 0) {
      //console.log('wrong balance', totalApplication, balance);
      const balancewarning = "⚠️ " + tt(formProps.t, "info.balancewrong");
      newStates.warningFields.balance.push(balancewarning);
      newStates.warningFields._submit.push(
        <div key="bal">{balancewarning}</div>
      );
    }
  }
  result.newStates = newStates;
  //console.log('newStates', newStates);
  return result;
};

export default crudCode;
