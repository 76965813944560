import React from "react";
import { Field, reduxForm } from "redux-form";
import gql from "graphql-tag";
import { graphql, compose } from "react-apollo";

import debounce from "lodash/debounce";
import { submit, change, touch, initialize } from "redux-form";

import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import { gqlActions } from "../../gqls/gqlactions";
import defQls from "../../defQls";
import {
  processError,
  errorTranslate,
  checkLoadCrud,
  cleanFilters,
  getFilters,
  preSubmitValidation,
  submitValues,
  deleteRecord,
  uuid,
  getRecordFromOptionsIndirectOnFilter,
  resolveLink,
} from "../../utils/helper";

import IconLoading from "../icons/IconLoading";
import {
  appSubmitStart,
  appSubmitStop,
  appSettingsLoad,
} from "../../actions/appActions";
import { showConfirm, hideConfirm } from "../../actions/confirmActions";

import Portal from "../Portal";
import { fetchQl } from "../../apolloClient";
import RichText from "./RichText";

import FormField from "../FormField";
import crudCode from "../ejobmail/crudCode";
import { connect } from "react-redux";
import {
  processValues,
  resolvePathObj,
  getInputValue,
  sourcesOptions,
  getObjFromListById,
} from "../../utils/commonutils";
import { Tables } from "../../defTables";

import ShowIcon from "../icons/ShowIcon";
import { Button } from "../../layouts/cssstyled";

const templates = [];

//const tableCrud = 'tour';
//const nameForm = tableCrud + 'Form';

class FormContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //always show, because component have an conditional in container // false,
      showModal: true, // value important to open and close the modal
      clicked: false, // to control click save one time
      // to redirect defaultValues from containerState  component caller. Eg: RegistrationView
      //  but id , must be a new
      defaultValues: { ...this.props.containerState.defaultValues, id: uuid() },
      // below values is copied from normal template 'add'
      actionsave: "add",
      actionsave_origen: "add",
      crudAction: "Add",
      text: "",
      // crudTable: tableCrud,
      id: this.props.match.params.id || "",

      dialogOpened: {},
      parentid:
        this.props.match.params && this.props.match.params.parentid
          ? this.props.match.params.parentid
          : "",
      disabledFields: [],
      hiddenFields: [],
      warningFields: {},
    };
  }

  componentDidUpdate() {
    /*
    // not needed, in state construct is activate at the start
    if (this.props.containerState.dialogOpened.MailCompose && !this.state.showModal) {
      this.setState({ showModal: true});
    }*/
  }

  send = (callback = null) => {
    this.setState({ clicked: true });
    // twin code from crudToolBar.handleClick()
    if (this.props.myState.app.submitting) {
      //ctrl submiting global , really neded, new block ctrl can reemplace this ?
      ////console.error('submit twice');
      return;
    }

    if (!this.state.clicked) {
      // TWIN CTR231 ctrl is not saving this
      this.setState({ clicked: true }, async () => {
        this.props.appSubmitStart();
        const resSubmit = await this.handleSubmit();
        this.props.appSubmitStop();
        this.setState({ clicked: false });
        if (callback) callback(resSubmit);
      });
    }
  };
  setText = (text) => {
    this.setState({ text });
  };
  handleSubmit = async (xxvalues) => {
    let values;
    if (this.props.ql === "tourUpdate") {
      values = {
        textgcu: JSON.stringify(this.state.text),
        id: this.props.id,
      };
    }
    if (this.props.ql === "setOrgSettings") {
      values = {
        textgcu: JSON.stringify(this.state.text),
      };
    }
    // console.log('this.state.text',this.state.text);
    // const textgcu =  JSON.stringify(this.state.text);
    /*const response = await gqlActions('tour', 'Update', this.props, 
      { 
        textgcu,
        id: this.props.id,
      })
    .catch((err) => {
      processError(err, this.props, true);
    });*/
    let mutationName;
    // mutationName
    try {
      const response = await this.props[this.props.ql]({
        variables: values,
        fetchPolicy: "no-cache",
        /*optimisticResponse: {
            [tableAction]: valuesOptimistic,
          },
          update: (store, { data: { [tableAction]: reponseRecord } }) => {
          },*/
      });

      if (!response.errors) {
        this.props.containerPropsForm.showNotificationWithTimeout(
          this.props.t("alert.datasaved"),
          false
        );
        // console.error('response',response);
      } else {
        //if (log)
        //console.error('gqlActions: error: ',response.errors);
        processError(response.error, this.props.containerPropsForm, true);
        return false; // don't must reload values, because error
      }
    } catch (err) {
      console.log("props", this.props);
      console.log("there are errorsr", err);
      processError(err, this.props.containerPropsForm, true); // new 19 Dec. 2017
    }

    this.setState({
      showModal: false,
    });

    if (this.props.nameField) {
      this.props.dispatch(
        change(this.props.nameForm, this.props.nameField, this.state.text)
      ); // for the field dont stringify, keep type object object
    }

    this.props.toolbarFunctionsContainer("closeDialog", {
      dialogName: "RichText",
    });
  };

  toolbarFunctions = (toolbar, params = {}) => {};

  hocdebounced = debounce((methodCode, params) => {
    this.executeCode(methodCode, params);
  }, 1500);

  executeCode = async (methodCode = "", params = {}) => {};

  closeModal = () => {
    // remove dialog name from the array list of dialog opened, in containter state
    // because need control state dialog, more one dialog can be opened at same time
    // lanunched from container,  don't support more one dialog same type
    this.props.toolbarFunctionsContainer("closeDialog", {
      dialogName: "RichText",
    });
    // hidde modal

    this.setState({
      showModal: false,
    });
  };

  sendCallback = (res) => {
    //console.log('send callback', res);

    if (res === "dontreloaddata") {
    }
    this.setState({
      clicked: false, // going to start processus
    });
  };
  render() {
    let orgSettings = resolvePathObj(
      this.props,
      "getOrgSettings.getOrgSettings.record"
    );
    let mailing = {};
    if (orgSettings) {
      orgSettings = JSON.parse(orgSettings);
      mailing = orgSettings.mailing;
      //console.log('mailing', mailing );
    }
    const { showModal } = this.state;

    const buttons = [];
    let save = false;
    if (!(this.props.permission && !this.props.permission.save)) {
      save = true;
      buttons.push({
        title: this.props.t("form.save"),
        action: this.send,
        callback: this.sendCallback,
      });
    }
    buttons.push({
      title: this.props.t("info.vCancel"),
      action: this.closeModal,
    });

    return (
      <div
        style={{ marginLeft: "30px", display: "flex", alignItems: "center" }}
      >
        <Portal
          scroll="1"
          header={this.props.t("form.write")}
          close={false}
          backgroundColor="#EFEFEF"
          open={showModal}
          onClose={() => this.closeModal()}
          size="80%"
          clicked={this.state.clicked}
          buttons={buttons}
        >
          <div style={{}}>
            {!save && (
              <div style={{ color: "darkred", marginBottom: "10px" }}>
                {this.props.t("info.alertPermission")}{" "}
                {this.props.t("info.vUpdate")}
              </div>
            )}
            <RichText
              key="mainform"
              textInitValue={this.props.textInitValue}
              id={""}
              onSubmit={this.handleSubmit}
              setText={this.setText}
              containerPropsForm={this.props}
              formState={this.state}
              t={this.props.t}
            />
          </div>
        </Portal>
      </div>
    );
  }
}
//
const tourUpdate = gql`
  mutation tourUpdate($id: ID!, $textgcu: String) {
    tourUpdate(id: $id, textgcu: $textgcu) {
      id
    }
  }
`;

const setOrgSettings = gql`
  mutation setOrgSettings($textgcu: String) {
    setOrgSettings(textgcu: $textgcu) {
      answer
      errors {
        key
        value
      }
    }
  }
`;
const withGraphqlandRouter = compose(
  graphql(tourUpdate, {
    name: "tourUpdate",
    options: (props) => {
      const optionsValues = { variables: { id: props.id, _qlType: "Update" } };
      optionsValues.fetchPolicy = "no-cache";
      return optionsValues;
    },
  }),
  graphql(setOrgSettings, {
    name: "setOrgSettings",
    options: (props) => {
      const optionsValues = {};
      optionsValues.fetchPolicy = "network-only";
      return optionsValues;
    },
  })
)(withRouter(FormContainer));

const mapStateToProps = (state) => {
  return {
    myState: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      appSubmitStart,
      appSubmitStop,
      showConfirm,
      initialize,
      change,
      touch,
      dispatch,
    },
    dispatch
  ); // to set this.props.dispatch
};

const withState = connect(
  mapStateToProps,
  mapDispatchToProps
)(withGraphqlandRouter);

const ComponentFull = withState;

export default ComponentFull;
